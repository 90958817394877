import { Spinner } from "@qbit/react";
import {
  type HierarchyType,
  type HierarchyItem,
  LocationHierarchy,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import {
  type RowSelectionState,
  type CellContext,
  type ColumnDef,
  type HeaderContext,
  type Row,
  type Table,
} from "@tanstack/react-table";
import { DataSourceHeader } from "components-ui/src/hierarchy-select-grid/Header";
import {
  type Hierarchy,
  type HierarchyGridItem,
} from "components-ui/src/hierarchy-select-grid/models/hierarchy";
import { EmptyGroup } from "components-ui/src/search/EmptyGroup";
import { EmptySearch } from "components-ui/src/search/EmptySearch";
import { ExpandableNameCell } from "components-ui/src/tables/common/table-cell/ExpandableNameCell";
import { VirtuosoTableComponent } from "components-ui/src/tables/virtuoso-table/VirtuosoTableComponent";
import { ColumnType } from "components-ui/src/tables/virtuoso-table/VirtuosoTableHeaderElement";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectLeafNodeShortName,
  selectSubscription,
} from "../../states/group-hierarchy-source-slice";
import { TransactionSourceCell } from "../transaction-source-cell/TransactionSourceCell";
import styles from "./StaticGroupLeafsTable.module.css";
import { StaticGroupLeafsTableHeader } from "./StaticGroupLeafsTableHeader";

const TRANSACTION_SOURCE_COLUMN_WIDTH = 40;
const PRODUCT_NAME_COLUMN_WIDTH = 250;

export type StaticGroupLeafsTableProps = {
  data: Hierarchy;
  displayEntitlements?: boolean;
  hierarchyType: string;
  isLoading: boolean;
  onSelectionsChange: (selectedItems: HierarchyItem[]) => void;
  searchQuery: string;
  selectedItems: HierarchyGridItem[];
};

export const StaticGroupLeafsTable = ({
  isLoading,
  data,
  displayEntitlements = false,
  hierarchyType,
  onSelectionsChange,
  searchQuery,
  selectedItems,
}: StaticGroupLeafsTableProps) => {
  const { transactionSources: availableTransactionSources } = useDivision();

  const [rowSelectionState, setRowSelectionState] = useState<RowSelectionState>(
    {}
  );

  const leafNodeShortName = useSelector(selectLeafNodeShortName);
  const subscription = useSelector(selectSubscription);

  const isAllRowsSelected = useCallback(
    (table: Table<HierarchyItem>) =>
      Object.values(table.getState().rowSelection).length ===
        data.items.length && data.items.length > 0,
    [data.items.length]
  );

  const handleAllToggleRowsSelected = useCallback(
    (table: Table<HierarchyItem>) => {
      const isChecked = isAllRowsSelected(table);

      let newSelectedRows: HierarchyItem[] = [];

      let commonItems: HierarchyItem[] = [];

      if (isChecked) {
        for (const item of data.items) {
          if (
            selectedItems.some(
              (selectedItem) => selectedItem.code === item.code
            )
          ) {
            commonItems = [...commonItems, item];
          }
        }

        newSelectedRows = selectedItems.filter(
          (selectedItem) =>
            !commonItems.some(
              (commonItem) => commonItem.code === selectedItem.code
            )
        );
      } else {
        newSelectedRows = [...selectedItems];
        for (const item of data.items) {
          if (
            !selectedItems.some(
              (selectedItem) => selectedItem.code === item.code
            )
          ) {
            newSelectedRows = [...newSelectedRows, item];
          }
        }
      }

      onSelectionsChange(newSelectedRows);
    },
    [data.items, isAllRowsSelected, onSelectionsChange, selectedItems]
  );

  const header = useCallback(
    ({ table }: HeaderContext<HierarchyGridItem, unknown>) => (
      <StaticGroupLeafsTableHeader
        // No clue how to do this
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        handleToggleAllRowsSelected={() => handleAllToggleRowsSelected(table)}
        isChecked={isAllRowsSelected(table)}
        showCheckbox
        title={hierarchyType}
      />
    ),
    [handleAllToggleRowsSelected, hierarchyType, isAllRowsSelected]
  );

  const getRowId = (row: HierarchyItem) => `${row.shortName}+${row.code}`;

  const items = useMemo(
    () =>
      data.items.map(
        (item) =>
          ({
            code: item.code,
            depth: item.depth,
            name: item.name,
            shortName: item.shortName,
            transactionSourceAccess: item.transactionSourceAccess,
            type: item.type,
          } as HierarchyItem)
      ),
    [data.items]
  );

  const isRowSelected = useCallback(
    (row: Row<HierarchyItem>) => {
      const item = row.original;

      return selectedItems.some(
        (selectedItem) => selectedItem.code === item.code
      );
    },
    [selectedItems]
  );

  const onToggleSelectionHandler = useCallback(
    (row: Row<HierarchyItem>) => {
      row.getToggleSelectedHandler();

      let newSelectedRows: HierarchyItem[] = [];

      const isChecked = selectedItems.some(
        (selectedItem) => selectedItem.code === row.original.code
      );
      if (isChecked) {
        newSelectedRows = selectedItems.filter(
          (selectedItem) => !(selectedItem.code === row.original.code)
        );
      } else {
        newSelectedRows = [...selectedItems, row.original];
      }

      onSelectionsChange(newSelectedRows);
    },
    [selectedItems, onSelectionsChange]
  );

  useEffect(() => {
    const newRowSelectionState: RowSelectionState = {};

    const newSelectedItems = data.items.filter((item) =>
      selectedItems.some((selectedItem) => selectedItem.code === item.code)
    );

    let indexes: number[] = [];
    for (const newSelectedItem of newSelectedItems) {
      indexes = [
        ...indexes,
        data.items.findIndex((item) => item.code === newSelectedItem.code),
      ];
    }

    for (const index of indexes) {
      newRowSelectionState[index] = true;
    }

    setRowSelectionState(newRowSelectionState);
  }, [data.items, selectedItems]);

  const cell = useCallback(
    ({ row, getValue }: CellContext<HierarchyItem, unknown>) => {
      // prepend code for stores only
      const displayValue =
        row.original.shortName === LocationHierarchy.Store
          ? `${row.original.code} - ${String(getValue())}`
          : String(getValue());

      return (
        <ExpandableNameCell
          canExpand={false}
          depth={row.depth}
          handleToggleExpanded={row.getToggleExpandedHandler()}
          handleToggleSelected={() => onToggleSelectionHandler(row)}
          isCompact
          isExpanded={false}
          isSelected={isRowSelected(row)}
          name={row.original.name}
          shortName={row.original.shortName}
          type={row.original.type}
          value={displayValue}
        />
      );
    },
    [isRowSelected, onToggleSelectionHandler]
  );

  const transactionSourceHeader = useCallback(
    () => <DataSourceHeader title="Dataset" />,
    []
  );

  const transactionSourceCell = useCallback(
    ({ row }: CellContext<HierarchyItem, unknown>) => (
      <TransactionSourceCell
        accessLevelShortName={leafNodeShortName}
        availableTransactionSources={availableTransactionSources}
        row={row}
        subscription={subscription}
      />
    ),
    [availableTransactionSources, leafNodeShortName, subscription]
  );

  const columns = useMemo<Array<ColumnDef<HierarchyItem>>>(() => {
    const columnDefinitions: Array<ColumnDef<HierarchyItem>> = [
      {
        accessorKey: "name",
        cell,
        header,
        meta: {
          columnType: ColumnType.Text,
        },
        size: PRODUCT_NAME_COLUMN_WIDTH,
      },
    ];

    if (displayEntitlements) {
      columnDefinitions.push({
        accessorKey: "dataset",
        cell: transactionSourceCell,
        header: transactionSourceHeader,
        size: TRANSACTION_SOURCE_COLUMN_WIDTH,
      });
    }

    return columnDefinitions;
  }, [
    cell,
    displayEntitlements,
    header,
    transactionSourceCell,
    transactionSourceHeader,
  ]);

  const alternateContent = useMemo(() => {
    if (data.items.length < 1 && searchQuery === "") {
      return <EmptyGroup type={hierarchyType as HierarchyType} />;
    } else if (data.items.length < 1 && searchQuery) {
      return (
        <div className={styles.noResults}>
          <EmptySearch />
        </div>
      );
    }

    return undefined;
  }, [data.items.length, hierarchyType, searchQuery]);

  return (
    <div className={styles.staticGroupLeafsTable}>
      {isLoading && <Spinner />}
      {!isLoading && alternateContent && (
        <div className={styles.alternateContent}>{alternateContent}</div>
      )}
      {!isLoading && !alternateContent && (
        <VirtuosoTableComponent
          // FIXME: should actually be optional and should not allow resizing for single column
          // https://jira.quantium.com.au/browse/CO3-1855
          columns={columns}
          compactRows
          data={items}
          depthPadding={50}
          getRowId={getRowId}
          pinFirstColumn
          rowExpandedState
          rowSelectionState={rowSelectionState}
        />
      )}
    </div>
  );
};
