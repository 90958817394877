import {
  FormBlock,
  FormBlockStatus,
  FormBlockType,
  TypeaheadMulti,
  TypeaheadFilter,
  TypeaheadWidth,
  TypeaheadHeight,
  Input,
} from "@qbit/react";
import { useState, useEffect, useMemo, useCallback } from "react";
import { ParameterLabel } from "../../../../parameter-label/ParameterLabel";
import { TimePeriodOptions } from "../../TimePeriodConstants";
import RequiredFieldError from "../required-field-error/RequiredFieldError";
import styles from "./TrendedAndAggregatedDropdown.module.css";

export type DropdownOption = {
  label: string;
  value: string;
};

export type TrendedAndAggregatedDropdownProps = {
  blockStatus: FormBlockStatus;
  defaultOption: DropdownOption;
  infoIcon?: string;
  label: string;
  onChange: (value: string[] | string) => void;
  options: DropdownOption[];
  selectedValue?: string;
};

export const TrendedAndAggregatedDropdown = ({
  blockStatus,
  defaultOption,
  infoIcon,
  label,
  onChange,
  options,
  selectedValue = "",
}: TrendedAndAggregatedDropdownProps) => {
  const [selected, setSelected] = useState<DropdownOption[]>([]);
  const [dropdownKey, setDropdownKey] = useState(0);
  const [isClosing, setIsClosing] = useState(false);

  const typeaheadOptions = useMemo(() => [{ options }], [options]);

  const typeaheadSelectedItems = useMemo(
    () =>
      selected.map((item) => ({
        value: item.value,
        label: item.label,
      })),
    [selected]
  );

  const hasCustomPeriod = useMemo(
    () =>
      selected.some((item) => item.value === TimePeriodOptions.CUSTOM_PERIOD),
    [selected]
  );

  // Function to close dropdown by remounting the component as typeaheadmulti does not have a close method
  const closeDropdown = useCallback(() => {
    setIsClosing(true);
    setTimeout(() => {
      setDropdownKey((previous) => previous + 1);
      setIsClosing(false);
    }, 50);
  }, []);

  const timePeriodOption = useCallback(
    (value: string) => options.find((opt) => opt.value === value),
    [options]
  );

  const handleCustomPeriod = useCallback(
    (selectedOptions: DropdownOption[]) => {
      const isCustomPeriodSelected = selectedOptions.some(
        (item) => item.value === TimePeriodOptions.CUSTOM_PERIOD
      );

      if (isCustomPeriodSelected && !hasCustomPeriod) {
        return selectedOptions.filter(
          (item) => item.value === TimePeriodOptions.CUSTOM_PERIOD
        );
      } else if (
        hasCustomPeriod &&
        selectedOptions.some(
          (item) => item.value !== TimePeriodOptions.CUSTOM_PERIOD
        )
      ) {
        return selectedOptions.filter(
          (item) => item.value !== TimePeriodOptions.CUSTOM_PERIOD
        );
      }

      return selectedOptions;
    },
    [hasCustomPeriod]
  );

  const handleTypeaheadChange = useCallback(
    (newSelected: Array<{ label?: string; value: number | string }>) => {
      if (isClosing) {
        return;
      }

      const selectedItems = newSelected
        .map((item) => timePeriodOption(String(item.value)))
        .filter(Boolean) as DropdownOption[];

      if (
        selectedItems.length === selected.length &&
        selectedItems.every((item) =>
          selected.some((select) => select.value === item.value)
        )
      ) {
        return;
      }

      const newSelection = handleCustomPeriod(selectedItems);

      setSelected(newSelection);

      if (newSelection.length === 0) {
        onChange([]);
      } else if (newSelection.length === 1) {
        onChange(newSelection[0].value);
      } else {
        onChange(newSelection.map((item) => item.value));
      }

      // Close dropdown if custom period is selected
      if (
        newSelection.some(
          (item) => item.value === TimePeriodOptions.CUSTOM_PERIOD
        )
      ) {
        closeDropdown();
      }
    },
    [
      selected,
      timePeriodOption,
      handleCustomPeriod,
      onChange,
      closeDropdown,
      isClosing,
    ]
  );

  // Initialize selected state from props
  useEffect(() => {
    if (!selectedValue) {
      return;
    }

    const selectedOptions = selectedValue
      .split(",")
      .map(timePeriodOption)
      .filter(Boolean) as DropdownOption[];

    if (selectedOptions.length > 0) {
      setSelected(selectedOptions);
    }
  }, [selectedValue, timePeriodOption]);

  return (
    <FormBlock
      blockStatus={blockStatus}
      blockType={FormBlockType.Select}
      className={styles.timeTypeaheadContainer}
    >
      <ParameterLabel description={infoIcon} heading={label} htmlFor={label} />
      <Input>
        <TypeaheadMulti
          className={styles.timeTypeahead}
          filterBy={TypeaheadFilter.Label}
          height={TypeaheadHeight.Medium}
          hideSelections={false}
          id={label}
          key={dropdownKey}
          onChange={handleTypeaheadChange}
          options={typeaheadOptions}
          placeholder={selected.length === 0 ? defaultOption.label : ""}
          selectedItems={typeaheadSelectedItems}
          width={TypeaheadWidth.Fill}
        />
      </Input>
      <div>
        {blockStatus === FormBlockStatus.Error && <RequiredFieldError />}
      </div>
    </FormBlock>
  );
};
