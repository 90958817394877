import tokens from "@qbit/core/dist/tokens.json";
import {
  Button,
  ButtonVariant,
  Dropdown,
  Icon,
  IconGlyph,
  IconSize,
  Text,
} from "@qbit/react";
import {
  type NotificationListDto,
  type NotificationDto,
  useLazyMarkAsReadQuery,
  useLazyFetchNotificationsQuery,
  useLazyGetUsersByIdQuery,
  getUserInitials,
  getUserColour,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import NoNotificationsSvg from "../assets/common/no-notifications.svg";
import { CircleIcon, CircleIconSize } from "../icons";
import styles from "./NotificationsDropdown.module.scss";

const emptyNotifications: NotificationListDto = {
  totalUnreadNotifications: 0,
  hasMore: false,
  notifications: [],
};

const getNotificationIcon = (senderInitials?: string, senderId?: string) => {
  if (senderId && senderInitials) {
    return (
      <div className={styles.notificationSenderIcon}>
        <CircleIcon
          color={getUserColour(senderId)}
          content={senderInitials}
          size={CircleIconSize.XSmall}
        />
      </div>
    );
  } else {
    return (
      <Icon
        colour={tokens.colour["status-bad-text"]}
        glyph={IconGlyph.AlertsAndNotificationsWarning}
        text="Alert"
      />
    );
  }
};

const NotificationElement = ({
  notification,
  initials,
}: {
  initials?: string;
  notification: NotificationDto;
}) => {
  const formattedDate = new Date(notification.createdAt)
    .toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    })
    .toString();

  const [markAsRead] = useLazyMarkAsReadQuery();

  const handleNotificationClick = useCallback(
    (notificationId: string) => {
      markAsRead({ notificationId }).catch(() => {
        // eslint-disable-next-line no-console
        console.log("Error couldn't mark item");
      });
    },
    [markAsRead]
  );

  return (
    <a
      className={styles.notificationLinkWrapper}
      href={notification.link}
      onClick={() => handleNotificationClick(notification.id)}
    >
      <div
        className={classNames(styles.notificationItem, {
          [styles.read]: notification.isRead,
        })}
        key={notification.id}
      >
        <div>{getNotificationIcon(initials, notification.senderId)}</div>
        <div className={styles.mainContent}>
          <Text className={styles.notificationMainText}>
            {notification.message}
          </Text>
          <p className={styles.notificationDate}>{formattedDate}</p>
        </div>
        <div className={styles.notificationDot} />
      </div>
    </a>
  );
};

export const NotificationsDropdown = () => {
  const [fetchNotifications] = useLazyFetchNotificationsQuery();
  const [getUsersById] = useLazyGetUsersByIdQuery();
  const [notifications, setNotifications] = useState(emptyNotifications);
  const [senders, setSenders] = useState<Map<string, string>>(new Map());
  const division = useDivision();

  const refreshNotifications = useCallback(() => {
    fetchNotifications({
      page: 0,
      pageSize: 100,
    })
      .then((response) => {
        if (response.data) {
          setNotifications(response.data);
        }
      })
      .catch(() => {
        // eslint-disable-next-line no-console
        console.error("Error fetching notifications");
      });
  }, [fetchNotifications, setNotifications]);

  const handleOpenChange = useCallback(
    (isOpen: boolean) => {
      if (isOpen) {
        refreshNotifications();
      }
    },
    [refreshNotifications]
  );

  useEffect(() => {
    refreshNotifications();
  }, [refreshNotifications]);

  const fetchSenderDetails = useCallback(
    (notificationList: NotificationListDto) => {
      const senderIds = notificationList.notifications
        .map((item) => item.senderId)
        .filter((id): id is string => id !== undefined);

      const uniqueSenderIds = [...new Set(senderIds)];

      if (uniqueSenderIds.length === 0) return;

      if (division.name) {
        void getUsersById({
          divisionName: division.name,
          payload: { SalesforceUserIds: uniqueSenderIds },
        }).then((response) => {
          const userData = response.data;
          if (userData) {
            setSenders((previousSenders) => {
              const salesMap = new Map<string, string>(previousSenders);

              for (const user of userData) {
                if (!salesMap.has(user.salesforceUserId)) {
                  salesMap.set(
                    user.salesforceUserId,
                    getUserInitials(user.firstName, user.lastName)
                  );
                }
              }

              return salesMap;
            });
          }
        });
      }
    },
    [getUsersById, division.name]
  );

  useEffect(() => {
    fetchSenderDetails(notifications);
  }, [notifications, fetchSenderDetails]);

  const iconTrigger = (
    <Button
      className={styles.notificationBellIconButton}
      data-testId="notifications-trigger"
      variant={ButtonVariant.Stealth}
    >
      <span>
        <Icon
          className={styles.notificationBellIcon}
          glyph={IconGlyph.AlertsAndNotificationsBellOutline}
          size={IconSize.Small}
          text="Notifications"
        />
      </span>

      {notifications.totalUnreadNotifications ? (
        <span
          className={styles.notificationBubble}
          data-testid="unread-count-bubble"
        >
          {notifications.totalUnreadNotifications}
        </span>
      ) : (
        <span />
      )}
    </Button>
  );

  return (
    <Dropdown
      className={styles.notificationDropdown}
      data-testid="notifications"
      onOpenChange={handleOpenChange}
      trigger={iconTrigger}
    >
      <div className={styles.notificationPanel}>
        <div className={styles.notificationsTitle}>Notifications</div>
        {notifications.notifications.length ? (
          notifications.notifications.map((notification) => (
            <NotificationElement
              initials={
                notification.senderId
                  ? senders.get(notification.senderId)
                  : undefined
              }
              key={"notification" + notification.id}
              notification={notification}
            />
          ))
        ) : (
          <div className={styles.noNotifications}>
            <div>
              <img alt="no notifications to show" src={NoNotificationsSvg} />
            </div>
            <Text>You don't have any notifications right now</Text>
          </div>
        )}
      </div>
    </Dropdown>
  );
};

export default NotificationsDropdown;
