import { MetricTypes, useFormatter } from "@quantium-enterprise/hooks-ui";
import HighChartsWithPinnableTooltips from "components-ui/src/charts/highcharts-react/HighChartsWithPinnableTooltips";
import { type HighchartsReactProps } from "components-ui/src/charts/highcharts-react/HighchartsReact";
import {
  TooltipHTML,
  defaultOptions,
} from "components-ui/src/charts/highcharts-react/HighchartsReact";
import { useEffect, useMemo } from "react";

type AffinitiesUpliftChartProps = {
  associatedItems: string[];
  basketsWithBothSeries: Array<number | null>;
  onOptionsChanged?: (options: HighchartsReactProps) => void;
  upliftSeries: Array<number | null>;
};

const uplift = "Uplift";

const AffinitiesUpliftChart = ({
  associatedItems,
  upliftSeries,
  basketsWithBothSeries,
  onOptionsChanged,
}: AffinitiesUpliftChartProps) => {
  const formatter = useFormatter();
  const options = useMemo(
    (): HighchartsReactProps => ({
      ...defaultOptions,
      series: [
        {
          borderWidth: 0,
          color: "var(--qbit-colour-brand-500)",
          grouping: false,
          pointPadding: 0.2,
          type: "column",
          zIndex: 1,
          data: upliftSeries,
          name: uplift,
          dataLabels: {
            crop: false,
            overflow: "allow",
            enabled: true,
            formatter() {
              // eslint-disable-next-line react/no-this-in-sfc, unicorn/no-this-assignment
              const self = this;
              const value = self.y;
              return formatter(MetricTypes.Decimal)(value, false, " x", true);
            },
            style: {
              color: "var(--qbit-colour-text-primary)",
              fontWeight: "var(--qbit-font-weight-regular)",
              fontSize: "12px",
              textOutline: "none",
            },
          },
        },
        {
          borderWidth: 0,
          color: "var(--qbit-colour-shade-4)",
          grouping: false,
          pointPadding: -0.2,
          type: "column",
          zIndex: 0,
          data: basketsWithBothSeries,
          yAxis: 1,
          name: "Baskets with both",
        },
      ],
      tooltip: {
        ...defaultOptions.tooltip,
        ReactFormatter: (data) => {
          const tooltipData = data.points?.map((pt, index: number) => ({
            color: String(pt.color),
            name: pt.series.name + ":",
            value: formatter(MetricTypes.Decimal)(
              pt.y,
              false,
              index === 0 ? " x" : "",
              true
            ),
          }));
          return TooltipHTML(tooltipData, data.x);
        },
      },
      xAxis: {
        categories: associatedItems,
        labels: {
          style: {
            color: "var(--qbit-colour-text-primary)",
          },
          enabled: true,
          rotation: -45,
        },
      },
      yAxis: [
        {
          labels: {
            style: {
              color: "var(--qbit-colour-text-primary",
            },
            enabled: true,
          },
          title: {
            style: {
              color: "var(--qbit-colour-text-primary)",
              fontWeight: "var(--qbit-font-weight-medium)",
            },
            text: uplift,
          },
        },
        {
          title: {
            style: {
              color: "var(--qbit-colour-text-primary)",
              fontWeight: "var(--qbit-font-weight-medium)",
            },
            text: "Baskets with both",
          },
          opposite: true,
        },
      ],
      legend: {
        align: "left",
        enabled: true,
        symbolRadius: 0,
        itemStyle: {
          color: "var(--qbit-colour-text-secondary)",
          fontFamily: `var(--qbit-font-family)`,
          fontSize: "0.75rem",
          fontWeight: "var(--qbit-font-weight-regular)",
        },
      },
      chart: {
        zooming: {
          type: undefined,
        },
        style: {
          fontFamily: "var(--qbit-font-family)",
        },
      },
    }),
    [associatedItems, basketsWithBothSeries, formatter, upliftSeries]
  );

  useEffect(() => {
    if (onOptionsChanged) {
      onOptionsChanged(options);
    }
  }, [options, onOptionsChanged]);

  return (
    <div>
      <HighChartsWithPinnableTooltips options={options} />
    </div>
  );
};

export default AffinitiesUpliftChart;
