import { Text } from "@qbit/react";
import { type ParameterDto } from "@quantium-enterprise/common-ui";
import { getMetricsState } from "../../parameters/metrics/MetricsState";
import { useAppSelector } from "../../states/hooks";
import styles from "./SummaryPanelMetric.module.css";

export type SummaryPanelMetricsProps = {
  parameterDto: ParameterDto;
};

export const SummaryPanelMetrics = ({
  parameterDto,
}: SummaryPanelMetricsProps) => {
  const metricsState = useAppSelector(getMetricsState(parameterDto.id));

  if (!metricsState?.selectedMetrics.length) {
    return null;
  }

  const remainingCount = metricsState.selectedMetrics.length - 3;

  return (
    <div className={styles.metricsList}>
      {metricsState.selectedMetrics.slice(0, 3).map((metric) => (
        <div className={styles.metricItem} key={metric.value}>
          <Text>{metric.label}</Text>
        </div>
      ))}
      {remainingCount > 0 && (
        <div className={styles.more}>
          <Text>{`+ ${remainingCount} more`}</Text>
        </div>
      )}
    </div>
  );
};
