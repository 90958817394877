import {
  type ParameterDto,
  type ParameterOptionDto,
  type SelectionDto,
  type MetricsOptionDto,
  isListSelectionDto,
} from "@quantium-enterprise/common-ui";
import { createSelector } from "@reduxjs/toolkit";
import { type ParameterState } from "../../states/ParameterState";
import { type RootState } from "../../store";

export type MetricsState = ParameterState & {
  data: {
    displayedItems: MetricsOptionDto[];
    filteredItems: MetricsOptionDto[];
    isLoading: boolean;
    items: MetricsOptionDto[];
    totalCount?: number;
  };
  errors: Record<string, boolean>;
  expandedGroups: string[];
  isSelectedItemsShown: boolean;
  searchString: string;
  selectedMetrics: MetricsOptionDto[];
};

const processMetric = (
  metric: ParameterOptionDto,
  groupValue: string
): MetricsOptionDto => ({
  ...metric,
  groupName: groupValue,
  metricList: [],
  description: metric.description || "",
  isDefault: metric.isDefault,
  isDisabled: metric.isDisabled,
  isAggregate: metric.isAggregate,
  options: metric.options ?? [],
});

const processGroup = (
  option: ParameterOptionDto & { metricList?: ParameterOptionDto[] }
): MetricsOptionDto => {
  const metricList = Array.isArray(option.metricList) ? option.metricList : [];
  const groupMetrics = metricList.map((metric) =>
    processMetric(metric, option.value)
  );

  return {
    value: option.value,
    label: option.label,
    groupName: option.value,
    description: option.description || "",
    metricList: groupMetrics,
    isDefault: option.isDefault,
    isDisabled: option.isDisabled,
    isAggregate: option.isAggregate,
    options: option.options ?? [],
  };
};

const getDisplayedItemsForSelection = (
  items: MetricsOptionDto[],
  selectedMetrics: MetricsOptionDto[]
): MetricsOptionDto[] =>
  items
    .map((group) => ({
      ...group,
      metricList: group.metricList.filter((metric) =>
        selectedMetrics.some((selected) => selected.value === metric.value)
      ),
    }))
    .filter((group) => group.metricList.length > 0);

export const validateMetricsState = (
  state: MetricsState
): {
  errors: Record<string, boolean>;
  isValid: boolean;
} => {
  const errors: Record<string, boolean> = {};
  const minRequired = state.parameterConfig.attributes.minSelections;

  if (state.selectedMetrics.length < minRequired) {
    errors.minSelection = true;
  }

  return {
    errors,
    isValid: Object.keys(errors).length === 0,
  };
};

export const isMetricGroup = (item: MetricsOptionDto): boolean =>
  Array.isArray(item.metricList) && item.metricList.length > 0;

export const isMetricsState = (state: ParameterState): state is MetricsState =>
  "selectedMetrics" in state;

export const newMetricsParameterState = (
  parameterConfig: ParameterDto,
  parameterGroup: string,
  savedSelections?: SelectionDto[]
): MetricsState => {
  const optionsWithMetrics = parameterConfig.options as Array<
    ParameterOptionDto & {
      metricList?: ParameterOptionDto[];
    }
  >;
  const items = optionsWithMetrics.map(processGroup);

  const preFilledSelections =
    savedSelections ?? parameterConfig.selections ?? [];
  let preSelectedMetrics: MetricsOptionDto[] = [];

  if (preFilledSelections.length > 0) {
    preSelectedMetrics = preFilledSelections
      .map((item) => {
        if (isListSelectionDto(item)) {
          let match = null;

          for (const group of items) {
            const found = group.metricList.filter(
              (x) => x.value === item.value
            );

            if (found.length > 0) {
              match = found[0];
              break;
            }
          }

          // Return match if found, otherwise undefined
          if (match) {
            const typedMatch: MetricsOptionDto = {
              description: match.description,
              groupName: match.groupName,
              isAggregate: match.isAggregate,
              isDefault: match.isDefault,
              isDisabled: match.isDisabled,
              label: match.label,
              metricList: match.metricList,
              options: match.options,
              value: match.value,
            };
            return typedMatch;
          }
        }

        // Return undefined if not a ListSelectionDto
        return undefined;
      })
      .filter(Boolean) as MetricsOptionDto[];
  }

  const baseState: MetricsState = {
    data: {
      items,
      filteredItems: items,
      isLoading: false,
      displayedItems: items,
      totalCount: items.reduce(
        (count, group) => count + group.metricList.length,
        0
      ),
    },
    selectedMetrics: preSelectedMetrics,
    expandedGroups: [],
    isSelectedItemsShown: false,
    searchString: "",
    isValid: false,
    parameterConfig,
    parameterGroup,
    errors: {},
    isWarning: false,
  };

  const validation = validateMetricsState(baseState);
  baseState.isValid = validation.isValid;
  baseState.errors = validation.errors;
  baseState.isWarning = preSelectedMetrics.length === 0;

  if (!savedSelections?.length) {
    return baseState;
  }

  const selections = savedSelections
    .filter(isListSelectionDto)
    .map((selection) => {
      const group = items.find((groupItem) =>
        groupItem.metricList.some((metric) => metric.value === selection.value)
      );

      return processMetric(
        {
          value: selection.value,
          label: selection.label,
          description: "",
          isDefault: false,
          isDisabled: false,
          isAggregate: false,
          options: [],
          groupName: "",
        },
        group?.value ?? ""
      );
    });

  if (!selections.length) {
    return baseState;
  }

  return {
    ...baseState,
    selectedMetrics: selections,
    isValid: selections.length >= parameterConfig.attributes.minSelections,
    isWarning: selections.length === 0,
  };
};

export const handleMetricSelection = (
  state: MetricsState,
  metric: MetricsOptionDto,
  selected: boolean
): MetricsState => {
  const selectedMetrics = selected
    ? [
        ...new Map(
          [...state.selectedMetrics, metric].map((item) => [item.value, item])
        ).values(),
      ]
    : state.selectedMetrics.filter(
        (selectedMetric) => selectedMetric.value !== metric.value
      );

  const updatedState = {
    ...state,
    selectedMetrics,
    isValid:
      selectedMetrics.length >= state.parameterConfig.attributes.minSelections,
    isWarning: selectedMetrics.length === 0,
  };

  if (state.isSelectedItemsShown) {
    updatedState.data = {
      ...state.data,
      displayedItems:
        selectedMetrics.length > 0
          ? getDisplayedItemsForSelection(state.data.items, selectedMetrics)
          : [],
    };
  }

  return updatedState;
};

export const handleMetricsSelections = (
  state: MetricsState,
  metrics: MetricsOptionDto[]
): MetricsState => {
  const updatedState = {
    ...state,
    selectedMetrics: metrics,
    isValid: metrics.length >= state.parameterConfig.attributes.minSelections,
    isWarning: metrics.length === 0,
  };

  if (state.isSelectedItemsShown) {
    updatedState.data = {
      ...state.data,
      displayedItems:
        metrics.length > 0
          ? getDisplayedItemsForSelection(state.data.items, metrics)
          : [],
    };
  }

  return updatedState;
};

export const handleMetricsSelectionChange = (
  state: MetricsState,
  newSelectedMetrics: MetricsOptionDto[],
  currentDisplayItems?: MetricsOptionDto[]
): MetricsState => {
  const updatedState = {
    ...state,
    selectedMetrics: newSelectedMetrics,
  };

  if (state.isSelectedItemsShown) {
    updatedState.data = {
      ...state.data,
      displayedItems:
        newSelectedMetrics.length > 0
          ? getDisplayedItemsForSelection(state.data.items, newSelectedMetrics)
          : [],
      filteredItems: currentDisplayItems ?? [],
    };
  }

  const validation = validateMetricsState(updatedState);
  updatedState.isValid = validation.isValid;
  updatedState.errors = validation.errors;
  updatedState.isWarning = newSelectedMetrics.length === 0;

  return updatedState;
};

export const handleMetricsSearch = (
  state: MetricsState,
  searchString: string
): MetricsState => {
  if (!searchString.trim()) {
    return {
      ...state,
      searchString: "",
      data: {
        ...state.data,
        filteredItems: state.data.items,
        displayedItems: state.isSelectedItemsShown
          ? getDisplayedItemsForSelection(
              state.data.items,
              state.selectedMetrics
            )
          : state.data.items,
      },
    };
  }

  const searchTerm = searchString.toLowerCase().trim();

  const matchingMetrics = state.data.items
    .flatMap((group) =>
      group.metricList.map((metric) => ({
        ...metric,
        originalGroup: group.value,
      }))
    )
    .filter((metric) => metric.label.toLowerCase().includes(searchTerm));

  const searchResults: MetricsOptionDto = {
    value: "search-results",
    label: `Search Results (${matchingMetrics.length})`,
    description: "",
    groupName: "search-results",
    metricList: matchingMetrics,
    isDefault: false,
    isDisabled: false,
    isAggregate: false,
    options: [],
  };

  return {
    ...state,
    searchString,
    data: {
      ...state.data,
      filteredItems: [searchResults],
      displayedItems: state.isSelectedItemsShown
        ? getDisplayedItemsForSelection([searchResults], state.selectedMetrics)
        : [searchResults],
    },
  };
};

export const handleGroupExpansion = (
  state: MetricsState,
  groupId: string
): MetricsState => {
  const expandedGroups = state.expandedGroups.includes(groupId)
    ? state.expandedGroups.filter((id) => id !== groupId)
    : [...state.expandedGroups, groupId];

  return {
    ...state,
    expandedGroups,
  };
};

export const getMetricsState = (parameterId: string) =>
  createSelector(
    [(state: RootState) => state.reportParameter.parameters[parameterId]],
    (parameterState): MetricsState | null =>
      isMetricsState(parameterState) ? parameterState : null
  );
