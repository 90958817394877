import { formatStringDate } from "@quantium-enterprise/common-ui";

export type TimePeriodItem = {
  customLength?: boolean;
  endDate: string;
  label: string;
  periodValue: string;
  startDate: string;
};

export type TimePeriodSelectionSummaryProps = {
  items: TimePeriodItem[] | undefined;
  name: string;
  showDateRange?: boolean;
};

export const TimePeriodSelectionSummary = ({
  name,
  items,
  showDateRange = true,
}: TimePeriodSelectionSummaryProps) => {
  const customLengthSelection = items?.find((item) => item.customLength);
  if (customLengthSelection && items?.length === 1) {
    const [, weeks] = customLengthSelection.periodValue.split("-");
    const startDateString = formatStringDate(customLengthSelection.startDate);
    const endDateString = formatStringDate(customLengthSelection.endDate);
    return (
      <div>
        <div>{name}: Custom selection</div>
        <div>Number of weeks: {weeks}</div>
        {showDateRange && (
          <div>
            {startDateString} to {endDateString}
          </div>
        )}
      </div>
    );
  } else {
    const itemStrings =
      items
        ?.filter((item) => !item.customLength)
        .map((item) => {
          const startDateString = formatStringDate(item.startDate);
          const endDateString = formatStringDate(item.endDate);
          return `${item.label} (${startDateString} - ${endDateString})`;
        })
        .join(", ") ?? "";
    return (
      <div>
        {name}: {itemStrings}
      </div>
    );
  }
};
