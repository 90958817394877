import { Button, ButtonHeight, ButtonVariant } from "@qbit/react";
import {
  FeatureFlag,
  useGetUserQuery,
  useShareCustomerGroupMutation,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import { DownloadIconForSidePanel } from "components-ui/src/assets/icons/DownloadIconForSidePanel";
import { type ButtonContent } from "components-ui/src/button-list/ButtonList";
import { ButtonList } from "components-ui/src/button-list/ButtonList";
import {
  cleanFilename,
  downloadFile,
  toCSVEncoded,
} from "components-ui/src/export/export-functions";
import { GreySpinner } from "components-ui/src/loader/GreySpinner";
import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GroupType } from "../../../enums/group-type";
import { setScrollToId } from "../../../states/group-list-slice";
import { getGroupListPath } from "../../../utilities/route-path-formats";
import { toCSVData } from "../../../utilities/segment-library-csv-utils";
import { type SegmentRow } from "../../segment-library-table/SegmentLibraryTableCells";
import commonStyles from "./GroupInfoPanelHeader.module.css";
import styles from "./SegmentLibraryInfoPanelHeader.module.css";

export type SegmentLibraryInfoPanelHeaderProps = {
  focalSegment?: SegmentRow;
};

export const SegmentLibraryInfoPanelHeader = ({
  focalSegment,
}: SegmentLibraryInfoPanelHeaderProps) => {
  const groupName = focalSegment?.customerGroupName;
  const flags = useFlags();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: userDto, isLoading } = useGetUserQuery();
  const currentUser = useMemo(() => userDto, [userDto]);
  const currentUserIsLoading = useMemo(() => isLoading, [isLoading]);
  const division = useDivision();
  const divisionName = division.name;
  const [shareCustomerGroup, { isLoading: isShareLoading }] =
    useShareCustomerGroupMutation();

  // create copy of group by sharing with current user
  const handleCreateCopy = useCallback(async () => {
    if (currentUser && focalSegment) {
      const newGroups = await shareCustomerGroup({
        divisionName,
        payload: {
          entityType: "Group",
          userIds: [currentUser.salesForceId],
          sharingNotes: "",
          sourceId: focalSegment.customerGroupId,
        },
      }).unwrap();
      const copiedGroupId = newGroups[newGroups.length - 1];
      dispatch(
        setScrollToId({
          groupType: GroupType.Customer,
          scrollToId: copiedGroupId,
        })
      );
      navigate(
        getGroupListPath(divisionName, GroupType.Customer, copiedGroupId)
      );
    }
  }, [
    currentUser,
    dispatch,
    divisionName,
    focalSegment,
    navigate,
    shareCustomerGroup,
  ]);

  const handleDownloadSegmentDetails = useCallback(async () => {
    if (focalSegment) {
      const data = toCSVData([focalSegment]);
      const encodedUri = toCSVEncoded(undefined, undefined, undefined, data);
      const filename = cleanFilename(
        `${focalSegment.customerGroupName}-${focalSegment.name}`
      );
      downloadFile(encodedUri, `${filename}.csv`);
    }
  }, [focalSegment]);
  const iconButtons = useMemo((): ButtonContent[][] => {
    const visibleButtons: ButtonContent[] = [];
    if (flags[FeatureFlag.SegmentBuilderDetailsDownloads]) {
      visibleButtons.push({
        buttonIcon: <DownloadIconForSidePanel />,
        handleClick: handleDownloadSegmentDetails,
        name: "download-segment-details",
        testId: "download-segment-details",
        text: "Download segment details",
        title: "Download segment details",
      });
    }

    return [visibleButtons];
  }, [handleDownloadSegmentDetails, flags]);

  return (
    <div className={commonStyles.infoPanelHeader}>
      <div className={commonStyles.infoPanelTitleContainer}>
        <h2 className={commonStyles.infoPanelTitle} title={groupName}>
          {groupName}
        </h2>
      </div>
      <div className={commonStyles.buttonPanel}>
        <div className={commonStyles.infoPanelButtons}>
          {!currentUserIsLoading &&
            focalSegment?.ownerId !== currentUser?.salesForceId && (
              <Button
                aria-label="Create a copy"
                className={styles.copyButton}
                data-testid="copy-segment"
                disabled={isShareLoading}
                height={ButtonHeight.XSmall}
                onClick={handleCreateCopy}
                variant={ButtonVariant.Primary}
              >
                {isShareLoading ? <GreySpinner /> : <div>Create a copy</div>}
              </Button>
            )}
          <ButtonList buttons={iconButtons} />
        </div>
      </div>
    </div>
  );
};
