import {
  type ParameterOptionDto,
  type ParameterDto,
  isTimePeriodParameterOption,
  isPromoWeekOption,
} from "@quantium-enterprise/common-ui";
import { addDays, differenceInCalendarWeeks, format } from "date-fns";
import { type RollingPeriodState } from "./RollingPeriodState";
import { type TimePeriodState } from "./TimePeriodState";
import { TimePeriodOptions } from "./components/TimePeriodConstants";
import { type TimePeriodOption } from "./components/time-period-option";

// File holding some useful functions
export const DAYS_IN_WEEK = 7;
export const WEEKS_IN_YEAR = 52;

export const dateToString = (
  date: Date | undefined,
  style: string = "d MMM yy"
) => {
  if (!date) {
    return "";
  }

  return format(date, style);
};

export const datetimeToIsoNoOffset = (date: Date | undefined) => {
  if (!date) {
    return "";
  }

  // convert AEST to ISO string, ignoring timezone offset
  return `${format(new Date(date), "yyyy-MM-dd")}T00:00:00.000Z`;
};

export const timestampToDate = (timestamp: string) => {
  if (!timestamp) {
    return undefined;
  }

  // We store dates in redux as UTC, when these times are displayed for users we need to convert to local time whilst retaining the same date.

  // Split the timestamp to get just the date part - 2024-10-25
  const [datePart] = timestamp.split("T");

  // Create a new date string with time set to midnight in local time
  const localDateString = `${datePart}T00:00:00.000`;

  return new Date(localDateString);
};

export const dateToIsoString = (date?: Date) =>
  dateToString(date, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");

export const shiftDays = (date: Date | undefined, days: number) => {
  if (!date) {
    return undefined;
  }

  return addDays(date, days);
};

const discardUTCOffset = (dateAsISOString: string): string =>
  dateAsISOString.slice(0, 19);

export const getDateOnly = (date: Date | string): Date => {
  if (date instanceof Date) {
    return new Date(date.toDateString());
  } else {
    return new Date(discardUTCOffset(date));
  }
};

export const getDifferenceInWeeks = (
  startDate: Date | undefined,
  endDate: Date | undefined
): number | undefined => {
  if (!startDate || !endDate) {
    return undefined;
  }

  const cleanedStartDate = getDateOnly(startDate);
  const cleanedEndDate = getDateOnly(endDate);

  const diffWeeks = differenceInCalendarWeeks(
    cleanedEndDate,
    cleanedStartDate,
    {
      weekStartsOn: cleanedEndDate.getDay() as 0 | 1 | 2 | 3 | 4 | 5 | 6,
    }
  );
  return diffWeeks;
};

export const convertParametersToTimePeriodOptions = (
  parameterOptions: ParameterOptionDto[]
) => {
  const options: TimePeriodOption[] = [];

  for (const option of parameterOptions) {
    if (isTimePeriodParameterOption(option)) {
      options.push({
        disabled: option.isDisabled,
        endDate: option.endDate || "",
        label: option.label,
        maxWeeks: option.maxWeeks,
        minWeeks: option.minWeeks,
        startDate: option.startDate || "",
        value: option.value,
        customLength: option.customLength,
        options: option.options?.filter(isPromoWeekOption) ?? [],
      });
    } else {
      options.push({
        disabled: false,
        endDate: "",
        label: option.label,
        startDate: "",
        value: option.value,
        customLength: false,
        options: [],
      });
    }
  }

  return options;
};

export const convertParametersToCustomPeriodOptions = (
  parameterOption: ParameterOptionDto[]
) => {
  const customTimePeriod = parameterOption.find(
    (fp) => fp.value === TimePeriodOptions.CUSTOM_PERIOD
  );

  if (!customTimePeriod) {
    return [];
  }

  if (customTimePeriod.options) {
    const promoWeekOptions = customTimePeriod.options.map((option) => {
      // Assumes the format of 'W/E YYYY-MM-DD'
      const dateString = option.label.split(" ")[1];
      const weekEndDate = new Date(
        dateString.replace(/Z$/u, "") + "T00:00:00.000"
      );

      return {
        value: option.value,
        weekEndDate,
        // Start day of promoweek is end day less 6 days
        weekStartDate: addDays(weekEndDate, -6),
      };
    });

    return promoWeekOptions;
  }

  return [];
};

export const getComparisonPeriod = (
  comparisonPeriod: string,
  focusPeriodStartDate: string,
  weeks: number | undefined
): { endDate: string; startDate: string } => {
  if (weeks) {
    const cleanedFocusStartDate = timestampToDate(focusPeriodStartDate);

    if (cleanedFocusStartDate) {
      if (
        comparisonPeriod === TimePeriodOptions.LAST_PERIOD ||
        comparisonPeriod === TimePeriodOptions.CUSTOM_PERIOD
      ) {
        const comparisonStartDate = addDays(
          cleanedFocusStartDate,
          -weeks * DAYS_IN_WEEK
        );
        const comparisonEndDate = addDays(cleanedFocusStartDate, -1);
        return {
          endDate: datetimeToIsoNoOffset(comparisonEndDate),
          startDate: datetimeToIsoNoOffset(comparisonStartDate),
        };
      } else if (comparisonPeriod === TimePeriodOptions.LAST_YEAR) {
        const comparisonStartDate = addDays(
          cleanedFocusStartDate,
          -WEEKS_IN_YEAR * DAYS_IN_WEEK
        );
        const comparisonEndDate = addDays(
          comparisonStartDate,
          weeks * DAYS_IN_WEEK - 1
        );
        return {
          endDate: datetimeToIsoNoOffset(comparisonEndDate),
          startDate: datetimeToIsoNoOffset(comparisonStartDate),
        };
      }
    }
  }

  return { endDate: "", startDate: "" };
};

export const getStartDate = (endDate: string, weeks: number): string => {
  const cleanedEndDate = timestampToDate(endDate);

  if (cleanedEndDate) {
    const startDate = addDays(cleanedEndDate, -weeks * DAYS_IN_WEEK + 1);
    return datetimeToIsoNoOffset(startDate);
  }

  return "";
};

export const getEndDate = (startDate: string, weeks: number): string => {
  const cleanedStartDate = timestampToDate(startDate);

  if (cleanedStartDate) {
    const endDate = addDays(cleanedStartDate, weeks * DAYS_IN_WEEK - 1);
    return datetimeToIsoNoOffset(endDate);
  }

  return "";
};

export const getMinCustomPeriodStartDate = (
  timePeriodOptions: ParameterDto
): string => {
  const customTimePeriod = timePeriodOptions.options.find(
    (option) => option.value === TimePeriodOptions.CUSTOM_PERIOD
  )?.options;

  if (customTimePeriod && isTimePeriodParameterOption(customTimePeriod[0])) {
    return customTimePeriod[0].startDate;
  }

  return "";
};

export const validateComparisonPeriodOptions = (
  comparisonPeriodState: TimePeriodState,
  focusPeriodState: TimePeriodState
) => {
  const minStartDate = getMinCustomPeriodStartDate(
    comparisonPeriodState.parameterConfig
  ).split("T")[0];

  const updatedOptions = comparisonPeriodState.options.map((option) => {
    const { startDate, endDate } = getComparisonPeriod(
      option.value,
      focusPeriodState.startDate,
      focusPeriodState.weeks
    );

    return {
      ...option,
      disabled: focusPeriodState.weeks
        ? dateToIsoString(timestampToDate(startDate)) < minStartDate
        : false,
      endDate: option.value === TimePeriodOptions.CUSTOM_PERIOD ? "" : endDate,
      startDate:
        option.value === TimePeriodOptions.CUSTOM_PERIOD ? "" : startDate,
    };
  });

  return {
    ...comparisonPeriodState,
    options: updatedOptions,
  };
};

export const validateRollingPeriodOptions = (
  rollingPeriodState: RollingPeriodState,
  focusPeriodState: TimePeriodState
): RollingPeriodState => {
  const minStartDate = getMinCustomPeriodStartDate(
    focusPeriodState.parameterConfig
  );

  const remainingWeeks =
    getDifferenceInWeeks(
      timestampToDate(minStartDate),
      timestampToDate(focusPeriodState.startDate)
    ) ?? 0;

  const updatedOptions = rollingPeriodState.options.map((option) => ({
    ...option,
    disabled: focusPeriodState.weeks
      ? Number(option.value) > remainingWeeks + 1
      : false,
  }));

  return {
    ...rollingPeriodState,
    options: updatedOptions,
  };
};
