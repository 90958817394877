const ONE_MINUTE = 1_000 * 60;
const ONE_HOUR = ONE_MINUTE * 60;
const ONE_DAY = ONE_HOUR * 24;
const ONE_WEEK = ONE_DAY * 7;

export const getTimeAgoString = function (
  addedTimeStamp: string,
  compareDate?: Date
): string {
  const dateAdded: Date = new Date(addedTimeStamp);
  const rightNow = compareDate ?? new Date();

  const difference = rightNow.getTime() - dateAdded.getTime();
  if (difference <= ONE_MINUTE) {
    return "A few seconds ago";
  }

  if (difference < ONE_HOUR) {
    const minsDifference = Math.floor(difference / ONE_MINUTE);
    return minsDifference + " min" + (minsDifference === 1 ? "" : "s") + " ago";
  }

  if (difference <= ONE_DAY) {
    const hoursDifference = Math.floor(difference / ONE_HOUR);
    return (
      hoursDifference + " hour" + (hoursDifference === 1 ? "" : "s") + " ago"
    );
  }

  if (difference <= ONE_WEEK) {
    const daysDifference = Math.floor(difference / 1_000 / 60 / 60 / 24);
    return daysDifference + " day" + (daysDifference === 1 ? "" : "s") + " ago";
  }

  return dateAdded.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
};
