import {
  AnchorButton,
  Button,
  ButtonHeight,
  ButtonVariant,
  Dialog,
  Group,
  GroupGutters,
  Icon,
  IconGlyph,
  IconSize,
  InlineIcon,
  InlineIconGlyph,
  Item,
  ItemWidth,
} from "@qbit/react";
import classNames from "classnames";
import { useCallback, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import { ExpanderSet } from "../expander/ExpanderSet";
import styles from "./InformationModal.module.css";
import { type expanderContent } from "./segmentation-modal/SegmentationInformation";

const ESCAPE_KEYCODE = 27;

export type InformationModalProps = {
  className?: string;
  creationType?: string;
  descriptionWithExpanders?: expanderContent[];
  detailedDescription?: JSX.Element | string;
  detailedDescriptionMarkdown?: string;
  header: JSX.Element | string;
  label: string;
  learnMore?: string;
  modalVisibilityState?: {
    isShown: boolean;
    setIsShown: (shown: boolean) => void;
  };
  moreInfo?: JSX.Element | string;
  moreInfoMarkdown?: string;
  path?: string;
};

export const InformationModal = ({
  className,
  creationType,
  header,
  detailedDescriptionMarkdown,
  detailedDescription,
  moreInfoMarkdown,
  moreInfo,
  descriptionWithExpanders,
  learnMore,
  label,
  modalVisibilityState,
  path,
}: InformationModalProps) => {
  const triggerRef = useRef<HTMLDivElement>(null);
  const [isModalShown, setIsModalShown] = useState(
    modalVisibilityState?.isShown ?? false
  );

  const isModalShownLocal = modalVisibilityState?.isShown ?? isModalShown;
  const setIsModalShownLocal =
    modalVisibilityState?.setIsShown ?? setIsModalShown;

  const handleOnModalClose = useCallback(() => {
    setIsModalShownLocal(false);
  }, [setIsModalShownLocal]);

  const headerContent = (
    <>
      <div className={styles.modalHeader}>
        <div className={styles.modalHeaderText}>{header}</div>
        <Button
          className={styles.closeButton}
          height={ButtonHeight.XSmall}
          onClick={() => {
            handleOnModalClose();
          }}
          variant={ButtonVariant.Stealth}
        >
          <Icon
            colour="var(--qbit-colour-shade-10)"
            glyph={IconGlyph.DeleteAndCloseClose}
            size={IconSize.Small}
            text="Close"
          />
        </Button>
      </div>
      <div className={styles.modalBodyContent}>
        {detailedDescriptionMarkdown && (
          <ReactMarkdown>{detailedDescriptionMarkdown}</ReactMarkdown>
        )}
        {detailedDescription}
      </div>
      <div className={styles.modalBodyContent}>
        {moreInfoMarkdown && <ReactMarkdown>{moreInfoMarkdown}</ReactMarkdown>}
        {moreInfo}
      </div>
    </>
  );

  const footerContent = useMemo(
    () => (
      <Group className={styles.modalButtonGroup} gutters={GroupGutters.Medium}>
        <div className={styles.modalButtons}>
          <div className={styles.modalButton}>
            <Button
              height={ButtonHeight.Small}
              onClick={() => {
                handleOnModalClose();
              }}
              text="Done"
              variant={ButtonVariant.Primary}
            />
          </div>
          {creationType && path && (
            <Link to={path}>
              <Button
                height={ButtonHeight.Small}
                text={"Create " + creationType}
                variant={ButtonVariant.Secondary}
              />
            </Link>
          )}
        </div>
        {learnMore && (
          <Item width={ItemWidth.Fit}>
            <AnchorButton
              href={learnMore}
              rel="noreferrer"
              target="_blank"
              text="Learn more"
              variant={ButtonVariant.Link}
            />
          </Item>
        )}
      </Group>
    ),
    [creationType, handleOnModalClose, learnMore, path]
  );

  const trigger = useMemo(
    () => (
      <div
        className={classNames(className, styles.informationModalTriggerButton)}
        onClick={() => {
          setIsModalShownLocal(true);
        }}
        onKeyUp={(event: React.KeyboardEvent<HTMLElement>) => {
          if (event.key === "Escape" || event.keyCode === ESCAPE_KEYCODE)
            setIsModalShownLocal(true);
        }}
        ref={triggerRef}
        role="button"
        tabIndex={0}
      >
        <InlineIcon
          glyph={InlineIconGlyph.AlertsAndNotificationsHelpCircleOutline}
          size={IconSize.Small}
          text={label}
        />
      </div>
    ),
    [label, className, setIsModalShownLocal]
  );
  return (
    <>
      {createPortal(
        <Dialog
          className={classNames(styles.informationModal, {
            [styles.informationModalWithExpanders]: descriptionWithExpanders,
          })}
          footer={footerContent}
          header={headerContent}
          onClose={() => handleOnModalClose()}
          show={isModalShownLocal}
          titleId="information-modal"
          triggeredBy={triggerRef}
        >
          <>
            {descriptionWithExpanders &&
              descriptionWithExpanders.length > 0 && (
                <ExpanderSet
                  className={styles.expanderSetInModal}
                  expanders={descriptionWithExpanders}
                />
              )}
          </>
        </Dialog>,
        document.body
      )}
      {trigger}
    </>
  );
};
