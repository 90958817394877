import { Spinner, SpinnerSize } from "@qbit/react";
import {
  useGetSavedParametersQuery,
  useGetUsersByIdQuery,
  FeatureFlag,
  useDeleteSavedParametersMutation,
  useLazyGetSavedParametersQuery,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import { type DeleteItems } from "components-ui/src/delete-dialog/DeleteDialog";
import { useCallback } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { NoContent, Variant } from "../no-content/NoContent";
import styles from "./SavedAndScheduled.module.css";
import { SavedAndScheduledGrid } from "./SavedAndScheduledGrid";

export const SavedAndScheduled = () => {
  const navigate = useNavigate();
  const { id: selectedSavedParametersId } = useParams();
  const { name: activeDivisionName } = useDivision();
  const flags = useFlags();
  const [deleteSavedParameters] = useDeleteSavedParametersMutation();
  const [triggerGetSavedParameters] = useLazyGetSavedParametersQuery();
  const { data: savedParameters } = useGetSavedParametersQuery(
    {
      divisionName: activeDivisionName,
    },
    {
      skip: !activeDivisionName || !flags[FeatureFlag.SavedSelections],
      refetchOnMountOrArgChange: true,
      pollingInterval: 30_000,
    }
  );
  const { data: allSavedParametersSharedUsers } = useGetUsersByIdQuery(
    {
      divisionName: activeDivisionName,
      payload: {
        SalesforceUserIds:
          savedParameters?.flatMap((value) => value.sharedWithUserIds) ?? [],
      },
    },
    {
      skip: !flags[FeatureFlag.SharingReports] || !savedParameters?.length,
    }
  );

  const savedAndScheduledNavigator = useCallback(
    (savedParametersId: string) => {
      if (savedParametersId === selectedSavedParametersId) {
        navigate(
          generatePath(`/:division/my-reports/saved-and-scheduled`, {
            division: activeDivisionName,
          })
        );
      } else {
        navigate(
          generatePath(`/:division/my-reports/saved-and-scheduled/:id`, {
            division: activeDivisionName,
            id: savedParametersId,
          })
        );
      }
    },
    [selectedSavedParametersId, navigate, activeDivisionName]
  );

  const handleDeleteSavedParameters = useCallback(
    async (itemIds: DeleteItems) => {
      if (itemIds.savedParametersIds?.length === 1) {
        await deleteSavedParameters({
          divisionName: activeDivisionName,
          savedParametersId: itemIds.savedParametersIds[0],
        })
          .unwrap()
          .then(
            async () =>
              await triggerGetSavedParameters({
                divisionName: activeDivisionName,
              })
          );
      }
    },
    [activeDivisionName, deleteSavedParameters, triggerGetSavedParameters]
  );

  const showGrid = Boolean(savedParameters?.length);
  const isLoading = savedParameters === undefined;
  return (
    <div>
      <div className={styles.savedParametersContainer}>
        {isLoading && <Spinner size={SpinnerSize.Large} />}
        {!isLoading &&
          (showGrid ? (
            <SavedAndScheduledGrid
              allSavedParametersSharedUsers={
                allSavedParametersSharedUsers ?? []
              }
              deleteItem={handleDeleteSavedParameters}
              savedAndScheduledNavigator={savedAndScheduledNavigator}
              savedParameters={savedParameters}
            />
          ) : (
            <NoContent
              activeDivisionName={activeDivisionName}
              variant={Variant.SavedSelections}
            />
          ))}
      </div>
    </div>
  );
};

export default SavedAndScheduled;
