import {
  Dropdown,
  Group,
  Icon,
  IconGlyph,
  IconSize,
  InlineIconGlyph,
  Item,
  ItemHalign,
  Menu,
  MenuItemButton,
  MenuSection,
  Number,
  NumberSentiment,
  NumberUnitPosition,
  Spinner,
  SpinnerSize,
  StealthInlineIconButton,
  Text,
} from "@qbit/react";
import {
  HierarchyType,
  type FocalItem,
  HierarchyShortName,
  type TransactionSource,
  useGetUserQuery,
  getTransactionSourceFromEntitlements,
  HierarchyItemType,
} from "@quantium-enterprise/common-ui";
import { useOpenFastReportingTabs } from "@quantium-enterprise/fast-reporting-ui";
import {
  MetricTypes,
  useDivision,
  useFormatter,
} from "@quantium-enterprise/hooks-ui";
import {
  type Row,
  type CellContext,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import classNames from "classnames";
import { HierarchyLevelIcon } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { HierarchyGroupIcon } from "components-ui/src/icons";
import { TransactionSourceIcon } from "components-ui/src/icons/transaction-source-icon/TransactionSourceIcon";
import {
  useState,
  type DragEvent,
  type FC,
  useRef,
  useMemo,
  useCallback,
} from "react";
import DragIcon from "../../assets/drag.svg";
import GrowthDecreaseIcon from "../../assets/growth-decrease.svg";
import GrowthIncreaseIcon from "../../assets/growth-increase.svg";
import GrowthNeutralIcon from "../../assets/growth-neutral.svg";
import { type WatchlistItemDto } from "../../services/dtos/WatchlistItemDto";
import { WatchlistItemType } from "../../services/dtos/WatchlistItemDto";
import { setDraggedImageToElement } from "../../utils/setDraggedImageToElement";
import styles from "./WatchlistTable.module.css";

export type MetricValue = {
  hasValue: boolean;
  value: number;
};

export type Metric = {
  comparisonPeriod: MetricValue;
  focalPeriod: MetricValue;
  growth: MetricValue;
};

export type WatchlistItemWithMetrics = WatchlistItemDto & {
  metrics: { [key: string]: Metric | undefined };
};

export type WatchlistItemDtoName = {
  primary: string;
  secondary?: string;
};

export type WatchlistItemDtoCode = {
  primary: string;
  secondary?: string;
};

export type MetricDefinition = {
  displayName: string;
  formatType: string;
  key: string;
};

export type WatchlistTableProps = {
  comparisonPeriodTitle: string;
  focalPeriodTitle: string;
  items: WatchlistItemWithMetrics[];
  metrics: MetricDefinition[];
  onDeleteItems: (items: WatchlistItemDto[]) => void;
  onReorderItem: (
    item: WatchlistItemDto,
    oldPosition: number,
    newPosition: number
  ) => void;
  refetching: boolean;
  userSelectedTransactionSource?: TransactionSource;
};

const MetricData =
  (
    refetching: boolean,
    formatter: Function,
    transactionSource?: TransactionSource
  ) =>
  (context: CellContext<WatchlistItemWithMetrics, MetricValue | undefined>) => {
    if (
      transactionSource &&
      !context.row.original.entitlements.includes(transactionSource)
    ) {
      return <Text>N/A</Text>;
    }

    const value = context.getValue();
    if (value === undefined || refetching) {
      return undefined;
    }

    return <Number>{formatter(value.value)}</Number>;
  };

const MetricDataLoading =
  (
    refetching: boolean,
    formatter: Function,
    transactionSource?: TransactionSource
  ) =>
  (context: CellContext<WatchlistItemWithMetrics, MetricValue | undefined>) => {
    if (
      transactionSource &&
      !context.row.original.entitlements.includes(transactionSource)
    ) {
      return <Text>N/A</Text>;
    }

    return context.getValue() === undefined || refetching ? (
      <Spinner className={styles.metricDataLoading} size={SpinnerSize.Small} />
    ) : (
      MetricData(refetching, formatter, transactionSource)(context)
    );
  };

const GrowthData =
  (
    refetching: boolean,
    formatter: Function,
    transactionSource?: TransactionSource
  ) =>
  (context: CellContext<WatchlistItemWithMetrics, MetricValue | undefined>) => {
    let growthIcon = (
      <img
        alt="No growth"
        className={styles.growthIcon}
        src={GrowthNeutralIcon}
      />
    );

    if (
      transactionSource &&
      !context.row.original.entitlements.includes(transactionSource)
    ) {
      return (
        <>
          <Text>N/A</Text>
          {growthIcon}
        </>
      );
    }

    const value = context.getValue();
    if (value === undefined || refetching) {
      return undefined;
    }

    const numberString = value.hasValue ? formatter(value.value) : "N/A";
    let sentiment = NumberSentiment.Neutral;

    if (value.value > 0) {
      sentiment = NumberSentiment.Good;
      growthIcon = (
        <img
          alt="Value has increased"
          className={styles.growthIcon}
          src={GrowthIncreaseIcon}
        />
      );
    }

    if (value.value < 0) {
      sentiment = NumberSentiment.Bad;
      growthIcon = (
        <img
          alt="Value has decreased"
          className={styles.growthIcon}
          src={GrowthDecreaseIcon}
        />
      );
    }

    return (
      <>
        <Number sentiment={sentiment} unitPosition={NumberUnitPosition.After}>
          {numberString}
        </Number>{" "}
        {growthIcon}
      </>
    );
  };

export type WatchlistItemIconProps = {
  item: WatchlistItemDto;
};

const WatchlistItemIcon = ({
  item,
}: WatchlistItemIconProps): JSX.Element | null => {
  if (item.type === WatchlistItemType.Hierarchy) {
    return (
      <HierarchyLevelIcon
        shortName={item.shortName}
        type={
          item.isLeaf ? HierarchyItemType.Leaf : HierarchyItemType.Hierarchy
        }
      />
    );
  }

  if (item.type === WatchlistItemType.Attribute) {
    return (
      <HierarchyLevelIcon
        shortName={item.shortName}
        type={HierarchyItemType.Attribute}
      />
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- Think this makes it clearer
  if (item.type === WatchlistItemType.ProductGroup) {
    return (
      <HierarchyGroupIcon
        evaluationType={item.evaluationType}
        hierarchyType={HierarchyType.Product}
      />
    );
  }

  return null;
};

const DraggedItem: FC<{
  row: Row<WatchlistItemDto>;
}> = ({ row }) => (
  <div className={styles.draggedItem}>
    <span className={styles.hierarchyIcon}>
      <WatchlistItemIcon item={row.original} />
    </span>
    {row.original.name}
  </div>
);

export const WatchlistTable = ({
  items,
  metrics,
  focalPeriodTitle,
  comparisonPeriodTitle,
  onDeleteItems,
  onReorderItem,
  refetching,
  userSelectedTransactionSource,
}: WatchlistTableProps) => {
  const { data: user } = useGetUserQuery();
  const { transactionSources: availableTransactionSources } = useDivision();
  const formatter = useFormatter();

  const isSupplier = useMemo(() => user?.isSupplier, [user]);
  const displayEntitlements =
    isSupplier === true || userSelectedTransactionSource !== undefined;

  const nonMetricColumns = useMemo(
    () => (displayEntitlements ? 3 : 2),
    [displayEntitlements]
  );

  const gridColumnEnd = useCallback(
    (columnsLength: number) => {
      const modifier = displayEntitlements ? 5 : 3;

      return columnsLength * 3 - modifier;
    },
    [displayEntitlements]
  );

  const isDelimited = useCallback(
    (index: number) => {
      if (index < 3) {
        return false;
      }

      const modifier = displayEntitlements ? 3 : 4;

      return (index + modifier) % 3 === 0;
    },
    [displayEntitlements]
  );

  const draggedRow = useRef<Row<WatchlistItemDto>>();
  const [draggedOverRow, setDraggedOverRow] =
    useState<Row<WatchlistItemWithMetrics>>();

  const onDragStart = (
    event: DragEvent<HTMLElement>,
    row: Row<WatchlistItemDto>
  ): void => {
    draggedRow.current = row;
    setDraggedImageToElement(event, <DraggedItem row={row} />);
  };

  const onDrop = (
    event: DragEvent<HTMLElement>,
    row: Row<WatchlistItemDto>
  ): void => {
    event.preventDefault();
    if (draggedRow.current) {
      onReorderItem(
        draggedRow.current.original,
        draggedRow.current.index,
        row.index
      );
      setDraggedOverRow(undefined);
    }
  };

  const onDragOver = (
    event: DragEvent<HTMLElement>,
    row: Row<WatchlistItemWithMetrics>
  ): void => {
    event.preventDefault();

    if (draggedRow.current?.id === row.id) {
      setDraggedOverRow(undefined);
    } else {
      setDraggedOverRow(row);
    }
  };

  const openTabs = useOpenFastReportingTabs();
  const openFastReportingTab = useCallback(
    (row: Row<WatchlistItemWithMetrics>) => {
      let focalItem: FocalItem;

      if (row.original.type === WatchlistItemType.Attribute) {
        focalItem = {
          code: row.original.code,
          displayName: row.original.name,
          shortName: row.original.shortName,
          type: HierarchyItemType.Attribute,
          additionalHierarchyFilter: {
            code: row.original.hierarchyCode ?? "",
            shortName: row.original.hierarchyLevel ?? "",
            displayName: row.original.hierarchyName ?? "",
          },
        };
      } else if (row.original.type === WatchlistItemType.Hierarchy) {
        focalItem = {
          code: row.original.code,
          displayName: row.original.name,
          shortName: row.original.shortName,
          type: row.original.isLeaf
            ? HierarchyItemType.Leaf
            : HierarchyItemType.Hierarchy,
        };
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- Think this makes it clearer
      } else if (row.original.type === WatchlistItemType.ProductGroup) {
        focalItem = {
          type: HierarchyShortName.ProductGroup,
          displayName: row.original.name,
          evaluationType: row.original.evaluationType,
          productGroupId: row.original.productGroupId,
        };
      } else {
        throw new Error("Unknown watchlist item type");
      }

      openTabs([focalItem]);
    },
    [openTabs]
  );

  const createActionsCell = ({
    row,
  }: CellContext<WatchlistItemWithMetrics, unknown>) => (
    <div
      data-cy="GrabHandle"
      draggable
      onDragOver={(event) => onDragOver(event, row)}
      onDragStart={(event) => onDragStart(event, row)}
    >
      <Group>
        <Item halign={ItemHalign.Left}>
          <img alt="drag" className={styles.dragIcon} src={DragIcon} />
        </Item>
      </Group>
    </div>
  );

  const createItemNameCell = ({
    row,
  }: CellContext<WatchlistItemWithMetrics, string>) => (
    <>
      <span className={styles.hierarchyIcon}>
        <WatchlistItemIcon item={row.original} />
      </span>{" "}
      <div
        className={styles.nameContainer}
        data-cy="ItemName"
        onClick={() => openFastReportingTab(row)}
        onKeyUp={() => openFastReportingTab(row)}
        role="button"
        tabIndex={0}
      >
        <div>{row.original.name}</div>
        {row.original.type === WatchlistItemType.Attribute &&
        row.original.hierarchyName ? (
          <div className={styles.nameSecondary}>
            in [{row.original.hierarchyLevel}] {row.original.hierarchyName}
          </div>
        ) : (
          <> </>
        )}
      </div>
      <Group>
        <Item className={styles.deleteIcon} halign={ItemHalign.Right}>
          <Dropdown
            id={row.original.id}
            trigger={
              <StealthInlineIconButton
                data-cy="ContextMenu"
                iconGlyph={InlineIconGlyph.MenuAndSettingsMoreHoriz}
                iconSize={IconSize.Large}
                iconText=""
              />
            }
          >
            <Menu className={styles.itemMenu}>
              <MenuSection>
                <MenuItemButton
                  data-cy="DeleteButton"
                  iconStart={
                    <Icon
                      className={styles.itemMenuDeleteIcon}
                      glyph={IconGlyph.DeleteAndCloseDelete}
                      size={IconSize.Small}
                      text=""
                    />
                  }
                  onClick={() => onDeleteItems([row.original])}
                  text={
                    <Text className={styles.itemMenuDeleteText}>Delete</Text>
                  }
                />
              </MenuSection>
            </Menu>
          </Dropdown>
        </Item>
      </Group>
    </>
  );

  const createTransactionSourceCell = ({
    row,
  }: CellContext<WatchlistItemWithMetrics, unknown>) => {
    const transactionSource =
      userSelectedTransactionSource ??
      getTransactionSourceFromEntitlements(row.original.entitlements);
    return (
      <>
        {transactionSource && (
          <div className={styles.transactionSource} data-cy="TransactionSource">
            <TransactionSourceIcon
              availableTransactionSources={availableTransactionSources}
              greyedOut
              transactionSource={transactionSource}
            />
          </div>
        )}
      </>
    );
  };

  const percentagePointFormatter = (value: number | null | undefined) =>
    formatter(MetricTypes.Percentage)(value) + " pt";

  const columnHelper = createColumnHelper<WatchlistItemWithMetrics>();
  const columns = [
    columnHelper.group({
      columns: [
        columnHelper.accessor((index) => index.name, {
          cell: createActionsCell,
          header: "",
          id: "action",
        }),
      ],
      // eslint-disable-next-line react/no-unstable-nested-components
      header: () => <span className={styles.actionHeader} />,
      id: "action",
    }),
    columnHelper.group({
      columns: [
        columnHelper.accessor((index) => index.name, {
          cell: createItemNameCell,
          header: "",
          id: "itemName",
        }),
      ],
      // eslint-disable-next-line react/no-unstable-nested-components
      header: () => <span className={styles.itemHeader}>Item</span>,
      id: "item",
    }),
  ];

  if (displayEntitlements) {
    columns.push(
      columnHelper.group({
        columns: [
          columnHelper.accessor((index) => index.name, {
            cell: createTransactionSourceCell,
            header: "",
            id: "transactionSource",
          }),
        ],
        // eslint-disable-next-line react/no-unstable-nested-components
        header: () => <span className={styles.datasetHeader}>Dataset</span>,
        id: "dataset",
      })
    );
  }

  for (const metric of metrics) {
    columns.push(
      columnHelper.group({
        columns: [
          columnHelper.accessor(
            (index) => index.metrics[metric.key]?.focalPeriod,
            {
              cell: MetricData(
                refetching,
                formatter(metric.formatType),
                userSelectedTransactionSource
              ),
              header: focalPeriodTitle,
              id: metric.key + "-focal",
            }
          ),
          columnHelper.accessor(
            (index) => index.metrics[metric.key]?.comparisonPeriod,
            {
              cell: MetricDataLoading(
                refetching,
                formatter(metric.formatType),
                userSelectedTransactionSource
              ),
              header: comparisonPeriodTitle,
              id: metric.key + "-comparison",
            }
          ),
          columnHelper.accessor((index) => index.metrics[metric.key]?.growth, {
            cell: GrowthData(
              refetching,
              metric.formatType === MetricTypes.Percentage
                ? percentagePointFormatter
                : formatter(MetricTypes.Percentage),
              userSelectedTransactionSource
            ),
            header: "Growth",
            id: metric.key + "-growth",
          }),
        ],
        header: metric.displayName,
      })
    );
  }

  const table = useReactTable<WatchlistItemWithMetrics>({
    columns,
    data: items,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <table
      className={styles.watchlistTable}
      data-cy="WatchlistTable"
      style={{
        gridTemplateColumns:
          columns.length === nonMetricColumns
            ? "32px auto"
            : `32px auto repeat(${
                (columns.length - nonMetricColumns) * 3
              }, minmax(min-content, 200px))`,
      }}
    >
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th colSpan={header.colSpan} key={header.id}>
                {header.isPlaceholder ? null : (
                  <div>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </div>
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table
          .getRowModel()
          .rows.map((row, index) => {
            let column = 1;

            return (
              <tr
                data-watchlist-item-id={row.original.id}
                key={row.id}
                onDragOver={(event) => onDragOver(event, row)}
                onDrop={(event) => onDrop(event, row)}
              >
                <span
                  className={classNames(
                    styles.rowBackground,
                    draggedOverRow?.id === row.id ? styles.rowDraggedOver : ""
                  )}
                  style={{
                    gridColumnEnd: gridColumnEnd(columns.length),
                    gridRow: index + 3,
                  }}
                />

                {row.getVisibleCells().map((cell, cellIndex) => (
                  <td
                    className={classNames({
                      [styles.watchlistTableDataDelimiter]:
                        isDelimited(cellIndex),
                    })}
                    key={cell.id}
                    style={{ gridColumnStart: column++, gridRow: index + 3 }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            );
          })
          // Rows appear in the DOM in the order they are created here. This causes a problem
          // with the options dropdown as it is a child of each row but extends past the row
          // below. Items appearing later in the DOM take precedence over earlier items,
          // causing the row below to appear over the top of the dropdown. Reversing the order
          // here ensures the dropdown is always on top but does not affect the order of the
          // rows themselves as they are determined by the grid-rows property.
          // When they have been implemented across browsers we should be able to use
          // CSS subgrids to avoid this problem - https://caniuse.com/?search=subgrid
          .reverse()}
      </tbody>
    </table>
  );
};

export default WatchlistTable;
