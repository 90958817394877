import { useFormatter } from "@quantium-enterprise/hooks-ui";
import { ChartColour } from "components-ui/src/charts/ChartColours";
import {
  HighchartsReact,
  type HighchartsReactProps,
  defaultOptions,
} from "components-ui/src/charts/highcharts-react/HighchartsReact";
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import { useCallback, useEffect, useMemo, useState } from "react";
import { type DataPoint } from "../models/DataPoint";
import {
  type laddervalues,
  type Series,
} from "../models/pricing-ladders-common-models";
import {
  formatMetricValue,
  getSeriesInfo,
  plGbbToolTipFormatter,
  sortLadderValues,
} from "../utils/common-utils";

HighchartsMore(Highcharts);

export type PricingLaddersGBBChartProps = {
  bestStartValue: number;
  betterStartValue: number;
  categories: string[];
  maxValue: number;
  minValue: number;
  onOptionsChanged?: (options: HighchartsReactProps) => void;
  pricingSeries: Series[];
  secondaryMetric: string;
  showChartDataLabels?: boolean;
  sortBy: string;
  viewBy: string;
};

// eslint-disable-next-line complexity
export const PricingLaddersGoodBetterBestChart = ({
  pricingSeries,
  categories,
  secondaryMetric,
  sortBy,
  viewBy,
  minValue,
  maxValue,
  betterStartValue,
  bestStartValue,
  showChartDataLabels,
  onOptionsChanged,
}: PricingLaddersGBBChartProps) => {
  const formatter = useFormatter();

  const seriesInfo = useMemo(() => getSeriesInfo(viewBy), [viewBy]);

  const pricingSeriesToProcess = useMemo(
    () =>
      pricingSeries.filter((series) => seriesInfo.includes(series.metricLabel)),
    [pricingSeries, seriesInfo]
  );

  const format =
    pricingSeriesToProcess.find(
      (series) => series.metricLabel === seriesInfo[2]
    )?.format ?? "Currency";

  const [hiddenSeries, setHiddenSeries] = useState<number[]>([]);
  const legendItemClick = useCallback(
    (index: number) => {
      if (hiddenSeries.includes(index)) {
        setHiddenSeries(hiddenSeries.filter((number) => number !== index));
      } else {
        setHiddenSeries([...hiddenSeries, index]);
      }
    },
    [hiddenSeries]
  );

  const { maxValueInSecondaryMeasure, sortladderseries } = useMemo(() => {
    let maxValueInSecondary = Number.MIN_SAFE_INTEGER;

    // view by information
    const maxavgpricedata =
      pricingSeriesToProcess.find(
        (series) => series.metricLabel === seriesInfo[0]
      )?.data ?? [];

    const avgpricedata =
      pricingSeriesToProcess.find(
        (series) => series.metricLabel === seriesInfo[1]
      )?.data ?? [];

    const avgpromopricedata =
      pricingSeriesToProcess.find(
        (series) => series.metricLabel === seriesInfo[2]
      )?.data ?? [];

    const ladderValues: laddervalues[] = avgpricedata
      .map(({ metricValue }, index) => ({
        x: index,
        low: formatMetricValue(avgpromopricedata[index].metricValue),
        mid: metricValue,
        high: formatMetricValue(maxavgpricedata[index].metricValue),
      }))
      .filter((item) => formatMetricValue(item.mid) !== null);

    if (secondaryMetric) {
      const unavailableIndexes = avgpricedata
        .map((data, index) =>
          formatMetricValue(data.metricValue) === null ? index : undefined
        )
        .filter((item) => item !== undefined);

      const filteredAdditionalSeriesWithExtraValues = pricingSeries.find(
        (metricSeries) => metricSeries.metricLabel === secondaryMetric
      )?.data;

      const filteredAdditionalSeries =
        filteredAdditionalSeriesWithExtraValues?.filter(
          (_, index) => !unavailableIndexes.includes(index)
        );

      if (filteredAdditionalSeries) {
        for (const [index, item] of ladderValues.entries()) {
          item.secondaryvalue = filteredAdditionalSeries[index]?.metricValue;
          if (
            item.secondaryvalue &&
            item.secondaryvalue > maxValueInSecondary
          ) {
            maxValueInSecondary = item.secondaryvalue;
          }
        }
      }
    }

    return {
      maxValueInSecondaryMeasure: maxValueInSecondary,
      // sorting the laddervalues based on the selected sortBy option
      sortladderseries: sortLadderValues(
        [...ladderValues],
        sortBy,
        secondaryMetric,
        true
      ),
    };
  }, [
    pricingSeriesToProcess,
    secondaryMetric,
    sortBy,
    seriesInfo,
    pricingSeries,
  ]);

  const xindex = useMemo(
    () => sortladderseries.map((data) => data.x),
    [sortladderseries]
  );

  const {
    minLadder,
    maxLadder,
    goodSeries,
    betterSeries,
    bestSeries,
    midSeries,
  } = useMemo(() => {
    let minLadderValue = Number.MAX_SAFE_INTEGER;
    let maxLadderValue = Number.MIN_SAFE_INTEGER;

    const seriesData = {
      good: [] as DataPoint[],
      better: [] as DataPoint[],
      best: [] as DataPoint[],
      mid: [] as Array<{ x: number; y: number }>,
    };

    for (const [index, ladderValue] of sortladderseries.entries()) {
      const midValueRounded = ladderValue.mid
        ? Math.round((ladderValue.mid + Number.EPSILON) * 100) / 100
        : 0;

      const dataPoint = {
        x: index,
        low: ladderValue.low ?? midValueRounded,
        high: ladderValue.high ?? midValueRounded,
        mid: midValueRounded,
        metricValue: 0,
        toolTipAvgPrice: midValueRounded,
        toolTipAvgPromotionPrice: ladderValue.low ?? 0,
        toolTipMaxAvgPrice: ladderValue.high ?? 0,
      };

      if (midValueRounded >= minValue && midValueRounded <= betterStartValue) {
        seriesData.good.push(dataPoint);
      } else if (
        midValueRounded > betterStartValue &&
        midValueRounded <= bestStartValue
      ) {
        seriesData.better.push(dataPoint);
      } else if (
        midValueRounded > bestStartValue &&
        midValueRounded <= maxValue
      ) {
        seriesData.best.push(dataPoint);
      }

      seriesData.mid.push({ x: index, y: midValueRounded });

      minLadderValue = Math.min(midValueRounded, minLadderValue);
      maxLadderValue = Math.max(midValueRounded, maxLadderValue);
    }

    return {
      minLadder: minLadderValue,
      maxLadder: maxLadderValue,
      goodSeries: seriesData.good,
      betterSeries: seriesData.better,
      bestSeries: seriesData.best,
      midSeries: seriesData.mid,
    };
  }, [bestStartValue, betterStartValue, maxValue, minValue, sortladderseries]);

  const minValueForPrimaryAxis = minLadder - (maxLadder - minLadder) / 4;

  const gbbSeries: Series[] = useMemo(() => {
    const gbbSeriesData: Series[] = [];

    // push the good series
    gbbSeriesData.push({
      name: "Good",
      data: goodSeries,
      type: "columnrange",
      color: ChartColour.BrandCyan800,
      custom: { metricFormat: format },
      yAxis: 0,
      metricLabel: "Good",
      format,
    });

    // push the better series
    gbbSeriesData.push({
      name: "Better",
      data: betterSeries,
      type: "columnrange",
      color: ChartColour.BrandTurquoiseNew,
      custom: { metricFormat: format },
      yAxis: 0,
      metricLabel: "Better",
      format,
    });

    // push the best series
    gbbSeriesData.push({
      name: "Best",
      data: bestSeries,
      type: "columnrange",
      color: ChartColour.BrandYellowNew,
      custom: { metricFormat: format },
      yAxis: 0,
      metricLabel: "Best",
      format,
    });

    // push the mid series
    gbbSeriesData.push({
      name: seriesInfo[1],
      data: midSeries,
      type: "line",
      color: "var(--qbit-colour-text-primary)",
      custom: { metricFormat: format },
      yAxis: 0,
      metricLabel: "Average Price",
      format,
    });

    if (secondaryMetric) {
      const filteredAdditionalSeries = pricingSeries.find(
        (metricSeries) => metricSeries.metricLabel === secondaryMetric
      );

      if (filteredAdditionalSeries) {
        gbbSeriesData.push({
          name: filteredAdditionalSeries.name,
          data: sortladderseries.map((data, index) => ({
            x: index,
            y: formatMetricValue(data.secondaryvalue) ?? 0,
          })),

          type: "column",
          // static colour for secondary line series
          color: ChartColour.BrandBlue500,
          custom: {
            metricFormat: filteredAdditionalSeries.format,
          },
          metricLabel: filteredAdditionalSeries.metricLabel,
          yAxis: 1,
          format,
        });
      }
    }

    for (const [index, seriesDataItem] of gbbSeriesData.entries()) {
      seriesDataItem.visible = !hiddenSeries.includes(index);
    }

    return gbbSeriesData;
  }, [
    bestSeries,
    betterSeries,
    format,
    goodSeries,
    hiddenSeries,
    midSeries,
    pricingSeries,
    secondaryMetric,
    seriesInfo,
    sortladderseries,
  ]);

  const yAxis: Highcharts.YAxisOptions[] = useMemo(() => {
    const yAxisData: Highcharts.YAxisOptions[] = [
      {
        plotLines: [
          {
            value: 0,
            color: "var(--qbit-colour-shade-6)",
            width: 2,
            zIndex: 1,
          },
        ],
        labels: {
          formatter() {
            // eslint-disable-next-line react/no-this-in-sfc, unicorn/no-this-assignment
            const self = this;
            const value = self.value;
            const metricFormat =
              self.axis.series[0]?.userOptions.custom?.metricFormat ?? "None";
            return formatter(metricFormat)(value, false, "", true);
          },
          style: {
            color: "var(--qbit-colour-text-secondary)",
          },
        },
        title: {
          style: {
            fontWeight: "bold",
            color: "var(--qbit-colour-text-primary)",
          },
          text: "Pricing",
        },
        min: minValueForPrimaryAxis,
        max: maxLadder,
      },
    ];

    if (secondaryMetric) {
      const filteredAdditionalSeries = pricingSeries.find(
        (metricSeries) => metricSeries.metricLabel === secondaryMetric
      );

      if (filteredAdditionalSeries) {
        yAxisData.push({
          labels: {
            formatter() {
              // eslint-disable-next-line react/no-this-in-sfc, unicorn/no-this-assignment
              const self = this;
              const value = self.value;
              const metricFormat =
                self.axis.series[0]?.userOptions.custom?.metricFormat ?? "None";
              return formatter(metricFormat)(value, false, "", true);
            },
            style: {
              color: "var(--qbit-colour-text-secondary)",
            },
          },
          title: {
            style: {
              fontWeight: "bold",
              color: "var(--qbit-colour-text-primary)",
            },
            text: filteredAdditionalSeries.metricLabel,
          },
          opposite: true,
          // Shift secondary axis so secondary series is bottom 1/5 of chart
          max: maxValueInSecondaryMeasure * 5,
          min: 0,
        });
      }
    }

    return yAxisData;
  }, [
    formatter,
    maxLadder,
    maxValueInSecondaryMeasure,
    minValueForPrimaryAxis,
    pricingSeries,
    secondaryMetric,
  ]);

  const options: HighchartsReactProps = useMemo(
    () => ({
      ...defaultOptions,
      chart: {
        ...defaultOptions.chart,
        style: {
          cursor: "crosshair",
          fontFamily: "var(--qbit-font-family)",
        },
        height: "444px",
      },
      legend: {
        ...defaultOptions.legend,
        // square symbols
        symbolRadius: 0,
      },
      xAxis: {
        categories: xindex.map((index) => categories[index]),
        crosshair: {
          color: "var(--qbit-colour-chrome-background)",
          zIndex: 0,
        },
        title: {
          style: {
            color: "var(--qbit-colour-text-primary)",
          },
        },
        labels: {
          style: {
            color: "var(--qbit-colour-text-secondary)",
          },
        },
        visible: true,
      },
      // Typescript is complaining when it shouldn't. Types are ensured to be correct above anyway
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      yAxis: yAxis as any,
      tooltip: {
        ...defaultOptions.tooltip,
        ReactFormatter: (ttData) => plGbbToolTipFormatter(ttData, seriesInfo),
      },
      plotOptions: {
        column: {
          // sets width of each column by changing padding between them
          pointPadding: 0,
          stacking: "normal",
          events: {
            legendItemClick: (event) => legendItemClick(event.target.index),
          },
        },
        columnrange: {
          grouping: false,
          dataLabels: {
            // DISABLED until PricingLaddersStandardChart also works without duplicates for consistency.
            enabled: false,
          },
          events: {
            legendItemClick: (event) => legendItemClick(event.target.index),
          },
        },
        line: {
          label: {
            enabled: false,
          },
          marker: {
            enabled: true,
            symbol: "circle",
            radius: 7,
            lineColor: "white",
            lineWidth: 2,
          },

          zIndex: 2,
          events: {
            legendItemClick: (event) => legendItemClick(event.target.index),
          },
        },

        series: {
          lineWidth: 0,
          borderWidth: 0,
          cursor: "pointer",
          dataLabels: {
            enabled: showChartDataLabels,
            padding: 10,
            formatter() {
              // eslint-disable-next-line react/no-this-in-sfc, unicorn/no-this-assignment
              const self = this;
              const point = self.point;
              const metricFormat =
                point.series.userOptions.custom?.metricFormat;
              return formatter(metricFormat)(point.y, false, "", false);
            },
            style: {
              color: "var(--qbit-colour-text-primary)",
              fontFamily: "var(--qbit-font-family)",
              fontSize: "0.7rem",
              fontWeight: "var(--qbit-font-weight-medium)",
              textOutline: "white",
              cursor: "pointer",
            },
          },
        },
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      series: gbbSeries as any,
    }),
    [
      categories,
      formatter,
      gbbSeries,
      legendItemClick,
      seriesInfo,
      showChartDataLabels,
      xindex,
      yAxis,
    ]
  );

  useEffect(() => {
    if (onOptionsChanged) {
      onOptionsChanged(options);
    }
  }, [onOptionsChanged, options]);

  return <HighchartsReact options={options} />;
};
