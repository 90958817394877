import { type IconGlyph, Button, ButtonVariant, Icon, Text } from "@qbit/react";
import classNames from "classnames";
import { type PropsWithChildren } from "react";
import styles from "./TwoPanelForm.module.css";

type TwoPanelFormProps = {};

const TwoPanelForm = ({ children }: PropsWithChildren<TwoPanelFormProps>) => (
  <div className={styles.twoPanelForm}>{children}</div>
);

type HeadingProps = {
  subText: string;
  text: string;
};

const Heading = ({ subText, text }: HeadingProps) => (
  <div className={styles.headingContainer}>
    <h6 className={styles.heading}>{text}</h6>
    <Text className={styles.subHeading}>{subText}</Text>
  </div>
);

type DetailsProps = {};

const Details = ({ children }: PropsWithChildren<DetailsProps>) => (
  <div className={styles.details}>{children}</div>
);

export type DividerButton = {
  disabled: boolean;
  handleClick: () => void;
  icon: IconGlyph;
  testId: string;
  text: string;
};

type DividerProps = {
  buttons: DividerButton[];
  className?: string;
  showBar: boolean;
};

const Divider = ({ buttons, className, showBar }: DividerProps) => (
  <div className={classNames(styles.divider, className)}>
    {showBar && (
      <>
        <div className={styles.verticalBar} />
        <div className={styles.buttonGroup}>
          {buttons.map((button) => (
            <Button
              className={styles.button}
              data-testid={button.testId}
              disabled={button.disabled}
              key={button.text}
              onClick={button.handleClick}
              variant={
                button.disabled ? ButtonVariant.Link : ButtonVariant.Stealth
              }
            >
              <Icon glyph={button.icon} text={button.text} />
            </Button>
          ))}
        </div>
        <div className={styles.verticalBar} />
      </>
    )}
  </div>
);

type PanelProps = {
  className?: string;
};

const Panel = ({ className, children }: PropsWithChildren<PanelProps>) => (
  <div className={classNames(styles.panel, className)}>{children}</div>
);

const LeftPanel = ({ className, children }: PropsWithChildren<PanelProps>) => (
  <Panel className={classNames(styles.leftPanel, className)}>{children}</Panel>
);

const RightPanel = ({ className, children }: PropsWithChildren<PanelProps>) => (
  <Panel className={classNames(styles.rightPanel, className)}>{children}</Panel>
);

TwoPanelForm.Heading = Heading;
TwoPanelForm.Details = Details;
TwoPanelForm.LeftPanel = LeftPanel;
TwoPanelForm.RightPanel = RightPanel;
TwoPanelForm.Divider = Divider;

export { TwoPanelForm };
