import { Text } from "@qbit/react";
import styles from "./ShareText.module.css";

export type YouUpdatedTextProps = {
  resourceType: string;
};

export const YouUpdatedText = ({ resourceType }: YouUpdatedTextProps) => (
  <>
    <Text className={styles.boldText}>You</Text>
    <Text>{` made updates to this ${resourceType}`}</Text>
  </>
);
