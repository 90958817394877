import { ParameterId, type ParameterDto } from "@quantium-enterprise/common-ui";
import { useDispatch, useSelector } from "react-redux";
import { useAppSelector } from "../../../states/hooks";
import {
  levelOfAnalysisSingleOptionSelected,
  selectAdvancedSearchEnableState,
} from "../../../states/report-wizard-slice";
import { type RootState } from "../../../store";
import { LevelParameter } from "../../level/LevelParameter";
import { getHierarchyState } from "../HierarchyState";
import { getLevelOfAnalysisState } from "../LevelOfAnalysisState";
import styles from "./FocalLevel.module.css";

export type FocalLevelParameterProps = {
  parameterDto: ParameterDto;
};

export const FocalLevelParameter = ({
  parameterDto,
}: FocalLevelParameterProps) => {
  const dispatch = useDispatch();
  const focalLevelState = useAppSelector(
    getLevelOfAnalysisState(parameterDto.id)
  );
  const focalProductState = useAppSelector(
    getHierarchyState(ParameterId.FocalProducts)
  );

  const isAdvancedSearchEnabled = useSelector((state: RootState) =>
    selectAdvancedSearchEnableState(ParameterId.FocalProducts, state)
  );

  // Change this onChange handler once we start handling nested options.
  const onChange = (value: string) => {
    dispatch(
      levelOfAnalysisSingleOptionSelected({
        parameter: parameterDto.id,
        selectedValue: value,
      })
    );
  };

  return (
    <div
      className={`${styles.focalLevelContainer} ${
        isAdvancedSearchEnabled ? styles.advanced : ""
      }`}
    >
      <LevelParameter
        handleSelect={onChange}
        hierarchyState={focalProductState}
        levelState={focalLevelState}
        parameterDto={parameterDto}
      />
    </div>
  );
};
