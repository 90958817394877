import {
  HierarchyGroupStatus,
  FeatureFlag,
} from "@quantium-enterprise/common-ui";
import { DownloadIconForSidePanel } from "components-ui/src/assets/icons/DownloadIconForSidePanel";
import { RerunIcon } from "components-ui/src/assets/icons/RerunIcon";
import { ShareIcon } from "components-ui/src/assets/icons/ShareIcon";
import { GroupType } from "../../../../enums/group-type";
import { ActionButton } from "./ActionButton";
import { DeleteButton } from "./DeleteButton";
import { EditButton } from "./EditButton";

export type ActionButtonsProps = {
  disableRefresh: boolean;
  editGroupPath: string;
  flags: Record<string, boolean | undefined>;
  groupType: string;
  handleDownloadGroup: () => void;
  handleRefreshGroup: () => void;
  hierarchyGroupStatus?: HierarchyGroupStatus;
  isOwner: boolean;
  onDelete: () => void;
  onShare: () => void;
};

export const ActionButtons = ({
  disableRefresh,
  editGroupPath,
  flags,
  groupType,
  handleRefreshGroup,
  handleDownloadGroup,
  hierarchyGroupStatus,
  isOwner,
  onDelete,
  onShare,
}: ActionButtonsProps) => (
  <>
    {hierarchyGroupStatus !== HierarchyGroupStatus.Invalid &&
      (groupType !== GroupType.Customer ||
        flags[FeatureFlag.EditCustomerGroupButtonInfoPanel]) && (
        <EditButton editGroupPath={editGroupPath} isOwner={isOwner} />
      )}

    {hierarchyGroupStatus === HierarchyGroupStatus.Invalid && (
      <DeleteButton isOwner={isOwner} onDelete={onDelete} />
    )}

    {groupType === GroupType.Customer && (
      <ActionButton
        disabled={disableRefresh}
        icon={<RerunIcon />}
        label="Refresh"
        onClick={handleRefreshGroup}
      />
    )}

    {flags[FeatureFlag.DownloadGroups] && groupType !== GroupType.Customer && (
      <ActionButton
        disabled={hierarchyGroupStatus === HierarchyGroupStatus.Invalid}
        icon={<DownloadIconForSidePanel />}
        label="Download"
        onClick={handleDownloadGroup}
      />
    )}

    {flags[FeatureFlag.SharingGroups] && (
      <ActionButton
        disabled={
          !isOwner || hierarchyGroupStatus === HierarchyGroupStatus.Invalid
        }
        icon={<ShareIcon />}
        label="Share"
        onClick={onShare}
        tooltipText={isOwner ? "Share" : "Only owner can share"}
      />
    )}
  </>
);
