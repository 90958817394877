import { TransactionSource } from "@quantium-enterprise/common-ui";
import { Accordion } from "components-ui/src/accordion/Accordion";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ErrorBoundary from "../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import WarningBanner from "../../../../components/src/error-banner/WarningBanner";
import { type RootState } from "../../store";
import styles from "./AggregateRankReportlet.module.css";
import AggregateRankTableQueryWrapper from "./aggregate-rank-table/AggregateRankTableQueryWrapper";

export const AggregateRankReportlet = () => {
  const { customerSegment, availableDatasets, rankedMetrics, unrankedMetrics } =
    useSelector((state: RootState) => ({
      availableDatasets: state.aggregateRank.availableDatasets,
      customerSegment:
        state.aggregateRank.persistedSelections.localParametersSelections
          .Segmentation[1]?.value,
      rankedMetrics:
        state.aggregateRank.persistedSelections.modalSelections.rankedMetrics,
      unrankedMetrics:
        state.aggregateRank.persistedSelections.modalSelections.unrankedMetrics,
    }));
  const [showWarningMessage, setShowWarningMessage] = useState(false);

  useEffect(() => {
    const totalMetricSelected =
      rankedMetrics.some(
        (metric) => metric.dataset === TransactionSource.Total.toString()
      ) ||
      unrankedMetrics.some(
        (metric) => metric.dataset === TransactionSource.Total.toString()
      );
    const showWarning = Boolean(
      availableDatasets.length > 1 &&
        totalMetricSelected &&
        customerSegment &&
        customerSegment !== "All"
    );
    setShowWarningMessage(showWarning);
  }, [rankedMetrics, unrankedMetrics, availableDatasets, customerSegment]);
  return (
    <div className={styles.reportlet}>
      {showWarningMessage && (
        <WarningBanner text="Metrics based on Total data are not available when Customer segments are applied" />
      )}
      <Accordion
        subtitle="Rank the performance of items in your report using selected metrics."
        title="Aggregate rank"
      >
        <div className={styles.reportletContent}>
          <ErrorBoundary>
            <AggregateRankTableQueryWrapper />
          </ErrorBoundary>
        </div>
      </Accordion>
    </div>
  );
};

export default AggregateRankReportlet;
