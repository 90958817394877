import {
  Button,
  ButtonHeight,
  ButtonVariant,
  Icon,
  IconGlyph,
  IconSize,
  MessageVariant,
  QbitEmitToast,
  QbitToastMessage,
} from "@qbit/react";
import {
  type HierarchyItem,
  type HierarchyGroupDto,
  TrackingComponent,
  TrackingEvent,
  useEventTrackingServiceContext,
  HierarchyType,
  HierarchyGroupEvaluationType,
  formatHierarchyName,
  HierarchyGroupRuleOperator,
  GroupsTrackingProperty,
  useCreateGroupMutation,
  useUpdateGroupMutation,
  GenericTrackingProperties,
  ddLog,
  useGetItemsQuery,
  useLazyGetLeafItemsQuery,
  HierarchyItemType,
  FeatureFlag,
  AppContext,
  useLazyHierarchyMetadataQuery,
  useGetUserQuery,
  useGetEntitledSubscriptionsQuery,
  TransactionSource,
  ADVANCED_REPORTING_FEATURE_NAME,
} from "@quantium-enterprise/common-ui";
import { type SubscriptionDto } from "@quantium-enterprise/common-ui/src/models/subscription-dto";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import { type Hierarchy } from "components-ui/src/hierarchy-select-grid/models/hierarchy";
import { HierarchyGroupIcon } from "components-ui/src/icons";
import { SearchBox } from "components-ui/src/search-box/SearchBox";
import { useState, useCallback, useEffect, useMemo, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  type HierarchyGridItem,
  type HierarchyState,
} from "report-parameters-ui/src/parameters";
import { isFilterComplete } from "report-parameters-ui/src/parameters/utils/isFilterComplete";
import {
  onEntitledSubscriptionsSuccess,
  onHierarchyMetadataReceived,
  onShowAdvancedSearch,
  reset,
  selectAdvancedSearchEnableState,
  selectFilterRules,
  selectHierarchySelectedRows,
  selectSubscription,
  selectSubscriptionOptions,
  onSubscriptionChange,
  onSearchChange,
  selectHierarchyItems,
  selectSearchHasNextPage,
  selectSearchString,
} from "../../states/group-hierarchy-source-slice";
import { type RootState } from "../../store";
import {
  getFeatureFilter,
  getFeatureModules,
  getTransactionSourceFilter,
} from "../../utilities/group-subscription-utils";
import {
  getGroupCreatorPath,
  getGroupListPath,
} from "../../utilities/route-path-formats";
import { GroupSummaryBar } from "../GroupSummaryBar";
import { ParameterAdvancedSearchEditor } from "../advance-search/ParameterAdvancedSearchEditor";
import { GroupEditor } from "../group-editor/GroupEditor";
import { GroupHierarchyTableWrapper } from "../static-group-hierarchy-table/GroupHierarchyTableWrapper";
import { StaticGroupLeafsTable } from "../static-group-leafs-table/StaticGroupLeafsTable";
import { SubscriptionToggle } from "../subscription-toggle/SubscriptionToggle";
import {
  TwoPanelForm,
  type DividerButton,
} from "../two-panel-form/TwoPanelForm";
import styles from "./StaticGroupEditor.module.css";

const MAX_ITEMS_IN_GROUP = 10_000;
const MAX_ITEMS_FETCH_REQUEST = MAX_ITEMS_IN_GROUP + 1;

const { Header, BackButton, Title, Content, Footer, SaveButton } = GroupEditor;
const { Heading, Divider, LeftPanel, RightPanel } = TwoPanelForm;

const getGroupDto = (
  name: string,
  hierarchyType: HierarchyType,
  groupItemsState: HierarchyState,
  id?: string,
  subscription?: SubscriptionDto,
  shouldSaveSubscription?: boolean
): HierarchyGroupDto => ({
  id,
  evaluationType: HierarchyGroupEvaluationType.Static,
  hierarchyType,
  name,
  rules:
    groupItemsState.data.items.length > 0
      ? [
          {
            operator: HierarchyGroupRuleOperator.Is,
            shortName: groupItemsState.data.items[0].shortName,
            values: groupItemsState.data.items.map((item) => item.code),
          },
        ]
      : [],
  featureModule: shouldSaveSubscription
    ? subscription?.featureModule ?? TransactionSource.Customer.toString()
    : undefined,
  transactionSource: shouldSaveSubscription
    ? subscription?.transactionSource ?? ADVANCED_REPORTING_FEATURE_NAME
    : undefined,
});

const getDistinctHierarchyGridItems = (
  items: HierarchyGridItem[]
): HierarchyGridItem[] => {
  const distinctItems: Map<string, HierarchyGridItem> = new Map<
    string,
    HierarchyGridItem
  >();

  for (const item of items) {
    if (!distinctItems.has(item.code)) {
      distinctItems.set(item.code, item);
    }
  }

  return Array.from(distinctItems.values());
};

export type StaticGroupProps = {
  existingGroup?: HierarchyGroupDto;
  hierarchyType: HierarchyType;
};

export const StaticGroupEditor = ({
  existingGroup,
  hierarchyType,
}: StaticGroupProps) => {
  const { name: divisionName } = useDivision();
  const { data: user } = useGetUserQuery();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userState } = useContext(AppContext);

  const featureFlags = useFlags();
  const isEntitlementsFilterEnabled =
    featureFlags[FeatureFlag.ScanEnhanceProductGroups] ?? false;

  const displayEntitlements = useMemo(
    () =>
      isEntitlementsFilterEnabled &&
      userState.currentUser?.isSupplier &&
      hierarchyType === HierarchyType.Product,
    [hierarchyType, isEntitlementsFilterEnabled, userState.currentUser]
  );

  const eventTrackingService = useEventTrackingServiceContext();
  const trackingComponent =
    hierarchyType === HierarchyType.Product
      ? TrackingComponent.ProductGroup
      : TrackingComponent.LocationGroup;
  const countTrackingProperty =
    hierarchyType === HierarchyType.Product
      ? GroupsTrackingProperty.ProductCount
      : GroupsTrackingProperty.LocationCount;

  const [createGroup] = useCreateGroupMutation();
  const [updateGroup] = useUpdateGroupMutation();
  const isAdvancedSearchEnabled = useSelector((state: RootState) =>
    selectAdvancedSearchEnableState(state)
  );

  const hierarchySelectedRows = useSelector(selectHierarchySelectedRows);
  const subscription = useSelector(selectSubscription);
  const subscriptionOptions = useSelector(selectSubscriptionOptions);

  const [rhsSearchText, setRhsSearchText] = useState("");
  const [maxItemsReached, setMaxItemsReached] = useState(false);
  const filterRules = useSelector((state: RootState) =>
    selectFilterRules(state)
  );

  const handleShowAdvancedSearch = () => dispatch(onShowAdvancedSearch());

  const handleSearchChange = useCallback(
    (searchText: string) => {
      dispatch(
        onSearchChange({
          searchString: searchText,
        })
      );
    },
    [dispatch]
  );

  const [groupItemsState, setGroupItemsState] = useState({
    data: {
      items: [],
      type: hierarchyType.toString() as HierarchyType,
      disabledLevelShortNames: [],
    } as Hierarchy,
    expandedRows: [] as HierarchyItem[],
    selectedRows: [] as HierarchyItem[],
  } as HierarchyState);

  const initialGroupItems =
    existingGroup?.rules && existingGroup.rules.length > 0
      ? existingGroup.rules.flatMap((rule) =>
          rule.values.map((code) => ({ code, shortName: rule.shortName }))
        )
      : [];

  const [hasLoadedInitialItems, setHasLoadedInitialItems] = useState(false);

  const [triggerHierarchyMetadataQuery] = useLazyHierarchyMetadataQuery();

  const fetchData = useCallback(async () => {
    await triggerHierarchyMetadataQuery({
      division: divisionName,
      hierarchyType,
      getAllAttributes: true,
    })
      .unwrap()
      .then((data) => {
        dispatch(
          onHierarchyMetadataReceived({
            hierarchyMetadata: data,
          })
        );
      });
  }, [divisionName, dispatch, hierarchyType, triggerHierarchyMetadataQuery]);

  useEffect(() => {
    if (divisionName) {
      fetchData().catch((error) => ddLog("ERROR", {}, "error", error));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [divisionName]);

  const { data: entitledSubscriptions } = useGetEntitledSubscriptionsQuery(
    {
      divisionName,
      hierarchyType,
    },
    { skip: !divisionName || !displayEntitlements }
  );

  useEffect(() => {
    if (entitledSubscriptions && entitledSubscriptions.length > 0) {
      dispatch(
        onEntitledSubscriptionsSuccess({ entitledSubscriptions, existingGroup })
      );
    }
  }, [dispatch, entitledSubscriptions, existingGroup]);

  const {
    data: itemsData,
    isFetching: isGetItemsQueryFetching,
    isSuccess: isGetItemsQuerySuccess,
  } = useGetItemsQuery(
    {
      division: divisionName,
      featureModules: getFeatureModules(subscription),
      hierarchyType: hierarchyType.toString() as HierarchyType,
      payload: {
        featureFilter: getFeatureFilter(subscription),
        items: initialGroupItems,
        page: 0,
        pageSize: MAX_ITEMS_FETCH_REQUEST,
        transactionSourceFilter: getTransactionSourceFilter(subscription),
      },
    },
    {
      skip:
        !divisionName ||
        initialGroupItems.length === 0 ||
        (displayEntitlements && !subscription) ||
        hasLoadedInitialItems,
    }
  );

  const [
    triggerGetLeafItemsQuery,
    {
      currentData: leafItemsQueryData,
      isFetching: isGetLeafItemsQueryFetching,
      isSuccess: isGetLeafItemsQuerySuccess,
    },
  ] = useLazyGetLeafItemsQuery();

  const saveRequestHandler = useCallback(
    async (request: Promise<HierarchyGroupDto>, successMessage: string) => {
      let isSuccess = false;
      try {
        const groupDto = await request;

        if (!groupDto.id) {
          throw new Error("Created group ID cannot be undefined or empty.");
        }

        isSuccess = true;
        navigate(getGroupListPath(divisionName, hierarchyType, groupDto.id));
        QbitEmitToast(
          <QbitToastMessage
            content={<span />}
            heading={<h5>{successMessage}</h5>}
            showCloseButton
            showIcon
            variant={MessageVariant.Success}
          />
        );
        dispatch(reset({ hierarchyType }));
      } finally {
        eventTrackingService.trackEvent(
          trackingComponent,
          TrackingEvent.Created,
          new GenericTrackingProperties({
            [countTrackingProperty]: groupItemsState.data.items.length,
            [GroupsTrackingProperty.GroupStatus]: isSuccess
              ? "Success"
              : "Failure",
            [GroupsTrackingProperty.GroupType]:
              HierarchyGroupEvaluationType.Static,
            division: divisionName,
            user: user?.isSupplier ? "Supplier" : "Retailer",
          })
        );
      }
    },
    [
      navigate,
      divisionName,
      hierarchyType,
      dispatch,
      eventTrackingService,
      trackingComponent,
      countTrackingProperty,
      groupItemsState.data.items.length,
      user?.isSupplier,
    ]
  );

  const handleCreateGroup = useCallback(
    async (name: string) => {
      const group = getGroupDto(
        name,
        hierarchyType,
        groupItemsState,
        undefined,
        subscription,
        isEntitlementsFilterEnabled && hierarchyType === HierarchyType.Product
      );
      const request = createGroup({ divisionName, group }).unwrap();
      await saveRequestHandler(request, "Group has been created.");
    },
    [
      createGroup,
      divisionName,
      groupItemsState,
      hierarchyType,
      isEntitlementsFilterEnabled,
      saveRequestHandler,
      subscription,
    ]
  );

  const handleEditGroup = useCallback(
    async (name: string) => {
      if (!existingGroup) {
        throw new Error("Tried to edit a group that doesn't exist!");
      }

      const group = getGroupDto(
        name,
        hierarchyType,
        groupItemsState,
        existingGroup.id,
        subscription,
        isEntitlementsFilterEnabled && hierarchyType === HierarchyType.Product
      );
      const request = updateGroup({ divisionName, group }).unwrap();
      await saveRequestHandler(request, "Group has been saved.");
      dispatch(reset({ hierarchyType }));
    },
    [
      dispatch,
      divisionName,
      existingGroup,
      groupItemsState,
      hierarchyType,
      isEntitlementsFilterEnabled,
      saveRequestHandler,
      subscription,
      updateGroup,
    ]
  );

  const onRhsSearch = useCallback((searchText: string) => {
    setRhsSearchText(searchText);
  }, []);

  const appendItems = useCallback(
    (state: HierarchyState, items: HierarchyItem[]) => {
      const newItems = getDistinctHierarchyGridItems(
        state.data.items.concat(items)
      );

      if (newItems.length > MAX_ITEMS_IN_GROUP) {
        setMaxItemsReached(true);
        return state;
      } else {
        return {
          ...state,
          data: {
            ...state.data,
            items: newItems,
          },
        };
      }
    },
    []
  );

  const handleAddItems = useCallback(() => {
    if (hierarchySelectedRows.some((row) => !row.isLeaf)) {
      triggerGetLeafItemsQuery({
        division: divisionName,
        featureModules: getFeatureModules(subscription),
        hierarchyType: hierarchyType.toString() as HierarchyType,
        payload: {
          featureFilter: getFeatureFilter(subscription),
          items: hierarchySelectedRows.map((row) => ({
            code: row.code,
            shortName: row.shortName,
          })),
          page: 0,
          pageSize: MAX_ITEMS_FETCH_REQUEST,
          transactionSourceFilter: getTransactionSourceFilter(subscription),
        },
        // eslint-disable-next-line @typescript-eslint/no-loop-func
      }).catch((error) => {
        ddLog("Error adding items to static group", {}, "error", error);
      });
    } else {
      setGroupItemsState((state) => appendItems(state, hierarchySelectedRows));
    }
  }, [
    appendItems,
    divisionName,
    hierarchySelectedRows,
    hierarchyType,
    subscription,
    triggerGetLeafItemsQuery,
  ]);

  useEffect(() => {
    if (
      leafItemsQueryData &&
      !isGetLeafItemsQueryFetching &&
      isGetLeafItemsQuerySuccess
    ) {
      const selectedItems = leafItemsQueryData.results.map(
        (item) =>
          ({
            code: item.code,
            depth: item.depth,
            isLeaf: true,
            name: item.name,
            ordinal: 0,
            parent: item.parent,
            shortName: item.shortName,
            transactionSourceAccess: item.transactionSourceAccess,
            type: HierarchyItemType.Hierarchy,
          } as HierarchyItem)
      );

      setGroupItemsState((state) => appendItems(state, selectedItems));
    }
  }, [
    isGetLeafItemsQueryFetching,
    leafItemsQueryData,
    isGetLeafItemsQuerySuccess,
    appendItems,
  ]);

  useEffect(() => {
    if (isGetItemsQuerySuccess && !isGetItemsQueryFetching) {
      setGroupItemsState((state) => ({
        ...state,
        data: {
          ...state.data,
          items: itemsData.results as HierarchyGridItem[],
        },
      }));
      setHasLoadedInitialItems(true);
    }
  }, [itemsData, isGetItemsQuerySuccess, isGetItemsQueryFetching]);

  useEffect(() => {
    if (maxItemsReached) {
      eventTrackingService.trackEvent(
        trackingComponent,
        TrackingEvent.GroupsLimitReached,
        new GenericTrackingProperties({
          division: divisionName,
          user: user?.isSupplier ? "Supplier" : "Retailer",
        })
      );

      QbitEmitToast(
        <QbitToastMessage
          content={
            <span>
              You can only select a maximum of {MAX_ITEMS_IN_GROUP / 1_000}k
              products to use in a static group
            </span>
          }
          heading={<h5>Maximum reached</h5>}
          showCloseButton
          showIcon
          variant={MessageVariant.Danger}
        />
      );
    }
  }, [
    divisionName,
    eventTrackingService,
    maxItemsReached,
    trackingComponent,
    user?.isSupplier,
  ]);

  const handleRemoveItems = useCallback(() => {
    const newItems: Map<string, HierarchyItem> = new Map<
      string,
      HierarchyItem
    >();

    for (const item of groupItemsState.data.items) {
      if (!newItems.has(item.code)) {
        newItems.set(item.code, item);
      }
    }

    for (const item of groupItemsState.selectedRows) {
      newItems.delete(item.code);
    }

    setGroupItemsState({
      ...groupItemsState,
      data: {
        ...groupItemsState.data,
        items: Array.from(newItems.values()),
      },
      selectedRows: [],
    });
  }, [groupItemsState]);

  const rhsFilteredItems = useMemo(() => {
    const newItems = [...groupItemsState.data.items];

    const searchTokens = rhsSearchText
      .split(",")
      .map((token) => token.toLowerCase().trim());
    return searchTokens.length
      ? newItems.filter((item) =>
          searchTokens.some(
            (token) =>
              item.code.toLowerCase().includes(token) ||
              item.name.toLowerCase().includes(token)
          )
        )
      : newItems;
  }, [rhsSearchText, groupItemsState.data.items]);

  const rhsFilteredItemsData = useMemo(
    () => ({
      ...groupItemsState.data,
      items: rhsFilteredItems,
      isSearchResult: Boolean(rhsSearchText),
    }),
    [rhsSearchText, rhsFilteredItems, groupItemsState.data]
  );

  const searchString = useSelector((state: RootState) =>
    selectSearchString(state)
  );

  const showSearchResultMessage = useMemo(
    () =>
      searchString !== "" ||
      (isAdvancedSearchEnabled &&
        filterRules.every((rule) => isFilterComplete(rule))),
    [filterRules, isAdvancedSearchEnabled, searchString]
  );

  const hierarchyItems = useSelector((state: RootState) =>
    selectHierarchyItems(state)
  );

  const searchHasNextPage = useSelector((state: RootState) =>
    selectSearchHasNextPage(state)
  );

  const resultCountText = useMemo(() => {
    const amount = hierarchyItems.length;

    if (searchHasNextPage) {
      return `${1_000}+ matches found. Showing first ${1_000}, please refine your search further`;
    } else {
      return `${amount} match${amount === 1 ? "" : "es"} found`;
    }
  }, [searchHasNextPage, hierarchyItems.length]);

  const dividerButtons = useMemo(
    (): DividerButton[] => [
      {
        disabled: hierarchySelectedRows.length === 0,
        handleClick: handleAddItems,
        icon: IconGlyph.ArrowsNext,
        text: "Add",
        testId: "add-button",
      },
      {
        disabled: groupItemsState.selectedRows.length === 0,
        handleClick: handleRemoveItems,
        icon: IconGlyph.ArrowsBack,
        text: "Remove",
        testId: "remove-button",
      },
    ],
    [
      groupItemsState.selectedRows.length,
      handleAddItems,
      handleRemoveItems,
      hierarchySelectedRows.length,
    ]
  );

  const handleChangeSubscription = useCallback(
    (currentState: HierarchyState, option: SubscriptionDto) => {
      setGroupItemsState({
        ...currentState,
        data: {
          ...currentState.data,
          items: [],
        },
        selectedRows: [],
      });

      dispatch(onSubscriptionChange(option));
    },
    [dispatch]
  );

  return (
    <GroupEditor>
      <Header>
        <BackButton
          onClick={() => {
            dispatch(reset({ hierarchyType }));
          }}
          returnPath={
            existingGroup
              ? getGroupListPath(divisionName, hierarchyType, existingGroup.id)
              : getGroupCreatorPath(divisionName, hierarchyType)
          }
          showExitDialog
        />
        <Title
          icon={
            <HierarchyGroupIcon
              evaluationType={HierarchyGroupEvaluationType.Static}
              hierarchyType={hierarchyType}
            />
          }
          subtitle="Static"
          title={`Create a ${formatHierarchyName(
            hierarchyType,
            false,
            false
          )} group`}
        />
      </Header>
      <Content>
        <TwoPanelForm>
          <Heading
            subText={`Select ${formatHierarchyName(
              hierarchyType,
              false,
              true
            )} from the ${formatHierarchyName(
              hierarchyType,
              false,
              false
            )} hierarchy. Move your selections to the ‘Selected’ column.`}
            text={`${formatHierarchyName(
              hierarchyType,
              true,
              false
            )} hierarchy`}
          />
          <GroupSummaryBar
            existingGroup={existingGroup}
            groupType={HierarchyGroupEvaluationType.Static}
            hierarchyType={HierarchyType.Product}
            itemsCount={groupItemsState.data.items.length}
            subscription={subscription}
          />
          <LeftPanel>
            <div
              className={
                showSearchResultMessage
                  ? styles.searchFilterRow
                  : styles.emptySearchFilterRow
              }
            >
              <div className={styles.searchControls}>
                <SubscriptionToggle
                  handleChangeSubscription={(option: SubscriptionDto) =>
                    handleChangeSubscription(groupItemsState, option)
                  }
                  hideToggle={subscriptionOptions.length < 2}
                  ignoreDialog={groupItemsState.data.items.length === 0}
                  selectedSubscription={subscription}
                  subscriptionOptions={subscriptionOptions}
                />
                <SearchBox
                  debounceTimeMs={500}
                  enableDebounce
                  onChange={handleSearchChange}
                  placeholder={`Search ${formatHierarchyName(
                    hierarchyType,
                    false,
                    true
                  )}`}
                  searchQuery={searchString}
                  testId="lhs-search"
                />
                <Button
                  height={ButtonHeight.XSmall}
                  onClick={handleShowAdvancedSearch}
                  variant={ButtonVariant.Stealth}
                >
                  <Icon
                    glyph={IconGlyph.SortAndViewFilter}
                    size={IconSize.Small}
                    text="Filter"
                  />
                  <span>Filter</span>
                </Button>
              </div>
              {showSearchResultMessage && (
                <div className={styles.resultCount}>{resultCountText}</div>
              )}
            </div>
            {isAdvancedSearchEnabled && (
              <div data-testid="parameter-advanced-search">
                <ParameterAdvancedSearchEditor
                  enableAdvancedSearch={handleShowAdvancedSearch}
                  featureFilter={getFeatureFilter(subscription)}
                  featureModules={getFeatureModules(subscription)}
                  hierarchyType={hierarchyType}
                  transactionSourceFilter={getTransactionSourceFilter(
                    subscription
                  )}
                />
              </div>
            )}

            <div className={styles.table}>
              <GroupHierarchyTableWrapper
                displayEntitlements={displayEntitlements}
                hierarchyType={hierarchyType}
              />
            </div>
          </LeftPanel>
          <Divider
            buttons={dividerButtons}
            className={styles.staticGroupDivider}
            showBar
          />
          <RightPanel>
            <div
              className={styles.groupEditorSearchRight}
              data-testid="rhs-search"
              id={styles.rhsSearch}
            >
              <SearchBox
                debounceTimeMs={500}
                enableDebounce
                onChange={onRhsSearch}
                placeholder={`Search ${formatHierarchyName(
                  hierarchyType,
                  false,
                  true
                )}`}
                resultCount={
                  rhsSearchText === ""
                    ? undefined
                    : rhsFilteredItemsData.items.length
                }
              />
            </div>
            {/* Migrate tests and make sure tests still work */}
            {/* <div data-testid="rhs-table"> */}
            {/* {isGetItemsQueryFetching && (
                  <div className={styles.loading}>
                    <Spinner />
                  </div>
                )} */}

            <div className={styles.table} data-testid="rhs-table">
              <StaticGroupLeafsTable
                data={rhsFilteredItemsData}
                displayEntitlements={displayEntitlements}
                hierarchyType={formatHierarchyName(hierarchyType, true, false)}
                isLoading={isGetLeafItemsQueryFetching}
                onSelectionsChange={(selectedItems) => {
                  setGroupItemsState({
                    ...groupItemsState,
                    selectedRows: selectedItems,
                  });
                }}
                searchQuery={rhsSearchText}
                selectedItems={groupItemsState.selectedRows}
              />
            </div>
          </RightPanel>
        </TwoPanelForm>
      </Content>
      <Footer>
        <SaveButton
          disabled={
            !groupItemsState.data.items.length || isGetLeafItemsQueryFetching
          }
          groupTypeIcon={
            <HierarchyGroupIcon
              evaluationType={HierarchyGroupEvaluationType.Static}
              hierarchyType={hierarchyType}
            />
          }
          groupTypeName="Static"
          handleCreateGroup={handleCreateGroup}
          handleEditGroup={existingGroup ? handleEditGroup : undefined}
          initialGroupName={existingGroup?.name}
        />
      </Footer>
    </GroupEditor>
  );
};
