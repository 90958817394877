import {
  Button,
  ButtonHeight,
  ButtonVariant,
  Group,
  GroupRowspace,
  IconSize,
  InlineIcon,
  InlineIconGlyph,
  Item,
} from "@qbit/react";
import classNames from "classnames";
import { type ReactElement } from "react";
import { Link } from "react-router-dom";
import MiniBarChartIcon from "../assets/common/mini-bar-chart-icon.svg";
import { InformationModal } from "../information-modal/InformationModal";
import styles from "./CreatorTile.module.css";

export enum CreatorTileTestIds {
  Label = "creator-tile-label",
  Tag = "creator-tile-tag",
  Tile = "creator-tile",
}

export enum CreatorTileTags {
  Advanced = "Advanced",
}

const AdvancedTag = () => (
  <div className={styles.tileTag}>
    <img alt="Bar chart" src={MiniBarChartIcon} />
    <span data-testid={CreatorTileTestIds.Tag}>Advanced</span>
  </div>
);

const Tags: Record<CreatorTileTags, ReactElement> = {
  [CreatorTileTags.Advanced]: <AdvancedTag />,
};

type CreatorTileProps = {
  buttonText: string;
  detailedDescription?: string;
  disabled: boolean;
  icon: JSX.Element;
  infoHeader?: string;
  label: string;
  learnMore?: string;
  moreInfo?: string;
  path?: string;
  showGradient?: boolean;
  tag?: CreatorTileTags;
};

export const CreatorTile = ({
  buttonText,
  detailedDescription,
  moreInfo,
  disabled,
  icon,
  infoHeader,
  label,
  path,
  showGradient,
  tag,
  learnMore,
}: CreatorTileProps) => (
  <Item>
    <Group className={styles.tileContainer} rowspace={GroupRowspace.XSmall}>
      <Item
        className={classNames(styles.tile, {
          [styles.blueGradient]: showGradient && !disabled,
          [styles.tileShadow]: !disabled,
        })}
        data-testid={CreatorTileTestIds.Tile}
      >
        {/* Checkbox, not required yet */}
        {/* <Checkbox
            assistiveLabel
            checked={checked}
            className={styles.checkbox}
            label={label + "creator tile"}
            name={label + "checkbox"}
            onChange={() => {}}
          /> */}
        {/* Pin, not required yet */}
        {/* <Button className={styles.pinButton} variant={ButtonVariant.Stealth}>
            <Icon
              className={styles.pin}
              glyph={IconGlyph.PinAndLocationPin}
              size={IconSize.Small}
              text="pin"
            />
          </Button> */}
        <div className={styles.mainIcon}>{icon}</div>
        <>
          {!disabled && path && (
            <Link to={path}>
              <Button
                className={styles.centreButton}
                height={ButtonHeight.XSmall}
                text={buttonText}
                variant={ButtonVariant.Primary}
              />
            </Link>
          )}
        </>
        {tag ? Tags[tag] : <> </>}
      </Item>
      <Item
        className={classNames(styles.textAndIcon, {
          [styles.underline]: !disabled,
        })}
      >
        <span
          className={styles.tileLabel}
          data-testid={CreatorTileTestIds.Label}
        >
          {label}
        </span>
        {/* To be replaced with toggletip component  */}
        {detailedDescription ? (
          <InformationModal
            creationType={infoHeader?.toLowerCase() ?? "report"}
            detailedDescriptionMarkdown={detailedDescription}
            header={infoHeader ?? label}
            label={label}
            learnMore={learnMore}
            moreInfoMarkdown={disabled ? "" : moreInfo}
            path={path}
          />
        ) : (
          <InlineIcon
            glyph={InlineIconGlyph.AlertsAndNotificationsHelpCircleOutline}
            size={IconSize.Small}
            text={label}
          />
        )}
      </Item>
    </Group>
  </Item>
);
