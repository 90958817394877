import {
  ParameterId,
  type HierarchyValue,
  type LocalSelectedValueDto,
} from "@quantium-enterprise/common-ui";
import { type BreakdownData } from "../../models/GainsAndLossBreakdownMiniChartResponseDto";
import { getGainsAndLossChartColour } from "../../models/GainsAndLossChartColours";
import { MetricIdTypes } from "../../models/GainsAndLossReportValues";
import { type GainsAndLossSwitchingBreakdownExportRequestDto } from "../../models/GainsAndLossSwitchingBreakdownExportRequestDto";
import {
  type SwitchingBreakdownData,
  type GainsAndLossSwitchingBreakdownResponseDto,
} from "../../models/GainsAndLossSwitchingBreakdownReportletDto";
import { type GainsAndLossSwitchingBreakdownRequestDto } from "../../models/GainsAndLossSwitchingBreakdownRequestDto";

export const TOP_ITEMS_COUNT = 15;
export const MINI_CHART_HEIGHT = 250;
export const BREAKDOWN_TYPES = [
  MetricIdTypes.Net,
  MetricIdTypes.Gains,
  MetricIdTypes.Loss,
];

export const createSwitchingBreakdownReportletPayload = (
  reportId: string,
  focalItem: HierarchyValue,
  channel: string,
  compStore: string,
  location: string
): GainsAndLossSwitchingBreakdownRequestDto => {
  const localSelectedValues: LocalSelectedValueDto[] = [
    {
      id: ParameterId.Channel,
      values: [channel],
    },
    {
      id: ParameterId.CompStore,
      values: [compStore],
    },
    {
      id: ParameterId.LocationHierarchy,
      values: [location],
    },
  ];

  return {
    focalItem,
    localSelectedValues,
    reportId,
  };
};

export const createSwitchingBreakdownExportPayload = (
  reportId: string,
  focalItem: HierarchyValue,
  channel: string,
  compstore: string,
  location: string,
  segmentation: string,
  metric: string,
  filename: string,
  filterByLargestGainLosses: boolean,
  localParametersSummary: Array<{
    name: string;
    value: string;
  }>
): GainsAndLossSwitchingBreakdownExportRequestDto => {
  const localSelectedValues: LocalSelectedValueDto[] = [
    {
      id: ParameterId.Channel,
      values: [channel],
    },
    {
      id: ParameterId.LocationHierarchy,
      values: [location],
    },
    {
      id: ParameterId.Segmentation,
      values: [segmentation],
    },
    {
      id: ParameterId.Metric,
      values: [metric],
    },
    {
      id: ParameterId.CompStore,
      values: [compstore],
    },
  ];

  const requestObject = {
    localSelectedValues,
    reportId,
    localParametersSummary,
    filename,
    focalItem,
    filterByLargestGainLosses,
  };
  return requestObject;
};

export const getSwitchingBreakdownForMeasure = (
  switchingBreakdownItems: GainsAndLossSwitchingBreakdownResponseDto[],
  measure: string
): GainsAndLossSwitchingBreakdownResponseDto => {
  const data = switchingBreakdownItems.find(
    (items) => items.measureName === measure
  );
  return data ? data : { format: "", items: [], measureName: "" };
};

export const sortSwitchingItems = (
  switchingData: GainsAndLossSwitchingBreakdownResponseDto
) => {
  const itemsCopy = switchingData.items.slice();
  itemsCopy.sort((a, b) => b.impact - a.impact);

  return { ...switchingData, items: itemsCopy };
};

export const getLargestGainsLosses = (
  items: SwitchingBreakdownData[],
  halfCount: number
) => {
  const gains = items.filter((item) => item.impact > 0);
  const losses = items.filter((item) => item.impact < 0);

  let gainsCount = halfCount;
  let lossesCount = halfCount;

  if (gains.length < halfCount) {
    lossesCount += halfCount - gains.length;
  }

  if (losses.length < halfCount) {
    gainsCount += halfCount - losses.length;
  }

  const filteredItems = gains
    .slice(0, gainsCount)
    .concat(
      losses.slice(Math.max(losses.length - lossesCount, 0), losses.length)
    );

  return filteredItems;
};

export const getBreakdownMiniChartColumns = (breakdownData: BreakdownData[]) =>
  BREAKDOWN_TYPES.map((breakdownName) => {
    const gainsAndLossValues = breakdownData.find(
      (x) => x.name === breakdownName
    );

    return gainsAndLossValues
      ? {
          color: getGainsAndLossChartColour[gainsAndLossValues.name],
          name: gainsAndLossValues.name,
          y:
            gainsAndLossValues.name === MetricIdTypes.Loss &&
            gainsAndLossValues.data &&
            gainsAndLossValues.data > 0
              ? -gainsAndLossValues.data
              : gainsAndLossValues.data,
        }
      : {
          color: getGainsAndLossChartColour[breakdownName],
          name: breakdownName,
          y: 0,
        };
  });
