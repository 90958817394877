import {
  type KeyDriverTreeRequest,
  type KeyDriverTreeResponse,
} from "../models/keyDriverTreeDtos";
import { rangePerformanceApi } from "./range-performance-api-slice";

export const keyDriverTreeApi = rangePerformanceApi.injectEndpoints({
  endpoints: (builder) => ({
    keyDriverTreeMeasures: builder.query<
      KeyDriverTreeResponse,
      { divisionName: string; payload: KeyDriverTreeRequest }
    >({
      query: ({ divisionName, payload }) => ({
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        method: "POST",
        url: `${divisionName}/key-driver-tree`,
      }),
    }),
  }),
});

export const { useKeyDriverTreeMeasuresQuery } = keyDriverTreeApi;
