import {
  ButtonToggleGroup,
  type ToggleButton,
} from "components-ui/src/button-toggle-group/ButtonToggleGroup";
import { useState, type Key } from "react";

export type ParameterSelectorToggleGroupProps<TItem> = {
  defaultSelection: TItem;
  itemDisplaySelector: (item: TItem) => string;
  itemKeySelector: (item: TItem) => Key;
  items: TItem[];
  onSelectionChanged: (items: TItem[]) => void;
};

export const ParameterSelectorToggleGroup = <T,>({
  items,
  defaultSelection,
  onSelectionChanged,
  itemDisplaySelector,
  itemKeySelector,
}: ParameterSelectorToggleGroupProps<T>) => {
  const [selected, setSelected] = useState<T>(defaultSelection);

  const tabButtons: Array<ToggleButton<Key>> = items.map((item) => ({
    displayText: itemDisplaySelector(item),
    id: itemKeySelector(item),
  }));

  const onButtonSelected = (buttonSelected: Key) => {
    const selectedItem =
      items.find((item) => itemKeySelector(item) === buttonSelected) ??
      defaultSelection;
    setSelected(selectedItem);
    onSelectionChanged([selectedItem]);
  };

  return (
    <ButtonToggleGroup
      buttonSelected={itemKeySelector(selected)}
      buttons={tabButtons}
      setButtonSelected={onButtonSelected}
    />
  );
};

export default ParameterSelectorToggleGroup;
