/* eslint-disable react/no-array-index-key */
import {
  EmptyState,
  Group,
  GroupGutters,
  IllustrationSize,
  Item,
  ItemHalign,
  ItemValign,
  ItemWidth,
  Spinner,
  SpinnerSize,
  SpotColour,
  StateType,
  Text,
} from "@qbit/react";
import {
  type HierarchyGroupEvaluationType,
  HierarchyShortName,
  type TransactionSource,
  SearchTrackingProperties,
  SearchTrackingProperty,
  TrackingComponent,
  TrackingEvent,
  useEventTrackingServiceContext,
  useGetUserQuery,
  getTransactionSourceFromEntitlements,
  FeatureFlag,
  FAST_REPORTING_FEATURE_NAME,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import {
  type CellContext,
  type RowSelectionState,
  type ColumnDef,
  type Row,
} from "@tanstack/react-table";
import classNames from "classnames";
import { TransactionSourceIcon } from "components-ui/src/icons/transaction-source-icon/TransactionSourceIcon";
import { DEFAULT_COLUMN_WIDTH } from "components-ui/src/tables/common/constants";
import {
  ExpandableNameCell,
  ExpandableNameCellClickTarget,
} from "components-ui/src/tables/common/table-cell/ExpandableNameCell";
import {
  type LoadMoreRow,
  ReportHierarchyTable,
  isLoadMoreRow,
} from "components-ui/src/tables/report-hierarchy-table/ReportHierarchyTable";
import { useCallback, useEffect, useMemo, useState } from "react";
import DebouncedSearchBar from "./DebouncedSearchBar";
import styles from "./FocalItemSearchLayout.module.css";
import { type HierarchySearchLevel } from "./HierarchySearchLevel";
import {
  getUniqueKey,
  isSelectableAttributeItem,
  isSelectableAttributeLevelItem,
  isSelectableHierarchyItem,
  isSelectableProductGroupItem,
  type SelectableItem,
} from "./SelectableItem";
import {
  type SearchStrategy,
  SearchStrategyStatus,
} from "./search-strategies/SearchStrategy";

type FocalItemSearchLayoutProps = {
  availableSearchLevels: HierarchySearchLevel[];
  disableSelecting?: boolean;
  disableSelectingTooltip?: string;
  onFocalItemClicked?: (item: SelectableItem) => void;
  onSearchLevelChanged: (level: HierarchySearchLevel) => void;
  onSearchQueryChanged?: (query: string) => void;
  onSelectedItem: (item: SelectableItem, isSelected: boolean) => void;
  searchLevel: HierarchySearchLevel;
  searchQuery?: string;
};

const FocalItemSearchLayout = ({
  searchLevel,
  availableSearchLevels,
  onSelectedItem,
  searchQuery,
  onSearchQueryChanged,
  onFocalItemClicked,
  onSearchLevelChanged,
  disableSelecting = false,
  disableSelectingTooltip = "",
}: FocalItemSearchLayoutProps) => {
  const productNameColumnWidth = 400;
  const transactionSourceColumnWidth = 40;
  const eventTrackingService = useEventTrackingServiceContext();
  const { data: user } = useGetUserQuery();
  const { transactionSources: availableTransactionSources } = useDivision();
  const flags = useFlags();
  const scanFeatureEntitlementsEnabled =
    flags[FeatureFlag.ScanFeatureEntitlements] ?? false;

  const displayEntitlements = useMemo(() => user?.isSupplier, [user]);

  const [hierarchySearchItems, setHierarchySearchItems] = useState<
    SelectableItem[]
  >([]);

  const [currentSearchStatus, setCurrentSearchStatus] =
    useState<SearchStrategyStatus>(SearchStrategyStatus.Uninitialized);

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const [expandedItems, setExpandedItems] = useState<SelectableItem[]>([]);

  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");
  useEffect(() => {
    searchLevel.searchStrategy.setSearchQuery(debouncedSearchQuery);
  }, [searchLevel, debouncedSearchQuery]);

  const searchQueryChangedHandler = (query: string) => {
    setDebouncedSearchQuery(query);
    if (onSearchQueryChanged) {
      onSearchQueryChanged(query);
    }
  };

  const isHierarchySearchLevelActive = (name: string): boolean =>
    searchLevel.name === name;

  const onSearchStrategyItemsChanged = (
    strategy: SearchStrategy,
    items: SelectableItem[]
  ) => {
    if (strategy === searchLevel.searchStrategy) {
      setHierarchySearchItems(items);
    }
  };

  const onExpandedItemsChanged = (
    strategy: SearchStrategy,
    items: SelectableItem[]
  ) => {
    if (strategy === searchLevel.searchStrategy) {
      setExpandedItems(items);
    }
  };

  const onSearchStrategyStatusChanged = (
    strategy: SearchStrategy,
    status: SearchStrategyStatus
  ) => {
    if (strategy === searchLevel.searchStrategy) {
      setCurrentSearchStatus(status);
    }
  };

  for (const level of availableSearchLevels) {
    level.searchStrategy.onItemsChanged = (items) => {
      onSearchStrategyItemsChanged(level.searchStrategy, items);
    };

    level.searchStrategy.onStatusChanged = (status) => {
      onSearchStrategyStatusChanged(level.searchStrategy, status);
    };

    level.searchStrategy.onExpandedItemsChanged = (items) => {
      onExpandedItemsChanged(level.searchStrategy, items);
    };

    if (
      level.searchStrategy.getStatus() === SearchStrategyStatus.Uninitialized
    ) {
      level.searchStrategy.initialize();
    }
  }

  const onSelectedHierarchyLevel = (level: HierarchySearchLevel) => {
    onSearchLevelChanged(level);
    eventTrackingService.trackEvent(
      TrackingComponent.WatchlistItem,
      TrackingEvent.Searched,
      SearchTrackingProperties.search(
        SearchTrackingProperty.TabSelected,
        level.name
      )
    );
  };

  useEffect(() => {
    setHierarchySearchItems(searchLevel.searchStrategy.getItems());
    setCurrentSearchStatus(searchLevel.searchStrategy.getStatus());
    setExpandedItems(searchLevel.searchStrategy.getExpandedItems());
  }, [searchLevel]);

  // a row can expand if it has subrows already loaded, or if it is specified that there are loadable child rows from the server
  // it must also not be an attribute level row or a result of a search
  const canLazyExpand = useCallback(
    (row: Row<SelectableItem>): boolean =>
      (debouncedSearchQuery === "" &&
        isSelectableHierarchyItem(row.original) &&
        row.original.hasLoadableChildRows) ||
      Boolean(row.original.subRows?.length) ||
      isSelectableAttributeItem(row.original),
    [debouncedSearchQuery]
  );

  // This returns a function that runs on clicking the expand/collapse
  const expandHandler = useCallback(
    (row: Row<SelectableItem>) => () => {
      if (!row.getCanExpand()) {
        return;
      }

      const toggleExpanded = !row.getIsExpanded();

      row.toggleExpanded(toggleExpanded);

      if (!toggleExpanded) {
        searchLevel.searchStrategy.unexpandItem(row.original);
      }

      // if it is an attribute level row, dont do anything
      if (isSelectableAttributeLevelItem(row.original)) {
        return;
      }

      // if its already expanded, don't do anything
      if (row.getIsExpanded()) {
        return;
      }

      searchLevel.searchStrategy.expandItem(row.original);
    },
    [searchLevel]
  );

  const selectHandler = useCallback(
    (row: Row<SelectableItem>) => (event: unknown) => {
      const toggleSelected = !row.getIsSelected();
      onSelectedItem(row.original, toggleSelected);
      row.getToggleSelectedHandler()(event);
    },
    [onSelectedItem]
  );

  const moreHandler = async (row: Row<SelectableItem>) =>
    searchLevel.searchStrategy.loadMore(
      (row.original as LoadMoreRow<SelectableItem>).loadMoreParentRow
    );

  const expandedHierarchyItems: Record<string, boolean> = {};
  for (const item of expandedItems) {
    expandedHierarchyItems[getUniqueKey(item)] = true;
  }

  const getEntitlements = useCallback(
    (row: Row<SelectableItem>): TransactionSource[] => {
      if (isSelectableAttributeItem(row.original)) {
        return row.original.additionalHierarchyFilter?.entitlements ?? [];
      } else if (
        isSelectableHierarchyItem(row.original) ||
        isSelectableAttributeLevelItem(row.original)
      ) {
        return row.original.entitlements;
      } else {
        return [];
      }
    },
    []
  );

  const getShortName = useCallback((row: Row<SelectableItem>): string => {
    if (isSelectableProductGroupItem(row.original)) {
      return HierarchyShortName.ProductGroup;
    } else if (
      isSelectableAttributeItem(row.original) ||
      isSelectableAttributeLevelItem(row.original) ||
      isSelectableHierarchyItem(row.original)
    ) {
      return row.original.shortName;
    } else {
      return "";
    }
  }, []);

  const getProductGroupEvaluationType = useCallback(
    (row: Row<SelectableItem>): HierarchyGroupEvaluationType | undefined => {
      if (isSelectableProductGroupItem(row.original)) {
        return row.original.evaluationType;
      }

      return undefined;
    },
    []
  );

  const isUnentitled = useCallback(
    (
      row: Row<SelectableItem>,
      transactionSource: TransactionSource | undefined
    ) =>
      displayEntitlements &&
      (isSelectableHierarchyItem(row.original) ||
        isSelectableAttributeLevelItem(row.original)) &&
      transactionSource === undefined,
    [displayEntitlements]
  );

  const isFastReportingFeatureEnabled = useCallback(
    (row: Row<SelectableItem>): boolean => {
      if (scanFeatureEntitlementsEnabled) {
        if (
          isSelectableHierarchyItem(row.original) ||
          isSelectableAttributeLevelItem(row.original)
        ) {
          return (
            row.original.features?.[FAST_REPORTING_FEATURE_NAME] !== undefined
          );
        } else if (isSelectableProductGroupItem(row.original)) {
          // All product groups currently should have access for Fast Reporting
          return true;
        } else {
          // this wouldn't really happen, but its a catch all just incase logic fails
          // Rather restrict access then give access by mistake
          return false;
        }
      } else {
        return true;
      }
    },
    [scanFeatureEntitlementsEnabled]
  );

  const productsCell = useCallback(
    ({ row, getValue }: CellContext<SelectableItem, unknown>) => {
      const entitlements = getEntitlements(row);
      const shortName = getShortName(row);
      const transactionSource =
        getTransactionSourceFromEntitlements(entitlements);
      const productGroupEvaluationType = getProductGroupEvaluationType(row);
      const unentitled = isUnentitled(row, transactionSource);
      const fastReportingFeatureEnabled = isFastReportingFeatureEnabled(row);

      return (
        <ExpandableNameCell
          canExpand={canLazyExpand(row)}
          checkboxDisabledTooltip={
            unentitled || !fastReportingFeatureEnabled
              ? "This hierarchy level is not available based on your entitlements."
              : disableSelectingTooltip
          }
          clickTarget={ExpandableNameCellClickTarget.NameAndHierarchyIcon}
          depth={row.depth}
          handleToggleExpanded={expandHandler(row)}
          handleToggleSelected={selectHandler(row)}
          isCheckboxDisabled={
            unentitled ||
            !fastReportingFeatureEnabled ||
            (disableSelecting && !row.getIsSelected())
          }
          isCheckboxHidden={isSelectableAttributeItem(row.original)}
          isCompact
          isExpanded={row.getIsExpanded()}
          isExpanding={
            (isSelectableHierarchyItem(row.original) ||
              isSelectableAttributeItem(row.original)) &&
            row.original.isExpanding
          }
          isSelected={row.getIsSelected()}
          name={row.original.name}
          onClick={() => {
            if (
              onFocalItemClicked &&
              !unentitled &&
              fastReportingFeatureEnabled
            ) {
              onFocalItemClicked(row.original);
            }
          }}
          productGroupEvaluationType={productGroupEvaluationType}
          shortName={shortName}
          suffix={
            isSelectableHierarchyItem(row.original) ||
            (isSelectableAttributeItem(row.original) && row.getIsExpanded())
              ? row.original.suffix
              : undefined
          }
          type={row.original.type}
          value={String(getValue())}
        />
      );
    },
    [
      getEntitlements,
      getProductGroupEvaluationType,
      getShortName,
      isFastReportingFeatureEnabled,
      isUnentitled,
      canLazyExpand,
      expandHandler,
      selectHandler,
      onFocalItemClicked,
      disableSelecting,
      disableSelectingTooltip,
    ]
  );

  const transactionSourceHeader = useCallback(() => <span>Dataset</span>, []);

  const transactionSourceCell = useCallback(
    ({ row }: CellContext<SelectableItem, unknown>) => {
      let transactionSource: TransactionSource | undefined;
      if (
        isSelectableHierarchyItem(row.original) ||
        isSelectableAttributeLevelItem(row.original)
      ) {
        transactionSource = getTransactionSourceFromEntitlements(
          row.original.entitlements
        );
      }

      if (isSelectableAttributeItem(row.original) && row.getIsSelected()) {
        transactionSource = getTransactionSourceFromEntitlements(
          row.original.additionalHierarchyFilter?.entitlements ?? []
        );
      }

      return (
        <div className={styles.transactionSourceCell}>
          <TransactionSourceIcon
            availableTransactionSources={availableTransactionSources}
            greyedOut={!row.getIsSelected()}
            transactionSource={transactionSource}
          />
        </div>
      );
    },
    [availableTransactionSources]
  );
  const columns = useMemo<Array<ColumnDef<SelectableItem>>>(
    () =>
      displayEntitlements &&
      (isSelectableHierarchyItem(hierarchySearchItems[0]) ||
        isSelectableAttributeItem(hierarchySearchItems[0]))
        ? [
            {
              accessorFn: (row) => row.name,
              cell: productsCell,
              header: "Search results",
              size: productNameColumnWidth,
            },
            {
              accessorKey: "transactionSource",
              cell: transactionSourceCell,
              header: transactionSourceHeader,
              size: transactionSourceColumnWidth,
            },
          ]
        : [
            {
              accessorFn: (row) => row.name,
              cell: productsCell,
              header: "Search results",
            },
          ],
    [
      hierarchySearchItems,
      displayEntitlements,
      productsCell,
      transactionSourceCell,
      transactionSourceHeader,
    ]
  );

  return (
    <div className={styles.focalItemSearchSearch}>
      <DebouncedSearchBar
        disabled={!searchLevel.flatSearchEnabled}
        initialQuery={searchQuery}
        onChanged={searchQueryChangedHandler}
      />
      <Group className={styles.focalItemSearch} gutters={GroupGutters.XSmall}>
        <Item
          className={classNames(styles.addItemsTitle, styles.searchLevels)}
          halign={ItemHalign.Left}
          width={ItemWidth.Fit}
        >
          {availableSearchLevels.map((sl, id) => (
            <div
              data-testid="search-level"
              key={id}
              onClick={() => onSelectedHierarchyLevel(sl)}
              onKeyUp={() => onSelectedHierarchyLevel(sl)}
              role="button"
              tabIndex={0}
            >
              <Item
                className={classNames(styles.focalItemSearchSearchLevel, {
                  [styles.focalItemSearchSearchLevelActive]:
                    isHierarchySearchLevelActive(sl.name),
                })}
                valign={ItemValign.Middle}
                width={ItemWidth.Fill}
              >
                <Text
                  className={classNames(styles.focalItemSearchSearchLevelText, {
                    [styles.focalItemSearchSearchLevelActiveText]:
                      isHierarchySearchLevelActive(sl.name),
                  })}
                >
                  {sl.name}
                </Text>
              </Item>
            </div>
          ))}
        </Item>
        <Item halign={ItemHalign.Right} width={ItemWidth.Fill}>
          <div className={styles.focalItemSearchGridWrapper}>
            {currentSearchStatus === SearchStrategyStatus.Success && (
              <>
                {hierarchySearchItems.length > 0 ? (
                  <ReportHierarchyTable
                    className={styles.table}
                    columnResizeMode="onChange"
                    columns={columns}
                    data={hierarchySearchItems}
                    defaultColumnWidthOverride={
                      displayEntitlements
                        ? {
                            minSize: transactionSourceColumnWidth,
                            size: DEFAULT_COLUMN_WIDTH,
                            maxSize: productNameColumnWidth,
                          }
                        : undefined
                    }
                    getRowId={(row) => {
                      if (isLoadMoreRow(row)) {
                        const loadMoreRow = row as LoadMoreRow<SelectableItem>;
                        return (
                          getUniqueKey(loadMoreRow.loadMoreParentRow) +
                          "-load-more"
                        );
                      }

                      return getUniqueKey(row);
                    }}
                    getSubRows={(row) => {
                      if (
                        isSelectableHierarchyItem(row) ||
                        isSelectableAttributeItem(row)
                      ) {
                        return row.subRows;
                      }

                      return [];
                    }}
                    handleMoreClicked={moreHandler}
                    lazyLoadEnabled
                    moreText="Load More...."
                    rowExpandedState={expandedHierarchyItems}
                    rowSelectionState={rowSelection}
                    setRowSelection={setRowSelection}
                  />
                ) : (
                  <div className={styles.emptyStateContainer}>
                    <EmptyState
                      description="Please try modifying your search term"
                      heading="No result found"
                      size={IllustrationSize.Large}
                      spotColour={SpotColour.Theme}
                      stateType={StateType.Search}
                    />
                  </div>
                )}
              </>
            )}
            {(currentSearchStatus === SearchStrategyStatus.Uninitialized ||
              currentSearchStatus === SearchStrategyStatus.Loading) && (
              <Group>
                <Item halign={ItemHalign.Centre} valign={ItemValign.Middle}>
                  <Spinner size={SpinnerSize.Medium} text="Loading..." />
                </Item>
              </Group>
            )}
            {currentSearchStatus === SearchStrategyStatus.Error && (
              <Group>
                <Item halign={ItemHalign.Left}>
                  <Text>Error has occurred retrieving hierarchy</Text>
                </Item>
              </Group>
            )}
          </div>
        </Item>
      </Group>
    </div>
  );
};

export default FocalItemSearchLayout;
