import {
  InputStatus,
  Item,
  Tag,
  TagColour,
  TagVariant,
  Text,
} from "@qbit/react";
import styles from "./NumberRequiredFieldError.module.css";

export const NumberRequiredFieldError = () => (
  <Item className={styles.errorContainer}>
    <Tag
      className={styles.errorIcon}
      colour={TagColour.Bad}
      text="whole number required"
      variant={TagVariant.Badge}
    />
    <InputStatus id="error-status">
      <Text className={styles.errorText}>
        Whole number within range required
      </Text>
    </InputStatus>
  </Item>
);

export default NumberRequiredFieldError;
