import { getBaseQuery } from "@quantium-enterprise/common-ui";
import { createApi } from "@reduxjs/toolkit/query/react";
import { buildRawDataFilenameFromReportParameters } from "../../../reports/src/raw-data/BuildRawDataReportName";
import { type ReportParametersDto, type NestedFolderDto } from "../models";

export const myReportsApi = createApi({
  baseQuery: getBaseQuery(`/api/report-parameters-web`),
  endpoints: (builder) => ({
    getGeneratedReports: builder.query<
      NestedFolderDto,
      { divisionName: string }
    >({
      providesTags: ["generated-reports"],
      query: ({ divisionName }) =>
        `/ReportParameters/GetMyReportsNested/${divisionName}`,
    }),
  }),
  reducerPath: "myReportsApi",
  tagTypes: ["generated-reports"],
});

type DownloadResponse = {
  success: boolean;
};

export const rawDataApi = createApi({
  baseQuery: getBaseQuery(`/api`),
  endpoints: (builder) => ({
    downloadRawDataReport: builder.query<
      DownloadResponse,
      { reportId: string; reportParameters?: ReportParametersDto }
    >({
      queryFn: async ({ reportId, reportParameters }) => {
        const fileName =
          buildRawDataFilenameFromReportParameters(reportParameters);
        // Create an anchor element
        const a = document.createElement("a");
        a.href = `/api/raw-data/download/${reportId}?fileName=${encodeURIComponent(
          fileName
        )}`;
        document.body.appendChild(a);
        a.click();

        return { data: { success: true } };
      },
    }),
  }),
  reducerPath: "rawDataApi",
});

export const { useGetGeneratedReportsQuery, useLazyGetGeneratedReportsQuery } =
  myReportsApi;

export const { useLazyDownloadRawDataReportQuery } = rawDataApi;
