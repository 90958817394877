import {
  ParameterId,
  TransactionSource,
  useGetGroupSummaryQuery,
  useGetValidTransactionSourcesQuery,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { TransactionSourceDisplayNames } from "components-ui/src/icons/transaction-source-icon/TransactionSourceIcon";
import { useSelector } from "react-redux";
import { useAppSelector } from "../../states/hooks";
import { selectMaxGroupSelectionsReached } from "../../states/report-wizard-slice";
import { type RootState } from "../../store";
import { getGroupParameterState } from "../groups/GroupParameterState";
import { type GroupInfo } from "./GroupParameterTable";

const useIsGroupSelectable = (
  group: GroupInfo,
  parameterId: string
): { reason: string; selectable: boolean } => {
  const { name: divisionName } = useDivision();
  const { currentData: groupSummary, isFetching: isSummaryFetching } =
    useGetGroupSummaryQuery(
      {
        divisionName,
        groupId: group.id,
        maxItemsInSubset: 32,
      },
      { skip: !divisionName }
    );
  const { data: validTransactionSources, isLoading: validTransactionsLoading } =
    useGetValidTransactionSourcesQuery(
      { divisionName, groupId: group.id },
      { skip: !divisionName }
    );

  const maxGroupSelectionsReached = useSelector((state: RootState) =>
    selectMaxGroupSelectionsReached(parameterId, state)
  );

  const groupsState = useAppSelector(
    getGroupParameterState(ParameterId.ProductGroups)
  );

  if (
    group.transactionSource === null ||
    (maxGroupSelectionsReached && !group.selected)
  ) {
    return {
      selectable: false,
      reason:
        "Selection limit reached. Please deselect an item to enable selection.",
    };
  }

  if (isSummaryFetching || validTransactionsLoading) {
    return { selectable: false, reason: "Loading..." };
  }

  if (groupSummary && groupSummary.totalItems === 0) {
    return {
      selectable: false,
      reason: "Product group is empty and invalid for selection.",
    };
  }

  const requiredTransactionSource =
    groupsState?.parameterConfig.productGroupTransactionLevel ??
    TransactionSource.Customer;

  const transactionText =
    requiredTransactionSource === TransactionSource.Customer
      ? TransactionSourceDisplayNames[TransactionSource.Total]
      : TransactionSourceDisplayNames[TransactionSource.Customer];

  if (
    validTransactionSources &&
    (validTransactionSources.length === 0 ||
      !validTransactionSources.includes(requiredTransactionSource))
  ) {
    return {
      selectable: false,
      reason: `Product group invalid for selection as it runs on ${transactionText}. Please select a ${TransactionSourceDisplayNames[requiredTransactionSource]} product group.`,
    };
  }

  return { selectable: true, reason: "" };
};

export default useIsGroupSelectable;
