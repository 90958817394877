import { Toggle, ToggleSize } from "@qbit/react";
import {
  ReportType,
  type ReportParametersDto,
} from "@quantium-enterprise/common-ui";
import {
  useFormatter,
  MetricTypes,
  useLabelFormatter,
} from "@quantium-enterprise/hooks-ui";
import { ButtonToggleGroup } from "components-ui/src/button-toggle-group/ButtonToggleGroup";
import { ChartFooterWrapper } from "components-ui/src/charts/chart-footer-wrapper/ChartFooterWrapper";
import {
  ChartOptions,
  type DataLabelsOptions,
} from "components-ui/src/charts/chart-options/ChartOptions";
import { type HighchartsReactProps } from "components-ui/src/charts/highcharts-react/HighchartsReact";
import { WaterfallChart } from "components-ui/src/charts/waterfall-chart/WaterfallChart";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { ReportIcon } from "components-ui/src/icons";
import {
  type PropsWithChildren,
  useState,
  useMemo,
  useRef,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { type RootState } from "../../store";
import styles from "../GainsAndLossReport.module.css";
import { type GainsAndLossOverviewResponseDto } from "../models/GainsAndLossOverviewReportletDto";
import { ValueTypes } from "../models/GainsAndLossReportValues";
import {
  selectChannel,
  selectCompStore,
  selectFocalItem,
  selectLocation,
  selectMetricSet,
  selectSegment,
  selectSegmentation,
  selectShowOverviewWaterfallChartDataLabels,
  selectTime,
  selectTimePeriodLength,
  toggleOverviewWaterfallChartDataLabels,
} from "../services/gains-and-loss-slice";
import { filterData } from "../utils/common-utils";
import { getParameterSummaryValueStrings } from "../utils/getParameterSummaryValueStrings";
import {
  getBasicWaterfallExportData,
  getBreakdownWaterfallExportData,
} from "../utils/overview-waterfall-chart-export-utils";
import {
  getBasicWaterfallChartData,
  getBreakdownWaterfallChartData,
} from "../utils/overview-waterfall-chart-utils";
import { waterfallChartCsvTransformation } from "./csvTransformation";

type GainsAndLossOverviewWaterfallProps = {
  className?: string;
  data?: GainsAndLossOverviewResponseDto;
  isExportEnabled: boolean;
  measureChosen: string;
  reportParameters?: ReportParametersDto;
};

const valueTypeButtons = [ValueTypes.Absolute, ValueTypes.Percentage];

export const GainsAndLossOverviewWaterfall = ({
  className,
  data,
  measureChosen,
  reportParameters,
  isExportEnabled,
}: PropsWithChildren<GainsAndLossOverviewWaterfallProps>) => {
  const dispatch = useDispatch();
  const [valueTypeSelection, setValueTypeSelection] = useState(0);
  const [showAsBreakdown, setShowAsBreakdown] = useState(false);
  const chartContainerRef = useRef<HTMLElement>();

  const handleToggleClick = () => {
    setShowAsBreakdown(!showAsBreakdown);
  };

  const focalItem = useSelector(selectFocalItem);
  const channel = useSelector(selectChannel);
  const compStore = useSelector(selectCompStore);
  const location = useSelector(selectLocation);
  const metricSet = useSelector(selectMetricSet);
  const segment = useSelector(selectSegment);
  const segmentation = useSelector(selectSegmentation);
  const time = useSelector(selectTime);
  const timePeriodLength = useSelector(selectTimePeriodLength);
  const showOverviewWaterfallChartDataLabels = useSelector(
    selectShowOverviewWaterfallChartDataLabels
  );

  const waterfallData = useMemo(
    () =>
      data
        ? filterData(
            data.overviewItems,
            measureChosen,
            valueTypeButtons[valueTypeSelection]
          )
        : undefined,
    [data, measureChosen, valueTypeSelection]
  );
  const exportFilename = useMemo(
    () =>
      cleanFilename(
        `Gain_And_Loss_Overview_Chart_${timePeriodLength}_${location.name}`
      ),
    [timePeriodLength, location]
  );

  const parameterSummary = useMemo(
    () =>
      getParameterSummaryValueStrings(
        channel,
        compStore,
        location,
        metricSet,
        segment,
        segmentation,
        time,
        focalItem
      ),
    [
      channel,
      compStore,
      location,
      segment,
      segmentation,
      time,
      focalItem,
      metricSet,
    ]
  );

  const format = useMemo(
    () =>
      waterfallData && "format" in waterfallData
        ? waterfallData.format
        : MetricTypes.None,
    [waterfallData]
  );

  const formatter = useFormatter()(format);
  const yAxisLabel = useLabelFormatter(
    measureChosen,
    MetricTypes[format as keyof typeof MetricTypes]
  );

  const metricHeader = useLabelFormatter(
    waterfallData?.measureName ?? "",
    MetricTypes[waterfallData?.format as keyof typeof MetricTypes]
  );
  const waterfallChartCsvTransformationCallback = useCallback(
    () =>
      waterfallChartCsvTransformation(
        showAsBreakdown === true
          ? getBreakdownWaterfallExportData(waterfallData)
          : getBasicWaterfallExportData(waterfallData),
        metricHeader
      ),
    [showAsBreakdown, waterfallData, metricHeader]
  );

  const dataLabelOptions: DataLabelsOptions[] = [
    {
      isSelected: showOverviewWaterfallChartDataLabels,
      value: "",
    },
  ];

  const [withBreakdownOptions, setWithBreakdownOptions] =
    useState<HighchartsReactProps>();
  const [noBreakdownOptions, setNoBreakdownOptions] =
    useState<HighchartsReactProps>();
  const currentOptions = showAsBreakdown
    ? withBreakdownOptions
    : noBreakdownOptions;
  const { reportName } = useSelector((state: RootState) => ({
    reportName: state.gainsAndLoss.reportName,
  }));
  const basicData = useMemo(
    () => getBasicWaterfallChartData(waterfallData),
    [waterfallData]
  );
  const breakdownData = useMemo(
    () => getBreakdownWaterfallChartData(waterfallData),
    [waterfallData]
  );

  return (
    <div className={className}>
      <div className={styles.multiChartOptionsContainer}>
        <div className={styles.chartToggleButtonContainer}>
          <ButtonToggleGroup
            buttonSelected={valueTypeSelection}
            buttons={valueTypeButtons.map((button, index) => ({
              displayText: button,
              id: index,
            }))}
            setButtonSelected={setValueTypeSelection}
          />
          <Toggle
            checked={showAsBreakdown}
            className={styles.breakdownWaterfallChartToggle}
            label="Show as breakdown"
            onClick={handleToggleClick}
            size={ToggleSize.Small}
          />
        </div>
        <div className={styles.container}>
          <ChartOptions
            dataLabelsOptions={dataLabelOptions}
            downloadWizardOptions={
              currentOptions
                ? {
                    chartOptions: currentOptions,
                    chartTitle: `Gains and loss overview - ${reportName}`,
                    reportIcon: <ReportIcon type={ReportType.GainsAndLoss} />,
                    reportTitle: reportName,
                  }
                : undefined
            }
            filename={exportFilename}
            getCSVData={waterfallChartCsvTransformationCallback}
            getElementToExport={() => chartContainerRef.current}
            isFeatureEnabled={isExportEnabled}
            localParameters={parameterSummary}
            reportParameters={reportParameters}
            toggleDataLabels={() => {
              dispatch(toggleOverviewWaterfallChartDataLabels());
            }}
          />
        </div>
      </div>
      {/* NOTE: returning null for highcharts causes chart animation */}

      {/* NOTE: returning null for highcharts causes chart animation */}

      <ChartFooterWrapper
        height="400px"
        parameters={parameterSummary}
        ref={chartContainerRef}
      >
        {showAsBreakdown ? (
          <WaterfallChart
            data={breakdownData}
            dataLabelFormatter={formatter}
            onOptionsChanged={setWithBreakdownOptions}
            showChartDataLabels={showOverviewWaterfallChartDataLabels}
            tooltipFormatter={formatter}
            yAxisLabel={yAxisLabel}
            yAxisTickFormatter={formatter}
          />
        ) : null}

        {showAsBreakdown === false ? (
          <WaterfallChart
            data={basicData}
            dataLabelFormatter={formatter}
            onOptionsChanged={setNoBreakdownOptions}
            showChartDataLabels={showOverviewWaterfallChartDataLabels}
            tooltipFormatter={formatter}
            yAxisLabel={yAxisLabel}
            yAxisTickFormatter={formatter}
          />
        ) : null}
      </ChartFooterWrapper>
    </div>
  );
};
