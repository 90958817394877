import { Spinner, SpinnerSize } from "@qbit/react";
import { type HierarchyItemType } from "@quantium-enterprise/common-ui";
import classNames from "classnames";
import { type MouseEvent } from "react";
import { HierarchyLevelIcon } from "../../hierarchy-level-icon/HierarchyLevelIcon";
import { ExpandChevron } from "../../icons";
import styles from "./RadioSelectTable.module.css";

export type RadioSelectTableCellProps = {
  handleSelect: (value: string) => void;
  handleToggleExpanded: (event: MouseEvent<HTMLElement>) => void;
  hierarchyItemType: HierarchyItemType;
  isChild?: boolean;
  isDisabled?: boolean;
  isExpanded: boolean;
  isLoading: boolean;
  isSelected: boolean;
  label: string;
  parent?: boolean;
  value: string;
};

export const RadioSelectTableCell = ({
  handleSelect,
  handleToggleExpanded,
  isExpanded,
  isLoading,
  isSelected,
  label,
  hierarchyItemType,
  value,
  isChild = false,
  isDisabled = false,
  parent = false,
}: RadioSelectTableCellProps) => {
  const radioStyle = classNames(styles.radioCell, {
    [styles.radioSelectChild]: isChild,
    [styles.disabled]: isDisabled || isLoading,
  });
  const displayValue = value.split("/")[0];
  return (
    <span className={radioStyle}>
      <span className={styles.toggle}>
        {parent ? (
          <ExpandChevron
            isCompact={false}
            isExpanded={isExpanded}
            isLoading={false}
            onClick={
              !isDisabled && !isLoading ? handleToggleExpanded : () => {}
            }
          />
        ) : isLoading ? (
          <Spinner
            className={styles.spinner}
            size={SpinnerSize.XSmall}
            text="Loading..."
          />
        ) : (
          <input
            checked={isSelected}
            data-testid="radio-select-table-radio"
            id={value}
            name={label}
            onChange={() => (isDisabled ? {} : handleSelect(value))}
            type="radio"
            value={value}
          />
        )}
      </span>

      <HierarchyLevelIcon shortName={displayValue} type={hierarchyItemType} />
      <label className={styles.radioLabel}>
        {label} {isExpanded ? <strong> in</strong> : <span />}
      </label>
    </span>
  );
};

export default RadioSelectTableCell;
