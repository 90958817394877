import {
  Button,
  ButtonHeight,
  ButtonVariant,
  Spinner,
  SpinnerSize,
} from "@qbit/react";
import { flexRender, type Row, type Table } from "@tanstack/react-table";
import classNames from "classnames";
import { useRef } from "react";
import { type ItemProps } from "react-virtuoso";
import commonStyles from "../common/GridTable.module.css";

// a row in the initial data can have a "isLoadMore" property which means it is the last row with a load more after it
const isMoreRow = <T extends { isMoreRow?: boolean }>(data: T) =>
  "isMoreRow" in data && data.isMoreRow === true;

// a virtual row inserted after the last isLoadMore row with the button for loading more
const isLoadMoreRow = <T extends { isLoadMoreRow?: boolean }>(data: T) =>
  "moreLabel" in data;

export type VirtuosoTableElementProps<T> = ItemProps<T> & {
  colSpan: number;
  compactRows?: boolean;
  depthPadding?: number;
  depthPaddingOffset?: number;
  firstColumnMinWidth: number;
  getIsRowDisabled?: (row: Row<T>, table: Table<T>) => boolean;
  getLoadingMoreId: (currentRow: T) => string;
  handleMoreClicked?: (row: Row<T>) => Promise<void>;
  isLoadingMore: Record<string, boolean>;
  moreText: string;
  onRowClick?: Function;
  onRowDoubleClick?: Function;
  pinFirstColumn?: boolean;
  row: Row<T>;
  rowIsDisabled?: boolean;
  setIsLoadingMore: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >;
  showCheckboxesOnlyOnHover?: boolean;
};

export const VirtuosoTableRowElement = <T,>({
  onRowDoubleClick,
  onRowClick,
  colSpan,
  compactRows = false,
  depthPadding = 0,
  depthPaddingOffset = 0,
  firstColumnMinWidth,
  getLoadingMoreId,
  handleMoreClicked,
  isLoadingMore,
  moreText,
  pinFirstColumn,
  row,
  rowIsDisabled = false,
  setIsLoadingMore,
  showCheckboxesOnlyOnHover,
  ...properties
}: VirtuosoTableElementProps<T>) => {
  const clickTimeout = useRef<NodeJS.Timeout | null>(null);

  if (!row.original || Object.keys(row.original).length === 0) {
    return null;
  }

  const onMoreClicked = (currentRow: Row<T>) => {
    setIsLoadingMore({
      ...isLoadingMore,
      [getLoadingMoreId(currentRow.original)]: true,
    });
    if (handleMoreClicked) {
      void handleMoreClicked(currentRow)
        .then(() =>
          setIsLoadingMore({
            ...isLoadingMore,
            [getLoadingMoreId(currentRow.original)]: false,
          })
        )
        .finally(() =>
          setIsLoadingMore({
            ...isLoadingMore,
            [getLoadingMoreId(currentRow.original)]: false,
          })
        );
    }
  };

  let handleRowClick: Function;
  if (onRowClick && onRowDoubleClick) {
    handleRowClick = (
      event: React.MouseEvent<HTMLTableRowElement>,
      rowId: number,
      selectedRowData: never
    ) => {
      if ((event.target as Element).closest(".quick-actions")) {
        return;
      }

      if (clickTimeout.current) {
        clearTimeout(clickTimeout.current);
        clickTimeout.current = null;
        onRowDoubleClick(event, rowId, selectedRowData);
      } else {
        clickTimeout.current = setTimeout(() => {
          onRowClick(event, rowId, selectedRowData);
          clickTimeout.current = null;
        }, 250);
      }
    };
  }

  return isLoadMoreRow(row.original as Object) ? (
    <tr
      className={commonStyles.moreRow}
      key={row.id}
      onClick={(event: React.MouseEvent<HTMLTableRowElement>) =>
        onRowClick && onRowDoubleClick
          ? handleRowClick(event, row.id, row.original)
          : onRowClick?.(event, row.id, row.original)
      }
      role="row"
    >
      <td
        colSpan={colSpan}
        // eslint-disable-next-line jsx-a11y/no-interactive-element-to-noninteractive-role
        role="cell"
      >
        {handleMoreClicked &&
          !isLoadingMore[getLoadingMoreId(row.original)] && (
            <div
              className={classNames(commonStyles.more)}
              style={{
                paddingLeft: row.depth * depthPadding + depthPaddingOffset,
              }}
            >
              <Button
                data-testid="load-more"
                height={ButtonHeight.XSmall}
                onClick={() => onMoreClicked(row)}
                text={moreText}
                variant={ButtonVariant.Link}
              />
            </div>
          )}
        {handleMoreClicked && isLoadingMore[getLoadingMoreId(row.original)] && (
          <div className={classNames(commonStyles.more)}>
            <div
              className={commonStyles.loadingMoreSpinner}
              style={{
                paddingLeft: row.depth * depthPadding + depthPaddingOffset,
              }}
            >
              <Spinner size={SpinnerSize.Small} text="Loading more..." />
            </div>
          </div>
        )}
      </td>
    </tr>
  ) : (
    <tr
      className={classNames(commonStyles.tableRow, {
        [commonStyles.selected]: row.getIsSelected(),
        [commonStyles.isMoreRow]: isMoreRow(row.original as Object),
        [commonStyles.checkboxesOnHover]: showCheckboxesOnlyOnHover,
        [commonStyles.greyedOut]: rowIsDisabled,
      })}
      onClick={(event: React.MouseEvent<HTMLTableRowElement>) =>
        onRowClick && onRowDoubleClick
          ? handleRowClick(event, row.id, row.original)
          : onRowClick?.(event, row.id, row.original)
      }
      role="row"
      {...properties}
    >
      {row.getVisibleCells().map((cell, cellIndex) => (
        <td
          className={classNames(commonStyles.tableData, {
            [commonStyles.selected]: row.getIsSelected(),
            [commonStyles.pinned]: cell.column.getIsPinned(),
          })}
          data-testid={`cell-${cell.column.id}`}
          key={cell.id}
          // eslint-disable-next-line jsx-a11y/no-interactive-element-to-noninteractive-role
          role="cell"
          style={{
            minWidth:
              cellIndex === 0 && pinFirstColumn
                ? firstColumnMinWidth
                : cell.column.getSize(),
            maxWidth:
              cellIndex === 0 && pinFirstColumn
                ? firstColumnMinWidth
                : cell.column.getSize(),
          }}
        >
          <div
            className={classNames(commonStyles.cellContainer, {
              [commonStyles.compact]: compactRows,
            })}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </div>
        </td>
      ))}
    </tr>
  );
};
