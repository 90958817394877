import { HierarchyItemType } from "@quantium-enterprise/common-ui";
import { useDivision, useFormatter } from "@quantium-enterprise/hooks-ui";
import {
  type CellContext,
  type ColumnDef,
  type ColumnResizeMode,
} from "@tanstack/react-table";
import { EmptySearch } from "components-ui/src/search/EmptySearch";
import { SearchBox } from "components-ui/src/search-box/SearchBox";
import { ExpandableNameCell } from "components-ui/src/tables/common/table-cell/ExpandableNameCell";
import { ValueCell } from "components-ui/src/tables/common/table-cell/ValueCell";
import { ReportHierarchyTableWrapper } from "components-ui/src/tables/report-hierarchy-table/components/ReportHierarchyTableWrapper";
import { VirtuosoTableComponent } from "components-ui/src/tables/virtuoso-table/VirtuosoTableComponent";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  type CustomerLoyaltyHierarchyProductData,
  type CustomerLoyaltyHierarchyProductLoadMoreRow,
  type CustomerLoyaltyHierarchyProductRow,
} from "../../models/CustomerLoyaltyHierarchyProductData";
import { type TableRowsRequestDto } from "../../models/CustomerLoyaltyTableRowsRequestDto";
import { useLazyGetTableRowsQuery } from "../../services/customer-loyalty-data-table-api-slice";
import {
  onLoadMoreResponseReceived,
  setFocalItems,
  onSearchQueryChange,
  selectLocationNodeSelection,
  selectMetricList,
  selectRowSelection,
  selectSearchQuery,
  selectSegment,
  selectSegmentation,
  selectTablePage,
  selectTableRows,
  selectTopDrawerActiveTab,
  selectTopDrawerRowCount,
} from "../../services/customer-loyalty-slice";
import styles from "./CustomerLoyaltyTopDrawerProductTable.module.css";
import { getTableRequestDto } from "./utils";

const columnResizeMode: ColumnResizeMode = "onChange";

const isLoadMore = (
  panel: CustomerLoyaltyHierarchyProductRow
): panel is CustomerLoyaltyHierarchyProductLoadMoreRow =>
  Object.prototype.hasOwnProperty.call(panel, "loadMoreParentRow");

const getRowId = (row: CustomerLoyaltyHierarchyProductRow) => {
  if (isLoadMore(row)) {
    return `${row.loadMoreParentRow.hierarchyItem.itemCode}-load-more`;
  }

  return row.hierarchyItem.itemCode;
};

export type CustomerLoyaltyTopDrawerProductTableProps = {
  isQuerySuccess: boolean;
};

export const CustomerLoyaltyTopDrawerProductTable = ({
  isQuerySuccess,
}: CustomerLoyaltyTopDrawerProductTableProps) => {
  const dispatch = useDispatch();
  const { id: reportId } = useParams();
  const { name: divisionName, customLoaDto } = useDivision();
  const formatter = useFormatter();

  const activeTab = useSelector(selectTopDrawerActiveTab);
  const metricList = useSelector(selectMetricList);
  const page = useSelector(selectTablePage);
  const rowSelection = useSelector(selectRowSelection);
  const searchQuery = useSelector(selectSearchQuery);
  const segmentation = useSelector(selectSegmentation);
  const segment = useSelector(selectSegment);
  const tableRows = useSelector(selectTableRows);
  const topDrawerRowCount = useSelector(selectTopDrawerRowCount);
  const locationNodeSelection = useSelector(selectLocationNodeSelection);

  const [getTableRows] = useLazyGetTableRowsQuery();

  const fetchData = useCallback(
    async (division: string, payload: TableRowsRequestDto) => {
      const response = await getTableRows({ division, payload });
      if (response.data) {
        dispatch(onLoadMoreResponseReceived(response.data));
      }
    },
    [getTableRows, dispatch]
  );

  const onMoreClickedHandler = useCallback(async () => {
    const payload = getTableRequestDto(
      activeTab ?? "",
      [segmentation.value as string, segment.value as string],
      locationNodeSelection,
      page,
      reportId ?? "",
      searchQuery
    );

    await fetchData(divisionName, payload);
  }, [
    activeTab,
    segmentation.value,
    segment.value,
    locationNodeSelection,
    page,
    reportId,
    searchQuery,
    fetchData,
    divisionName,
  ]);

  const onSearchChange = useCallback(
    (value: string) => dispatch(onSearchQueryChange(value)),
    [dispatch]
  );

  const matchesFound = useMemo(() => {
    if (!searchQuery) {
      return undefined;
    }

    return topDrawerRowCount;
  }, [topDrawerRowCount, searchQuery]);

  const memoizedSearchBox = useMemo(
    () => (
      <div className={styles.searchbox}>
        <SearchBox
          enableDebounce
          onChange={onSearchChange}
          placeholder="Type to search"
          resultCount={matchesFound}
        />
      </div>
    ),
    [onSearchChange, matchesFound]
  );

  const displayEmptySearch: boolean =
    isQuerySuccess && searchQuery.length > 0 && tableRows.length === 0;

  const createNameCell = useCallback(
    ({ row }: CellContext<CustomerLoyaltyHierarchyProductData, unknown>) => (
      <ExpandableNameCell
        canExpand={false}
        depth={0}
        handleToggleExpanded={row.getToggleExpandedHandler()}
        handleToggleSelected={(event) => {
          row.getToggleSelectedHandler()(event);
          dispatch(
            setFocalItems({
              isSelected: row.getIsSelected(),
              selectedRow: row.original,
              customLoaDto,
            })
          );
        }}
        isExpanded={false}
        isSelected={row.getIsSelected()}
        name={row.original.hierarchyItem.name}
        shortName={row.original.hierarchyItem.shortName}
        type={HierarchyItemType.Hierarchy}
        value={row.original.hierarchyItem.name}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const metricColumnsDefs = useMemo(
    (): Array<ColumnDef<CustomerLoyaltyHierarchyProductData>> =>
      metricList.map((metric, index) => ({
        accessorKey: metric.value,
        cell: ({ row }) =>
          ValueCell({
            formatter: formatter(metric.format),
            value: row.original.metricValues[index],
          }),
        enableHiding: false,
        enableResizing: false,
        enableSorting: false,
        header: metric.label,
        id: index + metric.value,
      })),
    [formatter, metricList]
  );

  // @ts-expect-error typecheck issue with memoizedSearchBox as header
  const columnDefs: Array<ColumnDef<CustomerLoyaltyHierarchyProductData>> =
    useMemo(
      () => [
        {
          accessorKey: "name",
          cell: createNameCell,
          enableHiding: false,
          enableResizing: true,
          enableSorting: false,
          header: memoizedSearchBox,
        },
        ...metricColumnsDefs,
      ],
      [metricColumnsDefs, memoizedSearchBox, createNameCell]
    );

  return (
    <ReportHierarchyTableWrapper isSuccess={isQuerySuccess}>
      <VirtuosoTableComponent
        columnResizeMode={columnResizeMode}
        columns={columnDefs}
        data={tableRows}
        enableSorting={false}
        getRowId={getRowId}
        handleMoreClicked={onMoreClickedHandler}
        moreText="Load more..."
        pinFirstColumn
        rowExpandedState={{ "0": true }}
        rowSelectionState={rowSelection}
      />
      {displayEmptySearch && (
        <div className={styles.emptySearch}>
          <EmptySearch />
        </div>
      )}
    </ReportHierarchyTableWrapper>
  );
};
