import { ParameterId, type ParameterDto } from "@quantium-enterprise/common-ui";
import { useDispatch, useSelector } from "react-redux";
import { useAppSelector } from "../../../states/hooks";
import {
  levelOfAnalysisSingleOptionSelected,
  selectAdvancedSearchEnableState,
} from "../../../states/report-wizard-slice";
import { type RootState } from "../../../store";
import { LevelParameter } from "../../level/LevelParameter";
import { getHierarchyState } from "../HierarchyState";
import { getLevelOfAnalysisState } from "../LevelOfAnalysisState";
import styles from "./AssociatedLevel.module.css";

export type AssociatedLevelProps = {
  parameterDto: ParameterDto;
};

export const AssociatedLevelParameter = ({
  parameterDto,
}: AssociatedLevelProps) => {
  const dispatch = useDispatch();
  const associatedLevelState = useAppSelector(
    getLevelOfAnalysisState(parameterDto.id)
  );
  const associatedProductState = useAppSelector(
    getHierarchyState(ParameterId.AssociatedProducts)
  );

  const isAdvancedSearchEnabled = useSelector((state: RootState) =>
    selectAdvancedSearchEnableState(ParameterId.AssociatedProducts, state)
  );

  // Change this onChange handler once we start handling nested options.
  const onChange = (value: string) => {
    dispatch(
      levelOfAnalysisSingleOptionSelected({
        parameter: parameterDto.id,
        selectedValue: value,
      })
    );
  };

  return (
    <div
      className={`${styles.associatedLevelContainer} ${
        isAdvancedSearchEnabled ? styles.advanced : ""
      }`}
    >
      <LevelParameter
        handleSelect={onChange}
        hierarchyState={associatedProductState}
        levelState={associatedLevelState}
        parameterDto={parameterDto}
      />
    </div>
  );
};
