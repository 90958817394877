import { type SortingState } from "@tanstack/react-table";
import { sortByIndex } from "../../common/utils/export-parameter-summary-utils";
import {
  type PurchasedWithProduct,
  type AssociatedItem,
} from "../models/basket-affinities-chart-models";

export const csvTransformation = (
  associatedLevel: string,
  chartRows: AssociatedItem[]
) => {
  const columnHeaders = [
    associatedLevel,
    `${associatedLevel} id`,
    "Associated items count",
  ];
  const csvData = [columnHeaders];
  for (const row of chartRows) {
    csvData.push([
      row.associatedAncestor.name,
      row.associatedAncestor.itemCode,
      row.associatedItemsCount.toString(),
    ]);
  }

  return csvData;
};

export const csvPurchasedWithFocalItems = (
  tableData: PurchasedWithProduct[],
  sortingState: SortingState,
  upliftThreshold: number,
  basketsWithBothThreshold: number,
  currency: string
) => {
  const filteredTableData = tableData.filter(
    (x) =>
      x.uplift >= upliftThreshold &&
      x.basketsWithBoth >= basketsWithBothThreshold
  );
  const columnHeaders = [
    `Associated products (${filteredTableData.length})`,
    "Subcategory",
    "Uplift",
    "Confidence (Basket With both/ Baskets with focal prd) (%)",
    "Support (Associated baskets / Total baskets) (%)",
    "Baskets with both",
    `Associated sales per basket (${currency})`,
    `All target sales (${currency})`,
  ];
  const sortIndex = columnHeaders.findIndex((item) =>
    item.includes(sortingState[0].id)
  );
  const csvData = [columnHeaders];
  for (const row of filteredTableData) {
    csvData.push([
      row.product.name,
      row.associatedAncestor.name,
      row.uplift.toString(),
      row.confidence.toString(),
      row.support.toString(),
      row.basketsWithBoth.toString(),
      row.associatedSalesPerBasket.toString(),
      row.allTargetSales.toString(),
    ]);
  }

  return sortByIndex(csvData, sortIndex, sortingState[0].desc);
};
