/* eslint-disable complexity */
import {
  MessageVariant,
  QbitEmitToast,
  QbitToastMessage,
  Spinner,
} from "@qbit/react";
import { uniqueId } from "@qbit/react/dist/common";
import {
  type HierarchyGroupRuleWithIdAndName,
  type HierarchyGroupDto,
  TrackingComponent,
  TrackingEvent,
  useEventTrackingServiceContext,
  HierarchyType,
  HierarchyGroupEvaluationType,
  formatHierarchyName,
  GroupsTrackingProperty,
  useCreateGroupMutation,
  useUpdateGroupMutation,
  GenericTrackingProperties,
  useHierarchyMetadataQuery,
  useGetUserQuery,
  useGetEntitledSubscriptionsQuery,
  FeatureFlag,
  ADVANCED_REPORTING_FEATURE_NAME,
  TransactionSource,
  HierarchyGroupRuleOperator,
  NULL_SHORT_NAME,
} from "@quantium-enterprise/common-ui";
import { type SubscriptionDto } from "@quantium-enterprise/common-ui/src/models/subscription-dto";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import { HierarchyGroupIcon } from "components-ui/src/icons";
import { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { reset } from "../../states/group-hierarchy-source-slice";
import {
  getGroupCreatorPath,
  getGroupListPath,
} from "../../utilities/route-path-formats";
import { GroupSummaryBar } from "../GroupSummaryBar";
import { DynamicGroupLeafsTable } from "../dynamic-group-leafs-table/DynamicGroupLeafsTable";
import { DynamicGroupRulesForm } from "../dynamic-group-rules-form/DynamicGroupRulesForm";
import { GroupEditor } from "../group-editor/GroupEditor";
import { SubscriptionToggle } from "../subscription-toggle/SubscriptionToggle";
import { TwoPanelForm } from "../two-panel-form/TwoPanelForm";
import styles from "./DynamicGroupEditor.module.css";

const { Header, BackButton, Title, Content, Footer, SaveButton } = GroupEditor;
const { Heading, LeftPanel, RightPanel } = TwoPanelForm;

const getGroupDto = (
  groupName: string,
  hierarchyType: HierarchyType,
  rules: HierarchyGroupRuleWithIdAndName[],
  id?: string,
  subscription?: SubscriptionDto,
  shouldSaveSubscription?: boolean
): HierarchyGroupDto => ({
  evaluationType: HierarchyGroupEvaluationType.Dynamic,
  hierarchyType,
  id,
  name: groupName,
  rules: rules.map((rule) => ({
    operator: rule.operator,
    shortName: rule.shortName,
    values: rule.values.map((value) => value.code),
  })),
  featureModule: shouldSaveSubscription
    ? subscription?.featureModule ?? TransactionSource.Customer.toString()
    : undefined,
  transactionSource: shouldSaveSubscription
    ? subscription?.transactionSource ?? ADVANCED_REPORTING_FEATURE_NAME
    : undefined,
});

type DynamicGroupProps = {
  existingGroup?: HierarchyGroupDto;
  hierarchyType: HierarchyType;
};

export const DynamicGroupEditor = ({
  existingGroup,
  hierarchyType,
}: DynamicGroupProps) => {
  const { name: divisionName } = useDivision();
  const { data: user } = useGetUserQuery();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const eventTrackingService = useEventTrackingServiceContext();
  const trackingComponent =
    hierarchyType === HierarchyType.Product
      ? TrackingComponent.ProductGroup
      : TrackingComponent.LocationGroup;
  const countTrackingProperty =
    hierarchyType === HierarchyType.Product
      ? GroupsTrackingProperty.ProductCount
      : GroupsTrackingProperty.LocationCount;

  const [createGroup] = useCreateGroupMutation();
  const [updateGroup] = useUpdateGroupMutation();

  const [isLeafItemsTableLoaded, setIsLeafItemsTableLoaded] = useState(false);
  const [leafItemsCount, setLeafItemsCount] = useState(0);

  const [groupRules, setGroupRules] = useState<
    HierarchyGroupRuleWithIdAndName[]
  >(
    existingGroup?.rules?.map((rule) => ({
      id: uniqueId("rule-"),
      operator: rule.operator,
      shortName: rule.shortName,
      fullShortName: "",
      values: rule.values.map((value) => ({
        code: value,
        name: undefined,
      })),
    })) ?? []
  );
  const [readOnlyRules, setReadOnlyRules] = useState(groupRules.slice(0, -1));
  const [editableRule, setEditableRule] = useState(groupRules.at(-1));
  const { data: hierarchyAttributes } = useHierarchyMetadataQuery(
    {
      division: divisionName,
      hierarchyType,
      getAllAttributes: false,
    },
    { skip: !divisionName }
  );

  const featureFlags = useFlags();

  const isEntitlementsFilterEnabled =
    featureFlags[FeatureFlag.ScanEnhanceProductGroups] ?? false;

  const displayEntitlements =
    isEntitlementsFilterEnabled &&
    user?.isSupplier &&
    hierarchyType === HierarchyType.Product;

  const { data: entitledSubscriptions } = useGetEntitledSubscriptionsQuery(
    {
      divisionName,
      hierarchyType,
    },
    { skip: !divisionName || !displayEntitlements }
  );

  // Default existing groups to Q.Checkout if they don't have a subscription.
  const initialSubscription = existingGroup
    ? entitledSubscriptions?.find(
        (subscription) =>
          subscription.transactionSource === existingGroup.transactionSource &&
          subscription.featureModule === existingGroup.featureModule
      ) ?? entitledSubscriptions?.[entitledSubscriptions.length - 1]
    : entitledSubscriptions?.[0];

  const [selectedSubscription, setSelectedSubscription] = useState<
    SubscriptionDto | undefined
  >(initialSubscription);

  useEffect(() => {
    if (selectedSubscription === undefined) {
      setSelectedSubscription(entitledSubscriptions?.[0]);
    }
  }, [entitledSubscriptions, selectedSubscription]);

  const handleSubscriptionChange = useCallback((option: SubscriptionDto) => {
    setReadOnlyRules([]);
    setEditableRule({
      id: uniqueId("rule-"),
      operator: HierarchyGroupRuleOperator.Is,
      shortName: NULL_SHORT_NAME,
      fullShortName: undefined,
      values: [],
    } as HierarchyGroupRuleWithIdAndName);
    setSelectedSubscription(option);
  }, []);

  const leafShortName = hierarchyAttributes?.find(
    (attribute) =>
      attribute.isLeaf &&
      attribute.structureName?.toUpperCase() === hierarchyType.toUpperCase()
  )?.shortName;

  const saveRequestHandler = useCallback(
    async (request: Promise<HierarchyGroupDto>, successMessage: string) => {
      let isSuccess = false;
      try {
        const groupDto = await request;

        if (!groupDto.id) {
          throw new Error("Created group ID cannot be undefined or empty.");
        }

        isSuccess = true;
        navigate(getGroupListPath(divisionName, hierarchyType, groupDto.id));
        QbitEmitToast(
          <QbitToastMessage
            content={<span />}
            heading={<h5>{successMessage}</h5>}
            showCloseButton
            showIcon
            variant={MessageVariant.Success}
          />
        );
      } finally {
        eventTrackingService.trackEvent(
          trackingComponent,
          TrackingEvent.Created,
          new GenericTrackingProperties({
            [countTrackingProperty]: leafItemsCount,
            [GroupsTrackingProperty.GroupStatus]: isSuccess
              ? "Success"
              : "Failure",
            [GroupsTrackingProperty.GroupType]:
              HierarchyGroupEvaluationType.Dynamic,
            [GroupsTrackingProperty.RulesCount]: groupRules.length,
            division: divisionName,
            user: user?.isSupplier ? "Supplier" : "Retailer",
          })
        );
      }
    },
    [
      navigate,
      divisionName,
      hierarchyType,
      eventTrackingService,
      trackingComponent,
      countTrackingProperty,
      leafItemsCount,
      groupRules.length,
      user?.isSupplier,
    ]
  );

  const handleCreateGroup = useCallback(
    async (name: string) => {
      const group = getGroupDto(
        name,
        hierarchyType,
        groupRules,
        undefined,
        selectedSubscription,
        isEntitlementsFilterEnabled && hierarchyType === HierarchyType.Product
      );
      const request = createGroup({ divisionName, group }).unwrap();
      await saveRequestHandler(request, "Group has been created.");
    },
    [
      createGroup,
      divisionName,
      groupRules,
      hierarchyType,
      isEntitlementsFilterEnabled,
      saveRequestHandler,
      selectedSubscription,
    ]
  );

  const handleEditGroup = useCallback(
    async (name: string) => {
      if (!existingGroup) {
        throw new Error("Tried to edit a group that doesn't exist!");
      }

      const group = getGroupDto(
        name,
        hierarchyType,
        groupRules,
        existingGroup.id,
        selectedSubscription,
        isEntitlementsFilterEnabled && hierarchyType === HierarchyType.Product
      );
      const request = updateGroup({ divisionName, group }).unwrap();
      await saveRequestHandler(request, "Group has been saved.");
      dispatch(reset({ hierarchyType }));
    },
    [
      dispatch,
      divisionName,
      existingGroup,
      groupRules,
      hierarchyType,
      isEntitlementsFilterEnabled,
      saveRequestHandler,
      updateGroup,
      selectedSubscription,
    ]
  );

  useEffect(() => {
    setGroupRules(
      editableRule ? readOnlyRules.concat([editableRule]) : readOnlyRules
    );
  }, [readOnlyRules, editableRule]);

  return (
    <GroupEditor>
      <Header>
        <BackButton
          onClick={() => {
            dispatch(reset({ hierarchyType }));
          }}
          returnPath={
            existingGroup
              ? getGroupListPath(divisionName, hierarchyType, existingGroup.id)
              : getGroupCreatorPath(divisionName, hierarchyType)
          }
          showExitDialog
        />
        <Title
          icon={
            <HierarchyGroupIcon
              evaluationType={HierarchyGroupEvaluationType.Dynamic}
              hierarchyType={hierarchyType}
            />
          }
          subtitle="Dynamic"
          title={`Create a ${formatHierarchyName(
            hierarchyType,
            false,
            false
          )} group`}
        />
      </Header>
      <Content>
        <TwoPanelForm>
          <Heading
            subText={`Add rules to define your ${formatHierarchyName(
              hierarchyType,
              false,
              false
            )} group.`}
            text="Add rules"
          />
          <GroupSummaryBar
            existingGroup={existingGroup}
            groupType={HierarchyGroupEvaluationType.Dynamic}
            hierarchyType={HierarchyType.Product}
            itemsCount={leafItemsCount}
            subscription={selectedSubscription}
          />
          <LeftPanel>
            {!hierarchyAttributes ||
            (!selectedSubscription && displayEntitlements) ? (
              <Spinner />
            ) : (
              <>
                <div className={styles.rulesFormHeader}>
                  <SubscriptionToggle
                    handleChangeSubscription={(option: SubscriptionDto) =>
                      handleSubscriptionChange(option)
                    }
                    hideToggle={
                      !displayEntitlements ||
                      (entitledSubscriptions?.length ?? 0) < 2
                    }
                    ignoreDialog={readOnlyRules.length === 0 && !editableRule}
                    selectedSubscription={selectedSubscription}
                    subscriptionOptions={entitledSubscriptions ?? []}
                  />
                </div>
                <DynamicGroupRulesForm
                  editableRule={editableRule}
                  hierarchyAttributes={hierarchyAttributes}
                  hierarchyType={hierarchyType}
                  leafShortName={leafShortName ?? ""}
                  readonlyRules={readOnlyRules}
                  setEditableRule={setEditableRule}
                  setReadOnlyRules={setReadOnlyRules}
                  subscription={selectedSubscription}
                />
              </>
            )}
          </LeftPanel>
          <RightPanel>
            <DynamicGroupLeafsTable
              displayEntitlements
              hierarchyType={hierarchyType}
              leafShortName={leafShortName ?? ""}
              rules={groupRules}
              setIsLeafItemsTableLoaded={setIsLeafItemsTableLoaded}
              setLeafItemsCount={setLeafItemsCount}
              subscription={selectedSubscription}
            />
          </RightPanel>
        </TwoPanelForm>
      </Content>
      <Footer>
        <SaveButton
          disabled={!isLeafItemsTableLoaded}
          groupTypeIcon={
            <HierarchyGroupIcon
              evaluationType={HierarchyGroupEvaluationType.Dynamic}
              hierarchyType={hierarchyType}
            />
          }
          groupTypeName="Dynamic"
          handleCreateGroup={handleCreateGroup}
          handleEditGroup={existingGroup ? handleEditGroup : undefined}
          initialGroupName={existingGroup?.name}
        />
      </Footer>
    </GroupEditor>
  );
};
