import { type SelectableItem } from "../SelectableItem";

export type SearchStrategy = {
  expandItem: (item: SelectableItem) => void;
  getExpandedItems: () => SelectableItem[];
  getItems: () => SelectableItem[];
  getStatus: () => SearchStrategyStatus;
  hierarchyLevelSelected: (
    item: SelectableItem,
    shortName: string,
    code: string,
    displayName: string
  ) => void;
  initialize: () => void;
  loadMore: (item: SelectableItem) => void;
  onExpandedItemsChanged: ((items: SelectableItem[]) => void) | undefined;
  onItemsChanged: ((status: SelectableItem[]) => void) | undefined;
  onStatusChanged: ((status: SearchStrategyStatus) => void) | undefined;
  setSearchQuery: (query: string) => void;
  unexpandItem: (item: SelectableItem) => void;
};

export enum SearchStrategyStatus {
  Uninitialized = 0,
  Loading = 1,
  Success = 2,
  Error = 3,
}
