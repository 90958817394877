import {
  type TransactionSourceAccess,
  type SelectionDto,
  type CustomerGroupType,
  type SegmentationType,
  type TransactionSource,
} from "@quantium-enterprise/common-ui";
import { type ContextMenuType } from "components-ui/src/tables/common/table-cell/ContextMenu";
import { type NumberFormat } from "../enums/NumberFormat";
import { type SegmentValueDto } from "./segmentation-selection-dto";

export type ParameterConfigurationsDto = {
  divisionName?: string;
  parameterGroups?: ParameterGroupDto[];
  transactionSourceConfigs: TransactionSourceConfig[];
};

export type ErrorMessage = {
  message: string;
  parameters: string[];
};

export type ParameterGroupDto = {
  description: string;
  errorMessage?: ErrorMessage[];
  infoMessage?: string;
  label: string;
  maxSelections?: number;
  minSelections?: number;
  parameterValidationMessage?: string;
  parameters: ParameterDto[];
  warningMessage?: string;
};

export type ParameterDto = {
  attributes: ParameterAttributes;
  attributesFilterConfigs?: AttributesFilterConfig[];
  contextMenuType?: keyof typeof ContextMenuType;
  description: string;
  enableCustomerGroups?: boolean;
  hierarchyLevels: HierarchyLevel[];
  hierarchyType: string;
  id: string;
  name: string;
  options: ParameterOptionDto[];
  parameterType: string;
  productGroupTransactionLevel?: TransactionSource;
  searchTerm?: string;
  selections?: SelectionDto[];
  universeRestrictedHierarchyLevel?: string;
};

export type ParameterOptionDto = {
  description: string;
  groupName: string;
  isAggregate: boolean;
  isDefault: boolean;
  isDisabled: boolean;
  label: string;
  options?: ParameterOptionDto[];
  value: string;
};

export type HierarchyLevel = {
  isSelectable: boolean;
  shortName: string;
};

export type HierarchyParameterOptionDto = ParameterOptionDto & {
  depth?: number;
  shortName: string;
  transactionSourceAccess?: TransactionSourceAccess;
};

export type TimePeriodParameterOption = ParameterOptionDto & {
  customLength: boolean;
  endDate: string;
  maxWeeks?: number;
  minWeeks?: number;
  startDate: string;
};

export type PromoWeekOption = ParameterOptionDto & {
  endDate: string;
  startDate: string;
};

export type TrendedandAggregatedParameterOption = ParameterOptionDto & {
  timePeriodDataType: string;
};

export type HierarchyAttributeParameterOption = ParameterOptionDto & {
  isHierarchical: boolean;
  isLeaf?: boolean;
  minSelections?: number;
};

export type SegmentationParameterOption = ParameterOptionDto & {
  segmentationType: SegmentationType;
  segments?: SegmentValueDto[];
  status?: string;
  type?: CustomerGroupType;
};

export type MeasureThresholdParameterOption = ParameterOptionDto & {
  options: ParameterOptionDto[];
  thresholdFormat: NumberFormat;
};

export type ParameterDependency = {
  message: string;
  parameterId: string;
};

export type ParameterAttributes = {
  dependency?: ParameterDependency;
  displayType: string;
  excludeFromParameterGroupValidation: boolean;
  isTabbedDisplay: boolean;
  maxSelections: number;
  minSelections: number;
  validateOnlyIfDependencyIsValid: boolean;
};

export type TransactionSourceConfig = {
  displayName: string;
  preferenceOrdinal?: number;
  transactionSource: TransactionSource;
  transactionSourceOverwrite?: TransactionSource;
  transactionSourceSuffix?: string;
};

export type AttributesFilterConfig = {
  filters: Record<string, string[] | undefined>;
  message?: string;
};

export const isParameterConfigurationDto = (
  object: object
): object is ParameterConfigurationsDto =>
  Object.hasOwn(object, "transactionSourceConfigs");

export const isTimePeriodParameterOption = (
  option: ParameterOptionDto
): option is TimePeriodParameterOption =>
  (Object.hasOwn(option, "startDate") && Object.hasOwn(option, "endDate")) ||
  Object.hasOwn(option, "maxWeeks") ||
  Object.hasOwn(option, "minWeeks");

export const isPromoWeekOption = (
  option: ParameterOptionDto
): option is PromoWeekOption =>
  Object.hasOwn(option, "startDate") && Object.hasOwn(option, "endDate");

export const isHierarchyParameterOptionDto = (
  option: ParameterOptionDto
): option is HierarchyParameterOptionDto =>
  Object.hasOwn(option, "depth") && Object.hasOwn(option, "shortName");

export const isHierarchyAttributeParameterOptionDto = (
  option: ParameterOptionDto
): option is HierarchyAttributeParameterOption =>
  Object.hasOwn(option, "isHierarchical");

export const isTrendedAndAggregatedParameterOption = (
  option: ParameterOptionDto
): option is TrendedandAggregatedParameterOption =>
  Object.hasOwn(option, "timePeriodDataType");

export type MetricsOptionDto = ParameterOptionDto & {
  description: string;
  groupName: string;
  isAggregate: boolean;
  isDefault: boolean;
  isDisabled: boolean;
  label: string;
  metricList: MetricsOptionDto[];
  options: ParameterOptionDto[];
  value: string;
};

export const isMetricsOptionDto = (
  option: ParameterOptionDto
): option is MetricsOptionDto =>
  Object.hasOwn(option, "metricList") &&
  Array.isArray((option as MetricsOptionDto).metricList);
