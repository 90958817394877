import { TransactionSource } from "@quantium-enterprise/common-ui";
import { type TransactionSourceDto } from "@quantium-enterprise/common-ui/src/models/transaction-source-dto";
import CustomerGreyIcon from "../../assets/common/transaction-source-customer-grey-icon.svg";
import CustomerColourIcon from "../../assets/common/transaction-source-customer-icon.svg";
import LoyaltyGreyIcon from "../../assets/common/transaction-source-loyalty-grey-icon.svg";
import LoyaltyColourIcon from "../../assets/common/transaction-source-loyalty-icon.svg";
import SampleGreyIcon from "../../assets/common/transaction-source-sample-icon-grey.svg";
import SampleColourIcon from "../../assets/common/transaction-source-sample-icon.svg";
import TotalGreyIcon from "../../assets/common/transaction-source-total-icon-grey.svg";
import TotalColourIcon from "../../assets/common/transaction-source-total-icon.svg";
import TransactionalGreyIcon from "../../assets/common/transaction-source-transactional-icon-grey.svg";
import TransactionalColourIcon from "../../assets/common/transaction-source-transactional-icon.svg";
import styles from "./TransactionSourceIcon.module.css";

enum TransactionSourceIconIds {
  Customer = "Customer",
  Loyalty = "Loyalty",
  Sample = "Sample",
  Total = "Total",
  Transactional = "Transactional",
}

export enum TransactionSourceIconTestIds {
  CustomerIcon = "customer-icon",
  LoyaltyIcon = "loyalty-icon",
  NoIcon = "no-icon",
  SampleIcon = "sample-icon",
  TotalSalesIcon = "total-sales-icon",
  TransactionalIcon = "transactional-icon",
}

export const TransactionSourceDisplayNames: Record<TransactionSource, string> =
  {
    [TransactionSource.Total]: "Total sales data",
    [TransactionSource.Sample]: "Sample data",
    [TransactionSource.Customer]: "Loyalty data",
  };

export type TransactionSourceIconProps = {
  availableTransactionSources?: TransactionSourceDto[];
  greyedOut?: boolean;
  toolTipText?: string;
  transactionSource?: TransactionSource | null;
};

export const TransactionSourceIcon = ({
  availableTransactionSources,
  greyedOut = false,
  transactionSource,
  toolTipText,
}: TransactionSourceIconProps) => {
  const transactionSourceConfig = availableTransactionSources?.find(
    (ts) => ts.transactionSource === transactionSource
  );

  if (transactionSourceConfig) {
    let testId: TransactionSourceIconTestIds | undefined;
    let icon: string | undefined;

    switch (transactionSourceConfig.transactionSourceIcon) {
      case TransactionSourceIconIds.Customer:
        testId = TransactionSourceIconTestIds.CustomerIcon;
        icon = greyedOut ? CustomerGreyIcon : CustomerColourIcon;
        break;
      case TransactionSourceIconIds.Loyalty:
        testId = TransactionSourceIconTestIds.LoyaltyIcon;
        icon = greyedOut ? LoyaltyGreyIcon : LoyaltyColourIcon;
        break;
      case TransactionSourceIconIds.Sample:
        testId = TransactionSourceIconTestIds.SampleIcon;
        icon = greyedOut ? SampleGreyIcon : SampleColourIcon;
        break;
      case TransactionSourceIconIds.Total:
        testId = TransactionSourceIconTestIds.TotalSalesIcon;
        icon = greyedOut ? TotalGreyIcon : TotalColourIcon;
        break;
      case TransactionSourceIconIds.Transactional:
        testId = TransactionSourceIconTestIds.TransactionalIcon;
        icon = greyedOut ? TransactionalGreyIcon : TransactionalColourIcon;
        break;
      default:
        break;
    }

    if (testId && icon) {
      return (
        <img
          alt={transactionSourceConfig.displayName}
          className={styles.transactionSourceIcon}
          data-testid={testId}
          src={icon}
          title={toolTipText ?? transactionSourceConfig.displayName}
        />
      );
    }
  }

  return (
    <span className={styles.transactionSourceIcon}>
      <svg
        data-testid={TransactionSourceIconTestIds.NoIcon}
        fill="none"
        height="24"
        width="24"
      />
    </span>
  );
};
