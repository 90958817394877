import {
  type TransactionSource,
  formatStringDate,
  formatHierarchyName,
  HierarchyGroupEvaluationType,
  HierarchyGroupRuleOperator,
  HierarchyItemType,
  HierarchyType,
  type HierarchyGroupRuleSummaryDto,
  type HierarchyGroupSummaryDto,
  type SimpleHierarchyItemDto,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import {
  HierarchyGroupIcon,
  TransactionSourceIcon,
} from "components-ui/src/icons";
import { useEffect, useRef, useState } from "react";
import { GroupTransactionSourceIcon } from "../icons/group-transaction-source-icon/GroupTransactionSourceIcon";
import { HierarchySelectionSummary } from "../parameter-selection-summary";
import styles from "./HierarchyGroupSummary.module.css";

const LINE_HEIGHT = 25;

const formatHierarchyItemName = (item?: SimpleHierarchyItemDto) =>
  item?.name ? item.name : "Not available - " + item?.code;

const GroupRule = ({ rule }: { rule: HierarchyGroupRuleSummaryDto }) => {
  if (rule.values.length === 0) {
    return <> </>;
  }

  return (
    <>
      <span className={styles.ruleAttribute}>
        {rule.attributeName ?? rule.shortName}
      </span>
      <span className={styles.ruleOperator}>
        {rule.operator === HierarchyGroupRuleOperator.Is ? "is" : "is not"}
      </span>
      <span className={styles.ruleValues}>
        {formatHierarchyItemName(rule.values[0])}
      </span>
      <span className={styles.rulePlusMore}>
        {rule.values.length > 1 && `+ ${rule.totalValues - 1} more`}
      </span>
    </>
  );
};

type GroupRulesProps = {
  rules: HierarchyGroupRuleSummaryDto[];
};

const GroupRules = ({ rules }: GroupRulesProps) => (
  <div>
    <div className={styles.rulesGrid}>
      {rules.map((rule) => (
        <GroupRule
          key={`${rule.shortName}-${rule.operator}-${rule.values}`}
          rule={rule}
        />
      ))}
    </div>
  </div>
);

type GroupItem = {
  itemCode: string;
  name: string;
  shortName: string;
  type: HierarchyItemType;
};

const GroupItems = ({ group }: { group: HierarchyGroupSummaryDto }) => {
  const [visibleItems, setVisibleItems] = useState<GroupItem[]>([]);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const height = ref.current?.offsetHeight ?? 0;
    const maxVisible = Math.max(2, Math.floor(height / LINE_HEIGHT) - 2);
    const visibleCount = Math.min(group.items.length, maxVisible);
    setVisibleItems(
      group.items.slice(0, visibleCount).map((item) => ({
        itemCode: item.code,
        name: item.name ?? "Not available - " + item.code,
        shortName: item.shortName,
        type: HierarchyItemType.Leaf,
      }))
    );
  }, [group.items, group.totalItems, ref]);

  return (
    <div className={styles.groupItems} ref={ref}>
      {group.totalItems > 0 && (
        <HierarchySelectionSummary
          itemCount={group.totalItems}
          items={visibleItems}
          maxDepth={visibleItems.length}
          moreItemsText=" more"
        />
      )}
      {group.totalItems === 0 && <div>None available.</div>}
    </div>
  );
};

type HierarchyGroupSummaryProps = {
  group: HierarchyGroupSummaryDto | undefined;
  isDataEntitlementsShown?: boolean;
  isSubscriptionShown?: boolean;
  isTransactionSourceLoading?: boolean;
  title?: string;
  transactionSource?: TransactionSource | null;
};

export const HierarchyGroupSummary = ({
  group,
  isTransactionSourceLoading = false,
  isDataEntitlementsShown = false,
  isSubscriptionShown = false,
  title,
  transactionSource,
}: HierarchyGroupSummaryProps) => {
  const { transactionSources: availableTransactionSources } = useDivision();

  return (
    <div className={styles.groupSummary}>
      {group && (
        <>
          <div className={styles.summarySection}>
            <div className={styles.sectionHeader}>
              <h6>{title ?? group.name}</h6>
              <GroupTransactionSourceIcon
                isDataEntitlementsShown={isDataEntitlementsShown}
                isLoading={isTransactionSourceLoading}
                transactionSource={transactionSource}
              />
            </div>
            <div className={styles.sectionBody}>
              <div>
                Type:
                <span className={styles.evaluationIcon}>
                  <HierarchyGroupIcon evaluationType={group.evaluationType} />
                </span>
                {group.evaluationType}
              </div>
              {isSubscriptionShown && group.transactionSource && (
                <div>
                  Dataset:
                  <span className={styles.evaluationIcon}>
                    <TransactionSourceIcon
                      availableTransactionSources={availableTransactionSources}
                      transactionSource={group.transactionSource}
                    />
                  </span>
                  {
                    // TODO: Swap for desired name once decided.
                    // For now display transaction source as is.
                    group.transactionSource
                  }
                </div>
              )}
              <div>
                Total {formatHierarchyName(group.hierarchyType, false, true)}:{" "}
                {group.totalItems}
              </div>
              <div>
                Created:{" "}
                {formatStringDate(group.createDateUtc, {
                  locale: "en-AU",
                  dropdown: true,
                })}
              </div>
              <div>
                Updated:{" "}
                {formatStringDate(group.updateDateUtc, {
                  locale: "en-AU",
                  dropdown: true,
                })}
              </div>
            </div>
          </div>
          {group.evaluationType === HierarchyGroupEvaluationType.Dynamic && (
            <div className={styles.summarySection}>
              <div className={styles.sectionHeader}>
                <h6>Rules (AND)</h6>
              </div>
              <div className={styles.sectionBody}>
                <GroupRules rules={group.rules} />
              </div>
            </div>
          )}
          <div className={styles.summarySection}>
            <div className={styles.sectionHeader}>
              <h6>
                {group.hierarchyType === HierarchyType.Location
                  ? "Stores"
                  : "Products"}
              </h6>
            </div>
            <div className={styles.sectionBody}>
              <GroupItems group={group} />
            </div>
          </div>
        </>
      )}
      {!group && <div>No group selected.</div>}
    </div>
  );
};
