import { notificationsApi, userApi } from "@quantium-enterprise/common-ui";
import { SearchConfigurationApi } from "@quantium-enterprise/fast-reporting-ui";
import { combineReducers, configureStore } from "@reduxjs/toolkit";

const rootReducer = combineReducers({
  [userApi.reducerPath]: userApi.reducer,
  [notificationsApi.reducerPath]: notificationsApi.reducer,
  [SearchConfigurationApi.reducerPath]: SearchConfigurationApi.reducer,
});

export const store = configureStore({
  devTools: { name: "checkout-ui" },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      userApi.middleware,
      notificationsApi.middleware,
      SearchConfigurationApi.middleware
    ),
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
