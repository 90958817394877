import { downloadFileFromResponse } from "components-ui/src/export/export-functions";
import { type ExportCsvRequest } from "../models/aggregate-rank-table-models";
import { aggregateRankApiSlice } from "./aggregate-rank-api-slice";

export const aggregateRankExportApiSlice =
  aggregateRankApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      downloadTable: builder.query<
        Blob,
        { divisionName: string; payload: ExportCsvRequest }
      >({
        query: ({ divisionName, payload }) => ({
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          method: "POST",
          url: `/GetExportTableRows/${divisionName}`,
          responseHandler: async (response: Response) =>
            await downloadFileFromResponse(
              response,
              `aggregate rank csv data for report ${payload.reportId}`
            ),
          cache: "no-cache",
        }),
        keepUnusedDataFor: 0,
      }),
      downloadAsExcel: builder.query<
        Blob,
        { divisionName: string; payload: ExportCsvRequest }
      >({
        query: ({ divisionName, payload }) => ({
          body: payload,
          headers: { "Content-type": "application/json; charset=UTF-8" },
          method: "POST",
          url: `/ExportAsExcel/${divisionName}`,
          responseHandler: async (response: Response) =>
            await downloadFileFromResponse(
              response,
              `aggregate rank excel data for report ${payload.reportId}`
            ),
          cache: "no-cache",
        }),
        keepUnusedDataFor: 0,
      }),
    }),
  });

export const { useLazyDownloadTableQuery, useLazyDownloadAsExcelQuery } =
  aggregateRankExportApiSlice;
