import { type TransactionSource, type RestrictionType } from "../enums";

export const FAST_REPORTING_FEATURE_NAME = "fast-reporting";
export const ADVANCED_REPORTING_FEATURE_NAME = "report";
export const SCAN_DATA_EXPORT_FEATURE_NAME = "scan-data-export";

export type ItemIdentifier = {
  code: string;
  shortName: string;
};

export type AttributeFilter = {
  codes: string[];
  shortName: string;
};

export type FilterCriteria = {
  excludeFilters: AttributeFilter[];
  includeFilters: AttributeFilter[];
};

export type TransactionSourceAccess = Record<
  string,
  TransactionSource[] | undefined
>;

export type RestrictionRequirementDefinitionDto = {
  restrictionType: RestrictionType;
  values: string[];
};

export enum FilterMode {
  Strict = 0,
  PreserveAncestors = 1,
}

export type FeatureFilter = {
  feature?: string;
  mode: FilterMode;
  module: string;
};

export type TransactionSourceFilter = {
  level?: string;
  mode: FilterMode;
  source: TransactionSource;
};

export type HierarchyItemDto = {
  code: string;
  depth: number;
  features?: Record<string, string[] | undefined>;
  name: string;
  parent: HierarchyParentItemDto;
  restrictions?: RestrictionRequirementDefinitionDto[];
  shortName: string;
  transactionSourceAccess?: TransactionSourceAccess;
};

export type HierarchyParentItemDto = {
  code: string;
  depth: number;
  name: string;
  shortName: string;
  transactionSourceAccess?: TransactionSourceAccess;
};

export type NodeExpansionRequestDto = {
  featureFilter?: FeatureFilter;
  page: number;
  pageSize?: number;
  parent: ItemIdentifier;
  transactionSourceFilter?: TransactionSourceFilter;
};

export type BulkNodeExpansionRequestDto = {
  page: number;
  pageSize?: number;
  parent: ItemIdentifier[];
};

export type HierarchyItemsRequestDto = {
  featureFilter?: FeatureFilter;
  items: ItemIdentifier[];
  page: number;
  pageSize?: number;
  transactionSourceFilter?: TransactionSourceFilter;
};

export type HierarchyRequestDto = {
  filters: AttributeFilter[];
  page: number;
  pageSize?: number;
};

export type LocateAttributeRequestDto = {
  attribute: ItemIdentifier;
  featureFilter?: FeatureFilter;
  levelShortNames: string[];
  page: number;
  pageSize?: number;
};

export type RootNodesRequestDto = {
  featureFilter?: FeatureFilter;
  page: number;
  pageSize?: number;
  transactionSourceFilter?: TransactionSourceFilter;
};

export type SearchRequestDto = {
  excludeFilters?: AttributeFilter[];
  featureFilter?: FeatureFilter;
  filters?: AttributeFilter[];
  focalAttributes?: string[];
  includeBarcodes?: boolean;
  includeCodes?: boolean;
  page: number;
  pageSize?: number;
  query?: string;
  transactionSourceFilter?: TransactionSourceFilter;
};

export type HierarchyCountRequestDto = {
  selectedItems: AttributeFilter[];
  shortNamesToCount: string[];
};

export type AttributesRequestDto = {
  filters: FilterCriteria[];
  focalAttributes: string[];
};

export type HierarchyServiceResponseDto = {
  count: number;
  hasNextPage: boolean;
  results: HierarchyItemDto[];
};

export type HierarchyStructureResponseDto = {
  name: string;
  ordinal: number;
  shortName: string;
  structureName: string;
};

export type HierarchyAttributeResponseDto = {
  name: string;
  ordinal: number;
  shortName: string;
  uiShortName: string;
};

export type HierarchyMetadataResponseDto = {
  isLeaf?: boolean;
  isRoot?: boolean;
  name: string;
  ordinal: number;
  shortName: string;
  structureName?: string;
  uiShortName: string;
};
