import { type HierarchyItem } from "@quantium-enterprise/common-ui";
import { type SubscriptionDto } from "@quantium-enterprise/common-ui/src/models/subscription-dto";
import { type TransactionSourceDto } from "@quantium-enterprise/common-ui/src/models/transaction-source-dto";
import { type Row } from "@tanstack/react-table";
import { TransactionSourceIcon } from "components-ui/src/icons";
import { getTransactionSource } from "../../utilities/group-subscription-utils";
import styles from "./TransactionSourceCell.module.css";

export type TransactionSourceCellProps = {
  accessLevelShortName: string;
  availableTransactionSources: TransactionSourceDto[];
  row: Row<HierarchyItem>;
  subscription: SubscriptionDto | undefined;
};

export const TransactionSourceCell = ({
  accessLevelShortName,
  availableTransactionSources,
  row,
  subscription,
}: TransactionSourceCellProps) => {
  const transactionSourceAccess =
    row.original.transactionSourceAccess?.[accessLevelShortName] ?? [];

  const transactionSource = getTransactionSource(
    transactionSourceAccess,
    subscription
  );

  return (
    <div
      className={styles.transactionSourceCell}
      data-testid="transaction-source-cell"
    >
      <TransactionSourceIcon
        availableTransactionSources={availableTransactionSources}
        greyedOut={!row.getIsSelected()}
        transactionSource={transactionSource}
      />
    </div>
  );
};
