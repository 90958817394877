export const viewTypes = {
  AGGREGATE: "aggregate",
  TRENDED: "trended",
} as const;

export type ViewType = (typeof viewTypes)[keyof typeof viewTypes];

export const getViewType = (value?: string): ViewType => {
  if (!value) return viewTypes.TRENDED;
  return value.toLowerCase().includes(viewTypes.AGGREGATE)
    ? viewTypes.AGGREGATE
    : viewTypes.TRENDED;
};
