import { format } from "date-fns";
import { type FocusPeriodOption } from "../models/FocusPeriodOption";

export const convertToDateTime = (dateString: string): Date => {
  const match = /^(\d{4})(\d{2})(\d{2})$/u.exec(dateString);
  if (!match) {
    throw new Error("Invalid date format");
  }

  const [, year, month, day] = match.map(Number);

  return new Date(year, month - 1, day);
};

const formatDate = (date: Date): string => format(date, "dd MMM yy");

export const selectFocusPeriodText = (
  focusPeriod: FocusPeriodOption | undefined
) => {
  if (!focusPeriod?.startDate || !focusPeriod.endDate) {
    return "";
  }

  const formattedStartDate = formatDate(
    convertToDateTime(focusPeriod.startDate)
  );
  const formattedEndDate = formatDate(convertToDateTime(focusPeriod.endDate));

  return `${formattedStartDate} - ${formattedEndDate}`;
};
