import { getNumberFormat, useDivision } from "@quantium-enterprise/hooks-ui";
import classNames from "classnames";
import { DataTableOptions } from "components-ui/src/data-table-options/DataTableOptions";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { type purchasedWithAssociatedData } from "../models/basket-affinities-chart-models";
import {
  selectFocalItem,
  selectLocalParametersSelection,
  selectPurchasedWithAssociated,
  selectPurchasedWithProductsSortingState,
  selectReportParameters,
} from "../services/basket-affinities-slice";
import { getExportParameterSummary } from "../utils/getExportParameterSummary";
import styles from "./BasketAffinitiesPurchasedWithFocalItemsTableExport.module.css";
import { csvPurchasedWithFocalItems } from "./csvTransformationEnhanced";

export const BasketAffinitiesPurchasedWithFocalItemsTableEnhancedExport =
  () => {
    const focalItem = useSelector(selectFocalItem);
    const tableData: purchasedWithAssociatedData[] = useSelector(
      selectPurchasedWithAssociated
    );
    const sortingState = useSelector(selectPurchasedWithProductsSortingState);
    const reportParameters = useSelector(selectReportParameters);

    const localParametersSelection = useSelector(
      selectLocalParametersSelection
    );
    const { locale, currency } = useDivision();
    const currencySymbol = useMemo(() => {
      const { getCurrencySymbol } = getNumberFormat(locale, currency);
      return getCurrencySymbol();
    }, [locale, currency]);

    const exportFilename = useMemo(
      () =>
        cleanFilename(
          `Purchased_With_Focal_Item_Table_${localParametersSelection.timePeriodLength}_${localParametersSelection.location.name}`
        ),
      [localParametersSelection]
    );

    const localParameters = useMemo(
      () => getExportParameterSummary(localParametersSelection, focalItem),
      [localParametersSelection, focalItem]
    );

    const csvTransformationCallback = useCallback(
      () =>
        csvPurchasedWithFocalItems(
          tableData,
          sortingState,
          localParametersSelection.upliftThreshold,
          localParametersSelection.basketsWithBothThreshold,
          currencySymbol
        ),
      [tableData, sortingState, localParametersSelection, currencySymbol]
    );

    return (
      <div className={classNames(styles.chartOptionsContainer)}>
        <DataTableOptions
          filename={exportFilename}
          invokeCSVDownload={csvTransformationCallback}
          isFeatureEnabled
          localParameters={localParameters}
          reportParameters={reportParameters}
        />
      </div>
    );
  };
