import {
  type TransactionSource,
  type FeatureFilter,
  type TransactionSourceFilter,
} from "@quantium-enterprise/common-ui";
import { FilterMode } from "@quantium-enterprise/common-ui";
import { type SubscriptionDto } from "@quantium-enterprise/common-ui/src/models/subscription-dto";

export const getTransactionSource = (
  validTransactionSources: TransactionSource[],
  selectedSubscription: SubscriptionDto | undefined
): TransactionSource | null => {
  if (!selectedSubscription) {
    return null;
  } else if (
    validTransactionSources.includes(selectedSubscription.transactionSource)
  ) {
    return selectedSubscription.transactionSource;
  } else {
    return null;
  }
};

export const getFeatureModules = (
  subscription: SubscriptionDto | undefined
): string[] | undefined => {
  if (!subscription) {
    return undefined;
  }

  return [subscription.featureModule];
};

export const getFeatureFilter = (
  subscription: SubscriptionDto | undefined,
  mode?: FilterMode
): FeatureFilter | undefined => {
  if (!subscription) {
    return undefined;
  }

  return {
    mode: mode ?? FilterMode.PreserveAncestors,
    module: subscription.featureModule,
  };
};

export const getTransactionSourceFilter = (
  subscription: SubscriptionDto | undefined,
  mode?: FilterMode
): TransactionSourceFilter | undefined => {
  if (!subscription) {
    return undefined;
  }

  return {
    mode: mode ?? FilterMode.PreserveAncestors,
    source: subscription.transactionSource,
  };
};
