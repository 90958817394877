import { InlineIcon, InlineIconGlyph } from "@qbit/react";
import {
  REPORT_CREATOR_PATH,
  MY_REPORTS_PATH,
  MY_DASHBOARD_PATH,
  GROUPS_PATH,
  type Division,
  type UserDto,
  type RouteWithLabel,
  useEventTrackingServiceContext,
  TrackingComponent,
  TrackingEvent,
  NavigationTrackingProperties,
  NavigationTrackingProperty,
  NavigationTrackingPropertyValue,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import classNames from "classnames";
import {
  type Dispatch,
  type SetStateAction,
  type ReactNode,
  type ReactElement,
  Children,
  cloneElement,
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
  useMemo,
  isValidElement,
} from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./AppSideBar.module.css";
import { DivisionDropdown } from "./DivisionDropdown";

const getCurrentRouteState = function (
  route: RouteWithLabel,
  pathname: string
): string {
  return route.path && pathname.includes(route.path) ? styles.selected : "";
};

/**
 * Sidebar context used to track internal hover states via provider
 */
type SidebarContextType = {
  isHovering: boolean;
  setIsHovering: Dispatch<SetStateAction<boolean>>;
};

const SidebarContext = createContext<SidebarContextType>({
  isHovering: false,
  setIsHovering: () => {},
});

/**
 * Sidebar container where we attach the internal hover state to
 */
type SidebarProps = {
  children: JSX.Element | ReactNode;
};

const Sidebar = (properties: SidebarProps) => {
  const [isHovering, setIsHovering] = useState(false);
  const defaultSidebarContext = useMemo(
    () => ({ isHovering, setIsHovering }),
    [isHovering, setIsHovering]
  );
  return (
    <SidebarContext.Provider value={defaultSidebarContext}>
      <div
        className={classNames(styles.sidebar, { [styles.hover]: isHovering })}
        onMouseEnter={() => {
          setIsHovering(true);
        }}
        onMouseLeave={() => {
          setIsHovering(false);
        }}
      >
        {Children.map(properties.children, (child) => {
          let element = null;
          if (isValidElement(child)) {
            element = cloneElement<SidebarContextType>(
              child as ReactElement<SidebarContextType>,
              { isHovering, setIsHovering }
            );
          }

          return element;
        })}
      </div>
    </SidebarContext.Provider>
  );
};

/**
 * Provides a list of items that are cloned and appended with onBlur and
 * onFocus event listeners
 */
type ListProps = {
  children: JSX.Element | ReactNode;
};

const List = ({ children }: ListProps) => {
  const { setIsHovering } = useContext(SidebarContext);
  return (
    <div
      className={styles.sidebarItems}
      onBlur={() => {
        setIsHovering(false);
      }}
      onFocus={() => {
        setIsHovering(true);
      }}
    >
      {children}
    </div>
  );
};

/**
 * Provides the checkout logo and text
 */
const HeaderItem = () => (
  <button className={styles.sidebarHeaderItem} type="button">
    <span className={styles.qLogo} />
  </button>
);

/**
 * Provides the dropdown division selection
 */
type DivisionDropdownItemProps = {
  activeDivision: Division;
  divisionIcon: string;
  user: UserDto;
};

const DivisionDropdownItem = ({
  activeDivision,
  divisionIcon,
  user,
}: DivisionDropdownItemProps) => {
  const sidebarRef = useRef(null);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const handleDropdownClick = useCallback(() => {
    setIsDropdownVisible((previous) => !previous);
  }, [setIsDropdownVisible]);
  const hideDropdown = useCallback(() => {
    setIsDropdownVisible(false);
  }, [setIsDropdownVisible]);
  return (
    <>
      {isDropdownVisible && (
        <div
          className={styles.divisionDropdownGhost}
          onClick={hideDropdown}
          role="presentation"
        />
      )}
      <div
        className={styles.divisionDropdownContainer}
        onClick={handleDropdownClick}
        onKeyUp={(event) => {
          if (
            document.activeElement === sidebarRef.current &&
            event.key === "enter"
          )
            handleDropdownClick();
        }}
        ref={sidebarRef}
        role="button"
        tabIndex={0}
      >
        <div
          className={styles.divisionDropdown}
          data-testid="division-dropdown"
        >
          <span
            className={styles.divisionImage}
            style={{ background: `url(${divisionIcon})` }}
          />
          <div className={styles.divisionLabel}>
            <span className={styles.divisionLabelPrefix}>Division</span>
            <div className={styles.divisionNameAndChevron}>
              <span className={styles.divisionName}>
                {activeDivision.displayName}
              </span>
              <InlineIcon
                className={styles.chevron}
                glyph={InlineIconGlyph.ArrowsChevronDown}
                text="Division dropdown arrow"
              />
            </div>
          </div>
        </div>
        {isDropdownVisible && (
          <DivisionDropdown activeDivision={activeDivision} user={user} />
        )}
      </div>
    </>
  );
};

/**
 * Provides a reach router Link
 */
type LinkItemProps = {
  callback?: () => void;
  children: JSX.Element | ReactNode;
  pathname: string;
  routeWithLabel: RouteWithLabel;
  toPath: string;
};
const LinkItem = ({
  children,
  pathname,
  routeWithLabel,
  toPath,
  callback,
}: LinkItemProps) => {
  const { setIsHovering } = useContext(SidebarContext);
  return (
    <Link
      className={`${styles.sidebarItem} ${getCurrentRouteState(
        routeWithLabel,
        pathname
      )}`}
      key={routeWithLabel.path}
      onClick={(event) => {
        setIsHovering(false);
        event.currentTarget.blur();
        callback?.();
      }}
      onKeyUp={(event) => {
        if (event.key === "enter") {
          setIsHovering(false);
          event.currentTarget.blur();
          callback?.();
        }
      }}
      to={toPath}
    >
      {children}
    </Link>
  );
};

Sidebar.List = List;
Sidebar.HeaderItem = HeaderItem;
Sidebar.DivisionDropdownItem = DivisionDropdownItem;
Sidebar.LinkItem = LinkItem;

/**
 * AppSideBar component exposed externally
 */
type AppSideBarProperties = {
  activeDivision: Division;
  dashboardRoute: RouteWithLabel;
  groupsRoute: RouteWithLabel;
  myReportsRoute: RouteWithLabel;
  reportCreatorRoute: RouteWithLabel;
  user: UserDto;
};

export const AppSideBar = ({
  activeDivision,
  myReportsRoute,
  reportCreatorRoute,
  dashboardRoute,
  groupsRoute,
  user,
}: AppSideBarProperties) => {
  const division = useDivision();
  const eventTrackingService = useEventTrackingServiceContext();
  const divisionIcon = new URL(
    `../../retailer/${activeDivision.uiAssetsPath}/${activeDivision.name}/division-icon.svg`,
    import.meta.url
  ).href;
  const pathname = useLocation().pathname;
  const showDivision = user.divisions.length > 1;
  return (
    <Sidebar>
      <Sidebar.List>
        <Sidebar.HeaderItem />
        {showDivision && (
          <Sidebar.DivisionDropdownItem
            activeDivision={activeDivision}
            divisionIcon={divisionIcon}
            user={user}
          />
        )}
        <Sidebar.LinkItem
          callback={() =>
            eventTrackingService.trackEvent(
              TrackingComponent.Dashboard,
              TrackingEvent.Navigated,
              new NavigationTrackingProperties(
                NavigationTrackingProperty.Source,
                NavigationTrackingPropertyValue.Sidebar
              )
            )
          }
          pathname={pathname}
          routeWithLabel={dashboardRoute}
          toPath={`/${activeDivision.name}/${MY_DASHBOARD_PATH}`}
        >
          <div className={styles.itemImageDashboard} />
          <div className={styles.itemLabel}>{dashboardRoute.label}</div>
        </Sidebar.LinkItem>

        {division.moduleAccess.reportCreator.hasAccess && (
          <Sidebar.LinkItem
            pathname={pathname}
            routeWithLabel={reportCreatorRoute}
            toPath={`/${activeDivision.name}/${REPORT_CREATOR_PATH}`}
          >
            <div className={styles.itemImageReportCreator} />
            <div className={styles.itemLabel}>{reportCreatorRoute.label}</div>
          </Sidebar.LinkItem>
        )}

        {division.moduleAccess.myReports.hasAccess && (
          <Sidebar.LinkItem
            pathname={pathname}
            routeWithLabel={myReportsRoute}
            toPath={`/${activeDivision.name}/${MY_REPORTS_PATH}`}
          >
            <div className={styles.itemImageMyReports}>
              {/* TODO: ADD BADGE AND CONNECT ONCE SSE NOTIFICATIONS IMPLEMENTED */}
              {/* <div className={styles.reportCountBadge}>{unopenedReportCount}</div> */}
            </div>
            <div className={styles.itemLabel}>{myReportsRoute.label}</div>
          </Sidebar.LinkItem>
        )}

        {division.moduleAccess.groups.hasAccess && (
          <Sidebar.LinkItem
            pathname={pathname}
            routeWithLabel={groupsRoute}
            toPath={`/${activeDivision.name}/${GROUPS_PATH}`}
          >
            <div className={styles.itemImageGroups} />
            <div className={styles.itemLabel}>{groupsRoute.label}</div>
          </Sidebar.LinkItem>
        )}
      </Sidebar.List>
    </Sidebar>
  );
};

export default AppSideBar;
