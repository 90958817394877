import { ParameterId } from "@quantium-enterprise/common-ui";
import { type ParameterDto } from "@quantium-enterprise/common-ui";
import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SearchBox } from "../../../../components/src/search-box/SearchBox";
import {
  metricsSearchChanged,
  selectMetricsFilteredItems,
  selectMetricsIsSelectedItemsShown,
  selectMetricsSearchString,
} from "../../states/report-wizard-slice";
import { type RootState } from "../../store";
import styles from "./MetricParameter.module.scss";
import { MetricsParameterTable } from "./MetricsParameterTable";
import { getMetricsState } from "./MetricsState";

export const METRICS_SEARCH_BOX_DEBOUNCE_TIME = 500;

type MetricsParameterProps = {
  parameterDto?: ParameterDto;
};

export const MetricsParameter: React.FC<MetricsParameterProps> = ({
  parameterDto,
}) => {
  const parameterType = parameterDto?.id ?? ParameterId.Metrics;
  const dispatch = useDispatch();

  const metricsState = useSelector(getMetricsState(parameterType));
  const searchString = useSelector((state: RootState) =>
    selectMetricsSearchString(parameterType as ParameterId, state)
  );
  const isSelectedItemsShown = useSelector((state: RootState) =>
    selectMetricsIsSelectedItemsShown(parameterType as ParameterId, state)
  );
  const filteredItems = useSelector((state: RootState) =>
    selectMetricsFilteredItems(parameterType as ParameterId, state)
  );

  // Search success state
  const isSearchSuccess = useMemo(() => {
    if (!searchString.trim() || isSelectedItemsShown) {
      return true;
    }

    return filteredItems.length > 0;
  }, [searchString, isSelectedItemsShown, filteredItems]);

  // Search handler
  const handleSearchChange = useCallback(
    (searchText: string) => {
      dispatch(
        metricsSearchChanged({
          parameterId: parameterType,
          searchString: searchText,
        })
      );
    },
    [dispatch, parameterType]
  );

  // Result message visibility
  const showSearchResultMessage = useMemo(
    () => Boolean(searchString.trim()),
    [searchString]
  );

  // Calculate result count
  const resultCount = useMemo(() => {
    if (!showSearchResultMessage) {
      return 0;
    }

    if (isSelectedItemsShown) {
      return filteredItems.length;
    }

    const allMetrics = filteredItems.flatMap((group) =>
      "metricList" in group ? group.metricList : [group]
    );

    const searchLower = searchString.toLowerCase();
    return allMetrics.filter((metric) =>
      metric.label.toLowerCase().includes(searchLower)
    ).length;
  }, [
    showSearchResultMessage,
    filteredItems,
    searchString,
    isSelectedItemsShown,
  ]);

  // Early return if no metrics state
  if (!metricsState) {
    return null;
  }

  return (
    <div className={styles.metricsParameter}>
      <div
        className={
          showSearchResultMessage
            ? styles.searchFilterRow
            : styles.emptySearchFilterRow
        }
      >
        <div className={styles.searchBox}>
          <SearchBox
            debounceTimeMs={METRICS_SEARCH_BOX_DEBOUNCE_TIME}
            enableDebounce
            onChange={handleSearchChange}
            placeholder="Search metrics"
          />
        </div>
        {showSearchResultMessage && (
          <div
            className={styles.resultCount}
            data-testid="search-results-message"
          >
            {`${resultCount} metric${resultCount === 1 ? "" : "s"} found`}
          </div>
        )}
      </div>
      <div className={styles.contentRow}>
        <MetricsParameterTable isSuccess={isSearchSuccess} />
      </div>
    </div>
  );
};
