import { datadogLogs } from "@datadog/browser-logs";
import { DefaultPrivacyLevel, datadogRum } from "@datadog/browser-rum";
import { ddLog, ENVIRONMENT } from "@quantium-enterprise/common-ui";
import packageJson from "../package.json";

if (import.meta.env.PROD) {
  // ApplicationId and clientToken don't change across environments, so no need to introduce env var.
  const clientToken = "pub8c5fc602bc1c2e41a5cc1a395345813e";
  const applicationId = "afb69caf-5b72-4662-8ebd-78ea5e0d6929";
  const env = String(ENVIRONMENT.VITE_ENV);
  const project = String(ENVIRONMENT.VITE_PROJECT);
  const service = packageJson.name;
  const site = "us3.datadoghq.com";
  const version = packageJson.version;

  // Configure RUM
  /* eslint-disable require-unicode-regexp  -- the 'u' expression flag in the regex isn't supported by all browsers, so disabling this to ensure all browsers are supported */
  /* eslint-disable unicorn/better-regex  -- the 'u' expression flag in the regex isn't supported by all browsers, so disabling this to ensure all browsers are supported */
  datadogRum.init({
    // second-to-last regex allows any internal IPs, last allows any
    allowedTracingUrls: [
      /https?:\/\/.*\.quantium\.com/u,
      /https?:\/\/.*\.quantium\.com.*/g,
      /https?:\/\/.*(10|172|192)(\.\d{1,3}\.\d{1,3}\.\d{1,3}).*/g,
      /.*/g,
    ],
    applicationId,
    clientToken,
    defaultPrivacyLevel: DefaultPrivacyLevel.MASK_USER_INPUT,
    env,
    service,
    // This must be disabled (set to 0) to avoid recording sensitive customer data
    sessionReplaySampleRate: 0,
    site,
    trackLongTasks: true,
    trackResources: true,
    trackUserInteractions: true,

    version,
  });
  datadogRum.setGlobalContextProperty("project", project);
  datadogRum.startSessionReplayRecording();
  /* eslint-enable require-unicode-regexp */
  /* eslint-enable unicorn/better-regex */

  // Configure Browser Logging
  datadogLogs.init({
    clientToken,
    site,
    service,
    env,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    version,
  });
}

// test browser logging - remove me
ddLog("If you can see this message in Datadog, browser logging is working!");
