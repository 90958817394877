import {
  type TimePeriodSelectionDto,
  type TimePeriodParameterOption,
  ParameterId,
  type ParameterDto,
  isTimePeriodSelectionDto,
  type SelectionDto,
  isTimePeriodParameterOption,
} from "@quantium-enterprise/common-ui";
import { createSelector } from "@reduxjs/toolkit";
import { addDays } from "date-fns";
import { type ParameterState } from "../../states/ParameterState";
import { type RootState } from "../../store";
import { TimePeriodOptions } from "./components/TimePeriodConstants";
import { type TimePeriodOption } from "./components/time-period-option";
import {
  getDifferenceInWeeks,
  timestampToDate,
  getEndDate,
  getStartDate,
  convertParametersToTimePeriodOptions,
  validateComparisonPeriodOptions,
  datetimeToIsoNoOffset,
} from "./utilities";

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export interface TimePeriodState extends ParameterState {
  customLength: boolean;
  endDate: string;
  errors: Record<string, boolean>;
  isMultiSelect?: boolean;
  label: string;
  options: TimePeriodOption[];
  selections?: TimePeriodSelectionDto[];
  startDate: string;
  value: string;
  weeks: number | undefined;
}

export const isTimePeriodState = (
  state: ParameterState
): state is TimePeriodState =>
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  state &&
  Object.prototype.hasOwnProperty.call(state, "startDate") &&
  Object.prototype.hasOwnProperty.call(state, "endDate") &&
  Object.prototype.hasOwnProperty.call(state, "label") &&
  Object.prototype.hasOwnProperty.call(state, "value") &&
  Object.prototype.hasOwnProperty.call(state, "weeks") &&
  Object.prototype.hasOwnProperty.call(state, "options");

export enum ErrorKeys {
  endDate = "endDate",
  invalidWeekRange = "invalidWeekRange",
  label = "label",
  selections = "selections",
  startDate = "startDate",
  value = "value",
  weeks = "weeks",
}

export const validateFields = (
  value: string,
  label: string,
  startDate: string,
  endDate: string,
  weeks: number | undefined,
  options: TimePeriodOption[]
) => {
  const errors: Record<string, boolean> = {};
  if (!endDate) {
    errors[ErrorKeys.endDate] = true;
  }

  if (!startDate) {
    errors[ErrorKeys.startDate] = true;
  }

  if (!value) {
    errors[ErrorKeys.value] = true;
  }

  if (!label) {
    errors[ErrorKeys.label] = true;
  }

  if (weeks === undefined) {
    errors[ErrorKeys.weeks] = true;
  }

  const isWarning = options.every((option) => option.disabled);

  return { errors, isValid: Object.keys(errors).length === 0, isWarning };
};

export const validateMultiSelect = (
  timePeriod: TimePeriodState
): Record<string, boolean> => {
  const errors: Record<string, boolean> = {};

  if (!timePeriod.selections || timePeriod.selections.length < 1) {
    errors[ErrorKeys.value] = true;
  }

  if (timePeriod.selections) {
    const hasInvalidSelection = timePeriod.selections.some(
      (sel) => !sel.periodValue || !sel.label || !sel.startDate || !sel.endDate
    );
    if (hasInvalidSelection) {
      errors[ErrorKeys.selections] = true;
    }
  }

  return errors;
};

export const validateTimePeriod = (timePeriod: TimePeriodState) => {
  if (
    timePeriod.isMultiSelect &&
    timePeriod.value !== TimePeriodOptions.CUSTOM_PERIOD
  ) {
    const errors = validateMultiSelect(timePeriod);
    return {
      errors,
      isValid: Object.keys(errors).length === 0,
      isWarning: timePeriod.options.every((option) => option.disabled),
    };
  }

  return validateFields(
    timePeriod.value,
    timePeriod.label,
    timePeriod.startDate,
    timePeriod.endDate,
    timePeriod.weeks,
    timePeriod.options
  );
};

export const resetTimePeriodState = (
  parameterConfig: ParameterDto,
  parameterGroup: string
): TimePeriodState => ({
  endDate: "",
  errors: {
    value: true,
  },
  isValid: false,
  isWarning: false,
  label: "",
  options: convertParametersToTimePeriodOptions(parameterConfig.options),
  parameterConfig,
  parameterGroup,
  startDate: "",
  value: "",
  weeks: undefined,
  customLength: false,
});

const newStateForCustomLengthSelection = (
  selection: TimePeriodSelectionDto,
  parameterConfig: ParameterDto,
  state: TimePeriodState
) => {
  let newState = state;
  const [periodType, weeks] = selection.periodValue.split("-");
  if (periodType && Number(weeks) >= 0) {
    const result = parameterConfig.options.find(
      (option) => (option as TimePeriodParameterOption).customLength
    ) as TimePeriodParameterOption | undefined;
    let startDate = selection.startDate;
    let endDate = selection.endDate;
    if (result) {
      const minWeeks = result.minWeeks ?? 1;
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      const optionsOffsetFromEnd = (Number(weeks) ?? 0) - minWeeks;
      // Custom length options is only used for latest and to date options.
      // This means they will contain the end date. Only start date will be variable.
      endDate = result.endDate;
      // We assume the date options are ordered from the backend.
      if (
        result.options &&
        optionsOffsetFromEnd >= 0 &&
        optionsOffsetFromEnd < result.options.length
      ) {
        const option =
          result.options[result.options.length - optionsOffsetFromEnd - 1];
        if (isTimePeriodParameterOption(option)) {
          startDate = option.startDate;
        }
      }
    }

    newState = {
      ...newState,
      endDate,
      label: selection.label,
      startDate,
      value: TimePeriodOptions.CUSTOM_LATEST_PERIOD,
      weeks: Number(weeks),
      customLength: true,
    };
  }

  return newState;
};

const newStateForStandardSelection = (
  selection: TimePeriodSelectionDto,
  parameterConfig: ParameterDto,
  state: TimePeriodState
) => {
  let newState = state;
  const result = parameterConfig.options.find(
    (option) => option.value === selection.periodValue
  ) as TimePeriodParameterOption | undefined;
  if (result) {
    newState = {
      ...newState,
      endDate: result.endDate,
      label: result.label,
      startDate: result.startDate,
      value: result.value,
      weeks: getDifferenceInWeeks(
        timestampToDate(result.startDate),
        timestampToDate(result.endDate)
      ),
      customLength: false,
    };
  }

  return newState;
};

const newStateForMultipleSelection = (
  selections: SelectionDto[],
  parameterConfig: ParameterDto,
  state: TimePeriodState
) => {
  let newState = state;
  const selectionDtos = selections.filter(isTimePeriodSelectionDto);

  newState = {
    ...newState,
    endDate: "",
    startDate: "",
    value: selectionDtos.map((opt) => opt.periodValue).join(","),
    label: selectionDtos.map((opt) => opt.label).join(", "),
    weeks: undefined,
    selections: selectionDtos,
    isMultiSelect: true,
  } as TimePeriodState;

  return newState;
};

const newStateForCustomRangeSelection = (
  selection: TimePeriodSelectionDto,
  newState: TimePeriodState
) => ({
  ...newState,
  endDate: selection.endDate,
  label: selection.label,
  startDate: selection.startDate,
  value: selection.periodValue,
  weeks: getDifferenceInWeeks(
    timestampToDate(selection.startDate),
    timestampToDate(selection.endDate)
  ),
});

export const newTimePeriodState = (
  parameterConfig: ParameterDto,
  parameterGroup: string,
  savedSelections?: SelectionDto[]
): TimePeriodState => {
  const defaultSelection = parameterConfig.options.find(
    (option) => option.isDefault
  ) as TimePeriodParameterOption | undefined;

  const prefilledSelection =
    savedSelections ?? parameterConfig.selections ?? [];
  let newState = resetTimePeriodState(parameterConfig, parameterGroup);
  if (defaultSelection) {
    newState = {
      ...newState,
      endDate: defaultSelection.endDate,
      label: defaultSelection.label,
      startDate: defaultSelection.startDate,
      value: defaultSelection.value,
      weeks: getDifferenceInWeeks(
        timestampToDate(defaultSelection.startDate),
        timestampToDate(defaultSelection.endDate)
      ),
      customLength: defaultSelection.customLength,
    };
  }

  if (prefilledSelection.length > 0) {
    const selection = prefilledSelection[0];

    if (isTimePeriodSelectionDto(selection)) {
      if (
        selection.periodValue === TimePeriodOptions.CUSTOM_PERIOD ||
        parameterConfig.id === ParameterId.LeadPeriod
      ) {
        newState = newStateForCustomRangeSelection(selection, newState);
      } else if (selection.customLength) {
        newState = newStateForCustomLengthSelection(
          selection,
          parameterConfig,
          newState
        );
      } else if (prefilledSelection.length > 1) {
        newState = newStateForMultipleSelection(
          prefilledSelection,
          parameterConfig,
          newState
        );
      } else {
        newState = newStateForStandardSelection(
          selection,
          parameterConfig,
          newState
        );
      }
    }
  }

  const validation = validateTimePeriod(newState);

  return {
    ...newState,
    errors: validation.errors,
    isValid: validation.isValid,
    isWarning: validation.isWarning,
  };
};

export const handleMultipleSelection = (
  timePeriodState: ParameterState,
  selections: string[]
): ParameterState => {
  if (!isTimePeriodState(timePeriodState)) {
    return timePeriodState;
  }

  const uniqueSelectionValues = [...new Set(selections)];

  const selectedOptions = uniqueSelectionValues
    .map((sel) => timePeriodState.options.find((opt) => opt.value === sel))
    .filter((opt): opt is TimePeriodOption => opt !== undefined);
  const selectionDtos: TimePeriodSelectionDto[] = selectedOptions.map(
    (opt) => ({
      endDate: opt.endDate,
      label: opt.label,
      startDate: opt.startDate,
      periodValue: opt.value,
      customLength: opt.customLength,
    })
  );

  const updatedState = {
    ...timePeriodState,
    endDate: "",
    startDate: "",
    value: selectedOptions.map((opt) => opt.value).join(","),
    label: selectedOptions.map((opt) => opt.label).join(", "),
    weeks: undefined,
    selections: selectionDtos,
    isMultiSelect: true,
  } as TimePeriodState;

  const validation = validateTimePeriod(updatedState);

  return {
    ...updatedState,
    errors: validation.errors,
    isValid: validation.isValid,
    isWarning: validation.isWarning,
  } as ParameterState;
};

export const getTimePeriodState = (parameterId: string) =>
  createSelector(
    (state: RootState) => state.reportParameter.parameters[parameterId],
    (state) => state as TimePeriodState | undefined
  );

export const handleFocusPeriodSelection = (
  focusPeriodState: ParameterState,
  selection: string[] | string
): ParameterState => {
  if (Array.isArray(selection)) {
    return handleMultipleSelection(focusPeriodState, selection);
  }

  if (isTimePeriodState(focusPeriodState)) {
    let updatedFocusPeriodState = resetTimePeriodState(
      focusPeriodState.parameterConfig,
      focusPeriodState.parameterGroup
    );
    // Get options which contains the start & end date
    const result = focusPeriodState.options.find(
      (option) => option.value === selection
    );

    if (result) {
      const weeks = result.customLength
        ? focusPeriodState.weeks
        : getDifferenceInWeeks(
            timestampToDate(result.startDate),
            timestampToDate(result.endDate)
          );
      const minWeeks = result.minWeeks ?? 1;
      const optionsOffsetFromEnd = (weeks ?? 0) - minWeeks;
      // Custom length options is only used for latest and to date options.
      // This means they will contain the end date. Only start date will be variable.
      const endDate = result.endDate;
      // We assume the date options are ordered from the backend.
      const startDate =
        result.customLength &&
        optionsOffsetFromEnd >= 0 &&
        optionsOffsetFromEnd < result.options.length
          ? result.options[result.options.length - optionsOffsetFromEnd - 1]
              .startDate
          : result.startDate;
      updatedFocusPeriodState = {
        ...focusPeriodState,
        endDate,
        label: result.label,
        startDate,
        value: selection,
        weeks,
        customLength: result.customLength,
      } as TimePeriodState;
    }

    const validation = validateTimePeriod(updatedFocusPeriodState);

    return {
      ...updatedFocusPeriodState,
      errors: validation.errors,
      isValid: validation.isValid,
      isWarning: validation.isWarning,
    } as ParameterState;
  }

  // Default is don't do anything to change the state
  return { ...focusPeriodState };
};

export const handleComparisonPeriodSelection = (
  parameters: Record<string, ParameterState>,
  selection?: string
): Record<string, ParameterState> => {
  const comparisonPeriodExists = Object.keys(parameters).includes(
    ParameterId.ComparisonPeriod
  );

  if (comparisonPeriodExists) {
    const comparisonPeriodState = parameters[ParameterId.ComparisonPeriod];
    const focusPeriodState = parameters[ParameterId.FocusPeriod];

    // Type guard check first
    // https://dev.to/ylerjen/typescript-cast-is-a-type-breaker-1jbh
    if (
      isTimePeriodState(comparisonPeriodState) &&
      isTimePeriodState(focusPeriodState)
    ) {
      let updatedComparisonPeriodState = resetTimePeriodState(
        comparisonPeriodState.parameterConfig,
        comparisonPeriodState.parameterGroup
      );
      updatedComparisonPeriodState = validateComparisonPeriodOptions(
        updatedComparisonPeriodState,
        focusPeriodState
      );
      // Get options which contains the start & end date
      const result = updatedComparisonPeriodState.options.find(
        (option) => option.value === selection
      );

      if (result && selection) {
        updatedComparisonPeriodState = {
          ...comparisonPeriodState,
          endDate: result.endDate,
          label: result.label,
          startDate: result.startDate,
          value: selection,
          weeks: focusPeriodState.weeks,
          customLength: result.customLength,
        } as TimePeriodState;
      }

      const validation = validateTimePeriod(updatedComparisonPeriodState);

      parameters[ParameterId.ComparisonPeriod] = {
        ...updatedComparisonPeriodState,
        errors: validation.errors,
        isValid: validation.isValid,
        isWarning: validation.isWarning,
      } as ParameterState;
    }
  }

  return parameters;
};

export const handleLeadPeriodSelection = (
  leadPeriodState: ParameterState,
  focusPeriodState: ParameterState,
  selection?: string
): ParameterState => {
  if (
    !isTimePeriodState(leadPeriodState) ||
    !isTimePeriodState(focusPeriodState)
  ) {
    return { ...leadPeriodState };
  }

  const internalSelection = selection ?? leadPeriodState.value;
  let updatedLeadPeriodState = resetTimePeriodState(
    leadPeriodState.parameterConfig,
    leadPeriodState.parameterGroup
  );

  const result = leadPeriodState.options.find(
    (option) => option.value === internalSelection
  );

  const focusPeriodStartDate = timestampToDate(focusPeriodState.startDate);
  const endDate = datetimeToIsoNoOffset(
    focusPeriodStartDate
      ? addDays(focusPeriodStartDate, -1)
      : focusPeriodStartDate
  );

  if (result) {
    if (internalSelection === TimePeriodOptions.CUSTOM_PERIOD) {
      updatedLeadPeriodState = {
        ...leadPeriodState,
        endDate,
        label: result.label,
        startDate: result.startDate,
        value: internalSelection,
        weeks: getDifferenceInWeeks(
          timestampToDate(result.startDate),
          timestampToDate(endDate)
        ),
        customLength: result.customLength,
      } as TimePeriodState;
    } else if (
      internalSelection !== TimePeriodOptions.LAST_PERIOD ||
      result.customLength
    ) {
      // if weeks is not present in the lead period state,
      // default to the minimum week value in the valid range, or 1 if not provided
      const defaultWeeksValue = result.minWeeks ?? 1;
      const customLengthWeeks = leadPeriodState.weeks ?? defaultWeeksValue;
      const weeks = result.customLength
        ? customLengthWeeks
        : Number(result.value.split("-")[1]);

      const startDate = datetimeToIsoNoOffset(
        focusPeriodStartDate
          ? addDays(focusPeriodStartDate, -7 * weeks)
          : focusPeriodStartDate
      );

      updatedLeadPeriodState = {
        ...leadPeriodState,
        endDate,
        label: result.label,
        startDate,
        value: internalSelection,
        weeks,
        customLength: result.customLength,
      } as TimePeriodState;
    } else {
      updatedLeadPeriodState = {
        ...leadPeriodState,
        endDate: focusPeriodState.startDate,
        label: result.label,
        startDate: focusPeriodState.startDate,
        value: internalSelection,
        weeks: 0,
        customLength: result.customLength,
      } as TimePeriodState;
    }
  }

  const validation = validateTimePeriod(updatedLeadPeriodState);

  return {
    ...updatedLeadPeriodState,
    errors: validation.errors,
    isValid: validation.isValid,
    isWarning: validation.isWarning,
    isParameterMissing: false,
  } as ParameterState;
};

export const handleResetLeadPeriodSelection = (
  leadPeriodState: ParameterState,
  focusPeriodStartDate: string
): ParameterState => {
  if (!isTimePeriodState(leadPeriodState)) return { ...leadPeriodState };

  const date = timestampToDate(focusPeriodStartDate);
  const endDate = datetimeToIsoNoOffset(date ? addDays(date, -1) : date);

  let updatedLeadPeriodState = resetTimePeriodState(
    leadPeriodState.parameterConfig,
    leadPeriodState.parameterGroup
  );

  updatedLeadPeriodState = {
    ...leadPeriodState,
    endDate,
    label: "",
    startDate: "",
    value: "",
    weeks: undefined,
    customLength: false,
  } as TimePeriodState;

  const validation = validateTimePeriod(updatedLeadPeriodState);

  return {
    ...updatedLeadPeriodState,
    errors: validation.errors,
    isValid: validation.isValid,
    isWarning: validation.isWarning,
  } as ParameterState;
};

export const handleStartDateSelection = (
  timePeriodState: ParameterState,
  startDate: string,
  parameter: string
): ParameterState => {
  // Type guard check first
  // https://dev.to/ylerjen/typescript-cast-is-a-type-breaker-1jbh
  if (isTimePeriodState(timePeriodState)) {
    let updatedState = {
      ...timePeriodState,
      startDate,
    } as TimePeriodState;

    // Handle multi-select custom period
    if (
      updatedState.isMultiSelect &&
      updatedState.selections &&
      updatedState.selections.some(
        (sel) => sel.periodValue === TimePeriodOptions.CUSTOM_PERIOD
      )
    ) {
      const updatedSelections = updatedState.selections.map((sel) =>
        sel.periodValue === TimePeriodOptions.CUSTOM_PERIOD
          ? { ...sel, startDate }
          : sel
      );

      updatedState = {
        ...updatedState,
        selections: updatedSelections,
      } as TimePeriodState;
    }

    if (parameter === ParameterId.FocusPeriod) {
      updatedState.weeks = getDifferenceInWeeks(
        timestampToDate(startDate),
        timestampToDate(updatedState.endDate)
      );
    } else if (
      parameter === ParameterId.ComparisonPeriod &&
      updatedState.weeks
    ) {
      updatedState.endDate = getEndDate(startDate, updatedState.weeks);
    } else if (parameter === ParameterId.LeadPeriod) {
      updatedState.weeks = getDifferenceInWeeks(
        timestampToDate(startDate),
        timestampToDate(updatedState.endDate)
      );
    }

    const validation = validateTimePeriod(updatedState);

    return {
      ...updatedState,
      errors: validation.errors,
      isValid: validation.isValid,
      isWarning: validation.isWarning,
    } as ParameterState;
  }

  // Default is don't do anything to change the state
  return { ...timePeriodState };
};

export const handleEndDateSelection = (
  timePeriodState: ParameterState,
  endDate: string,
  parameter: string
): ParameterState => {
  // Type guard check first
  // https://dev.to/ylerjen/typescript-cast-is-a-type-breaker-1jbh
  if (isTimePeriodState(timePeriodState)) {
    let updatedState = {
      ...timePeriodState,
      endDate,
    } as TimePeriodState;
    if (
      updatedState.isMultiSelect &&
      updatedState.selections &&
      updatedState.selections.some(
        (sel) => sel.periodValue === TimePeriodOptions.CUSTOM_PERIOD
      )
    ) {
      const updatedSelections = updatedState.selections.map((sel) =>
        sel.periodValue === TimePeriodOptions.CUSTOM_PERIOD
          ? { ...sel, endDate }
          : sel
      );

      updatedState = {
        ...updatedState,
        selections: updatedSelections,
      } as TimePeriodState;
    }

    if (parameter === ParameterId.FocusPeriod) {
      updatedState.weeks = getDifferenceInWeeks(
        timestampToDate(updatedState.startDate),
        timestampToDate(endDate)
      );
    } else if (
      parameter === ParameterId.ComparisonPeriod &&
      updatedState.weeks
    ) {
      updatedState.startDate = getStartDate(endDate, updatedState.weeks);
    }

    const validation = validateTimePeriod(updatedState);

    return {
      ...updatedState,
      errors: validation.errors,
      isValid: validation.isValid,
      isWarning: validation.isWarning,
    } as ParameterState;
  }

  // Default is don't do anything to change the state
  return { ...timePeriodState };
};

export const handleWeeksSelection = (
  timePeriodState: ParameterState,
  weeks: number,
  parameter: string,
  weekOffset: number = 0
): ParameterState => {
  if (
    isTimePeriodState(timePeriodState) &&
    (parameter === ParameterId.FocusPeriod ||
      parameter === ParameterId.LeadPeriod)
  ) {
    timePeriodState.weeks = weeks;

    const result = timePeriodState.options.find(
      (option) => option.customLength
    );
    const minWeeks = result?.minWeeks ?? 1;
    const maxWeeks = result?.maxWeeks;

    if (result?.customLength) {
      const optionsOffsetFromEnd = weeks - minWeeks + weekOffset;
      const startDate =
        optionsOffsetFromEnd >= 0 &&
        optionsOffsetFromEnd < result.options.length
          ? result.options[result.options.length - optionsOffsetFromEnd - 1]
              .startDate
          : result.startDate;
      const endDate =
        weekOffset > 0 && weekOffset < result.options.length
          ? result.options[result.options.length - weekOffset - 1].endDate
          : result.endDate;

      timePeriodState.startDate = startDate;
      timePeriodState.endDate = endDate;
    }

    const validation = validateTimePeriod(timePeriodState);
    if (result && ((maxWeeks && weeks > maxWeeks) || weeks < minWeeks)) {
      validation.errors[ErrorKeys.invalidWeekRange] = true;
    }

    return {
      ...timePeriodState,
      errors: validation.errors,
      isValid: validation.isValid,
      isWarning: validation.isWarning,
    } as ParameterState;
  }

  // Default is don't do anything to change the state
  return { ...timePeriodState };
};
