import {
  MessageVariant,
  Nav,
  NavSize,
  NavVariant,
  QbitEmitToast,
  QbitToastMessage,
} from "@qbit/react";
import {
  type FolderDto,
  type FolderOrReportDto,
} from "@quantium-enterprise/common-ui";
import {
  EMIT_TOAST_DURATION,
  useGetReportParametersSummaryQuery,
  useGetUserQuery,
  useLazyDownloadRawDataReportQuery,
  useLazyGetSharingActivitiesQuery,
  useSetReportViewedMutation,
  ddLog,
  FeatureFlag,
  GenericTrackingProperties,
  ReportStatus,
  setAllReportSharedUsers,
  setIsPollingEnabled,
  setShowDeleteItemModal,
  setShowMoveItemModal,
  setShowShareModal,
  TrackingComponent,
  TrackingEvent,
  updateRowData,
  useDeleteSavedParametersMutation,
  useEventTrackingServiceContext,
  useGetAvailableReportsQuery,
  useGetOrganizationUsersQuery,
  useGetSavedParametersDetailsQuery,
  useGetSavedParametersSharingActivitiesQuery,
  useGetUsersByIdQuery,
  useLazyGetRerunParameterConfigurationsQuery,
  useLazyGetSavedParametersQuery,
  useRenameReportMutation,
  useRenameSavedParametersMutation,
} from "@quantium-enterprise/common-ui";
import {
  useDivision,
  useFlags,
  useModuleAccessProtection,
} from "@quantium-enterprise/hooks-ui";
import { AppFooter } from "components-ui/src/footer/AppFooter";
import { ReportInfoPanelDrawerContent } from "components-ui/src/info-panel/InfoPanelWithRedux";
import { InfoPanelType } from "components-ui/src/info-panel/info-panel-header/InfoPanelHeaderWithRedux";
import PanelWithSideDrawer from "components-ui/src/panel-with-side-drawer/PanelWithSideDrawer";
import { useCallback, useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  generatePath,
  NavLink,
  useNavigate,
  useParams,
} from "react-router-dom";
import { resetParametersState } from "report-parameters-ui/src/states/report-wizard-slice";
import { GeneratedReportsModals } from "./GeneratedReportsModals";
import styles from "./MyReports.module.css";
import GeneratedReportsWithRedux from "./generated-reports/GeneratedReports";
import {
  isFolderRow,
  isReportRow,
} from "./my-reports-grid/MyReportsGridWithFolders";
import SavedAndScheduled from "./saved-and-scheduled/SavedAndScheduled";
import { SavedAndScheduledInfoPanelContent } from "./saved-and-scheduled/SavedAndScheduledInfoPanelContent";
import { type RootState } from "./store";

const generatedReportsId = "generated-reports";
const savedAndScheduledId = "saved-and-scheduled";
const tabs = [
  {
    element: <GeneratedReportsWithRedux />,
    id: generatedReportsId,
    label: "Generated reports",
    path: "",
  },
  {
    element: <SavedAndScheduled />,
    id: savedAndScheduledId,
    label: "Saved selections",
    path: "saved-and-scheduled",
  },
];

export const myReportsRoutes = tabs.flatMap((tab) => [
  tab,
  {
    ...tab,
    path: tab.path ? `${tab.path}/:id` : ":id",
  },
]);

const MyReportsTabs = ({
  activeIndex,
  setActiveIndex,
}: {
  activeIndex: number;
  setActiveIndex: (activeIndex: number) => void;
}) => (
  <div className={styles.myReportsHeading}>
    <h3 className={styles.myReportsHeader}>My reports</h3>
    <Nav
      activeIndex={activeIndex}
      size={NavSize.Medium}
      variant={NavVariant.Tab}
    >
      {/* NavLink from react-router requires this to work with qbit */}
      {/* Can use qbit NavLink but because it links via href, the navigation triggers page reload */}
      {tabs.map((tab, index) => (
        <NavLink
          className="q-nav-item-control q-nav-item-link"
          data-testid={tab.id}
          key={tab.path}
          onClick={() => setActiveIndex(index)}
          to={tab.path}
        >
          {tab.label}
        </NavLink>
      ))}
    </Nav>
  </div>
);

const findLastIndex = <T,>(array: T[], predicate: (value: T) => boolean) => {
  let index = array.length - 1;
  for (; index >= 0; --index) {
    if (predicate(array[index])) {
      return index;
    }
  }

  // -1 will be returned at the end
  return index;
};

// eslint-disable-next-line complexity
export const MyReportsWithRedux = () => {
  useModuleAccessProtection((moduleAccess) => moduleAccess.myReports);

  // findLastIndex because the first tab will be empty path which will always match

  const navigate = useNavigate();
  const eventTrackingService = useEventTrackingServiceContext();
  const { name: activeDivisionName } = useDivision();
  const { data: user } = useGetUserQuery();
  const [renameReport] = useRenameReportMutation();
  const flags = useFlags();
  const dispatch = useDispatch();
  const { id: focalRowId } = useParams();
  const [triggerRerunQuery, { isLoading: isRerunLoading }] =
    useLazyGetRerunParameterConfigurationsQuery();
  const [activeIndex, setActiveIndex] = useState<number>(
    findLastIndex(tabs, (tab) => location.pathname.includes(tab.path))
  );
  const [deleteSavedParameters] = useDeleteSavedParametersMutation();
  const [renameSavedParameters] = useRenameSavedParametersMutation();
  const [triggerGetSavedParameters] = useLazyGetSavedParametersQuery();
  const [triggerDownloadReport] = useLazyDownloadRawDataReportQuery();
  const [setReportViewed] = useSetReportViewedMutation();
  const [
    triggerGetReportSharingActivities,
    {
      data: reportSharingActivities,
      isLoading: isReportSharingActivitiesLoading,
    },
  ] = useLazyGetSharingActivitiesQuery();
  const activeTabId = tabs[activeIndex]?.id;
  const [infoPanelCurrentNavIndex, setInfoPanelCurrentNavIndex] = useState(0);
  const focalSavedParametersId =
    activeTabId === savedAndScheduledId ? focalRowId : undefined;
  const focalGeneratedReportsId =
    activeTabId === generatedReportsId ? focalRowId : undefined;

  const { rowData } = useSelector(
    (state: RootState) => ({
      rowData: state.generatedReports.rowData,
    }),
    // UNSURE OF CONSEQUENCES OF THIS COMPARISON
    shallowEqual
  );

  const { data: savedParameters, isLoading: isSavedParametersLoading } =
    useGetSavedParametersDetailsQuery(
      {
        divisionName: activeDivisionName,
        savedParametersId: focalSavedParametersId ?? "",
      },
      { skip: !focalSavedParametersId || !activeDivisionName }
    );

  const focalRowData = useMemo(
    () => rowData[focalRowId ?? ""] as FolderOrReportDto | undefined,
    [rowData, focalRowId]
  );

  const showDrawer = useMemo(
    () => Boolean(focalRowId && !isFolderRow(focalRowData)),
    [focalRowData, focalRowId]
  );

  const {
    data: allReportSharedUsers,
    isSuccess: isAllReportSharedUsersSuccess,
  } = useGetUsersByIdQuery({
    divisionName: activeDivisionName,
    payload: {
      SalesforceUserIds: [
        ...new Set(
          Object.values(rowData).flatMap((row) =>
            isReportRow(row)
              ? row.sharedWithUserIds.concat(
                  row.sharedByUserId ? row.sharedByUserId : []
                )
              : []
          )
        ),
      ],
    },
  });

  useEffect(() => {
    if (isAllReportSharedUsersSuccess)
      dispatch(setAllReportSharedUsers(allReportSharedUsers));
  }, [allReportSharedUsers, isAllReportSharedUsersSuccess, dispatch]);

  const {
    data: focalReportParameters,
    isLoading: isFocalReportParametersLoading,
  } = useGetReportParametersSummaryQuery(
    { divisionName: activeDivisionName, reportId: focalRowId ?? "" },
    { skip: !focalGeneratedReportsId || !showDrawer || !activeDivisionName }
  );

  const { data: organisationUsers } = useGetOrganizationUsersQuery(
    { divisionName: activeDivisionName },
    { skip: !activeDivisionName || !flags[FeatureFlag.SharingReports] }
  );

  const {
    data: savedParametersSharingActivities,
    isLoading: isSavedParametersSharingActivitiesLoading,
  } = useGetSavedParametersSharingActivitiesQuery(
    {
      divisionName: activeDivisionName,
      savedParametersId: focalSavedParametersId ?? "",
    },
    {
      skip:
        !focalSavedParametersId ||
        !activeDivisionName ||
        !flags[FeatureFlag.SharingReports],
    }
  );

  const sharingActivities =
    activeTabId === generatedReportsId
      ? reportSharingActivities
      : savedParametersSharingActivities;
  const isSharingActivitiesLoading =
    activeTabId === generatedReportsId
      ? isReportSharingActivitiesLoading
      : isSavedParametersSharingActivitiesLoading;

  const updateReportSharingActivities = useCallback(
    async (divisionName: string, reportId: string) => {
      await triggerGetReportSharingActivities({ divisionName, reportId }).catch(
        (error) => ddLog("ERROR", {}, "error", error as Error)
      );
    },
    [triggerGetReportSharingActivities]
  );

  useEffect(() => {
    if (
      activeTabId === generatedReportsId &&
      activeDivisionName &&
      focalRowId &&
      isReportRow(focalRowData)
    )
      void updateReportSharingActivities(activeDivisionName, focalRowId);
  }, [
    activeDivisionName,
    focalRowData,
    focalRowId,
    activeTabId,
    updateReportSharingActivities,
  ]);

  const { data: sharedUsers, isLoading: isSharedUsersLoading } =
    useGetUsersByIdQuery(
      {
        divisionName: activeDivisionName,
        payload: {
          SalesforceUserIds:
            sharingActivities?.flatMap(
              (sharingActivity) => sharingActivity.salesforceUserIds
            ) ?? [],
        },
      },
      { skip: !sharingActivities || !flags[FeatureFlag.SharingReports] }
    );

  const getDefaultMyReportsPath = useCallback(
    () =>
      activeTabId === savedAndScheduledId
        ? generatePath("/:division/my-reports/saved-and-scheduled", {
            division: activeDivisionName,
          })
        : generatePath("/:division/my-reports", {
            division: activeDivisionName,
          }),
    [activeDivisionName, activeTabId]
  );

  const { data: allReportTypes } = useGetAvailableReportsQuery(
    activeDivisionName,
    {
      skip: !activeDivisionName,
    }
  );

  const availableReportTypes = useMemo(
    () =>
      allReportTypes?.availableReports
        .filter((report) => !report.disabled)
        ?.map((report) => report.name) ?? [],
    [allReportTypes]
  );

  const isReportDisabled = useMemo(
    () => !(focalReportParameters?.reportType ?? "" in availableReportTypes),
    [availableReportTypes, focalReportParameters]
  );

  const focalItemPath = useMemo(
    () =>
      focalRowData &&
      isReportRow(focalRowData) &&
      focalRowData.reportStatus === ReportStatus.Completed
        ? `/${activeDivisionName}/${focalRowData.reportType}/${focalRowData.id}`
        : undefined,
    [focalRowData, activeDivisionName]
  );

  const reportTypeDisplayName = useMemo(
    () =>
      allReportTypes?.availableReports
        .filter(
          (report) =>
            focalRowData &&
            isReportRow(focalRowData) &&
            report.name === focalRowData.reportType
        )
        .map((report) => report.displayName)[0],
    [focalRowData, allReportTypes]
  );

  const updateRowName = useCallback(
    (newRowName: string, rowId: string) => {
      dispatch(
        updateRowData({
          ...rowData,
          [String(rowId)]: { ...rowData[rowId], name: newRowName },
        })
      );
    },
    [rowData, dispatch]
  );

  const handleRenameReport = useCallback(
    async (newRowName: string, rowId: string) => {
      const oldRowName = rowData[rowId].name;
      updateRowName(newRowName, rowId);
      let response;
      try {
        response = await renameReport({
          divisionName: activeDivisionName,
          payload: { newReportName: newRowName, reportId: rowId },
        }).unwrap();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        response = error.unwrap();
        updateRowName(oldRowName, rowId);
      } finally {
        dispatch(setIsPollingEnabled(true));
      }

      return response;
    },
    [renameReport, activeDivisionName, rowData, updateRowName, dispatch]
  );

  const handleRerunReport = useCallback(async () => {
    if (!isReportRow(focalRowData)) return;
    const requestDto = {
      divisionName: activeDivisionName,
      reportType: focalRowData.reportType,
      rerunReportId: focalRowData.id,
    };
    await triggerRerunQuery(requestDto)
      .unwrap()
      .then((data) => {
        dispatch(resetParametersState());
        navigate(
          `/${activeDivisionName}/report-wizard/${focalRowData.reportType}`,
          {
            state: {
              reRunConfig: data,
            },
          }
        );
      })
      .catch((error) => {
        ddLog("Validation failed for report re-run.", {}, "error", error);
        QbitEmitToast(
          <QbitToastMessage
            content={
              <p>
                Something went wrong. Please try again later. Contact support if
                error persists.
              </p>
            }
            heading={<h5>Report wasn’t re-run</h5>}
            showCloseButton
            showIcon
            variant={MessageVariant.Danger}
          />
        );
      });
  }, [activeDivisionName, focalRowData, dispatch, triggerRerunQuery, navigate]);

  const handleDeleteSavedParameters = useCallback(
    async (savedParametersId: string) =>
      await deleteSavedParameters({
        divisionName: activeDivisionName,
        savedParametersId,
      })
        .unwrap()
        .then(
          async () =>
            await triggerGetSavedParameters({
              divisionName: activeDivisionName,
            })
        ),
    [activeDivisionName, deleteSavedParameters, triggerGetSavedParameters]
  );

  const handleRenameSavedParameters = useCallback(
    async (savedParametersId: string, newItemName: string) => {
      await renameSavedParameters({
        divisionName: activeDivisionName,
        payload: {
          newName: newItemName,
          savedParametersId,
        },
      })
        .unwrap()
        .then(async () => {
          await triggerGetSavedParameters({
            divisionName: activeDivisionName,
          });
          QbitEmitToast(
            <QbitToastMessage
              content={<p>Saved selection has been renamed</p>}
              heading={<h5>Success</h5>}
              showCloseButton
              showIcon
              variant={MessageVariant.Success}
            />,
            {
              autoClose: EMIT_TOAST_DURATION,
            }
          );
        })
        .catch((error) => {
          ddLog("ERROR", {}, "error", error as Error);
          QbitEmitToast(
            <QbitToastMessage
              content={<p>Saved selection rename failed</p>}
              heading={<h5>Unknown error</h5>}
              showCloseButton
              showIcon
              variant={MessageVariant.Danger}
            />,
            {
              autoClose: EMIT_TOAST_DURATION,
            }
          );
        });
    },
    [activeDivisionName, renameSavedParameters, triggerGetSavedParameters]
  );

  const isDownloadEnabled =
    focalRowData &&
    isReportRow(focalRowData) &&
    focalRowData.reportStatus === ReportStatus.Completed;

  const handleDownloadReport = useCallback(
    async (reportId: string) => {
      try {
        await triggerDownloadReport({
          reportId,
          reportParameters: focalReportParameters,
        }).unwrap();
      } catch (error) {
        ddLog("ERROR", {}, "error", error as Error);
        QbitEmitToast(
          <QbitToastMessage
            content={<p>Failed to download report. Please try again later.</p>}
            heading={<h5>Download Failed</h5>}
            showCloseButton
            showIcon
            variant={MessageVariant.Danger}
          />,
          {
            autoClose: EMIT_TOAST_DURATION,
          }
        );
      }
    },
    [triggerDownloadReport, focalReportParameters]
  );
  const trackButtonClick = async (reportName: string, reportId: string) => {
    await setReportViewed({ divisionName: activeDivisionName, reportId });
    eventTrackingService.trackEvent(
      [TrackingComponent.MyReports, TrackingComponent.Report],
      TrackingEvent.Opened,
      new GenericTrackingProperties({
        reportName,
        reportId,
        method: "View report button",
        division: activeDivisionName,
        user: user?.isSupplier ? "Supplier" : "Retailer",
      })
    );
  };

  const drawerContext =
    activeTabId === savedAndScheduledId
      ? SavedAndScheduledInfoPanelContent({
          isLoading:
            isSavedParametersLoading ||
            isSharingActivitiesLoading ||
            isSharedUsersLoading,
          savedParameters,
          savedParametersSharedUsers: sharedUsers,
          sharingActivities,
          currentNavIndex: infoPanelCurrentNavIndex,
          setCurrentNavIndex: setInfoPanelCurrentNavIndex,
          flags,
          onClose: () => navigate(getDefaultMyReportsPath()),
          deleteItem: handleDeleteSavedParameters,
          renameItem: handleRenameSavedParameters,
        })
      : ReportInfoPanelDrawerContent({
          focalReport: focalReportParameters,
          focalReportPath: focalItemPath,
          infoPanelType: InfoPanelType.MyReports,
          isReportDisabled,
          isLoading:
            isFocalReportParametersLoading ||
            isSharingActivitiesLoading ||
            isSharedUsersLoading ||
            isReportDisabled,
          setShowDeleteModal: () => dispatch(setShowDeleteItemModal(true)),
          setShowMoveModal: () => dispatch(setShowMoveItemModal(true)),
          setShowShareModal: () => dispatch(setShowShareModal(true)),
          setIsPollingEnabled: (newState: boolean) =>
            dispatch(setIsPollingEnabled(newState)),
          isRerunLoading,
          isDownloadEnabled,
          isDownloadableReport: focalReportParameters?.isDownloadableReport,
          handleDownload:
            focalRowData && isReportRow(focalRowData)
              ? async () => await handleDownloadReport(focalRowData.id)
              : undefined,
          onDelete: () => navigate(getDefaultMyReportsPath()),
          // eslint-disable-next-line @typescript-eslint/no-misused-promises -- fire and forget, the handleRerunReport function will deal with the result
          onRerun: handleRerunReport,
          organisationUsers,
          renameItem: handleRenameReport,
          reportSharedUsers: sharedUsers,
          reportTypeDisplayName,
          sharingActivities,
          trackButtonClick,
          currentNavIndex: infoPanelCurrentNavIndex,
          setCurrentNavIndex: setInfoPanelCurrentNavIndex,
          flags,
          folders: Object.values(rowData).filter(
            (row) => row.isFolder
          ) as unknown as FolderDto[],
        });

  return (
    <div className={styles.myReportsContainer}>
      <PanelWithSideDrawer
        closeFunc={() => {
          navigate(getDefaultMyReportsPath());
        }}
        drawerContent={drawerContext}
        isOverlay={false}
        showDrawer={showDrawer}
      >
        <div className={styles.myReportsLayout}>
          <MyReportsTabs
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
          />
          <GeneratedReportsModals
            getDefaultMyReportsPath={getDefaultMyReportsPath}
            updateReportSharingActivities={updateReportSharingActivities}
          />
          <div className={styles.myReportsContent}>
            {tabs[activeIndex].element}
          </div>
        </div>
      </PanelWithSideDrawer>
      <AppFooter />
    </div>
  );
};

export default MyReportsWithRedux;
