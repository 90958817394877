import { format } from "date-fns";
import { enUS, enGB, enAU } from "date-fns/locale";

const locales = { enUS, enGB, enAU };

type LocaleKey = keyof typeof locales;

// Mapping from hyphenated codes to locale keys
const localeMapping: Record<string, LocaleKey | undefined> = {
  "en-US": "enUS",
  "en-GB": "enGB",
  "en-AU": "enAU",
  default: undefined,
};

const customFormats: Partial<
  Record<LocaleKey, { default: string; dropdown: string }>
> = {
  enUS: {
    dropdown: "MMM dd, yyyy",
    default: "PP",
  },
  enGB: {
    dropdown: "dd MMM yyyy",
    default: "d MMM yy",
  },
  enAU: {
    dropdown: "dd MMM yyyy",
    default: "d MMM yy",
  },
};

export type FormatOptions = {
  dropdown?: boolean;
  locale: string;
};

/**
 * Returns the date format and locale as Locale type based on the format options
 *
 * @param {FormatOptions} formatOptions - Options for formatting the date
 */
const getLocaleAndDateFormat = (formatOptions?: FormatOptions) => {
  let locale = enAU;
  let dateFormat = customFormats.enAU?.default ?? "PP";

  if (formatOptions?.locale && localeMapping[formatOptions.locale]) {
    const mappedLocale = localeMapping[formatOptions.locale];
    locale = locales[mappedLocale as LocaleKey];
    const formatType = formatOptions.dropdown ? "dropdown" : "default";
    dateFormat = customFormats[mappedLocale as LocaleKey]?.[formatType] ?? "PP";
  }

  return { dateFormat, locale };
};

/**
 * Converts date to desired date string format
 *
 * @param {Date} date - date to format
 */
export const formatDate = (date: Date, formatOptions?: FormatOptions) => {
  const { dateFormat, locale } = getLocaleAndDateFormat(formatOptions);

  // Adjust the date to match the local timezone
  const offset = date.getTimezoneOffset();
  const modifiedDate = new Date(date.getTime() + offset * 60 * 1_000);

  return format(modifiedDate, dateFormat, { locale });
};

/**
 * Converts date in number format to desired date string format
 *
 * @param {number} dateInNumberFormat - date in YYYYMMDD format e.g. 20220202
 * @param {FormatOptions} formatOptions - Options for formatting the date
 */
export const formatNumberDate = (
  dateInNumberFormat: number,
  formatOptions?: FormatOptions
) => {
  const dateString = dateInNumberFormat.toString();

  const year = dateString.slice(0, 4);
  const month = dateString.slice(4, 6);
  const day = dateString.slice(6, 8);

  const date = new Date(`${year}-${month}-${day}`);

  return formatDate(date, formatOptions);
};

/**
 * Converts date in ISO (or any accepted string) format to desired date string format
 *
 * @param {string} dateInStringFormat - date in ISO format e.g. 2021-08-16T10:00:00Z
 */
export const formatStringDate = (
  dateInStringFormat?: string,
  formatOptions?: FormatOptions
) => {
  if (!dateInStringFormat) {
    return "-";
  }

  return formatDate(new Date(dateInStringFormat), formatOptions);
};
