import { SortableContext } from "@dnd-kit/sortable";
import classNames from "classnames";
import { type ButtonGroupOptionsWithLegend } from "components-ui/src/drag-and-multi-drop/models/ButtonGroupOptionsWithLegend";
import { useMemo, useState } from "react";
import { ReportButtonTabs } from "../../../../reports/src/common/components/ReportTabComponents/ReportButtonTabs";
import { ReportTabItem } from "../../../../reports/src/common/components/ReportTabComponents/ReportTabItem";
import { type PanelOption } from "../../local-parameters-panel/FixedSidePanel";
import { SimpleDroppableZone } from "../item/SimpleDroppableZone";
import { Item } from "../item/SimplifiedItem";
import { type SimpleItem } from "../models/Item";
import { type SimpleZone, type Container } from "../models/Zone";
import styles from "./DragAndMultiDrop.module.css";
import { DragAndDropTitle } from "./Title";

export enum DragAndMultiDropContainerType {
  Left = "left-container",
  Right = "right-container",
}

export type DragAndMultiDropContainerProps = {
  activeButton: string;
  buttonGroupOptions?: ButtonGroupOptionsWithLegend[];
  container: Container;
  dropdownOptions: PanelOption[];
  items: SimpleItem[];
  onRemove: (item: SimpleItem) => void;
  setActiveButton: (activeButton: string) => void;
  type: DragAndMultiDropContainerType;
  zones: SimpleZone[];
};

export const DragAndMultiDropContainer = ({
  type,
  items,
  container,
  activeButton = "",
  setActiveButton,
  onRemove,
  zones,
  dropdownOptions,
  buttonGroupOptions,
}: DragAndMultiDropContainerProps) => {
  const itemIds = useMemo(() => items.map((item) => item.id), [items]);
  const [searchString, setSearchString] = useState("");
  const [selectedValue, setSelectedValue] = useState(
    dropdownOptions[0].value.toString()
  );

  const filteredItems = useMemo(
    () =>
      searchString
        ? items.filter((item) =>
            item.name
              .toLocaleLowerCase()
              .includes(searchString.toLocaleLowerCase())
          )
        : items,
    [items, searchString]
  );

  const itemSubset = useMemo(
    () =>
      selectedValue === "All types"
        ? filteredItems
        : filteredItems.filter((item) => item.grouping === selectedValue),
    [filteredItems, selectedValue]
  );

  return (
    <div
      className={classNames({
        [styles.draggableListContainer]:
          type === DragAndMultiDropContainerType.Left,
        [styles.droppableContainer]:
          type === DragAndMultiDropContainerType.Right,
      })}
    >
      {container.title && (
        <DragAndDropTitle
          container={container}
          onSelection={setSelectedValue}
          searchAndFilter={
            container.containerType === DragAndMultiDropContainerType.Left
          }
          selectOptions={dropdownOptions}
          selectedValue={selectedValue}
          setSearchString={setSearchString}
        />
      )}
      {type === DragAndMultiDropContainerType.Left && items.length === 0 && (
        <div className={styles.noItemsMessage}>
          No available {container.title.toLowerCase()}
        </div>
      )}
      {type === DragAndMultiDropContainerType.Left &&
        buttonGroupOptions &&
        buttonGroupOptions.length > 0 && (
          <div className={styles.buttonGroupContainer}>
            <ReportButtonTabs
              activeTab={activeButton}
              onClickButtonTabItem={(value) => {
                setActiveButton(value);
              }}
            >
              {buttonGroupOptions.map((option) => (
                <ReportTabItem
                  key={option.value}
                  label={option.label}
                  value={option.value}
                />
              ))}
            </ReportButtonTabs>

            <div className={styles.legendContainer}>
              {buttonGroupOptions
                .filter((option) => option.value !== "All")
                .map((option) => (
                  <div className={styles.legendItem} key={option.value}>
                    <span
                      className={styles.legendSquare}
                      style={{ backgroundColor: option.color }}
                    />
                    <span>{option.label}</span>
                  </div>
                ))}
            </div>
          </div>
        )}
      <div className={styles.itemContainers}>
        {type === DragAndMultiDropContainerType.Left &&
          itemSubset.map((item) => (
            <Item item={item} key={item.id} onRemove={onRemove} />
          ))}
        {type === DragAndMultiDropContainerType.Right && (
          <SortableContext items={itemIds}>
            {zones.map((zone) => (
              <SimpleDroppableZone
                key={zone.id}
                onRemove={onRemove}
                zone={zone}
              />
            ))}
          </SortableContext>
        )}
      </div>
    </div>
  );
};
