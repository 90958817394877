import { HierarchyType, ParameterId } from "@quantium-enterprise/common-ui";
import { AdvancedSearchEditor } from "components-ui/src/advanced-search/AdvancedSearchEditor";
import { useDispatch, useSelector } from "react-redux";
import {
  onFilterRulesUpdate,
  selectFilterRules,
  selectHierarchyMetadata,
  selectLeafShortName,
} from "../../states/report-wizard-slice";
import { type RootState } from "../../store";

const hierarchyType = HierarchyType.Product;

export type ParameterAdvancedSearchEditorProps = {
  enableAdvancedSearch: React.Dispatch<React.SetStateAction<boolean>>;
  parameterType?: ParameterId;
  useFullWidth?: boolean;
};

export const ParameterAdvancedSearchEditor = ({
  enableAdvancedSearch,
  parameterType = ParameterId.ProductHierarchy,
  useFullWidth = false,
}: ParameterAdvancedSearchEditorProps) => {
  const dispatch = useDispatch();

  const leafShortName = useSelector((state: RootState) =>
    selectLeafShortName(parameterType, state)
  );

  const hierarchyAttributes = useSelector((state: RootState) =>
    selectHierarchyMetadata(parameterType, state)
  );

  const filterRules = useSelector((state: RootState) =>
    selectFilterRules(parameterType, state)
  );

  return (
    <AdvancedSearchEditor
      enableAdvancedSearch={enableAdvancedSearch}
      filterRules={filterRules}
      hierarchyAttributes={hierarchyAttributes}
      hierarchyType={hierarchyType}
      leafShortName={leafShortName}
      onFilterRulesUpdate={(value) => dispatch(onFilterRulesUpdate(value))}
      parameterType={parameterType}
      useFullWidth={useFullWidth}
    />
  );
};
