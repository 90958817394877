import {
  type LocalHierarchyNodeSelection,
  type LocalSelectedValueDto,
} from "@quantium-enterprise/common-ui";
import { LocalParameters } from "../../common/models/LocalParameterId";
import { type SidePanelParameter } from "../../common/models/local-parameters/SidePanelParameters";
import {
  isLocationSelectionParameter,
  isSegmentationParameter,
  isSingleSelectionParameter,
} from "../../common/utils/local-parameters/LocalParametersUtils";
import {
  getLocationSelectDefault,
  getSegmentationSelectDefaultSelection,
  getSingleSelectDefaultSelection,
} from "../../common/utils/local-parameters/getDefaultSelectionUtils";
import { type AggregateRankLocalSelections } from "../services/aggregate-rank-slice";

export const getDefaultSelections = (
  localParameters: SidePanelParameter[]
): AggregateRankLocalSelections => {
  const selections: AggregateRankLocalSelections = {
    Channel: { label: "", value: "" },
    LevelOfAnalysis: { label: "", value: "" },
    LocationHierarchy: {
      code: "",
      depth: -1,
      isBenchmark: false,
      isLeaf: false,
      name: "",
      nodeNumber: -1,
      shortName: "",
      isDefault: false,
    },
    Segmentation: [],
  };

  for (const parameter of localParameters) {
    switch (parameter.id) {
      case LocalParameters.LevelOfAnalysis:
        if (isSingleSelectionParameter(parameter)) {
          const selection = getSingleSelectDefaultSelection(parameter);
          if (selection)
            selections.LevelOfAnalysis = {
              label: selection.label,
              value: selection.value,
            };
        }

        break;
      case LocalParameters.Channel:
        if (isSingleSelectionParameter(parameter)) {
          const selection = getSingleSelectDefaultSelection(parameter);
          if (selection) {
            selections.Channel = {
              label: selection.label,
              value: selection.value,
            };
          }
        }

        break;

      case LocalParameters.Segmentation:
        if (isSegmentationParameter(parameter)) {
          const selection = getSegmentationSelectDefaultSelection(parameter);
          if (selection) {
            selections.Segmentation = [
              {
                label: selection.label,
                value: selection.value,
              },
              {
                label: selection.segmentLabel,
                value: selection.segmentValue,
              },
            ];
          }
        }

        break;
      case LocalParameters.LocationHierarchy:
        if (isLocationSelectionParameter(parameter))
          selections.LocationHierarchy = getLocationSelectDefault(parameter);

        break;
      default:
        break;
    }
  }

  return selections;
};

export const getUserSelections = (
  localParameters: AggregateRankLocalSelections
) => {
  const userSelections: LocalSelectedValueDto[] = [];

  for (const [parameterID, values] of Object.entries(localParameters)) {
    if (typeof values === "object" && "value" in values && values.value) {
      userSelections.push({
        id: parameterID,
        values: [values.value as string],
      });
    } else if (parameterID === "LocationHierarchy") {
      const castValue = values as LocalHierarchyNodeSelection;
      userSelections.push({
        id: parameterID,
        values: [castValue.nodeNumber.toString()],
      });
    } else if (Array.isArray(values) && values.length > 0) {
      userSelections.push({
        id: parameterID,
        values: values.map((value) => value.value.toString()),
      });
    }
  }

  return userSelections;
};
