export enum LocationHierarchy {
  Banner = "BAN",
  District = "DIS",
  Division = "DIV",
  "Division " = "ADIV",
  Metro = "MET",
  Region = "REG",
  State = "STA",
  "State " = "STT",
  Store = "STR",
  Total = "ALL",
}
