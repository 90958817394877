import {
  type HierarchyGroupDto,
  type HierarchyMetadataResponseDto,
  type HierarchyGroupRuleWithIdAndName,
  HierarchyType,
  type HierarchyItem,
  HierarchyItemType,
  type HierarchyServiceResponseDto,
  areFiltersEqual,
} from "@quantium-enterprise/common-ui";
import { type SubscriptionDto } from "@quantium-enterprise/common-ui/src/models/subscription-dto";
import {
  createSelector,
  createSlice,
  type PayloadAction,
} from "@reduxjs/toolkit";
import { type HierarchyTableData } from "report-parameters-ui/src/parameters";
import { DEFAULT_FILTER_RULES } from "report-parameters-ui/src/parameters";
import { isFilterComplete } from "report-parameters-ui/src/parameters/utils/isFilterComplete";
import { type NestedHierarchyItem } from "../components/static-group-hierarchy-table/GroupHierarchyTable";
import { type RootState } from "../store";

export type GroupHierarchySourceState = {
  dataItems: NestedHierarchyItem[];
  expandedRows: HierarchyItem[];
  filterRules: HierarchyGroupRuleWithIdAndName[];
  focalAttributes: string[];
  hierarchyMetadata: HierarchyMetadataResponseDto[];
  hierarchyStructure: string[];
  isAdvancedSearchEnabled: boolean;
  leafNodeShortName: string;
  searchHasNextPage: boolean;
  searchString: string;
  selectedRows: HierarchyItem[];
  subscription?: SubscriptionDto;
  subscriptionOptions: SubscriptionDto[];
  triggerSearch: boolean;
  type: HierarchyType;
  unfilteredData: HierarchyTableData;
};

const initialState: GroupHierarchySourceState = {
  dataItems: [],
  expandedRows: [],
  leafNodeShortName: "",
  selectedRows: [],
  subscription: undefined,
  subscriptionOptions: [],
  type: HierarchyType.Product,
  isAdvancedSearchEnabled: false,
  searchString: "",
  triggerSearch: false,
  hierarchyMetadata: [],
  hierarchyStructure: [],
  filterRules: [...DEFAULT_FILTER_RULES],
  unfilteredData: {
    items: [],
    expandedRows: [],
  },
  searchHasNextPage: false,
  focalAttributes: [],
};

const toggleNodeExpanding = (
  data: NestedHierarchyItem[],
  code: string,
  shortName: string
): NestedHierarchyItem[] => {
  for (const row of data) {
    if (row.code === code && row.shortName === shortName) {
      row.isExpanding = row.subRows === undefined || row.subRows.length === 0;
      return data;
    }

    if (row.subRows?.length) {
      toggleNodeExpanding(row.subRows, code, shortName);
    }
  }

  return data;
};

const insertChildrenIntoPosition = (
  data: NestedHierarchyItem[],
  subRows: NestedHierarchyItem[],
  parentCode: string,
  parentShortName: string
): NestedHierarchyItem[] => {
  for (const row of data) {
    if (row.code === parentCode && row.shortName === parentShortName) {
      if (row.subRows === undefined || row.subRows.length === 0) {
        row.subRows = subRows;
      } else {
        row.subRows[row.subRows.length - 1].isMoreRow = false;
        row.subRows = [...row.subRows, ...subRows];
      }

      return data;
    }

    if (row.subRows?.length) {
      insertChildrenIntoPosition(
        row.subRows,
        subRows,
        parentCode,
        parentShortName
      );
    }
  }

  return data;
};

const overwriteChildrenInPosition = (
  data: NestedHierarchyItem[],
  subRows: NestedHierarchyItem[],
  parentCode: string,
  parentShortName: string
) => {
  for (const row of data) {
    if (row.code === parentCode && row.shortName === parentShortName) {
      row.subRows = subRows;
      return;
    }

    if (row.subRows?.length) {
      overwriteChildrenInPosition(
        row.subRows,
        subRows,
        parentCode,
        parentShortName
      );
    }
  }
};

export const groupHierarchySourceSlice = createSlice({
  initialState,
  name: "group-hierarchy-source-slice",
  reducers: {
    copyHierarchyTableDataToUnfilteredDataStore: (
      state: GroupHierarchySourceState
    ) => {
      state.unfilteredData.items = [...state.dataItems];
      state.unfilteredData.expandedRows = [...state.expandedRows];
    },

    copyUnfilteredDataStoreToHierarchyTableData: (
      state: GroupHierarchySourceState
    ) => {
      state.dataItems = [...state.unfilteredData.items];
      state.expandedRows = [...state.unfilteredData.expandedRows];
    },

    hierarchyNodeExpanded: (
      state: GroupHierarchySourceState,
      {
        payload: { expandedRows },
      }: PayloadAction<{
        expandedRows: HierarchyItem[];
      }>
    ) => {
      state.expandedRows = expandedRows;
    },

    onEntitledSubscriptionsSuccess: (
      state: GroupHierarchySourceState,
      {
        payload: { entitledSubscriptions, existingGroup },
      }: PayloadAction<{
        entitledSubscriptions: SubscriptionDto[];
        existingGroup?: HierarchyGroupDto;
      }>
    ) => {
      state.subscriptionOptions = entitledSubscriptions;

      if (existingGroup) {
        const existingSubscription = entitledSubscriptions.find(
          (subscription) =>
            subscription.featureModule === existingGroup.featureModule &&
            subscription.transactionSource === existingGroup.transactionSource
        );

        // Existing groups default to CO if not specified
        state.subscription =
          existingSubscription ??
          entitledSubscriptions[entitledSubscriptions.length - 1];
      } else {
        state.subscription = entitledSubscriptions[0];
      }
    },

    onHierarchyChildNodesReceived: (
      state: GroupHierarchySourceState,
      {
        payload: { childNodes, overwrite, leafNodeShortName },
      }: PayloadAction<{
        childNodes: HierarchyServiceResponseDto;
        leafNodeShortName: string;
        overwrite?: boolean;
      }>
    ) => {
      if (childNodes.count > 0 && leafNodeShortName !== "") {
        const subRows = [
          ...childNodes.results.map((node) => ({
            code: node.code,
            depth: node.depth,
            isLeaf: node.shortName === leafNodeShortName,
            name: node.name,
            ordinal: 0,
            parent: node.parent,
            shortName: node.shortName,
            transactionSourceAccess: node.transactionSourceAccess,
            type: HierarchyItemType.Hierarchy,
          })),
        ] as NestedHierarchyItem[];

        if (childNodes.hasNextPage) {
          subRows[subRows.length - 1].isMoreRow = true;
        }

        const parentCode = childNodes.results[0].parent.code;
        const parentShortName = childNodes.results[0].parent.shortName;

        if (overwrite) {
          overwriteChildrenInPosition(
            state.dataItems,
            subRows,
            parentCode,
            parentShortName
          );
        } else {
          insertChildrenIntoPosition(
            state.dataItems,
            subRows,
            parentCode,
            parentShortName
          );
        }

        toggleNodeExpanding(state.dataItems, parentCode, parentShortName);
      }
    },

    onHierarchyNodeSelection: (
      state: GroupHierarchySourceState,
      { payload }: PayloadAction<HierarchyItem[]>
    ) => {
      const selectedRows = payload;

      // eslint-disable-next-line no-warning-comments
      // todo: if the level selected is above product, all levels under (including products) whether expanded
      //  or not should also be selected (https://quantium.atlassian.net/browse/CO3-2202)
      state.selectedRows = selectedRows;
    },

    onProductSearchResultsReceived: (
      state: GroupHierarchySourceState,
      {
        payload: { childNodes },
      }: PayloadAction<{
        childNodes: HierarchyServiceResponseDto;
      }>
    ) => {
      const leafShortName = state.leafNodeShortName;

      state.triggerSearch = false;
      state.searchHasNextPage = childNodes.hasNextPage;

      if (leafShortName) {
        const products: HierarchyItem[] = childNodes.results.map((node) => {
          const {
            code,
            depth,
            shortName,
            name,
            parent,
            transactionSourceAccess,
          } = node;

          return {
            code,
            depth,
            parent,
            isLeaf: shortName === leafShortName,
            name,
            ordinal: 0,
            shortName,
            transactionSourceAccess,
            type: HierarchyItemType.Hierarchy,
          };
        });

        state.dataItems = [...products];
        state.expandedRows = [];
      }
    },

    onHierarchyRootNodeReceived: (
      state: GroupHierarchySourceState,
      { payload }: PayloadAction<HierarchyServiceResponseDto>
    ) => {
      const rootHierarchyItems: NestedHierarchyItem[] = payload.results.map(
        (item) => ({
          code: item.code,
          depth: item.depth,
          isLeaf: false,
          name: item.name,
          ordinal: 0,
          parent: item.parent,
          shortName: item.shortName,
          transactionSourceAccess: item.transactionSourceAccess,
          type: HierarchyItemType.Hierarchy,
        })
      );

      state.dataItems = [...rootHierarchyItems];
      state.expandedRows =
        rootHierarchyItems.length === 1 ? [...rootHierarchyItems] : [];

      if (state.searchString === "" && !state.isAdvancedSearchEnabled) {
        // Create new object for unfilteredData
        state.unfilteredData = {
          items: [...state.dataItems],
          expandedRows: [...state.expandedRows],
        };
      }
    },
    onFilterRulesUpdate: (
      state: GroupHierarchySourceState,
      {
        payload: { filterRules },
      }: PayloadAction<{
        filterRules: HierarchyGroupRuleWithIdAndName[];
      }>
    ) => {
      // test that new filter rules are different from the current ones minus the empty rule set
      const filterRulesMinusEmpty = filterRules.filter(
        (filterRule) => filterRule.values.length > 0
      );

      const hasFilters = filterRules.length > 0;

      const isAllFiltersComplete =
        hasFilters && filterRules.every(isFilterComplete);

      // ensure we only want to update view when filter rules are different from the current ones and
      // no inflight requests have been triggered
      if (
        !areFiltersEqual(state.filterRules, filterRulesMinusEmpty) &&
        !state.triggerSearch &&
        isAllFiltersComplete
      ) {
        groupHierarchySourceSlice.caseReducers.onSearchChange(state, {
          payload: {
            searchString: state.searchString,
            filterRules,
          },
          type: "onSearchChange",
        });
      }
    },

    onHierarchyMetadataReceived: (
      state: GroupHierarchySourceState,
      {
        payload: { hierarchyMetadata },
      }: PayloadAction<{
        hierarchyMetadata: HierarchyMetadataResponseDto[];
      }>
    ) => {
      state.hierarchyMetadata = hierarchyMetadata;

      const hierarchyType = state.type;

      const structure = hierarchyMetadata.filter(
        (attribute) =>
          attribute.structureName?.toLowerCase() === hierarchyType.toLowerCase()
      );
      const structureShortNames = structure.map(
        (metadata) => metadata.shortName
      );

      state.hierarchyStructure = structureShortNames;

      state.leafNodeShortName =
        structure.find((attribute) => attribute.isLeaf)?.shortName ?? "";
    },

    onSearchChange: (
      state: GroupHierarchySourceState,
      {
        payload: {
          filterRules,
          searchString,

          forceReload = false,
        },
      }: PayloadAction<{
        filterRules?: HierarchyGroupRuleWithIdAndName[];
        forceReload?: boolean;
        searchString: string;
      }>
    ) => {
      if (
        state.searchString === searchString &&
        (!filterRules || areFiltersEqual(state.filterRules, filterRules)) &&
        !forceReload
      ) {
        return;
      }

      state.searchString = searchString;

      if (filterRules) {
        state.filterRules = filterRules;
      }

      if (state.searchString === "" && !state.isAdvancedSearchEnabled) {
        state.triggerSearch = false;
      } else {
        state.triggerSearch = true;
      }

      if (state.isAdvancedSearchEnabled) {
        if (
          state.filterRules.some(
            (rule) =>
              rule.values.length > 0 &&
              !state.hierarchyStructure.includes(rule.shortName)
          )
        ) {
          state.focalAttributes = [state.leafNodeShortName];
        } else if (state.focalAttributes.length > 0) {
          state.focalAttributes = [];
        }
      } else if (state.focalAttributes.length > 0) {
        state.focalAttributes = [];
      }

      if (state.searchString === "" && !state.isAdvancedSearchEnabled) {
        groupHierarchySourceSlice.caseReducers.copyUnfilteredDataStoreToHierarchyTableData(
          state
        );
      }
    },

    onShowAdvancedSearch: (state: GroupHierarchySourceState) => {
      state.isAdvancedSearchEnabled = !state.isAdvancedSearchEnabled;

      if (!state.isAdvancedSearchEnabled) {
        groupHierarchySourceSlice.caseReducers.onSearchChange(state, {
          payload: {
            searchString: state.searchString,
            filterRules: [...DEFAULT_FILTER_RULES],
          },
          type: "onSearchChange",
        });
      }
    },

    reset: (
      state: GroupHierarchySourceState,
      {
        payload: { hierarchyType },
      }: PayloadAction<{ hierarchyType: HierarchyType }>
    ) => {
      state.dataItems = [];
      state.expandedRows = [];
      state.filterRules = [...DEFAULT_FILTER_RULES];
      state.focalAttributes = [];
      state.hierarchyMetadata = [];
      state.hierarchyStructure = [];
      state.isAdvancedSearchEnabled = false;
      state.leafNodeShortName = "";
      state.searchHasNextPage = false;
      state.searchString = "";
      state.selectedRows = [];
      state.subscription = undefined;
      state.subscriptionOptions = [];
      state.triggerSearch = false;
      state.type = hierarchyType;
      state.unfilteredData = {
        items: [],
        expandedRows: [],
      };
    },

    toggleAllHierarchyNodes: (
      state: GroupHierarchySourceState,
      { payload: { checked } }: PayloadAction<{ checked: boolean }>
    ) => {
      const items = state.dataItems;
      const selectedRows = state.selectedRows;

      const selectedRowsMap = new Map<string, HierarchyItem>();

      // Initialize the map with existing selectedRows
      for (const row of selectedRows) {
        const key = `${row.code}|${row.shortName}`;
        selectedRowsMap.set(key, row);
      }

      if (checked) {
        // Add items that don't exist yet
        for (const item of items) {
          const key = `${item.code}|${item.shortName}`;
          if (!selectedRowsMap.has(key)) {
            selectedRowsMap.set(key, item);
          }
        }
      } else {
        // Remove items
        for (const item of items) {
          const key = `${item.code}|${item.shortName}`;
          selectedRowsMap.delete(key);
        }
      }

      // Convert map back to array
      state.selectedRows = Array.from(selectedRowsMap.values());
    },
    setHierarchyNodeExpanding: (
      state: GroupHierarchySourceState,
      {
        payload: { nodeCode, nodeShortName },
      }: PayloadAction<{
        nodeCode: string;
        nodeShortName: string;
      }>
    ) => {
      toggleNodeExpanding(state.dataItems, nodeCode, nodeShortName);
    },

    onHierarchySearchResultsReceived: (
      state: GroupHierarchySourceState,
      {
        payload: { childNodes, leafNodeShortName },
      }: PayloadAction<{
        childNodes: HierarchyServiceResponseDto[];
        leafNodeShortName: string | undefined;
      }>
    ) => {
      state.triggerSearch = false;

      if (childNodes.length) {
        const rootHierarchyItems: HierarchyItem[] = childNodes.map((node) => {
          const { code, depth, shortName, name, transactionSourceAccess } =
            node.results[0].parent;

          return {
            code,
            depth,
            isLeaf: shortName === leafNodeShortName,
            name,
            ordinal: 0,
            shortName,
            transactionSourceAccess,
            type: HierarchyItemType.Hierarchy,
          };
        });

        state.dataItems = [...rootHierarchyItems];

        for (const rootItem of rootHierarchyItems) {
          const subItems = childNodes.find(
            (node) =>
              node.results[0].parent.code === rootItem.code &&
              node.results[0].parent.shortName === rootItem.shortName
          );

          if (subItems) {
            const subRows: NestedHierarchyItem[] = subItems.results.map(
              (node) => ({
                ...node,
                isLeaf: node.shortName === leafNodeShortName,
                ordinal: 0,
                type: HierarchyItemType.Hierarchy,
              })
            );

            if (subItems.hasNextPage) {
              subRows[subRows.length - 1].isMoreRow = true;
            }

            insertChildrenIntoPosition(
              state.dataItems,
              subRows,
              rootItem.code,
              rootItem.shortName
            );

            toggleNodeExpanding(
              state.dataItems,
              rootItem.code,
              rootItem.shortName
            );
          }
        }

        state.expandedRows = [...rootHierarchyItems];
      }
    },

    onSubscriptionChange: (
      state: GroupHierarchySourceState,
      { payload: subscription }: PayloadAction<SubscriptionDto>
    ) => {
      state.subscription = subscription;
      state.selectedRows = [];
      state.searchString = "";

      if (state.isAdvancedSearchEnabled) {
        groupHierarchySourceSlice.caseReducers.onShowAdvancedSearch(state);
      }
    },
  },
});

export const {
  reset,
  onEntitledSubscriptionsSuccess,
  onHierarchyRootNodeReceived,
  onHierarchyChildNodesReceived,
  onHierarchyNodeSelection,
  onHierarchyMetadataReceived,
  onHierarchySearchResultsReceived,
  onShowAdvancedSearch,
  onSearchChange,
  onFilterRulesUpdate,
  onProductSearchResultsReceived,
  setHierarchyNodeExpanding,
  hierarchyNodeExpanded,
  onSubscriptionChange,
  toggleAllHierarchyNodes,
} = groupHierarchySourceSlice.actions;

export default groupHierarchySourceSlice.reducer;

export const selectHierarchyItems = (state: RootState): NestedHierarchyItem[] =>
  state.groupHierarchySource.dataItems;

export const selectSearchHasNextPage = (state: RootState): boolean =>
  state.groupHierarchySource.searchHasNextPage;

export const selectHierarchySelectedRows = (
  state: RootState
): HierarchyItem[] => state.groupHierarchySource.selectedRows;

export const selectHierarchyExpandedRows = (
  state: RootState
): HierarchyItem[] => state.groupHierarchySource.expandedRows;

export const selectLeafNodeShortName = (state: RootState): string =>
  state.groupHierarchySource.leafNodeShortName;

export const selectSubscription = (
  state: RootState
): SubscriptionDto | undefined => state.groupHierarchySource.subscription;

export const selectSubscriptionOptions = (
  state: RootState
): SubscriptionDto[] => state.groupHierarchySource.subscriptionOptions;

export const selectSearchString = (state: RootState): string =>
  state.groupHierarchySource.searchString;

export const selectHierarchyType = (state: RootState): string =>
  state.groupHierarchySource.type;

export const selectAdvancedSearchEnableState = (state: RootState): boolean =>
  state.groupHierarchySource.isAdvancedSearchEnabled;

export const selectFilterRules = (
  state: RootState
): HierarchyGroupRuleWithIdAndName[] => state.groupHierarchySource.filterRules;

export const selectHierarchyMetadata = (
  state: RootState
): HierarchyMetadataResponseDto[] =>
  state.groupHierarchySource.hierarchyMetadata;

export const selectTriggerSearchState = (state: RootState): boolean =>
  state.groupHierarchySource.triggerSearch;

export const selectFocalAttributes = (state: RootState): string[] =>
  state.groupHierarchySource.focalAttributes;

export const selectHierarchyStructure = (state: RootState): string[] =>
  state.groupHierarchySource.hierarchyStructure;

export const selectIsAllChecked = createSelector(
  [selectHierarchyItems, selectHierarchySelectedRows],
  (dataItems: NestedHierarchyItem[], dataSelectedRows: HierarchyItem[]) => {
    if (dataItems.length === 0) return false;

    // Using a Map for O(1) lookups instead of find
    const selectedRowsMap = new Map<string, boolean>();
    for (const row of dataSelectedRows) {
      selectedRowsMap.set(`${row.code}|${row.shortName}`, true);
    }

    return dataItems.every((item) =>
      selectedRowsMap.has(`${item.code}|${item.shortName}`)
    );
  }
);
