import { Text } from "@qbit/react";
import { ParameterId, type ParameterDto } from "@quantium-enterprise/common-ui";
import { getTimePeriodState } from "../../parameters/time/TimePeriodState";
import { getTimePeriodDataTypeState } from "../../parameters/time/TrendedAndAggregatedState";
import { useAppSelector } from "../../states/hooks";
import styles from "./SummaryPanelTimePeriod.module.css";

type SummaryPanelTrendedAndAggregatedProps = {
  parameterDto: ParameterDto;
};

export const SummaryPanelTrendedAndAggregated = ({
  parameterDto,
}: SummaryPanelTrendedAndAggregatedProps) => {
  const focusPeriodState = useAppSelector(
    getTimePeriodState(ParameterId.FocusPeriod)
  );
  const timePeriodDataTypeState = useAppSelector(
    getTimePeriodDataTypeState(parameterDto.id)
  );

  // Early return if any required data is missing
  if (!focusPeriodState || !timePeriodDataTypeState) {
    return null;
  }

  const selectedOption = parameterDto.options.find(
    (option) => option.value === timePeriodDataTypeState.value
  );

  if (!selectedOption) {
    return null;
  }

  return (
    <div>
      <Text className={styles.label}>{selectedOption.label}</Text>
    </div>
  );
};

export default SummaryPanelTrendedAndAggregated;
