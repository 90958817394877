import { Nav, NavButton, NavVariant, Spinner, SpinnerSize } from "@qbit/react";
import {
  type ReportParametersDto,
  type ParameterGroupSelectionDto,
  type Flags,
  type SharedUserDto,
  type SharingActivity,
  FeatureFlag,
  ParameterId,
} from "@quantium-enterprise/common-ui";
import { useFlags } from "@quantium-enterprise/hooks-ui";
import { type PropsWithChildren, useState } from "react";
import { ParameterGroup } from "report-parameters-ui/src/common/model/ParameterGroup";
import { type Folder } from "../modals/move-item-modal/MoveItemModal";
import { PanelWithSideDrawer } from "../panel-with-side-drawer/PanelWithSideDrawer";
import { InfoPanelActivity } from "./info-panel-body/InfoPanelActivity";
import { InfoPanelBody } from "./info-panel-body/InfoPanelBody";
import {
  type InfoPanelType,
  InfoPanelHeader,
  InfoPanelLabel,
} from "./info-panel-header/InfoPanelHeaderWithRedux";

export type InfoPanelProps = {
  focalReport: ReportParametersDto | undefined;
  focalReportPath?: string;
  folders?: Folder[];
  handleDownload?: () => Promise<void>;
  infoPanelType: InfoPanelType;
  isDownloadEnabled?: boolean;
  isDownloadableReport?: boolean;
  isLoading: boolean;
  isOverlay?: boolean;
  isReportDisabled?: boolean;
  isRerunLoading?: boolean;
  onClose: () => void;
  onRerun?: () => void;
  organisationUsers?: SharedUserDto[];
  renameItem: (newItemName: string, itemId: string) => Promise<unknown>;
  reportSharedUsers?: SharedUserDto[];
  reportTypeDisplayName?: string;
  setIsPollingEnabled: (newState: boolean) => void;
  setShowDeleteModal: (newState: boolean) => void;
  setShowMoveModal: (newState: boolean) => void;
  setShowShareModal: (newState: boolean) => void;
  sharingActivities?: SharingActivity[];
  showPanel: boolean;
  trackButtonClick?: Function;
};

export const transformLocationParameterSelection = (
  parameterGroupSelections?: ParameterGroupSelectionDto[]
) => {
  const locationSelection = parameterGroupSelections?.find(
    (selection) => selection.name === ParameterGroup.Location
  );

  const locationParameter = locationSelection?.parameterSelections.filter(
    (parameter) =>
      parameter.id === ParameterId.LocationHierarchy ||
      parameter.id === ParameterId.LocationGroups ||
      parameter.id === ParameterId.CompStore
  );

  const locationStructureParameter =
    locationSelection?.parameterSelections.find(
      (parameter) => parameter.id === ParameterId.LocationStructure
    );

  if (
    parameterGroupSelections &&
    locationSelection &&
    locationParameter &&
    locationStructureParameter
  ) {
    const newLocationSelection: ParameterGroupSelectionDto = {
      ...locationSelection,
      parameterSelections: locationParameter,
    };
    const locationStructureSelection: ParameterGroupSelectionDto = {
      name: "Location structure",
      parameterSelections: [locationStructureParameter],
    };

    const locationSelectionIndex = parameterGroupSelections.findIndex(
      (selection) => selection.name === ParameterGroup.Location
    );

    const newParameterGroupSelections: ParameterGroupSelectionDto[] = [
      ...parameterGroupSelections.slice(0, locationSelectionIndex),
      newLocationSelection,
      locationStructureSelection,
      ...parameterGroupSelections.slice(locationSelectionIndex + 1),
    ];

    return newParameterGroupSelections;
  }

  return parameterGroupSelections;
};

export const ReportInfoPanelDrawerContent = ({
  focalReport,
  infoPanelType,
  isLoading,
  focalReportPath,
  isReportDisabled,
  isRerunLoading,
  onRerun,
  renameItem,
  reportSharedUsers,
  reportTypeDisplayName,
  sharingActivities,
  trackButtonClick,
  flags,
  currentNavIndex,
  setCurrentNavIndex,
  setShowShareModal,
  setShowDeleteModal,
  setShowMoveModal,
  setIsPollingEnabled,
  isDownloadableReport,
  isDownloadEnabled,
  handleDownload,
}: Omit<InfoPanelProps, "children" | "isOverlay" | "onClose" | "showPanel"> & {
  currentNavIndex: number;
  flags: Flags;
  onDelete: () => void;
  setCurrentNavIndex: (currentNavIndex: number) => void;
}) => {
  if (isLoading) {
    return {
      body: <Spinner size={SpinnerSize.Large} />,
      header: <> </>,
      label: <> </>,
    };
  } else if (focalReport) {
    const infoPanelTabs = [
      {
        content: (
          <InfoPanelBody
            parameterGroups={transformLocationParameterSelection(
              focalReport.parameterGroupSelections
            )}
            transactionSource={focalReport.dataSource}
          />
        ),
        title: "Selections",
      },
    ];

    if (flags[FeatureFlag.SharingReports]) {
      const activityTab = {
        content: (
          <InfoPanelActivity
            resourceType="report"
            sharedUsers={reportSharedUsers}
            sharingActivities={sharingActivities}
          />
        ),
        title: "Activity",
      };
      infoPanelTabs.push(activityTab);
    }

    return {
      body: infoPanelTabs[currentNavIndex].content,
      header: (
        <>
          <InfoPanelHeader
            createDateUtc={focalReport.createDateUtc}
            handleDownload={handleDownload}
            infoPanelType={infoPanelType}
            isDownloadEnabled={isDownloadEnabled}
            isDownloadableReport={isDownloadableReport}
            isReportDisabled={isReportDisabled}
            isRerunLoading={isRerunLoading}
            onRerun={onRerun}
            renameItem={renameItem}
            reportId={focalReport.id}
            reportName={focalReport.reportName}
            reportPath={focalReportPath}
            reportType={focalReport.reportType}
            retentionDays={focalReport.retentionDays}
            setIsPollingEnabled={setIsPollingEnabled}
            setShowDeleteModal={setShowDeleteModal}
            setShowMoveModal={setShowMoveModal}
            setShowShareModal={setShowShareModal}
            trackButtonClick={() =>
              trackButtonClick?.(
                focalReport.reportTypeDisplayName,
                focalReport.id
              )
            }
          />
          <Nav activeIndex={currentNavIndex} variant={NavVariant.Tab}>
            {infoPanelTabs.map((tab, index) => (
              <NavButton
                key={tab.title}
                onClick={() => setCurrentNavIndex(index)}
              >
                {tab.title}
              </NavButton>
            ))}
          </Nav>
        </>
      ),
      label: (
        <InfoPanelLabel
          reportType={
            reportTypeDisplayName ??
            (focalReport.reportTypeDisplayName || focalReport.reportType)
          }
        />
      ),
    };
  } else {
    return {
      body: <p>Failed to retrieve report parameters.</p>,
      header: <> </>,
      label: <> </>,
    };
  }
};

export const InfoPanel = (properties: PropsWithChildren<InfoPanelProps>) => {
  const { children, isOverlay = false, onClose, showPanel } = properties;
  const [currentNavIndex, setCurrentNavIndex] = useState(0);
  const flags = useFlags();
  return (
    <PanelWithSideDrawer
      closeFunc={onClose}
      drawerContent={ReportInfoPanelDrawerContent({
        ...properties,
        currentNavIndex,
        setCurrentNavIndex,
        flags,
        onDelete: onClose,
      })}
      isOverlay={isOverlay}
      showDrawer={showPanel}
    >
      {children}
    </PanelWithSideDrawer>
  );
};
