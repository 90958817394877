import {
  ParameterId,
  type ParameterDto,
  isListSelectionDto,
  type SelectionDto,
} from "@quantium-enterprise/common-ui";
import { createSelector } from "@reduxjs/toolkit";
import { type ParameterState } from "../../states/ParameterState";
import { type RootState } from "../../store";
import { isTimePeriodState } from "./TimePeriodState";
import { type TimePeriodOption } from "./components/time-period-option";
import { validateRollingPeriodOptions } from "./utilities";

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export interface RollingPeriodState extends ParameterState {
  errors: Record<string, boolean>;
  label: string;
  options: TimePeriodOption[];
  value: string;
}

export const isRollingPeriodState = (
  state: ParameterState
): state is RollingPeriodState =>
  Object.prototype.hasOwnProperty.call(state, "label") &&
  Object.prototype.hasOwnProperty.call(state, "options") &&
  Object.prototype.hasOwnProperty.call(state, "value");

export const resetRollingPeriodState = (
  parameterConfig: ParameterDto,
  parameterGroup: string
): RollingPeriodState => ({
  errors: {
    value: true,
  },
  isValid: false,
  isWarning: false,
  label: "",
  options: parameterConfig.options.map((option) => ({
    disabled: false,
    endDate: "",
    label: option.label,
    startDate: "",
    value: option.value,
    // TODO: finish implementation of custom length weeks for other periods
    customLength: false,
    options: [],
  })),
  parameterConfig,
  parameterGroup,
  value: "",
});

export const newRollingPeriodState = (
  parameterConfig: ParameterDto,
  parameterGroup: string,
  savedSelections?: SelectionDto[]
): RollingPeriodState => {
  let newState = resetRollingPeriodState(parameterConfig, parameterGroup);
  const prefilledSelection =
    savedSelections ?? parameterConfig.selections ?? [];

  if (prefilledSelection.length > 0) {
    const selection = prefilledSelection[0];
    if (isListSelectionDto(selection)) {
      newState = {
        ...newState,
        errors: {},
        isValid: true,
        value: selection.value,
        label: selection.label,
      };
    }
  }

  return { ...newState };
};

export const getRollingPeriodState = (parameterId: string) =>
  createSelector(
    (state: RootState) => state.reportParameter.parameters[parameterId],
    (state) => state as RollingPeriodState | undefined
  );

export const handleRollingPeriodSelection = (
  rollingPeriodState: ParameterState,
  selection: string
): ParameterState => {
  // Type guard check first
  // https://dev.to/ylerjen/typescript-cast-is-a-type-breaker-1jbh
  if (isRollingPeriodState(rollingPeriodState)) {
    // Get options which contains the start & end date
    const result = rollingPeriodState.parameterConfig.options.find(
      (option) => option.value === selection
    );

    if (result) {
      return {
        ...rollingPeriodState,
        errors: {},
        isValid: true,
        isParameterMissing: false,
        label: result.label,
        value: result.value,
      } as ParameterState;
    }

    return resetRollingPeriodState(
      rollingPeriodState.parameterConfig,
      rollingPeriodState.parameterGroup
    ) as ParameterState;
  }

  // Default is don't do anything to change the state
  return { ...rollingPeriodState };
};

export const validateRollingPeriodSelection = (
  parameters: Record<string, ParameterState>
) => {
  const rollingPeriodExists = Object.keys(parameters).includes(
    ParameterId.RollingPeriod
  );

  if (rollingPeriodExists) {
    const rollingPeriodState = parameters[ParameterId.RollingPeriod];
    const focusPeriodState = parameters[ParameterId.FocusPeriod];

    if (
      isRollingPeriodState(rollingPeriodState) &&
      isTimePeriodState(focusPeriodState)
    ) {
      let updatedRollingPeriodState = validateRollingPeriodOptions(
        rollingPeriodState,
        focusPeriodState
      );

      updatedRollingPeriodState = {
        ...updatedRollingPeriodState,
        isWarning: updatedRollingPeriodState.options.every(
          (option) => option.disabled
        ),
      };

      const selection = updatedRollingPeriodState.options.find(
        (option) => option.value === rollingPeriodState.value
      );

      if (selection?.disabled) {
        updatedRollingPeriodState = {
          ...updatedRollingPeriodState,
          errors: { value: true },
          isValid: false,
          label: "",
          value: "",
        };
      }

      parameters[ParameterId.RollingPeriod] = updatedRollingPeriodState;
    }
  }

  return parameters;
};
