import {
  getPreferredTransactionSource,
  getSelectedHierarchyTransactionSources,
  ParameterId,
  TransactionSource,
} from "@quantium-enterprise/common-ui";
import { TransactionSourceDisplayNames } from "components-ui/src/icons/transaction-source-icon/TransactionSourceIcon";
import { isGroupParameterState } from "../parameters/groups/GroupParameterState";
import { isHierarchyState } from "../parameters/hierarchy/HierarchyState";
import {
  checkLoaContainsAttribute,
  getHierarchicalSelections,
  isLevelOfAnalysisState,
} from "../parameters/hierarchy/LevelOfAnalysisState";
import {
  checkStructureContainsAttribute,
  isStructureState,
} from "../parameters/hierarchy/StructureState";
import {
  isListParameterState,
  type ListParameterState,
} from "../parameters/list/ListParameterState";
import { type ParameterState } from "./ParameterState";
import { type WizardState } from "./report-wizard-slice";

export const getValidGroupTransactionSources = (
  groupState: ParameterState,
  transactionSources: TransactionSource[]
): TransactionSource[] => {
  if (!isGroupParameterState(groupState)) {
    return transactionSources;
  }

  return transactionSources.filter((source) =>
    groupState.confirmedSelections.every((group) => {
      const groupTransactionSources =
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        groupState.knownGroupTransactionSources[group.id!];
      return (
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        !groupTransactionSources || groupTransactionSources.includes(source)
      );
    })
  );
};

export const getValidReportTransactionSources = (
  state: WizardState
): TransactionSource[] => {
  const productHierarchy = state.parameters[ParameterId.ProductHierarchy];
  const locationHierarchy = state.parameters[ParameterId.LocationHierarchy];

  // New parameter in Basket Affinities
  const focalProducts = state.parameters[ParameterId.FocalProducts];
  const associatedProducts = state.parameters[ParameterId.AssociatedProducts];
  if (isHierarchyState(focalProducts) && isHierarchyState(associatedProducts)) {
    return [TransactionSource.Customer];
  }

  if (
    !isHierarchyState(productHierarchy) ||
    !isHierarchyState(locationHierarchy)
  ) {
    return [];
  }

  const productItems = productHierarchy.selectedRows;
  const productStructure = state.parameters[ParameterId.ProductStructure];
  const levelOfAnalysis = state.parameters[ParameterId.LevelOfAnalysis];

  // Transaction source access is only checked for hierarchical levels.
  // Non-hierarchical product attributes (e.g. brand) are treated as leaf level
  // for the purposes of transaction source access
  let productAggregateLevels: string[] = [];
  if (isStructureState(productStructure)) {
    const hasAttribute = checkStructureContainsAttribute(productStructure);
    productAggregateLevels = hasAttribute
      ? [productHierarchy.data.leafShortName]
      : [];
  } else if (isLevelOfAnalysisState(levelOfAnalysis)) {
    const hierarchyLevels = getHierarchicalSelections(levelOfAnalysis);
    const hasAttribute = checkLoaContainsAttribute(levelOfAnalysis);
    productAggregateLevels = hasAttribute
      ? [productHierarchy.data.leafShortName]
      : hierarchyLevels;
  }

  const productSources = getSelectedHierarchyTransactionSources(
    productItems,
    productAggregateLevels,
    state.isProductHierarchyRunOnLowestLevel
      ? productHierarchy.data.leafShortName
      : undefined
  );

  // Non-hierarchical locations attributes (e.g. store cluster) have no restrictions
  // for the purposes of transaction source access
  const locationItems = locationHierarchy.selectedRows;
  const locationAggregateLevels: string[] = [];

  const locationSources = getSelectedHierarchyTransactionSources(
    locationItems,
    locationAggregateLevels
  );

  let validSources = Object.values(TransactionSource).filter(
    (source) =>
      productSources.includes(source) && locationSources.includes(source)
  );

  validSources = getValidGroupTransactionSources(
    state.parameters[ParameterId.ProductGroups],
    validSources
  );

  validSources = getValidGroupTransactionSources(
    state.parameters[ParameterId.LocationGroups],
    validSources
  );

  if (state.savedParameters) {
    // The above method relies heavily on the valid transaction sources to come
    // from the item selections in product and location. However, When restoring
    // transaction source from saved parameters, the items won't contain any
    // information on the transaction sources available so the end result will be
    // and empty array of `validSource`. This is to ensure that the transaction source
    // from the saved parameters can still be valid and set. The backend will still
    // prevent invalid transaction sources by failing any report that is invalid.
    const savedParameters = state.savedParameters;
    const savedSource = state.transactionSources.find((source) =>
      savedParameters.dataSource.includes(source.idOverwrite ?? source.id)
    );
    if (savedSource) {
      const savedSourceId = savedSource.idOverwrite ?? savedSource.id;
      validSources = [...validSources, savedSourceId];
    }
  }

  return validSources;
};

export const updateTransactionSourceWarning = (state: WizardState) => {
  const selectedTransactionSourceIds = state.transactionSources
    .filter((source) => source.isSelected)
    .map((source) => source.idOverwrite ?? source.id);

  const sampleSourceDisplayName = TransactionSourceDisplayNames.SampleCustomer;

  const sampleSourceId = state.transactionSources.find(
    (source) => source.displayName === sampleSourceDisplayName
  )?.idOverwrite;

  if (!sampleSourceId) {
    return;
  }

  const warningMessage = `Due to your selection and subscription, this report will now run on ${sampleSourceDisplayName}.`;

  // warning only when the user's selection results in a Sample.
  const isWarning = selectedTransactionSourceIds.includes(sampleSourceId);

  const relevantParameterIds = [
    ParameterId.ProductHierarchy,
    ParameterId.ProductStructure,
    ParameterId.LevelOfAnalysis,
    ParameterId.LocationHierarchy,
    ParameterId.LocationStructure,
  ];
  for (const parameterId of relevantParameterIds) {
    if (!Object.keys(state.parameters).includes(parameterId)) {
      continue;
    }

    const parameterState = state.parameters[parameterId];
    const parameterGroup = state.parameterGroups[parameterState.parameterGroup];

    if (parameterGroup) {
      if (isWarning) {
        parameterGroup.isEntitlementsWarning = true;
        parameterGroup.entitlementsWarningMessage = warningMessage;
      } else {
        parameterGroup.isEntitlementsWarning = false;
        parameterGroup.entitlementsWarningMessage = undefined;
      }
    }
  }
};

export const updateReportTransactionSources = (state: WizardState) => {
  const validSourceIds = getValidReportTransactionSources(state);
  let selectedSourceIds: TransactionSource[];

  state.transactionSources = state.transactionSources.map((source) => ({
    ...source,
    isEnabled: validSourceIds.includes(source.idOverwrite ?? source.id),
  }));

  const datasetParameter = state.parameters[ParameterId.Dataset];

  if (isListParameterState(datasetParameter)) {
    const selections = datasetParameter.selections.filter((selection) =>
      validSourceIds.includes(selection.value as TransactionSource)
    );
    const newDatasetState: ListParameterState = {
      ...datasetParameter,
      selections,
    };
    state.parameters[ParameterId.Dataset] = newDatasetState;
    selectedSourceIds = selections.map(
      (selection) => selection.value as TransactionSource
    );
  } else {
    const preferredSourceIds = getPreferredTransactionSource(
      state.transactionSources,
      validSourceIds
    );

    selectedSourceIds = preferredSourceIds ?? [];
  }

  state.transactionSources = state.transactionSources.map((source) => ({
    ...source,
    isSelected: selectedSourceIds.includes(source.idOverwrite ?? source.id),
  }));

  if (state.isDataEntitlementsShown) {
    updateTransactionSourceWarning(state);
  }
};
