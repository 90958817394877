import { type DragEndEvent } from "@dnd-kit/core";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  Checkbox,
  FormBlock,
  FormBlockType,
  Input,
  Number,
  NumberSentiment,
  NumberUnitPosition,
  Spinner,
  SpinnerSize,
  Text,
} from "@qbit/react";
import {
  HierarchyType,
  type FocalItem,
  HierarchyShortName,
  type TransactionSource,
  useGetUserQuery,
  getTransactionSourceFromEntitlements,
  HierarchyItemType,
} from "@quantium-enterprise/common-ui";
import { useOpenFastReportingTabs } from "@quantium-enterprise/fast-reporting-ui";
import {
  MetricTypes,
  useDivision,
  useFormatter,
} from "@quantium-enterprise/hooks-ui";
import {
  type Row,
  type CellContext,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import classNames from "classnames";
import { HierarchyLevelIcon } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { HierarchyGroupIcon } from "components-ui/src/icons";
import { TransactionSourceIcon } from "components-ui/src/icons/transaction-source-icon/TransactionSourceIcon";
import { useMemo, useCallback, useState, useEffect } from "react";
import DragIcon from "../../assets/drag.svg";
import GrowthDecreaseIcon from "../../assets/growth-decrease.svg";
import GrowthIncreaseIcon from "../../assets/growth-increase.svg";
import GrowthNeutralIcon from "../../assets/growth-neutral.svg";
import { type WatchlistItemDto } from "../../services/dtos/WatchlistItemDto";
import { WatchlistItemType } from "../../services/dtos/WatchlistItemDto";
import {
  type WatchlistItemWithMetrics,
  type MetricValue,
  type MetricDefinition,
} from "./WatchlistTable";
import styles from "./WatchlistTableNew.module.css";

export type WatchlistTableNewProps = {
  comparisonPeriodTitle: string;
  focalPeriodTitle: string;
  items: WatchlistItemWithMetrics[];
  metrics: MetricDefinition[];
  onDeleteItems: (items: WatchlistItemDto[]) => void;
  onReorderItem: (
    item: WatchlistItemDto,
    oldPosition: number,
    newPosition: number
  ) => void;
  onSelectionChange?: (selectedItems: Set<string>) => void;
  refetching: boolean;
  selectedItems?: Set<string>;
  userSelectedTransactionSource?: TransactionSource;
};

const MetricData =
  (
    refetching: boolean,
    formatter: Function,
    transactionSource?: TransactionSource
  ) =>
  (context: CellContext<WatchlistItemWithMetrics, MetricValue | undefined>) => {
    if (
      transactionSource &&
      !context.row.original.entitlements.includes(transactionSource)
    ) {
      return <Text>N/A</Text>;
    }

    const value = context.getValue();
    if (value === undefined || refetching) {
      return undefined;
    }

    return <Number>{formatter(value.value)}</Number>;
  };

const MetricDataLoading =
  (
    refetching: boolean,
    formatter: Function,
    transactionSource?: TransactionSource
  ) =>
  (context: CellContext<WatchlistItemWithMetrics, MetricValue | undefined>) => {
    if (
      transactionSource &&
      !context.row.original.entitlements.includes(transactionSource)
    ) {
      return <Text>N/A</Text>;
    }

    return context.getValue() === undefined || refetching ? (
      <Spinner className={styles.metricDataLoading} size={SpinnerSize.Small} />
    ) : (
      MetricData(refetching, formatter, transactionSource)(context)
    );
  };

const GrowthData =
  (
    refetching: boolean,
    formatter: Function,
    transactionSource?: TransactionSource
  ) =>
  (context: CellContext<WatchlistItemWithMetrics, MetricValue | undefined>) => {
    let growthIcon = (
      <img
        alt="No growth"
        className={styles.growthIcon}
        src={GrowthNeutralIcon}
      />
    );

    if (
      transactionSource &&
      !context.row.original.entitlements.includes(transactionSource)
    ) {
      return (
        <>
          <Text>N/A</Text>
          {growthIcon}
        </>
      );
    }

    const value = context.getValue();
    if (value === undefined || refetching) {
      return undefined;
    }

    const numberString = value.hasValue ? formatter(value.value) : "N/A";
    let sentiment = NumberSentiment.Neutral;

    if (value.value > 0) {
      sentiment = NumberSentiment.Good;
      growthIcon = (
        <img
          alt="Value has increased"
          className={styles.growthIcon}
          src={GrowthIncreaseIcon}
        />
      );
    }

    if (value.value < 0) {
      sentiment = NumberSentiment.Bad;
      growthIcon = (
        <img
          alt="Value has decreased"
          className={styles.growthIcon}
          src={GrowthDecreaseIcon}
        />
      );
    }

    return (
      <>
        <Number sentiment={sentiment} unitPosition={NumberUnitPosition.After}>
          {numberString}
        </Number>{" "}
        {growthIcon}
      </>
    );
  };

export type WatchlistItemIconProps = {
  item: WatchlistItemDto;
};

const WatchlistItemIcon = ({
  item,
}: WatchlistItemIconProps): JSX.Element | null => {
  if (item.type === WatchlistItemType.Hierarchy) {
    return (
      <HierarchyLevelIcon
        shortName={item.shortName}
        type={
          item.isLeaf ? HierarchyItemType.Leaf : HierarchyItemType.Hierarchy
        }
      />
    );
  }

  if (item.type === WatchlistItemType.Attribute) {
    return (
      <HierarchyLevelIcon
        shortName={item.shortName}
        type={HierarchyItemType.Attribute}
      />
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (item.type === WatchlistItemType.ProductGroup) {
    return (
      <HierarchyGroupIcon
        evaluationType={item.evaluationType}
        hierarchyType={HierarchyType.Product}
      />
    );
  }

  return null;
};

type CombinedHeaderCheckboxProps = {
  onSelectAll: (checked: boolean) => void;
  selectAll: boolean;
};

const CombinedHeaderCheckbox = ({
  selectAll,
  onSelectAll,
}: CombinedHeaderCheckboxProps) => (
  <div className={styles.combinedHeaderCheckbox}>
    <FormBlock blockType={FormBlockType.Checkbox}>
      <Input>
        <Checkbox
          checked={selectAll}
          data-cy="SelectAllCheckbox"
          label=""
          name="select-all-checkbox"
          onChange={(event) => onSelectAll(event.target.checked)}
        />
      </Input>
    </FormBlock>
  </div>
);

const ItemHeader = () => <span className={styles.itemHeader}>Item</span>;
type SortableRowProps = {
  isDelimited: (index: number) => boolean;
  row: Row<WatchlistItemWithMetrics>;
};

const SortableRow = ({ row, isDelimited }: SortableRowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: row.original.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <tr data-watchlist-item-id={row.original.id} ref={setNodeRef} style={style}>
      {row.getVisibleCells().map((cell, cellIndex) => {
        if (cellIndex === 0) {
          return (
            <td
              className={classNames({
                [styles.watchlistTableDataDelimiter]: isDelimited(cellIndex),
              })}
              key={cell.id}
            >
              {flexRender(cell.column.columnDef.cell, {
                ...cell.getContext(),
                dragAttributes: attributes,
                dragListeners: listeners,
              })}
            </td>
          );
        }

        return (
          <td
            className={classNames({
              [styles.watchlistTableDataDelimiter]: isDelimited(cellIndex),
            })}
            key={cell.id}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
        );
      })}
    </tr>
  );
};

const createCombinedHeaderCheckboxRenderer =
  (selectAll: boolean, onSelectAll: (checked: boolean) => void) => () =>
    <CombinedHeaderCheckbox onSelectAll={onSelectAll} selectAll={selectAll} />;

const createItemHeaderRenderer = () => () => <ItemHeader />;

export const WatchlistTableNew = ({
  items,
  metrics,
  focalPeriodTitle,
  comparisonPeriodTitle,
  onReorderItem,
  refetching,
  userSelectedTransactionSource,
  selectedItems: externalSelectedItems,
  onSelectionChange,
}: WatchlistTableNewProps) => {
  const [tableData, setTableData] = useState(items);
  const [selectedItems, setSelectedItems] = useState<Set<string>>(
    externalSelectedItems ?? new Set()
  );
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    setTableData(items);
  }, [items]);

  useEffect(() => {
    if (externalSelectedItems) {
      setSelectedItems(externalSelectedItems);
      setSelectAll(
        externalSelectedItems.size === tableData.length && tableData.length > 0
      );
    }
  }, [externalSelectedItems, tableData]);
  useEffect(() => {
    if (selectedItems.size === tableData.length && tableData.length > 0) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedItems, tableData]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const { data: user } = useGetUserQuery();
  const { transactionSources: availableTransactionSources } = useDivision();
  const formatter = useFormatter();

  const isSupplier = useMemo(() => user?.isSupplier, [user]);
  const displayEntitlements =
    isSupplier === true || userSelectedTransactionSource !== undefined;

  const handleToggleSelection = useCallback(
    (itemId: string) => {
      setSelectedItems((previousSelected) => {
        const newSelected = new Set(Array.from(previousSelected));

        if (newSelected.has(itemId)) {
          newSelected.delete(itemId);
        } else {
          newSelected.add(itemId);
        }

        if (onSelectionChange) {
          onSelectionChange(newSelected);
        }

        return newSelected;
      });
    },
    [onSelectionChange]
  );

  const handleSelectAll = useCallback(
    (checked: boolean) => {
      setSelectAll(checked);
      if (checked) {
        const allIds = tableData.map((item) => item.id);
        const newSelected = new Set(allIds);
        setSelectedItems(newSelected);

        if (onSelectionChange) {
          onSelectionChange(newSelected);
        }
      } else {
        const newSelected = new Set<string>();
        setSelectedItems(newSelected);

        if (onSelectionChange) {
          onSelectionChange(newSelected);
        }
      }
    },
    [tableData, onSelectionChange]
  );

  const isDelimited = useCallback(
    (index: number) => {
      if (index < 3) {
        return false;
      }

      const modifier = displayEntitlements ? 3 : 4;

      return (index + modifier) % 3 === 0;
    },
    [displayEntitlements]
  );

  const isHeaderDelimited = useCallback(
    (index: number, depth: number) => {
      if (index <= 2) {
        return false;
      }

      if (depth === 0) {
        return true;
      }

      const firstMetricColumnIndex = displayEntitlements ? 3 : 2;
      return (index - firstMetricColumnIndex) % 3 === 0;
    },
    [displayEntitlements]
  );

  const openTabs = useOpenFastReportingTabs();
  const openFastReportingTab = useCallback(
    (row: Row<WatchlistItemWithMetrics>) => {
      let focalItem: FocalItem;

      if (row.original.type === WatchlistItemType.Attribute) {
        focalItem = {
          code: row.original.code,
          displayName: row.original.name,
          shortName: row.original.shortName,
          type: HierarchyItemType.Attribute,
          additionalHierarchyFilter: {
            code: row.original.hierarchyCode ?? "",
            shortName: row.original.hierarchyLevel ?? "",
            displayName: row.original.hierarchyName ?? "",
          },
        };
      } else if (row.original.type === WatchlistItemType.Hierarchy) {
        focalItem = {
          code: row.original.code,
          displayName: row.original.name,
          shortName: row.original.shortName,
          type: row.original.isLeaf
            ? HierarchyItemType.Leaf
            : HierarchyItemType.Hierarchy,
        };
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      } else if (row.original.type === WatchlistItemType.ProductGroup) {
        focalItem = {
          type: HierarchyShortName.ProductGroup,
          displayName: row.original.name,
          evaluationType: row.original.evaluationType,
          productGroupId: row.original.productGroupId,
        };
      } else {
        throw new Error("Unknown watchlist item type");
      }

      openTabs([focalItem]);
    },
    [openTabs]
  );

  const createCombinedActionsCell = useCallback(
    ({
      row,
      dragAttributes,
      dragListeners,
    }: CellContext<WatchlistItemWithMetrics, unknown> & {
      dragAttributes?: React.HTMLAttributes<HTMLDivElement>;
      dragListeners?:
        | React.DragEventHandler<HTMLDivElement>
        | Record<string, React.EventHandler<React.SyntheticEvent>>;
    }) => (
      <div className={styles.combinedHandleCheckbox}>
        <div
          className={styles.dragHandle}
          data-cy="GrabHandle"
          {...dragAttributes}
          {...dragListeners}
        >
          <img alt="drag" className={styles.dragIcon} src={DragIcon} />
        </div>
        <div
          aria-label="Checkbox"
          className={styles.compactCheckbox}
          onClick={(event) => {
            event.stopPropagation();
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter" || event.key === " ") {
              event.stopPropagation();
            }
          }}
          role="button"
          tabIndex={0}
        >
          <FormBlock blockType={FormBlockType.Checkbox}>
            <Input>
              <Checkbox
                checked={selectedItems.has(row.original.id)}
                data-cy="ItemCheckbox"
                label=""
                name={`checkbox-${row.original.id}`}
                onChange={() => {
                  handleToggleSelection(row.original.id);
                }}
              />
            </Input>
          </FormBlock>
        </div>
      </div>
    ),
    [selectedItems, handleToggleSelection]
  );

  const createItemNameCell = useCallback(
    ({ row }: CellContext<WatchlistItemWithMetrics, string>) => (
      <div className={styles.nameCellContainer}>
        <span className={styles.hierarchyIcon}>
          <WatchlistItemIcon item={row.original} />
        </span>

        <div
          className={styles.nameContainer}
          data-cy="ItemName"
          onClick={() => openFastReportingTab(row)}
          onKeyUp={() => openFastReportingTab(row)}
          role="button"
          tabIndex={0}
        >
          <div className={styles.nameContent}>
            <span className={styles.primaryName}>{row.original.name}</span>
            {row.original.type === WatchlistItemType.Attribute &&
            row.original.hierarchyName ? (
              <span className={styles.nameSecondary}>
                in [{row.original.hierarchyLevel}] {row.original.hierarchyName}
              </span>
            ) : null}
          </div>
        </div>
      </div>
    ),
    [openFastReportingTab]
  );

  const createTransactionSourceCell = ({
    row,
  }: CellContext<WatchlistItemWithMetrics, unknown>) => {
    const transactionSource =
      userSelectedTransactionSource ??
      getTransactionSourceFromEntitlements(row.original.entitlements);
    return (
      <>
        {transactionSource && (
          <div className={styles.transactionSource} data-cy="TransactionSource">
            <TransactionSourceIcon
              availableTransactionSources={availableTransactionSources}
              greyedOut
              transactionSource={transactionSource}
            />
          </div>
        )}
      </>
    );
  };

  const percentagePointFormatter = (value: number | null | undefined) =>
    formatter(MetricTypes.Percentage)(value);

  const columnHelper = createColumnHelper<WatchlistItemWithMetrics>();
  const columns = useMemo(() => {
    const combinedHeaderRenderer = createCombinedHeaderCheckboxRenderer(
      selectAll,
      handleSelectAll
    );
    const itemHeaderRenderer = createItemHeaderRenderer();

    return [
      columnHelper.group({
        columns: [
          columnHelper.accessor((index) => index.id, {
            cell: createCombinedActionsCell,
            header: combinedHeaderRenderer,
            id: "combinedActions",
          }),
        ],
        id: "actions",
      }),
      columnHelper.group({
        columns: [
          columnHelper.accessor((index) => index.name, {
            cell: createItemNameCell,
            header: "",
            id: "itemName",
          }),
        ],
        header: itemHeaderRenderer,
        id: "item",
      }),
    ];
  }, [
    selectAll,
    handleSelectAll,
    columnHelper,
    createCombinedActionsCell,
    createItemNameCell,
  ]);

  if (displayEntitlements) {
    columns.push(
      columnHelper.group({
        columns: [
          columnHelper.accessor((index) => index.name, {
            cell: createTransactionSourceCell,
            header: "",
            id: "transactionSource",
          }),
        ],
        // eslint-disable-next-line react/no-unstable-nested-components
        header: () => <span className={styles.datasetHeader}>Dataset</span>,
        id: "dataset",
      })
    );
  }

  for (const metric of metrics) {
    columns.push(
      columnHelper.group({
        columns: [
          columnHelper.accessor(
            (index) => index.metrics[metric.key]?.focalPeriod,
            {
              cell: MetricData(
                refetching,
                formatter(metric.formatType),
                userSelectedTransactionSource
              ),
              header: focalPeriodTitle,
              id: metric.key + "-focal",
            }
          ),
          columnHelper.accessor(
            (index) => index.metrics[metric.key]?.comparisonPeriod,
            {
              cell: MetricDataLoading(
                refetching,
                formatter(metric.formatType),
                userSelectedTransactionSource
              ),
              header: comparisonPeriodTitle,
              id: metric.key + "-comparison",
            }
          ),
          columnHelper.accessor((index) => index.metrics[metric.key]?.growth, {
            cell: GrowthData(
              refetching,
              metric.formatType === MetricTypes.Percentage
                ? percentagePointFormatter
                : formatter(MetricTypes.Percentage),
              userSelectedTransactionSource
            ),
            header: "Change (%)",
            id: metric.key + "-growth",
          }),
        ],
        header: metric.displayName,
      })
    );
  }

  const table = useReactTable<WatchlistItemWithMetrics>({
    columns,
    data: tableData,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const rows = table.getRowModel().rows;
      const activeIndex = rows.findIndex(
        (row) => row.original.id === active.id
      );
      const overIndex = rows.findIndex((row) => row.original.id === over.id);

      if (activeIndex !== -1 && overIndex !== -1) {
        const newItems = [...tableData];
        const [removed] = newItems.splice(activeIndex, 1);
        newItems.splice(overIndex, 0, removed);

        setTableData(newItems);

        onReorderItem(removed, activeIndex, overIndex);
      }
    }
  };

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <div
        className={styles.watchlistTableContainer}
        data-cy="WatchlistTableContainer"
      >
        <table className={styles.watchlistTable} data-cy="WatchlistTable">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header, headerIndex) => {
                  const needsBorder = isHeaderDelimited(
                    headerIndex,
                    headerGroup.depth
                  );

                  const isMetricHeader =
                    headerGroup.depth === 0 &&
                    headerIndex >= (displayEntitlements ? 3 : 2);

                  if (headerIndex === 0 && headerGroup.depth === 0) {
                    return (
                      <th
                        className={classNames({
                          [styles.watchlistTableDataDelimiter]: needsBorder,
                        })}
                        colSpan={header.colSpan}
                        key={header.id}
                      >
                        {header.isPlaceholder ? null : (
                          <div>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </div>
                        )}
                      </th>
                    );
                  }

                  return (
                    <th
                      className={classNames({
                        [styles.watchlistTableDataDelimiter]: needsBorder,
                        [styles.metricCategoryHeader]:
                          isMetricHeader && needsBorder,
                      })}
                      colSpan={header.colSpan}
                      key={header.id}
                    >
                      {header.isPlaceholder ? null : (
                        <div>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            <SortableContext
              items={tableData.map((item) => item.id)}
              strategy={verticalListSortingStrategy}
            >
              {table.getRowModel().rows.map((row) => (
                <SortableRow isDelimited={isDelimited} key={row.id} row={row} />
              ))}
            </SortableContext>
          </tbody>
        </table>
      </div>
    </DndContext>
  );
};

export default WatchlistTableNew;
