import {
  type TransactionSource,
  type MetricsOptionDto,
  type ParameterOptionDto,
  type AttributesFilterConfig,
  type HierarchyItemDto,
  type HierarchyGroupRuleWithIdAndName,
  type HierarchyGroupDto,
  type HierarchyMetadataResponseDto,
  type HierarchyItem,
  HierarchyItemType,
  type HierarchyServiceResponseDto,
  getPreferredTransactionSource,
  type ParameterConfigurationsDto,
  ParameterId,
  type SavedParametersDetailsDto,
  isHierarchyItemFilterMatch,
  createRowNameMatchesSearchPredicate,
  areFiltersEqual,
} from "@quantium-enterprise/common-ui";
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type Item } from "components-ui/src/drag-and-drop/models/Item";
import { type Zone } from "components-ui/src/drag-and-drop/models/Zone";
import { type HierarchyGridItem } from "components-ui/src/hierarchy-select-grid/models/hierarchy";
import { ContextMenuType } from "components-ui/src/tables/common/table-cell/ContextMenu";
import { type BandSelection } from "../parameters/bands/BandsParameterState";
import { handleBandsParameterSelections } from "../parameters/bands/BandsParameterState";
import { handleBufferParameterSelection } from "../parameters/buffer/BufferParameterState";
import {
  type DropdownOption,
  handleDropdownParameterSelection,
} from "../parameters/dropdown/DropdownParameterState";
import {
  type GroupParameterState,
  isGroupParameterState,
  toggleGroupSelection,
  updateGroupTransactionSources,
} from "../parameters/groups/GroupParameterState";
import {
  handleHierarchyNodeSelections,
  isHierarchyState,
  type HierarchyState,
  DEFAULT_FILTER_RULES,
  defaultSearchState,
} from "../parameters/hierarchy/HierarchyState";
import {
  getHierarchicalStructureZones,
  getStructureParameterId,
} from "../parameters/hierarchy/HierarchyUtilities";
import {
  handleLevelOfAnalysisReset,
  handleLevelOfAnalysisSelection,
  handleLevelOfAnalysisSingleSelection,
} from "../parameters/hierarchy/LevelOfAnalysisState";
import { type LocationHierarchyParameterItem } from "../parameters/hierarchy/LocationHierarchyParameterTable";
import { type ProductHierarchyParameterItem } from "../parameters/hierarchy/ProductHierarchyParameterTable";
import {
  handleDroppableZonesSelection,
  handleItemsSelection,
  handleStructureItemSelection,
} from "../parameters/hierarchy/StructureState";
import { type ListParameterSelectionType } from "../parameters/list/ListParameterSelectionType";
import { handleListParameterSelection } from "../parameters/list/ListParameterState";
import {
  handleMeasureThresholdAdded,
  handleMeasureThresholdRemoved,
  handleMeasureThresholdUpdated,
  type MeasureThresholdSelection,
} from "../parameters/measure-threshold/MeasureThresholdParameterState";
import { type MetricsState } from "../parameters/metrics/MetricsState";
import {
  isMetricsState,
  handleMetricSelection,
  handleMetricsSelections,
  handleGroupExpansion,
  handleMetricsSearch,
  isMetricGroup,
} from "../parameters/metrics/MetricsState";
import {
  handleSegmentFilterParameterSelection,
  type SegmentFilterOption,
} from "../parameters/segment-filter/SegmentFilterParameterState";
import { type SegmentationSelection } from "../parameters/segmentation/SegmentationParameterState";
import { handleSegmentationParameterSelection } from "../parameters/segmentation/SegmentationParameterState";
import {
  handleRollingPeriodSelection,
  validateRollingPeriodSelection,
} from "../parameters/time/RollingPeriodState";
import {
  handleComparisonPeriodSelection,
  handleEndDateSelection,
  handleFocusPeriodSelection,
  handleLeadPeriodSelection,
  handleStartDateSelection,
  handleResetLeadPeriodSelection,
  type TimePeriodState,
  handleWeeksSelection,
} from "../parameters/time/TimePeriodState";
import { handleTimePeriodDataTypeSelection } from "../parameters/time/TrendedAndAggregatedState";
import { TimePeriodOptions } from "../parameters/time/components/TimePeriodConstants";
import {
  overwriteChildrenInPosition,
  insertChildrenIntoPosition,
  toggleNodeExpanding,
  createItemLookup,
} from "../parameters/utils/HierarchyParameterTableUtils";
import { type RootState } from "../store";
import { type GroupState } from "./GroupState";
import { initialParametersState, type ParameterState } from "./ParameterState";
import { updateReportTransactionSources } from "./transaction-source-utils";
import { validateGroupState } from "./validation-utils";

const HIERARCHY_PARAMETERS = [
  ParameterId.ProductHierarchy,
  ParameterId.LocationHierarchy,
  ParameterId.FocalProducts,
  ParameterId.AssociatedProducts,
];

export type TransactionSourceState = {
  displayName: string;
  id: TransactionSource;
  idOverwrite?: TransactionSource;
  isEnabled: boolean;
  isSelected?: boolean;
  preferenceOrder: number;
};

export type ServerErrorState = {
  errorMessage: string;
  isError: boolean;
  parameters: string[];
};

// Top level State
export type WizardState = {
  firstProductSelectionMade: boolean;
  isDataEntitlementsShown: boolean;
  isProductHierarchyRunOnLowestLevel: boolean;
  isRerun: boolean;
  parameterGroups: Record<string, GroupState | undefined>;
  parameters: Record<string, ParameterState>;
  savedParameters?: SavedParametersDetailsDto;
  selectionsMade: boolean;
  serverError: ServerErrorState;
  transactionSources: TransactionSourceState[];
};

const initialState: WizardState = {
  firstProductSelectionMade: false,
  isDataEntitlementsShown: false,
  parameterGroups: {},
  parameters: {},
  selectionsMade: false,
  transactionSources: [],
  savedParameters: undefined,
  serverError: {
    isError: false,
    errorMessage: "",
    parameters: [],
  },
  isProductHierarchyRunOnLowestLevel: false,
  isRerun: false,
};

const initialisePrefilledAndValidStates = (
  initialParameterGroups: Record<string, GroupState>,
  initialParametersStates: Record<string, ParameterState>
) => {
  const groups = Object.keys(initialParameterGroups);

  for (const group of groups) {
    const groupState = initialParameterGroups[group];
    const groupParameters = groupState.parameters.map(
      (parameterName) => initialParametersStates[parameterName].parameterConfig
    );

    const { isValid, isGroupValid } = validateGroupState(
      groupState,
      initialParametersStates
    );

    groupState.isValid = isValid;

    groupState.isPrefilled =
      groupParameters.some((parameter) =>
        parameter.options.find((option) => option.isDefault)
      ) && isGroupValid;
  }
};

export const UpdateParameterGroup = (parameter: string, state: WizardState) => {
  const parameterExists = Object.keys(state.parameters).includes(parameter);
  if (parameterExists) {
    const groupName = state.parameters[parameter].parameterGroup;
    const groupState = state.parameterGroups[groupName];

    if (groupState) {
      const groupParameters = groupState.parameters;

      groupState.isValid = validateGroupState(
        groupState,
        state.parameters
      ).isValid;

      groupState.isWarning = groupParameters.some(
        (groupParameter) => state.parameters[groupParameter].isWarning === true
      );

      groupState.isInfo = groupParameters.some(
        (groupParameter) => state.parameters[groupParameter].isInfo === true
      );

      groupState.isParametermissing = groupParameters.some(
        (groupParameter) =>
          state.parameters[groupParameter].isParameterMissing === true
      );

      // isPrefilled need to reset when parameter is changed by user
      groupState.isPrefilled = false;
      state.selectionsMade = true;
    }
  }

  return state.parameterGroups;
};

const verifyProductStructure = (state: WizardState) => {
  const parameters = Object.keys(state.parameters);
  if (!parameters.includes(ParameterId.ProductStructure)) {
    return;
  }

  const productStructure = state.parameters[ParameterId.ProductStructure];
  const productHierarchy = state.parameters[ParameterId.ProductHierarchy];
  if (!isHierarchyState(productHierarchy)) {
    return;
  }

  if (productHierarchy.selectedRows.length === 0) {
    if (!parameters.includes(ParameterId.ProductGroups)) {
      return;
    }

    const productGroup = state.parameters[ParameterId.ProductGroups];

    if (
      isGroupParameterState(productGroup) &&
      (productGroup.confirmedSelections.length > 0 ||
        productGroup.pendingSelections.length > 0)
    ) {
      productStructure.isInfo = true;
      productStructure.isWarning = false;
    } else {
      productStructure.isWarning = true;
      productStructure.isInfo = false;
    }
  } else {
    productStructure.isInfo = false;
    productStructure.isWarning = false;
  }
};

const updateRerunHierarchySelections = (
  state: WizardState,
  parameterId: ParameterId
) => {
  const contextMenutype = (state.parameters[parameterId] as HierarchyState)
    .parameterConfig.contextMenuType;

  const lockSelection =
    ContextMenuType[contextMenutype ?? "None"] === ContextMenuType.Menu;

  const localState = state.parameters[parameterId];
  if (isHierarchyState(localState) && lockSelection) {
    if (localState.selectedRows.length === 0) {
      localState.hierarchyLevelLock = undefined;
    } else {
      let minSelectedDepth: number | undefined;
      let maxSelectedDepth: number | undefined;
      for (const row of localState.selectedRows) {
        if (minSelectedDepth === undefined || minSelectedDepth > row.depth) {
          minSelectedDepth = row.depth;
        }

        if (maxSelectedDepth === undefined || maxSelectedDepth < row.depth) {
          maxSelectedDepth = row.depth;
        }
      }

      if (minSelectedDepth !== undefined && maxSelectedDepth !== undefined)
        localState.hierarchyLevelLock = {
          min: minSelectedDepth,
          max: maxSelectedDepth,
        };
    }
  }

  if (parameterId === ParameterId.ProductHierarchy) {
    verifyProductStructure(state);
    state.firstProductSelectionMade = true;
  }
};

const resetServerErrorOnParameterChange = (
  parameterId: string,
  state: WizardState
) => {
  if (
    state.serverError.isError &&
    state.serverError.parameters.includes(parameterId)
  ) {
    state.serverError = {
      isError: false,
      errorMessage: "",
      parameters: [],
    };
  }
};

/**
 * Use this function to check if the LoA parameter selections should be reset when some other parameter selection is being updated.
 *
 * @param parameters parameter state collection
 * @returns a boolean value
 */
export const hasLevelOfAnalysisTab = (
  parameters: Record<string, ParameterState>
) => Object.keys(parameters).includes(ParameterId.LevelOfAnalysis);

export const reportParameterSlice = createSlice({
  initialState,
  name: "Report Wizard Slice",
  reducers: {
    comparisonPeriodSelected: (
      state: WizardState,
      {
        payload: { parameter, timePeriodValue },
      }: PayloadAction<{
        parameter: string;
        timePeriodValue: string;
      }>
    ) => {
      state.parameters = handleComparisonPeriodSelection(
        state.parameters,
        timePeriodValue
      );

      state.parameterGroups = UpdateParameterGroup(parameter, state);
      resetServerErrorOnParameterChange(parameter, state);
    },

    droppableZonesSelected: (
      state: WizardState,
      {
        payload: { parameter, zones },
      }: PayloadAction<{
        parameter: string;
        zones: Zone[];
      }>
    ) => {
      state.parameters[parameter] = handleDroppableZonesSelection(
        state.parameters[parameter],
        zones
      );

      updateReportTransactionSources(state);
      state.parameterGroups = UpdateParameterGroup(parameter, state);
      resetServerErrorOnParameterChange(parameter, state);
    },

    timePeriodDataTypeSelected: (
      state: WizardState,
      {
        payload: { parameter, value },
      }: PayloadAction<{
        parameter: string;
        value: string;
      }>
    ) => {
      state.parameters[parameter] = handleTimePeriodDataTypeSelection(
        state.parameters[parameter],
        value
      );

      state.parameterGroups = UpdateParameterGroup(parameter, state);
      resetServerErrorOnParameterChange(parameter, state);
    },

    endDateSelected: (
      state: WizardState,
      {
        payload: { parameter, endDate },
      }: PayloadAction<{
        endDate: string;
        parameter: string;
      }>
    ) => {
      state.parameters[parameter] = handleEndDateSelection(
        state.parameters[parameter],
        endDate,
        parameter
      );
      state.parameterGroups = UpdateParameterGroup(parameter, state);
      resetServerErrorOnParameterChange(parameter, state);

      if (parameter === ParameterId.FocusPeriod) {
        state.parameters = handleComparisonPeriodSelection(state.parameters);
        state.parameterGroups = UpdateParameterGroup(
          ParameterId.ComparisonPeriod,
          state
        );
      }

      state.parameters = validateRollingPeriodSelection(state.parameters);
      state.parameterGroups = UpdateParameterGroup(
        ParameterId.RollingPeriod,
        state
      );
    },

    focusWeeksSelected: (
      state: WizardState,
      {
        payload: { parameter, weeks },
      }: PayloadAction<{
        parameter: string;
        weeks: number;
      }>
    ) => {
      state.parameters[parameter] = handleWeeksSelection(
        state.parameters[parameter],
        weeks,
        parameter
      );
      state.parameterGroups = UpdateParameterGroup(parameter, state);
      resetServerErrorOnParameterChange(parameter, state);

      state.parameters = handleComparisonPeriodSelection(state.parameters);
      state.parameterGroups = UpdateParameterGroup(
        ParameterId.ComparisonPeriod,
        state
      );

      state.parameters[ParameterId.LeadPeriod] = handleLeadPeriodSelection(
        state.parameters[ParameterId.LeadPeriod],
        state.parameters[parameter]
      );
      state.parameterGroups = UpdateParameterGroup(
        ParameterId.LeadPeriod,
        state
      );

      state.parameters = validateRollingPeriodSelection(state.parameters);
      state.parameterGroups = UpdateParameterGroup(
        ParameterId.RollingPeriod,
        state
      );
    },

    focusPeriodSelected: (
      state: WizardState,
      {
        payload: { parameter, timePeriodValue },
      }: PayloadAction<{
        parameter: string;
        timePeriodValue: string[] | string;
      }>
    ) => {
      state.parameters[parameter] = handleFocusPeriodSelection(
        state.parameters[parameter],
        timePeriodValue
      );
      state.parameterGroups = UpdateParameterGroup(parameter, state);
      resetServerErrorOnParameterChange(parameter, state);

      state.parameters = handleComparisonPeriodSelection(state.parameters);
      state.parameterGroups = UpdateParameterGroup(
        ParameterId.ComparisonPeriod,
        state
      );

      state.parameters[ParameterId.LeadPeriod] = handleLeadPeriodSelection(
        state.parameters[ParameterId.LeadPeriod],
        state.parameters[parameter]
      );
      state.parameterGroups = UpdateParameterGroup(
        ParameterId.LeadPeriod,
        state
      );

      state.parameters = validateRollingPeriodSelection(state.parameters);
      state.parameterGroups = UpdateParameterGroup(
        ParameterId.RollingPeriod,
        state
      );
    },

    leadWeeksSelected: (
      state: WizardState,
      {
        payload: { parameter, weeks },
      }: PayloadAction<{
        parameter: string;
        weeks: number;
      }>
    ) => {
      const focusPeriodState = state.parameters[
        ParameterId.FocusPeriod
      ] as TimePeriodState;
      const focusPeriodWeeks = focusPeriodState.weeks ?? 0;
      state.parameters[parameter] = handleWeeksSelection(
        state.parameters[parameter],
        weeks,
        parameter,
        focusPeriodWeeks
      );
      state.parameterGroups = UpdateParameterGroup(parameter, state);
    },

    leadPeriodSelected: (
      state: WizardState,
      {
        payload: { parameter, leadPeriodValue },
      }: PayloadAction<{
        leadPeriodValue: string;
        parameter: string;
      }>
    ) => {
      state.parameters[parameter] = handleLeadPeriodSelection(
        state.parameters[parameter],
        state.parameters[ParameterId.FocusPeriod],
        leadPeriodValue
      );

      state.parameterGroups = UpdateParameterGroup(parameter, state);
    },

    groupSelected: (
      state: WizardState,
      {
        payload: { parameterId, groups },
      }: PayloadAction<{
        groups: HierarchyGroupDto[];
        parameterId: string;
      }>
    ) => {
      state.parameters[parameterId] = toggleGroupSelection(
        state.parameters[parameterId],
        groups
      );

      updateReportTransactionSources(state);
      state.parameterGroups = UpdateParameterGroup(parameterId, state);
      resetServerErrorOnParameterChange(parameterId, state);

      if (parameterId === ParameterId.ProductGroups) {
        verifyProductStructure(state);
        reportParameterSlice.caseReducers.resetLevelOfAnalysis(state);
      }
    },

    groupValidated: (
      state: WizardState,
      {
        payload: { groupId, parameterId, transactionSources },
      }: PayloadAction<{
        groupId: string;
        parameterId: string;
        transactionSources: TransactionSource[];
      }>
    ) => {
      state.parameters[parameterId] = updateGroupTransactionSources(
        state.parameters[parameterId],
        groupId,
        transactionSources
      );

      updateReportTransactionSources(state);
      state.parameterGroups = UpdateParameterGroup(parameterId, state);
    },

    groupFolderExpanded: (
      state: WizardState,
      {
        payload: { parameter, expandedIds },
      }: PayloadAction<{
        expandedIds: string[];
        parameter: string;
      }>
    ) => {
      state.parameters[parameter] = {
        ...state.parameters[parameter],
        expandedIds,
      } as ParameterState;
    },

    hierarchyNodeExpanded: (
      state: WizardState,
      {
        payload: { parameter, expandedRows },
      }: PayloadAction<{
        expandedRows: HierarchyItem[];
        parameter: string;
      }>
    ) => {
      state.parameters[parameter] = {
        ...state.parameters[parameter],
        expandedRows,
      } as ParameterState;
    },

    hierarchyNodeSelected: (
      state: WizardState,
      {
        payload: { parameter, selectedRows },
      }: PayloadAction<{
        parameter: string;
        selectedRows: HierarchyItem[];
      }>
    ) => {
      state.parameters[parameter] = handleHierarchyNodeSelections(
        state,
        state.parameters[parameter],
        selectedRows
      );

      updateReportTransactionSources(state);
      state.parameterGroups = UpdateParameterGroup(parameter, state);
      resetServerErrorOnParameterChange(parameter, state);

      reportParameterSlice.caseReducers.updateStructureParameter(state, {
        payload: {
          // FIXME: hard coding this to LocationHierarchy as we're separating the updating of nodes in to separate reducers
          parameter: ParameterId.LocationHierarchy,
          selectedRows,
        },
        type: "updateStructureParameter",
      });
    },

    levelOfAnalysisOptionSelected: (
      state: WizardState,
      {
        payload: { parameter, selectedValue },
      }: PayloadAction<{
        parameter: string;
        selectedValue: string;
      }>
    ) => {
      state.parameters[parameter] = handleLevelOfAnalysisSelection(
        state.parameters[parameter],
        selectedValue
      );

      state.parameterGroups = UpdateParameterGroup(parameter, state);
      resetServerErrorOnParameterChange(parameter, state);
      updateReportTransactionSources(state);
    },

    levelOfAnalysisSingleOptionSelected: (
      state: WizardState,
      {
        payload: { parameter, selectedValue },
      }: PayloadAction<{
        parameter: string;
        selectedValue: string;
      }>
    ) => {
      state.parameters[parameter] = handleLevelOfAnalysisSingleSelection(
        state.parameters[parameter],
        selectedValue
      );

      state.parameterGroups = UpdateParameterGroup(parameter, state);
      resetServerErrorOnParameterChange(parameter, state);
      updateReportTransactionSources(state);
    },

    levelOfAnalysisOptionsUpdated: (
      state: WizardState,
      { payload: showWarning }: PayloadAction<boolean>
    ) => {
      if (hasLevelOfAnalysisTab(state.parameters)) {
        const levelOfAnalysisState =
          state.parameters[ParameterId.LevelOfAnalysis];
        levelOfAnalysisState.isWarning = showWarning;

        const groupState =
          state.parameterGroups[levelOfAnalysisState.parameterGroup];

        if (groupState !== undefined) {
          groupState.isWarning = groupState.parameters.some(
            (groupParameter) =>
              state.parameters[groupParameter].isWarning === true
          );
        }
      }
    },

    updateParameterWarningState: (
      state: WizardState,
      { payload }: PayloadAction<{ isWarning: boolean; parameter: string }>
    ) => {
      // Update the parameter's state
      const parameterState = state.parameters[payload.parameter];
      parameterState.isWarning = payload.isWarning;

      // Update the parameter group's state
      const parameterGroup =
        state.parameterGroups[parameterState.parameterGroup];
      if (parameterGroup) {
        parameterGroup.isWarning = parameterGroup.parameters.some(
          (groupParameter) =>
            state.parameters[groupParameter].isWarning === true
        );
      }
    },

    listOptionSelected: (
      state: WizardState,
      {
        payload: { parameter, selectedValue, selectionType },
      }: PayloadAction<{
        parameter: string;
        selectedValue: string;
        selectionType: ListParameterSelectionType;
      }>
    ) => {
      state.parameters[parameter] = handleListParameterSelection(
        state.parameters[parameter],
        selectedValue,
        selectionType
      );
      updateReportTransactionSources(state);
      state.parameterGroups = UpdateParameterGroup(parameter, state);
      resetServerErrorOnParameterChange(parameter, state);
    },

    dropdownOptionSelected: (
      state: WizardState,
      {
        payload: { parameterId, selection },
      }: PayloadAction<{
        parameterId: string;
        selection: DropdownOption;
      }>
    ) => {
      state.parameters[parameterId] = handleDropdownParameterSelection(
        state.parameters[parameterId],
        selection
      );
      state.parameterGroups = UpdateParameterGroup(parameterId, state);
      resetServerErrorOnParameterChange(parameterId, state);
    },

    segmentationOptionSelected: (
      state: WizardState,
      {
        payload: { parameter, selectedValue },
      }: PayloadAction<{
        parameter: string;
        selectedValue: SegmentationSelection;
      }>
    ) => {
      state.parameters[parameter] = handleSegmentationParameterSelection(
        state.parameters[parameter],
        selectedValue
      );

      state.parameterGroups = UpdateParameterGroup(parameter, state);
      resetServerErrorOnParameterChange(parameter, state);
    },

    segmentFilterOptionSelected: (
      state: WizardState,
      {
        payload: { parameterId, selection },
      }: PayloadAction<{
        parameterId: string;
        selection: SegmentFilterOption;
      }>
    ) => {
      state.parameters[parameterId] = handleSegmentFilterParameterSelection(
        state.parameters[parameterId],
        selection
      );

      state.parameterGroups = UpdateParameterGroup(parameterId, state);
      resetServerErrorOnParameterChange(parameterId, state);
    },

    // Initialisation action, gets called when RTQ fetches from the api
    // Once state has been set up it will set the initialised flag in the state to 'true'
    onConfigReceived: (
      state: WizardState,
      {
        payload: { parameterConfig },
      }: PayloadAction<{
        parameterConfig: ParameterConfigurationsDto;
      }>
    ) => {
      const preferredTransactionSource = getPreferredTransactionSource(
        parameterConfig.transactionSourceConfigs.map((config) => ({
          id: config.transactionSource,
          idOverwrite: config.transactionSourceOverwrite,
          preferenceOrder: config.preferenceOrdinal ?? Number.MAX_SAFE_INTEGER,
        }))
      );
      state.transactionSources = parameterConfig.transactionSourceConfigs.map(
        (config) => ({
          id: config.transactionSource,
          idOverwrite:
            config.transactionSourceOverwrite ?? config.transactionSource,
          displayName: config.displayName,
          preferenceOrder: config.preferenceOrdinal ?? Number.MAX_SAFE_INTEGER,
          isEnabled: true,
          isSelected: config.transactionSourceOverwrite
            ? preferredTransactionSource?.includes(
                config.transactionSourceOverwrite
              )
            : preferredTransactionSource?.includes(config.transactionSource),
        })
      );

      // Initialise a fresh state
      const initialParametersStates: Record<string, ParameterState> = {};
      const initialParameterGroups: Record<string, GroupState> = {};

      const savedParameters = state.isRerun
        ? undefined
        : state.savedParameters?.parameterGroupSelections.flatMap(
            (parameter) => parameter.parameterSelections
          );

      if (parameterConfig.parameterGroups) {
        for (const parameterGroup of parameterConfig.parameterGroups) {
          for (const parameter of parameterGroup.parameters) {
            const savedSelections = savedParameters?.find(
              (savedParameter) => savedParameter.id === parameter.id
            )?.selections;

            const newState = initialParametersState(
              parameter,
              parameterGroup.label,
              state.transactionSources.length > 0
                ? state.transactionSources[0].idOverwrite ??
                    state.transactionSources[0].id
                : undefined,
              state.isDataEntitlementsShown,
              savedSelections
            );
            initialParametersStates[parameter.id] = newState;
          }

          const groupParameters = parameterGroup.parameters;

          let isValidDefault:
            | Array<{ isValid: boolean; parameters: string[] }>
            | boolean = false;

          if (
            parameterGroup.errorMessage &&
            parameterGroup.errorMessage.length > 1
          ) {
            isValidDefault = parameterGroup.errorMessage.map((item) => ({
              parameters: item.parameters,
              isValid: false,
            }));
          }

          const groupState: GroupState = {
            errorMessage: parameterGroup.errorMessage,
            minSelections: parameterGroup.minSelections,
            maxSelections: parameterGroup.maxSelections,
            isParametermissing: Boolean(
              parameterGroup.parameterValidationMessage
            ),
            parameterWarningMessage: parameterGroup.parameterValidationMessage,
            isOptional:
              !parameterGroup.minSelections &&
              groupParameters.every(
                (parameter) => parameter.attributes.minSelections === 0
              ),
            isPrefilled: false,
            isValid: isValidDefault,
            parameters: groupParameters.map((parameter) => parameter.id),
            warningMessage: parameterGroup.warningMessage,
            infoMessage: parameterGroup.infoMessage,
          };

          initialParameterGroups[parameterGroup.label] = groupState;
        }

        initialisePrefilledAndValidStates(
          initialParameterGroups,
          initialParametersStates
        );

        state.parameterGroups = initialParameterGroups;
        state.parameters = initialParametersStates;

        // update the product & location structure report selections for report re-run
        if (state.isRerun) {
          for (const parameter in state.parameters) {
            if (
              parameter === ParameterId.ProductHierarchy ||
              parameter === ParameterId.LocationHierarchy
            ) {
              updateRerunHierarchySelections(state, parameter);
              reportParameterSlice.caseReducers.updateStructureParameter(
                state,
                {
                  payload: {
                    parameter,
                    selectedRows: (
                      state.parameters[parameter] as HierarchyState
                    ).selectedRows,
                  },
                  type: "updateStructureParameter",
                }
              );
            } else if (
              parameter === ParameterId.FocusPeriod &&
              Object.keys(state.parameters).includes(
                ParameterId.ComparisonPeriod
              ) &&
              (
                state.parameters[
                  ParameterId.ComparisonPeriod
                ] as TimePeriodState
              ).value !== TimePeriodOptions.CUSTOM_PERIOD.toString()
            ) {
              reportParameterSlice.caseReducers.comparisonPeriodSelected(
                state,
                {
                  payload: {
                    parameter,
                    timePeriodValue: (
                      state.parameters[
                        ParameterId.ComparisonPeriod
                      ] as TimePeriodState
                    ).value,
                  },
                  type: "updateComparisonPeriodParameter",
                }
              );
            }
          }
        }

        state.isRerun = false;
      }
    },

    onHierarchyChildNodesReceived: (
      state: WizardState,
      {
        payload: { childNodes, parameterType, overwrite },
      }: PayloadAction<{
        childNodes: HierarchyServiceResponseDto;
        overwrite?: boolean;
        parameterType: ParameterId;
      }>
    ) => {
      const hierarchyState = state.parameters[parameterType] as HierarchyState;

      const leafShortName = hierarchyState.data.leafShortName;
      const isHierarchyParameter = HIERARCHY_PARAMETERS.includes(parameterType);
      if (
        childNodes.count > 0 &&
        leafShortName !== "" &&
        isHierarchyParameter
      ) {
        const itemLookup = createItemLookup(hierarchyState.data.items);
        const subRows = [
          ...childNodes.results.map((node) => ({
            code: node.code,
            depth: node.depth,
            isLeaf: node.shortName === leafShortName,
            name: node.name,
            ordinal: 0,
            parent: node.parent,
            shortName: node.shortName,
            transactionSourceAccess: node.transactionSourceAccess,
            type: HierarchyItemType.Hierarchy,
          })),
        ] as LocationHierarchyParameterItem[];

        if (childNodes.hasNextPage) {
          subRows[subRows.length - 1].isMoreRow = true;
        }

        const parentCode = childNodes.results[0].parent.code;
        const parentShortName = childNodes.results[0].parent.shortName;

        if (overwrite) {
          overwriteChildrenInPosition(
            itemLookup,
            subRows,
            parentCode,
            parentShortName
          );
        } else {
          insertChildrenIntoPosition(
            itemLookup,
            subRows,
            parentCode,
            parentShortName
          );
        }

        toggleNodeExpanding(itemLookup, parentCode, parentShortName);

        if (
          hierarchyState.searchString === "" &&
          !hierarchyState.isAdvancedSearchEnabled
        ) {
          reportParameterSlice.caseReducers.copyHierarchyTableDataToUnfilteredDataStore(
            state,
            {
              payload: parameterType,
              type: "copyHierarchyTableDataToUnfilteredDataStore",
            }
          );
        }
      }
    },

    onServerError: (
      state: WizardState,
      action: PayloadAction<ServerErrorState>
    ) => {
      state.serverError = action.payload;
    },

    onShowAdvancedSearch: (
      state: WizardState,
      action: PayloadAction<ParameterId>
    ) => {
      const hierarchyState = state.parameters[action.payload] as HierarchyState;

      hierarchyState.isAdvancedSearchEnabled =
        !hierarchyState.isAdvancedSearchEnabled;

      if (!hierarchyState.isAdvancedSearchEnabled) {
        reportParameterSlice.caseReducers.onSearchChange(state, {
          payload: {
            searchString: hierarchyState.searchString,
            filterRules: [...DEFAULT_FILTER_RULES],
            parameterType: action.payload,
          },
          type: "onSearchChange",
        });
      }
    },

    onFilterRulesUpdate: (
      state: WizardState,
      {
        payload: { filterRules, parameterType },
      }: PayloadAction<{
        filterRules: HierarchyGroupRuleWithIdAndName[];
        parameterType: ParameterId;
      }>
    ) => {
      const hierarchyState = state.parameters[parameterType] as HierarchyState;

      // test that new filter rules are different from the current ones minus the empty rule set
      const filterRulesMinusEmpty = filterRules.filter(
        (filterRule) => filterRule.values.length > 0
      );

      // ensure we only want to update view when filter rules are different from the current ones and
      // no inflight requests have been triggered
      if (
        !areFiltersEqual(hierarchyState.filterRules, filterRulesMinusEmpty) &&
        !hierarchyState.triggerSearch
      ) {
        reportParameterSlice.caseReducers.onSearchChange(state, {
          payload: {
            searchString: hierarchyState.searchString,
            filterRules,
            parameterType,
          },
          type: "onSearchChange",
        });
      }
    },

    toggleShowSelectedItems: (
      state: WizardState,

      {
        payload: { parameterType },
      }: PayloadAction<{
        parameterType: ParameterId;
      }>
    ) => {
      const hierarchyState = state.parameters[parameterType] as HierarchyState;
      hierarchyState.isSelectedItemsShown =
        !hierarchyState.isSelectedItemsShown;
      hierarchyState.selectedItems = hierarchyState.selectedRows
        .map((row) => {
          // @ts-expect-error: type is incorrect
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { parent, subRows, parentRow, ...newRow } = row;
          return {
            ...newRow,
            isLeaf: true,
          };
        })
        .sort((a, b) => Number(a.code) - Number(b.code))
        .sort((a, b) => a.depth - b.depth);
      hierarchyState.selectedItemsFiltered = hierarchyState.selectedItems;

      reportParameterSlice.caseReducers.onSearchChange(state, {
        payload: {
          searchString: hierarchyState.searchString,
          filterRules: hierarchyState.filterRules,
          parameterType,
          forceReload: true,
        },
        type: "onSearchChange",
      });
    },

    onSearchChange: (
      state: WizardState,
      {
        payload: {
          filterRules,
          searchString,
          parameterType,
          forceReload = false,
        },
      }: PayloadAction<{
        filterRules: HierarchyGroupRuleWithIdAndName[];
        forceReload?: boolean;
        parameterType: ParameterId;
        searchString: string;
      }>
    ) => {
      const hierarchyState = state.parameters[parameterType] as HierarchyState;
      const rowNameMatchesSearch =
        createRowNameMatchesSearchPredicate(searchString);

      hierarchyState.selectedItemsFiltered = hierarchyState.selectedItems
        .filter(({ name }) => searchString === "" || rowNameMatchesSearch(name))
        .filter(({ code }) => isHierarchyItemFilterMatch(code, filterRules));

      if (
        hierarchyState.searchString === searchString &&
        areFiltersEqual(hierarchyState.filterRules, filterRules) &&
        !forceReload
      ) {
        return;
      }

      hierarchyState.searchString = searchString;
      hierarchyState.filterRules = filterRules;

      if (!hierarchyState.isSelectedItemsShown) {
        if (hierarchyState.isAdvancedSearchEnabled) {
          const hierarchyAttributes = hierarchyState.data.hierarchyMetadata
            .filter(
              (metadata) => metadata.structureName === hierarchyState.data.type
            )
            .map((metadata) => metadata.shortName);
          // business rule is to show only leaf nodes when non-hierarchical attributes are selected.
          if (
            filterRules.some(
              (rule) =>
                rule.values.length > 0 &&
                !hierarchyAttributes.includes(rule.shortName)
            )
          ) {
            hierarchyState.focalAttributes = [
              hierarchyState.data.leafShortName,
            ];
          } else if (hierarchyState.focalAttributes.length > 0) {
            hierarchyState.focalAttributes = [];
          }
        } else if (hierarchyState.focalAttributes.length > 0) {
          hierarchyState.focalAttributes = [];
        }

        if (searchString === "" && !hierarchyState.isAdvancedSearchEnabled) {
          hierarchyState.triggerSearch = false;
        } else {
          hierarchyState.triggerSearch = true;
        }

        if (
          hierarchyState.searchString === "" &&
          !hierarchyState.isAdvancedSearchEnabled
        ) {
          reportParameterSlice.caseReducers.copyUnfilteredDataStoreToHierarchyTableData(
            state,
            {
              payload: parameterType,
              type: "copyUnfilteredDataStoreToHierarchyTableData",
            }
          );
        }
      }
    },

    resetSearch: (state: WizardState, action: PayloadAction<ParameterId>) => {
      const hierarchyState = state.parameters[action.payload] as HierarchyState;

      (state.parameters[action.payload] as HierarchyState) = {
        ...hierarchyState,
        ...defaultSearchState,
      };
    },

    copyHierarchyTableDataToUnfilteredDataStore: (
      state: WizardState,
      action: PayloadAction<ParameterId>
    ) => {
      const hierarchyState = state.parameters[action.payload] as HierarchyState;

      hierarchyState.unfilteredData.items = [...hierarchyState.data.items];
      hierarchyState.unfilteredData.expandedRows = [
        ...hierarchyState.expandedRows,
      ];
    },

    copyUnfilteredDataStoreToHierarchyTableData: (
      state: WizardState,
      action: PayloadAction<ParameterId>
    ) => {
      const hierarchyState = state.parameters[action.payload] as HierarchyState;

      hierarchyState.data.items = [...hierarchyState.unfilteredData.items];
      hierarchyState.expandedRows = [
        ...hierarchyState.unfilteredData.expandedRows,
      ];
    },

    onHierarchySearchResultsReceived: (
      state: WizardState,
      {
        payload: { childNodes, parameterType },
      }: PayloadAction<{
        childNodes: HierarchyServiceResponseDto[];
        parameterType: ParameterId;
      }>
    ) => {
      const hierarchyState = state.parameters[parameterType] as HierarchyState;
      const leafShortName = hierarchyState.data.leafShortName;
      const isHierarchyParameter = HIERARCHY_PARAMETERS.includes(parameterType);

      hierarchyState.triggerSearch = false;

      if (childNodes.length && leafShortName && isHierarchyParameter) {
        const rootHierarchyItems: HierarchyItem[] = childNodes.map((node) => {
          const { code, depth, shortName, name, transactionSourceAccess } =
            node.results[0].parent;

          return {
            code,
            depth,
            isLeaf: shortName === leafShortName,
            name,
            ordinal: 0,
            shortName,
            transactionSourceAccess,
            type: HierarchyItemType.Hierarchy,
          };
        });

        hierarchyState.data.items = [...rootHierarchyItems];

        for (const rootItem of rootHierarchyItems) {
          // TODO: see if this can be moved outside the for loop
          const itemLookup = createItemLookup(hierarchyState.data.items);
          const subItems = childNodes.find(
            (node) =>
              node.results[0].parent.code === rootItem.code &&
              node.results[0].parent.shortName === rootItem.shortName
          );

          if (subItems) {
            const subRows: ProductHierarchyParameterItem[] =
              subItems.results.map((node) => ({
                ...node,
                isLeaf: node.shortName === leafShortName,
                ordinal: 0,
                type: HierarchyItemType.Hierarchy,
              }));

            if (subItems.hasNextPage) {
              subRows[subRows.length - 1].isMoreRow = true;
            }

            insertChildrenIntoPosition(
              itemLookup,
              subRows,
              rootItem.code,
              rootItem.shortName
            );

            toggleNodeExpanding(itemLookup, rootItem.code, rootItem.shortName);
          }
        }

        hierarchyState.expandedRows = [...rootHierarchyItems];
      }
    },

    onProductSearchResultsReceived: (
      state: WizardState,
      {
        payload: { childNodes, parameterType },
      }: PayloadAction<{
        childNodes: HierarchyServiceResponseDto;
        parameterType: ParameterId;
      }>
    ) => {
      const hierarchyState = state.parameters[parameterType] as HierarchyState;
      const leafShortName = hierarchyState.data.leafShortName;
      const isHierarchyParameter = HIERARCHY_PARAMETERS.includes(parameterType);
      hierarchyState.triggerSearch = false;
      hierarchyState.searchHasNextPage = childNodes.hasNextPage;

      if (leafShortName && isHierarchyParameter) {
        const products: HierarchyItem[] = childNodes.results.map((node) => {
          const {
            code,
            depth,
            shortName,
            name,
            parent,
            transactionSourceAccess,
          } = node;

          return {
            code,
            depth,
            parent,
            isLeaf: shortName === leafShortName,
            name,
            ordinal: 0,
            shortName,
            transactionSourceAccess,
            type: HierarchyItemType.Hierarchy,
          };
        });

        hierarchyState.data.items = [...products];
        hierarchyState.expandedRows = [];
      }
    },

    onBulkHierarchyChildNodesReceived: (
      state: WizardState,
      {
        payload,
      }: PayloadAction<
        Array<{
          childNodes: HierarchyServiceResponseDto;
          parameterType: ParameterId;
        }>
      >
    ) => {
      const localState = state.parameters[
        payload[0].parameterType
      ] as HierarchyState;
      const leafShortName = localState.data.leafShortName;
      for (const item of payload) {
        const itemLookup = createItemLookup(localState.data.items);
        if (
          item.childNodes.count > 0 &&
          leafShortName !== "" &&
          (item.parameterType === ParameterId.ProductHierarchy ||
            item.parameterType === ParameterId.LocationHierarchy)
        ) {
          const groupedByParent = {} as {
            [parentKey: string]: HierarchyItemDto[] | undefined;
          };
          for (const node of item.childNodes.results) {
            const parentKey = `${node.parent.shortName}:${node.parent.code}`;
            let group = groupedByParent[parentKey];
            if (!group) {
              group = [];
              groupedByParent[parentKey] = group;
            }

            group.push(node);
          }

          for (const group of Object.values(groupedByParent)) {
            if (group) {
              const subRows = group.map<LocationHierarchyParameterItem>(
                (node) => ({
                  code: node.code,
                  depth: node.depth,
                  isLeaf: node.shortName === leafShortName,
                  name: node.name,
                  ordinal: 0,
                  parent: node.parent,
                  shortName: node.shortName,
                  transactionSourceAccess: node.transactionSourceAccess,
                  type: HierarchyItemType.Hierarchy,
                })
              );

              if (item.childNodes.hasNextPage) {
                subRows[subRows.length - 1].isMoreRow = true;
              }

              const parentCode = group[0].parent.code;
              const parentShortName = group[0].parent.shortName;

              overwriteChildrenInPosition(
                itemLookup,
                subRows,
                parentCode,
                parentShortName
              );

              toggleNodeExpanding(itemLookup, parentCode, parentShortName);
            }
          }
        }
      }
    },

    onHierarchyMetadataReceived: (
      state: WizardState,
      {
        payload: { parameterType, hierarchyMetadata },
      }: PayloadAction<{
        hierarchyMetadata: HierarchyMetadataResponseDto[];
        parameterType: ParameterId;
      }>
    ) => {
      (
        state.parameters[parameterType] as HierarchyState
      ).data.hierarchyMetadata = hierarchyMetadata;

      // Early exit for associated products parameter this is because we want
      // leaf name to be set from configuration rather than hierarchy metadata
      if (parameterType === ParameterId.AssociatedProducts) {
        return;
      }

      const hierarchyType = (state.parameters[parameterType] as HierarchyState)
        .data.type;
      const leaf = hierarchyMetadata.find(
        (attribute) =>
          attribute.isLeaf &&
          attribute.structureName?.toLowerCase() === hierarchyType.toLowerCase()
      );

      (state.parameters[parameterType] as HierarchyState).data.leafShortName =
        leaf?.shortName ?? "";

      updateReportTransactionSources(state);
    },

    onHierarchyNodeSelection: (
      state: WizardState,
      {
        payload: { parameterType, nodeSelection, lockSelection },
      }: PayloadAction<{
        lockSelection?: Boolean;
        nodeSelection: HierarchyItem[];
        parameterType: ParameterId;
      }>
    ) => {
      const localState = state.parameters[parameterType] as HierarchyState;
      if (HIERARCHY_PARAMETERS.includes(parameterType)) {
        const selectedRows = nodeSelection;
        if (lockSelection) {
          if (nodeSelection.length === 0) {
            localState.hierarchyLevelLock = undefined;
          } else {
            let minSelectedDepth: number | undefined;
            let maxSelectedDepth: number | undefined;
            for (const row of nodeSelection) {
              if (
                minSelectedDepth === undefined ||
                minSelectedDepth > row.depth
              ) {
                minSelectedDepth = row.depth;
              }

              if (
                maxSelectedDepth === undefined ||
                maxSelectedDepth < row.depth
              ) {
                maxSelectedDepth = row.depth;
              }
            }

            if (
              minSelectedDepth !== undefined &&
              maxSelectedDepth !== undefined
            )
              localState.hierarchyLevelLock = {
                min: minSelectedDepth,
                max: maxSelectedDepth,
              };
          }
        }

        state.parameters[parameterType] = handleHierarchyNodeSelections(
          state,
          state.parameters[parameterType],
          selectedRows
        );

        updateRerunHierarchySelections(state, parameterType);
        updateReportTransactionSources(state);
        state.parameterGroups = UpdateParameterGroup(parameterType, state);
        resetServerErrorOnParameterChange(parameterType, state);

        reportParameterSlice.caseReducers.updateStructureParameter(state, {
          payload: {
            parameter: parameterType,
            selectedRows,
          },
          type: "updateStructureParameter",
        });

        if (parameterType === ParameterId.ProductHierarchy) {
          verifyProductStructure(state);
          state.firstProductSelectionMade = true;

          if (!state.isRerun) {
            reportParameterSlice.caseReducers.resetLevelOfAnalysis(state);
          }
        }

        if (parameterType === ParameterId.FocalProducts) {
          reportParameterSlice.caseReducers.resetLevelSelection(state, {
            payload: ParameterId.FocalLevel,
            type: "resetLevelSelection",
          });
        }

        if (parameterType === ParameterId.AssociatedProducts) {
          reportParameterSlice.caseReducers.resetLevelSelection(state, {
            payload: ParameterId.AssociatedLevel,
            type: "resetLevelSelection",
          });
        }
      }
    },

    onAddHierarchyNodeSelection: (
      state: WizardState,
      {
        payload: { parameterType, nodeSelection, lockSelection },
      }: PayloadAction<{
        lockSelection?: boolean;
        nodeSelection: HierarchyItem[];
        parameterType: ParameterId;
      }>
    ) => {
      const localState = state.parameters[parameterType] as HierarchyState;
      const isHierarchyParameter = HIERARCHY_PARAMETERS.includes(parameterType);
      if (isHierarchyParameter) {
        const selectedRows = [...nodeSelection, ...localState.selectedRows];

        if (lockSelection) {
          let minSelectedDepth: number | undefined;
          let maxSelectedDepth: number | undefined;
          for (const row of selectedRows) {
            if (
              minSelectedDepth === undefined ||
              minSelectedDepth > row.depth
            ) {
              minSelectedDepth = row.depth;
            }

            if (
              maxSelectedDepth === undefined ||
              maxSelectedDepth < row.depth
            ) {
              maxSelectedDepth = row.depth;
            }
          }

          if (minSelectedDepth !== undefined && maxSelectedDepth !== undefined)
            localState.hierarchyLevelLock = {
              min: minSelectedDepth,
              max: maxSelectedDepth,
            };
        }

        state.parameters[parameterType] = handleHierarchyNodeSelections(
          state,
          state.parameters[parameterType],
          selectedRows
        );

        updateReportTransactionSources(state);
        state.parameterGroups = UpdateParameterGroup(parameterType, state);
        resetServerErrorOnParameterChange(parameterType, state);

        reportParameterSlice.caseReducers.updateStructureParameter(state, {
          payload: {
            parameter: parameterType,
            selectedRows,
          },
          type: "updateStructureParameter",
        });

        if (parameterType === ParameterId.ProductHierarchy) {
          verifyProductStructure(state);
          state.firstProductSelectionMade = true;
          reportParameterSlice.caseReducers.resetLevelOfAnalysis(state);
        }

        if (parameterType === ParameterId.FocalProducts) {
          reportParameterSlice.caseReducers.resetLevelSelection(state, {
            payload: ParameterId.FocalLevel,
            type: "resetLevelSelection",
          });
        }

        if (parameterType === ParameterId.AssociatedProducts) {
          reportParameterSlice.caseReducers.resetLevelSelection(state, {
            payload: ParameterId.AssociatedLevel,
            type: "resetLevelSelection",
          });
        }
      }
    },

    onRemoveHierarchyNodeSelection: (
      state: WizardState,
      {
        payload: { parameterType, nodeSelection, lockSelection },
      }: PayloadAction<{
        lockSelection?: boolean;
        nodeSelection: HierarchyItem[];
        parameterType: ParameterId;
      }>
    ) => {
      const localState = state.parameters[parameterType] as HierarchyState;
      const isHierarchyParameter = HIERARCHY_PARAMETERS.includes(parameterType);
      if (isHierarchyParameter) {
        const selectedRows: HierarchyItem[] = [];
        const nodeSelectionLookup: Record<
          string,
          Record<string, boolean> | undefined
        > = {};
        for (const node of nodeSelection) {
          let nodeByCode = nodeSelectionLookup[node.shortName];
          if (!nodeByCode) {
            nodeByCode = {};
            nodeSelectionLookup[node.shortName] = nodeByCode;
          }

          nodeByCode[node.code] = true;
        }

        let minSelectedDepth: number | undefined;
        let maxSelectedDepth: number | undefined;
        for (const row of localState.selectedRows) {
          const nodesByCode = nodeSelectionLookup[row.shortName];
          if (!nodesByCode?.[row.code]) {
            selectedRows.push(row);
            if (
              minSelectedDepth === undefined ||
              minSelectedDepth > row.depth
            ) {
              minSelectedDepth = row.depth;
            }

            if (
              maxSelectedDepth === undefined ||
              maxSelectedDepth < row.depth
            ) {
              maxSelectedDepth = row.depth;
            }
          }
        }

        if (lockSelection) {
          if (
            selectedRows.length === 0 ||
            minSelectedDepth === undefined ||
            maxSelectedDepth === undefined
          ) {
            localState.hierarchyLevelLock = undefined;
          } else {
            localState.hierarchyLevelLock = {
              min: minSelectedDepth,
              max: maxSelectedDepth,
            };
          }
        }

        state.parameters[parameterType] = handleHierarchyNodeSelections(
          state,
          state.parameters[parameterType],
          selectedRows
        );

        updateReportTransactionSources(state);
        state.parameterGroups = UpdateParameterGroup(parameterType, state);
        resetServerErrorOnParameterChange(parameterType, state);

        reportParameterSlice.caseReducers.updateStructureParameter(state, {
          payload: {
            parameter: parameterType,
            selectedRows,
          },
          type: "updateStructureParameter",
        });

        if (parameterType === ParameterId.ProductHierarchy) {
          verifyProductStructure(state);
          reportParameterSlice.caseReducers.resetLevelOfAnalysis(state);
        }

        if (parameterType === ParameterId.FocalProducts) {
          reportParameterSlice.caseReducers.resetLevelSelection(state, {
            payload: ParameterId.FocalLevel,
            type: "resetLevelSelection",
          });
        }

        if (parameterType === ParameterId.AssociatedProducts) {
          reportParameterSlice.caseReducers.resetLevelSelection(state, {
            payload: ParameterId.AssociatedLevel,
            type: "resetLevelSelection",
          });
        }
      }
    },

    onHierarchyRootNodeReceived: (
      state: WizardState,
      {
        payload: { parameterType, response },
      }: PayloadAction<{
        parameterType: ParameterId;
        response: HierarchyServiceResponseDto;
      }>
    ) => {
      const hierarchyState = state.parameters[parameterType] as HierarchyState;
      const isHierarchyParameter = HIERARCHY_PARAMETERS.includes(parameterType);
      if (response.results.length > 0 && isHierarchyParameter) {
        const leafShortName = (
          state.parameters[parameterType] as HierarchyState
        ).data.leafShortName;
        const rootHierarchyItems: HierarchyItem[] = response.results.map(
          (item) => ({
            code: item.code,
            depth: item.depth,
            isLeaf: item.shortName === leafShortName,
            name: item.name,
            ordinal: 0,
            parent: item.parent,
            shortName: item.shortName,
            transactionSourceAccess: item.transactionSourceAccess,
            type:
              item.shortName === leafShortName
                ? HierarchyItemType.Leaf
                : HierarchyItemType.Hierarchy,
          })
        );

        hierarchyState.data.items = [...rootHierarchyItems];
        hierarchyState.data.rootDepth = Math.min(
          ...rootHierarchyItems.map((node) => node.depth)
        );
        hierarchyState.expandedRows =
          rootHierarchyItems.length === 1 && !rootHierarchyItems[0].isLeaf
            ? [rootHierarchyItems[0]]
            : [];
        hierarchyState.expandedRows =
          rootHierarchyItems.length === 1 && !rootHierarchyItems[0].isLeaf
            ? [rootHierarchyItems[0]]
            : [];

        if (
          hierarchyState.searchString === "" &&
          !hierarchyState.isAdvancedSearchEnabled
        ) {
          reportParameterSlice.caseReducers.copyHierarchyTableDataToUnfilteredDataStore(
            state,
            {
              payload: parameterType,
              type: "copyHierarchyTableDataToUnfilteredDataStore",
            }
          );
        }
      }
    },

    bandsUpdated: (
      state: WizardState,
      {
        payload: { parameterId, bands },
      }: PayloadAction<{
        bands: BandSelection[];
        parameterId: string;
      }>
    ) => {
      state.parameters[parameterId] = handleBandsParameterSelections(
        state.parameters[parameterId],
        bands
      );

      state.parameterGroups = UpdateParameterGroup(parameterId, state);
      resetServerErrorOnParameterChange(parameterId, state);
    },

    bufferUpdated: (
      state: WizardState,
      {
        payload: { parameterId, buffer },
      }: PayloadAction<{
        buffer: number;
        parameterId: string;
      }>
    ) => {
      state.parameters[parameterId] = handleBufferParameterSelection(
        state.parameters[parameterId],
        buffer
      );

      state.parameterGroups = UpdateParameterGroup(parameterId, state);
    },

    measureThresholdAdded: (
      state: WizardState,
      {
        payload: { parameterId, newSelection },
      }: PayloadAction<{
        newSelection: MeasureThresholdSelection;
        parameterId: string;
      }>
    ) => {
      state.parameters[parameterId] = handleMeasureThresholdAdded(
        state.parameters[parameterId],
        newSelection
      );

      state.parameterGroups = UpdateParameterGroup(parameterId, state);
    },

    measureThresholdRemoved: (
      state: WizardState,
      {
        payload: { parameterId, index },
      }: PayloadAction<{
        index: number;
        parameterId: string;
      }>
    ) => {
      state.parameters[parameterId] = handleMeasureThresholdRemoved(
        state.parameters[parameterId],
        index
      );

      state.parameterGroups = UpdateParameterGroup(parameterId, state);
    },

    measureThresholdUpdated: (
      state: WizardState,
      {
        payload: { parameterId, newSelection, index },
      }: PayloadAction<{
        index: number;
        newSelection: MeasureThresholdSelection;
        parameterId: string;
      }>
    ) => {
      state.parameters[parameterId] = handleMeasureThresholdUpdated(
        state.parameters[parameterId],
        newSelection,
        index
      );

      state.parameterGroups = UpdateParameterGroup(parameterId, state);
    },

    resetLevelOfAnalysis: (state: WizardState) => {
      if (hasLevelOfAnalysisTab(state.parameters)) {
        state.parameters[ParameterId.LevelOfAnalysis] =
          handleLevelOfAnalysisReset(
            state.parameters[ParameterId.LevelOfAnalysis]
          );

        state.parameterGroups = UpdateParameterGroup(
          ParameterId.LevelOfAnalysis,
          state
        );
        updateReportTransactionSources(state);
        resetServerErrorOnParameterChange(ParameterId.LevelOfAnalysis, state);
      }
    },

    resetLevelSelection: (
      state: WizardState,
      { payload: parameter }: PayloadAction<string>
    ) => {
      state.parameters[parameter] = handleLevelOfAnalysisReset(
        state.parameters[parameter]
      );

      state.parameterGroups = UpdateParameterGroup(parameter, state);

      resetServerErrorOnParameterChange(parameter, state);
    },

    rollingPeriodSelected: (
      state: WizardState,
      {
        payload: { parameter, rollingPeriodValue },
      }: PayloadAction<{
        parameter: string;
        rollingPeriodValue: string;
      }>
    ) => {
      state.parameters[parameter] = handleRollingPeriodSelection(
        state.parameters[parameter],
        rollingPeriodValue
      );

      state.parameterGroups = UpdateParameterGroup(parameter, state);
      resetServerErrorOnParameterChange(parameter, state);
    },

    setHierarchyNodeExpanding: (
      state: WizardState,
      {
        payload: { nodeCode, nodeShortName, parameterType },
      }: PayloadAction<{
        nodeCode: string;
        nodeShortName: string;
        parameterType: ParameterId;
      }>
    ) => {
      const itemLookup = createItemLookup(
        (state.parameters[parameterType] as HierarchyState).data.items
      );
      toggleNodeExpanding(itemLookup, nodeCode, nodeShortName);
    },

    showDataEntitlements: (state: WizardState) => {
      state.isDataEntitlementsShown = true;
    },

    startDateSelected: (
      state: WizardState,
      {
        payload: { parameter, startDate },
      }: PayloadAction<{
        parameter: string;
        startDate: string;
      }>
    ) => {
      state.parameters[parameter] = handleStartDateSelection(
        state.parameters[parameter],
        startDate,
        parameter
      );
      state.parameterGroups = UpdateParameterGroup(parameter, state);
      resetServerErrorOnParameterChange(parameter, state);

      if (parameter === ParameterId.FocusPeriod) {
        state.parameters = handleComparisonPeriodSelection(state.parameters);
        state.parameterGroups = UpdateParameterGroup(
          ParameterId.ComparisonPeriod,
          state
        );

        if (Object.keys(state.parameters).includes(ParameterId.LeadPeriod)) {
          state.parameters[ParameterId.LeadPeriod] =
            handleResetLeadPeriodSelection(
              state.parameters[ParameterId.LeadPeriod],
              startDate
            );

          state.parameterGroups = UpdateParameterGroup(
            ParameterId.LeadPeriod,
            state
          );
        }
      }

      state.parameters = validateRollingPeriodSelection(state.parameters);
      state.parameterGroups = UpdateParameterGroup(
        ParameterId.RollingPeriod,
        state
      );
    },
    metricSelected: (
      state: WizardState,
      action: PayloadAction<{
        metric: ParameterOptionDto;
        parameterId: string;
        selected: boolean;
      }>
    ) => {
      const { parameterId, metric, selected } = action.payload;

      if (!isMetricsState(state.parameters[parameterId])) {
        return;
      }

      const metricsState = state.parameters[parameterId] as MetricsState;
      const metricGroup = metricsState.data.items.find((group) =>
        group.metricList.some(
          (groupMetric) => groupMetric.value === metric.value
        )
      );

      const metricWithGroup: MetricsOptionDto = {
        value: metric.value,
        label: metric.label,
        description: metric.description,
        groupName: metricGroup?.value ?? "",
        metricList: [],
        isDefault: metric.isDefault,
        isDisabled: metric.isDisabled,
        isAggregate: metric.isAggregate,
        options: metric.options ?? [],
      };

      state.parameters[parameterId] = handleMetricSelection(
        metricsState,
        metricWithGroup,
        selected
      );

      state.parameterGroups = UpdateParameterGroup(parameterId, state);
    },

    metricsBulkSelected: (
      state: WizardState,
      action: PayloadAction<{
        metrics: ParameterOptionDto[];
        parameterId: string;
      }>
    ) => {
      const { parameterId, metrics } = action.payload;

      if (!isMetricsState(state.parameters[parameterId])) {
        return;
      }

      const metricsState = state.parameters[parameterId] as MetricsState;

      const metricsWithGroups: MetricsOptionDto[] = metrics.map(
        (metricItem) => {
          const metricGroup = metricsState.data.items.find((group) =>
            group.metricList.some((metric) => metric.value === metricItem.value)
          );

          return {
            value: metricItem.value,
            label: metricItem.label,
            description: metricItem.description,
            groupName: metricGroup?.value ?? "",
            metricList: [],
            isDefault: metricItem.isDefault,
            isDisabled: metricItem.isDisabled,
            isAggregate: metricItem.isAggregate,
            options: metricItem.options ?? [],
          };
        }
      );

      state.parameters[parameterId] = handleMetricsSelections(
        metricsState,
        metricsWithGroups
      );

      state.parameterGroups = UpdateParameterGroup(parameterId, state);
    },

    metricGroupExpanded: (
      state: WizardState,
      action: PayloadAction<{
        groupId: string;
        parameterId: string;
      }>
    ) => {
      const { parameterId, groupId } = action.payload;

      if (!isMetricsState(state.parameters[parameterId])) {
        return;
      }

      const metricsState = state.parameters[parameterId] as MetricsState;

      state.parameters[parameterId] = handleGroupExpansion(
        metricsState,
        groupId
      );
    },

    metricsSearchChanged: (
      state: WizardState,
      action: PayloadAction<{
        parameterId: string;
        searchString: string;
      }>
    ) => {
      const { parameterId, searchString } = action.payload;

      if (!isMetricsState(state.parameters[parameterId])) {
        return;
      }

      state.parameters[parameterId] = handleMetricsSearch(
        state.parameters[parameterId] as MetricsState,
        searchString
      );
    },

    toggleShowSelectedMetrics: (
      state: WizardState,
      action: PayloadAction<{
        parameterType: ParameterId;
      }>
    ) => {
      const { parameterType } = action.payload;

      if (!isMetricsState(state.parameters[parameterType])) {
        return;
      }

      const metricsState = state.parameters[parameterType] as MetricsState;
      metricsState.isSelectedItemsShown = !metricsState.isSelectedItemsShown;
      if (metricsState.isSelectedItemsShown) {
        metricsState.data.displayedItems = metricsState.selectedMetrics;
      } else if (metricsState.searchString) {
        const searchTerm = metricsState.searchString.toLowerCase().trim();
        metricsState.data.displayedItems = metricsState.data.items.flatMap(
          (group) =>
            group.metricList.filter((metric) =>
              metric.label.toLowerCase().includes(searchTerm)
            )
        );
      } else {
        metricsState.data.displayedItems = metricsState.data.items;
      }
    },
    structureItemSelected: (
      state: WizardState,
      {
        payload: { parameter, activeItem },
      }: PayloadAction<{
        activeItem: Item | undefined;
        parameter: string;
      }>
    ) => {
      state.parameters[parameter] = handleStructureItemSelection(
        state.parameters[parameter],
        activeItem
      );

      updateReportTransactionSources(state);
      state.parameterGroups = UpdateParameterGroup(parameter, state);
      resetServerErrorOnParameterChange(parameter, state);
    },

    structureItemsSelected: (
      state: WizardState,
      {
        payload: { parameter, selectedItems },
      }: PayloadAction<{
        parameter: string;
        selectedItems: Item[];
      }>
    ) => {
      state.parameters[parameter] = handleItemsSelection(
        state.parameters[parameter],
        selectedItems
      );

      updateReportTransactionSources(state);
      state.parameterGroups = UpdateParameterGroup(parameter, state);
      resetServerErrorOnParameterChange(parameter, state);
    },

    updateStructureParameter: (
      state: WizardState,
      {
        payload: { parameter, selectedRows },
      }: PayloadAction<{
        parameter: ParameterId;
        selectedRows: HierarchyItem[];
      }>
    ) => {
      const structureParameterId = getStructureParameterId(
        state.parameters[parameter].parameterConfig.hierarchyType
      );

      const isStructureExist =
        structureParameterId &&
        Object.keys(state.parameters).includes(structureParameterId);

      if (isStructureExist) {
        const structure = getHierarchicalStructureZones(
          selectedRows,
          state.parameters[structureParameterId].parameterConfig,
          state.isDataEntitlementsShown,
          state.transactionSources
        );

        const leafShortName = (state.parameters[parameter] as HierarchyState)
          .data.leafShortName;
        state.parameters[structureParameterId] = handleDroppableZonesSelection(
          state.parameters[structureParameterId],
          structure,
          leafShortName,
          selectedRows,
          state.isDataEntitlementsShown,
          state.transactionSources,
          state.isRerun
        );

        updateReportTransactionSources(state);
        state.parameterGroups = UpdateParameterGroup(
          structureParameterId,
          state
        );
        resetServerErrorOnParameterChange(parameter, state);
      }
    },
    setIsProductHierarchyRunOnLowestLevel: (
      state: WizardState,
      { payload: isProductHierarchyRunOnLowestLevel }: PayloadAction<boolean>
    ) => {
      state.isProductHierarchyRunOnLowestLevel =
        isProductHierarchyRunOnLowestLevel;
    },
    setRerunConfig: (
      state: WizardState,
      {
        payload: { parameterConfig },
      }: PayloadAction<{
        parameterConfig: ParameterConfigurationsDto;
      }>
    ) => {
      reportParameterSlice.caseReducers.resetParametersState();
      state.isRerun = true;
      reportParameterSlice.caseReducers.onConfigReceived(state, {
        payload: { parameterConfig },
        type: "initializeRerun",
      });
    },
    onSavedParametersReceived: (
      state: WizardState,
      {
        payload: { savedParameters },
      }: PayloadAction<{ savedParameters: SavedParametersDetailsDto }>
    ) => {
      state.savedParameters = savedParameters;
    },

    resetParametersState: () => initialState,
  },
});

export const {
  comparisonPeriodSelected,
  onConfigReceived,
  endDateSelected,
  focusWeeksSelected,
  focusPeriodSelected,
  leadWeeksSelected,
  leadPeriodSelected,
  onHierarchyMetadataReceived,
  onHierarchyRootNodeReceived,
  onHierarchyChildNodesReceived,
  onBulkHierarchyChildNodesReceived,
  onHierarchyNodeSelection,
  onAddHierarchyNodeSelection,
  onRemoveHierarchyNodeSelection,
  setHierarchyNodeExpanding,
  hierarchyNodeExpanded,
  hierarchyNodeSelected,
  groupSelected,
  groupValidated,
  groupFolderExpanded,
  levelOfAnalysisOptionSelected,
  levelOfAnalysisSingleOptionSelected,
  levelOfAnalysisOptionsUpdated,
  listOptionSelected,
  dropdownOptionSelected,
  segmentationOptionSelected,
  segmentFilterOptionSelected,
  rollingPeriodSelected,
  timePeriodDataTypeSelected,
  structureItemSelected,
  startDateSelected,
  showDataEntitlements,
  structureItemsSelected,
  droppableZonesSelected,
  updateStructureParameter,
  resetLevelOfAnalysis,
  resetLevelSelection,
  resetParametersState,
  onHierarchySearchResultsReceived,
  onProductSearchResultsReceived,
  onShowAdvancedSearch,
  copyHierarchyTableDataToUnfilteredDataStore,
  copyUnfilteredDataStoreToHierarchyTableData,
  onSearchChange,
  resetSearch,
  onFilterRulesUpdate,
  toggleShowSelectedItems,
  bandsUpdated,
  bufferUpdated,
  measureThresholdAdded,
  measureThresholdRemoved,
  measureThresholdUpdated,
  setIsProductHierarchyRunOnLowestLevel,
  setRerunConfig,
  onSavedParametersReceived,
  onServerError,
  metricSelected,
  metricGroupExpanded,
  metricsSearchChanged,
  toggleShowSelectedMetrics,
  metricsBulkSelected,
  updateParameterWarningState,
} = reportParameterSlice.actions;

export default reportParameterSlice.reducer;

export const selectIsDataEntitlementsShown = (state: RootState): boolean =>
  state.reportParameter.isDataEntitlementsShown;

export const selectTransactionSources = (
  state: RootState
): TransactionSourceState[] => state.reportParameter.transactionSources;

export const selectLeafShortName = (
  parameterHierarchyType: ParameterId,
  state: RootState
): string =>
  (state.reportParameter.parameters[parameterHierarchyType] as HierarchyState)
    .data.leafShortName;

export const selectHierarchyItems = (
  parameterHierarchyType: ParameterId,
  state: RootState
): HierarchyGridItem[] =>
  (state.reportParameter.parameters[parameterHierarchyType] as HierarchyState)
    .data.items;

export const selectHierarchyMetadata = (
  parameterHierarchyType: ParameterId,
  state: RootState
): HierarchyMetadataResponseDto[] =>
  (state.reportParameter.parameters[parameterHierarchyType] as HierarchyState)
    .data.hierarchyMetadata;

export const selectAdvancedSearchEnableState = (
  parameterHierarchyType: ParameterId,
  state: RootState
): boolean =>
  (state.reportParameter.parameters[parameterHierarchyType] as HierarchyState)
    .isAdvancedSearchEnabled;

export const selectHierarchyLevelLock = (
  parameterHierarchyType: ParameterId,
  state: RootState
) =>
  (state.reportParameter.parameters[parameterHierarchyType] as HierarchyState)
    .hierarchyLevelLock;

export const selectHierarchySelectedRows = (
  parameterHierarchyType: ParameterId,
  state: RootState
): LocationHierarchyParameterItem[] =>
  (state.reportParameter.parameters[parameterHierarchyType] as HierarchyState)
    .selectedRows;

export const selectHierarchySelectedItemsFiltered = (
  parameterHierarchyType: ParameterId,
  state: RootState
): HierarchyItem[] =>
  (state.reportParameter.parameters[parameterHierarchyType] as HierarchyState)
    .selectedItemsFiltered;

export const selectHierarchyExpandedRows = (
  parameterHierarchyType: ParameterId,
  state: RootState
): HierarchyItem[] =>
  (state.reportParameter.parameters[parameterHierarchyType] as HierarchyState)
    .expandedRows;

export const selectHierarchyDisabledLevelShortNames = (
  parameterHierarchyType: ParameterId,
  state: RootState
): string[] =>
  (state.reportParameter.parameters[parameterHierarchyType] as HierarchyState)
    .data.disabledLevelShortNames;

export const selectHierarchySupportedLevelShortNames = (
  parameterHierarchyType: ParameterId,
  state: RootState
): string[] =>
  (state.reportParameter.parameters[parameterHierarchyType] as HierarchyState)
    .data.supportedLevelShortNames;

export const selectUniverseRestrictedLevelShortName = (
  parameterHierarchyType: ParameterId,
  state: RootState
): string | undefined =>
  (state.reportParameter.parameters[parameterHierarchyType] as HierarchyState)
    .data.universeRestrictedHierarchyLevel;

export const getGroupSelectionsSelector =
  (parameterHierarchyType: ParameterId) =>
  (state: RootState): HierarchyGroupDto[] =>
    (
      state.reportParameter.parameters[
        parameterHierarchyType
      ] as GroupParameterState
    ).confirmedSelections;

export const getPendingGroupSelectionsSelector =
  (parameterHierarchyType: ParameterId) =>
  (state: RootState): HierarchyGroupDto[] =>
    (
      state.reportParameter.parameters[
        parameterHierarchyType
      ] as GroupParameterState
    ).pendingSelections;

export const selectGroupExpandedIds =
  (parameterHierarchyType: ParameterId) =>
  (state: RootState): string[] | undefined =>
    (
      state.reportParameter.parameters[
        parameterHierarchyType
      ] as GroupParameterState
    ).expandedIds;

export const selectMaxGroupSelectionsReached = (
  parameterHierarchyType: string,
  state: RootState
): boolean =>
  state.reportParameter.parameters[parameterHierarchyType]
    .maxGroupSelectionsReached ?? false;

export const selectTriggerSearchState = (
  parameterHierarchyType: ParameterId,
  state: RootState
): boolean =>
  (state.reportParameter.parameters[parameterHierarchyType] as HierarchyState)
    .triggerSearch;

export const selectSearchString = (
  parameterHierarchyType: ParameterId,
  state: RootState
): string =>
  (state.reportParameter.parameters[parameterHierarchyType] as HierarchyState)
    .searchString;

export const selectIsSelectedItemsShown = (
  parameterHierarchyType: ParameterId,
  state: RootState
): boolean =>
  (state.reportParameter.parameters[parameterHierarchyType] as HierarchyState)
    .isSelectedItemsShown;

export const selectFilterRules = (
  parameterHierarchyType: ParameterId,
  state: RootState
): HierarchyGroupRuleWithIdAndName[] =>
  (state.reportParameter.parameters[parameterHierarchyType] as HierarchyState)
    .filterRules;

export const selectSearchHasNextPage = (
  parameterHierarchyType: ParameterId,
  state: RootState
): boolean =>
  (state.reportParameter.parameters[parameterHierarchyType] as HierarchyState)
    .searchHasNextPage;

export const selectFocalAttributes = (
  parameterHierarchyType: ParameterId,
  state: RootState
): string[] =>
  (state.reportParameter.parameters[parameterHierarchyType] as HierarchyState)
    .focalAttributes;

export const selectHierarchyRootDepth = (
  parameterHierarchyType: ParameterId,
  state: RootState
): number =>
  (state.reportParameter.parameters[parameterHierarchyType] as HierarchyState)
    .data.rootDepth;

export const selectAttributesFilterConfigs = (
  parameterHierarchyType: ParameterId,
  state: RootState
): AttributesFilterConfig[] | undefined =>
  (state.reportParameter.parameters[parameterHierarchyType] as HierarchyState)
    .parameterConfig.attributesFilterConfigs;

export const getselections = (
  parameterHierarchyType: ParameterId,
  state: RootState
): ParameterState =>
  state.reportParameter.parameters[parameterHierarchyType] as HierarchyState;

export const selectMetricsFilteredItems = (
  parameterType: ParameterId,
  state: RootState
): MetricsOptionDto[] => {
  if (!isMetricsState(state.reportParameter.parameters[parameterType])) {
    return [];
  }

  return (state.reportParameter.parameters[parameterType] as MetricsState).data
    .filteredItems;
};

export const selectMetricsItems = (
  parameterType: ParameterId,
  state: RootState
): MetricsOptionDto[] => {
  if (!isMetricsState(state.reportParameter.parameters[parameterType])) {
    return [];
  }

  return (state.reportParameter.parameters[parameterType] as MetricsState).data
    .items;
};

export const selectMetricsSelected = (
  parameterType: ParameterId,
  state: RootState
): MetricsOptionDto[] => {
  if (!isMetricsState(state.reportParameter.parameters[parameterType])) {
    return [];
  }

  return (state.reportParameter.parameters[parameterType] as MetricsState)
    .selectedMetrics;
};

export const selectMetricsExpandedGroups = (
  parameterType: ParameterId,
  state: RootState
): string[] => {
  if (!isMetricsState(state.reportParameter.parameters[parameterType])) {
    return [];
  }

  return (state.reportParameter.parameters[parameterType] as MetricsState)
    .expandedGroups;
};

export const selectMetricsSearchString = (
  parameterType: ParameterId,
  state: RootState
): string => {
  if (!isMetricsState(state.reportParameter.parameters[parameterType])) {
    return "";
  }

  return (state.reportParameter.parameters[parameterType] as MetricsState)
    .searchString;
};

export const selectMetricsIsSelectedItemsShown = (
  parameterType: ParameterId,
  state: RootState
): boolean => {
  if (!isMetricsState(state.reportParameter.parameters[parameterType])) {
    return false;
  }

  return (state.reportParameter.parameters[parameterType] as MetricsState)
    .isSelectedItemsShown;
};

export const selectMetricsSelectedItemsFiltered = (
  parameterType: ParameterId,
  state: RootState
): MetricsOptionDto[] => {
  if (!isMetricsState(state.reportParameter.parameters[parameterType])) {
    return [];
  }

  const metricsState = state.reportParameter.parameters[
    parameterType
  ] as MetricsState;
  const searchString = metricsState.searchString.toLowerCase();
  const selectedMetrics = metricsState.selectedMetrics;

  // If selected items are shown, filter selected metrics
  if (metricsState.isSelectedItemsShown) {
    return selectedMetrics.filter(
      (metric) =>
        !searchString ||
        metric.label.toLowerCase().includes(searchString) ||
        metric.value.toLowerCase().includes(searchString)
    );
  }

  // Get all original groups that had selected metrics
  const groupsWithSelectedMetrics = metricsState.data.items.filter(
    (group) =>
      isMetricGroup(group) &&
      group.metricList.some((metric) =>
        selectedMetrics.some((selected) => selected.value === metric.value)
      )
  );

  // For each group, return it with its original structure but only with selected metrics
  return groupsWithSelectedMetrics.map((group) => ({
    ...group,
    metricList: group.metricList.filter(
      (metric) =>
        selectedMetrics.some((selected) => selected.value === metric.value) &&
        (!searchString ||
          metric.label.toLowerCase().includes(searchString) ||
          metric.value.toLowerCase().includes(searchString))
    ),
  }));
};

export const selectProductParameters = (state: RootState): string[] =>
  state.reportParameter.parameterGroups.Products?.parameters ?? [];

export const selectedTransactionSources = (
  state: RootState
): TransactionSource | undefined =>
  state.reportParameter.transactionSources.find((ts) => ts.isSelected)?.id;
