import {
  type HierarchyAttributeParameterOption,
  type ParameterDto,
  type SelectionDto,
  isListSelectionDto,
} from "@quantium-enterprise/common-ui";
import { createSelector } from "@reduxjs/toolkit";
import { type ParameterState } from "../../states/ParameterState";
import { type RootState } from "../../store";
import { isBetweenMinMax } from "../parameter-validation/ValidationState";

export type LevelOfAnalysisSelection = {
  index: number;
  isHierarchical: boolean;
  label: string;
  value: string;
};

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export interface LevelOfAnalysisState extends ParameterState {
  selections: LevelOfAnalysisSelection[];
}

export const isLevelOfAnalysisState = (
  state: ParameterState | undefined
): state is LevelOfAnalysisState =>
  state !== undefined &&
  Object.prototype.hasOwnProperty.call(state, "selections");

export const newLevelOfAnalysisState = (
  parameterConfig: ParameterDto,
  parameterGroup: string,
  savedSelections?: SelectionDto[]
): LevelOfAnalysisState => {
  const loaOptions =
    parameterConfig.options as HierarchyAttributeParameterOption[];
  const defaultValues = loaOptions
    .filter((option) => {
      if (savedSelections) {
        return savedSelections.find((savedSelection) => {
          const listSelection = isListSelectionDto(savedSelection)
            ? savedSelection.value
            : undefined;
          return listSelection === option.value;
        });
      }

      return option.isDefault;
    })
    .map((option) => {
      const selection = {
        index: parameterConfig.options.indexOf(option),
        isHierarchical: option.isHierarchical,
        label: option.label,
        value: option.value,
      } as LevelOfAnalysisSelection;

      return selection;
    });

  let selections: LevelOfAnalysisSelection[] = defaultValues;
  const prefilledSelections = parameterConfig.selections;
  if (prefilledSelections) {
    const updatedSelections = prefilledSelections.flatMap((item) => {
      if (isListSelectionDto(item)) {
        const loaOption = loaOptions.find((opt) => opt.value === item.value);
        if (loaOption) {
          return [
            {
              index: loaOptions.indexOf(loaOption),
              isHierarchical: loaOption.isHierarchical,
              label: loaOption.label,
              value: loaOption.value,
            } as LevelOfAnalysisSelection,
          ];
        }
      }

      return [];
    });
    selections = updatedSelections;
  }

  return {
    isValid: isBetweenMinMax(selections.length, parameterConfig),
    parameterConfig,
    parameterGroup,
    selections,
  };
};

export const getLevelOfAnalysisState = (parameterId: string) =>
  createSelector(
    (state: RootState) => state.reportParameter.parameters[parameterId],
    (state) => state as LevelOfAnalysisState | undefined
  );

export const handleLevelOfAnalysisSelection = (
  parameterState: ParameterState,
  value: string
): ParameterState => {
  if (isLevelOfAnalysisState(parameterState)) {
    const loaOptions = parameterState.parameterConfig
      .options as HierarchyAttributeParameterOption[];
    const valueOption = loaOptions.find((option) => option.value === value);

    if (valueOption) {
      const selection = {
        index: loaOptions.indexOf(valueOption),
        isHierarchical: valueOption.isHierarchical,
        label: valueOption.label,
        value: valueOption.value,
      } as LevelOfAnalysisSelection;

      const selections = parameterState.selections.some(
        (select) => select.value === value
      )
        ? parameterState.selections.filter((select) => select.value !== value)
        : [...parameterState.selections, selection];

      return {
        ...parameterState,
        isValid: isBetweenMinMax(
          selections.length,
          parameterState.parameterConfig
        ),
        isParameterMissing: false,
        selections,
      } as ParameterState;
    }
  }

  // Default is don't do anything to change the state
  return { ...parameterState };
};

export const handleLevelOfAnalysisSingleSelection = (
  parameterState: ParameterState,
  value: string
): ParameterState => {
  if (isLevelOfAnalysisState(parameterState)) {
    const loaOptions = parameterState.parameterConfig
      .options as HierarchyAttributeParameterOption[];
    const valueOption = loaOptions.find((option) => option.value === value);

    if (valueOption) {
      const selection = {
        index: loaOptions.indexOf(valueOption),
        isHierarchical: valueOption.isHierarchical,
        label: valueOption.label,
        value: valueOption.value,
      } as LevelOfAnalysisSelection;

      return {
        ...parameterState,
        isValid: isBetweenMinMax(1, parameterState.parameterConfig),
        selections: [selection],
      } as ParameterState;
    }
  }

  return { ...parameterState };
};

export const handleLevelOfAnalysisReset = (
  parameterState: ParameterState
): ParameterState => {
  if (isLevelOfAnalysisState(parameterState)) {
    return {
      ...parameterState,
      isValid: isBetweenMinMax(0, parameterState.parameterConfig),
      selections: [],
    } as ParameterState;
  }

  return { ...parameterState };
};

export const getHierarchicalSelections = (state: LevelOfAnalysisState) =>
  state.selections
    .filter((selection) => selection.isHierarchical)
    .map((selection) => selection.value);

export const checkLoaContainsAttribute = (state: LevelOfAnalysisState) => {
  const attributeIndex = state.selections.findIndex(
    (selection) => !selection.isHierarchical
  );
  return attributeIndex !== -1;
};
