import {
  Button,
  ButtonVariant,
  ButtonWidth,
  Icon,
  IconGlyph,
  Nav,
  NavButton,
  NavResponsive,
  NavSize,
  NavVariant,
  Text,
  Themes,
  Dialog,
  DialogHeight,
  DialogWidth,
  IconColour,
  IconSize,
} from "@qbit/react";
import {
  useEventTrackingServiceContext,
  TrackingComponent,
  TrackingEvent,
  GenericTrackingProperties,
  useGetUserQuery,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import classNames from "classnames/bind";
import React, {
  useRef,
  Children,
  cloneElement,
  useCallback,
  useMemo,
  type ReactElement,
  type PropsWithChildren,
  useState,
  useEffect,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { type RootState } from "../../store";
import { getIconComponent } from "./TabIcon";
import styles from "./Wizard.module.css";
import { nextTab, previousTab, setTabIndex, resetTitle } from "./wizard-slice";

const cx = classNames.bind(styles);

const eventTrackingComponentHierarchy = [
  TrackingComponent.Wizard,
  TrackingComponent.ParameterGroup,
];

type WizardCloseProps = {
  path: string;
  showCloseDialog?: boolean;
};

const WizardClose = ({ path, showCloseDialog = true }: WizardCloseProps) => {
  const [showDialog, setShowDialog] = useState(false);
  const buttonRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const handleCloseDialogExit = useCallback(() => {
    setShowDialog(false);
  }, []);

  const handleCloseButtonClick = useCallback(() => {
    setShowDialog(true);
  }, []);

  const handleLeavePage = useCallback(() => {
    dispatch(resetTitle());
  }, [dispatch]);

  useEffect(() => {
    if (showCloseDialog) {
      navigate(location.pathname);
    }

    const handlePopState = (event: PopStateEvent) => {
      event.preventDefault();
      if (showCloseDialog) {
        navigate(location.pathname);
        setShowDialog(true);
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [location.pathname, navigate, showCloseDialog]);

  return (
    <div className={styles.closeContainer}>
      <Dialog
        className={styles.dialog}
        data-testid="close-dialog"
        footer={
          <div className={styles.dialogFooterContent}>
            <Button
              onClick={handleCloseDialogExit}
              variant={ButtonVariant.Primary}
            >
              <Text>Stay on page</Text>
            </Button>
            <Link to={path}>
              <Button onClick={handleLeavePage} variant={ButtonVariant.Danger}>
                <Text>Leave page</Text>
              </Button>
            </Link>
          </div>
        }
        header={
          <div className={styles.closeHeader}>
            <h5>Are you sure you want to leave?</h5>
            <Button
              className={styles.close}
              onClick={handleCloseDialogExit}
              variant={ButtonVariant.Stealth}
            >
              <Icon glyph={IconGlyph.DeleteAndCloseClose} text="Close" />
            </Button>
          </div>
        }
        height={DialogHeight.XSmall}
        onClose={handleCloseDialogExit}
        show={showDialog}
        titleId="close-wizard-dialog"
        triggeredBy={buttonRef}
        width={DialogWidth.XXXSmall}
      >
        <div className={styles.closeModalP}>
          <p>Leaving the page will remove all your selections.</p>
          <p>This cannot be undone.</p>
        </div>
      </Dialog>
      {showCloseDialog ? (
        <Button
          className={styles.close}
          data-testid="close-button"
          onClick={handleCloseButtonClick}
          variant={ButtonVariant.Stealth}
        >
          <Icon glyph={IconGlyph.ArrowsChevronLeft} text="Back" />
        </Button>
      ) : (
        <Link to={path}>
          <Button
            className={styles.close}
            data-testid="close-button"
            variant={ButtonVariant.Stealth}
          >
            <Icon glyph={IconGlyph.ArrowsChevronLeft} text="Back" />
          </Button>
        </Link>
      )}
    </div>
  );
};

const Wizard = ({ children }: PropsWithChildren) => (
  <div className={styles.wizardContainer}>
    <div className={styles.wizard}>{children}</div>
  </div>
);

type WizardHeaderProps = {
  path: string;
  showCloseDialog?: boolean;
};

const WizardHeader = ({
  children,
  path,
  showCloseDialog = true,
}: PropsWithChildren<WizardHeaderProps>) => (
  <div className={styles.header}>
    <WizardClose path={path} showCloseDialog={showCloseDialog} />
    {children}
  </div>
);

export type WizardContentProps = {
  children?:
    | Array<ReactElement<typeof WizardContentItem>>
    | ReactElement<typeof WizardContentItem>;
};

const WizardContent = ({ children }: WizardContentProps) => {
  const eventTrackingService = useEventTrackingServiceContext();

  const tabLength = useSelector((state: RootState) => state.wizard.tabLength);
  const currentTab = useSelector((state: RootState) => state.wizard.currentTab);
  const isFirst = useSelector((state: RootState) => state.wizard.isFirst);
  const wizardTitle = useSelector((state: RootState) => state.wizard.title);
  const isLast = useSelector((state: RootState) => state.wizard.isLast);
  const [child, setChild] = useState<ReactElement<typeof WizardContentItem>>();
  const tabs = useSelector((state: RootState) => state.wizard.tabs);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!children || (Array.isArray(children) && children.length === 0)) {
      return;
    }

    if (
      Array.isArray(children) &&
      children.length > 0 &&
      children.length <= tabLength
    ) {
      setChild(children[currentTab]);
    }

    if (!Array.isArray(children)) {
      setChild(children);
    }
  }, [children, currentTab, tabLength]);

  const handleGoBackClick = useCallback(() => {
    dispatch(previousTab());
    eventTrackingService.trackEvent(
      eventTrackingComponentHierarchy,
      TrackingEvent.Navigated,
      new GenericTrackingProperties({
        wizardTitle,
        navigationMethod: "Back button",
        originParameterGroup: tabs[currentTab],
        destinationParameterGroup: tabs[currentTab - 1],
      })
    );
  }, [dispatch, currentTab, eventTrackingService, wizardTitle, tabs]);

  const handleContinueClick = useCallback(() => {
    dispatch(nextTab());
    eventTrackingService.trackEvent(
      eventTrackingComponentHierarchy,
      TrackingEvent.Navigated,
      new GenericTrackingProperties({
        wizardTitle,
        navigationMethod: "Continue button",
        originParameterGroup: tabs[currentTab],
        destinationParameterGroup: tabs[currentTab + 1],
      })
    );
  }, [dispatch, currentTab, eventTrackingService, wizardTitle, tabs]);

  return (
    <div className={styles.content}>
      {child}
      <div className={styles.contentButtons}>
        <Button
          className={cx({ hidden: isFirst })}
          data-testid="back-button"
          onClick={handleGoBackClick}
          variant={ButtonVariant.Stealth}
        >
          <Icon
            colour={IconColour.Notification}
            glyph={IconGlyph.ArrowsBack}
            text="Go back"
          />
          <Text className={styles.backButton}>{tabs[currentTab - 1]}</Text>
        </Button>
        <span className={styles.flexAuto} />
        <Button
          className={cx(styles.continueButton, { hidden: isLast })}
          data-testid="continue-button"
          disabled={isLast}
          onClick={handleContinueClick}
          variant={ButtonVariant.Secondary}
        >
          <Text>Continue</Text>
          <Icon glyph={IconGlyph.ArrowsNext} text="Continue" />
        </Button>
      </div>
    </div>
  );
};

type WizardContentItemProps = {};

const WizardContentItem = ({
  children,
}: PropsWithChildren<WizardContentItemProps>) => (
  <div className={styles.contentItem} data-testid="content-item">
    {children}
  </div>
);

export type WizardSidebarProps = {};

const WizardSidebar = ({ children }: PropsWithChildren<WizardSidebarProps>) => (
  <div className={styles.sidebar}>{children}</div>
);

export type WizardTitleProps = {
  icon: JSX.Element;
  subtitle: string;
  title: string;
};

const WizardTitle = ({ icon, title, subtitle }: WizardTitleProps) => (
  <div className={styles.titleContainer}>
    <div className={styles.title}>{title}</div>
    <span className={styles.titleIcon}>{icon}</span>
    <Text className={styles.subTitle}>{subtitle}</Text>
  </div>
);

export type WizardTabsProps = {
  children:
    | Array<ReactElement<typeof WizardTabItem>>
    | ReactElement<typeof WizardTabItem>;
  onTabChange?: (oldIndex: number, newIndex: number) => void;
};

const WizardTabs = ({
  children,
  onTabChange,
}: WizardTabsProps): React.ReactElement => {
  const eventTrackingService = useEventTrackingServiceContext();
  const dispatch = useDispatch();
  const { currentTab, tabs, wizardTitle } = useSelector((state: RootState) => ({
    currentTab: state.wizard.currentTab,
    tabs: state.wizard.tabs,
    wizardTitle: state.wizard.title,
  }));

  const handleTabClick = useCallback(
    (index: number) => {
      dispatch(setTabIndex(index));
      eventTrackingService.trackEvent(
        eventTrackingComponentHierarchy,
        TrackingEvent.Navigated,
        new GenericTrackingProperties({
          wizardTitle,
          navigationMethod: "Tabs",
          originParameterGroup: tabs[currentTab],
          destinationParameterGroup: tabs[index],
        })
      );
      if (onTabChange) {
        onTabChange(currentTab, index);
      }
    },
    [currentTab, dispatch, onTabChange, eventTrackingService, wizardTitle, tabs]
  );

  return (
    <div className={styles.tabs}>
      <Nav
        activeIndex={currentTab}
        responsive={NavResponsive.Nowrap}
        size={NavSize.Medium}
        variant={NavVariant.Tab}
      >
        {Children.map(
          children,
          (child, index) =>
            cloneElement(child, {
              onClick: () => {
                handleTabClick(index);
              },
              tabIndex: index,
            } as WizardTabItemProps) as ReactElement<never>
        )}
      </Nav>
    </div>
  );
};

export type WizardTabItemProps = {
  children: string;
  onClick?: () => void;
  tabIndex?: number;
};

const WizardTabItem = ({ children, onClick, tabIndex }: WizardTabItemProps) => {
  const currentTab = useSelector((state: RootState) => state.wizard.currentTab);
  const tabLength = useSelector((state: RootState) => state.wizard.tabLength);

  const [navTextClassName, setNavTextClassName] = useState("");
  const [chevronClassName, setChevronClassName] = useState("");

  const img = useMemo(() => {
    const iconClassName = cx({
      tabIcon: true,
      tabIconSelected: currentTab === tabIndex,
    });
    return cloneElement(getIconComponent(children), {
      className: iconClassName,
    });
  }, [children, currentTab, tabIndex]);

  useMemo(() => {
    setNavTextClassName(
      cx({
        tabText: true,
        tabTextHidden: currentTab !== tabIndex,
      })
    );
  }, [currentTab, setNavTextClassName, tabIndex]);

  useMemo(() => {
    setChevronClassName(
      cx({
        hidden: tabIndex === tabLength - 1,
        tabChevron: true,
      })
    );
  }, [tabIndex, tabLength]);

  return (
    <NavButton className={styles.tab} onClick={() => onClick?.()}>
      {img}
      <span className={navTextClassName}>{children}</span>
      <div className={chevronClassName}>
        <Icon
          glyph={IconGlyph.ArrowsChevronRight}
          size={IconSize.Medium}
          text="shows the flow of parameters selection"
        />
      </div>
    </NavButton>
  );
};

export type WizardResetButtonProps = {
  onReset: () => void;
};

const WizardResetButton = ({ onReset }: WizardResetButtonProps) => {
  const { currentTab, tabs, wizardTitle } = useSelector((state: RootState) => ({
    currentTab: state.wizard.currentTab,
    tabs: state.wizard.tabs,
    wizardTitle: state.wizard.title,
  }));
  const { name: divisionName } = useDivision();
  const { data: user } = useGetUserQuery();
  const location = useLocation();
  const eventTrackingService = useEventTrackingServiceContext();

  const [showDialog, setShowDialog] = useState(false);
  const buttonRef = useRef(null);
  const navigate = useNavigate();

  const handleResetDialogExit = () => {
    setShowDialog(false);
  };

  const handleResetButtonClick = () => {
    setShowDialog(true);
  };

  const handleResetConfirmClick = useCallback(() => {
    eventTrackingService.trackEvent(
      [TrackingComponent.Wizard],
      TrackingEvent.Reset,
      new GenericTrackingProperties({
        wizardTitle,
        currentParameterGroup: tabs[currentTab],
        division: divisionName,
        user: user?.isSupplier ? "Supplier" : "Retailer",
      })
    );
    onReset();
    // reload the page
    navigate(location.pathname, { replace: true, state: { reset: true } });
    setTimeout(() => navigate(0), 0);
  }, [
    eventTrackingService,
    wizardTitle,
    tabs,
    currentTab,
    divisionName,
    user?.isSupplier,
    onReset,
    navigate,
    location.pathname,
  ]);

  return (
    <div className={styles.resetButtonContainer}>
      <Dialog
        className={styles.dialog}
        data-testid="reset-dialog"
        footer={
          <div className={styles.dialogFooterContent}>
            <Button
              onClick={handleResetConfirmClick}
              variant={ButtonVariant.Primary}
            >
              <Text>Reset</Text>
            </Button>
            <Button
              onClick={handleResetDialogExit}
              variant={ButtonVariant.Secondary}
            >
              <Text>Cancel</Text>
            </Button>
          </div>
        }
        header={
          <div className={styles.closeHeader}>
            <h5>Reset</h5>
            <Button
              className={styles.close}
              onClick={handleResetDialogExit}
              ref={buttonRef}
              variant={ButtonVariant.Stealth}
            >
              <Icon glyph={IconGlyph.DeleteAndCloseClose} text="Close" />
            </Button>
          </div>
        }
        height={DialogHeight.XSmall}
        onClose={handleResetDialogExit}
        show={showDialog}
        titleId="close-wizard-dialog"
        triggeredBy={buttonRef}
        width={DialogWidth.XXXSmall}
      >
        <p className={styles.closeModalP}>
          Are you sure you want to reset all selections?
        </p>
      </Dialog>
      <Button
        className={styles.resetButton}
        data-qtheme={Themes.Coral}
        data-testid="reset-button"
        onClick={handleResetButtonClick}
        variant={ButtonVariant.Stealth}
        width={ButtonWidth.Fit}
      >
        <Icon
          className={styles.resetButtonIcon}
          glyph={IconGlyph.ArrowsReplay}
          text="Reset"
        />
        <Text className={styles.resetButtonText}>Reset</Text>
      </Button>
    </div>
  );
};

Wizard.Header = WizardHeader;
Wizard.Content = WizardContent;
Wizard.ContentItem = WizardContentItem;
Wizard.Sidebar = WizardSidebar;
Wizard.Title = WizardTitle;
Wizard.Tabs = WizardTabs;
Wizard.TabItem = WizardTabItem;
Wizard.ResetButton = WizardResetButton;

export { Wizard };
