import { getBaseQuery } from "@quantium-enterprise/common-ui";
import { createApi } from "@reduxjs/toolkit/query/react";
import {
  type BulkNodeExpansionRequestDto,
  type AttributesRequestDto,
  type RootNodesRequestDto,
  type HierarchyAttributeResponseDto,
  type HierarchyCountRequestDto,
  type HierarchyRequestDto,
  type HierarchyServiceResponseDto,
  type HierarchyStructureResponseDto,
  type LocateAttributeRequestDto,
  type NodeExpansionRequestDto,
  type SearchRequestDto,
  type HierarchyMetadataResponseDto,
  type HierarchyItemsRequestDto,
} from "../models";

const apiVersion = "v1";
export const hierarchyServiceApi = createApi({
  baseQuery: getBaseQuery(`/api/hierarchy-service/${apiVersion}`),
  endpoints: (builder) => ({
    countChildren: builder.query<
      { [key: string]: number },
      {
        division: string;
        featureModules?: string[];
        hierarchyType: string;
        payload: HierarchyCountRequestDto;
      }
    >({
      providesTags: ["CountChildren"],
      query: ({ division, featureModules = [], hierarchyType, payload }) => ({
        body: payload,
        method: "POST",
        url: `/Hierarchy/${division}/${hierarchyType}/count${"".concat(
          ...featureModules.map(
            (fm, index) => `${index === 0 ? "?" : "&"}featureModule=${fm}`
          )
        )}`,
      }),
    }),
    expand: builder.query<
      HierarchyServiceResponseDto,
      {
        division: string;
        featureModules?: string[];
        hierarchyType: string;
        payload: NodeExpansionRequestDto;
      }
    >({
      providesTags: ["Expand"],
      query: ({ division, featureModules = [], hierarchyType, payload }) => ({
        body: payload,
        method: "POST",
        url: `/Hierarchy/${division}/${hierarchyType}/expand${"".concat(
          ...featureModules.map(
            (fm, index) => `${index === 0 ? "?" : "&"}featureModule=${fm}`
          )
        )}`,
      }),
    }),
    bulkExpand: builder.query<
      HierarchyServiceResponseDto,
      {
        division: string;
        featureModules?: string[];
        hierarchyType: string;
        payload: BulkNodeExpansionRequestDto;
      }
    >({
      providesTags: ["Expand"],
      query: ({ division, featureModules = [], hierarchyType, payload }) => ({
        body: payload,
        method: "POST",
        url: `/Hierarchy/${division}/${hierarchyType}/bulk-expand${"".concat(
          ...featureModules.map(
            (fm, index) => `${index === 0 ? "?" : "&"}featureModule=${fm}`
          )
        )}`,
      }),
    }),
    getAncestors: builder.query<
      HierarchyServiceResponseDto,
      {
        division: string;
        featureModules?: string[];
        hierarchyType: string;
        payload: HierarchyRequestDto;
      }
    >({
      providesTags: ["GetAncestors"],
      query: ({ division, featureModules = [], hierarchyType, payload }) => ({
        body: payload,
        method: "POST",
        url: `/Hierarchy/${division}/${hierarchyType}/ancestors${"".concat(
          ...featureModules.map(
            (fm, index) => `${index === 0 ? "?" : "&"}featureModule=${fm}`
          )
        )}`,
      }),
    }),
    getItems: builder.query<
      HierarchyServiceResponseDto,
      {
        division: string;
        featureModules?: string[];
        hierarchyType: string;
        payload: HierarchyItemsRequestDto;
      }
    >({
      providesTags: ["GetItems"],
      query: ({ division, featureModules = [], hierarchyType, payload }) => ({
        body: payload,
        method: "POST",
        url: `/Hierarchy/${division}/${hierarchyType}/items${"".concat(
          ...featureModules.map(
            (fm, index) => `${index === 0 ? "?" : "&"}featureModule=${fm}`
          )
        )}`,
      }),
    }),
    getLeafItems: builder.query<
      HierarchyServiceResponseDto,
      {
        division: string;
        featureModules?: string[];
        hierarchyType: string;
        payload: HierarchyItemsRequestDto;
      }
    >({
      providesTags: ["GetLeafItems"],
      query: ({ division, featureModules = [], hierarchyType, payload }) => ({
        body: payload,
        method: "POST",
        url: `/Hierarchy/${division}/${hierarchyType}/leaf-items${"".concat(
          ...featureModules.map(
            (fm, index) => `${index === 0 ? "?" : "&"}featureModule=${fm}`
          )
        )}`,
      }),
    }),
    getRootNodes: builder.query<
      HierarchyServiceResponseDto,
      {
        division: string;
        featureModules?: string[];
        hierarchyType: string;
        payload: RootNodesRequestDto;
      }
    >({
      providesTags: ["GetRootNodes"],
      query: ({ division, featureModules = [], hierarchyType, payload }) => ({
        body: payload,
        method: "POST",
        url: `/Hierarchy/${division}/${hierarchyType}/root-nodes${"".concat(
          ...featureModules.map(
            (fm, index) => `${index === 0 ? "?" : "&"}featureModule=${fm}`
          )
        )}`,
      }),
    }),
    hierarchyAttribute: builder.query<
      HierarchyAttributeResponseDto[],
      { division: string; hierarchyType: string }
    >({
      providesTags: ["HierarchyAttribute"],
      query: ({ division, hierarchyType }) =>
        `Metadata/attributes/${division}/${hierarchyType}`,
    }),
    hierarchyMetadata: builder.query<
      HierarchyMetadataResponseDto[],
      { division: string; getAllAttributes?: boolean; hierarchyType: string }
    >({
      providesTags: ["HierarchyMetadata"],
      query: ({ division, hierarchyType, getAllAttributes = true }) =>
        `Metadata/${division}/${hierarchyType}/?getAllAttributes=${getAllAttributes}`,
    }),
    hierarchyStructure: builder.query<
      HierarchyStructureResponseDto[],
      { division: string; hierarchyType: string }
    >({
      providesTags: ["HierarchyStructure"],
      query: ({ division, hierarchyType }) =>
        `Metadata/structure/${division}/${hierarchyType}`,
    }),
    locateAttribute: builder.query<
      HierarchyServiceResponseDto,
      {
        division: string;
        featureModules?: string[];
        hierarchyType: string;
        payload: LocateAttributeRequestDto;
      }
    >({
      providesTags: ["LocateAttribute"],
      query: ({ division, featureModules = [], hierarchyType, payload }) => ({
        body: payload,
        method: "POST",
        url: `/Hierarchy/${division}/${hierarchyType}/attribute-location${"".concat(
          ...featureModules.map(
            (fm, index) => `${index === 0 ? "?" : "&"}featureModule=${fm}`
          )
        )}`,
      }),
    }),
    attributesCount: builder.query<
      { [key: string]: number },
      {
        division: string;
        featureModules?: string[];
        hierarchyType: string;
        payload: AttributesRequestDto;
      }
    >({
      providesTags: ["AttributesCount"],
      query: ({ division, featureModules = [], hierarchyType, payload }) => ({
        body: payload,
        method: "POST",
        url: `/Hierarchy/${division}/${hierarchyType}/attributes-count${"".concat(
          ...featureModules.map(
            (fm, index) => `${index === 0 ? "?" : "&"}featureModule=${fm}`
          )
        )}`,
      }),
    }),
    search: builder.query<
      HierarchyServiceResponseDto,
      {
        division: string;
        featureModules?: string[];
        hierarchyType: string;
        payload: SearchRequestDto;
      }
    >({
      providesTags: ["Search"],
      query: ({ division, featureModules = [], hierarchyType, payload }) => ({
        body: payload,
        method: "POST",
        url: `/search/${division}/${hierarchyType}${"".concat(
          ...featureModules.map(
            (fm, index) => `${index === 0 ? "?" : "&"}featureModule=${fm}`
          )
        )}`,
      }),
    }),
    searchCount: builder.query<
      number,
      {
        division: string;
        featureModules?: string[];
        hierarchyType: string;
        payload: SearchRequestDto;
      }
    >({
      providesTags: ["Search"],
      query: ({ division, featureModules = [], hierarchyType, payload }) => ({
        body: payload,
        method: "POST",
        url: `/search-count/${division}/${hierarchyType}${"".concat(
          ...featureModules.map(
            (fm, index) => `${index === 0 ? "?" : "&"}featureModule=${fm}`
          )
        )}`,
      }),
    }),
  }),
  reducerPath: "hierarchyServiceApi",
  tagTypes: [
    "Search",
    "Expand",
    "GetAncestors",
    "GetItems",
    "GetLeafItems",
    "HierarchyStructure",
    "HierarchyAttribute",
    "LocateAttribute",
    "AttributesCount",
    "CountChildren",
    "GetRootNodes",
    "HierarchyMetadata",
  ],
});

export const {
  useSearchQuery,
  useLazySearchQuery,
  useSearchCountQuery,
  useExpandQuery,
  useLazyExpandQuery,
  useLazyBulkExpandQuery,
  useLazyGetAncestorsQuery,
  useGetAncestorsQuery,
  useGetItemsQuery,
  useLazyGetItemsQuery,
  useGetLeafItemsQuery,
  useLazyGetLeafItemsQuery,
  useHierarchyStructureQuery,
  useHierarchyAttributeQuery,
  useHierarchyMetadataQuery,
  useLazyHierarchyMetadataQuery,
  useLazyLocateAttributeQuery,
  useLocateAttributeQuery,
  useAttributesCountQuery,
  useLazyAttributesCountQuery,
  useCountChildrenQuery,
  useLazyCountChildrenQuery,
  useGetRootNodesQuery,
  useLazyGetRootNodesQuery,
} = hierarchyServiceApi;
