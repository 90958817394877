import {
  ddLog,
  HierarchyItemType,
  TrackingComponent,
  type HierarchySliceNodeDto,
  TrackingEvent,
  useGetUserQuery,
} from "@quantium-enterprise/common-ui";
import { type DeferredFormatFunction } from "@quantium-enterprise/hooks-ui";
import { useDivision, useFormatter } from "@quantium-enterprise/hooks-ui";
import {
  type ExpandedState,
  type RowSelectionState,
  type CellContext,
  createColumnHelper,
} from "@tanstack/react-table";
import { EmptySearch } from "components-ui/src/search/EmptySearch";
import { SearchBox } from "components-ui/src/search-box/SearchBox";
import { ExpandableNameCell } from "components-ui/src/tables/common/table-cell/ExpandableNameCell";
import { ValueCell } from "components-ui/src/tables/common/table-cell/ValueCell";
import { ReportHierarchyTableWrapper } from "components-ui/src/tables/report-hierarchy-table/components/ReportHierarchyTableWrapper";
import {
  AUTO_WIDTH,
  PERCENT_WIDTH,
  VirtuosoTableComponent,
} from "components-ui/src/tables/virtuoso-table/VirtuosoTableComponent";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { buildNestedRows } from "../../../common/utils/data-table-utils";
import { type RootState } from "../../../store";
import { type TableChildrenRequestDto } from "../../models/CustomerProfilingReportTableRequestDto";
import {
  type SegmentDto,
  type CustomerProfilingTableRow,
} from "../../models/CustomerProfilingReportTableResponseDto";
import {
  useGetSearchResultsTableQuery,
  useLazyGetTableChildrenQuery,
} from "../../services/customer-profiling-data-table-api-slice";
import {
  onTableChildrenDataRecieved,
  selectFocalItems,
  selectLocalSelections,
  selectMetric,
  selectSegmentation,
  setFocalItems,
} from "../../services/customer-profiling-slice";
import styles from "./CustomerProfilingTopDrawerProductTable.module.css";
import { getLocalSelectionPayload } from "./utils";

export type CustomerProfilingTopDrawerProductTableProps = {
  eventTrackingService: Function;
  isQuerySuccess: boolean;
};

const measuresColumnDefs = (
  segments: SegmentDto[],
  format: string,
  formatter: DeferredFormatFunction
) =>
  segments.map((segment) => ({
    accessorFn: (row: CustomerProfilingTableRow) => {
      const segmentData = row.data.find((data) => data.name === segment.key);

      if (segmentData) {
        return segmentData.value;
      }

      return "";
    },
    cell: ({ getValue }: CellContext<CustomerProfilingTableRow, number>) =>
      ValueCell({
        formatter: formatter(format),
        value: getValue(),
      }),
    header: segment.label,
    id: segment.key,
  }));

export const CustomerProfilingTopDrawerProductTable = ({
  isQuerySuccess,
  eventTrackingService,
}: CustomerProfilingTopDrawerProductTableProps) => {
  const [isRowFetchingChildren, setIsRowFetchingChildren] = useState<{
    [id: number]: boolean;
  }>({});

  const dispatch = useDispatch();
  const { name: divisionName } = useDivision();
  const { id: reportId } = useParams();
  const { data: user } = useGetUserQuery();
  const formatter = useFormatter();

  const focalItems = useSelector(selectFocalItems);
  const localSelections = useSelector(selectLocalSelections);
  const metric = useSelector(selectMetric);
  const segmentation = useSelector(selectSegmentation);

  const { tableResponse } = useSelector((state: RootState) => ({
    tableResponse: state.customerProfiling.tableResponse,
  }));

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [expandState, setExpandState] = useState<ExpandedState>(true);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const trimmedSearchQuery = useMemo(() => searchQuery.trim(), [searchQuery]);

  const { data: searchResult, isFetching: isSearchFetching } =
    useGetSearchResultsTableQuery(
      {
        divisionName,
        payload: {
          localSelectedValues: getLocalSelectionPayload(localSelections),
          searchQuery: trimmedSearchQuery,
          reportId: reportId ?? "",
        },
      },
      {
        skip: !divisionName || !trimmedSearchQuery || !reportId,
      }
    );

  useEffect(() => {
    for (const key in rowSelection) {
      if ({}.hasOwnProperty.call(rowSelection, key)) {
        rowSelection[key] = false;
      }
    }

    for (const item of focalItems) {
      rowSelection[item.nodeNumber.toString()] = true;
    }
  }, [focalItems, rowSelection]);

  const columnHelper = createColumnHelper<CustomerProfilingTableRow>();

  const getRowId = (row: CustomerProfilingTableRow) =>
    row.hierarchyItem.nodeNumber.toString();

  const [getTableChildrenTrigger] = useLazyGetTableChildrenQuery({
    refetchOnFocus: false,
  });

  const fetchChildren = useCallback(
    async (parentNode: HierarchySliceNodeDto) => {
      setIsRowFetchingChildren((previousData) => ({
        ...previousData,
        [parentNode.nodeNumber.toString()]: true,
      }));

      const payload: TableChildrenRequestDto = {
        localSelectedValues: getLocalSelectionPayload(localSelections),
        parentNode: parentNode.nodeNumber,
        reportId: reportId ?? "",
      };

      const { data } = await getTableChildrenTrigger({
        divisionName,
        payload,
      });
      if (data) {
        dispatch(onTableChildrenDataRecieved(data));
      }

      setIsRowFetchingChildren((previousData) => ({
        ...previousData,
        [parentNode.nodeNumber.toString()]: false,
      }));
    },
    [dispatch, divisionName, getTableChildrenTrigger, localSelections, reportId]
  );

  // Create the expandable cells for the first columns
  const createNameCell = useCallback(
    ({ row, getValue }: CellContext<CustomerProfilingTableRow, string>) => (
      <ExpandableNameCell
        canExpand={!row.original.hierarchyItem.isLeaf}
        depth={row.depth}
        handleToggleExpanded={() => {
          // Fetch children if no data
          if (row.subRows.length === 0) {
            fetchChildren(row.original.hierarchyItem).catch((error) => {
              ddLog("ERROR", {}, "error", error);
            });
          }

          row.getToggleExpandedHandler()();
        }}
        handleToggleSelected={(event) => {
          row.getToggleSelectedHandler()(event);
          const newFocalItemState = row.getIsSelected()
            ? focalItems.filter(
                (value) =>
                  value.nodeNumber !== row.original.hierarchyItem.nodeNumber
              )
            : [...focalItems, row.original.hierarchyItem];
          dispatch(setFocalItems(newFocalItemState));
          eventTrackingService(
            [
              TrackingComponent.MyReports,
              TrackingComponent.Report,
              TrackingComponent.FocalItemTableRow,
            ],
            row.getIsSelected()
              ? TrackingEvent.Unselected
              : TrackingEvent.Selected,
            {
              selection: newFocalItemState.map(
                ({ shortName, name }) => `${shortName}:${name}`
              ),
              division: divisionName,
              user: user?.isSupplier ? "Supplier" : "Retailer",
            }
          );
        }}
        isExpanded={row.getIsExpanded() && row.original.subRows.length > 0}
        isExpanding={
          isRowFetchingChildren[row.original.hierarchyItem.nodeNumber] &&
          row.original.subRows.length === 0
        }
        isSelected={row.getIsSelected()}
        name={row.original.hierarchyItem.name}
        shortName={row.original.hierarchyItem.shortName}
        type={HierarchyItemType.Hierarchy}
        value={getValue()}
      />
    ),
    [
      isRowFetchingChildren,
      fetchChildren,
      focalItems,
      dispatch,
      eventTrackingService,
      divisionName,
      user?.isSupplier,
    ]
  );

  const { tableRows, dataColumns } = useMemo(() => {
    const tableData =
      trimmedSearchQuery && !isSearchFetching ? searchResult : tableResponse;

    const metricData = tableData?.metricValues.find(
      (x) => x.metricName === (metric.value as string)
    );

    const metricName = metricData?.displayName ?? (metric.value as string);
    const rows = trimmedSearchQuery
      ? metricData?.items.map(
          (metricItem) =>
            ({
              data: metricItem.data,
              subRows: [],
              hierarchyItem: { ...metricItem.hierarchyItem, isLeaf: true },
            } as CustomerProfilingTableRow)
        )
      : buildNestedRows(metricData?.items ?? []);
    const columns =
      segmentation.length > 0
        ? [
            {
              columns: measuresColumnDefs(
                tableResponse.segments,
                metricData?.format ?? "Decimal",
                formatter
              ),
              header: metricName,
              id: metricName,
            },
          ]
        : [];

    return {
      dataColumns: columns,
      tableRows: rows,
    };
  }, [
    trimmedSearchQuery,
    isSearchFetching,
    searchResult,
    tableResponse,
    metric,
    segmentation.length,
    formatter,
  ]);

  const onSearchChange = useCallback((value: string) => {
    setSearchQuery(value);
  }, []);

  const matchesFound = useMemo(() => {
    if (!searchQuery) {
      return 0;
    }

    return (
      searchResult?.metricValues.find(
        (metricValue) => metricValue.metricName === (metric.value as string)
      )?.items.length ?? 0
    );
  }, [searchQuery, searchResult?.metricValues, metric]);

  const memoizedSearchBox = useMemo(
    () => (
      <div className={styles.searchBox}>
        <SearchBox
          enableDebounce
          onChange={onSearchChange}
          placeholder="Type to search"
          resultCount={trimmedSearchQuery ? matchesFound : undefined}
          searchQuery={trimmedSearchQuery}
        />
      </div>
    ),
    [matchesFound, onSearchChange, trimmedSearchQuery]
  );

  return (
    <ReportHierarchyTableWrapper
      className={styles.customerProfilingReportHierarchyTableWrapperContainer}
      isSuccess={isQuerySuccess && !isSearchFetching}
    >
      <VirtuosoTableComponent
        columns={[
          {
            columns: [
              columnHelper.accessor((row) => row.hierarchyItem.name, {
                cell: createNameCell,
                enableHiding: false,
                enableResizing: true,
                enableSorting: false,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                header: memoizedSearchBox,
                id: "FocalItemTableLowerHeader",
              }),
            ],
            header: "Item description",
            id: "FocalItemTableUpperHeader",
          },
          ...dataColumns,
        ]}
        data={tableRows ?? []}
        getRowId={getRowId}
        getSubRows={(row) => row.subRows}
        onExpandedChange={setExpandState}
        onRowSelectionChange={setRowSelection}
        pinFirstColumn
        rowExpandedState={expandState}
        rowSelectionState={rowSelection}
        showCheckboxesOnlyOnHover
        tableWidth={segmentation.length > 1 ? PERCENT_WIDTH : AUTO_WIDTH}
      />
      {trimmedSearchQuery && !matchesFound && (
        <div className={styles.emptySearch}>
          <EmptySearch />
        </div>
      )}
    </ReportHierarchyTableWrapper>
  );
};

export default CustomerProfilingTopDrawerProductTable;
