import { Checkbox } from "@qbit/react";
import styles from "./StaticGroupLeafsTableHeader.module.css";

type StaticGroupLeafsTableHeaderProps = {
  handleToggleAllRowsSelected: (event: unknown) => void;
  isChecked: boolean;
  showCheckbox: boolean;
  title: string;
};

export const StaticGroupLeafsTableHeader = ({
  handleToggleAllRowsSelected,
  isChecked,
  showCheckbox,
  title,
}: StaticGroupLeafsTableHeaderProps) => (
  <div className={styles.groupSelectedTableHeader}>
    {showCheckbox && (
      <Checkbox
        assistiveLabel
        checked={isChecked}
        data-testid="header-checkbox"
        label="Toggle selecting all rows"
        name="leafselectallrows"
        onChange={handleToggleAllRowsSelected}
      />
    )}
    <span
      className={styles.headerText}
      style={{ marginLeft: "var(--qbit-space-xlarge)" }}
    >
      {title}
    </span>
  </div>
);
