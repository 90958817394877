import React from "react";

type MetricsProps = {
  className?: string;
};

export const Metrics: React.FC<MetricsProps> = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="17"
    viewBox="0 0 16 17"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.18335 12.0833V4.91668C2.18335 4.55001 2.31113 4.23612 2.56668 3.97501C2.82224 3.7139 3.13335 3.58334 3.50002 3.58334H13.5C13.8667 3.58334 14.1806 3.7139 14.4417 3.97501C14.7028 4.23612 14.8333 4.55001 14.8333 4.91668V12.0833C14.8333 12.45 14.7028 12.7611 14.4417 13.0167C14.1806 13.2722 13.8667 13.4 13.5 13.4H3.50002C3.13335 13.4 2.82224 13.2722 2.56668 13.0167C2.31113 12.7611 2.18335 12.45 2.18335 12.0833V12.0833ZM3.50002 12.0833H5.95002V4.91668H3.50002V12.0833ZM7.26668 12.0833H9.73335V4.91668H7.26668V12.0833V12.0833ZM11.05 12.0833H13.5V4.91668H11.05V12.0833Z"
      fill="#B5B5B5"
    />
  </svg>
);
