import {
  Button,
  ButtonHeight,
  ButtonVariant,
  Tooltip,
  TooltipPlacement,
  TooltipSpaceInside,
  TooltipVariant,
} from "@qbit/react";

type DeleteButtonProps = {
  isOwner: boolean;
  onDelete: () => void;
};

export const DeleteButton = ({ isOwner, onDelete }: DeleteButtonProps) => (
  <Tooltip
    placement={TooltipPlacement.TopCentre}
    spaceInside={TooltipSpaceInside.Small}
    trigger={
      <Button
        data-testid="delete-group-button"
        disabled={!isOwner}
        height={ButtonHeight.XSmall}
        onClick={onDelete}
        text="Delete"
        variant={ButtonVariant.Danger}
      />
    }
    variant={TooltipVariant.ArrowDark}
  >
    {isOwner ? "Delete" : "Only owner can delete"}
  </Tooltip>
);
