import { useSortable } from "@dnd-kit/sortable";
import classnames from "classnames";
import { TestId } from "../../drag-and-drop/constants";
import { DragIcon } from "../../drag-and-drop/item/DragIcon";
import { RemoveIcon } from "../../drag-and-drop/item/RemoveIcon";
import { type SimpleItem } from "../models/Item";
import { ContainerIdType } from "../models/Zone";
import styles from "./Item.module.css";

export const SimplifiedDraggableItem = ({
  name,
  isPlaceholder,
  color,
}: SimpleItem) =>
  isPlaceholder ? (
    <div className={classnames(styles.itemContainer, styles.itemPlaceholder)} />
  ) : (
    <div
      className={classnames(styles.draggableItem, styles.itemContainer)}
      style={color ? { borderLeft: `4px solid ${color}` } : {}}
    >
      <div className={styles.itemInfo}>
        <span
          className={styles.itemName}
          data-testid={TestId.ItemName}
          title={name}
        >
          {name}
        </span>
      </div>
      <div data-testid={TestId.DragIcon}>
        <DragIcon />
      </div>
    </div>
  );

export type ItemProps = {
  item: SimpleItem;
  onRemove: (item: SimpleItem) => void;
};

export const Item = ({ item, onRemove }: ItemProps) => {
  const { attributes, listeners, setNodeRef } = useSortable({
    data: {
      item,
    },
    id: item.id,
  });

  if (item.containerId === ContainerIdType.METRIC) {
    return (
      <div
        className={classnames(styles.draggableItemWrapper)}
        data-testid={TestId.DraggableItem}
      >
        <div ref={setNodeRef} {...listeners} {...attributes}>
          <SimplifiedDraggableItem {...item} />
        </div>
      </div>
    );
  }

  return (
    <div
      className={classnames(styles.removableItem)}
      data-testid={TestId.DraggableItem}
    >
      <div ref={setNodeRef} {...listeners} {...attributes}>
        <SimplifiedDraggableItem {...item} />
      </div>
      <button
        className={styles.removableItemBtn}
        data-testid={TestId.RemoveIcon}
        onClick={() => {
          onRemove(item);
        }}
        type="button"
      >
        <RemoveIcon />
      </button>
    </div>
  );
};
