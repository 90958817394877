import { Spinner } from "@qbit/react";
import {
  type HierarchyItemName,
  type HierarchyGroupRuleWithIdAndName,
  type HierarchyServiceResponseDto,
  type NodeExpansionRequestDto,
  type SearchRequestDto,
  ddLog,
  useLazyGetRootNodesQuery,
  HierarchyGroupRuleOperator,
  HierarchyType,
  useLazyExpandQuery,
  useLazySearchQuery,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { createSelector } from "@reduxjs/toolkit";
import { EmptySearch } from "components-ui/src/search/EmptySearch";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isFilterComplete } from "report-parameters-ui/src/parameters/utils/isFilterComplete";
import {
  onHierarchyRootNodeReceived,
  reset,
  onHierarchySearchResultsReceived,
  onProductSearchResultsReceived,
  selectSubscription,
} from "../../states/group-hierarchy-source-slice";
import { type RootState } from "../../store";
import {
  getFeatureFilter,
  getFeatureModules,
  getTransactionSourceFilter,
} from "../../utilities/group-subscription-utils";
import { type NestedHierarchyItem } from "./GroupHierarchyTable";
import { GroupHierarchyTable } from "./GroupHierarchyTable";
import styles from "./GroupHierarchyTableWrapper.module.css";

type HierarchyTableState = {
  filterRules: HierarchyGroupRuleWithIdAndName[];
  focalAttributes: string[];
  hierarchyStructure: string[];
  isAdvancedSearchEnabled: boolean;
  items: NestedHierarchyItem[];
  leafNodeShortName: string;
  searchString: string;
  stateHierarchyType: HierarchyType;
  triggerSearch: boolean;
};

const selectHierarchyTableState = createSelector(
  [(state: RootState) => state.groupHierarchySource],
  (groupHierarchySource): HierarchyTableState => ({
    leafNodeShortName: groupHierarchySource.leafNodeShortName,
    items: groupHierarchySource.dataItems,
    stateHierarchyType: groupHierarchySource.type,
    searchString: groupHierarchySource.searchString,
    isAdvancedSearchEnabled: groupHierarchySource.isAdvancedSearchEnabled,
    triggerSearch: groupHierarchySource.triggerSearch,
    filterRules: groupHierarchySource.filterRules,
    focalAttributes: groupHierarchySource.focalAttributes,
    hierarchyStructure: groupHierarchySource.hierarchyStructure,
  })
);

type GroupHierarchyTableWrapperProps = {
  displayEntitlements?: boolean;
  hierarchyType: HierarchyType;
};

export const curatedSearchText = (
  searchtext: string,
  hierarchyType: HierarchyType
) => {
  if (hierarchyType === HierarchyType.Product) return searchtext;

  // matches string starting with digits followed by a hyphen and a word, whitespaces are optional
  const regex = /(\d+\s*-\s?)(\w+)/gu;
  return searchtext.replaceAll(regex, "$2");
};

// only search barcodes when searching for 4+ digit numbers
export const isSearchBarcodes = (
  searchtext: string,
  hierarchyType: HierarchyType
) => {
  const onlyDigits = /^[\d\s,]+$/gu;
  const atLeastFourDigits = /\d{4}/gu;
  return (
    onlyDigits.test(curatedSearchText(searchtext, hierarchyType)) &&
    atLeastFourDigits.test(curatedSearchText(searchtext, hierarchyType))
  );
};

// This is the equivalent of the ProductHierarchyParameterQuery in report-parameters (for the report-wizard)
export const GroupHierarchyTableWrapper = ({
  displayEntitlements = false,
  hierarchyType,
}: GroupHierarchyTableWrapperProps) => {
  const SEARCH_MAX_PAGE_SIZE = 1_000;
  const PRODUCT_HIERARCHY_EXPAND_QUERY_PAGE_SIZE = 500;

  const dispatch = useDispatch();
  const { name: activeDivisionName } = useDivision();

  const subscription = useSelector(selectSubscription);

  const {
    leafNodeShortName,
    items,
    stateHierarchyType,
    searchString,
    isAdvancedSearchEnabled,
    triggerSearch,
    filterRules,
    focalAttributes,
    hierarchyStructure,
  } = useSelector(selectHierarchyTableState);

  // Grab root nodes
  const [
    triggerLazyGetRootNodesQuery,
    {
      data: lazyGetRootNodesData,
      isFetching: isRootNodesQueryFetching,
      isSuccess: lazyGetRootNodesQuerySuccess,
    },
  ] = useLazyGetRootNodesQuery();

  const [triggerSearchQuery, { isFetching: isSearchQueryFetching }] =
    useLazySearchQuery();
  const [triggerExpandQuery, { isFetching: isExpandQueryFetching }] =
    useLazyExpandQuery();

  const includeFilters = useMemo(
    () =>
      filterRules
        .filter(
          (rule) =>
            isFilterComplete(rule) &&
            rule.operator === HierarchyGroupRuleOperator.Is
        )
        .map((rule) => ({
          codes: rule.values.map((x) => x.code),
          shortName: rule.shortName,
        })),
    [filterRules]
  );

  const excludeFilters = useMemo(
    () =>
      filterRules
        .filter(
          (rule) =>
            isFilterComplete(rule) &&
            rule.operator === HierarchyGroupRuleOperator.IsNot
        )
        .map((rule) => ({
          codes: rule.values.map((x) => x.code),
          shortName: rule.shortName,
        })),
    [filterRules]
  );

  const hasFilters = filterRules.length > 0;
  const isAllFiltersComplete =
    hasFilters && filterRules.every(isFilterComplete);
  const hasSearchText = searchString && searchString.trim().length > 0;

  const searchRequest = useMemo(() => {
    const request: SearchRequestDto = {
      featureFilter: getFeatureFilter(subscription),
      page: 0,
      pageSize: SEARCH_MAX_PAGE_SIZE,
      includeCodes: true,
      transactionSourceFilter: getTransactionSourceFilter(subscription),
    };

    if (hasSearchText) {
      request.query = curatedSearchText(searchString, hierarchyType);
      request.includeBarcodes = isSearchBarcodes(searchString, hierarchyType);
    }

    if (isAdvancedSearchEnabled && hasFilters && isAllFiltersComplete) {
      if (includeFilters.length > 0) {
        request.filters = includeFilters;
      }

      if (excludeFilters.length > 0) {
        request.excludeFilters = excludeFilters;
      }

      if (focalAttributes.length > 0) {
        request.focalAttributes = focalAttributes;
      }
    }

    return request;
  }, [
    searchString,
    hierarchyType,
    hasSearchText,
    hasFilters,
    isAllFiltersComplete,
    isAdvancedSearchEnabled,
    excludeFilters,
    includeFilters,
    focalAttributes,
    subscription,
  ]);

  const fetchRootNode = useCallback(async () => {
    if (!activeDivisionName) {
      return;
    }

    await triggerLazyGetRootNodesQuery({
      division: activeDivisionName,
      featureModules: getFeatureModules(subscription),
      hierarchyType: hierarchyType.toString() as HierarchyType,
      payload: {
        featureFilter: getFeatureFilter(subscription),
        page: 0,
        pageSize: 100,
        transactionSourceFilter: getTransactionSourceFilter(subscription),
      },
    });
  }, [
    activeDivisionName,
    hierarchyType,
    subscription,
    triggerLazyGetRootNodesQuery,
  ]);

  const fetchOptionsForIsNotOperator = useCallback(
    async (ruleToFetch: HierarchyGroupRuleWithIdAndName) => {
      try {
        const { data: fetchOptionsData } = await triggerSearchQuery({
          division: activeDivisionName,
          featureModules: getFeatureModules(subscription),
          hierarchyType,
          payload: {
            featureFilter: getFeatureFilter(subscription),
            focalAttributes: [ruleToFetch.shortName],
            page: 0,
            pageSize: 30_000,
            excludeFilters,
            filters: includeFilters,
            query: searchString || undefined,
            transactionSourceFilter: getTransactionSourceFilter(subscription),
          },
        });
        return fetchOptionsData;
      } catch (error) {
        ddLog(
          "Error fetching advanced search options in static group editor.",
          {},
          "error",
          error as Error
        );
        return null;
      }
    },
    [
      activeDivisionName,
      excludeFilters,
      includeFilters,
      hierarchyType,
      searchString,
      triggerSearchQuery,
      subscription,
    ]
  );

  const getExpandPromises = useCallback(
    (
      values: HierarchyItemName[],
      shortName: string
    ): Array<Promise<HierarchyServiceResponseDto>> =>
      values.map(
        async (value) =>
          await triggerExpandQuery({
            division: activeDivisionName,
            featureModules: getFeatureModules(subscription),
            hierarchyType,
            payload: {
              featureFilter: getFeatureFilter(subscription),
              page: 0,
              pageSize: PRODUCT_HIERARCHY_EXPAND_QUERY_PAGE_SIZE,
              parent: {
                code: value.code,
                shortName,
              },
              transactionSourceFilter: getTransactionSourceFilter(subscription),
            } as NodeExpansionRequestDto,
          }).unwrap()
      ),
    [activeDivisionName, hierarchyType, triggerExpandQuery, subscription]
  );

  const handleApplyFilter = useCallback(async () => {
    if (
      isAdvancedSearchEnabled &&
      searchString === "" &&
      filterRules.every((rule) => hierarchyStructure.includes(rule.shortName))
    ) {
      const ruleToFetch = filterRules[filterRules.length - 1];

      let values;
      if (ruleToFetch.operator === HierarchyGroupRuleOperator.IsNot) {
        const fetchOptionsData = await fetchOptionsForIsNotOperator(
          ruleToFetch
        );
        if (!fetchOptionsData || fetchOptionsData.count === 0) return;

        values = fetchOptionsData.results
          .map(({ code, name }) => ({ code, name } as HierarchyItemName))
          .filter(({ code }) => code);
      } else {
        values = ruleToFetch.values;
      }

      const promises = getExpandPromises(values, ruleToFetch.shortName);

      await Promise.all(promises)
        .then((childNodesData) => {
          dispatch(
            onHierarchySearchResultsReceived({
              childNodes: childNodesData,
              leafNodeShortName,
            })
          );
        })
        .catch((error) => {
          ddLog("ERROR", {}, "error", error);
        });
    } else {
      await triggerSearchQuery({
        division: activeDivisionName,
        featureModules: getFeatureModules(subscription),
        hierarchyType,
        payload: searchRequest,
      })
        .unwrap()
        .then((childNodesData) => {
          dispatch(
            onProductSearchResultsReceived({
              childNodes: childNodesData,
            })
          );
        })
        .catch((error) => {
          ddLog(
            "Error in search query in static group editor.",
            {},
            "error",
            error
          );
        });
    }
  }, [
    activeDivisionName,
    dispatch,
    fetchOptionsForIsNotOperator,
    filterRules,
    getExpandPromises,
    isAdvancedSearchEnabled,
    leafNodeShortName,
    hierarchyStructure,
    hierarchyType,
    searchString,
    searchRequest,
    triggerSearchQuery,
    subscription,
  ]);

  // Effects
  useEffect(() => {
    if (hierarchyType !== stateHierarchyType) {
      dispatch(reset({ hierarchyType }));
    }
  }, [dispatch, hierarchyType, stateHierarchyType]);

  useEffect(() => {
    if (!displayEntitlements || subscription) {
      fetchRootNode().catch((error) => {
        ddLog(
          "Error fetching root nodes for static group editor",
          {},
          "error",
          error
        );
      });
    }
  }, [displayEntitlements, fetchRootNode, subscription]);

  useEffect(() => {
    if (
      lazyGetRootNodesQuerySuccess &&
      lazyGetRootNodesData &&
      leafNodeShortName
    ) {
      dispatch(onHierarchyRootNodeReceived(lazyGetRootNodesData));
    }
  }, [
    lazyGetRootNodesData,
    dispatch,
    lazyGetRootNodesQuerySuccess,
    leafNodeShortName,
  ]);

  useEffect(() => {
    if (triggerSearch) {
      handleApplyFilter().catch((error) => {
        ddLog("ERROR", {}, "error", error);
      });
    }
  }, [dispatch, handleApplyFilter, triggerSearch]);

  const isLoading = useMemo(
    () =>
      isRootNodesQueryFetching ||
      isSearchQueryFetching ||
      isExpandQueryFetching ||
      (!lazyGetRootNodesQuerySuccess && !items.length),
    [
      isRootNodesQueryFetching,
      isSearchQueryFetching,
      isExpandQueryFetching,
      items,
      lazyGetRootNodesQuerySuccess,
    ]
  );

  const getGroupHierarchyTable = () => {
    if (isLoading) {
      return (
        <div className={styles.searchTable} data-testid="search-table">
          <div className={styles.loading}>
            <Spinner />
          </div>
        </div>
      );
    }

    if (lazyGetRootNodesQuerySuccess && !items.length) {
      return (
        <div className={styles.searchTable} data-testid="search-table">
          <div className={styles.noresults}>
            <EmptySearch />
          </div>
        </div>
      );
    }

    return (
      <GroupHierarchyTable
        displayEntitlements={displayEntitlements}
        hierarchyType={hierarchyType.toString() as HierarchyType}
        isSuccess={lazyGetRootNodesQuerySuccess && !isRootNodesQueryFetching}
      />
    );
  };

  return <>{getGroupHierarchyTable()}</>;
};
