import { type DragEndEvent, type DragStartEvent } from "@dnd-kit/core";
import { uniqueId } from "@qbit/react/dist/common";
import { DragAndMultiDrop } from "components-ui/src/drag-and-multi-drop/container/DragAndMultiDrop";
import { DragAndMultiDropContainerType } from "components-ui/src/drag-and-multi-drop/container/DragAndMultiDropContainer";
import { type SimpleItem } from "components-ui/src/drag-and-multi-drop/models/Item";
import {
  type Container,
  type SimpleZone,
  ContainerIdType,
} from "components-ui/src/drag-and-multi-drop/models/Zone";
import {
  handleDragCancel,
  handleDragEnd,
  handleDragStart,
  handleRemove,
} from "components-ui/src/drag-and-multi-drop/utilities/eventHandlers";
import { type PanelOption } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { type RootState } from "../../../store";
import {
  type AggregateRankRankedSelections,
  type AggregateRankUnrankedSelections,
  type AggregateRankMetricMetaData,
} from "../../models/aggregate-rank-common-models";
import { updateSelectedDataset } from "../../services/aggregate-rank-slice";
import styles from "./AggregateRankModal.module.css";

type DefaultWrapperProps = {
  dropdownOptions: PanelOption[];
  items: SimpleItem[];
  setItems: (items: SimpleItem[]) => void;
  setZones: (zones: SimpleZone[]) => void;
  updateSelectedMetrics: Function;
  zones: SimpleZone[];
};

export const containers = [
  {
    title: "METRICS",
    id: ContainerIdType.METRIC,
    containerType: DragAndMultiDropContainerType.Left,
  },
  {
    title: "RANKED",
    id: ContainerIdType.RANKED,
    containerType: DragAndMultiDropContainerType.Right,
  },
  {
    title: "UNRANKED",
    id: ContainerIdType.UNRANKED,
    containerType: DragAndMultiDropContainerType.Right,
  },
] as Container[];

export const MetricsDaDWrapper = ({
  items,
  zones,
  setZones,
  dropdownOptions,
  updateSelectedMetrics,
  setItems,
}: DefaultWrapperProps) => {
  const [activeItem, setActiveItem] = useState<SimpleItem | undefined>(
    undefined
  );
  const [savedZoneState, setSavedZoneState] = useState<
    SimpleZone[] | undefined
  >(undefined);
  const dispatch = useDispatch();

  const { availableDatasets, metricsMetaData, selectedDataset } = useSelector(
    (state: RootState) => ({
      availableDatasets: state.aggregateRank.availableDatasets,
      metricsMetaData: state.aggregateRank.metricsMetaData,
      selectedDataset: state.aggregateRank.selectedDataset,
    })
  );

  const buttonGroupOptions = useMemo(
    () =>
      availableDatasets.length > 1
        ? [
            {
              value: "All",
              label: "All",
              color: "",
            },
          ].concat(availableDatasets)
        : [],
    [availableDatasets]
  );

  const setActiveDataset = useCallback(
    (activeDataset: string) => {
      dispatch(updateSelectedDataset(activeDataset));
    },
    [dispatch]
  );

  useEffect(() => {
    const rankedZones = zones.filter(
      (zone) => zone.containerId === ContainerIdType.RANKED && zone.item
    );

    // Map directly from rankedZones to keep their exact order
    const rankedMetrics = rankedZones
      .map((zone) => {
        // Find the matching metadata
        const matchingMetric = metricsMetaData.find(
          (metric) => metric.metricLabel === zone.item?.name
        );

        return matchingMetric
          ? {
              metricLabel: matchingMetric.metricLabel,
              metricValue: matchingMetric.metricValue,
              color: matchingMetric.color,
              dataset: matchingMetric.dataset,
            }
          : null;
      })
      .filter(Boolean);

    const unrankedZones = zones.filter(
      (zone) => zone.containerId === ContainerIdType.UNRANKED && zone.item
    );

    const unrankedMetrics = unrankedZones
      .map((zone) => {
        const matchingMetric = metricsMetaData.find(
          (metric) => metric.metricLabel === zone.item?.name
        );

        return matchingMetric
          ? {
              metricLabel: matchingMetric.metricLabel,
              metricValue: matchingMetric.metricValue,
              color: matchingMetric.color,
              dataset: matchingMetric.dataset,
            }
          : null;
      })
      .filter(Boolean);

    updateSelectedMetrics(rankedMetrics, unrankedMetrics);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zones, selectedDataset]);

  return (
    <DragAndMultiDrop
      activeButton={selectedDataset}
      activeItem={activeItem}
      buttonGroupOptions={buttonGroupOptions}
      containers={containers}
      dropdownOptions={dropdownOptions}
      items={items}
      onDragCancel={() => {
        handleDragCancel(
          setActiveItem,
          items,
          setItems,
          setZones,
          setSavedZoneState,
          savedZoneState,
          activeItem
        );
      }}
      onDragEnd={(event: DragEndEvent) => {
        handleDragEnd(
          setActiveItem,
          zones,
          setZones,
          items,
          setItems,
          setSavedZoneState,
          savedZoneState,
          event.over?.data.current,
          event.over?.id,
          activeItem
        );
      }}
      onDragStart={(event: DragStartEvent) => {
        handleDragStart(
          items,
          setItems,
          setActiveItem,
          zones,
          setZones,
          setSavedZoneState,
          event.active.data.current?.item
        );
      }}
      onRemove={(item: SimpleItem) => {
        handleRemove(item, zones, setZones, items, setItems);
      }}
      setActiveButton={setActiveDataset}
      zones={zones}
    />
  );
};

const createPanelOption = (value: string) => ({
  value,
  label: value,
});

export const AggregateRankMetricsContent = ({
  metricsMetaData,
  rankedMetrics,
  unrankedMetrics,
  updateSelectedMetrics,
  isModalShown,
}: {
  isModalShown: boolean;
  metricsMetaData: AggregateRankMetricMetaData[];
  rankedMetrics: AggregateRankRankedSelections[];
  unrankedMetrics: AggregateRankUnrankedSelections[];
  updateSelectedMetrics: Function;
}) => {
  const [zones, setZones] = useState<SimpleZone[]>([]);
  const { selectedDataset } = useSelector((state: RootState) => ({
    selectedDataset: state.aggregateRank.selectedDataset,
  }));

  const generateZoneItem = useCallback(
    (metricLabel: string, ranked: boolean, index: number, color: string) => {
      const metaData = metricsMetaData.find(
        (meta) => meta.metricLabel === metricLabel
      );
      return {
        containerId: ranked ? ContainerIdType.RANKED : ContainerIdType.UNRANKED,
        type: "zone",
        id: uniqueId(),
        hasItem: true,
        name: metricLabel,
        ordinal: index,
        grouping: metaData?.grouping,
        isPlaceholder: false,
        item: {
          containerId: ranked
            ? ContainerIdType.RANKED
            : ContainerIdType.UNRANKED,
          id: uniqueId(),
          name: metricLabel,
          grouping: metaData?.grouping ?? "",
          ordinal: index,
          color,
        },
      };
    },
    [metricsMetaData]
  );

  const availableMetrics = useMemo(
    () =>
      metricsMetaData
        .filter((metric) => {
          if (selectedDataset === "All") {
            return !zones.map((x) => x.item?.name).includes(metric.metricLabel);
          }

          return (
            !zones.map((x) => x.item?.name).includes(metric.metricLabel) &&
            metric.dataset === selectedDataset
          );
        })
        .map((metricItem, index) => ({
          containerId: ContainerIdType.METRIC,
          id: uniqueId(),
          name: metricItem.metricLabel,
          ordinal: index,
          grouping: metricItem.grouping,
          color: metricItem.color,
        })),
    [metricsMetaData, zones, selectedDataset]
  );
  const [availableMetricItems, setAvailableMetricItems] =
    useState<SimpleItem[]>(availableMetrics);

  const dropdownOptions = [createPanelOption("All types")].concat(
    Array.from(new Set(metricsMetaData.map((x) => x.grouping))).map(
      createPanelOption
    )
  );

  useEffect(() => {
    const selectedMetricItems: SimpleZone[] = rankedMetrics
      .map((metric, index) =>
        generateZoneItem(metric.metricLabel, true, index, metric.color)
      )
      .concat(
        unrankedMetrics.map((metric, index) =>
          generateZoneItem(metric.metricLabel, false, index, metric.color)
        )
      );
    setZones(selectedMetricItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generateZoneItem, isModalShown]);

  useEffect(() => {
    setAvailableMetricItems(availableMetrics);
  }, [selectedDataset, metricsMetaData, zones, availableMetrics]);

  return (
    <div className={styles.modalContentContainer}>
      <MetricsDaDWrapper
        dropdownOptions={dropdownOptions}
        items={availableMetricItems}
        setItems={setAvailableMetricItems}
        setZones={setZones}
        updateSelectedMetrics={updateSelectedMetrics}
        zones={zones}
      />
    </div>
  );
};

export default AggregateRankMetricsContent;
