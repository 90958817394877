import { Text } from "@qbit/react";

const DEFAULT_VALUE: string[] = [];

export type ListSelectionSummaryProps = {
  label: string;
  selections: string[];
};

export const ListSelectionSummary = ({
  label = "",
  selections = DEFAULT_VALUE,
}: ListSelectionSummaryProps) => {
  // Enabling the shorthand .filter((x) => x) syntax here
  // eslint-disable-next-line unicorn/prefer-native-coercion-functions
  const commaSeparatedSelectionString = selections.filter((x) => x).join(", ");

  return label !== "" && selections.length > 0 ? (
    <div>
      <Text data-testid="summary-display-list-selections">{`${label}: ${commaSeparatedSelectionString}`}</Text>
    </div>
  ) : null;
};

export type TimePeriodDataTypeSelectionSummaryProps = {
  selections: string[];
};

export const TimePeriodDataTypeSelectionSummary = ({
  selections = DEFAULT_VALUE,
}: TimePeriodDataTypeSelectionSummaryProps) => {
  // Enabling the shorthand .filter((x) => x) syntax here
  // eslint-disable-next-line unicorn/prefer-native-coercion-functions
  const commaSeparatedSelectionString = selections.filter((x) => x).join(", ");

  return selections.length > 0 ? (
    <div>
      <Text data-testid="summary-display-list-selections">{`${commaSeparatedSelectionString}`}</Text>
    </div>
  ) : null;
};
