import { MessageVariant, QbitEmitToast, QbitToastMessage } from "@qbit/react";
import {
  FeatureFlag,
  useGetReportParametersQuery,
  useRenameReportMutation,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import { InfoPanelType } from "components-ui/src/info-panel/info-panel-header/InfoPanelHeader";
import ReportView, {
  ReportViewLayoutVariant,
} from "components-ui/src/report/ReportView";
import { useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, type RouteObject } from "react-router-dom";
import ErrorBoundary from "../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { NoDataChartWrapper } from "../common/components/NoDataChartWrapper";
import { ReportletAccordion } from "../common/components/ReportletAccordion";
import { selectFocalTableInitialised } from "../new-product-benchmarking/services/new-product-benchmarking-slice";
import { BasketAffinitiesAssociatedItemsReportlet } from "./components/BasketAffinitiesAssociatedItemsReportlet";
import { BasketAffinitiesPurchasedWithFocalItemsReportlet } from "./components/BasketAffinitiesPurchasedWithFocalItemsReportlet";
import { BasketAffinitiesPurchasedWithFocalItemsStats } from "./components/BasketAffinitiesPurchasedWithFocalItemsStats";
import { BasketAffinitiesPurchasedWithFocalItemsTable } from "./components/BasketAffinitiesPurchasedWithFocalItemsTable";
import { BasketAffinitiesPurchasedWithFocalItemsTableExport } from "./components/BasketAffinitiesPurchasedWithFocalItemsTableExport";
import BasketAffinitiesSidePanel from "./components/BasketAffinitiesSidePanel";
import BasketAffinitiesTopDrawer from "./components/BasketAffinitiesTopDrawer";
import { BasketAffinitiesUpliftReportlet } from "./components/BasketAffinitiesUpliftReportlet";
import { type PurchasedWithTableRequest } from "./models/basket-affinities-chart-models";
import {
  useGetReportletDataQuery,
  useLazyGetAssociatedReportletDataQuery,
  useLazyGetReportQuery,
} from "./services/basket-affinities-api-slice";
import {
  onReportOpen,
  selectChannel,
  selectLocation,
  selectLocalParametersInitialised,
  selectFocalItem,
  selectPurchasedWithProductsInitialised,
  selectPurchasedWithProductsHasProducts,
  selectSegment,
  selectSegmentation,
  selectIsEmptyReport,
  onReportParametersReceived,
  onAssociatedReportletDataReceived,
  setUpliftIsIntialised,
  setIsFetching,
} from "./services/basket-affinities-slice";

export const BasketAffinitiesReport = () => {
  const dispatch = useDispatch();
  const { id: reportId } = useParams();
  const { name: activeDivisionName } = useDivision();
  const featureFlags = useFlags();
  const isTabsEnabled =
    featureFlags[FeatureFlag.AdvancedReportingTabs] ?? false;
  const isEnhancedReportletsEnabled =
    featureFlags[FeatureFlag.BasketAffinitiesV2Reportlets] ?? false;

  const isEmptyReport = useSelector(selectIsEmptyReport);
  const location = useSelector(selectLocation);
  const channel = useSelector(selectChannel);
  const segment = useSelector(selectSegment);
  const segmentation = useSelector(selectSegmentation);
  const focalItem = useSelector(selectFocalItem);
  const isLocalParametersInitialised = useSelector(
    selectLocalParametersInitialised
  );
  const isFocalTableIntialised = useSelector(selectFocalTableInitialised);
  const isReportletTableIntialised = useSelector(
    selectPurchasedWithProductsInitialised
  );

  const isReportletTableHasData = useSelector(
    selectPurchasedWithProductsHasProducts
  );
  const isDataLoaded = Boolean(isLocalParametersInitialised && reportId);
  const isRequestInvalid = isFocalTableIntialised && focalItem !== undefined;

  const [RenameReport] = useRenameReportMutation();
  const handleRenameReport = async (newReportName: string, itemId: string) => {
    await RenameReport({
      divisionName: activeDivisionName,
      payload: { newReportName, reportId: itemId },
    }).unwrap();
  };

  useEffect(() => {
    if (reportId) {
      dispatch(
        onReportOpen({
          reportId,
          isTabsEnabled,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTabsEnabled, reportId]);

  const { data: reportParameters, isLoading: isInfoPanelSummaryLoading } =
    useGetReportParametersQuery(
      { divisionName: activeDivisionName, reportId: reportId ?? "" },
      { skip: reportId === undefined || !activeDivisionName }
    );

  useEffect(() => {
    if (reportParameters) {
      dispatch(onReportParametersReceived(reportParameters));
    }
  }, [dispatch, reportParameters]);

  const [getReportQuery, { isSuccess }] = useLazyGetReportQuery();

  const fetchReportQuery = useCallback(
    async (division: string, id: string) => {
      await getReportQuery({ divisionName: division, reportId: id });
    },
    [getReportQuery]
  );

  useEffect(() => {
    if (activeDivisionName && reportId && !isLocalParametersInitialised) {
      void fetchReportQuery(activeDivisionName, reportId);
    }
  }, [
    activeDivisionName,
    reportId,
    fetchReportQuery,
    isLocalParametersInitialised,
  ]);

  if (isSuccess && isEmptyReport) {
    QbitEmitToast(
      <QbitToastMessage
        content={
          <span>Insufficient data available for the selected parameters.</span>
        }
        heading="No data available"
        variant={MessageVariant.Warning}
      />
    );
  }

  // load the reportlet data
  const postData = useMemo(
    () =>
      ({
        reportId: reportId ?? "",
        parameterSelections: {
          location: location.nodeNumber,
          channel: channel.value,
          segmentation: {
            segment: segment.value,
            segmentation: segmentation.value,
          },
        },
        focalItem,
      } as PurchasedWithTableRequest),
    [
      reportId,
      location,
      channel.value,
      segment.value,
      segmentation.value,
      focalItem,
    ]
  );

  const [
    getAssociatedReportletQuery,
    { data: AffinitiesUpliftData, isFetching: isDataFetching },
  ] = useLazyGetAssociatedReportletDataQuery();

  useEffect(() => {
    if (AffinitiesUpliftData) {
      dispatch(onAssociatedReportletDataReceived(AffinitiesUpliftData));
      dispatch(setUpliftIsIntialised(true));
    }
  }, [dispatch, AffinitiesUpliftData]);

  useEffect(() => {
    dispatch(setIsFetching(isDataFetching));
  }, [dispatch, isDataFetching]);

  const fetchReportletData = useCallback(async () => {
    await getAssociatedReportletQuery({
      divisionName: activeDivisionName,
      requestPayload: postData,
    });
  }, [activeDivisionName, getAssociatedReportletQuery, postData]);

  useEffect(() => {
    if (!isRequestInvalid && isDataLoaded) {
      void fetchReportletData();
    }
  }, [
    fetchReportletData,
    isDataLoaded,
    isFocalTableIntialised,
    isRequestInvalid,
  ]);

  useGetReportletDataQuery(
    { divisionName: activeDivisionName, requestPayload: postData },
    {
      skip:
        reportId === undefined ||
        focalItem === undefined ||
        !activeDivisionName ||
        !isLocalParametersInitialised ||
        isEmptyReport ||
        isEnhancedReportletsEnabled,
    }
  );

  const defaultReportlets = () => (
    <>
      <BasketAffinitiesAssociatedItemsReportlet />
      <br />
      <br />
      <ReportletAccordion
        subtitle="See which products are purchased with the focal item based on the relevant metrics."
        title="Purchased with focal item"
      >
        <ErrorBoundary>
          <NoDataChartWrapper
            isLoading={!isReportletTableIntialised}
            minHeight="500px"
            noData={
              isEmptyReport ||
              (isReportletTableIntialised && !isReportletTableHasData)
            }
          >
            <>
              <BasketAffinitiesPurchasedWithFocalItemsStats />
              <BasketAffinitiesPurchasedWithFocalItemsTableExport />
              <BasketAffinitiesPurchasedWithFocalItemsTable />
            </>
          </NoDataChartWrapper>
        </ErrorBoundary>
      </ReportletAccordion>
    </>
  );

  const enhancedReportlets = () => (
    <>
      <ReportletAccordion
        subtitle="See the associated items purchased with the focal item, ranked by how likely customers are to purchase them together."
        title="Uplift chart"
      >
        <BasketAffinitiesUpliftReportlet />
      </ReportletAccordion>
      <ReportletAccordion
        subtitle="See which items are purchased with the focal item based on the relevant metrics."
        title="Purchased with focal item"
      >
        <BasketAffinitiesPurchasedWithFocalItemsReportlet />
      </ReportletAccordion>
    </>
  );

  return (
    <ReportView>
      <ReportView.Layout variant={ReportViewLayoutVariant.AdvancedReporting}>
        <ReportView.HeaderPanel>
          <BasketAffinitiesTopDrawer
            renameReport={handleRenameReport}
            reportParameters={reportParameters}
          />
        </ReportView.HeaderPanel>
        <ReportView.SidePanel>
          <BasketAffinitiesSidePanel />
        </ReportView.SidePanel>
        <ReportView.ReportletPanel>
          {isEnhancedReportletsEnabled
            ? enhancedReportlets()
            : defaultReportlets()}
        </ReportView.ReportletPanel>
      </ReportView.Layout>
      <ReportView.InfoPanel
        infoPanelSummary={reportParameters}
        infoPanelType={InfoPanelType.ReportViewer}
        isInfoPanelSummaryLoading={isInfoPanelSummaryLoading}
        renameItem={handleRenameReport}
      />
    </ReportView>
  );
};

export const route: RouteObject = {
  element: <BasketAffinitiesReport />,
  path: "basket-affinities/:id",
};

export default BasketAffinitiesReport;
