import { Button, ButtonVariant, Icon, IconGlyph, Spinner } from "@qbit/react";
import { uniqueId } from "@qbit/react/dist/common";
import {
  type FeatureFilter,
  type TransactionSourceFilter,
  type HierarchyType,
  type HierarchyMetadataResponseDto,
  HierarchyGroupRuleOperator,
  HierarchyStructureName,
  type HierarchyGroupRuleWithIdAndName,
  ParameterId,
  NULL_SHORT_NAME,
  areFiltersEqual,
} from "@quantium-enterprise/common-ui";
import { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./AdvancedSearchEditor.module.css";
import { type RuleNameOption } from "./DynamicGroupRule";
import { DynamicGroupRule } from "./DynamicGroupRule";

export type AdvancedSearchEditorProps = {
  enableAdvancedSearch: React.Dispatch<React.SetStateAction<boolean>>;
  featureFilter?: FeatureFilter | undefined;
  featureModules?: string[] | undefined;
  filterRules: HierarchyGroupRuleWithIdAndName[];
  hierarchyAttributes: HierarchyMetadataResponseDto[];
  hierarchyType: HierarchyType;
  leafShortName: string;
  onFilterRulesUpdate: (value: {
    filterRules: HierarchyGroupRuleWithIdAndName[];
    parameterType: ParameterId;
  }) => void;
  parameterType?: ParameterId;
  transactionSourceFilter?: TransactionSourceFilter | undefined;
  useFullWidth?: boolean;
};

export const AdvancedSearchEditor = ({
  enableAdvancedSearch,
  featureFilter,
  featureModules,
  filterRules,
  hierarchyAttributes,
  hierarchyType,
  leafShortName,
  onFilterRulesUpdate,
  transactionSourceFilter,
  parameterType = ParameterId.ProductHierarchy,
  useFullWidth = false,
}: AdvancedSearchEditorProps) => {
  const [readOnlyRules, setReadOnlyRules] = useState(filterRules.slice(0, -1));
  const [editableRule, setEditableRule] = useState(filterRules.at(-1));

  useEffect(() => {
    const newFilterRules = editableRule
      ? readOnlyRules.concat([editableRule])
      : readOnlyRules;

    // Only update if rules have actually changed
    if (!areFiltersEqual(filterRules, newFilterRules)) {
      onFilterRulesUpdate({
        parameterType,
        filterRules: newFilterRules,
      });
    }
  }, [
    editableRule,
    readOnlyRules,
    onFilterRulesUpdate,
    filterRules,
    parameterType,
  ]);

  // do not allow the user to add more rules or click apply filter if the currently edited rule is not complete
  const areAnyFiltersEmpty = useMemo(() => {
    if (filterRules.length === 0) return false;

    return (
      filterRules[filterRules.length - 1].shortName === NULL_SHORT_NAME ||
      filterRules[filterRules.length - 1].values.length === 0
    );
  }, [filterRules]);

  const ruleNameOptions = useMemo(
    () => [
      {
        name: "Filter by",
        shortName: NULL_SHORT_NAME,
        disabled: true,
        selected: true,
      } as RuleNameOption,
      ...hierarchyAttributes,
    ],
    [hierarchyAttributes]
  );

  const getLastHierarchicalRuleInFilters = useCallback(
    (structureName: HierarchyStructureName) => {
      const hierarchyNames = hierarchyAttributes
        .filter((x) => x.structureName === structureName)
        .sort((a, b) => a.ordinal - b.ordinal)
        .map((x) => x.shortName);

      return readOnlyRules
        .filter((x) => hierarchyNames.includes(x.shortName))
        .at(-1);
    },
    [hierarchyAttributes, readOnlyRules]
  );

  // cannot be the final item in the hierarchy, or be in the rules already, or be higher than the last rule if its a hierarchical item
  const canAttributeBeUsedWithRule = useCallback(
    (rule: HierarchyGroupRuleWithIdAndName, shortName: string) => {
      // assume its an attribute, or its the first rule added
      let isRuleLaterOrAttribute = true;

      if (editableRule) {
        const wantedShortName = hierarchyAttributes.find(
          (x) => x.shortName === shortName
        );

        // only check if its a hierarchical attribute
        if (
          wantedShortName?.structureName === HierarchyStructureName.Product ||
          wantedShortName?.structureName === HierarchyStructureName.Location
        ) {
          const lastRuleOfSameHierarchyName = getLastHierarchicalRuleInFilters(
            wantedShortName.structureName
          )?.shortName;
          const lastShortName = hierarchyAttributes.find(
            (x) => x.shortName === lastRuleOfSameHierarchyName
          );

          isRuleLaterOrAttribute =
            wantedShortName.ordinal >= (lastShortName?.ordinal ?? 0);
        }
      }

      const usedRules = readOnlyRules.map((x) => x.shortName);

      return (
        shortName === NULL_SHORT_NAME ||
        (shortName !== leafShortName &&
          !usedRules.includes(shortName) &&
          isRuleLaterOrAttribute)
      );
    },
    [
      getLastHierarchicalRuleInFilters,
      editableRule,
      hierarchyAttributes,
      leafShortName,
      readOnlyRules,
    ]
  );

  const getAllowedRuleNameOptions = useCallback(
    (rule: HierarchyGroupRuleWithIdAndName) =>
      ruleNameOptions.filter((attribute) =>
        canAttributeBeUsedWithRule(rule, attribute.shortName)
      ),
    [canAttributeBeUsedWithRule, ruleNameOptions]
  );

  const handleAddRule = () => {
    setReadOnlyRules(filterRules);
    setEditableRule({
      id: uniqueId("rule-"),
      operator: HierarchyGroupRuleOperator.Is,
      shortName: NULL_SHORT_NAME,
      fullShortName: undefined,
      values: [],
    } as HierarchyGroupRuleWithIdAndName);
  };

  const handleReadonlyRuleRemoved = (ruleId: string) => {
    setReadOnlyRules((oldRules) =>
      oldRules.filter((rule) => ruleId !== rule.id)
    );
  };

  const handleEditableRuleRemoved = () => {
    if (filterRules.length === 1) {
      enableAdvancedSearch(false);
    } else {
      setEditableRule(readOnlyRules.at(-1));
      setReadOnlyRules((oldRules) => oldRules.slice(0, -1));
    }
  };

  const handleRuleChange = (updatedRule: HierarchyGroupRuleWithIdAndName) => {
    // Reset the selected values here so that we don't get into an infinite loop inside DynamicGroupRule
    if (editableRule?.shortName !== updatedRule.shortName) {
      updatedRule.values = [];
    }

    setEditableRule(updatedRule);
  };

  const handleRemoveAllFilters = () => {
    enableAdvancedSearch(false);
  };

  return (
    <div
      className={`${styles.advancedSearchEditorContainer} ${
        useFullWidth ? styles.advancedSearchFullWidth : ""
      }`}
      data-testid="advanced-search-container"
    >
      {!hierarchyAttributes.length && (
        <div className={styles.loadingLeafs}>
          <Spinner />
        </div>
      )}
      {hierarchyAttributes.length &&
        readOnlyRules.map((rule) => (
          <DynamicGroupRule
            featureFilter={featureFilter}
            featureModules={featureModules}
            hierarchyType={hierarchyType}
            key={rule.id}
            onRuleChange={(x) => handleRuleChange(x)}
            onRuleRemoved={() => handleReadonlyRuleRemoved(rule.id)}
            previousGroupRules={readOnlyRules}
            readonly
            rule={rule}
            ruleNameOptions={getAllowedRuleNameOptions(rule)}
            transactionSourceFilter={transactionSourceFilter}
          />
        ))}
      {hierarchyAttributes.length && editableRule && (
        <DynamicGroupRule
          featureFilter={featureFilter}
          featureModules={featureModules}
          hierarchyType={hierarchyType}
          key={editableRule.id}
          onRuleChange={(x) => handleRuleChange(x)}
          onRuleRemoved={() => handleEditableRuleRemoved()}
          previousGroupRules={readOnlyRules}
          readonly={false}
          rule={editableRule}
          ruleNameOptions={getAllowedRuleNameOptions(editableRule)}
          transactionSourceFilter={transactionSourceFilter}
        />
      )}
      <div className={styles.advancedSearchFilterController}>
        <Button
          disabled={areAnyFiltersEmpty}
          onClick={handleAddRule}
          variant={ButtonVariant.Stealth}
        >
          <Icon glyph={IconGlyph.AddAndPlusAddPlus} text="Add filter" />
          <span>Add filter</span>
        </Button>
        <Button
          onClick={handleRemoveAllFilters}
          text="Remove all filters"
          variant={ButtonVariant.Stealth}
        />
      </div>
    </div>
  );
};
