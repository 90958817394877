import {
  type FeatureFilter,
  type TransactionSourceFilter,
  type HierarchyType,
} from "@quantium-enterprise/common-ui";
import { AdvancedSearchEditor } from "components-ui/src/advanced-search/AdvancedSearchEditor";
import { useDispatch, useSelector } from "react-redux";
import {
  onFilterRulesUpdate,
  selectFilterRules,
  selectHierarchyMetadata,
  selectLeafNodeShortName,
} from "../../states/group-hierarchy-source-slice";
import { type RootState } from "../../store";

export type ParameterAdvancedSearchEditorProps = {
  enableAdvancedSearch: React.Dispatch<React.SetStateAction<boolean>>;
  featureFilter: FeatureFilter | undefined;
  featureModules: string[] | undefined;
  hierarchyType: HierarchyType;
  transactionSourceFilter: TransactionSourceFilter | undefined;
};

export const ParameterAdvancedSearchEditor = ({
  enableAdvancedSearch,
  featureFilter,
  featureModules,
  hierarchyType,
  transactionSourceFilter,
}: ParameterAdvancedSearchEditorProps) => {
  const dispatch = useDispatch();

  const leafShortName = useSelector((state: RootState) =>
    selectLeafNodeShortName(state)
  );

  const hierarchyAttributes = useSelector((state: RootState) =>
    selectHierarchyMetadata(state)
  );

  const filterRules = useSelector((state: RootState) =>
    selectFilterRules(state)
  );

  return (
    <AdvancedSearchEditor
      enableAdvancedSearch={enableAdvancedSearch}
      featureFilter={featureFilter}
      featureModules={featureModules}
      filterRules={filterRules}
      hierarchyAttributes={hierarchyAttributes}
      hierarchyType={hierarchyType}
      leafShortName={leafShortName}
      onFilterRulesUpdate={(value) => dispatch(onFilterRulesUpdate(value))}
      transactionSourceFilter={transactionSourceFilter}
    />
  );
};
