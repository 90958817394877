import { HierarchyItemType, ReportType } from "@quantium-enterprise/common-ui";
import { ChartFooterWrapper } from "components-ui/src/charts/chart-footer-wrapper/ChartFooterWrapper";
import { ChartOptions } from "components-ui/src/charts/chart-options/ChartOptions";
import { type HighchartsReactProps } from "components-ui/src/charts/highcharts-react/HighchartsReact";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { HierarchyLevelIcon } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { ReportIcon } from "components-ui/src/icons";
import { useCallback, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ErrorBoundary from "../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { NoDataChartWrapper } from "../../common/components/NoDataChartWrapper";
import { type RootState } from "../../store";
import { ReportletTableId } from "../constants/basket-affinities-common";
import {
  selectAssociatedLevel,
  selectFocalItem,
  selectIsEmptyReport,
  selectLocalParametersSelection,
  selectPurchasedWithAssociated,
  selectPurchasedWithAssociatedHasProducts,
  selectUpliftInitialised,
  selectIsFetching,
  selectReportParameters,
} from "../services/basket-affinities-slice";
import { getExportParameterSummary } from "../utils/getExportParameterSummary";
import AffinitiesUpliftChart from "./AffinitiesUpliftChart";
import styles from "./BasketAffinitiesUpliftReportlet.module.css";
import { csvTransformation } from "./csvTransformationEnhanced";

export const BasketAffinitiesUpliftReportlet = () => {
  const focalItem = useSelector(selectFocalItem);
  const associatedLevel = useSelector(selectAssociatedLevel);
  const localParametersSelection = useSelector(selectLocalParametersSelection);
  const isUpliftTableIntialised = useSelector(selectUpliftInitialised);
  const isEmptyReport = useSelector(selectIsEmptyReport);
  const isDataFetching = useSelector(selectIsFetching);
  const isReportletTableHasData = useSelector(
    selectPurchasedWithAssociatedHasProducts
  );
  const reportParameters = useSelector(selectReportParameters);

  const chartContainerRef = useRef<HTMLElement>();
  const [currentOptions, setCurrentOptions] = useState<HighchartsReactProps>();

  const localParameters = useMemo(
    () => getExportParameterSummary(localParametersSelection, focalItem),
    [localParametersSelection, focalItem]
  );

  const purchasedWithAssociated = useSelector(selectPurchasedWithAssociated);
  const associatedItems = useMemo(
    () => purchasedWithAssociated.map((item) => item.product.name),
    [purchasedWithAssociated]
  );
  const upliftSeries = useMemo(
    () => purchasedWithAssociated.map((item) => item.uplift),
    [purchasedWithAssociated]
  );
  const basketsWithBothSeries = useMemo(
    () => purchasedWithAssociated.map((item) => item.basketsWithBoth),
    [purchasedWithAssociated]
  );

  // for export
  const chartCsvTransformationCallback = useCallback(
    () => csvTransformation(associatedLevel, purchasedWithAssociated),
    [associatedLevel, purchasedWithAssociated]
  );

  const exportFilename = useMemo(
    () =>
      cleanFilename(
        `Uplift_Chart_${localParametersSelection.timePeriodLength}_${localParametersSelection.location.name}`
      ),
    [localParametersSelection]
  );

  // Memoize the component for performance and to avoid freezing when changing
  // the affinities slider filter local parameter.
  const basketAffinitiesUpliftChart = useMemo(
    () => (
      <AffinitiesUpliftChart
        associatedItems={associatedItems}
        basketsWithBothSeries={basketsWithBothSeries}
        onOptionsChanged={setCurrentOptions}
        upliftSeries={upliftSeries}
      />
    ),
    [associatedItems, basketsWithBothSeries, upliftSeries]
  );

  const { reportName } = useSelector((state: RootState) => ({
    reportName: state.basketAffinities.reportName,
  }));

  return (
    <ErrorBoundary>
      <NoDataChartWrapper
        isLoading={!isUpliftTableIntialised || isDataFetching}
        minHeight="500px"
        noData={
          isEmptyReport || (isUpliftTableIntialised && !isReportletTableHasData)
        }
      >
        <div className={styles.chartOptionsContainer}>
          <ChartOptions
            downloadWizardOptions={
              currentOptions
                ? {
                    chartOptions: currentOptions,
                    reportIcon: (
                      <ReportIcon type={ReportType.BasketAffinities} />
                    ),
                    chartTitle: `Uplift chart - ${reportName}`,
                    reportTitle: reportName,
                  }
                : undefined
            }
            filename={exportFilename}
            getCSVData={chartCsvTransformationCallback}
            getElementToExport={() => chartContainerRef.current}
            isFeatureEnabled
            localParameters={localParameters}
            reportParameters={reportParameters}
          />
        </div>
        <div className={styles.table} id={`${ReportletTableId}-uplift`}>
          <div className={styles.focalItem}>
            <span className={styles.detailsFor}>Associated level: </span>
            <HierarchyLevelIcon
              shortName={associatedLevel.shortName}
              type={HierarchyItemType.Attribute}
            />
            {associatedLevel.displayName}
          </div>
          <ChartFooterWrapper
            height="420px"
            parameters={localParameters}
            ref={chartContainerRef}
          >
            {basketAffinitiesUpliftChart}
          </ChartFooterWrapper>
        </div>
      </NoDataChartWrapper>
    </ErrorBoundary>
  );
};
