import { Checkbox, Toggle, ToggleSize, Tag, TagVariant } from "@qbit/react";
import { type MetricsOptionDto } from "@quantium-enterprise/common-ui";
import { HierarchyItemType, ParameterId } from "@quantium-enterprise/common-ui";
import {
  type ColumnDef,
  type Row,
  type RowSelectionState,
  type CellContext,
} from "@tanstack/react-table";
import classNames from "classnames";
import { EmptySearch } from "components-ui/src/search/EmptySearch";
import { ExpandableNameCell } from "components-ui/src/tables/common/table-cell/ExpandableNameCell";
import { ReportHierarchyTable } from "components-ui/src/tables/report-hierarchy-table/ReportHierarchyTable";
import { ReportHierarchyTableWrapper } from "components-ui/src/tables/report-hierarchy-table/components/ReportHierarchyTableWrapper";
import { useCallback, useMemo, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  metricGroupExpanded,
  toggleShowSelectedMetrics,
  metricSelected,
  metricsBulkSelected,
  selectMetricsExpandedGroups,
  selectMetricsIsSelectedItemsShown,
  selectMetricsItems,
  selectMetricsSearchString,
  selectMetricsSelected,
} from "../../states/report-wizard-slice";
import { type RootState } from "../../store";
import styles from "./MetricsParameterTable.module.scss";
import { isMetricGroup } from "./MetricsState";

type MetricsTableHeaderProps = {
  isSelectedItemsShown: boolean;
  isToggleButtonDisabled: boolean;
  onHeaderCheckboxChange: () => void;
  onToggleShowSelected: () => void;
  selectedMetrics: MetricsOptionDto[];
  toggleModeMetrics: MetricsOptionDto[];
};

const MetricsTableHeader = ({
  isSelectedItemsShown,
  selectedMetrics,
  toggleModeMetrics,
  onHeaderCheckboxChange,
  onToggleShowSelected,
  isToggleButtonDisabled,
}: MetricsTableHeaderProps) => (
  <div className={styles.metricsTableHeader}>
    <div className={styles.headerTextAndCheckbox}>
      {isSelectedItemsShown && (
        <Checkbox
          checked={selectedMetrics.length > 0}
          indeterminate={
            selectedMetrics.length > 0 &&
            selectedMetrics.length < toggleModeMetrics.length
          }
          label=""
          name="allMetricsSelectedCheckbox"
          onChange={onHeaderCheckboxChange}
        />
      )}
      <span className={styles.headerText}>Metrics</span>
    </div>
    <div className={styles.selectedItemsToggle}>
      <Toggle
        checked={isSelectedItemsShown}
        disabled={isToggleButtonDisabled}
        label="Show selected item(s)"
        onClick={onToggleShowSelected}
        size={ToggleSize.XSmall}
      />
      <Tag
        className={classNames(styles.selectedItemsCount, {
          [styles.selectedItemsCountDisabled]: selectedMetrics.length === 0,
          [styles.selectedItemsCountEnabled]: selectedMetrics.length > 0,
        })}
        text={selectedMetrics.length.toString()}
        variant={TagVariant.Lozenge}
      />
    </div>
  </div>
);

type MetricsTableCellProps = {
  expandedGroups: string[];
  isSelectedItemsShown: boolean;
  onToggleExpansionHandler: (row: Row<MetricsOptionDto>) => void;
  onToggleSelectionHandler: (row: Row<MetricsOptionDto>) => void;
  row: Row<MetricsOptionDto>;
  searchString: string;
};

const MetricsTableCell = ({
  row,
  searchString,
  isSelectedItemsShown,
  expandedGroups,
  onToggleExpansionHandler,
  onToggleSelectionHandler,
}: MetricsTableCellProps) => {
  const isSearchMode = searchString.length > 0;
  const isGroup = !isSearchMode && isMetricGroup(row.original);

  const cellProps = {
    canExpand:
      !isSelectedItemsShown && isGroup && row.original.metricList.length > 0,
    depth: isSearchMode ? 0 : isSelectedItemsShown ? 0 : row.depth,
    handleToggleExpanded: () => onToggleExpansionHandler(row),
    handleToggleSelected: () => onToggleSelectionHandler(row),
    isExpanded:
      !isSelectedItemsShown && isGroup
        ? expandedGroups.includes(row.original.value)
        : false,
    isSelected: row.getIsSelected(),
    name: row.original.value,
    value: row.original.label,
    hideHierarchyIcon: true,
    type: HierarchyItemType.Hierarchy,
    isCompact: true,
    isExpanding: false,
  };

  return <ExpandableNameCell shortName="" {...cellProps} />;
};

type MetricsParameterTableProps = {
  isSuccess: boolean;
};

const parameterType = ParameterId.Metrics;

export const MetricsParameterTable = ({
  isSuccess,
}: MetricsParameterTableProps) => {
  const dispatch = useDispatch();
  const toggleModeMetrics = useRef<MetricsOptionDto[]>([]);
  const isTabSwitching = useRef(false);

  const items = useSelector((state: RootState) =>
    selectMetricsItems(parameterType, state)
  );
  const selectedMetrics = useSelector((state: RootState) =>
    selectMetricsSelected(parameterType, state)
  );
  const isSelectedItemsShown = useSelector((state: RootState) =>
    selectMetricsIsSelectedItemsShown(parameterType, state)
  );
  const expandedGroups = useSelector((state: RootState) =>
    selectMetricsExpandedGroups(parameterType, state)
  );
  const searchString = useSelector((state: RootState) =>
    selectMetricsSearchString(parameterType, state)
  );

  // Store the initial selected metrics when switching to selected view
  useEffect(() => {
    if (isSelectedItemsShown && toggleModeMetrics.current.length === 0) {
      toggleModeMetrics.current = selectedMetrics;
    }
  }, [isSelectedItemsShown, selectedMetrics]);

  // Update toggleModeMetrics when switching tabs
  useEffect(() => {
    if (!isSelectedItemsShown) {
      isTabSwitching.current = true;
      toggleModeMetrics.current = selectedMetrics;
    }
  }, [selectedMetrics, isSelectedItemsShown]);

  // Reset tab switching flag and update toggleModeMetrics
  useEffect(() => {
    if (isTabSwitching.current && isSelectedItemsShown) {
      isTabSwitching.current = false;
      toggleModeMetrics.current = selectedMetrics;
    }
  }, [isSelectedItemsShown, selectedMetrics]);

  const tableData = useMemo(() => {
    if (isSelectedItemsShown) {
      const uniqueMetrics = [
        ...new Map(
          (toggleModeMetrics.current.length > 0
            ? toggleModeMetrics.current
            : selectedMetrics
          ).map((metric) => [metric.value, metric])
        ).values(),
      ];
      return uniqueMetrics;
    }

    if (searchString.length > 0) {
      const searchTerm = searchString.toLowerCase().trim();
      const allMetrics = items.flatMap((group) =>
        group.metricList.map((metric) => ({
          ...metric,
          originalGroup: group.value,
        }))
      );

      return allMetrics.filter((metric) =>
        metric.label.toLowerCase().includes(searchTerm)
      );
    }

    return items;
  }, [isSelectedItemsShown, searchString, items, selectedMetrics]);

  const rowSelectionState = useMemo<RowSelectionState>(() => {
    const selectionState: Record<string, boolean> = {};

    for (const metric of selectedMetrics) {
      selectionState[metric.value] = true;
    }

    if (!isSelectedItemsShown && !searchString.length) {
      for (const group of items) {
        selectionState[group.value] = group.metricList.every((metric) =>
          selectedMetrics.some((selected) => selected.value === metric.value)
        );
      }
    }

    return selectionState;
  }, [selectedMetrics, items, isSelectedItemsShown, searchString]);

  const rowExpansionState = useMemo(() => {
    if (isSelectedItemsShown || searchString.length > 0) return {};

    const expansionState: Record<string, boolean> = {};
    for (const groupId of expandedGroups) {
      expansionState[groupId] = true;
    }

    return expansionState;
  }, [expandedGroups, isSelectedItemsShown, searchString]);

  const onToggleExpansionHandler = useCallback(
    (row: Row<MetricsOptionDto>) => {
      if (
        !isSelectedItemsShown &&
        !searchString.length &&
        isMetricGroup(row.original)
      ) {
        dispatch(
          metricGroupExpanded({
            parameterId: parameterType,
            groupId: row.original.value,
          })
        );
      }
    },
    [dispatch, isSelectedItemsShown, searchString]
  );

  const onToggleSelectionHandler = useCallback(
    (row: Row<MetricsOptionDto>) => {
      const isSelected = !row.getIsSelected();
      const original = row.original;

      if (!searchString.length && isMetricGroup(original)) {
        const uniqueMetrics = [
          ...new Map(
            [...selectedMetrics, ...original.metricList].map((metric) => [
              metric.value,
              metric,
            ])
          ).values(),
        ];

        const newMetrics = isSelected
          ? uniqueMetrics
          : selectedMetrics.filter(
              (metric) =>
                !original.metricList.some((gm) => gm.value === metric.value)
            );

        dispatch(
          metricsBulkSelected({
            parameterId: parameterType,
            metrics: newMetrics,
          })
        );
      } else {
        dispatch(
          metricSelected({
            parameterId: parameterType,
            metric: original,
            selected: isSelected,
          })
        );
      }
    },
    [dispatch, selectedMetrics, searchString]
  );

  const handleToggleShowSelected = useCallback(() => {
    if (!isSelectedItemsShown) {
      toggleModeMetrics.current = selectedMetrics;
    }

    dispatch(toggleShowSelectedMetrics({ parameterType }));
  }, [dispatch, isSelectedItemsShown, selectedMetrics]);

  const isToggleButtonDisabled = useMemo(
    () =>
      selectedMetrics.length === 0 &&
      (!isSelectedItemsShown || toggleModeMetrics.current.length === 0),
    [selectedMetrics.length, isSelectedItemsShown]
  );

  const handleHeaderCheckboxChange = useCallback(() => {
    if (isSelectedItemsShown) {
      dispatch(
        metricsBulkSelected({
          parameterId: parameterType,
          metrics: selectedMetrics.length > 0 ? [] : toggleModeMetrics.current,
        })
      );
    } else {
      const visibleMetrics =
        searchString.length > 0
          ? tableData
          : tableData.flatMap((item) =>
              isMetricGroup(item) ? item.metricList : [item]
            );

      const allVisibleSelected = visibleMetrics.every((metric) =>
        selectedMetrics.some((selected) => selected.value === metric.value)
      );

      if (allVisibleSelected) {
        const newSelection = selectedMetrics.filter(
          (selected) =>
            !visibleMetrics.some((metric) => metric.value === selected.value)
        );
        dispatch(
          metricsBulkSelected({
            parameterId: parameterType,
            metrics: newSelection,
          })
        );
      } else {
        const newSelection = [
          ...new Set([...selectedMetrics, ...visibleMetrics]),
        ];
        dispatch(
          metricsBulkSelected({
            parameterId: parameterType,
            metrics: newSelection,
          })
        );
      }
    }
  }, [
    dispatch,
    tableData,
    selectedMetrics,
    isSelectedItemsShown,
    searchString,
  ]);

  const getSubRows = (row: MetricsOptionDto): MetricsOptionDto[] => {
    if (isSelectedItemsShown || searchString.length > 0) {
      return [];
    }

    return isMetricGroup(row) ? row.metricList : [];
  };

  const renderHeader = useCallback(
    () => (
      <MetricsTableHeader
        isSelectedItemsShown={isSelectedItemsShown}
        isToggleButtonDisabled={isToggleButtonDisabled}
        onHeaderCheckboxChange={handleHeaderCheckboxChange}
        onToggleShowSelected={handleToggleShowSelected}
        selectedMetrics={selectedMetrics}
        toggleModeMetrics={toggleModeMetrics.current}
      />
    ),
    [
      isSelectedItemsShown,
      isToggleButtonDisabled,
      handleHeaderCheckboxChange,
      handleToggleShowSelected,
      selectedMetrics,
    ]
  );

  const renderCell = useCallback(
    (context: CellContext<MetricsOptionDto, unknown>) => (
      <MetricsTableCell
        expandedGroups={expandedGroups}
        isSelectedItemsShown={isSelectedItemsShown}
        onToggleExpansionHandler={onToggleExpansionHandler}
        onToggleSelectionHandler={onToggleSelectionHandler}
        row={context.row}
        searchString={searchString}
      />
    ),
    [
      expandedGroups,
      isSelectedItemsShown,
      onToggleExpansionHandler,
      onToggleSelectionHandler,
      searchString,
    ]
  );

  const columns = useMemo<Array<ColumnDef<MetricsOptionDto>>>(
    () => [
      {
        id: "metrics",
        accessorKey: "label",
        enableSorting: false,
        header: renderHeader,
        cell: renderCell,
      },
    ],
    [renderHeader, renderCell]
  );

  return (
    <div className={styles.metricsParameter}>
      <ReportHierarchyTableWrapper isSuccess={isSuccess}>
        <ReportHierarchyTable
          className={styles.table}
          columns={columns}
          compactRows
          data={tableData}
          depthPadding={24}
          getRowId={(row) => row.value}
          getSubRows={getSubRows}
          rowExpandedState={rowExpansionState}
          rowSelectionState={rowSelectionState}
        />
        {searchString.length > 0 && tableData.length === 0 && (
          <div className={styles.noResults}>
            <EmptySearch />
          </div>
        )}
      </ReportHierarchyTableWrapper>
    </div>
  );
};
