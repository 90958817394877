import {
  Tooltip,
  TooltipPlacement,
  TooltipSpaceInside,
  Button,
  ButtonVariant,
  TooltipVariant,
} from "@qbit/react";
import styles from "../GroupInfoPanelHeader.module.css";

export type ActionButtonProps = {
  disabled?: boolean;
  icon: JSX.Element;
  label: string;
  onClick: () => void;
  tooltipText?: string;
};

export const ActionButton = ({
  disabled,
  icon,
  label,
  onClick,
  tooltipText,
}: ActionButtonProps) => (
  <Tooltip
    placement={TooltipPlacement.TopCentre}
    spaceInside={TooltipSpaceInside.Small}
    trigger={
      <Button
        aria-label={label}
        className={styles.refreshButton}
        disabled={disabled}
        onClick={onClick}
        variant={ButtonVariant.Stealth}
      >
        {icon}
      </Button>
    }
    variant={TooltipVariant.ArrowDark}
  >
    {tooltipText ?? label}
  </Tooltip>
);
