import { type ReactElement } from "react";
import { ParameterGroup } from "../model/ParameterGroup";
import {
  Time,
  Customer,
  LevelOfAnalysis,
  Location,
  Products,
  Structure,
  Transaction,
  Rules,
  Metrics,
} from "./images";

export const getIconComponent = (tabName: string) => {
  const iconTypesMap: Record<string, ReactElement> = {
    [ParameterGroup.Customer]: <Customer />,
    [ParameterGroup.LevelOfAnalysis]: <LevelOfAnalysis />,
    [ParameterGroup.Location]: <Location />,
    [ParameterGroup.Products]: <Products />,
    [ParameterGroup.Rules]: <Rules />,
    [ParameterGroup.Structure]: <Structure />,
    [ParameterGroup.Time]: <Time />,
    [ParameterGroup.Transaction]: <Transaction />,
    [ParameterGroup.Associated]: <Products />,
    [ParameterGroup.Focal]: <Products />,
    [ParameterGroup.Metrics]: <Metrics />,
  };

  if (Object.keys(iconTypesMap).includes(tabName)) {
    return iconTypesMap[tabName];
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};
