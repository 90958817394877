import { Group, Item, ItemValign } from "@qbit/react";
import {
  type HierarchyGroupDto,
  type HierarchyGroupEvaluationType,
  type HierarchyType,
} from "@quantium-enterprise/common-ui";
import {
  formatHierarchyName,
  formatStringDate,
  getTransactionSourceFromEntitlements,
} from "@quantium-enterprise/common-ui";
import { type SubscriptionDto } from "@quantium-enterprise/common-ui/src/models/subscription-dto";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import {
  HierarchyGroupIcon,
  TransactionSourceIcon,
} from "components-ui/src/icons";
import styles from "./GroupSummaryBar.module.css";
import { TwoPanelForm } from "./two-panel-form/TwoPanelForm";

const { Details } = TwoPanelForm;

export type GroupSummaryBarProps = {
  existingGroup?: HierarchyGroupDto;
  groupType: HierarchyGroupEvaluationType;
  hierarchyType: HierarchyType;
  itemsCount: number;
  subscription?: SubscriptionDto;
};

export const GroupSummaryBar = ({
  groupType,
  hierarchyType,
  existingGroup,
  itemsCount,
  subscription,
}: GroupSummaryBarProps) => {
  const { transactionSources } = useDivision();

  return (
    <Details>
      <Group>
        <Item valign={ItemValign.Top}>
          <div className={styles.detailHeader}>Group type</div>
          <div className={styles.detailContent}>
            <span className={styles.detailIcon}>
              <HierarchyGroupIcon evaluationType={groupType} />
            </span>
            <span>{groupType}</span>
          </div>
        </Item>
        <Item valign={ItemValign.Top}>
          <div className={styles.detailHeader}>
            <span>Total {formatHierarchyName(hierarchyType, false, true)}</span>
          </div>
          <div className={styles.detailContent}>
            <span>{itemsCount}</span>
          </div>
        </Item>
        <Item valign={ItemValign.Top}>
          <div className={styles.detailHeader}>Created</div>
          <div className={styles.detailContent}>
            {existingGroup
              ? formatStringDate(existingGroup.createDateUtc, {
                  locale: "en-AU",
                  dropdown: true,
                })
              : "-"}
          </div>
        </Item>
        <Item valign={ItemValign.Top}>
          <div className={styles.detailHeader}>Updated</div>
          <div className={styles.detailContent}>
            {existingGroup
              ? formatStringDate(existingGroup.updateDateUtc, {
                  locale: "en-AU",
                  dropdown: true,
                })
              : "-"}
          </div>
        </Item>
        {subscription && (
          <Item valign={ItemValign.Top}>
            <div className={styles.detailHeader}>Dataset</div>
            <div className={styles.detailContent}>
              <TransactionSourceIcon
                availableTransactionSources={transactionSources}
                greyedOut={false}
                transactionSource={getTransactionSourceFromEntitlements([
                  subscription.transactionSource,
                ])}
              />
            </div>
          </Item>
        )}
      </Group>
    </Details>
  );
};
