import {
  useEventTrackingServiceContext,
  GenericTrackingProperties,
  GroupsTrackingProperty,
  HierarchyType,
  TrackingComponent,
  TrackingEvent,
  FeatureFlag,
  useGetOrganizationUsersQuery,
  useGetUsersByIdQuery,
  useShareGroupMutation,
  useUnshareGroupMutation,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import { ShareModal } from "components-ui/src/modals";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectShareFolderOrGroup,
  selectShowManageAccess,
  setShareFolderOrGroup,
  setShowManageAccess,
} from "../../states/group-list-slice";
import { isFolder } from "../../utilities/folder-helper";

type ShareHierarchyGroupModalProps = {
  hierarchyType: HierarchyType;
};

export const ShareHierarchyGroupModal = ({
  hierarchyType,
}: ShareHierarchyGroupModalProps) => {
  const dispatch = useDispatch();
  const eventTrackingService = useEventTrackingServiceContext();
  const flags = useFlags();
  const enableTrueShare = flags[FeatureFlag.ProductGroupsTrueShare];
  const [shareGroup] = useShareGroupMutation();
  const [unshareGroup] = useUnshareGroupMutation();
  const division = useDivision();
  const shareFolderOrGroup = useSelector(selectShareFolderOrGroup);
  const showManageAccess = useSelector(selectShowManageAccess);

  const entityType = isFolder(shareFolderOrGroup) ? "Folder" : "Group";
  const trackingComponent =
    hierarchyType === HierarchyType.Product
      ? TrackingComponent.ProductGroup
      : TrackingComponent.LocationGroup;
  const groupId = shareFolderOrGroup?.id;
  const groupName = shareFolderOrGroup?.name;
  const ownerId = shareFolderOrGroup?.userId;
  const sharedWithUserIds = shareFolderOrGroup?.sharedWithUserIds;

  const { data: organisationUsers } = useGetOrganizationUsersQuery({
    divisionName: division.name,
  });
  const { data: owner } = useGetUsersByIdQuery(
    {
      divisionName: division.name,
      payload: {
        SalesforceUserIds: ownerId ? [ownerId] : [],
      },
    },
    {
      skip: !ownerId,
    }
  );
  const shareGroupCallback = useCallback(
    async (
      divisionName: string,
      salesforceUserIds: string[],
      sharingNotes: string
    ) => {
      for (const salesforceUserId of salesforceUserIds) {
        eventTrackingService.trackEvent(
          trackingComponent,
          TrackingEvent.Shared,
          new GenericTrackingProperties({
            groupId,
            [GroupsTrackingProperty.SharedWith]: salesforceUserId,
            division: divisionName,
            [GroupsTrackingProperty.EntityType]: entityType,
          })
        );
      }

      return await shareGroup({
        divisionName,
        payload: {
          entityType,
          userIds: salesforceUserIds,
          sharingNotes,
          sourceId: groupId ?? "",
        },
      });
    },
    [shareGroup, groupId, entityType, eventTrackingService, trackingComponent]
  );
  const unshareGroupCallback = useCallback(
    async (salesforceUserId: string) => {
      eventTrackingService.trackEvent(
        trackingComponent,
        TrackingEvent.Unshared,
        new GenericTrackingProperties({
          groupId,
          [GroupsTrackingProperty.UnsharedWith]: salesforceUserId,
          division: division.name,
          [GroupsTrackingProperty.EntityType]: entityType,
        })
      );

      return await unshareGroup({
        divisionName: division.name,
        payload: {
          entityType,
          userIds: [salesforceUserId],
          sharingNotes: "",
          sourceId: groupId ?? "",
        },
      });
    },
    [
      unshareGroup,
      groupId,
      entityType,
      division,
      eventTrackingService,
      trackingComponent,
    ]
  );

  return (
    <ShareModal
      enableTrueShare={enableTrueShare}
      onClose={() => {
        dispatch(setShareFolderOrGroup(undefined));
      }}
      organisationUsers={organisationUsers}
      owner={owner ? owner[0] : undefined}
      resourceType={entityType.toLowerCase()}
      setShowManageAccessExternal={(value) =>
        dispatch(setShowManageAccess(value))
      }
      share={shareGroupCallback}
      sharedWithUserIds={sharedWithUserIds}
      showManageAccessExternal={showManageAccess}
      showModal={Boolean(shareFolderOrGroup)}
      subtitle={entityType.toUpperCase()}
      title={groupName ?? ""}
      unshare={unshareGroupCallback}
    />
  );
};
