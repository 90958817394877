/* eslint-disable complexity */
import {
  Button,
  ButtonHeight,
  ButtonVariant,
  type FormBlockEditability,
  Icon,
  IconGlyph,
  IconSize,
  InlineIcon,
  InlineIconGlyph,
  Text,
} from "@qbit/react";
import { type Option } from "@qbit/react/dist/typeahead";
import {
  type HierarchySliceNodeDto,
  type ListSelectionDto,
  type LocalGroupSelection,
  type LocalHierarchySelection,
  type LocalHierarchyNodeSelection,
  type TimePeriodSelectionDto,
  TrackingEvent,
  TrackingComponent,
  useGetUserQuery,
} from "@quantium-enterprise/common-ui";
import {
  useDivision,
  type FormatterFunction,
} from "@quantium-enterprise/hooks-ui";
import classNames from "classnames";
import { useContext, useState } from "react";
import CollapsiblePanel from "../local-filters/CollapsiblePanel";
import {
  type HierarchySelectLevel,
  HierarchySelectFilter,
} from "../local-filters/HierarchySelectFilter";
import { LocationHierarchyFilter } from "../local-filters/LocationHierarchyFilter";
import MultiSelectFilter from "../local-filters/MultiSelectFilter";
import NoEditingPanel from "../local-filters/NoEditingPanel";
import SingleSelectFilter, {
  type SingleSelectType,
} from "../local-filters/SingleSelectFilter";
import { TimePeriodFilter } from "../local-filters/TimePeriodFilter";
import { type FixedLocationHierarchyFilterProps } from "../local-filters/fixedLocationHierarchyFilter/FixedLocationHierarchyFilter";
import FixedLocationHierarchyFilter from "../local-filters/fixedLocationHierarchyFilter/FixedLocationHierarchyFilter";
import {
  type IndexAgainstOption,
  type MetricOption,
} from "../local-filters/indexedMetricFilter/IndexedMetricFilter";
import IndexMetricFilter from "../local-filters/indexedMetricFilter/IndexedMetricFilter";
import { MultiLocationHierarchyFilter } from "../local-filters/location-hierarchy-filter/multi-location-hierarchy-filter/MultiLocationHierarchyFilter";
import MultiCheckbox from "../local-filters/multiCheckboxFilter/MultiCheckbox";
import MultiSelectSegmentFilterWrapper from "../local-filters/multiSelectSegmentFilter/MultiSelectSegmentFilterWrapper";
import { type SegmentOption } from "../local-filters/segmentFilter/SegmentFilter";
import SegmentFilter from "../local-filters/segmentFilter/SegmentFilter";
import { SliderFilter } from "../local-filters/sliderFilter/SliderFilter";
import { ReportViewContext } from "../report/ReportView";
import { CollapseSidePanelIcon } from "./CollapseSidePanelIcon";
import styles from "./FixedSidePanel.module.scss";

// The type of panels to show.
// Hierarchy - Show a hierarchical list of options, such as location
// Single - Show a single value that can be selected, such as a radio button list, or a dropdown
// Multi - Multiple selection list, such as checkboxes or a MultiSelect typeahead.
// Text - Plain text that can be collapsed
// Segmentation - Show segmentation dropdown with segment select radio
export enum PanelType {
  HIERARCHY = 1,
  SINGLE = 2,
  MULTI = 3,
  TEXT = 4,
  TIME_PERIOD = 5,
  SEGMENTATION = 6,
  INDEXED_METRIC = 7,
  PLAIN_TEXT_CONTENT = 8,
  LOCATION = 9,
  MULTI_CHECKBOX = 10,
  MULTI_SELECT_LOCATION = 11,
  MULTI_SELECT_CUSTOMER_SEGMENTATION = 12,
  FIXED_LOCATION = 13,
  SLIDER = 14,
}

export type BasePanel = {
  content?: JSX.Element;
  id: string;
  label: string;
  panelType: PanelType;
  summary?: JSX.Element | string;
};

export type FixedLocationHierarchyPanel = BasePanel & {
  hasShortNamePrefix?: boolean;
  locationGroupSelections?: LocalGroupSelection[];
  locationHierarchySelections: LocalHierarchySelection[];
};

export type LocationHierarchySelectPanel = BasePanel & {
  allSelections: LocalHierarchyNodeSelection[];
  onSelection: (selection: LocalHierarchyNodeSelection) => void;
  selectedValue: LocalHierarchyNodeSelection;
};

export type MultiLocationHierarchySelectPanel = BasePanel & {
  allSelections: LocalHierarchyNodeSelection[];
  onSubmit: (selection: LocalHierarchyNodeSelection[]) => void;
  selectedValues: LocalHierarchyNodeSelection[];
};

export type HierarchySelectPanel = BasePanel & {
  levels: HierarchySelectLevel[];
  onSelection: (item: string, level?: string) => void;
  selectedLevel: string;
  selectedValue: string;
};

export type SingleSelectPanel = BasePanel & {
  displayFieldType: SingleSelectType;
  onSelection: (value: PanelOption) => void;
  selectedValue: string;
  selections: PanelOption[];
};

export type PanelOption = {
  label: string;
  // optional custom element that will be displayed instead of the label if provided
  // i.e. we have special labels for "All" options for Channel and Promotion to add
  // tooltips explaining exactly what "All" means in those contexts.
  labelElement?: JSX.Element;
  optionDisabled?: boolean;
  value: number | string;
};

export type MultiSelectPanel = BasePanel & {
  maxSelected: number | undefined;
  onSelection: (values: Option[]) => void;
  selectedValues: string[];
  selections: Option[];
};

export type TimePeriodPanel = BasePanel & {
  comparisonPeriod?: TimePeriodSelectionDto;
  focusPeriod: TimePeriodSelectionDto;
  leadPeriod?: TimePeriodSelectionDto;
  rollingPeriod?: ListSelectionDto;
};

export type TextPanel = BasePanel & {
  // Text panel specific fields
};

export type SegmentationSelectPanel = BasePanel & {
  isDisabled?: boolean;
  onSelection: (value: SegmentOption) => void;
  secondaryTitle?: string;
  segmentOptions: SegmentOption[];
  selectedValues: string[];
};

export type MultiSegmentationSelectPanel = BasePanel & {
  onSelection: (values: SegmentOption[]) => void;
  secondaryTitle?: string;
  segmentOptions: SegmentOption[];
  selectedValues: string[];
};

export type IndexedMetricPanel = BasePanel & {
  filterIndexAgainst?: (option: IndexAgainstOption) => boolean;
  focalItems: HierarchySliceNodeDto[];
  indexAgainstOptions: IndexAgainstOption[];
  metricOptions: MetricOption[];
  onSelection: (metric: PanelOption, indexedAgainst: PanelOption) => void;
  secondaryTitle?: string;
  selectedIndexedAgainstValue: string;
  selectedMetricValue: PanelOption;
};

export type PlainTextContentPanel = BasePanel & {
  plainTextContent: string;
};

export type MultiCheckboxPanel = BasePanel & {
  isDisabled: (value: string) => boolean;
  onSelection: (value: PanelOption) => void;
  options: PanelOption[];
  selected: string[];
  subtitle: string;
  summary: string;
};

export type SliderPanel = BasePanel & {
  currentValue?: number;
  editability: FormBlockEditability;
  formatter?: FormatterFunction;
  maxValue: number;
  minValue: number;
  onChange: (value: number) => void;
  percentageThresholds?: { maximum: number; minimum: number };
  stepSize?: number;
  summary?: string;
};

export type Panel =
  | HierarchySelectPanel
  | IndexedMetricPanel
  | MultiCheckboxPanel
  | MultiSelectPanel
  | PlainTextContentPanel
  | SegmentationSelectPanel
  | SingleSelectPanel
  | TextPanel;

// Typeguards used for type narrowing
export const isHierarchyPanel = (panel: Panel): panel is HierarchySelectPanel =>
  Object.prototype.hasOwnProperty.call(panel, "levels") &&
  Object.prototype.hasOwnProperty.call(panel, "selectedLevel") &&
  Object.prototype.hasOwnProperty.call(panel, "selectedValue");

export const getMaxSelectionText = (
  maxSelected: number,
  totalSelections: number
) => {
  // qbit multiselect typeahead will not allow selection if maxSelected is 0
  if (maxSelected === 0 || totalSelections === 0) {
    return "";
  }

  return maxSelected === totalSelections
    ? "Add selection"
    : `Add selection (${maxSelected} max)`;
};

const SidePanel = ({
  panels,
  eventTrackingService,
}: {
  eventTrackingService?: Function;
  panels: Panel[];
}) => {
  const sidebarContext = useContext(ReportViewContext);
  const [panelsCollapseStatus, setPanelsCollapseStatus] = useState<boolean[]>(
    panels.map(() => false)
  );

  const { name: divisionName } = useDivision();
  const { data: user } = useGetUserQuery();

  const parameterTrackingService = (
    parameterName: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selection: Record<string, any> | string
  ) =>
    eventTrackingService?.(
      [
        TrackingComponent.MyReports,
        TrackingComponent.Report,
        TrackingComponent.LocalParameterPanel,
        TrackingComponent.Item,
      ],
      TrackingEvent.Selected,
      {
        parameter: parameterName,
        selection,
        division: divisionName,
        user: user?.isSupplier ? "Supplier" : "Retailer",
      }
    );

  const isAllPanelsCollapsed = () =>
    panels.every(
      (panel, index) =>
        panelsCollapseStatus[index] === true ||
        panel.panelType === PanelType.TEXT
    );

  const toggleCollapsed = (index: number, label: string) => {
    const newCollapseStatus = [...panelsCollapseStatus];
    newCollapseStatus[index] = !newCollapseStatus[index];
    setPanelsCollapseStatus(newCollapseStatus);

    eventTrackingService?.(
      [
        TrackingComponent.MyReports,
        TrackingComponent.Report,
        TrackingComponent.LocalParameterPanel,
        TrackingComponent.Item,
      ],
      newCollapseStatus[index]
        ? TrackingEvent.Collapsed
        : TrackingEvent.Expanded,
      {
        parameter: label,
        division: divisionName,
        user: user?.isSupplier ? "Supplier" : "Retailer",
      }
    );
  };

  const toggleAllCollapse = (isCollapsed: boolean) => {
    const newCollapseStatus = [...panelsCollapseStatus];
    for (const [index] of panels.entries()) {
      newCollapseStatus[index] = isCollapsed;
    }

    setPanelsCollapseStatus(newCollapseStatus);
  };

  const handleExpandButton = () => {
    toggleAllCollapse(!isAllPanelsCollapsed());

    eventTrackingService?.(
      [
        TrackingComponent.MyReports,
        TrackingComponent.Report,
        TrackingComponent.LocalParameterPanel,
      ],
      isAllPanelsCollapsed() ? TrackingEvent.Collapsed : TrackingEvent.Expanded,
      {
        division: divisionName,
        user: user?.isSupplier ? "Supplier" : "Retailer",
      }
    );
  };

  const children = panels.map((panel, index) => {
    const key = `panel-${panel.id.toLowerCase()}`;
    switch (panel.panelType) {
      case PanelType.LOCATION:
        return (
          <LocationHierarchyFilter
            allSelections={
              (panel as LocationHierarchySelectPanel).allSelections
            }
            isCollapsed={panelsCollapseStatus[index]}
            key={key}
            // eslint-disable-next-line react/jsx-handler-names -- incorrectly identified as handler
            onSelection={(selections) => {
              parameterTrackingService(
                "Location",
                `${selections.shortName}:${selections.name}`
              );
              (panel as LocationHierarchySelectPanel).onSelection(selections);
            }}
            selectedValue={
              (panel as LocationHierarchySelectPanel).selectedValue
            }
            title="Location"
            toggleCollapsed={() => toggleCollapsed(index, panel.label)}
          />
        );
      case PanelType.HIERARCHY:
        return (
          <HierarchySelectFilter
            isCollapsed={panelsCollapseStatus[index]}
            key={key}
            levels={(panel as HierarchySelectPanel).levels}
            onSelection={(item, level) => {
              (panel as HierarchySelectPanel).onSelection(item, level);
              parameterTrackingService(
                "Location",
                `${
                  level ?? (panel as HierarchySelectPanel).selectedLevel
                }:${item}`
              );
            }}
            selectedItem={(panel as HierarchySelectPanel).selectedValue}
            title={panel.label}
            toggleCollapsed={() => toggleCollapsed(index, panel.label)}
          />
        );

      case PanelType.SINGLE:
        return (
          <SingleSelectFilter
            isCollapsed={panelsCollapseStatus[index]}
            isDisabled={false}
            key={key}
            onSelection={(newValue: PanelOption) => {
              parameterTrackingService(panel.label, newValue.value as string);
              (panel as SingleSelectPanel).onSelection(newValue);
            }}
            selectOptions={(panel as SingleSelectPanel).selections}
            selectType={(panel as SingleSelectPanel).displayFieldType}
            selectedValue={(panel as SingleSelectPanel).selectedValue}
            title={panel.label}
            toggleCollapsed={() => {
              toggleCollapsed(index, panel.label);
            }}
          />
        );

      case PanelType.MULTI: {
        const totalSelections = (panel as MultiSelectPanel).selections.length;
        const maxSelected =
          (panel as MultiSelectPanel).maxSelected ?? totalSelections;
        const maxSelectedText = getMaxSelectionText(
          maxSelected,
          totalSelections
        );
        return (
          <MultiSelectFilter
            isCollapsed={panelsCollapseStatus[index]}
            isDisabled={false}
            key={key}
            maxSelected={maxSelected}
            noSelectionMessage={<>Nothing selected</>}
            onSelection={(newValues: Option[]) => {
              parameterTrackingService(
                panel.label,
                newValues.map(({ label }) => label ?? "")
              );
              (panel as MultiSelectPanel).onSelection(newValues);
            }}
            selectOptions={(panel as MultiSelectPanel).selections}
            selectPlaceholder={maxSelectedText}
            selectedItems={(panel as MultiSelectPanel).selectedValues}
            title={panel.label}
            toggleCollapsed={() => toggleCollapsed(index, panel.label)}
          />
        );
      }

      case PanelType.TIME_PERIOD:
        return (
          <TimePeriodFilter
            isCollapsed={panelsCollapseStatus[index]}
            key={key}
            panel={panel as TimePeriodPanel}
            title={panel.label}
            toggleCollapsed={() => {
              toggleCollapsed(index, panel.label);
            }}
          />
        );

      case PanelType.TEXT:
        return (
          <CollapsiblePanel
            isCollapsed={panelsCollapseStatus[index]}
            key={key}
            title={panel.label}
            toggleCollapsed={() => toggleCollapsed(index, panel.label)}
          >
            {{ content: panel.content ?? "", summary: panel.summary ?? "" }}
          </CollapsiblePanel>
        );

      case PanelType.SEGMENTATION:
        return (
          <SegmentFilter
            isCollapsed={panelsCollapseStatus[index]}
            isDisabled={(panel as SegmentationSelectPanel).isDisabled}
            key={key}
            onSelection={(newValues) => {
              const panelWithType = panel as SegmentationSelectPanel;
              const selectedOption = panelWithType.segmentOptions.find(
                (option) =>
                  option.segmentationValue === newValues[0] &&
                  option.segmentValue === newValues[1]
              );
              if (selectedOption) {
                parameterTrackingService(panel.label, newValues);
                panelWithType.onSelection(selectedOption);
              } else {
                throw new Error(
                  `Could not find value ${newValues} in options: [${panelWithType.segmentOptions
                    .map(
                      (option) =>
                        `${option.segmentationValue}:${option.segmentationValue}`
                    )
                    .join(",")}]`
                );
              }
            }}
            secondaryTitle={(panel as SegmentationSelectPanel).secondaryTitle}
            segmentOptions={(panel as SegmentationSelectPanel).segmentOptions}
            selectedValues={(panel as SegmentationSelectPanel).selectedValues}
            title={panel.label}
            toggleCollapsed={() => toggleCollapsed(index, panel.label)}
          />
        );

      case PanelType.INDEXED_METRIC:
        return (
          <IndexMetricFilter
            filterIndexAgainst={(option) => {
              const filterIndexAgainst = (panel as IndexedMetricPanel)
                .filterIndexAgainst;
              return !filterIndexAgainst || filterIndexAgainst(option);
            }}
            focalItems={(panel as IndexedMetricPanel).focalItems}
            indexAgainstOptions={
              (panel as IndexedMetricPanel).indexAgainstOptions
            }
            isCollapsed={panelsCollapseStatus[index]}
            key={key}
            metricOptions={(panel as IndexedMetricPanel).metricOptions}
            onSelection={(metric, indexedAgainst) => {
              (panel as IndexedMetricPanel).onSelection(metric, indexedAgainst);
              parameterTrackingService(
                panel.label,
                (panel as IndexedMetricPanel).metricOptions.find(
                  (x) => x.value === (metric.value as string)
                )?.label ?? "undefined"
              );
            }}
            secondaryTitle={(panel as IndexedMetricPanel).secondaryTitle}
            selectedIndexedAgainstValue={
              (panel as IndexedMetricPanel).selectedIndexedAgainstValue
            }
            selectedMetricValue={
              (panel as IndexedMetricPanel).selectedMetricValue
            }
            title={panel.label}
            toggleCollapsed={() => toggleCollapsed(index, panel.label)}
          />
        );

      case PanelType.PLAIN_TEXT_CONTENT:
        return (
          <NoEditingPanel key={key} title={panel.label}>
            {(panel as PlainTextContentPanel).plainTextContent}
          </NoEditingPanel>
        );

      case PanelType.MULTI_CHECKBOX:
        return (
          <MultiCheckbox
            checkboxOptions={(panel as MultiCheckboxPanel).options}
            isCollapsed={panelsCollapseStatus[index]}
            isDisabled={(panel as MultiCheckboxPanel).isDisabled}
            key={key}
            onSelect={(newValues) => {
              const panelWithType = panel as MultiCheckboxPanel;
              const selectedOption = panelWithType.options.find(
                (option) => option.value === newValues
              );
              if (selectedOption) {
                panelWithType.onSelection(selectedOption);
                parameterTrackingService(
                  panelWithType.label,
                  panelWithType.selected.includes(newValues)
                    ? panelWithType.selected.filter((x) => x !== newValues)
                    : panelWithType.selected.concat([newValues])
                );
              } else {
                throw new Error(
                  `Could not find value ${newValues} in options: [${panelWithType.options
                    .map((option) => option.value)
                    .join(",")}]`
                );
              }
            }}
            selected={(panel as MultiCheckboxPanel).selected}
            subtitle={(panel as MultiCheckboxPanel).subtitle}
            summary={(panel as MultiCheckboxPanel).summary}
            title={panel.label}
            toggleCollapsed={() => toggleCollapsed(index, panel.label)}
          />
        );

      case PanelType.MULTI_SELECT_LOCATION:
        return (
          <MultiLocationHierarchyFilter
            allSelections={
              (panel as MultiLocationHierarchySelectPanel).allSelections
            }
            isCollapsed={panelsCollapseStatus[index]}
            key={key}
            onSubmit={(selections) => {
              (panel as MultiLocationHierarchySelectPanel).onSubmit(selections);
            }}
            parameterTrackingService={(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              selection: Record<string, any> | string
            ) => parameterTrackingService("Location", selection)}
            selectedRows={
              (panel as MultiLocationHierarchySelectPanel).selectedValues
            }
            title={panel.label}
            toggleCollapsed={() => toggleCollapsed(index, panel.label)}
          />
        );

      case PanelType.MULTI_SELECT_CUSTOMER_SEGMENTATION:
        return (
          <MultiSelectSegmentFilterWrapper
            isCollapsed={panelsCollapseStatus[index]}
            key={key}
            onSelection={(values) => {
              const panelWithType = panel as MultiSegmentationSelectPanel;

              parameterTrackingService(panel.label, values);
              panelWithType.onSelection(values);
            }}
            segmentOptions={(panel as SegmentationSelectPanel).segmentOptions}
            selectedValues={(panel as SegmentationSelectPanel).selectedValues}
            title={panel.label}
            toggleCollapsed={() => toggleCollapsed(index, panel.label)}
          />
        );

      case PanelType.FIXED_LOCATION: {
        const localScopePanel = panels[index] as FixedLocationHierarchyPanel;

        let componentProperties: FixedLocationHierarchyFilterProps = {
          hierarchySelections: localScopePanel.locationHierarchySelections,
          isCollapsed: panelsCollapseStatus[index],
          hasShortNamePrefix: localScopePanel.hasShortNamePrefix,
          title: "Location",
          toggleCollapsed: () => toggleCollapsed(index, localScopePanel.label),
        };

        if (Object.keys(localScopePanel).includes("locationGroupSelections")) {
          componentProperties = {
            ...componentProperties,
            groupSelections: localScopePanel.locationGroupSelections,
          };
        }

        return (
          <FixedLocationHierarchyFilter key={key} {...componentProperties} />
        );
      }

      case PanelType.SLIDER: {
        const localScopePanel = panels[index] as SliderPanel;

        return (
          <SliderFilter
            currentValue={localScopePanel.currentValue}
            editability={localScopePanel.editability}
            endValues={{
              maximum: localScopePanel.maxValue,
              minimum: localScopePanel.minValue,
            }}
            formatter={localScopePanel.formatter}
            isCollapsed={panelsCollapseStatus[index]}
            key={key}
            onChange={(value: number) => localScopePanel.onChange(value)}
            percentageThresholds={
              localScopePanel.percentageThresholds ?? undefined
            }
            stepSize={localScopePanel.stepSize}
            summary={localScopePanel.summary}
            title={localScopePanel.label}
            toggleCollapsed={() =>
              toggleCollapsed(index, localScopePanel.label)
            }
          />
        );
      }

      default:
        return null;
    }
  });

  return (
    <div
      className={classNames(styles.panelContainer)}
      data-testid="panel-container"
    >
      <CollapseSidePanelIcon />
      <div
        className={classNames(styles.content, {
          [styles.collapsed]: sidebarContext.collapseSidePanel,
          [styles.expanded]: !sidebarContext.collapseSidePanel,
        })}
      >
        <div className={styles.panelHeader}>
          <span className={styles.flex}>
            <InlineIcon
              className={styles.marginRight}
              colour="var(--qbit-colour-black)"
              glyph={InlineIconGlyph.MenuAndSettingsTune}
              size={IconSize.Small}
              text="Local Parameters"
            />
            PARAMETERS
          </span>
          <Button
            className={styles.expandCollapseButton}
            data-testid="expand-collapse-button"
            height={ButtonHeight.XSmall}
            onClick={handleExpandButton}
            variant={ButtonVariant.Stealth}
          >
            <Icon
              colour="var(--qbit-colour-link)"
              data-testid="expand-collapse-icon"
              glyph={IconGlyph.ArrowsSortingUnsorted}
              text={
                isAllPanelsCollapsed()
                  ? "Expand all parameters"
                  : "Collapse all parameters"
              }
            />
            <Text className={styles.expandCollapseText}>
              {isAllPanelsCollapsed() ? "Expand all" : "Collapse all"}
            </Text>
          </Button>
        </div>
        <div className={styles.panelContent}>{children}</div>
      </div>
    </div>
  );
};

export { SidePanel };
