import {
  FormBlock,
  FormBlockStatus,
  FormBlockType,
  FormInputInputMode,
  Input,
  NumberInput,
} from "@qbit/react";
import { ParameterLabel } from "../../parameter-label/ParameterLabel";
import styles from "./NumberOfWeeksInput.module.scss";
import { NumberRequiredFieldError } from "./shared/number-required-field-error/NumberRequiredFieldError";

export type NumberOfWeeksInputProps = {
  blockStatus: FormBlockStatus;
  max?: number;
  min?: number;
  onChange: (value: number) => void;
  value?: number;
};

export const NumberOfWeeksInput = ({
  blockStatus,
  onChange,
  value,
  min,
  max,
}: NumberOfWeeksInputProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue: string = event.target.value;
    onChange(Number(newValue));
  };

  const label = max
    ? `No. of weeks (${min ?? 1} - ${max} weeks)`
    : "No. of weeks";

  return (
    <FormBlock
      blockStatus={blockStatus}
      blockType={FormBlockType.Select}
      className={styles.formBlock}
    >
      <ParameterLabel heading={label} htmlFor={label} />
      <Input>
        <NumberInput
          data-testid={label}
          id={label}
          inputMode={FormInputInputMode.Numeric}
          max={max}
          min={min}
          onChange={handleChange}
          step={1}
          value={value === undefined ? undefined : String(value)}
        />
        <>
          {blockStatus === FormBlockStatus.Error && (
            <NumberRequiredFieldError />
          )}
        </>
      </Input>
    </FormBlock>
  );
};

export default NumberOfWeeksInput;
