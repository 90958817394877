import classNames from "classnames";
import {
  type Chart as HighchartsChart,
  type Chart,
  type TooltipFormatterCallbackFunction,
  type TooltipFormatterContextObject,
} from "highcharts";
import Highcharts from "highcharts";
import highchartsAccessibility from "highcharts/modules/accessibility";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";
import SeriesLabel from "highcharts/modules/series-label";
import {
  type LegacyRef,
  forwardRef,
  memo,
  useCallback,
  useState,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import ReactDOM from "react-dom";
import useResizeObserver from "use-resize-observer";
import styles from "./HighchartsReact.module.css";

export type HighchartsReactProps = Omit<Highcharts.Options, "tooltip"> & {
  symbols?: string[];
} & {
  tooltip: Highcharts.TooltipOptions & {
    ReactFormatter: (data: TooltipFormatterContextObject) => JSX.Element;
  };
};

type HighchartsReactPropertiesWrapper = {
  className?: string;
  options: HighchartsReactProps;
};

NoDataToDisplay(Highcharts);
highchartsAccessibility(Highcharts);
SeriesLabel(Highcharts);

Highcharts.setOptions({
  lang: {
    numericSymbols: ["k", "m", "b"],
  },
});

// generate HTML for tooltip
// eslint-disable-next-line no-warning-comments
// TODO: refactor this function as a react component
export const TooltipHTML = (
  metrics?: Array<{
    color?: string | null;
    name?: number | string | null;
    value?: string | null;
  }>,
  primaryHeader?: number | string,
  secondaryHeader?: number | string,
  topAuxiliaryHeader?: number | string,
  title?: string | null
) => (
  <div className={styles.chartTooltip}>
    {topAuxiliaryHeader && (
      <div className={styles.topAuxiliaryHeader}>{topAuxiliaryHeader}</div>
    )}
    {primaryHeader && (
      <div className={styles.primaryHeader}>{primaryHeader}</div>
    )}
    {secondaryHeader && (
      <div className={styles.secondaryHeader}>{secondaryHeader}</div>
    )}
    {title && <div className={styles.mainHeader}>{title}</div>}
    {metrics?.map((metric, index) => (
      <div
        className={styles.textContainer}
        // eslint-disable-next-line react/no-array-index-key
        key={`${metric.name}_${metric.value}_${index}`}
      >
        <div className={styles.leftText}>
          <div
            className={styles.circle}
            style={{ backgroundColor: metric.color ?? undefined }}
          />
          {metric.name}
        </div>
        <div className={styles.spacer} />
        <div className={styles.rightText}>{metric.value}</div>
      </div>
    ))}
  </div>
);

export const generateTooltipId = (chartId: number) =>
  `highcharts-custom-tooltip-${chartId}`;

type Props = {
  chart: Chart | null;
  children: (formatterContext: TooltipFormatterContextObject) => JSX.Element;
};

export const defaultOptions: Highcharts.Options = {
  chart: {
    spacing: [20, 0, 0, 0],
    style: {
      cursor: "crosshair",
      fontFamily: "var(--qbit-font-family)",
    },
    zooming: {
      resetButton: {
        position: {
          y: -20,
        },
      },
      type: "x",
    },
  },
  yAxis: {
    plotLines: [
      {
        value: 0,
        color: "var(--qbit-colour-shade-6)",
        width: 2,
        zIndex: 1,
      },
    ],
  },
  credits: {
    enabled: false,
  },
  legend: {
    align: "left",
    layout: "horizontal",
    verticalAlign: "bottom",
    itemStyle: {
      color: "var(--qbit-colour-text-secondary)",
      fontSize: "0.75rem",
      fontWeight: "var(--qbit-font-weight-regular)",
    },
  },
  title: {
    text: "",
  },
  tooltip: {
    backgroundColor: "transparent",
    borderColor: "transparent",
    padding: 0,
    shadow: false,
    shared: true,
    style: {
      pointerEvents: "auto",
    },
    useHTML: true,
    stickOnContact: true,
  },
};

// CREDIT: https://gist.github.com/dankremniov/a9a6b969e63dfc4f0f83e6f82b82eb4f
const Tooltip = ({ chart, children }: Props) => {
  const [context, setContext] = useState<TooltipFormatterContextObject | null>(
    null
  );

  useEffect(() => {
    if (chart) {
      const formatter: TooltipFormatterCallbackFunction = function () {
        // eslint-disable-next-line @babel/no-invalid-this
        setContext(this);

        return `<div id="${generateTooltipId(chart.index)}"></div>`;
      };

      chart.update({
        tooltip: {
          formatter,
        },
      });
    }
  }, [chart]);

  const node =
    chart && document.querySelector(`#${generateTooltipId(chart.index)}`);

  return node && context
    ? ReactDOM.createPortal(children(context), node)
    : null;
};

const referenceToForward = forwardRef(
  (
    { options, className }: HighchartsReactPropertiesWrapper,
    ref
  ): JSX.Element => {
    const containerRef = useRef<HTMLDivElement>();
    const chartRef = useRef<Highcharts.Chart>();

    const [chart, setChart] = useState<HighchartsChart | null>(null);
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const callback = useCallback((chart: HighchartsChart) => {
      setChart(chart);
    }, []);

    useEffect(() => {
      if (chartRef.current) {
        // when removing or adding a series, set series to empty to reset colour and symbol counter
        // related issue: https://github.com/highcharts/highcharts/issues/6138
        if (chartRef.current.series.length !== options.series?.length) {
          chartRef.current.update({ series: [] }, true, true);
        }

        chartRef.current.update(options, true, true);
      } else {
        chartRef.current = Highcharts.chart(
          containerRef.current ?? "",
          options,
          callback
        );
      }
    });
    useEffect(
      () => () => {
        // Destroy chart only if unmounting.
        if (chartRef.current) {
          chartRef.current.destroy();
          chartRef.current = undefined;
        }
      },
      []
    );

    useImperativeHandle(
      ref,
      () => ({
        get chart() {
          return chartRef.current;
        },
        container: containerRef,
        resize(width: string) {
          if (containerRef.current) {
            containerRef.current.style.width = width;
            chartRef.current?.reflow();
          }
        },
      }),
      []
    );

    // Make sure the chart resizes when its container does
    useResizeObserver<HTMLDivElement>({
      onResize() {
        chartRef.current?.reflow();
      },
      ref: containerRef.current,
    });

    return (
      <div
        className={classNames(styles.chartContainer, className)}
        ref={containerRef as LegacyRef<HTMLDivElement>}
      >
        <Tooltip chart={chart}>
          {(data) => options.tooltip.ReactFormatter(data)}
        </Tooltip>
      </div>
    );
  }
);
export const HighchartsReact = memo(referenceToForward);
