import { Spinner, SpinnerSize } from "@qbit/react";
import {
  type SortingState,
  type ColumnFiltersState,
  type TableOptions,
  type ColumnDef,
  type ColumnResizeMode,
  getCoreRowModel,
  getExpandedRowModel,
  getGroupedRowModel,
  useReactTable,
  type VisibilityState,
  type OnChangeFn,
  type GroupColumnDef,
  type Row,
  type Table,
  type ExpandedState,
  type Updater,
  type RowSelectionState,
  getFilteredRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
import classNames from "classnames";
import { type Ref } from "react";
import {
  useMemo,
  useEffect,
  useCallback,
  useState,
  memo,
  forwardRef,
} from "react";
import { type TableVirtuosoHandle } from "react-virtuoso";
import { TableVirtuoso } from "react-virtuoso";
import {
  DEFAULT_COLUMN_MAX_WIDTH,
  DEFAULT_COLUMN_MIN_WIDTH,
  DEFAULT_COLUMN_WIDTH,
  FIRST_COLUMN_WIDTH_MULTIPLY_BY_5,
} from "../common/constants";
import { DELETED_LINE_FILTER_COLUMN_ID } from "../common/filters/excludeDeletedLineFilter";
import { SELECTED_ITEMS_ONLY_FILTER_COLUMN_ID } from "../common/filters/selectedItemsOnlyFilter";
import {
  type HierarchicalRow,
  type LoadMoreRow,
} from "../report-hierarchy-table/ReportHierarchyTable";
import {
  isHierarchical,
  isMoreRow,
} from "../report-hierarchy-table/ReportHierarchyTable";
import styles from "./VirtuosoTable.module.css";
import { VirtuosoTableBodyElement } from "./VirtuosoTableBodyElement";
import { VirtuosoTableElement } from "./VirtuosoTableElement";
import { VirtuosoTableHeaderElement } from "./VirtuosoTableHeaderElement";
import { VirtuosoTableRowElement } from "./VirtuosoTableRowElement";

const DEFAULT_ROW_EXPANDED_STATE = {};
const DEFAULT_ROW_SELECTION_STATE = {};
const DEFAULT_MORE_TEXT = "Load more...";
export const AUTO_WIDTH = "auto";
export const PERCENT_WIDTH = "100%";

export const DEFAULT_COLUMN_RESIZE_MODE = "onChange";

export type VirtuosoTableComponentProps<T> = {
  className?: string;
  columnFilters?: ColumnFiltersState;
  columnResizeMode?: ColumnResizeMode;
  columnVisibility?: VisibilityState;
  columns: Array<ColumnDef<T>>;
  compactRows?: boolean;
  data: T[];
  defaultColumnWidthOverride?:
    | Partial<ColumnDef<T>>
    | Partial<GroupColumnDef<T>>;
  depthPadding?: number;
  depthPaddingOffset?: number;
  enableColumnResizing?: boolean;
  enableRowFiltering?: boolean;
  enableSorting?: boolean;
  enableSortingRemoval?: boolean;
  getIsRowDisabled?: (row: Row<T>, table: Table<T>) => boolean;
  getRowId?: (row: T, relativeIndex?: number, parent?: Row<T>) => string;
  getSubRows?: (row: T) => T[];
  handleMoreClicked?: (row: Row<T>) => Promise<void>;
  manualSorting?: boolean;
  moreText?: string;
  onColumnVisibilityChange?: OnChangeFn<VisibilityState>;
  onExpandedChange?: (rowExpansion: Updater<ExpandedState>) => void;
  onRowClick?: Function;
  onRowDoubleClick?: Function;
  onRowSelectionChange?: (rowSelection: Updater<RowSelectionState>) => void;
  onSortingChange?: (sorting: Updater<SortingState>) => void;
  pinFirstColumn?: boolean;
  refreshingData?: boolean;
  rowExpandedState?: ExpandedState;
  rowSelectionState?: RowSelectionState;
  showCheckboxesOnlyOnHover?: boolean;
  sortByDescendingFirst?: boolean;
  sorting?: SortingState;
  tableWidth?: number | string;
};

const referenceToForward = forwardRef(
  <T,>(
    {
      className,
      columnFilters,
      columnResizeMode = DEFAULT_COLUMN_RESIZE_MODE,
      columnVisibility,
      columns,
      compactRows = false,
      data,
      defaultColumnWidthOverride,
      depthPadding = 0,
      depthPaddingOffset = 0,
      enableColumnResizing = false,
      enableRowFiltering = false,
      enableSorting = false,
      getRowId,
      getSubRows,
      handleMoreClicked,
      moreText = DEFAULT_MORE_TEXT,
      getIsRowDisabled,
      onColumnVisibilityChange,
      onExpandedChange,
      onRowDoubleClick,
      onRowClick,
      onRowSelectionChange,
      onSortingChange,
      pinFirstColumn = false,
      refreshingData = false,
      rowExpandedState = DEFAULT_ROW_EXPANDED_STATE,
      rowSelectionState = DEFAULT_ROW_SELECTION_STATE,
      showCheckboxesOnlyOnHover,
      manualSorting = false,
      enableSortingRemoval = false,
      sortByDescendingFirst = false,
      sorting,
      tableWidth = PERCENT_WIDTH,
    }: VirtuosoTableComponentProps<T>,
    ref?: Ref<TableVirtuosoHandle>
  ) => {
    const [tableData, setTableData] = useState<T[]>(() => []);
    const [isLoadingMore, setIsLoadingMore] = useState<Record<string, boolean>>(
      {}
    );

    const getLoadingMoreId = useCallback(
      (currentRow: T) => (getRowId ? getRowId(currentRow) : "load-more"),
      [getRowId]
    );

    // loop through data, and for each time you find a isMoreRow, add a new row at the same depth level at the end.
    const addLoadMoreRows = useCallback(
      (localRows: Array<HierarchicalRow<T>>) => {
        const newRows: Array<LoadMoreRow<T> | T> = [];

        for (const row of localRows) {
          const temporaryRow = { ...row };
          // clear the subRows first
          temporaryRow.subRows = [];

          // recursively update its children
          if (isHierarchical(row as object)) {
            temporaryRow.subRows = [
              ...addLoadMoreRows(row.subRows as Array<HierarchicalRow<T>>),
            ];
          }

          newRows.push(temporaryRow);

          if (isMoreRow(row as object)) {
            newRows.push({
              moreLabel: moreText,
              loadMoreParentRow: row,
            } as unknown as LoadMoreRow<T>);
            isLoadingMore[getLoadingMoreId(row)] = false;
          }
        }

        return newRows;
      },
      [getLoadingMoreId, isLoadingMore, moreText]
    );

    const firstColumnMinWidth: number = useMemo(
      () => DEFAULT_COLUMN_MIN_WIDTH * FIRST_COLUMN_WIDTH_MULTIPLY_BY_5,
      []
    );

    const baseTableConfigurations = {
      columnResizeMode,
      columns,
      data: tableData,
      defaultColumn: defaultColumnWidthOverride
        ? defaultColumnWidthOverride
        : {
            minSize: DEFAULT_COLUMN_MIN_WIDTH,
            size: DEFAULT_COLUMN_WIDTH,
            maxSize: DEFAULT_COLUMN_MAX_WIDTH,
          },
      state: {
        columnVisibility,
        expanded: rowExpandedState,
        rowSelection: rowSelectionState,
        sorting,
      },
      enableColumnResizing,
      enableSorting,
      getCoreRowModel: getCoreRowModel(),
      getExpandedRowModel: getExpandedRowModel(),
      getGroupedRowModel: getGroupedRowModel(),
      getRowCanExpand: (row: Row<T>) => Boolean(row.subRows.length),
      getRowId,
      getSubRows,
      manualSorting,
      onSortingChange,
      enableSortingRemoval,
      sortDescFirst: sortByDescendingFirst,
      onColumnVisibilityChange,
      onExpandedChange,
      onRowSelectionChange,
    };
    let tableConfigurations: TableOptions<T> = {
      ...baseTableConfigurations,
      autoResetExpanded: !onExpandedChange,
    };

    if (enableSorting) {
      const sortingConfigurations = {
        getSortedRowModel: getSortedRowModel(),
        onSortingChange,
      };

      tableConfigurations = {
        ...tableConfigurations,
        ...sortingConfigurations,
      };
    }

    if (columnFilters) {
      tableConfigurations = {
        ...tableConfigurations,
        getFilteredRowModel: getFilteredRowModel(),
      };
    }

    const table = useReactTable(tableConfigurations);

    const rows = table.getRowModel().rows;

    // set the filter values for the columns to trigger filters to be applied
    useEffect(() => {
      if (enableRowFiltering && columnFilters) {
        const deletedLinesFilter = columnFilters.find(
          (filter) => filter.id === DELETED_LINE_FILTER_COLUMN_ID
        );

        const selectedItemsOnlyFilter = columnFilters.find(
          (filter) => filter.id === SELECTED_ITEMS_ONLY_FILTER_COLUMN_ID
        );

        for (const headerGroup of table.getHeaderGroups()) {
          for (const header of headerGroup.headers) {
            if (
              deletedLinesFilter &&
              header.column.id === DELETED_LINE_FILTER_COLUMN_ID
            ) {
              header.column.setFilterValue(deletedLinesFilter.value);
            }

            if (
              selectedItemsOnlyFilter &&
              header.column.id === SELECTED_ITEMS_ONLY_FILTER_COLUMN_ID
            ) {
              header.column.setFilterValue(selectedItemsOnlyFilter.value);
            }
          }
        }
      }
    }, [columnFilters, enableRowFiltering, table]);

    useEffect(() => {
      if (pinFirstColumn) {
        table.getHeaderGroups()[0].headers[0].column.pin("left");
      }
    }, [table, pinFirstColumn]);

    // if the table Data changes, rerender
    useEffect(
      () => setTableData(addLoadMoreRows(data as Array<HierarchicalRow<T>>)),
      [addLoadMoreRows, data]
    );

    return (
      <div
        className={classNames(styles.virtuosoTableWrapper, {
          [styles.refreshData]: refreshingData,
        })}
      >
        <TableVirtuoso<T>
          components={{
            Table: (properties) =>
              VirtuosoTableElement({
                className,
                tableWidth,
                "aria-rowcount": data.length || 0,
                ...properties,
              }),
            TableBody: VirtuosoTableBodyElement,
            TableRow: (properties) => {
              const index = properties["data-index"];
              const row = rows[index];
              const colSpan = table.getRowModel().rows.length;
              const rowIsDisabled = getIsRowDisabled?.(row, table);

              return VirtuosoTableRowElement({
                colSpan,
                compactRows,
                depthPadding,
                depthPaddingOffset,
                firstColumnMinWidth,
                getLoadingMoreId,
                handleMoreClicked,
                isLoadingMore,
                moreText,
                onRowDoubleClick,
                onRowClick,
                pinFirstColumn,
                row,
                rowIsDisabled,
                setIsLoadingMore,
                showCheckboxesOnlyOnHover,
                ...properties,
              });
            },
          }}
          fixedHeaderContent={() =>
            table.getHeaderGroups().map((headerGroup) =>
              VirtuosoTableHeaderElement({
                columnResizeMode,
                firstColumnMinWidth,
                pinFirstColumn,
                headerGroup,
                deltaOffset: table.getState().columnSizingInfo.deltaOffset,
                enableSorting,
              })
            )
          }
          ref={ref}
          totalCount={rows.length}
        />
        {refreshingData && (
          <div className={styles.refreshDataSpinner}>
            <Spinner size={SpinnerSize.Large} />
          </div>
        )}
      </div>
    );
  }
) as <T>(
  p: VirtuosoTableComponentProps<T> & {
    ref?: Ref<TableVirtuosoHandle | undefined>;
  }
) => JSX.Element;

// generic type wrapper to trick TS to carry through the generic type
const genericMemo: <T>(component: T) => T = memo;

export const VirtuosoTableComponent = genericMemo(referenceToForward);
