import {
  Icon,
  IconGlyph,
  Menu,
  MenuItemLink,
  MenuSection,
  Text,
} from "@qbit/react";
import { type Division, type UserDto } from "@quantium-enterprise/common-ui";
import styles from "./DivisionDropdown.module.css";

export type DivisionDropdownProps = {
  activeDivision: Division;
  user: UserDto;
};

const DivisionDropdown = ({ activeDivision, user }: DivisionDropdownProps) => (
  <Menu className={styles.divisionDropdown}>
    {user.divisions.map((division) => (
      <MenuSection className={styles.menuSection} key={division.name}>
        <MenuItemLink
          data-testid="division-menu-item-link"
          href={`/${division.name}`}
          iconEnd={
            activeDivision.name === division.name && (
              <Icon
                className={styles.tickIcon}
                glyph={IconGlyph.SelectionSuccess}
                text={`${division.displayName} selected`}
              />
            )
          }
          selected={activeDivision.name === division.name}
          text={<Text>{division.displayName}</Text>}
        />
      </MenuSection>
    ))}
  </Menu>
);

export { DivisionDropdown };
