import {
  type HierarchyValue,
  HierarchyItemType,
  ddLog,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFormatter } from "@quantium-enterprise/hooks-ui";
import {
  type Row,
  type ExpandedState,
  type CellContext,
  type ColumnDef,
  type ColumnResizeMode,
} from "@tanstack/react-table";
import { ExpandableNameCell } from "components-ui/src/tables/common/table-cell/ExpandableNameCell";
import { ValueCell } from "components-ui/src/tables/common/table-cell/ValueCell";
import { ReportHierarchyTableWrapper } from "components-ui/src/tables/report-hierarchy-table/components/ReportHierarchyTableWrapper";
import { VirtuosoTableComponent } from "components-ui/src/tables/virtuoso-table/VirtuosoTableComponent";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  type CustomerLoyaltyHierarchyProductData,
  type CustomerLoyaltyHierarchyProductLoadMoreRow,
  type CustomerLoyaltyHierarchyProductRow,
} from "../../models/CustomerLoyaltyHierarchyProductData";
import {
  type TableRowsRequestDto,
  type TableChildRowsRequestDto,
} from "../../models/CustomerLoyaltyTableRowsRequestDto";
import { useLazyGetTableRowsQuery } from "../../services/customer-loyalty-data-table-api-slice";
import { useLazyGetTableChildRowsQuery } from "../../services/customer-loyalty-data-table-children-api-slice";
import {
  onLoadMoreResponseReceived,
  onTopDrawerTableChildrenResponseReceived,
  selectChildrenPage,
  selectFocalItems,
  selectFocalSegment,
  selectLocationNodeSelection,
  selectMetricList,
  selectRowSelection,
  selectSegment,
  selectSegmentation,
  selectTablePage,
  selectTableRows,
  selectTopDrawerActiveTab,
  selectTopDrawerTableSuccess,
  setFocalItems,
} from "../../services/customer-loyalty-slice";
import { getTableChildrenRequestDto, getTableRequestDto } from "./utils";

const columnResizeMode: ColumnResizeMode = "onChange";

const isLoadMore = (
  panel: CustomerLoyaltyHierarchyProductRow
): panel is CustomerLoyaltyHierarchyProductLoadMoreRow =>
  Object.prototype.hasOwnProperty.call(panel, "loadMoreParentRow");

const getRowId = (row: CustomerLoyaltyHierarchyProductRow) => {
  if (isLoadMore(row)) {
    return `${row.loadMoreParentRow.hierarchyItem.itemCode}-load-more`;
  }

  return row.hierarchyItem.itemCode;
};

export type CustomerLoyaltyTopDrawerProductToSegmentTableProps = {
  isQuerySuccess: boolean;
};

export const CustomerLoyaltyTopDrawerProductToSegmentTable = ({
  isQuerySuccess,
}: CustomerLoyaltyTopDrawerProductToSegmentTableProps) => {
  const [isRowFetchingChildren, setIsRowFetchingChildren] = useState<{
    [id: string]: boolean;
  }>({});

  const dispatch = useDispatch();
  const { id: reportId } = useParams();
  const { name: divisionName, customLoaDto } = useDivision();
  const formatter = useFormatter();

  const activeTab = useSelector(selectTopDrawerActiveTab);
  const metricList = useSelector(selectMetricList);
  const page = useSelector(selectTablePage);
  const rowSelection = useSelector(selectRowSelection);
  const segmentation = useSelector(selectSegmentation);
  const segment = useSelector(selectSegment);
  const tableRows = useSelector(selectTableRows);
  const locationNodeSelection = useSelector(selectLocationNodeSelection);
  const childrenPage = useSelector(selectChildrenPage);
  const focalItems = useSelector(selectFocalItems);
  const focalSegment = useSelector(selectFocalSegment);
  const topDrawerTableSuccess = useSelector(selectTopDrawerTableSuccess);

  const [expandState, setExpandState] = useState<ExpandedState>({});

  const [getTableRows] = useLazyGetTableRowsQuery();

  const fetchData = useCallback(
    async (division: string, payload: TableRowsRequestDto) => {
      const response = await getTableRows({ division, payload });
      if (response.data) {
        dispatch(onLoadMoreResponseReceived(response.data));
      }
    },
    [getTableRows, dispatch]
  );

  const [getTableChildRows] = useLazyGetTableChildRowsQuery({
    refetchOnFocus: false,
  });

  const fetchChildren = useCallback(
    async (parentHierarchyItem: HierarchyValue) => {
      setIsRowFetchingChildren((previousData) => ({
        ...previousData,
        [parentHierarchyItem.itemCode + parentHierarchyItem.shortName]: true,
      }));

      const payload: TableChildRowsRequestDto = getTableChildrenRequestDto(
        activeTab ?? "",
        0,
        parentHierarchyItem,
        reportId ?? "",
        [segmentation.value as string, segment.value as string],
        locationNodeSelection
      );

      const { data } = await getTableChildRows({
        division: divisionName,
        payload,
      });

      if (data) {
        dispatch(onTopDrawerTableChildrenResponseReceived(data));

        setExpandState((previousState) => ({
          ...(previousState as Record<string, boolean>),
          [parentHierarchyItem.itemCode]: true,
        }));
      }

      setIsRowFetchingChildren((previousData) => ({
        ...previousData,
        [parentHierarchyItem.itemCode + parentHierarchyItem.shortName]: false,
      }));
    },
    [
      activeTab,
      reportId,
      segmentation.value,
      segment.value,
      locationNodeSelection,
      getTableChildRows,
      divisionName,
      dispatch,
    ]
  );

  const fetchLoadMoreChildren = useCallback(
    async (parentHierarchyItem: HierarchyValue) => {
      const payload: TableChildRowsRequestDto = getTableChildrenRequestDto(
        activeTab ?? "",
        childrenPage[parentHierarchyItem.itemCode],
        parentHierarchyItem,
        reportId ?? "",
        [segmentation.value as string, segment.value as string],
        locationNodeSelection
      );

      const { data } = await getTableChildRows({
        division: divisionName,
        payload,
      });

      if (data) {
        dispatch(onTopDrawerTableChildrenResponseReceived(data));
      }
    },
    [
      activeTab,
      childrenPage,
      reportId,
      segmentation.value,
      segment.value,
      locationNodeSelection,
      getTableChildRows,
      divisionName,
      dispatch,
    ]
  );

  useEffect(() => {
    if (topDrawerTableSuccess && focalSegment) {
      fetchChildren(focalSegment).catch((error) => {
        ddLog(
          "Error fetching customer loyalty table child rows",
          {},
          "error",
          error
        );
      });

      if (typeof expandState !== "boolean") {
        setExpandState({ [focalSegment.itemCode]: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topDrawerTableSuccess]);

  const onMoreClickedHandler = useCallback(
    async (row: Row<CustomerLoyaltyHierarchyProductData>) => {
      if (
        isLoadMore(row.original) &&
        row.original.loadMoreParentRow.parentHierarchyItem
      ) {
        await fetchLoadMoreChildren(
          row.original.loadMoreParentRow.parentHierarchyItem
        );
      } else {
        const payload = getTableRequestDto(
          activeTab ?? "",
          [segmentation.value as string, segment.value as string],
          locationNodeSelection,
          page,
          reportId ?? ""
        );

        await fetchData(divisionName, payload);
      }
    },
    [
      fetchLoadMoreChildren,
      activeTab,
      segmentation.value,
      segment.value,
      locationNodeSelection,
      page,
      reportId,
      fetchData,
      divisionName,
    ]
  );

  const getIsRowDisabled = useCallback(
    (row: Row<CustomerLoyaltyHierarchyProductData>): boolean =>
      focalSegment !== undefined &&
      row.original.hierarchyItem.itemCode !== focalSegment.itemCode &&
      (row.original.parentHierarchyItem?.itemCode !== focalSegment.itemCode ||
        focalSegment.itemCode === focalItems[0]?.itemCode),
    [focalItems, focalSegment]
  );

  const createNameCell = useCallback(
    ({ row }: CellContext<CustomerLoyaltyHierarchyProductData, unknown>) => (
      <ExpandableNameCell
        canExpand={row.original.childrenCount > 0}
        depth={
          row.original.hierarchyItem.shortName ===
          customLoaDto.hierarchyLevels.productLevel
            ? 1
            : 0
        }
        handleToggleExpanded={() => {
          // Fetch children if no data
          if (row.subRows.length === 0) {
            fetchChildren(row.original.hierarchyItem).catch((error) => {
              ddLog(
                "Error fetching customer loyalty table child rows",
                {},
                "error",
                error
              );
            });
          }

          row.getToggleExpandedHandler()();
        }}
        handleToggleSelected={(event) => {
          row.getToggleSelectedHandler()(event);
          dispatch(
            setFocalItems({
              isSelected: row.getIsSelected(),
              selectedRow: row.original,
              customLoaDto,
            })
          );
        }}
        hideTooltip
        isCheckboxDisabled={getIsRowDisabled(row)}
        isExpanded={row.getIsExpanded()}
        isExpanding={
          isRowFetchingChildren[
            row.original.hierarchyItem.itemCode +
              row.original.hierarchyItem.shortName
          ] && row.original.subRows.length === 0
        }
        isSelected={row.getIsSelected()}
        name={row.original.hierarchyItem.name}
        shortName={row.original.hierarchyItem.shortName}
        type={HierarchyItemType.Hierarchy}
        value={row.original.hierarchyItem.name}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, fetchChildren, getIsRowDisabled, isRowFetchingChildren]
  );

  const metricColumnsDefs = useMemo(
    (): Array<ColumnDef<CustomerLoyaltyHierarchyProductData>> =>
      metricList.map((metric, index) => ({
        accessorKey: metric.value,
        cell: ({ row }) =>
          ValueCell({
            formatter: formatter(metric.format),
            value: row.original.metricValues[index],
          }),
        enableHiding: false,
        enableResizing: false,
        enableSorting: false,
        header: metric.label,
        id: index + metric.value,
      })),
    [formatter, metricList]
  );

  const columnDefs: Array<ColumnDef<CustomerLoyaltyHierarchyProductData>> =
    useMemo(
      () => [
        {
          accessorKey: "name",
          cell: createNameCell,
          enableHiding: false,
          enableResizing: true,
          enableSorting: false,
          header: "Search",
        },
        ...metricColumnsDefs,
      ],
      [metricColumnsDefs, createNameCell]
    );

  return (
    <ReportHierarchyTableWrapper isSuccess={isQuerySuccess}>
      <VirtuosoTableComponent
        columnResizeMode={columnResizeMode}
        columns={columnDefs}
        data={tableRows}
        depthPadding={40}
        depthPaddingOffset={32}
        getRowId={getRowId}
        getSubRows={(row) => row.subRows}
        handleMoreClicked={onMoreClickedHandler}
        moreText="Load more..."
        onExpandedChange={setExpandState}
        pinFirstColumn
        rowExpandedState={expandState}
        rowSelectionState={rowSelection}
      />
    </ReportHierarchyTableWrapper>
  );
};
