import {
  AppContext,
  GenericTrackingProperties,
  GroupsTrackingProperty,
  HierarchyType,
  TrackingComponent,
  TrackingEvent,
  useDeleteGroupFolderMutation,
  useDeleteGroupsMutation,
  useEventTrackingServiceContext,
  useUnshareGroupMutation,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { type DeleteItems } from "components-ui/src/delete-dialog/DeleteDialog";
import { DeleteDialog } from "components-ui/src/delete-dialog/DeleteDialog";
import { useCallback, useContext, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  confirmDeleteFolderOrGroupsSuccess,
  resetDeleteFolderOrGroups,
  selectDeleteFolderOrGroups,
} from "../../states/group-list-slice";
import { getDefaultGroupsPath } from "../../utilities/route-path-formats";

type DeleteHierarchyGroupModalProps = {
  hierarchyType: HierarchyType;
};

export const DeleteHierarchyGroupModal = ({
  hierarchyType,
}: DeleteHierarchyGroupModalProps) => {
  const { userState } = useContext(AppContext);
  const eventTrackingService = useEventTrackingServiceContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const division = useDivision();
  const divisionName = division.name;
  const deleteFolderOrGroups = useSelector(selectDeleteFolderOrGroups);
  const trackingComponent =
    hierarchyType === HierarchyType.Product
      ? TrackingComponent.ProductGroup
      : TrackingComponent.LocationGroup;

  const [deleteGroups] = useDeleteGroupsMutation();
  const [deleteFolder] = useDeleteGroupFolderMutation();
  const [unshareGroup] = useUnshareGroupMutation();

  const deleteButtonRef = useRef(null);

  const isOwnerOfDropdownGroup =
    deleteFolderOrGroups.length === 1 &&
    deleteFolderOrGroups[0].userId === userState.currentUser?.salesForceId;

  const handleDeleteGroups = useCallback(() => {
    dispatch(confirmDeleteFolderOrGroupsSuccess());
    navigate(getDefaultGroupsPath(divisionName, hierarchyType));
  }, [dispatch, navigate, divisionName, hierarchyType]);

  const itemsToDelete = useMemo(() => {
    const groupIds = [];
    const folderIds = [];
    for (const folderOrGroup of deleteFolderOrGroups) {
      if (folderOrGroup.id) {
        if (folderOrGroup.isFolder) {
          folderIds.push(folderOrGroup.id);
        } else {
          groupIds.push(folderOrGroup.id);
        }
      }
    }

    return {
      groupIds,
      folderIds,
    } as DeleteItems;
  }, [deleteFolderOrGroups]);

  // the actual deleting of items on clicking delete in the popup
  const deleteItems = async (items: DeleteItems) => {
    if (!isOwnerOfDropdownGroup) {
      return [];
    }

    const promises: Array<Promise<unknown>> = [];

    if (items.groupIds?.length) {
      promises.push(
        deleteGroups({
          divisionName,
          itemIds: items.groupIds,
        }).unwrap()
      );
    }

    for (const folderId of items.folderIds ?? []) {
      promises.push(
        deleteFolder({
          divisionName,
          dto: {
            folderId,
          },
        }).unwrap()
      );
    }

    return await Promise.all(promises);
  };

  const removeItems = async () => {
    if (isOwnerOfDropdownGroup) {
      return [];
    }

    const folderOrGroupToRemove = deleteFolderOrGroups[0];
    const entityType = folderOrGroupToRemove.isFolder ? "Folder" : "Group";

    eventTrackingService.trackEvent(
      trackingComponent,
      TrackingEvent.Removed,
      new GenericTrackingProperties({
        groupId: folderOrGroupToRemove.id,
        [GroupsTrackingProperty.OwnerId]: folderOrGroupToRemove.userId,
        division: division.name,
        [GroupsTrackingProperty.EntityType]: entityType,
      })
    );

    const result = await unshareGroup({
      divisionName,
      payload: {
        entityType,
        sharingNotes: "",
        sourceId: folderOrGroupToRemove.id ?? "",
        userIds: [userState.currentUser?.salesForceId ?? ""],
      },
    }).unwrap();

    return [
      result,
      eventTrackingService,
      trackingComponent,
      deleteFolderOrGroups,
      userState,
      division,
      entityType,
    ];
  };

  return (
    <DeleteDialog
      deleteItem={isOwnerOfDropdownGroup ? deleteItems : removeItems}
      isRemove={!isOwnerOfDropdownGroup}
      itemIds={itemsToDelete}
      onClose={() => dispatch(resetDeleteFolderOrGroups())}
      onDelete={handleDeleteGroups}
      ref={deleteButtonRef}
      show={deleteFolderOrGroups.length > 0}
      trackingComponent={trackingComponent}
    />
  );
};
