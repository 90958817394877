import { Text } from "@qbit/react";
import {
  type MetricsOptionDto,
  type ParameterSelectionDto,
  type ParameterGroupSelectionDto,
  type MeasureThresholdSelectionDto,
  type ListSelectionDto,
  type NumberSelectionDto,
  type BandSelectionDto,
  type SegmentationSelectionDto,
  type GroupSelectionDto,
  type TransactionSource,
  type HierarchySelectionDto,
  type OrderedSelectionDto,
  type TimePeriodSelectionDto,
  isListSelectionDto,
  ProductHierarchy,
  isSegmentationSelectionDto,
  ParameterId,
  NumberFormat,
  type GroupSegmentSelectionDto,
  HierarchyItemType,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import React from "react";
import {
  TransactionSourceDisplayNames,
  TransactionSourceIcon,
} from "../../icons/transaction-source-icon/TransactionSourceIcon";
import {
  BandsSelectionSummary,
  BufferSelectionSummary,
  GroupSelectionSummary,
  HierarchySelectionSummary,
  ListSelectionSummary,
  ListSelectionSummaryWithDefault,
  MeasureThresholdSelectionSummary,
  MetricsSelectionSummary,
  TimePeriodDataTypeSelectionSummary,
  TimePeriodSelectionSummary,
} from "../../parameter-selection-summary";
import { LevelSelectionSummary } from "../../parameter-selection-summary/level-selection-summary/LevelSelectionSummary";
import { StructurePreview } from "../../structure-preview/StructurePreview";
import styles from "./InfoPanelBody.module.css";

export type InfoPanelBodyProps = {
  parameterGroups: ParameterGroupSelectionDto[] | undefined;
  transactionSource: TransactionSource[] | undefined;
};

const BandsSummary = (parameter: ParameterSelectionDto) => {
  const selections = parameter.selections as BandSelectionDto[];
  const bands = selections.map((selection) => ({
    ...selection,
    maximum: selection.maximum * 100,
    minimum: selection.minimum * 100,
  }));
  return <BandsSelectionSummary bands={bands} />;
};

const BufferSummary = (parameter: ParameterSelectionDto) => {
  const selections = parameter.selections as NumberSelectionDto[];
  const buffer =
    selections.length === 1 ? selections[0].number * 100 : undefined;
  return <BufferSelectionSummary buffer={buffer} />;
};

const HierarchySummary = (parameter: ParameterSelectionDto) => {
  const selections = parameter.selections as HierarchySelectionDto[];
  const items = selections.map((selection) => ({
    ...selection.hierarchyItem,
    type: HierarchyItemType.Hierarchy,
  }));
  return (
    <HierarchySelectionSummary
      itemCount={parameter.selectionsCount}
      items={items}
      maxDepth={3}
      moreItemsText=" more items"
    />
  );
};

const GroupSummary = (parameter: ParameterSelectionDto) => {
  const selections = parameter.selections as GroupSelectionDto[];
  return (
    <GroupSelectionSummary
      itemCount={parameter.selectionsCount}
      items={selections}
      maxDepth={2}
      moreItemsText=" more items"
    />
  );
};

const MeasureThresholdSummary = (parameter: ParameterSelectionDto) => {
  const selections = (
    parameter.selections as MeasureThresholdSelectionDto[]
  ).map((selection) => ({
    ...selection,
    threshold: {
      ...selection.threshold,
      number:
        selection.threshold.format === NumberFormat.Percent
          ? selection.threshold.number * 100
          : selection.threshold.number,
    },
  }));

  return <MeasureThresholdSelectionSummary selections={selections} />;
};

const NotesSummary = (parameter: ParameterSelectionDto) => {
  const value = parameter.selections
    .map((selection) => (isListSelectionDto(selection) ? selection.value : ""))
    .at(0);

  const text = value ? value : "None specified";

  return <Text className={styles.notesContent}>{text}</Text>;
};

const SegmentationSummary = (parameter: ParameterSelectionDto) => {
  const selectedValues = parameter.selections.map((selection) =>
    isSegmentationSelectionDto(selection) ? selection.name : ""
  );
  // No selected value uses No segementation selections component
  if (selectedValues.length === 0) {
    return <ListSelectionSummaryWithDefault label={parameter.name} />;
  }

  return (
    <ListSelectionSummary label={parameter.name} selections={selectedValues} />
  );
};

const SegmentSummary = (parameter: ParameterSelectionDto) => {
  const segmentNames = parameter.selections
    .filter((selection) => isSegmentationSelectionDto(selection))
    .map((segmentation) => segmentation as SegmentationSelectionDto)
    .flatMap(
      (segmentation) =>
        segmentation.segments?.map(
          (segment) => `${segmentation.name} ${segment.name}`
        ) ?? []
    );

  if (segmentNames.length === 0) {
    return <ListSelectionSummaryWithDefault label={parameter.name} />;
  }

  return (
    <ListSelectionSummary label={parameter.name} selections={segmentNames} />
  );
};

const LevelSummary = (parameter: ParameterSelectionDto) => {
  const selections = parameter.selections as ListSelectionDto[];

  return (
    <LevelSelectionSummary
      parameterName={parameter.name}
      selections={selections}
    />
  );
};

const ListSummary = (parameter: ParameterSelectionDto) => {
  const selectedValues = parameter.selections.map((selection) =>
    isListSelectionDto(selection) ? selection.label : ""
  );
  return (
    <ListSelectionSummary label={parameter.name} selections={selectedValues} />
  );
};

const TimePeriodDataTypeSummary = (parameter: ParameterSelectionDto) => {
  const selectedValues = parameter.selections.map((selection) =>
    isListSelectionDto(selection) ? selection.label : ""
  );
  return <TimePeriodDataTypeSelectionSummary selections={selectedValues} />;
};

const MetricsSummary = (parameter: ParameterSelectionDto) => {
  const selections = parameter.selections as MetricsOptionDto[];
  return (
    <MetricsSelectionSummary
      itemCount={parameter.selectionsCount}
      items={selections}
      maxDepth={3}
      moreItemsText=" more items"
    />
  );
};

const StructureSummary = (parameter: ParameterSelectionDto) => {
  const selections = parameter.selections as OrderedSelectionDto[];
  const items = selections
    .slice()
    .sort((a, b) => a.position - b.position)
    .map((selection) => ({
      isAttribute: false,
      label: selection.name,
      shortName: selection.shortName,
      type: HierarchyItemType.Hierarchy,
    }));

  return (
    <StructurePreview
      itemCount={parameter.selectionsCount}
      items={items}
      maxDepth={5}
      moreItemsText=" more items"
    />
  );
};

const LevelOfAnalysisSummary = (parameter: ParameterSelectionDto) => {
  const selections = parameter.selections as ListSelectionDto[];
  const items = selections.slice().map((selection) => ({
    itemCode: "",
    name: selection.label,
    shortName: selection.value,
    type: (Object.values(ProductHierarchy) as string[]).includes(
      selection.value
    )
      ? HierarchyItemType.Hierarchy
      : HierarchyItemType.Attribute,
  }));

  return (
    <HierarchySelectionSummary
      itemCount={parameter.selectionsCount}
      items={items}
      maxDepth={5}
      moreItemsText=""
    />
  );
};

const TimePeriodSummary = (parameter: ParameterSelectionDto) => (
  <TimePeriodSelectionSummary
    items={parameter.selections as TimePeriodSelectionDto[]}
    name={parameter.name}
  />
);

const LeadPeriodSummary = (parameter: ParameterSelectionDto) => {
  if (parameter.id !== ParameterId.LeadPeriod) return null;

  const selections = parameter.selections as TimePeriodSelectionDto[];
  const customDateOptionValue = "Custom-0";

  if (
    selections[0].periodValue === customDateOptionValue ||
    selections[0].customLength
  ) {
    return (
      <TimePeriodSelectionSummary
        items={parameter.selections as TimePeriodSelectionDto[]}
        name={parameter.name}
        showDateRange={false}
      />
    );
  } else {
    return (
      <ListSelectionSummary
        label={parameter.name}
        selections={[selections[0].label]}
      />
    );
  }
};

const GroupSegmentsSummary = (parameter: ParameterSelectionDto) => {
  const selections = parameter.selections as GroupSegmentSelectionDto[];
  return (
    <>
      {selections.map((cg) => (
        <div key={cg.customerGroupId + cg.segmentKey}>
          {cg.segmentDisplayName}
        </div>
      ))}
    </>
  );
};

const CombinedGroupRuleSummary = (parameter: ParameterSelectionDto) => {
  const selections = parameter.selections as ListSelectionDto[];
  return <div>{selections[0].value}</div>;
};

const AffinitiesHierarchySummary = (parameter: ParameterSelectionDto) => {
  const selections = parameter.selections as HierarchySelectionDto[];
  const items = selections.map((selection) => ({
    ...selection.hierarchyItem,
    type: HierarchyItemType.Hierarchy,
  }));
  return (
    <HierarchySelectionSummary
      itemCount={parameter.selectionsCount}
      items={items}
      maxDepth={3}
      moreItemsText=" more items"
      title={parameter.name}
    />
  );
};

export const getParameterSummary = (parameter: ParameterSelectionDto) => {
  const ParameterMap: Record<NonNullable<string>, JSX.Element> = {
    [ParameterId.Channel]: <ListSummary {...parameter} />,
    [ParameterId.ComparisonPeriod]: <TimePeriodSummary {...parameter} />,
    [ParameterId.FocusPeriod]: <TimePeriodSummary {...parameter} />,
    [ParameterId.LeadPeriod]: <LeadPeriodSummary {...parameter} />,
    [ParameterId.LevelOfAnalysis]: <LevelOfAnalysisSummary {...parameter} />,
    [ParameterId.LocationHierarchy]: <HierarchySummary {...parameter} />,
    [ParameterId.LocationGroups]: <GroupSummary {...parameter} />,
    [ParameterId.LocationStructure]: <StructureSummary {...parameter} />,
    [ParameterId.CompStore]: <ListSummary {...parameter} />,
    [ParameterId.ProductHierarchy]: <HierarchySummary {...parameter} />,
    [ParameterId.ProductGroups]: <GroupSummary {...parameter} />,
    [ParameterId.ProductStructure]: <StructureSummary {...parameter} />,
    [ParameterId.Promotion]: <ListSummary {...parameter} />,
    [ParameterId.Dataset]: <ListSummary {...parameter} />,
    [ParameterId.RollingPeriod]: <ListSummary {...parameter} />,
    [ParameterId.Segmentation]: <SegmentationSummary {...parameter} />,
    [ParameterId.SegmentFilter]: <SegmentSummary {...parameter} />,
    [ParameterId.PurchasingBehaviour]: <ListSummary {...parameter} />,
    [ParameterId.PurchasingTrends]: <ListSummary {...parameter} />,
    [ParameterId.NewLapsedContinuous]: <ListSummary {...parameter} />,
    [ParameterId.RetailerCustomers]: <ListSummary {...parameter} />,
    [ParameterId.Measure]: <ListSummary {...parameter} />,
    [ParameterId.Bands]: <BandsSummary {...parameter} />,
    [ParameterId.Buffer]: <BufferSummary {...parameter} />,
    [ParameterId.MeasureThreshold]: <MeasureThresholdSummary {...parameter} />,
    [ParameterId.CombinedGroupRule]: (
      <CombinedGroupRuleSummary {...parameter} />
    ),
    [ParameterId.GroupSegments]: <GroupSegmentsSummary {...parameter} />,
    [ParameterId.Notes]: <NotesSummary {...parameter} />,
    [ParameterId.TimePeriodDataType]: (
      <TimePeriodDataTypeSummary {...parameter} />
    ),
    [ParameterId.Metrics]: <MetricsSummary {...parameter} />,
    [ParameterId.FocalProducts]: <AffinitiesHierarchySummary {...parameter} />,
    [ParameterId.FocalLevel]: <LevelSummary {...parameter} />,
    [ParameterId.AssociatedProducts]: (
      <AffinitiesHierarchySummary {...parameter} />
    ),
    [ParameterId.AssociatedLevel]: <LevelSummary {...parameter} />,
  };

  return (
    <>
      {Object.keys(ParameterMap).includes(parameter.id) ? (
        ParameterMap[parameter.id]
      ) : (
        <Text>Unhandled summary line</Text>
      )}
    </>
  );
};

export const InfoPanelBody = ({
  transactionSource,
  parameterGroups,
}: InfoPanelBodyProps) => {
  const { transactionSources: availableTransactionSources } = useDivision();

  return (
    <>
      <div className={styles.parameterGroup}>
        <h6 className={styles.parameterGroupHeader}>Dataset</h6>
        <div className={styles.parameterGroupContent}>
          {transactionSource?.map((source) => {
            const transactionSourcedisplayName =
              availableTransactionSources.find(
                (ts) => ts.transactionSource === source
              )?.displayName ?? TransactionSourceDisplayNames[source];
            return (
              <span className={styles.dataTypeContent} key={source}>
                <TransactionSourceIcon
                  availableTransactionSources={availableTransactionSources}
                  transactionSource={source}
                />
                <Text>{transactionSourcedisplayName}</Text>
              </span>
            );
          })}
        </div>
      </div>
      {parameterGroups?.map((parameterGroup) => (
        <div className={styles.parameterGroup} key={parameterGroup.name}>
          <h6 className={styles.parameterGroupHeader}>{parameterGroup.name}</h6>
          <div className={styles.parameterGroupContent}>
            {parameterGroup.parameterSelections.map((parameter) => (
              <React.Fragment key={parameter.name}>
                {getParameterSummary(parameter)}
              </React.Fragment>
            ))}
          </div>
        </div>
      ))}
    </>
  );
};
