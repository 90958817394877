import {
  FAST_REPORTING_FEATURE_NAME,
  FeatureFlag,
} from "@quantium-enterprise/common-ui";
import { useFlags } from "@quantium-enterprise/hooks-ui";
import { useCallback, useEffect, useState } from "react";
import { Provider } from "react-redux";
import FocalItemSearchLayout from "./focal-item-search/FocalItemSearchLayout";
import { type HierarchySearchLevel } from "./focal-item-search/HierarchySearchLevel";
import {
  getUniqueKey,
  isSelectableAttributeItem,
  isSelectableAttributeLevelItem,
  isSelectableHierarchyItem,
  type SelectableItem,
} from "./focal-item-search/SelectableItem";
import { store } from "./store";

export type FastReportingFocalItemSearchProps = {
  availableSearchLevels: HierarchySearchLevel[];
  disableSelecting?: boolean;
  disableSelectingTooltip?: string;
  onFocalItemClicked?: (item: SelectableItem) => void;
  onSearchLevelChanged: (level: HierarchySearchLevel) => void;
  onSearchQueryChanged?: (query: string) => void;
  onSelectionChanged: (
    selectedItems: SelectableItem[],
    isValidSelection: boolean
  ) => void;
  searchLevel?: HierarchySearchLevel;
  searchQuery?: string;
};

export const FastReportingFocalItemSearch = ({
  onSelectionChanged,
  searchLevel,
  availableSearchLevels,
  searchQuery,
  onSearchQueryChanged,
  onFocalItemClicked,
  onSearchLevelChanged,
  disableSelecting = false,
  disableSelectingTooltip = "",
}: FastReportingFocalItemSearchProps) => {
  const [selectedItems, setSelectedItems] = useState<SelectableItem[]>([]);
  const flags = useFlags();
  const scanFeatureEntitlementsEnabled =
    flags[FeatureFlag.ScanFeatureEntitlements] ?? false;

  useEffect(() => {
    let isSelectionValid = true;
    for (const item of selectedItems) {
      if (
        scanFeatureEntitlementsEnabled &&
        (isSelectableHierarchyItem(item) ||
          isSelectableAttributeLevelItem(item))
      ) {
        // eslint-disable-next-line unicorn/no-lonely-if -- readability
        if (item.features?.[FAST_REPORTING_FEATURE_NAME] === undefined) {
          isSelectionValid = false;
        }
      }

      if (isSelectableAttributeItem(item) && !item.additionalHierarchyFilter) {
        isSelectionValid = false;
      }
    }

    onSelectionChanged(selectedItems, isSelectionValid);
  }, [scanFeatureEntitlementsEnabled, selectedItems, onSelectionChanged]);

  const handleSelected = useCallback(
    (item: SelectableItem, isSelected: boolean) => {
      setSelectedItems((previous) => {
        const items = [...previous];

        if (isSelected) {
          const indexOfSelected = items.findIndex(
            (x) => getUniqueKey(x) === getUniqueKey(item)
          );

          if (indexOfSelected !== -1) {
            items[indexOfSelected] = item;
            return items;
          }

          return [...items, item];
        }

        return items.filter((x) => getUniqueKey(x) !== getUniqueKey(item));
      });
    },
    []
  );

  return (
    <Provider store={store}>
      <FocalItemSearchLayout
        availableSearchLevels={availableSearchLevels}
        disableSelecting={disableSelecting}
        disableSelectingTooltip={disableSelectingTooltip}
        onFocalItemClicked={onFocalItemClicked}
        onSearchLevelChanged={onSearchLevelChanged}
        onSearchQueryChanged={onSearchQueryChanged}
        onSelectedItem={handleSelected}
        searchLevel={searchLevel ?? availableSearchLevels[0]}
        searchQuery={searchQuery}
      />
    </Provider>
  );
};
