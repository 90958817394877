// eslint-disable-next-line no-warning-comments
// TODO: Hierarchy levels are different across different divisions, so in the future this functionality
// should be placed in a DivisionService similar to what we currently have in Scan. See:
// https://github.com/quantium-enterprise/iip-apollo/blob/master/client/scan/src/services/DivisionService.ts
export enum ProductHierarchy {
  Category = "CT",
  "Category " = "CAT",
  Class = "CLA",
  "Class segment" = "CLS",
  Department = "DT",
  "Department " = "DEP",
  "Merchandising category" = "MDC",
  PSKU = "PSKU",
  Product = "PD",
  "Product " = "PRD",
  "Product profile group" = "PPG",
  Segment = "SE",
  Subcategory = "SC",
  Total = "SM",
  "Total " = "TOT",
}
