import {
  type HierarchyAttributeParameterOption,
  type ParameterOptionDto,
} from "@quantium-enterprise/common-ui";
import CheckboxRow from "./CheckboxRow";
import { HierarchyLabelWithIcon } from "./HierarchyLabelWithIcon";

export type CheckboxGroupProps = {
  childData: ParameterOptionDto[];
  disabledValuesWithReason: Record<string, string>;
  mainTooltipMessage?: string;
  maxSelections: number;
  onChange: (clickedValue: string) => void;
  parameterName: string;
  selectedValues: string[];
};

export const isHierarchyAttributeParameterOption = (
  parameterOptionDto: ParameterOptionDto
): parameterOptionDto is HierarchyAttributeParameterOption =>
  Object.prototype.hasOwnProperty.call(parameterOptionDto, "isHierarchical");

export const CheckboxGroup = ({
  onChange,
  parameterName,
  maxSelections,
  childData,
  selectedValues,
  disabledValuesWithReason,
  mainTooltipMessage,
}: CheckboxGroupProps) => (
  <div data-testid={childData[0].groupName}>
    {childData.map((item) => {
      const isChecked = selectedValues.includes(item.value);

      const isMaxReached = selectedValues.length >= maxSelections && !isChecked;
      const isDisabledByRule = Object.keys(disabledValuesWithReason).includes(
        item.value
      );
      const isDisabled = item.isDisabled || isDisabledByRule || isMaxReached;

      const tooltipText = (() => {
        if (isMaxReached) {
          return (
            mainTooltipMessage ??
            `A maximum of ${maxSelections} ${parameterName} may be selected`
          );
        }

        if (isDisabledByRule) {
          return disabledValuesWithReason[item.value];
        }

        return undefined;
      })();

      return (
        <CheckboxRow
          isChecked={isChecked}
          isDisabled={isDisabled}
          key={item.label}
          label={
            isHierarchyAttributeParameterOption(item) ? (
              <HierarchyLabelWithIcon
                isDisabled={isDisabled}
                parameterOption={item}
              />
            ) : (
              item.label
            )
          }
          onChange={onChange}
          parameterName={parameterName}
          tooltipText={tooltipText}
          value={item.value}
        />
      );
    })}
  </div>
);
