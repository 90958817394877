import { useSelector } from "react-redux";
import ErrorBoundary from "../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { NoDataChartWrapper } from "../../common/components/NoDataChartWrapper";
import {
  selectIsEmptyReport,
  selectIsFetching,
  selectUpliftInitialised,
  selectPurchasedWithAssociatedHasProducts,
} from "../services/basket-affinities-slice";
import { BasketAffinitiesPurchasedWithFocalItemsStatsV2 } from "./BasketAffinitiesPurchasedWithFocalItemsStatsEnhanced";
import { BasketAffinitiesPurchasedWithFocalItemsTableV2 } from "./BasketAffinitiesPurchasedWithFocalItemsTableEnhanced";
import { BasketAffinitiesPurchasedWithFocalItemsTableEnhancedExport } from "./BasketAffinitiesPurchasedWithFocalItemsTableExportEnhanced";

export const BasketAffinitiesPurchasedWithFocalItemsReportlet = () => {
  const isUpliftTableIntialised = useSelector(selectUpliftInitialised);
  const isEmptyReport = useSelector(selectIsEmptyReport);
  const isDataFetching = useSelector(selectIsFetching);
  const isReportletTableHasData = useSelector(
    selectPurchasedWithAssociatedHasProducts
  );

  return (
    <ErrorBoundary>
      <NoDataChartWrapper
        isLoading={!isUpliftTableIntialised || isDataFetching}
        minHeight="500px"
        noData={
          isEmptyReport || (isUpliftTableIntialised && !isReportletTableHasData)
        }
      >
        <BasketAffinitiesPurchasedWithFocalItemsStatsV2 />
        <BasketAffinitiesPurchasedWithFocalItemsTableEnhancedExport />
        <BasketAffinitiesPurchasedWithFocalItemsTableV2 />
      </NoDataChartWrapper>
    </ErrorBoundary>
  );
};
