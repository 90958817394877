import { useDivision } from "@quantium-enterprise/hooks-ui";
import { ErrorLayout } from "components-ui/";

export const FullPageError = () => {
  const division = useDivision();
  return (
    <ErrorLayout>
      <div>
        <p>
          Contact <a href={`mailto:${division.supportEmail}`}>Support</a> if
          error persists.
        </p>
      </div>
    </ErrorLayout>
  );
};
