export enum FeatureFlag {
  ActivationGuidelines = "CO3-6465-Activation-Guidelines",
  AdvancedReportingTabs = "CO3-5573-Advanced-Reporting-Tabs",
  BasketAffinitiesV2Reportlets = "CO3-7106-Basket-Affinities-V2-Reportlets",
  BasketAffinitiesV2Wizard = "CO3-7106-Basket-Affinities-V2-Wizard",
  ChartDownloadPngExport = "CO3-Fast-Reporting-Png-Exports",
  ChartDownloadWizard = "CO3-5871-Chart-Download-Wizard",
  CombineStandardSegments = "CO3-5902-Combine-Standard-Segments",
  DownloadAsExcel = "CO3-7436-Download-As-Excel",
  DownloadAsExcelAggregateRank = "CO3-7436-Download-As-Excel-Aggregate-Rank",
  DownloadAsExcelCustomerCrossShop = "CO3-7436-Download-As-Excel-Customer-Cross-Shop",
  DownloadAsExcelGainsAndLoss = "CO3-7436-Download-As-Excel-Gains-And-Loss",
  DownloadAsExcelKeyDriverTree = "CO3-7436-Download-As-Excel-Key-Driver-Tree",
  DownloadAsExcelKeyMeasureTrends = "CO3-7436-Download-As-Excel-Key-Measure-Trends",
  DownloadGroups = "CO3-5356-Download-Groups",
  EditCustomerGroupButtonInfoPanel = "co-3-5505-edit-customer-group-button-in-info-panel",
  Folders = "CO3-5005-Folders",
  FoldersForGroups = "CO3-5289-Folders-Groups",
  HypercubeExport = "CO3-6981-Hypercube-Export",
  InAppNotifications = "co-3-6542-in-app-notifications",
  LoadRecentReportInReportWizard = "CO3-5773-load-recent-report-in-report-wizard",
  LocationHierarchySelectedItemsToggle = "CO3-6535-location-hierarchy-selected-items",
  NewWatchlistButtons = "CO3-Watchlist-Redesign-Buttons",
  NewWatchlistCsv = "CO3-Watchlist-Redesign-Csv",
  NewWatchlistTable = "CO3-Watchlist-Redesign-Table",
  ProductGroupsTrueShare = "CO3-6950-Product-Groups-True-Share",
  ProductHierarchySelectedItemsToggle = "CO3-215-product-hierarchy-selected-items",
  RangePerformanceDashboard = "CO3-6861-Range-Performance-Dashboard",
  RangePerformanceDriverInsightsCardPanels = "CO3-7843-range-performance-drivers-insights-card-panels",
  ReportWizardCustomerGroupDetails = "co-3-5514-customer-group-details-in-report-wizard",
  Rerun = "CO3-4069-Report-Rerun",
  SavedSelections = "CO3-5763-Saved-Selections",
  ScanEnhanceProductGroups = "CO3-7565-Scan-Enhance-Product-Groups",
  ScanFeatureEntitlements = "CO3-7517-Scan-Feature-Subscriptions",
  SegmentBuilder = "CO3-5711-Segment-Builder",
  SegmentBuilderDetailsDownloads = "CO3-5785-Download-Segment-Details",
  SharingGroups = "CO3-5290-Sharing-Groups",
  SharingReports = "CO3-4079-Sharing",
  TopAndBottomImprovements = "co3-3046-fast-reporting-top-and-bottom-improvements",
}
