import {
  Icon,
  IconColour,
  IconGlyph,
  IconSize,
  Spinner,
  SpinnerSize,
} from "@qbit/react";
import {
  useGetGroupSummaryQuery,
  type TransactionSource,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { TransactionSourceIcon } from "components-ui/src/icons/transaction-source-icon/TransactionSourceIcon";
import styles from "./GroupTransactionSourceIcon.module.css";

export type GroupTransactionSourceIconProps = {
  groupId?: string;
  isDataEntitlementsShown?: boolean;
  isLoading?: boolean;
  transactionSource?: TransactionSource | null;
};

export const GroupTransactionSourceIcon = ({
  isLoading = false,
  transactionSource,
  isDataEntitlementsShown = false,
  groupId,
}: GroupTransactionSourceIconProps) => {
  const {
    transactionSources: availableTransactionSources,
    name: divisionName,
  } = useDivision();

  const { currentData: groupSummary, isFetching: isSummaryFetching } =
    useGetGroupSummaryQuery(
      {
        divisionName,
        groupId: groupId ?? "",
        maxItemsInSubset: 32,
      },
      { skip: !divisionName || !groupId || !isDataEntitlementsShown }
    );

  if (isLoading || isSummaryFetching) {
    return (
      <Spinner
        className={styles.transactionSourceIcon}
        data-testid="loading-group-txn-source-icon"
        size={SpinnerSize.XSmall}
      />
    );
  }

  if (groupSummary && groupSummary.totalItems === 0) {
    return (
      <Icon
        className={styles.transactionSourceIcon}
        colour={IconColour.Bad}
        data-testid="empty-group-txn-source-icon"
        glyph={IconGlyph.AlertsAndNotificationsAlertCircle}
        size={IconSize.Small}
        text="Product group is empty and invalid for selection."
      />
    );
  }

  if (transactionSource === null) {
    return (
      <Icon
        className={styles.transactionSourceIcon}
        colour={IconColour.Bad}
        data-testid="empty-group-txn-source-icon"
        glyph={IconGlyph.AlertsAndNotificationsAlertCircle}
        size={IconSize.Small}
        text="Group is empty."
      />
    );
  }

  return isDataEntitlementsShown ? (
    <TransactionSourceIcon
      availableTransactionSources={availableTransactionSources}
      transactionSource={transactionSource}
    />
  ) : (
    <TransactionSourceIcon />
  );
};
