/* eslint-disable typescript-sort-keys/string-enum */
export abstract class TrackingProperties {
  protected Properties: Record<string, string> | undefined;

  public getProperties(): Record<string, string> | undefined {
    return this.Properties;
  }
}

export enum ParametersTrackingProperty {
  TransactionSourceSelected = "Transaction Source Selected",
  ComparisonTimePeriodSelected = "Comparison Time Period Selected",
  FocalTimePeriod = "Focal Time Period Selected",
  MetricSelected = "Metric Selected",
  MetricDeselected = "Metric Deselected",
  BenchmarkSelected = "Benchmark Selected",
  LocationLevelSelected = "Location Level Selected",
  LocationSelected = "Location Selected",
  ChannelSelected = "Channel Selected",
  PromotionSelected = "Promo Selected",
  BannerSelected = "Banner Selected",
  LevelOfAnalysisSelected = "Level of Analysis Selected",
  SegmentationSelected = "Segmentation Selected",
  StoreFormatSelected = "Store Format Selected",
  CompStoreSelected = "Comp Store Selected",
}

export enum GroupsTrackingProperty {
  ActionInitiator = "ActionInitiator",
  DeletedIds = "Deleted Ids",
  DeletionType = "Deletion Type",
  EntityType = "Entity Type",
  GroupStatus = "Group Status",
  GroupType = "Group Type",
  IsShared = "Is Shared",
  LocationCount = "Location Count",
  OwnerId = "Owner Id",
  ProductCount = "Product Count",
  RulesCount = "Rules Count",
  SharedWith = "Shared With",
  UnsharedWith = "Unshared With",
}

export enum UnknownTrackingPropertyValue {
  Unknown = "Unknown",
}
