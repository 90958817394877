import {
  Group,
  GroupRowspace,
  Item,
  ItemHalign,
  ItemWidth,
  Text,
  Button,
  ButtonVariant,
  IconGlyph,
  Icon,
} from "@qbit/react";
import {
  type WatchlistCompStoreDto,
  type WatchlistMetricDto,
  type WatchlistComparisonPeriodDto,
  type WatchlistFocusPeriodDto,
  type WatchlistTransactionSourceDto,
  type WatchlistParametersDto,
} from "../services/dtos/WatchlistDto";
import { type WatchlistItemDto } from "../services/dtos/WatchlistItemDto";
import AddItemsButton from "./AddItemsButton";
import styles from "./WatchlistButtons.module.css";
import { WatchlistOptions } from "./WatchlistOptions";
import ParameterSelectorDropdown from "./parameter-selector-dropdown/ParameterSelectorDropdown";
import ParameterSelectorToggleGroup from "./parameter-selector-toggle-group/ParameterSelectorToggleGroup";
import {
  type WatchlistItemWithMetrics,
  type MetricDefinition,
} from "./watchlist-table/WatchlistTable";

export type SelectedParameters = {
  compStore?: WatchlistCompStoreDto;
  comparisonPeriod: WatchlistComparisonPeriodDto;
  focusPeriod: WatchlistFocusPeriodDto;
  metrics: WatchlistMetricDto[];
  transactionSource?: WatchlistTransactionSourceDto;
};

export type NoItemsSelectedButtonsProps = {
  chosenMetrics: MetricDefinition[];
  chosenParameters: SelectedParameters;
  filterCustomerMetrics: boolean;
  handleCompStoreChanged: (items: WatchlistCompStoreDto[]) => void;
  handleComparisonPeriodChanged: (
    items: WatchlistComparisonPeriodDto[]
  ) => void;
  handleMetricsChanged: (items: WatchlistMetricDto[]) => void;
  handleShowHierarchySearch: () => void;
  handleTransactionSourceChanged: (
    items: WatchlistTransactionSourceDto[]
  ) => void;
  handlefocusPeriodChanged: (items: WatchlistFocusPeriodDto[]) => void;
  newWatchlistCsv: boolean;
  nonCustomerMetrics: WatchlistMetricDto[];
  tableItems: WatchlistItemWithMetrics[];
  watchlistParameters: WatchlistParametersDto;
};

export type ItemsSelectedButtonsProps = {
  chosenMetrics: MetricDefinition[];
  chosenParameters: SelectedParameters;
  handleDelete: (
    itemsToDelete: Set<string> | WatchlistItemDto[]
  ) => Promise<void>;
  handleShowHierarchySearch: () => void;
  newWatchlistCsv: boolean;
  selectedWatchlistItems: Set<string>;
  tableItems: WatchlistItemWithMetrics[];
};

const displaySelector = (
  item:
    | WatchlistComparisonPeriodDto
    | WatchlistCompStoreDto
    | WatchlistFocusPeriodDto
    | WatchlistTransactionSourceDto
) => <Text>{item.label}</Text>;

const metricDisplaySelector = (item: WatchlistMetricDto) => (
  <Text>{item.metadata.displayName}</Text>
);

export const NoItemsSelectedButtons = ({
  watchlistParameters,
  chosenMetrics,
  chosenParameters,
  handleShowHierarchySearch,
  filterCustomerMetrics,
  nonCustomerMetrics,
  handleMetricsChanged,
  handlefocusPeriodChanged,
  handleComparisonPeriodChanged,
  handleTransactionSourceChanged,
  handleCompStoreChanged,
  newWatchlistCsv,
  tableItems,
}: NoItemsSelectedButtonsProps) => (
  <>
    <Group>
      <Item>
        <div className={styles.heading}>
          <h4>Watchlist</h4>
        </div>
      </Item>
      <Item halign={ItemHalign.Right} width={ItemWidth.Fit}>
        <Group className={styles.watchlistOptionsContainer}>
          <Item>
            <AddItemsButton onClick={handleShowHierarchySearch} />
          </Item>
          {newWatchlistCsv && (
            <>
              <div className={styles.optionsSeparator} />
              <Item>
                <WatchlistOptions
                  metrics={chosenMetrics}
                  selectedParameters={chosenParameters}
                  watchlistTableItems={tableItems}
                />
              </Item>
            </>
          )}
        </Group>
      </Item>
    </Group>

    <div className={styles.parameterContainer}>
      <Group rowspace={GroupRowspace.Medium}>
        <Item halign={ItemHalign.Left} width={ItemWidth.Fit}>
          <Group>
            <Item>
              <ParameterSelectorToggleGroup
                data-cy="FocusPeriodSelector"
                defaultSelection={chosenParameters.focusPeriod}
                itemDisplaySelector={(item) => item.label}
                itemKeySelector={(item) => item.focusPeriod}
                items={watchlistParameters.focusPeriods}
                onSelectionChanged={handlefocusPeriodChanged}
              />
            </Item>
            <Item className={styles.newParameter}>
              <ParameterSelectorDropdown
                buttonText={chosenParameters.comparisonPeriod.label}
                data-cy="ComparisonPeriodSelector"
                defaultSelection={[chosenParameters.comparisonPeriod]}
                disableLastSelected
                isMultiSelect={false}
                itemDisplaySelector={displaySelector}
                itemKeySelector={(item: WatchlistComparisonPeriodDto) =>
                  item.comparisonPeriod
                }
                items={watchlistParameters.comparisonPeriods}
                onSelectionChanged={handleComparisonPeriodChanged}
              />
            </Item>
            <div className={styles.parameterSeparator} />
            {chosenParameters.transactionSource && (
              <>
                <Item className={styles.newParameter}>
                  <ParameterSelectorDropdown
                    buttonText={chosenParameters.transactionSource.label}
                    data-cy="DatasetSelector"
                    defaultSelection={[chosenParameters.transactionSource]}
                    disableLastSelected
                    isMultiSelect={false}
                    itemDisplaySelector={displaySelector}
                    itemKeySelector={(item) => item.label}
                    items={watchlistParameters.transactionSources}
                    onSelectionChanged={handleTransactionSourceChanged}
                  />
                </Item>
                <div className={styles.parameterSeparator} />
              </>
            )}
            {chosenParameters.compStore && (
              <>
                <Item className={styles.newParameter}>
                  <ParameterSelectorDropdown
                    buttonText={chosenParameters.compStore.label}
                    defaultSelection={[chosenParameters.compStore]}
                    disableLastSelected
                    isMultiSelect={false}
                    itemDisplaySelector={displaySelector}
                    itemKeySelector={(item) => item.label}
                    items={watchlistParameters.compStores}
                    onSelectionChanged={handleCompStoreChanged}
                  />
                </Item>
                <div className={styles.parameterSeparator} />
              </>
            )}
            <Item className={styles.newParameter}>
              <ParameterSelectorDropdown
                buttonText={`Metrics (${chosenMetrics.length})`}
                data-cy="MetricSelector"
                defaultSelection={chosenParameters.metrics}
                disableLastSelected={false}
                isMultiSelect
                itemDisplaySelector={metricDisplaySelector}
                itemKeySelector={(item) => item.key}
                items={
                  filterCustomerMetrics
                    ? nonCustomerMetrics
                    : watchlistParameters.watchlistMetrics
                }
                onSelectionChanged={handleMetricsChanged}
              />
            </Item>
          </Group>
        </Item>
      </Group>
    </div>
  </>
);

export const ItemsSelectedButtons = ({
  selectedWatchlistItems,
  handleDelete,
  handleShowHierarchySearch,
  newWatchlistCsv,
  chosenMetrics,
  chosenParameters,
  tableItems,
}: ItemsSelectedButtonsProps) => (
  <>
    <Group>
      <Item>
        <div className={styles.heading}>
          <h4>Watchlist</h4>
        </div>
      </Item>
      <Item halign={ItemHalign.Right} width={ItemWidth.Fit}>
        <Group className={styles.watchlistOptionsContainer}>
          <Item>
            <AddItemsButton onClick={handleShowHierarchySearch} />
          </Item>
          {newWatchlistCsv && (
            <>
              <div className={styles.optionsSeparator} />
              <Item>
                <WatchlistOptions
                  metrics={chosenMetrics}
                  selectedParameters={chosenParameters}
                  watchlistTableItems={tableItems}
                />
              </Item>
            </>
          )}
        </Group>
      </Item>
    </Group>

    <div className={styles.deleteButtonContainer}>
      <Button
        className={styles.deleteSelectedButton}
        data-cy="DeleteSelectedItemsButton"
        onClick={async () => await handleDelete(selectedWatchlistItems)}
        variant={ButtonVariant.Danger}
      >
        <Icon glyph={IconGlyph.DeleteAndCloseDelete} text="" />
        <Text>{`Delete (${selectedWatchlistItems.size})`}</Text>
      </Button>
    </div>
  </>
);
