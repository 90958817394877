// src/hooks/useAppcues.ts
import { AppContext, ENVIRONMENT } from "@quantium-enterprise/common-ui";
import { useEffect, useContext, useRef } from "react";

// This is a snippet of code needed for Appcues to work in Typescript
// https://docs.appcues.com/dev-installing-appcues/installation-overview-for-developers
type WindowWithAppcues = Window & {
  Appcues: {
    identify: (id: string, userData: Record<string, string>) => void;
  };
  AppcuesSettings: {
    enableURLDetection: boolean;
  };
};

declare const window: WindowWithAppcues;

const ENABLE_APPCUES = ENVIRONMENT.VITE_ENABLE_APPCUES;
const APPCUES_SETTINGS_SCRIPT_ID = ENVIRONMENT.VITE_APPCUES_SETTINGS_SCRIPT_ID;
const APPCUES_SCRIPT_ID = ENVIRONMENT.VITE_APPCUES_SCRIPT_ID;
const APPCUES_ACCOUNT_ID = ENVIRONMENT.VITE_APPCUES_ACCOUNT_ID;

const hasRequiredEnvironmentVariables = Boolean(
  ENABLE_APPCUES &&
    APPCUES_SETTINGS_SCRIPT_ID &&
    APPCUES_SCRIPT_ID &&
    APPCUES_ACCOUNT_ID
);

const isAppcuesEnabled =
  hasRequiredEnvironmentVariables && [true, "true"].includes(ENABLE_APPCUES);

export const useAppcues = () => {
  const { userState } = useContext(AppContext);
  const isMounted = useRef(false);

  // Initialize Appcues scripts on first mount only
  useEffect(() => {
    if (!isAppcuesEnabled) return;
    if (isMounted.current) return;

    try {
      // Ensure this script is only run once
      isMounted.current = true;

      // Initialize URL change detection settings
      const settingsScript = document.createElement("script");
      settingsScript.id = APPCUES_SETTINGS_SCRIPT_ID.toString();
      settingsScript.type = "text/javascript";
      settingsScript.textContent =
        "window.AppcuesSettings = { enableURLDetection: true };";
      document.body.appendChild(settingsScript);

      // Load Appcues SDK script
      const script = document.createElement("script");
      script.id = APPCUES_SCRIPT_ID.toString();
      script.src = `//fast.appcues.com/${APPCUES_ACCOUNT_ID.toString()}.js`;
      script.async = true;
      document.body.appendChild(script);
    } catch {
      // Since Appcues is not a core feature, ignore errors to ensure main app can still run
    }
  }, []);

  // Handle user identification
  useEffect(() => {
    if (!isAppcuesEnabled) return;

    try {
      // we still need to check if the Appcues object exists
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (userState.currentUser && window.Appcues) {
        window.Appcues.identify(userState.currentUser.salesForceId, {
          email: userState.currentUser.email,
          name: userState.currentUser.fullName,
        });
      }
    } catch {
      // Since Appcues is not a core feature, ignore errors to ensure main app can still run
    }
  }, [userState.currentUser, isAppcuesEnabled, window.Appcues]);
};
