import {
  type ColumnsMetaData,
  type AffinitiesRow,
  type purchasedWithAssociatedData,
} from "../models/basket-affinities-chart-models";

export const purchasedWithAssociatedFiltered: (
  purchasedWithAssociated: AffinitiesRow[],
  upliftThreshold: number,
  basketsWithBothThreshold: number,
  columnsMetaData: ColumnsMetaData[]
) => purchasedWithAssociatedData[] = (
  purchasedWithAssociated: AffinitiesRow[],
  upliftThreshold: number,
  basketsWithBothThreshold: number,
  columnsMetaData: ColumnsMetaData[]
) => {
  const upliftColumn = columnsMetaData.findIndex(
    (column) => column.label === "Uplift"
  );
  const basketsWithBothColumn = columnsMetaData.findIndex(
    (column) => column.label === "Baskets with both"
  );
  const confidenceColumn = columnsMetaData.findIndex(
    (column) =>
      column.label ===
      "Confidence (Baskets with both / Baskets with focal item)"
  );
  const supportColumn = columnsMetaData.findIndex(
    (column) => column.label === "Support (Associated baskets/ Total baskets)"
  );
  const associatedSalesColumn = columnsMetaData.findIndex(
    (column) => column.label === "Associated sales per basket"
  );

  const filteredData: purchasedWithAssociatedData[] = [];

  // eslint-disable-next-line unicorn/no-array-reduce
  purchasedWithAssociated.reduce((accumulator, item) => {
    const basketWithBoth = item.affinitiesData[basketsWithBothColumn] ?? 0;
    const uplift = item.affinitiesData[upliftColumn] ?? 0;

    if (
      basketWithBoth >= basketsWithBothThreshold &&
      uplift >= upliftThreshold
    ) {
      filteredData.push({
        product: item.associatedItem,
        uplift: item.affinitiesData[upliftColumn],
        basketsWithBoth: item.affinitiesData[basketsWithBothColumn],
        confidence: item.affinitiesData[confidenceColumn],
        support: item.affinitiesData[supportColumn],
        associatedSales: item.affinitiesData[associatedSalesColumn],
      });
    }

    return accumulator;
  }, []);

  // sort by associatedItemsCount desc, associatedAncestor.name asc
  return Object.values(filteredData).sort(
    (item1, item2) =>
      (item2.uplift ?? 0) - (item1.uplift ?? 0) ||
      item1.product.name.localeCompare(item2.product.name)
  );
};
