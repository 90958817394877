import {
  type ParameterDto,
  isTrendedAndAggregatedParameterOption,
  type SelectionDto,
  isListSelectionDto,
} from "@quantium-enterprise/common-ui";
import { createSelector } from "@reduxjs/toolkit";
import { type ParameterState } from "../../states/ParameterState";
import { type RootState } from "../../store";

// Defines the state shape for time period type parameters
export type timePeriodDataTypeState = ParameterState & {
  errors: Record<string, boolean>;
  options: TimePeriodDataTypeOption[];
  value: string;
};

type TimePeriodDataTypeOption = {
  isDefault: boolean;
  isDisabled: boolean;
  label: string;
  timePeriodType: string;
  value: string;
};

const initialParameterState = (
  parameterConfig: ParameterDto,
  parameterGroup: string
): timePeriodDataTypeState => ({
  value: "",
  isValid: false,
  options: [],
  errors: { value: true },
  parameterConfig,
  parameterGroup,
});

export const isTimePeriodDataTypeState = (
  state: ParameterState
): state is timePeriodDataTypeState => Object.hasOwn(state, "options");

export const validateTimePeriodDataType = (
  timePeriodType: timePeriodDataTypeState
) => {
  const errors: Record<string, boolean> = {};
  if (!timePeriodType.value) {
    errors.value = true;
  }

  const isValid = Object.keys(errors).length === 0;
  return { errors, isValid };
};

export const newTimePeriodDataTypeState = (
  parameterConfig: ParameterDto,
  parameterGroup: string,
  savedSelections?: SelectionDto[]
): timePeriodDataTypeState => {
  const options = parameterConfig.options
    .filter(isTrendedAndAggregatedParameterOption)
    .map((option) => ({
      value: option.value,
      label: option.label,
      isDefault: option.isDefault,
      isDisabled: option.isDisabled,
      // Mapping timePeriodReportType to timePeriodType
      timePeriodType: option.timePeriodDataType,
    }));

  const baseState: timePeriodDataTypeState = {
    ...initialParameterState(parameterConfig, parameterGroup),
    options,
  };

  const prefilledSelection =
    savedSelections ?? parameterConfig.selections ?? [];

  if (prefilledSelection.length > 0) {
    const selection = prefilledSelection[0];
    if (isListSelectionDto(selection)) {
      const updatedState = {
        ...baseState,
        value: selection.value,
      };
      const validation = validateTimePeriodDataType(updatedState);
      return {
        ...updatedState,
        ...validation,
      };
    }
  }

  const defaultOption = options.find((option) => option.isDefault);
  if (defaultOption) {
    const updatedState = {
      ...baseState,
      value: defaultOption.value,
    };
    const validation = validateTimePeriodDataType(updatedState);
    return {
      ...updatedState,
      ...validation,
    };
  }

  return baseState;
};

export const handleTimePeriodDataTypeSelection = (
  timePeriodDataTypeState: ParameterState,
  selection: string
): ParameterState => {
  if (!isTimePeriodDataTypeState(timePeriodDataTypeState)) {
    return { ...timePeriodDataTypeState };
  }

  const selectedOption = timePeriodDataTypeState.options.find(
    (opt) => opt.value === selection
  );

  if (selectedOption) {
    const updatedState = {
      ...timePeriodDataTypeState,
      value: selection,
    };
    const validation = validateTimePeriodDataType(updatedState);
    return {
      ...updatedState,
      ...validation,
    };
  }

  return initialParameterState(
    timePeriodDataTypeState.parameterConfig,
    timePeriodDataTypeState.parameterGroup
  );
};

export const getTimePeriodDataTypeState = (parameterId: string) =>
  createSelector(
    (state: RootState) => state.reportParameter.parameters[parameterId],
    (state) => state as timePeriodDataTypeState | undefined
  );
