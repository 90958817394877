import {
  type HierarchyType,
  ddLog,
  type NodeExpansionRequestDto,
  type HierarchyItem,
  useLazyExpandQuery,
  LocationHierarchy,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import {
  type RowSelectionState,
  type Updater,
  type Row,
  type CellContext,
  type ColumnDef,
} from "@tanstack/react-table";
import { DataSourceHeader } from "components-ui/src/hierarchy-select-grid/Header";
import { DEFAULT_COLUMN_WIDTH } from "components-ui/src/tables/common/constants";
import { ExpandableNameCell } from "components-ui/src/tables/common/table-cell/ExpandableNameCell";
import { ReportHierarchyTable } from "components-ui/src/tables/report-hierarchy-table/ReportHierarchyTable";
import { ReportHierarchyTableWrapper } from "components-ui/src/tables/report-hierarchy-table/components/ReportHierarchyTableWrapper";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectHierarchyItems,
  selectHierarchyExpandedRows,
  selectHierarchySelectedRows,
  setHierarchyNodeExpanding,
  onHierarchyChildNodesReceived,
  onHierarchyNodeSelection,
  hierarchyNodeExpanded,
  selectLeafNodeShortName,
  selectSearchString,
  selectSubscription,
  toggleAllHierarchyNodes,
  selectIsAllChecked,
} from "../../states/group-hierarchy-source-slice";
import {
  getFeatureFilter,
  getFeatureModules,
  getTransactionSourceFilter,
} from "../../utilities/group-subscription-utils";
import { TransactionSourceCell } from "../transaction-source-cell/TransactionSourceCell";
import styles from "./GroupHierarchyTable.module.css";
import { GroupHierarchyTableHeader } from "./GroupHierarchyTableHeader";

const PAGE_SIZE = 500;

export type NestedHierarchyItem = HierarchyItem & {
  isExpanding?: boolean;
  isMoreRow?: boolean;
  loadMoreParentRow?: NestedHierarchyItem;
  parentRow?: NestedHierarchyItem;
  subRows?: NestedHierarchyItem[];
};

export type GroupHierarchyTableProps = {
  displayEntitlements?: boolean;
  hierarchyType: HierarchyType;
  isSuccess: boolean;
};

export const GroupHierarchyTable = ({
  displayEntitlements = false,
  isSuccess,
  hierarchyType,
}: GroupHierarchyTableProps) => {
  const transactionSourceColumnWidth = 40;
  const productNameColumnWidth = 250;
  const dispatch = useDispatch();
  const {
    name: activeDivisionName,
    transactionSources: availableTransactionSources,
  } = useDivision();

  const [rowsPagination, setRowsPagination] = useState<Record<string, number>>(
    {}
  );

  const leafNodeShortName = useSelector(selectLeafNodeShortName);
  const items = useSelector(selectHierarchyItems);
  const selectedRows = useSelector(selectHierarchySelectedRows);
  const expandedRows = useSelector(selectHierarchyExpandedRows);
  const subscription = useSelector(selectSubscription);

  const getNodeId = useMemo(
    () => (shortName: string, code: string) => `${shortName}.${code}`,
    []
  );

  const createRowId = useCallback(
    (row: NestedHierarchyItem) =>
      row.loadMoreParentRow?.parent
        ? `${getNodeId(
            row.loadMoreParentRow.parent.shortName,
            row.loadMoreParentRow.parent.code
          )}-load-more`
        : getNodeId(row.shortName, row.code),
    [getNodeId]
  );

  const updatePaginationState = (shortName: string, code: string) => {
    setRowsPagination((pageState) => {
      const rowId = `${shortName}.${code}`;
      const newPageState = { ...pageState };
      newPageState[rowId] = newPageState[rowId] ? newPageState[rowId] + 1 : 1;

      return newPageState;
    });
  };

  const [triggerExpandQuery] = useLazyExpandQuery();

  const fetchChildrenNodesData = useCallback(
    async (
      payload: NodeExpansionRequestDto,
      featureModules: string[] | undefined
    ) => {
      const result = await triggerExpandQuery({
        division: activeDivisionName,
        featureModules,
        hierarchyType,
        payload,
      }).unwrap();

      if (result.count > 0 && leafNodeShortName) {
        dispatch(
          onHierarchyChildNodesReceived({
            childNodes: result,
            leafNodeShortName,
            overwrite: true,
          })
        );

        const parentNodeShortName = result.results[0].parent.shortName;
        const parentNodeCode = result.results[0].parent.code;

        updatePaginationState(parentNodeShortName, parentNodeCode);

        dispatch(
          setHierarchyNodeExpanding({
            nodeCode: parentNodeCode,
            nodeShortName: parentNodeShortName,
          })
        );
      }
    },
    [
      activeDivisionName,
      hierarchyType,
      triggerExpandQuery,
      leafNodeShortName,
      dispatch,
    ]
  );

  const searchString = useSelector(selectSearchString);

  useEffect(() => {
    if (isSuccess && items.length > 0 && items[0].subRows === undefined) {
      dispatch(
        setHierarchyNodeExpanding({
          nodeCode: items[0].code,
          nodeShortName: items[0].shortName,
        })
      );

      fetchChildrenNodesData(
        {
          featureFilter: getFeatureFilter(subscription),
          page: 0,
          pageSize: PAGE_SIZE,
          parent: {
            code: items[0].code,
            shortName: items[0].shortName,
          },
          transactionSourceFilter: getTransactionSourceFilter(subscription),
        },
        getFeatureModules(subscription)
      ).catch((error) => {
        ddLog(
          "Error expanding hierarchy node in static group editor.",
          {},
          "error",
          error
        );
      });
    }
  }, [isSuccess, items, fetchChildrenNodesData, dispatch, subscription]);

  useEffect(() => {
    if (isSuccess && items.length > 0 && items[0].subRows === undefined) {
      dispatch(
        setHierarchyNodeExpanding({
          nodeCode: items[0].code,
          nodeShortName: items[0].shortName,
        })
      );

      fetchChildrenNodesData(
        {
          featureFilter: getFeatureFilter(subscription),
          page: 0,
          pageSize: PAGE_SIZE,
          parent: {
            code: items[0].code,
            shortName: items[0].shortName,
          },
          transactionSourceFilter: getTransactionSourceFilter(subscription),
        },
        getFeatureModules(subscription)
      ).catch((error) => {
        ddLog(
          "Error expanding hierarchy node in static group editor.",
          {},
          "error",
          error
        );
      });
    }
  }, [isSuccess, items, fetchChildrenNodesData, dispatch, subscription]);

  const onToggleExpansionHandler = useCallback(
    (row: Row<NestedHierarchyItem>) => {
      row.toggleExpanded();

      const expandedRowIndex = expandedRows.findIndex(
        (expandedRow) =>
          expandedRow.code === row.original.code &&
          expandedRow.shortName === row.original.shortName
      );

      const newExpandedRows: HierarchyItem[] =
        expandedRowIndex === -1
          ? [...expandedRows, row.original]
          : [
              ...expandedRows.filter(
                (expandedRow) =>
                  getNodeId(expandedRow.shortName, expandedRow.code) !==
                  getNodeId(row.original.shortName, row.original.code)
              ),
            ];

      dispatch(
        hierarchyNodeExpanded({
          expandedRows: newExpandedRows,
        })
      );

      (async () => {
        if (row.subRows.length === 0) {
          dispatch(
            setHierarchyNodeExpanding({
              nodeCode: row.original.code,
              nodeShortName: row.original.shortName,
            })
          );

          await fetchChildrenNodesData(
            {
              featureFilter: getFeatureFilter(subscription),
              page: 0,
              pageSize: PAGE_SIZE,
              parent: {
                code: row.original.code,
                shortName: row.original.shortName,
              },
              transactionSourceFilter: getTransactionSourceFilter(subscription),
            },
            getFeatureModules(subscription)
          );
        }
      })();
    },
    [dispatch, expandedRows, fetchChildrenNodesData, getNodeId, subscription]
  );

  const onToggleSelectionHandler = useCallback(
    (row: Row<NestedHierarchyItem>) => {
      row.getToggleSelectedHandler();

      // row.getIsSelected provides the opposite state for checkbox
      const isChecked = !row.getIsSelected();

      // Filter out the current row add it back in if checked later
      let newSelectedRows: HierarchyItem[] = selectedRows.filter(
        (selectedRow) =>
          getNodeId(selectedRow.shortName, selectedRow.code) !==
          getNodeId(row.original.shortName, row.original.code)
      );

      if (isChecked) {
        newSelectedRows = [...newSelectedRows, row.original];
      }

      dispatch(onHierarchyNodeSelection(newSelectedRows));
    },
    [dispatch, selectedRows, getNodeId]
  );

  const onMoreClickedHandler = async (row: Row<NestedHierarchyItem>) => {
    if (row.original.loadMoreParentRow?.parent) {
      const parentNodeCode = row.original.loadMoreParentRow.parent.code;
      const parentNodeShortName =
        row.original.loadMoreParentRow.parent.shortName;
      const nextPage =
        rowsPagination[getNodeId(parentNodeShortName, parentNodeCode)];

      await fetchChildrenNodesData(
        {
          featureFilter: getFeatureFilter(subscription),
          page: nextPage,
          pageSize: PAGE_SIZE,
          parent: {
            code: parentNodeCode,
            shortName: parentNodeShortName,
          },
          transactionSourceFilter: getTransactionSourceFilter(subscription),
        },
        getFeatureModules(subscription)
      );
    }
  };

  const hasSearchText = Boolean(searchString.trim());

  const isCheckedSelectAll = useSelector(selectIsAllChecked);

  const toggleSelectAll = useCallback(
    (checked: boolean) => {
      dispatch(toggleAllHierarchyNodes({ checked }));
    },
    [dispatch]
  );

  const header = useCallback(
    () => (
      <GroupHierarchyTableHeader
        isChecked={isCheckedSelectAll}
        showCheckbox={hasSearchText}
        title={hierarchyType}
        toggleSelectAll={toggleSelectAll}
      />
    ),
    [toggleSelectAll, hierarchyType, isCheckedSelectAll, hasSearchText]
  );

  const cell = useCallback(
    ({ row, getValue }: CellContext<NestedHierarchyItem, unknown>) => {
      // prepend code for stores only
      const displayValue =
        row.original.shortName === LocationHierarchy.Store
          ? `${row.original.code} - ${String(getValue())}`
          : String(getValue());

      return (
        <ExpandableNameCell
          canExpand={!row.original.isLeaf && searchString === ""}
          depth={row.depth}
          handleToggleExpanded={() => onToggleExpansionHandler(row)}
          handleToggleSelected={() => onToggleSelectionHandler(row)}
          isCompact
          isExpanded={row.subRows.length > 0 && row.getIsExpanded()}
          isExpanding={row.original.isExpanding}
          isSelected={row.getIsSelected()}
          name={row.original.name}
          shortName={row.original.shortName}
          type={row.original.type}
          value={displayValue}
        />
      );
    },
    [onToggleExpansionHandler, onToggleSelectionHandler, searchString]
  );

  const transactionSourceHeader = useCallback(
    () => <DataSourceHeader title="Dataset" />,
    []
  );

  const transactionSourceCell = useCallback(
    ({ row }: CellContext<NestedHierarchyItem, unknown>) => (
      <TransactionSourceCell
        accessLevelShortName={row.original.shortName}
        availableTransactionSources={availableTransactionSources}
        row={row}
        subscription={subscription}
      />
    ),
    [availableTransactionSources, subscription]
  );

  const columns = useMemo<Array<ColumnDef<NestedHierarchyItem>>>(() => {
    const columnDefinitions = [
      {
        accessorKey: "name",
        cell,
        header,
        size: productNameColumnWidth,
      },
    ];

    if (displayEntitlements) {
      columnDefinitions.push({
        accessorKey: "dataset",
        cell: transactionSourceCell,
        header: transactionSourceHeader,
        size: transactionSourceColumnWidth,
      });
    }

    return columnDefinitions;
  }, [
    cell,
    displayEntitlements,
    header,
    transactionSourceCell,
    transactionSourceHeader,
  ]);

  const handleRowSelectionsChange = (
    newRowSelections: Updater<RowSelectionState>
  ) => {
    const selectionIds = Object.keys(newRowSelections);

    const newSelectedRows = items.filter((item) =>
      selectionIds.includes(item.code)
    );

    dispatch(onHierarchyNodeSelection(newSelectedRows));
  };

  const rowSelectionState = useMemo(() => {
    const newRowSelectionState: Record<string, boolean> = {};

    for (const selectedRow of selectedRows) {
      newRowSelectionState[getNodeId(selectedRow.shortName, selectedRow.code)] =
        true;
    }

    return newRowSelectionState;
  }, [selectedRows, getNodeId]);

  const rowExpansionState = useMemo(() => {
    const newRowExpansionState: Record<string, boolean> = {};

    for (const expandedRow of expandedRows) {
      newRowExpansionState[getNodeId(expandedRow.shortName, expandedRow.code)] =
        true;
    }

    return newRowExpansionState;
  }, [expandedRows, getNodeId]);

  return (
    <div className={styles.groupHierarchy}>
      <ReportHierarchyTableWrapper isSuccess={isSuccess}>
        <ReportHierarchyTable
          className={styles.table}
          // FIXME: should actually be optional and should not allow resizing for single column
          // https://jira.quantium.com.au/browse/CO3-1855
          columnResizeMode="onChange"
          columns={columns}
          compactRows
          data={items}
          defaultColumnWidthOverride={
            displayEntitlements
              ? {
                  minSize: transactionSourceColumnWidth,
                  size: DEFAULT_COLUMN_WIDTH,
                  maxSize: productNameColumnWidth,
                }
              : undefined
          }
          depthPadding={50}
          disableSorting
          getRowId={(row: NestedHierarchyItem) => createRowId(row)}
          getSubRows={(row) => row.subRows}
          // FIXME: should actually be optional and should not be passed in if not needed
          // https://jira.quantium.com.au/browse/CO3-1856
          handleMoreClicked={async (row: Row<NestedHierarchyItem>) =>
            await onMoreClickedHandler(row)
          }
          moreText="Load More..."
          rowExpandedState={rowExpansionState}
          rowSelectionState={rowSelectionState}
          setRowSelection={handleRowSelectionsChange}
        />
      </ReportHierarchyTableWrapper>
    </div>
  );
};
