import {
  Button,
  ButtonVariant,
  Dropdown,
  Icon,
  IconGlyph,
  Menu,
  MenuItemButton,
  MenuSection,
  Text,
} from "@qbit/react";
import {
  getTransactionSourceFromEntitlements,
  useGetUserQuery,
} from "@quantium-enterprise/common-ui";
import { DownloadIcon } from "components-ui/src/assets/icons/DownloadIcon";
import {
  downloadFile,
  toCSVEncoded,
} from "components-ui/src/export/export-functions";
import { useMemo } from "react";
import { type SelectedParameters } from "./WatchlistButtons";
import {
  type MetricDefinition,
  type WatchlistItemWithMetrics,
} from "./watchlist-table/WatchlistTable";

export type WatchlistOptionsProps = {
  metrics: MetricDefinition[];
  selectedParameters: SelectedParameters;
  watchlistTableItems: WatchlistItemWithMetrics[];
};

export const WatchlistOptions = ({
  watchlistTableItems,
  selectedParameters,
  metrics,
}: WatchlistOptionsProps) => {
  const { data: user } = useGetUserQuery();
  const isSupplier = useMemo(() => user?.isSupplier, [user]);
  const displayDatasetColumn =
    selectedParameters.transactionSource !== undefined || isSupplier === true;

  const getCSVData = (): string[][] => {
    const parameterRows: string[] = [
      `Focus Period: ${selectedParameters.focusPeriod.label}`,
      `Comparison Period: ${selectedParameters.comparisonPeriod.label}`,
      ...(selectedParameters.transactionSource
        ? [`Dataset: ${selectedParameters.transactionSource.label}`]
        : []),
      ...(selectedParameters.compStore
        ? [`Comp Store: ${selectedParameters.compStore.label}`]
        : []),
    ];

    const headers = ["Item"];

    if (displayDatasetColumn) {
      headers.push("Dataset");
    }

    for (const metric of metrics) {
      headers.push(
        `${metric.displayName} - ${selectedParameters.focusPeriod.label}`
      );
      headers.push(
        `${metric.displayName} - ${selectedParameters.comparisonPeriod.label}`
      );
      headers.push(`${metric.displayName} - Change`);
    }

    const rows = [parameterRows, headers];

    for (const item of watchlistTableItems) {
      const row = [item.name];

      if (displayDatasetColumn) {
        row.push(
          selectedParameters.transactionSource?.label ??
            getTransactionSourceFromEntitlements(item.entitlements) ??
            "N/A"
        );
      }

      for (const metric of metrics) {
        const metricData = item.metrics[metric.key];

        row.push(
          metricData?.focalPeriod.hasValue
            ? metricData.focalPeriod.value.toString()
            : "-"
        );
        row.push(
          metricData?.comparisonPeriod.hasValue
            ? metricData.comparisonPeriod.value.toString()
            : "-"
        );
        row.push(
          metricData?.growth.hasValue
            ? metricData.growth.value.toString()
            : "N/A"
        );
      }

      rows.push(row);
    }

    return rows;
  };

  const handleCsvDownload = () => {
    const csvUri = toCSVEncoded(undefined, undefined, undefined, getCSVData());
    // Replace with watchlist name once implemented
    downloadFile(csvUri, "Watchlist.csv");
  };

  return (
    <Dropdown
      trigger={
        <Button variant={ButtonVariant.Stealth}>
          <Icon glyph={IconGlyph.MenuAndSettingsMoreVert} text="" />
        </Button>
      }
    >
      <Menu>
        <MenuSection>
          <MenuItemButton
            contentStart={<DownloadIcon />}
            onClick={handleCsvDownload}
            text={<Text>Download</Text>}
          />
        </MenuSection>
      </Menu>
    </Dropdown>
  );
};
