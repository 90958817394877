import {
  type NotificationListDto,
  type FetchNotificationsRequestDto,
  type DeleteNotificationsRequestDto,
} from "@quantium-enterprise/common-ui";
import { getBaseQuery } from "@quantium-enterprise/common-ui";
import { createApi } from "@reduxjs/toolkit/query/react";

export const notificationsApi = createApi({
  baseQuery: getBaseQuery(`/api/notifications-service-web`),
  endpoints: (builder) => ({
    fetchNotifications: builder.query<
      NotificationListDto,
      FetchNotificationsRequestDto
    >({
      providesTags: ["FetchNotifications"],
      query: (requestDto) => ({
        body: requestDto,
        method: "POST",
        url: `Notification/FetchNotifications`,
      }),
    }),

    deleteNotification: builder.query<unknown, DeleteNotificationsRequestDto>({
      providesTags: ["DeleteNotification"],
      query: (requestDto) => ({
        body: requestDto,
        method: "POST",
        url: `/Notification/DeleteNotification`,
      }),
    }),
    markAsRead: builder.query<unknown, { notificationId: string }>({
      query: ({ notificationId }) => ({
        url: `Notification/MarkAsRead/${notificationId}`,
        method: "POST",
      }),
    }),
  }),
  reducerPath: "notificationsApi",
  tagTypes: [
    "notifications",
    "FetchNotifications",
    "DeleteNotification",
    "MarkAsRead",
  ],
});

export const {
  useFetchNotificationsQuery,
  useLazyFetchNotificationsQuery,
  useLazyDeleteNotificationQuery,
  useLazyMarkAsReadQuery,
} = notificationsApi;

export default notificationsApi;
