import {
  EmptyState,
  Spinner,
  SpinnerSize,
  SpotColour,
  StateType,
} from "@qbit/react";
import { type HierarchyItemType } from "@quantium-enterprise/common-ui";
import { type ColumnDef } from "@tanstack/react-table";
import { useCallback, useMemo, useState } from "react";
import { BasicTable } from "../basic-table/BasicTable";
import styles from "./RadioSelectTable.module.css";
import RadioSelectTableCell from "./RadioSelectTableCell";

export type RadioSelectTableOption = {
  children?: RadioSelectTableOption[];
  hierarchyItemType: HierarchyItemType;
  isDisabled?: boolean;
  isExpanded?: boolean;
  label: string;
  parent?: string;
  value: string;
};

export type RadioSelectTableProps = {
  handleSelect: (value: string, parent?: string) => void;
  isLoading?: boolean;
  options: RadioSelectTableOption[];
  selected: { parent?: string; value?: string };
  title: string;
};

const headerCell = (isLoading: boolean, title: string) => (
  <span className={styles.tableHeaderCell}>
    {title}
    {isLoading ? (
      <Spinner size={SpinnerSize.XSmall} text="Loading..." />
    ) : (
      <span />
    )}
  </span>
);

const createColumnDefs = (
  handleSelect: (value: string, parent?: string) => void,
  title: string,
  isLoading: boolean,
  expandedRows: Set<string>,
  onToggleExpand: (value: string) => void,
  selected: { parent?: string; value?: string }
): Array<ColumnDef<RadioSelectTableOption>> => [
  {
    accessorFn: (row) => row.label,
    cell: (info) => {
      const handleToggleExpanded = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        onToggleExpand(info.row.original.value);
        info.row.getToggleExpandedHandler();
      };

      const isSelected =
        selected.value === info.row.original.value &&
        selected.parent === info.row.original.parent;

      return (
        <RadioSelectTableCell
          handleSelect={() =>
            handleSelect(info.row.original.value, info.row.original.parent)
          }
          handleToggleExpanded={handleToggleExpanded}
          hierarchyItemType={info.row.original.hierarchyItemType}
          isChild={info.row.original.parent !== undefined}
          isDisabled={info.row.original.isDisabled}
          isExpanded={expandedRows.has(info.row.original.value)}
          isLoading={isLoading}
          isSelected={isSelected}
          label={info.row.original.label}
          parent={info.row.original.children !== undefined}
          value={info.row.original.value}
        />
      );
    },
    header: () => headerCell(isLoading, title),
    id: `Radio-select-table-column-${title}`,
    enableSorting: false,
    enableResizing: false,
  },
];

/**
 * This is a single column table with Radios for each row.
 * Note Radio selection is handled outside of this component and passed in
 */
export const RadioSelectTable = ({
  options,
  selected,
  title,
  handleSelect,
  isLoading = false,
}: RadioSelectTableProps) => {
  const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());
  const [flattenedData, setFlattenedData] = useState<RadioSelectTableOption[]>(
    []
  );

  // Function to flatten data based on expanded rows
  const flattenData = useCallback(
    (items: RadioSelectTableOption[], parentExpanded = true) => {
      const result: RadioSelectTableOption[] = [];
      if (items.length === 0) return result;
      for (const item of items) {
        result.push(item);
        if (
          item.children &&
          expandedRows.has(item.value) &&
          parentExpanded &&
          !item.isDisabled
        ) {
          for (const child of item.children) {
            result.push({ ...child });
          }
        }
      }

      return result;
    },
    [expandedRows]
  );

  // Update flattened data when expanded rows change
  useMemo(() => {
    const newFlattenedData = flattenData(options);
    setFlattenedData(newFlattenedData);
  }, [flattenData, options]);

  const tableColumnDefs = useMemo(() => {
    const handleToggleExpand = (value: string) => {
      const newExpandedRows = new Set(expandedRows);
      if (expandedRows.has(value)) {
        newExpandedRows.delete(value);
      } else {
        newExpandedRows.add(value);
      }

      setExpandedRows(newExpandedRows);
    };

    return createColumnDefs(
      handleSelect,
      title,
      isLoading,
      expandedRows,
      handleToggleExpand,
      selected
    );
  }, [selected, handleSelect, title, isLoading, expandedRows]);

  const table = <BasicTable columns={tableColumnDefs} data={flattenedData} />;
  const emptyStateHeading = "No " + title.toLocaleLowerCase() + " available";
  const emptyStateDescription =
    "Please select product hierarchy to enable " +
    title.toLocaleLowerCase() +
    "s.";

  return (
    <div className={styles.tableContainer}>
      {table}
      {flattenedData.length === 0 ? (
        <EmptyState
          className={styles.emptyState}
          description={emptyStateDescription}
          heading={emptyStateHeading}
          spotColour={SpotColour.Theme}
          stateType={StateType.Insights}
        />
      ) : (
        <span />
      )}
    </div>
  );
};

export default RadioSelectTable;
