import { uniqueId } from "@qbit/react/dist/common";
import {
  type HierarchyGroupRuleWithIdAndName,
  HierarchyType,
  type HierarchyItem,
  HierarchyItemType,
  isHierarchyParameterOptionDto,
  type HierarchyParameterOptionDto,
  type ParameterDto,
  type HierarchyMetadataResponseDto,
  HierarchyGroupRuleOperator,
  isHierarchySelectionDto,
  type SelectionDto,
  NULL_SHORT_NAME,
} from "@quantium-enterprise/common-ui";
import { createSelector } from "@reduxjs/toolkit";
import { type ParameterState } from "../../states/ParameterState";
import { type WizardState } from "../../states/report-wizard-slice";
import { type RootState } from "../../store";
import { isBetweenMinMax } from "../parameter-validation/ValidationState";

export const DEFAULT_FILTER_RULES = [
  {
    id: uniqueId("rule-"),
    operator: HierarchyGroupRuleOperator.Is,
    shortName: NULL_SHORT_NAME,
    fullShortName: undefined,
    values: [],
  } as HierarchyGroupRuleWithIdAndName,
];

export type HierarchyGridItem = HierarchyItem & {
  subRows?: HierarchyGridItem[];
};

export type Hierarchy = {
  disabledLevelShortNames: string[];
  hierarchyMetadata: HierarchyMetadataResponseDto[];
  items: HierarchyGridItem[];
  leafShortName: string;
  rootDepth: number;
  supportedLevelShortNames: string[];
  type: HierarchyType;
  universeRestrictedHierarchyLevel?: string;
  validLevelSelections?: Record<string, string[]>;
};

export type HierarchyTableData = {
  expandedRows: HierarchyItem[];
  items: HierarchyGridItem[];
};

export type HierarchyState = ParameterState & {
  data: Hierarchy;
  expandedRows: HierarchyItem[];
  filterRules: HierarchyGroupRuleWithIdAndName[];
  focalAttributes: string[];
  hierarchyLevelLock?: {
    max: number;
    min: number;
  };
  isAdvancedSearchEnabled: boolean;
  isSelectedItemsShown: boolean;
  searchHasNextPage: boolean;
  searchString: string;
  selectedItems: HierarchyItem[];
  selectedItemsFiltered: HierarchyItem[];
  selectedRows: HierarchyItem[];
  triggerSearch: boolean;
  unfilteredData: HierarchyTableData;
};

export const isHierarchyState = (
  state: ParameterState | undefined
): state is HierarchyState =>
  state !== undefined &&
  Object.prototype.hasOwnProperty.call(state, "data") &&
  Object.prototype.hasOwnProperty.call(state, "expandedRows") &&
  Object.prototype.hasOwnProperty.call(state, "selectedRows");

export const defaultSearchState = {
  isAdvancedSearchEnabled: false,
  isSelectedItemsShown: false,
  selectedItems: [],
  selectedItemsFiltered: [],
  unfilteredData: {
    items: [],
    expandedRows: [],
  },
  triggerSearch: false,
  searchString: "",
  filterRules: [...DEFAULT_FILTER_RULES],
  focalAttributes: [],
  searchHasNextPage: false,
};

export const newHierarchyState = (
  parameterConfig: ParameterDto,
  parameterGroup: string,
  savedSelections?: SelectionDto[]
): HierarchyState => {
  let selectedRows: HierarchyItem[] = [];
  let leafShortName = "";
  let disabledLevelShortNames: string[] = [];
  let supportedLevelShortNames: string[] = [];
  const hierarchyMetadata: HierarchyMetadataResponseDto[] = [];
  const isLocationHierarchy = parameterConfig.hierarchyType === "Location";

  let defaultHierarchyItems: HierarchyParameterOptionDto[] = [];

  defaultHierarchyItems = parameterConfig.options.filter(
    (option) => option.isDefault && isHierarchyParameterOptionDto(option)
  ) as HierarchyParameterOptionDto[];

  const preFilledSelections =
    savedSelections ?? parameterConfig.selections ?? [];
  if (preFilledSelections.length > 0) {
    const selections = preFilledSelections.flatMap((item) => {
      if (isHierarchySelectionDto(item)) {
        return [
          {
            depth: item.hierarchyItem.depth,
            shortName: item.hierarchyItem.shortName,
            value: item.hierarchyItem.itemCode,
            label: item.hierarchyItem.name,
            transactionSourceAccess: item.hierarchyItem.transactionSourceAccess,
          } as HierarchyParameterOptionDto,
        ];
      }

      return [];
    });

    defaultHierarchyItems = selections;
  }

  const universeRestrictedHierarchyLevel =
    parameterConfig.universeRestrictedHierarchyLevel;

  if (parameterConfig.hierarchyLevels.length > 0) {
    // levels disabled from the top are "disabled".
    disabledLevelShortNames = parameterConfig.hierarchyLevels
      .filter((level) => !level.isSelectable)
      .map((level) => level.shortName);

    const supportedLevels = parameterConfig.hierarchyLevels.filter(
      (level) => level.isSelectable
    );
    supportedLevelShortNames = supportedLevels.map((level) => level.shortName);

    // levels below lowest supported level are "hidden" from the hierarchy.
    if (supportedLevels.length > 0) {
      leafShortName = supportedLevels[supportedLevels.length - 1].shortName;
    } else {
      leafShortName = disabledLevelShortNames[0];
    }
  }

  if (defaultHierarchyItems.length > 0) {
    selectedRows = [
      ...defaultHierarchyItems.map(
        (selection): HierarchyItem => ({
          code: selection.value,
          depth: selection.depth ?? 0,
          isLeaf: false,
          name: selection.label,
          ordinal: 0,
          parent: undefined,
          shortName: selection.shortName,
          transactionSourceAccess: selection.transactionSourceAccess,
          type: HierarchyItemType.Hierarchy,
        })
      ),
    ];
  }

  return {
    data: {
      items: [],
      leafShortName,
      disabledLevelShortNames,
      rootDepth: 0,
      supportedLevelShortNames,
      universeRestrictedHierarchyLevel,
      type: isLocationHierarchy
        ? HierarchyType.Location
        : HierarchyType.Product,
      hierarchyMetadata,
    },
    expandedRows: [],
    isValid: isBetweenMinMax(selectedRows.length, parameterConfig),
    parameterConfig,
    parameterGroup,
    selectedRows,
    // Default to initial search state
    ...defaultSearchState,
  };
};

export const getHierarchyState = (parameterId: string) =>
  createSelector(
    (state: RootState) => state.reportParameter.parameters[parameterId],
    (state) => state as HierarchyState | undefined
  );

export const handleHierarchyNodeSelections = (
  state: WizardState,
  parameterState: ParameterState,
  selectedRows: HierarchyItem[]
): ParameterState => {
  if (isHierarchyState(parameterState)) {
    return {
      ...parameterState,
      isValid: isBetweenMinMax(
        selectedRows.length,
        parameterState.parameterConfig
      ),
      selectedRows,
    } as ParameterState;
  }

  // Default is don't do anything to change the state
  return { ...parameterState, isParameterMissing: false };
};
