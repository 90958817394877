import {
  Button,
  ButtonHeight,
  ButtonVariant,
  Icon,
  IconGlyph,
  IconSize,
} from "@qbit/react";
import { type ParameterDto, ParameterId } from "@quantium-enterprise/common-ui";
import { SearchBox } from "components-ui/src/search-box/SearchBox";
import { ContextMenuType } from "components-ui/src/tables/common/table-cell/ContextMenu";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  onSearchChange,
  onShowAdvancedSearch,
  resetSearch,
  selectAdvancedSearchEnableState,
  selectFilterRules,
  selectHierarchyItems,
  selectHierarchySelectedItemsFiltered,
  selectIsSelectedItemsShown,
  selectSearchHasNextPage,
  selectSearchString,
  selectTriggerSearchState,
} from "../../../states/report-wizard-slice";
import { type RootState } from "../../../store";
import { ParameterAdvancedSearchEditor } from "../../advanced-search/ParameterAdvancedSearchEditor";
import { isFilterComplete } from "../../utils/isFilterComplete";
import {
  PRODUCT_HIERARCHY_SEARCH_MAX_PAGE_SIZE,
  PRODUCT_HIERARCHY_SEARCH_QUERY_PAGE_SIZE,
  SEARCH_BOX_DEBOUNCE_TIME,
} from "../ProductHierarchyParameter";
import { ProductHierarchyParameterQuery } from "../ProductHierarchyParameterQuery";
import styles from "./FocalProductsParameter.module.css";

type FocalProductsParameterProps = {
  parameterDto: ParameterDto;
};

export const FocalProductsParameter = ({
  parameterDto,
}: FocalProductsParameterProps) => {
  const dispatch = useDispatch();

  const contextMenuType =
    ContextMenuType[parameterDto.contextMenuType ?? "None"];

  const items = useSelector((state: RootState) =>
    selectHierarchyItems(ParameterId.FocalProducts, state)
  );

  const searchString = useSelector((state: RootState) =>
    selectSearchString(ParameterId.FocalProducts, state)
  );

  const filterRules = useSelector((state: RootState) =>
    selectFilterRules(ParameterId.FocalProducts, state)
  );

  const handleSearchChange = useCallback(
    (searchText: string) => {
      dispatch(
        onSearchChange({
          parameterType: ParameterId.FocalProducts,
          searchString: searchText,
          filterRules,
        })
      );
    },
    [dispatch, filterRules]
  );

  const isAdvancedSearchEnabled = useSelector((state: RootState) =>
    selectAdvancedSearchEnableState(ParameterId.FocalProducts, state)
  );

  const handleShowAdvancedSearch = () =>
    dispatch(onShowAdvancedSearch(ParameterId.FocalProducts));

  const triggerSearch = useSelector((state: RootState) =>
    selectTriggerSearchState(ParameterId.FocalProducts, state)
  );

  const isSelectedItemsShown = useSelector((state: RootState) =>
    selectIsSelectedItemsShown(ParameterId.FocalProducts, state)
  );

  const selectedRowsFiltered = useSelector((state: RootState) =>
    selectHierarchySelectedItemsFiltered(ParameterId.FocalProducts, state)
  );

  const searchHasNextPage = useSelector((state: RootState) =>
    selectSearchHasNextPage(ParameterId.FocalProducts, state)
  );

  const showSearchResultMessage = useMemo(
    () =>
      !triggerSearch &&
      (searchString !== "" ||
        (isAdvancedSearchEnabled &&
          filterRules.every((rule) => isFilterComplete(rule)))),
    [filterRules, isAdvancedSearchEnabled, searchString, triggerSearch]
  );

  const resultCountText = useMemo(() => {
    const amount = isSelectedItemsShown
      ? selectedRowsFiltered.length
      : items.length;
    if (searchHasNextPage && !isSelectedItemsShown) {
      return `${PRODUCT_HIERARCHY_SEARCH_MAX_PAGE_SIZE}+ matches found. Showing first ${PRODUCT_HIERARCHY_SEARCH_QUERY_PAGE_SIZE}, please refine your search further`;
    } else {
      return `${amount} match${amount === 1 ? "" : "es"} found`;
    }
  }, [
    searchHasNextPage,
    items.length,
    selectedRowsFiltered.length,
    isSelectedItemsShown,
  ]);

  /**
   * Reset the search state when the component is unmounted
   */
  useEffect(
    () => () => {
      dispatch(resetSearch(ParameterId.FocalProducts));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className={styles.focalProductContainer}>
      <div className={styles.searchFilterRow}>
        <div className={styles.searchBox}>
          <SearchBox
            debounceTimeMs={SEARCH_BOX_DEBOUNCE_TIME}
            enableDebounce
            onChange={handleSearchChange}
            testId="focal-product-parameter-search"
          />
        </div>
        <Button
          height={ButtonHeight.XSmall}
          onClick={handleShowAdvancedSearch}
          variant={ButtonVariant.Stealth}
        >
          <Icon
            glyph={IconGlyph.SortAndViewFilter}
            size={IconSize.Small}
            text="Filter"
          />
          <span>Filter</span>
        </Button>
        {showSearchResultMessage && (
          <div className={styles.resultCount}>{resultCountText}</div>
        )}
      </div>

      {isAdvancedSearchEnabled && (
        <div className={styles.advancedSearchFilterRow}>
          <ParameterAdvancedSearchEditor
            enableAdvancedSearch={handleShowAdvancedSearch}
            parameterType={ParameterId.FocalProducts}
            useFullWidth
          />
        </div>
      )}
      <ProductHierarchyParameterQuery
        contextMenuType={contextMenuType}
        parameterType={ParameterId.FocalProducts}
      />
    </div>
  );
};
