import { FormBlockStatus } from "@qbit/react";
import { ParameterId } from "@quantium-enterprise/common-ui";
import { type ParameterDto } from "@quantium-enterprise/common-ui";
import { addDays } from "date-fns";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAppSelector } from "../../states/hooks";
import {
  endDateSelected,
  focusPeriodSelected,
  startDateSelected,
  focusWeeksSelected,
} from "../../states/report-wizard-slice";
import { type RootState } from "../../store";
import { ErrorKeys, getTimePeriodState } from "./TimePeriodState";
import { getTimePeriodDataTypeState } from "./TrendedAndAggregatedState";
import { getViewType, viewTypes } from "./TrendedAndAggregatedViewType";
import {
  CustomTimePeriod,
  type CustomTimePeriodOption,
} from "./components/CustomTimePeriod";
import { TimePeriod } from "./components/TimePeriod";
import styles from "./components/TimePeriod.module.css";
import { TimePeriodOptions } from "./components/TimePeriodConstants";
import { WithMinWeeksRestriction } from "./components/WithMinWeeksRestriction";
import { TrendedAndAggregatedDropdown } from "./components/shared/Trended-Aggregated-dropdown/TrendedAndAggregatedDropdown";
import {
  convertParametersToCustomPeriodOptions,
  timestampToDate,
  DAYS_IN_WEEK,
  datetimeToIsoNoOffset,
} from "./utilities";

type FocusPeriodParameterProps = {
  parameterDto: ParameterDto;
};

export const FocusPeriodParameter = ({
  parameterDto,
}: FocusPeriodParameterProps) => {
  // React Store Hooks
  const dispatch = useDispatch();

  const focusPeriodState = useAppSelector(getTimePeriodState(parameterDto.id));
  const timePeriodDataTypeState = useSelector((state: RootState) =>
    getTimePeriodDataTypeState(ParameterId.TimePeriodDataType)(state)
  );

  const { wizard } = useSelector((state: RootState) => ({
    wizard: state.wizard,
  }));

  const [maxEndDate, setMaxEndDate] = useState<Date | undefined>();
  const [minStartDate, setMinStartDate] = useState<Date | undefined>();

  if (!focusPeriodState) {
    return null;
  }

  const currentViewType = getViewType(timePeriodDataTypeState?.value);

  const isTabVisited = wizard.visitedTabs[focusPeriodState.parameterGroup];

  const startDate = timestampToDate(focusPeriodState.startDate);
  const endDate = timestampToDate(focusPeriodState.endDate);

  const customTimePeriodOptions = convertParametersToCustomPeriodOptions(
    parameterDto.options
  ) as CustomTimePeriodOption[];

  const customTimePeriodOption = focusPeriodState.options.find(
    (fp) => fp.value === TimePeriodOptions.CUSTOM_PERIOD
  );
  const maxNumberWeeks = customTimePeriodOption?.maxWeeks;
  const minNumberWeeks = customTimePeriodOption?.minWeeks;
  const customLatestPeriodOption = focusPeriodState.options.find(
    (fp) => fp.customLength
  );
  const maxLatestWeeks = customLatestPeriodOption?.maxWeeks;
  const minLatestWeeks = customLatestPeriodOption?.minWeeks;

  const hasOnlyCustomOption =
    focusPeriodState.options.length === 1 &&
    focusPeriodState.options[0].value === TimePeriodOptions.CUSTOM_PERIOD;

  const timePeriodOnChangeHandler = (newTimePeriod: string[] | string) => {
    const timePeriodValue = Array.isArray(newTimePeriod)
      ? newTimePeriod
      : [newTimePeriod];
    dispatch(
      focusPeriodSelected({
        parameter: parameterDto.id,
        timePeriodValue:
          currentViewType === viewTypes.AGGREGATE
            ? timePeriodValue
            : timePeriodValue[0],
      })
    );

    setMaxEndDate(undefined);
    setMinStartDate(undefined);
  };

  const startDateHandler = (newStartDate: string) => {
    dispatch(
      startDateSelected({
        parameter: parameterDto.id,
        startDate: newStartDate,
      })
    );

    if (maxNumberWeeks && maxNumberWeeks > 0) {
      const convertedStartDate = timestampToDate(newStartDate);
      if (convertedStartDate) {
        const computedMaxEndDate = addDays(
          convertedStartDate,
          maxNumberWeeks * DAYS_IN_WEEK - 1
        );
        if (maxNumberWeeks === minNumberWeeks) {
          dispatch(
            endDateSelected({
              endDate: datetimeToIsoNoOffset(computedMaxEndDate),
              parameter: parameterDto.id,
            })
          );
        } else {
          setMaxEndDate(computedMaxEndDate);
        }
      } else {
        setMaxEndDate(undefined);
      }
    }
  };

  const endDateHandler = (newEndDate: string) => {
    dispatch(
      endDateSelected({
        endDate: newEndDate,
        parameter: parameterDto.id,
      })
    );

    if (maxNumberWeeks && maxNumberWeeks > 0) {
      const convertedEndDate = timestampToDate(newEndDate);
      if (convertedEndDate) {
        const computedMinStartDate = addDays(
          convertedEndDate,
          -(maxNumberWeeks * DAYS_IN_WEEK - 1)
        );
        if (maxNumberWeeks === minNumberWeeks) {
          dispatch(
            startDateSelected({
              parameter: parameterDto.id,
              startDate: datetimeToIsoNoOffset(computedMinStartDate),
            })
          );
        } else {
          setMinStartDate(computedMinStartDate);
        }
      } else {
        setMinStartDate(undefined);
      }
    }
  };

  const weeksHandler = (weeks: number) => {
    dispatch(
      focusWeeksSelected({
        weeks,
        parameter: parameterDto.id,
      })
    );
  };

  const customPeriodBuilder = (
    beginningOfEndDate?: Date,
    endOfStartDate?: Date
  ) => (
    <CustomTimePeriod
      beginningOfEndDate={beginningOfEndDate}
      endDate={endDate}
      endDateHandler={endDateHandler}
      endOfStartDate={endOfStartDate}
      errors={focusPeriodState.errors}
      isEndDateSelectionVisible
      isVisited={isTabVisited}
      maxEndDate={maxEndDate}
      maxStartDate={endDate}
      minEndDate={startDate}
      minStartDate={minStartDate}
      options={customTimePeriodOptions}
      startDate={startDate}
      startDateHandler={startDateHandler}
      weeks={focusPeriodState.weeks ?? undefined}
    />
  );

  if (currentViewType === viewTypes.AGGREGATE) {
    const trendingBlockStatus =
      isTabVisited &&
      Object.keys(focusPeriodState.errors).filter(
        (key) => key !== ErrorKeys.invalidWeekRange
      ).length
        ? FormBlockStatus.Error
        : FormBlockStatus.Default;
    return (
      <div className={styles.timePeriodContainer}>
        <div className={styles.timePeriodDropdownWrapper}>
          <TrendedAndAggregatedDropdown
            blockStatus={trendingBlockStatus}
            defaultOption={{
              label: `Select ${parameterDto.name.toLowerCase()}`,
              value: "",
            }}
            infoIcon={parameterDto.description}
            label={parameterDto.name}
            onChange={timePeriodOnChangeHandler}
            options={focusPeriodState.options}
            selectedValue={focusPeriodState.value}
          />
        </div>
        {focusPeriodState.value === TimePeriodOptions.CUSTOM_PERIOD && (
          <WithMinWeeksRestriction
            customTimePeriodBuilder={customPeriodBuilder}
            customTimePeriodOption={customTimePeriodOption}
            customTimePeriodOptions={customTimePeriodOptions}
            endDate={endDate}
            startDate={startDate}
          />
        )}
      </div>
    );
  }

  return (
    <TimePeriod
      customLength={focusPeriodState.customLength}
      customTimePeriodOption={customTimePeriodOption}
      customTimePeriodOptions={customTimePeriodOptions}
      endDate={endDate}
      endDateHandler={endDateHandler}
      errors={focusPeriodState.errors}
      infoIcon={parameterDto.description}
      isDisabled={focusPeriodState.options.length === 1}
      isDropdownVisible={!hasOnlyCustomOption}
      isVisited={isTabVisited}
      label={parameterDto.name}
      maxEndDate={maxEndDate}
      maxStartDate={endDate}
      maxWeeks={maxLatestWeeks}
      minEndDate={startDate}
      minStartDate={minStartDate}
      minWeeks={minLatestWeeks}
      onChange={timePeriodOnChangeHandler}
      options={focusPeriodState.options}
      startDate={startDate}
      startDateHandler={startDateHandler}
      timePeriod={focusPeriodState.value}
      weeks={focusPeriodState.weeks}
      weeksHandler={weeksHandler}
    />
  );
};

export default FocusPeriodParameter;
