import {
  DatasetColors,
  ParameterId,
  type LocalHierarchyNodeSelection,
} from "@quantium-enterprise/common-ui";
import {
  createSelector,
  createSlice,
  type PayloadAction,
} from "@reduxjs/toolkit";
import { type ButtonGroupOptionsWithLegend } from "components-ui/src/drag-and-multi-drop/models/ButtonGroupOptionsWithLegend";
import { type SegmentOption } from "components-ui/src/local-filters/segmentFilter/SegmentFilter";
import { type PanelOption } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { type SidePanelParameter } from "../../common/models/local-parameters/SidePanelParameters";
import { isSingleSelectionParameter } from "../../common/utils/local-parameters/LocalParametersUtils";
import {
  getPersistedSelections,
  persistSelections,
} from "../../common/utils/persistence-utils";
import { type RootState } from "../../store";
import {
  type AggregateRankRankedSelections,
  type AggregateRankUnrankedSelections,
  type AggregateRankTableRow,
  type AggregateRankAttribute,
  type AggregateRankMetricMetaData,
  type AggregateRankMetricWeight,
  type MetricsWeightsAttributes,
} from "../models/aggregate-rank-common-models";
import { type AggregateRankTableResponseDto } from "../models/aggregate-rank-table-models";
import { ResponseToTableRows } from "../utils/aggregate-rank-table-utils";
import { getDefaultSelections } from "../utils/local-parameter-utils";

const getPersistenceKey = (reportId: string) => `aggregate-rank-${reportId}`;

export type AggregateRankLocalSelections = {
  Channel: PanelOption;
  LevelOfAnalysis: PanelOption;
  LocationHierarchy: LocalHierarchyNodeSelection;
  Segmentation: PanelOption[];
};

export type AggregateRankModalSelections = {
  rankedMetrics: AggregateRankRankedSelections[];
  selectedAttributes: string[];
  unrankedMetrics: AggregateRankUnrankedSelections[];
  weights: AggregateRankMetricWeight[];
};

export type AggregateRankPersistedSelections = {
  localParametersSelections: AggregateRankLocalSelections;
  modalSelections: AggregateRankModalSelections;
};

export type AggregateRankState = {
  attributeMetaData: AggregateRankAttribute[];
  availableDatasets: ButtonGroupOptionsWithLegend[];
  levelOfAnalysisOptions: Array<{ label: string; value: string }>;
  localParametersConfig: SidePanelParameter[];
  localParametersInitialised: boolean;
  metaData: {
    reportId: string;
    reportName: string;
  };
  metricsMetaData: AggregateRankMetricMetaData[];
  metricsWeightAttributeData: MetricsWeightsAttributes;
  modalInitialised: boolean;
  persistedSelections: AggregateRankPersistedSelections;
  persistedSelectionsLoaded: boolean;
  selectedDataset: string;
  tableRows: AggregateRankTableRow[];
  tableSearchResultCount?: number;
  tableSearchText: string;
  totalTableRows: number;
};

export const initialState: AggregateRankState = {
  selectedDataset: "All",
  availableDatasets: [],
  localParametersConfig: [],
  tableSearchText: "",
  tableSearchResultCount: undefined,
  localParametersInitialised: false,
  levelOfAnalysisOptions: [],
  metaData: {
    reportId: "",
    reportName: "",
  },
  persistedSelections: {
    localParametersSelections: {
      Channel: { label: "", value: "" },
      LevelOfAnalysis: { label: "", value: "" },
      LocationHierarchy: {
        code: "",
        depth: -1,
        isBenchmark: false,
        isLeaf: false,
        name: "",
        nodeNumber: -1,
        shortName: "",
        isDefault: false,
      },
      Segmentation: [],
    },
    modalSelections: {
      rankedMetrics: [],
      selectedAttributes: [],
      unrankedMetrics: [],
      weights: [],
    },
  },
  persistedSelectionsLoaded: false,
  attributeMetaData: [],
  metricsMetaData: [],
  modalInitialised: false,
  tableRows: [],
  totalTableRows: 0,
  metricsWeightAttributeData: {
    aggregateRankAttributes: [],
    defaultMetrics: [],
    metricsGroupings: [],
    dataSets: [],
  },
};

export const AggregateRankSlice = createSlice({
  initialState,
  name: "aggregate-rank",
  reducers: {
    onMetadataSuccess: (
      state: AggregateRankState,
      { payload }: PayloadAction<{ reportId: string; reportName: string }>
    ) => {
      state.metaData = payload;
    },
    onModalSave: (
      state: AggregateRankState,
      {
        payload,
      }: PayloadAction<{
        selectedAttributes: string[];
        selectedRankedMetrics: AggregateRankRankedSelections[];
        selectedUnrankedMetrics: AggregateRankUnrankedSelections[];
        selectedWeights: AggregateRankMetricWeight[];
      }>
    ) => {
      state.persistedSelections.modalSelections.selectedAttributes =
        payload.selectedAttributes;
      state.persistedSelections.modalSelections.rankedMetrics =
        payload.selectedRankedMetrics;
      state.persistedSelections.modalSelections.unrankedMetrics =
        payload.selectedUnrankedMetrics;
      state.persistedSelections.modalSelections.weights =
        payload.selectedWeights;

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },
    updateSelectedDataset: (
      state: AggregateRankState,
      { payload }: PayloadAction<string>
    ) => {
      state.selectedDataset = payload;
    },
    onLocalParametersReceived: (
      state: AggregateRankState,
      { payload }: PayloadAction<SidePanelParameter[]>
    ) => {
      if (!state.persistedSelectionsLoaded) {
        state.persistedSelections.localParametersSelections =
          getDefaultSelections(payload);
      }

      state.localParametersConfig = payload;

      const levelOfAnalysisParameter = payload.find(
        (parameter) => parameter.id === ParameterId.LevelOfAnalysis
      );
      if (
        levelOfAnalysisParameter &&
        isSingleSelectionParameter(levelOfAnalysisParameter)
      ) {
        state.levelOfAnalysisOptions = levelOfAnalysisParameter.selections;
      }

      state.localParametersInitialised = true;

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },
    onModalParametersReceived: (
      state: AggregateRankState,
      { payload }: PayloadAction<MetricsWeightsAttributes>
    ) => {
      const filterByDataset = (source: string) => {
        if (!state.selectedDataset || state.selectedDataset === "All") {
          return true;
        }

        return source === state.selectedDataset;
      };

      const getDatasetSuffix = (source: string) => {
        const suffix =
          payload.dataSets.find((dataset) => dataset.value === source)
            ?.suffix ?? "";

        return payload.dataSets.length > 1 ? suffix : "";
      };

      state.availableDatasets = payload.dataSets.map((dataset) => ({
        label: dataset.label,
        value: dataset.value,
        color:
          payload.dataSets.length > 1
            ? DatasetColors[dataset.value as keyof typeof DatasetColors]
            : "",
      }));

      state.metricsWeightAttributeData = payload;

      state.attributeMetaData = payload.aggregateRankAttributes;

      state.metricsMetaData = payload.metricsGroupings.flatMap(
        ({ metricList, groupName }) =>
          metricList.flatMap((metricProperties) =>
            metricProperties.transactionSource.map((dataset) => ({
              ...metricProperties,
              metricValue: metricProperties.metricLabel,
              metricLabel: `${metricProperties.metricLabel}${getDatasetSuffix(
                dataset
              )}`,
              grouping: groupName,
              dataset,
              color:
                state.availableDatasets.length > 1
                  ? DatasetColors[dataset as keyof typeof DatasetColors]
                  : "",
            }))
          )
      );

      if (state.persistedSelectionsLoaded) {
        state.modalInitialised = true;
        return;
      }

      state.persistedSelections.modalSelections.rankedMetrics =
        payload.defaultMetrics
          .filter((metricItem) => metricItem.ranked)
          .flatMap((metric) =>
            metric.transactionSource
              .filter((dataset) => filterByDataset(dataset))
              .map((source) => ({
                metricLabel: `${metric.metricLabel}${getDatasetSuffix(source)}`,
                metricValue: metric.metricLabel,
                color:
                  state.availableDatasets.length > 1
                    ? DatasetColors[source as keyof typeof DatasetColors]
                    : "",
                dataset: source,
              }))
          );

      state.persistedSelections.modalSelections.unrankedMetrics =
        payload.defaultMetrics
          .filter((metricItem) => !metricItem.ranked)
          .flatMap((metric) =>
            metric.transactionSource
              .filter((dataset) => filterByDataset(dataset))
              .map((source) => ({
                metricLabel: `${metric.metricLabel}${getDatasetSuffix(source)}`,
                metricValue: metric.metricLabel,
                color:
                  state.availableDatasets.length > 1
                    ? DatasetColors[source as keyof typeof DatasetColors]
                    : "",
                dataset: source,
              }))
          );

      state.persistedSelections.modalSelections.weights = payload.defaultMetrics
        .filter((metric) => metric.ranked)
        .flatMap((metric) =>
          metric.transactionSource
            .filter((dataset) => filterByDataset(dataset))
            .map((source) => ({
              metricLabel: `${metric.metricLabel}${getDatasetSuffix(source)}`,
              metricValue: metric.metricLabel,
              metricWeight: metric.weight ?? 1,
              dataset: source,
              errorState: false,
            }))
        );

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
      state.modalInitialised = true;
    },
    onChannelChange: (
      state: AggregateRankState,
      { payload }: PayloadAction<PanelOption>
    ) => {
      state.persistedSelections.localParametersSelections.Channel = {
        label: payload.label,
        value: payload.value,
      };

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },
    onLevelOfAnalysisChange: (
      state: AggregateRankState,
      { payload }: PayloadAction<PanelOption>
    ) => {
      state.persistedSelections.localParametersSelections.LevelOfAnalysis = {
        label: payload.label,
        value: payload.value,
      };

      // Clear the attribute selections in the modal - we don't want to linger when LoA != Product
      state.persistedSelections.modalSelections.selectedAttributes = [];

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },
    onLocationChange: (
      state: AggregateRankState,
      action: PayloadAction<LocalHierarchyNodeSelection>
    ) => {
      state.persistedSelections.localParametersSelections.LocationHierarchy =
        action.payload;

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },
    onReportOpen: (
      state: AggregateRankState,
      action: PayloadAction<{
        isTabsEnabled: boolean;
        reportId: string;
      }>
    ) => {
      if (!action.payload.isTabsEnabled) {
        return {
          ...initialState,
        };
      }

      const persistedSelections: AggregateRankPersistedSelections | null =
        getPersistedSelections(getPersistenceKey(action.payload.reportId));

      if (persistedSelections === null) {
        return {
          ...initialState,
        };
      }

      return {
        ...initialState,
        persistedSelections,
        persistedSelectionsLoaded: true,
      };
    },
    onSegmentationChange: (
      state: AggregateRankState,
      action: PayloadAction<SegmentOption>
    ) => {
      state.persistedSelections.localParametersSelections.Segmentation = [
        {
          value: action.payload.segmentationValue,
          label: action.payload.segmentationLabel,
        },
        {
          value: action.payload.segmentValue,
          label: action.payload.segmentLabel,
        },
      ];

      persistSelections(
        getPersistenceKey(state.metaData.reportId),
        state.persistedSelections
      );
    },
    reset: () => initialState,
    onTableResponse: (
      state: AggregateRankState,
      { payload: response }: PayloadAction<AggregateRankTableResponseDto>
    ) => {
      state.tableRows = ResponseToTableRows(
        response.rows,
        response.columnMetaData
      );
      state.totalTableRows = response.totalRowCount;
      state.tableSearchResultCount = response.searchResultCount;
    },
    onTableLoadMoreResponse: (
      state: AggregateRankState,
      { payload: response }: PayloadAction<AggregateRankTableResponseDto>
    ) => {
      state.tableRows = state.tableRows.concat(
        ResponseToTableRows(response.rows, response.columnMetaData)
      );
    },
    onSearchChange: (
      state: AggregateRankState,
      { payload: searchText }: PayloadAction<string>
    ) => {
      state.tableSearchText = searchText;
    },
  },
});

// SELECTORS
// These are for getting data out of the store. They are memoized, so they only recalculate if the state they depend on has changed.
export const selectAggregateRank = createSelector(
  (state: RootState) => state,
  (state: RootState) => state.aggregateRank
);

export const selectReportId = createSelector(
  (state: RootState) => state.aggregateRank.metaData.reportId,
  (reportId) => reportId
);

export const selectLocalParametersInitialised = createSelector(
  (state: RootState) => state.aggregateRank.localParametersInitialised,
  (localParametersInitialised) => localParametersInitialised
);

export const selectLocalParametersSelections = createSelector(
  (state: RootState) =>
    state.aggregateRank.persistedSelections.localParametersSelections,
  (localParametersSelections) => localParametersSelections
);

export const selectChannel = createSelector(
  (state: RootState) =>
    state.aggregateRank.persistedSelections.localParametersSelections.Channel,
  (channel) => channel
);

export const selectLevelOfAnalysis = createSelector(
  (state: RootState) =>
    state.aggregateRank.persistedSelections.localParametersSelections
      .LevelOfAnalysis,
  (levelOfAnalysis) => levelOfAnalysis
);

export const selectLocationHierarchy = createSelector(
  (state: RootState) =>
    state.aggregateRank.persistedSelections.localParametersSelections
      .LocationHierarchy,
  (locationHierarchy) => locationHierarchy
);

export const selectSegmentation = createSelector(
  (state: RootState) =>
    state.aggregateRank.persistedSelections.localParametersSelections
      .Segmentation,
  (segmentation) => segmentation
);

export const {
  onMetadataSuccess,
  updateSelectedDataset,
  onModalSave,
  onLocalParametersReceived,
  onChannelChange,
  onLevelOfAnalysisChange,
  onLocationChange,
  onReportOpen,
  onSegmentationChange,
  onModalParametersReceived,
  reset,
  onTableResponse,
  onTableLoadMoreResponse,
  onSearchChange,
} = AggregateRankSlice.actions;

export default AggregateRankSlice.reducer;
