/* eslint-disable @typescript-eslint/no-unnecessary-condition -- The highcharts.d.ts file is wrong. Some fields are nullable. */
import { type FormatterFunction } from "@quantium-enterprise/hooks-ui";
import { MetricTypes, useFormatter } from "@quantium-enterprise/hooks-ui";
import { getColourByIndex } from "components-ui/src/charts/ChartColours";
import { HighChartsWithPinnableTooltips } from "components-ui/src/charts/highcharts-react/HighChartsWithPinnableTooltips";
import {
  type HighchartsReactProps,
  defaultOptions,
} from "components-ui/src/charts/highcharts-react/HighchartsReact";
import { getPoint } from "components-ui/src/charts/utils";
import { HighchartsCustomTooltip } from "components-ui/src/highcharts-custom-tooltip/HighchartsCustomTooltip";
import {
  type TooltipFormatterContextObject,
  type PointClickCallbackFunction,
  type PointOptionsObject,
  type PointClickEventObject,
} from "highcharts";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReportletTableId } from "../constants/basket-affinities-common";
import { type AssociatedItem } from "../models/basket-affinities-chart-models";
import {
  resetAssociatedAncestor,
  selectAssociatedAncestor,
  selectAssociatedItems,
  selectShowAssociatedItemsChartDataLabels,
  setAssociatedAncestor,
} from "../services/basket-affinities-slice";

const getColor = (ttData: TooltipFormatterContextObject) => {
  // prefer colour index over colour as we will change the point colour to gray (shade-6) when selected
  const colourIndex = getPoint(ttData, (point) => point.colorIndex);
  return colourIndex === undefined
    ? String(getPoint(ttData, (point) => point.color))
    : getColourByIndex(colourIndex as number);
};

const getAssociatedItem: (
  ttData: TooltipFormatterContextObject
) => AssociatedItem | undefined = (ttData) => {
  const item = getPoint(ttData, (point) => point.options.custom?.item);
  if (item) {
    return item;
  }

  return undefined;
};

type TooltipProps = {
  chartDataFormatter?: FormatterFunction;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  ttData: TooltipFormatterContextObject;
};

const Tooltip = ({ chartDataFormatter, onClick, ttData }: TooltipProps) => (
  <HighchartsCustomTooltip.Layout>
    <HighchartsCustomTooltip.Title
      seriesIcon={
        getPoint(ttData)
          ? {
              color: getColor(ttData),
              type: getPoint(ttData, (point) => point.series.type) as string,
            }
          : undefined
      }
      title={getAssociatedItem(ttData)?.associatedAncestor.name ?? ""}
    />
    <HighchartsCustomTooltip.Series
      chartDataFormatter={chartDataFormatter}
      hasSentiment={false}
      hideSeriesIcon
      ttData={ttData}
      useSeriesNameForLabels
    />
    <HighchartsCustomTooltip.ClickableLink
      onClick={onClick}
      text="View associated items"
    />
  </HighchartsCustomTooltip.Layout>
);

type BasketAffinitiesAssociatedItemsChartProps = {
  onOptionsChanged?: (options: HighchartsReactProps) => void;
};

export const BasketAffinitiesAssociatedItemsChart = ({
  onOptionsChanged,
}: BasketAffinitiesAssociatedItemsChartProps) => {
  const dispatch = useDispatch();
  const formatter = useFormatter();

  const associatedItems = useSelector(selectAssociatedItems);
  const associatedAncestor = useSelector(selectAssociatedAncestor);
  const showAssociatedItemsChartDataLabels = useSelector(
    selectShowAssociatedItemsChartDataLabels
  );

  const scrollToTable = useCallback(() => {
    const reportletTable = document.querySelector(`#${ReportletTableId}`);
    if (reportletTable) {
      reportletTable.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const pointClickCallback: PointClickCallbackFunction = useCallback(
    (event: PointClickEventObject) => {
      const item = event.point?.options?.custom?.item;
      if (item) {
        if (
          item.associatedAncestor.itemCode ===
          associatedAncestor.associatedAncestor.itemCode
        ) {
          dispatch(resetAssociatedAncestor());
        } else {
          dispatch(setAssociatedAncestor(item));
          scrollToTable();
        }
      }
    },
    [associatedAncestor.associatedAncestor.itemCode, dispatch, scrollToTable]
  );
  const tooltipClickCallback = useCallback(
    (ttData: TooltipFormatterContextObject) => {
      const item = getAssociatedItem(ttData);
      if (item) {
        dispatch(setAssociatedAncestor(item));
        scrollToTable();
      }
    },
    [dispatch, scrollToTable]
  );

  const categories = associatedItems.map(
    (item) => item.associatedAncestor.name
  );
  // memoise the series so that pinned tooltips are reset ever rerender.
  const series = useMemo(() => {
    const data = associatedItems.map(
      (item) =>
        ({
          y: item.associatedItemsCount,
          custom: {
            item,
          },
          color:
            // item.associatedAncestor.itemCode === associatedAncestor.associatedAncestor.itemCode ? "var(--qbit-colour-shade-6)" :
            undefined,
        } as PointOptionsObject)
    );

    return [
      {
        // only care about the data matching the categories in the right order
        type: "bar",
        // Typescript is complaining when it shouldn't. Types are ensured to be correct above anyway
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data: data as any,
        name: "Associated items count",
      },
    ];
  }, [associatedItems]);
  // HACK: Update the colour outside the series to prevent pinned tooltips from being rerendered.
  for (const seriesData of series[0].data) {
    seriesData.color =
      seriesData.custom.item.associatedAncestor.itemCode ===
      associatedAncestor.associatedAncestor.itemCode
        ? "var(--qbit-colour-shade-6)"
        : undefined;
  }

  const xAxisPlotBands = useMemo(() => {
    const associatedItemIndex = associatedItems.findIndex(
      (item: AssociatedItem) =>
        item.associatedAncestor.itemCode ===
        associatedAncestor.associatedAncestor.itemCode
    );
    return associatedAncestor.associatedAncestor.itemCode === "" ||
      associatedItemIndex === -1
      ? []
      : [
          {
            color: "var(--qbit-colour-chrome-background)",
            from: associatedItemIndex - 0.5,
            to: associatedItemIndex + 0.5,
            id: "plot-band-1",
          },
        ];
  }, [associatedAncestor.associatedAncestor.itemCode, associatedItems]);

  const yAxis: Highcharts.YAxisOptions[] = useMemo(
    () => [
      {
        plotLines: [
          {
            value: 0,
            color: "var(--qbit-colour-shade-6)",
            width: 2,
            zIndex: 1,
          },
        ],
        labels: {
          formatter() {
            // eslint-disable-next-line react/no-this-in-sfc, unicorn/no-this-assignment
            const self = this;
            const value = self.value;
            return formatter(MetricTypes.Integer)(value, false, "", true);
          },
          style: {
            color: "var(--qbit-colour-text-secondary)",
          },
        },
        title: {
          style: {
            fontWeight: "bold",
            color: "var(--qbit-colour-text-primary)",
          },
          text: "Number of associated items",
        },
        visible: true,
        allowDecimals: false,
      },
    ],
    [formatter]
  );

  const options: HighchartsReactProps = useMemo(
    () => ({
      ...defaultOptions,
      chart: {
        // remove zooming and cursor styles
        style: {
          fontFamily: "var(--qbit-font-family)",
        },
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        categories,
        crosshair: {
          color: "var(--qbit-colour-chrome-background)",
          zIndex: 0,
        },
        visible: true,
        title: {
          style: {
            color: "var(--qbit-colour-text-primary)",
          },
        },
        labels: {
          style: {
            color: "var(--qbit-colour-text-secondary)",
          },
        },
        plotBands: xAxisPlotBands,
      },
      // Typescript is complaining when it shouldn't. Types are ensured to be correct above anyway
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      yAxis: yAxis as any,
      tooltip: {
        ...defaultOptions.tooltip,
        ReactFormatter: (ttData) =>
          Tooltip({
            chartDataFormatter: formatter(MetricTypes.Integer),
            onClick: (event) => {
              event.stopPropagation();
              tooltipClickCallback(ttData);
            },
            ttData,
          }),
      },
      plotOptions: {
        bar: {
          colorByPoint: true,
          dataLabels: {
            enabled: showAssociatedItemsChartDataLabels,
            formatter() {
              // eslint-disable-next-line react/no-this-in-sfc, unicorn/no-this-assignment
              const self = this;
              const value = self.y;
              return formatter(MetricTypes.Integer)(value);
            },
          },
          point: {
            events: {
              click: pointClickCallback,
            },
          },
        },
      },
      // Typescript is complaining when it shouldn't. Types are ensured to be correct above anyway
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      series: series as any,
    }),
    [
      categories,
      formatter,
      pointClickCallback,
      series,
      showAssociatedItemsChartDataLabels,
      tooltipClickCallback,
      xAxisPlotBands,
      yAxis,
    ]
  );

  useEffect(() => {
    if (onOptionsChanged) {
      onOptionsChanged(options);
    }
  }, [onOptionsChanged, options]);

  return <HighChartsWithPinnableTooltips options={options} />;
};
