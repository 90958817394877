import { getBaseQuery } from "@quantium-enterprise/common-ui";
import { createApi } from "@reduxjs/toolkit/query/react";
import {
  type RenameReportDto,
  type ReportParametersDto,
  type SharedReportRequestDto,
  type SharingActivity,
} from "../models";

export const reportParametersWebApi = createApi({
  baseQuery: getBaseQuery(`/api/report-parameters-web`),
  endpoints: (builder) => ({
    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
    getReportParameters: builder.query<
      ReportParametersDto,
      { divisionName: string; reportId: string }
    >({
      providesTags: ["report-parameters"],
      query: ({ divisionName, reportId }) =>
        `/ReportParameters/GetReportParameters/${divisionName}?reportId=${reportId}`,
    }),
    getReportParametersSummary: builder.query<
      ReportParametersDto,
      { divisionName: string; reportId: string }
    >({
      providesTags: ["report-parameters"],
      query: ({ divisionName, reportId }) =>
        `/ReportParameters/GetReportParametersSummary/${divisionName}?reportId=${reportId}`,
    }),
    renameReport: builder.mutation<
      unknown,
      { divisionName: string; payload: RenameReportDto }
    >({
      invalidatesTags: ["report-parameters"],
      query: ({ divisionName, payload }) => ({
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        method: "POST",
        url: `/ReportParameters/RenameReport/${divisionName}`,
      }),
    }),
    deleteReports: builder.mutation<
      unknown,
      { divisionName: string; itemIds: string[] }
    >({
      invalidatesTags: ["report-parameters"],
      query: ({ divisionName, itemIds }) => ({
        method: "DELETE",
        url: `/ReportParameters/DeleteReports/${divisionName}?reportIds=${itemIds.join(
          "&reportIds="
        )}`,
      }),
    }),
    shareReport: builder.query<
      unknown,
      { divisionName: string; payload: SharedReportRequestDto }
    >({
      providesTags: ["share-report"],
      query: ({ divisionName, payload }) => ({
        body: payload,
        method: "POST",
        url: `/Sharing/ShareReport/${divisionName}`,
      }),
    }),
    setReportViewed: builder.mutation<
      unknown,
      { divisionName: string; reportId: string }
    >({
      query: ({ divisionName, reportId }) => ({
        body: reportId,
        method: "POST",
        url: `/ReportParameters/SetReportViewed/${divisionName}?reportId=${reportId}`,
      }),
    }),
    getSharingActivities: builder.query<
      SharingActivity[],
      { divisionName: string; reportId: string }
    >({
      providesTags: ["sharing-activities"],
      query: ({ divisionName, reportId }) =>
        `/Sharing/GetSharingActivities/${divisionName}?reportId=${reportId}`,
    }),
    getSavedParametersSharingActivities: builder.query<
      SharingActivity[],
      { divisionName: string; savedParametersId: string }
    >({
      providesTags: ["sharing-activities"],
      query: ({ divisionName, savedParametersId }) =>
        `/Sharing/GetSavedParametersSharingActivities/${divisionName}?savedParametersId=${savedParametersId}`,
    }),
    getRecentReportIdByType: builder.query<
      string | null,
      { divisionName: string; reportType: string }
    >({
      providesTags: ["recent-report"],
      query: ({ divisionName, reportType }) =>
        `/ReportParameters/GetRecentReportIdByType/${divisionName}?reportType=${reportType}`,
    }),
  }),
  reducerPath: "reportParametersWebApi",
  tagTypes: [
    "report-parameters",
    "share-report",
    "sharing-activities",
    "recent-report",
  ],
});

export const {
  useGetReportParametersQuery,
  useLazyGetReportParametersQuery,
  useGetReportParametersSummaryQuery,
  useLazyGetReportParametersSummaryQuery,
  useRenameReportMutation,
  useDeleteReportsMutation,
  useLazyShareReportQuery,
  useGetSharingActivitiesQuery,
  useLazyGetSharingActivitiesQuery,
  useGetSavedParametersSharingActivitiesQuery,
  useLazyGetRecentReportIdByTypeQuery,
  useSetReportViewedMutation,
} = reportParametersWebApi;
