import {
  HierarchyItemType,
  type ListSelectionDto,
  ProductHierarchy,
} from "@quantium-enterprise/common-ui";
import { HierarchySelectionSummary } from "../hierarchy-selection-summary/HierarchySelectionSummary";
import styles from "./LevelSelectionSummary.module.css";

export type LevelSelectionSummaryProps = {
  parameterName: string;
  selections: ListSelectionDto[];
};

export const LevelSelectionSummary = ({
  selections,
  parameterName,
}: LevelSelectionSummaryProps) => {
  const items = selections.slice().map((selection) => ({
    itemCode: "",
    name: selection.label,
    shortName: selection.value,
    type: (Object.values(ProductHierarchy) as string[]).includes(
      selection.value
    )
      ? HierarchyItemType.Hierarchy
      : HierarchyItemType.Attribute,
  }));

  return (
    <div className={styles.levelSelectionSummaryContainer}>
      <div className={styles.parameterLabel}> {parameterName}: </div>
      <HierarchySelectionSummary
        items={items}
        maxDepth={1}
        moreItemsText="more items"
      />
    </div>
  );
};
