import { type Dropdown } from "@qbit/react";

export const closeDropdown = (
  dropdownRef: React.RefObject<typeof Dropdown>
) => {
  // @ts-expect-error Qbit dropdown currently has no public method to close itself
  if (dropdownRef.current?.state?.isOpen && dropdownRef.current.toggleState) {
    // @ts-expect-error Bug ticket to fix this: https://jira.quantium.com.au/browse/QDS-386
    dropdownRef.current.toggleState();
  }
};
