import { type SortingState } from "@tanstack/react-table";
import { sortByIndex } from "../../common/utils/export-parameter-summary-utils";
import {
  type AssociatedLevel,
  type purchasedWithAssociatedData,
} from "../models/basket-affinities-chart-models";

export const csvTransformation = (
  associatedLevel: AssociatedLevel,
  chartRows: purchasedWithAssociatedData[]
) => {
  const columnHeaders = [
    `Associated ${associatedLevel.displayName.toLocaleLowerCase()}`,
    "Uplift",
    "Baskets with both",
  ];

  const csvData = [columnHeaders].concat(
    chartRows.map((row) => [
      row.product.name,
      (row.uplift ?? "-").toString(),
      (row.basketsWithBoth ?? "-").toString(),
    ])
  );

  return csvData;
};

export const csvPurchasedWithFocalItems = (
  tableData: purchasedWithAssociatedData[],
  sortingState: SortingState,
  upliftThreshold: number,
  basketsWithBothThreshold: number,
  currency: string
) => {
  const filteredTableData = tableData.filter(
    (x) =>
      x.uplift &&
      x.uplift >= upliftThreshold &&
      x.basketsWithBoth &&
      x.basketsWithBoth >= basketsWithBothThreshold
  );
  const columnHeaders = [
    `Associated assortments (${filteredTableData.length})`,
    "Uplift",
    "Baskets with both",
    "Confidence (Basket With both/ Baskets with focal item) (%)",
    "Support (Associated baskets / Total baskets) (%)",
    `Associated sales per basket (${currency})`,
  ];
  const sortIndex = columnHeaders.findIndex((item) =>
    item.includes(sortingState[0].id)
  );
  const csvData = [columnHeaders].concat(
    filteredTableData.map((row) => [
      row.product.name,
      (row.uplift ?? "-").toString(),
      (row.basketsWithBoth ?? "-").toString(),
      (row.confidence ?? "-").toString(),
      (row.support ?? "-").toString(),
      (row.associatedSales ?? "-").toString(),
    ])
  );

  return sortByIndex(csvData, sortIndex, sortingState[0].desc);
};
