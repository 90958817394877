import { Toggle } from "@qbit/react";
import {
  ddLog,
  HierarchyItemType,
  TrackingComponent,
  TrackingEvent,
  useGetUserQuery,
} from "@quantium-enterprise/common-ui";
import {
  useFlags,
  type DeferredFormatFunction,
} from "@quantium-enterprise/hooks-ui";
import { useDivision, useFormatter } from "@quantium-enterprise/hooks-ui";
import {
  type ExpandedState,
  createColumnHelper,
  type CellContext,
  type VisibilityState,
  type GroupColumnDef,
} from "@tanstack/react-table";
import { DataTableOptions } from "components-ui/src/data-table-options/DataTableOptions";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { hierarchyLevelDisplayLabel } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { ReportTopDrawer } from "components-ui/src/report-top-drawer/ReportTopDrawer";
import { EmptySearch } from "components-ui/src/search/EmptySearch";
import { SearchBox } from "components-ui/src/search-box/SearchBox";
import { ExpandableNameCell } from "components-ui/src/tables/common/table-cell/ExpandableNameCell";
import { ValueCell } from "components-ui/src/tables/common/table-cell/ValueCell";
import { ReportHierarchyTableWrapper } from "components-ui/src/tables/report-hierarchy-table/components/ReportHierarchyTableWrapper";
import { VirtuosoTableComponent } from "components-ui/src/tables/virtuoso-table/VirtuosoTableComponent";
import {
  type MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ErrorBoundary from "../../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { EMPTY_NODE_NUMBER } from "../../../common/constants";
import { KeyDriverTreeFeatureFlags } from "../../constants/key-driver-tree-feature-flags";
import {
  type KeyDriverTreeTableMeasureGrouping,
  type KeyDriverTreeTableRow,
} from "../../models/key-driver-tree-table-models";
import {
  onFocalItemChange,
  onSearchChange,
} from "../../services/key-driver-tree-slice";
import {
  selectFocalItem,
  selectFocalItemTableInitialised,
  selectHierarchySliceHypercubeId,
  selectInitialDataRecieved,
  selectLocalParameterSelections,
  selectLocalParametersInitialised,
  selectMeasureGroups,
  selectRowSelection,
  selectSearchText,
  selectTableRows,
} from "../../services/key-driver-tree-slice-selectors";
import {
  useLazyGetTableDataQuery,
  useLazyGetChildrenTableDataQuery,
  type FetchChildrenTableRequest,
  useLazyDownloadTableQuery,
  useLazyGetSearchResultsTableQuery,
  useLazyGetInitialTableQuery,
} from "../../services/key-driver-tree-table-data-api-slice";
import { getUserSelections } from "../../utils/getUserSelections";
import styles from "./KeyDriverTreeTopDrawer.module.css";
import { getDescendantRows, getExpandedRows } from "./utils";

const measuresColumnDefs = (
  metricGroup: KeyDriverTreeTableMeasureGrouping,
  formatter: DeferredFormatFunction
) =>
  metricGroup.measureTypes.map((measureType) => ({
    accessorFn: (row: KeyDriverTreeTableRow) => {
      const data = row.measures.find(
        (measure) =>
          measure.measureType === measureType &&
          measure.baseMeasure === metricGroup.baseMeasure
      );

      if (data) {
        return data.measureValue;
      }

      return "";
    },
    cell: ({ getValue, row }: CellContext<KeyDriverTreeTableRow, number>) =>
      ValueCell({
        // sentiment colouring for sales change and contribution
        hasSentiment:
          measureType === "% change" || measureType === "Contribution",
        // Inefficient way to grab metric format
        formatter: formatter(
          row.original.measures.find(
            (measure) =>
              measure.baseMeasure === metricGroup.baseMeasure &&
              measure.measureType === measureType
          )?.format ?? "Decimal"
        ),
        value: getValue(),
      }),
    header: measureType,
    id: `${metricGroup.baseMeasure}-${measureType}`,
  }));

export type KeyDriverTreeTopDrawerProps = {
  eventTrackingService: Function;
  renameReport: (newItemName: string, itemId: string) => Promise<void>;

  reportName: string;
  reportType: string;
};

export const KeyDriverTreeTopDrawer = ({
  renameReport,
  reportType,
  reportName,
  eventTrackingService,
}: KeyDriverTreeTopDrawerProps) => {
  const { name: divisionName } = useDivision();
  const { id: reportId } = useParams();
  const { data: user } = useGetUserQuery();
  const dispatch = useDispatch();
  const formatter = useFormatter();
  const featureFlags = useFlags();
  const isExportEnabled =
    featureFlags[KeyDriverTreeFeatureFlags.ReportExport] ?? false;

  // Store
  const focalItem = useSelector(selectFocalItem);
  const focalItemTableInitialised = useSelector(
    selectFocalItemTableInitialised
  );
  const hierarchySliceHypercubeId = useSelector(
    selectHierarchySliceHypercubeId
  );
  const initialDataRecieved = useSelector(selectInitialDataRecieved);
  const localParameterSelections = useSelector(selectLocalParameterSelections);
  const localParametersInitialised = useSelector(
    selectLocalParametersInitialised
  );
  const measureGroups = useSelector(selectMeasureGroups);
  const rowSelection = useSelector(selectRowSelection);
  const tableRows = useSelector(selectTableRows);
  const searchText = useSelector(selectSearchText);

  const [itemsToRefetch, setItemsToRefetch] = useState<number[]>([]);
  const [expandState, setExpandState] = useState<ExpandedState>(true);

  const expandRow = useCallback(
    (row: KeyDriverTreeTableRow) => {
      setItemsToRefetch([...itemsToRefetch, row.hierarchyItem.nodeNumber]);
    },
    [itemsToRefetch]
  );

  const collapseRow = useCallback(
    (row: KeyDriverTreeTableRow) => {
      const rowsToCollapse = getDescendantRows(row);
      rowsToCollapse.push(row.hierarchyItem.nodeNumber);
      const filtered = itemsToRefetch.filter(
        (item) => !rowsToCollapse.includes(item)
      );
      setItemsToRefetch(filtered);
    },
    [itemsToRefetch]
  );

  // Initial Table Query
  const [
    getInitialTable,
    { data: initialTableData, isFetching: isInitialTableFetching },
  ] = useLazyGetInitialTableQuery();

  const fetchInitialTable = useCallback(async () => {
    await getInitialTable({
      divisionName,
      payload: {
        localSelectedValues: getUserSelections(localParameterSelections),
        reportId: reportId ?? "",
        defaultFocalItem: focalItem,
      },
    });
  }, [
    divisionName,
    focalItem,
    getInitialTable,
    localParameterSelections,
    reportId,
  ]);

  useEffect(() => {
    if (
      !focalItemTableInitialised &&
      localParametersInitialised &&
      focalItem.nodeNumber !== EMPTY_NODE_NUMBER
    ) {
      fetchInitialTable().catch((error) => {
        ddLog(
          "Error retrieving key driver tree table data",
          {},
          "error",
          error
        );
      });
    }
  }, [
    fetchInitialTable,
    focalItem,
    focalItemTableInitialised,
    localParametersInitialised,
  ]);

  // Set the initial displayed nodes
  useEffect(() => {
    if (initialTableData) {
      setItemsToRefetch(getExpandedRows(initialTableData.tableRows));
    }
  }, [initialTableData]);

  // Lazy query for refetch of the full table
  const [refetchTableQueryTrigger, { isFetching: refetchTableFetching }] =
    useLazyGetTableDataQuery({
      refetchOnFocus: true,
    });

  const [searchTableQueryTrigger, { isFetching: isSearchFetching }] =
    useLazyGetSearchResultsTableQuery({ refetchOnFocus: true });

  const fetchTable = useCallback(async () => {
    await (searchText.length > 0
      ? searchTableQueryTrigger({
          divisionName,
          payload: {
            localSelectedValues: getUserSelections(localParameterSelections),
            searchText,
            hierarchySliceHypercubeId,
            reportId: reportId ?? "",
          },
        })
      : refetchTableQueryTrigger({
          divisionName,
          payload: {
            dataTableItems: itemsToRefetch,
            hierarchySliceHypercubeId,
            localSelectedValues: getUserSelections(localParameterSelections),
            reportId: reportId ?? "",
          },
        }));
    // Handle any post fetch things here
  }, [
    itemsToRefetch,
    divisionName,
    hierarchySliceHypercubeId,
    localParameterSelections,
    reportId,
    refetchTableQueryTrigger,
    searchTableQueryTrigger,
    searchText,
  ]);

  // Only call this fetch for entire table when we pick up on local parameter change
  useEffect(() => {
    if (initialDataRecieved && localParametersInitialised) {
      fetchTable().catch((error) => {
        // FIXME throw this somewhere
        ddLog("ERROR", {}, "error", error);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localParameterSelections, searchText]);

  const isSearchMode = useMemo(
    () => searchText.length > 0 && !isSearchFetching,
    [isSearchFetching, searchText.length]
  );

  const displayEmptySearch =
    isSearchMode && !isSearchFetching && tableRows.length === 0;

  const memoizedSearchBox = useMemo(
    () => (
      <div className={styles.searchBox}>
        <SearchBox
          enableDebounce
          onChange={(value: string) => dispatch(onSearchChange(value))}
          placeholder="Type to search"
          resultCount={isSearchMode ? tableRows.length : undefined}
          searchQuery={searchText}
        />
      </div>
    ),
    [isSearchMode, tableRows.length, searchText, dispatch]
  );

  const [isRowFetchingChildren, setIsRowFetchingChildren] = useState<{
    [id: number]: boolean;
  }>({});

  // Lazy query for fetching children
  const [fetchChildrenQueryTrigger] = useLazyGetChildrenTableDataQuery({
    refetchOnFocus: false,
  });

  const fetchChildren = useCallback(
    async (parentNode: KeyDriverTreeTableRow) => {
      const payload: FetchChildrenTableRequest = {
        hierarchySliceHypercubeId,
        localSelectedValues: getUserSelections(localParameterSelections),
        parentNode: parentNode.hierarchyItem.nodeNumber,
        reportId: reportId ?? "",
      };

      setIsRowFetchingChildren({ [parentNode.hierarchyItem.nodeNumber]: true });

      await fetchChildrenQueryTrigger({
        divisionName,
        payload,
      });

      setIsRowFetchingChildren({
        [parentNode.hierarchyItem.nodeNumber]: false,
      });
    },
    [
      hierarchySliceHypercubeId,
      localParameterSelections,
      reportId,
      fetchChildrenQueryTrigger,
      divisionName,
    ]
  );

  // Column helper
  const columnHelper = createColumnHelper<KeyDriverTreeTableRow>();

  // RowId is just the hierarchy slice node number since that is unique.
  const getRowId = (row: KeyDriverTreeTableRow) =>
    row.hierarchyItem.nodeNumber.toString();

  // Create the expandable cells for the first columns
  const createNameCell = useCallback(
    ({ row, getValue }: CellContext<KeyDriverTreeTableRow, string>) => (
      <ExpandableNameCell
        canExpand={!row.original.hierarchyItem.isLeaf && !isSearchMode}
        depth={row.depth}
        handleToggleExpanded={(event: MouseEvent<HTMLElement>) => {
          event.stopPropagation();
          // If node already not expanded then expand
          if (itemsToRefetch.includes(row.original.hierarchyItem.nodeNumber)) {
            collapseRow(row.original);
          } else {
            // Fetch children if no data
            if (row.subRows.length === 0) {
              fetchChildren(row.original).catch((error) => {
                // FIXME throw this somewhere
                ddLog("ERROR", {}, "error", error);
              });
              // when fetching children, the row doesn't expand on first click so
              // force it to open manually
              setExpandState((previousState) =>
                typeof previousState === "boolean"
                  ? true
                  : {
                      ...previousState,
                      [row.original.hierarchyItem.nodeNumber]: true,
                    }
              );
            }

            expandRow(row.original);
          }

          row.getToggleExpandedHandler()();
        }}
        handleToggleSelected={() => {
          row.getToggleSelectedHandler();
        }}
        isCheckboxHidden
        isExpanded={row.getIsExpanded() && row.original.subRows.length > 0}
        isExpanding={
          isRowFetchingChildren[row.original.hierarchyItem.nodeNumber] &&
          row.original.subRows.length === 0
        }
        isSelected={row.getIsSelected()}
        name={row.original.hierarchyItem.name}
        onClick={() => {
          if (!row.getIsSelected()) {
            dispatch(onFocalItemChange(row.original.hierarchyItem));
          }

          eventTrackingService(
            [
              TrackingComponent.MyReports,
              TrackingComponent.Report,
              TrackingComponent.FocalItemTableRow,
            ],
            row.getIsSelected()
              ? TrackingEvent.Unselected
              : TrackingEvent.Selected,
            {
              selection: `${row.original.hierarchyItem.shortName}:${row.original.hierarchyItem.name}`,
              division: divisionName,
              user: user?.isSupplier ? "Supplier" : "Retailer",
            }
          );
        }}
        shortName={row.original.hierarchyItem.shortName}
        type={HierarchyItemType.Hierarchy}
        value={getValue()}
      />
    ),
    [
      isSearchMode,
      isRowFetchingChildren,
      itemsToRefetch,
      collapseRow,
      expandRow,
      fetchChildren,
      eventTrackingService,
      divisionName,
      user?.isSupplier,
      dispatch,
    ]
  );

  // Create the columns for the rest of the table
  const dataColumns = useMemo(
    () =>
      measureGroups.map((metricGroup) => ({
        columns: measuresColumnDefs(metricGroup, formatter),
        header: metricGroup.baseMeasure,
        id: `${metricGroup.baseMeasure}`,
      })),
    [formatter, measureGroups]
  );

  const columns = useMemo(
    () =>
      [
        {
          columns: [
            columnHelper.accessor((row) => row.hierarchyItem.name, {
              cell: createNameCell,
              enableHiding: false,
              enableResizing: true,
              enableSorting: false,
              header: "Item description",
              id: "KdtFocalItemTableLowerHeader",
            }),
          ],
          header: memoizedSearchBox,
          id: "KdtFocalItemTableUpperHeader",
        },
        ...dataColumns,
      ] as Array<GroupColumnDef<KeyDriverTreeTableRow>>,
    [columnHelper, createNameCell, memoizedSearchBox, dataColumns]
  );

  // Hide and show the Last period metrics
  const [lastPeriodToggle, setLastPeriodToggle] = useState<boolean>(false);

  const lastPeriodVisibility: VisibilityState = useMemo(() => {
    const visibilityState: VisibilityState = {};
    for (const measureGroup of measureGroups) {
      for (const measureType of measureGroup.measureTypes) {
        if (measureType === "Last period") {
          visibilityState[`${measureGroup.baseMeasure}-${measureType}`] =
            lastPeriodToggle;
        }
      }
    }

    return visibilityState;
  }, [lastPeriodToggle, measureGroups]);

  const [downloadTableTrigger] = useLazyDownloadTableQuery();
  const fileName = useMemo(
    () =>
      cleanFilename(
        `Key_driver_tree_${localParameterSelections.TimePeriodLength}_${localParameterSelections.LocationHierarchy.name}`
      ),
    [localParameterSelections]
  );
  const parameterSummary = useMemo(
    () => [
      { name: "Time", value: localParameterSelections.Time },
      { name: "Key driver", value: localParameterSelections.KeyDriver.label },
      { name: "Channel", value: localParameterSelections.Channel.label },
      { name: "Promotion", value: localParameterSelections.Promotion.label },
      {
        name: "Segmentation",
        value: localParameterSelections.Segmentation.label,
      },
      {
        name: "Customer segment",
        value: localParameterSelections.Segment.label,
      },
      ...(localParameterSelections.CompStore.label
        ? [
            {
              name: "Comp store",
              value: localParameterSelections.CompStore.label,
            },
          ]
        : []),
      {
        name: "Location",
        value: `(${hierarchyLevelDisplayLabel(
          localParameterSelections.LocationHierarchy.shortName
        )}) ${localParameterSelections.LocationHierarchy.name}`,
      },
    ],
    [localParameterSelections]
  );

  const handleDownloadButtonClick = useCallback(async () => {
    await downloadTableTrigger({
      divisionName,
      payload: {
        localParameters: parameterSummary,
        localParameterSelections: getUserSelections(localParameterSelections),
        fileName,
        reportId: reportId ?? "",
      },
    });
  }, [
    downloadTableTrigger,
    divisionName,
    localParameterSelections,
    fileName,
    reportId,
    parameterSummary,
  ]);

  return (
    <ReportTopDrawer
      controls={[
        <Toggle
          checked={lastPeriodToggle}
          key="last-period-toggle"
          label="Last period"
          onClick={() => {
            setLastPeriodToggle(!lastPeriodToggle);
          }}
        />,
        <div key="data-table-options">
          <DataTableOptions
            filename={fileName}
            invokeCSVDownload={handleDownloadButtonClick}
            isFeatureEnabled={isExportEnabled}
            isInFocalItemHeader
            key={reportId}
          />
        </div>,
      ]}
      items={
        focalItem.nodeNumber === EMPTY_NODE_NUMBER
          ? []
          : [
              {
                code: focalItem.shortName,
                displayName: focalItem.name,
                type: HierarchyItemType.Hierarchy,
              },
            ]
      }
      renameReport={renameReport}
      reportId={reportId}
      reportName={reportName}
      reportType={reportType}
    >
      <ErrorBoundary>
        <ReportHierarchyTableWrapper isSuccess={!isInitialTableFetching}>
          <VirtuosoTableComponent
            columnVisibility={lastPeriodVisibility}
            columns={columns}
            data={tableRows}
            getRowId={getRowId}
            getSubRows={(row) => row.subRows}
            onExpandedChange={setExpandState}
            pinFirstColumn
            refreshingData={isSearchFetching || refetchTableFetching}
            rowExpandedState={expandState}
            rowSelectionState={rowSelection}
          />
          {displayEmptySearch && (
            <div className={styles.emptySearch}>
              <EmptySearch />
            </div>
          )}
        </ReportHierarchyTableWrapper>
      </ErrorBoundary>
    </ReportTopDrawer>
  );
};

export default KeyDriverTreeTopDrawer;
