import {
  ddLog,
  type HierarchyValueDto,
  type HierarchyValue,
} from "@quantium-enterprise/common-ui";
import { useNumberFormat, useDivision } from "@quantium-enterprise/hooks-ui";
import { ChartFooterWrapper } from "components-ui/src/charts/chart-footer-wrapper/ChartFooterWrapper";
import { ChartOptions } from "components-ui/src/charts/chart-options/ChartOptions";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { SwitchingBreakdownTable } from "../../../../../components/src/tables/switching-breakdown-table/SwitchingBreakdownTable";
import { ChartDataWrapper } from "../../../common/components/ChartDataWrapper";
import { EMPTY_NODE_NUMBER } from "../../../common/constants";
import { type RootState } from "../../../store";
import { getMeasureLabel } from "../../models/GainsAndLossReportValues";
import { useLazyGetBreakdownMiniDataQuery } from "../../services/gains-and-loss-breakdown-mini-chart-slice";
import { useLazyGetBreakdownSegmentationQuery } from "../../services/gains-and-loss-breakdown-segmentation-chart-slice";
import {
  selectChannel,
  selectCompStore,
  selectFocalItem,
  selectLocation,
  selectMetricSet,
  selectSegment,
  selectSegmentation,
  selectShowSwitchingBreakdownChartDataLabels,
  selectTime,
  selectTimePeriodLength,
} from "../../services/gains-and-loss-slice";
import { getParameterSummaryValueStrings } from "../../utils/getParameterSummaryValueStrings";
import { getMiniChartRequestDto } from "../../utils/mini-chart-utils";
import { getSegmentationChartRequestDto } from "../../utils/segmentation-chart-utils";
import { GainsAndLossBreakdownMiniChart } from "./GainsAndLossBreakdownMiniChart";
import { GainsAndLossBreakdownSegmentationChart } from "./GainsAndLossBreakdownSegmentationChart";
import styles from "./LargestGainsLosses.module.css";
import { TOP_ITEMS_COUNT, getLargestGainsLosses } from "./utils";

export type LargestGainsLossesProps = {
  className?: string;
  isExportEnabled: boolean;
};

export const LargestGainsLosses = ({
  className,
  isExportEnabled,
}: LargestGainsLossesProps) => {
  const { metricFormatter, integerFormatter } = useNumberFormat();
  const { name: divisionName } = useDivision();
  const { id } = useParams();
  const chartContainerRef = useRef<HTMLElement>();

  const focalItem = useSelector(selectFocalItem);
  const channel = useSelector(selectChannel);
  const compStore = useSelector(selectCompStore);
  const location = useSelector(selectLocation);
  const metricSet = useSelector(selectMetricSet);
  const segment = useSelector(selectSegment);
  const segmentation = useSelector(selectSegmentation);
  const time = useSelector(selectTime);
  const timePeriodLength = useSelector(selectTimePeriodLength);
  const showSwitchingBreakdownChartDataLabels = useSelector(
    selectShowSwitchingBreakdownChartDataLabels
  );

  const { data } = useSelector((state: RootState) => ({
    data: state.gainsAndLoss.switchingBreakdownData,
  }));

  const selectedSegmentation = segmentation.value as string;

  const filteredItems = getLargestGainsLosses(data.items, TOP_ITEMS_COUNT);
  const formatImpactValue = (value?: number | string | null) =>
    metricFormatter(data.format, value);
  const impactColumnHeader = `Impact of switching (${
    getMeasureLabel[metricSet.value]
  })`;

  const exportFilename = useMemo(
    () =>
      cleanFilename(
        `Switching_breakdown_Chart_${timePeriodLength}_${location.name}`
      ),
    [timePeriodLength, location]
  );

  const parameterSummary = useMemo(
    () =>
      getParameterSummaryValueStrings(
        channel,
        compStore,
        location,
        metricSet,
        segment,
        segmentation,
        time,
        focalItem
      ),
    [
      channel,
      compStore,
      location,
      segment,
      segmentation,
      time,
      focalItem,
      metricSet,
    ]
  );

  const [selectedItem, setSelectedItem] = useState<HierarchyValueDto>({
    itemCode: "",
    name: "",
    shortName: "",
  });
  const [
    triggerBreakdownMiniChartQuery,
    {
      data: miniBreakdownChartData,
      isFetching: isBreakdownMiniChartLoading,
      isError: isBreakdownMiniChartError,
      isSuccess: isBreakdownMiniChartSuccess,
    },
  ] = useLazyGetBreakdownMiniDataQuery();

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const fetchBreakdownMiniChartData = useCallback(
    async (
      activeFocalItem: HierarchyValue,
      item: HierarchyValueDto,
      reportId: string
    ) => {
      const payload = getMiniChartRequestDto(
        activeFocalItem,
        item,
        location.nodeNumber.toString(),
        channel.value as string,
        compStore.value as string,
        reportId
      );
      await triggerBreakdownMiniChartQuery({ division: divisionName, payload });
    },
    [channel, compStore, divisionName, location, triggerBreakdownMiniChartQuery]
  );

  const [
    triggerBreakdownSegmentationChartQuery,
    {
      data: segmentationBreakdownChartData,
      isFetching: isBreakdownSegmentationChartLoading,
      isError: isBreakdownSegmentationChartError,
      isSuccess: isBreakdownSegmentationChartSuccess,
    },
  ] = useLazyGetBreakdownSegmentationQuery();

  const fetchBreakdownSegmentationChartData = useCallback(
    async (
      activeFocalItem: HierarchyValue,
      item: HierarchyValueDto,
      reportId: string,
      segmentationChosen: string
    ) => {
      const payload = getSegmentationChartRequestDto(
        activeFocalItem,
        item,
        location.nodeNumber.toString(),
        channel.value as string,
        compStore.value as string,
        reportId,
        segmentationChosen
      );
      if (segmentationChosen !== "") {
        await triggerBreakdownSegmentationChartQuery({
          division: divisionName,
          payload,
        });
      }
    },
    [
      channel,
      compStore,
      divisionName,
      location,
      triggerBreakdownSegmentationChartQuery,
    ]
  );

  const attemptFetchBreakdownSegmentationChartData = useCallback(() => {
    if (isExpanded && focalItem && id)
      fetchBreakdownSegmentationChartData(
        focalItem,
        selectedItem,
        id,
        selectedSegmentation
      ).catch((error) => {
        ddLog("ERROR", {}, "error", error);
      });
  }, [
    fetchBreakdownSegmentationChartData,
    focalItem,
    id,
    isExpanded,
    selectedItem,
    selectedSegmentation,
  ]);

  useEffect(() => {
    attemptFetchBreakdownSegmentationChartData();
  }, [attemptFetchBreakdownSegmentationChartData]);

  const handleOnRowClick = (item: HierarchyValueDto) => {
    setSelectedItem(item);
    setIsExpanded(true);

    if (focalItem && id && location.nodeNumber !== EMPTY_NODE_NUMBER) {
      fetchBreakdownMiniChartData(focalItem, item, id).catch((error) => {
        ddLog("ERROR", {}, "error", error);
      });
      fetchBreakdownSegmentationChartData(
        focalItem,
        item,
        id,
        selectedSegmentation
      ).catch((error) => {
        ddLog("ERROR", {}, "error", error);
      });
    }
  };

  const dataForMeasure = useMemo(() => {
    const measureData = miniBreakdownChartData?.find(
      (x) => x.measureName === metricSet.value
    );
    if (!measureData) {
      return {
        format: "",
        gainsAndLossValues: [],
        measureName: "",
      };
    }

    return measureData;
  }, [metricSet, miniBreakdownChartData]);

  return (
    <div className={className}>
      <SwitchingBreakdownTable
        dataArray={filteredItems}
        formatImpactValue={formatImpactValue}
        handleOnRowClick={handleOnRowClick}
        impactColumnHeader={impactColumnHeader}
        integerFormatter={integerFormatter}
        showSwitchingBreakdownChartDataLabels={
          showSwitchingBreakdownChartDataLabels
        }
      >
        <div className={styles.chartOptionsContainer}>
          <ChartOptions
            filename={exportFilename}
            getElementToExport={() => chartContainerRef.current}
            isFeatureEnabled={isExportEnabled}
            localParameters={parameterSummary}
          />
        </div>
        <ChartFooterWrapper
          height="400px"
          parameters={parameterSummary}
          ref={chartContainerRef}
        >
          <div className={styles.miniCharts}>
            <div className={styles.chart}>
              <ChartDataWrapper
                isError={isBreakdownMiniChartError}
                isSuccess={
                  isBreakdownMiniChartSuccess && !isBreakdownMiniChartLoading
                }
                minHeight="400px"
                retry={() => {
                  handleOnRowClick(selectedItem);
                }}
                showNoDataAvailable={
                  !isBreakdownMiniChartLoading && !miniBreakdownChartData
                }
              >
                <GainsAndLossBreakdownMiniChart
                  dataForMeasure={dataForMeasure}
                  item={selectedItem}
                />
              </ChartDataWrapper>
            </div>
            {segmentation.value && (
              <div className={styles.chart}>
                <ChartDataWrapper
                  isError={isBreakdownSegmentationChartError}
                  isSuccess={
                    isBreakdownSegmentationChartSuccess &&
                    !isBreakdownSegmentationChartLoading
                  }
                  retry={attemptFetchBreakdownSegmentationChartData}
                  showNoDataAvailable={
                    !isBreakdownSegmentationChartLoading &&
                    !segmentationBreakdownChartData
                  }
                >
                  <GainsAndLossBreakdownSegmentationChart
                    item={selectedItem}
                    measure={metricSet.value as string}
                    response={segmentationBreakdownChartData}
                  />
                </ChartDataWrapper>
              </div>
            )}
          </div>{" "}
        </ChartFooterWrapper>
      </SwitchingBreakdownTable>
    </div>
  );
};
