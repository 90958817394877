import { useFormatter } from "@quantium-enterprise/hooks-ui";
import { ChartColour } from "components-ui/src/charts/ChartColours";
import {
  HighchartsReact,
  type HighchartsReactProps,
  defaultOptions,
} from "components-ui/src/charts/highcharts-react/HighchartsReact";
import { type TooltipFormatterContextObject } from "highcharts";
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  type laddervalues,
  type Series,
} from "../models/pricing-ladders-common-models";
import {
  formatMetricValue,
  getPricingSeriesData,
  getSeriesInfo,
  sortLadderValues,
} from "../utils/common-utils";
import { HighchartsPlTooltip } from "./PricingLaddersHighChartsTooltip";

HighchartsMore(Highcharts);

export type PricingLaddersStandardChartProps = {
  categories: string[];
  onOptionsChanged?: (options: HighchartsReactProps) => void;
  pricingSeries: Series[];
  secondaryMetric: string;
  showChartDataLabels?: boolean;
  sortBy: string;
  viewBy: string;
};

const colors = [
  ChartColour.BrandCyan800,
  "var(--qbit-colour-text-primary)",
  ChartColour.BrandTurquoiseNew,
];

export const PricingLaddersStandardChart = ({
  pricingSeries,
  categories,
  secondaryMetric,
  sortBy,
  viewBy,
  showChartDataLabels,
  onOptionsChanged,
}: PricingLaddersStandardChartProps) => {
  const formatter = useFormatter();

  let colourIndex = 0;

  const seriesInfo = useMemo(() => getSeriesInfo(viewBy), [viewBy]);
  const pricingSeriesToProcess = useMemo(
    () =>
      pricingSeries.filter((series) => seriesInfo.includes(series.metricLabel)),
    [pricingSeries, seriesInfo]
  );
  // finding the series data based on selected viewby seriesInfo
  const { highSeries, midSeries, lowSeries } = getPricingSeriesData(
    pricingSeriesToProcess,
    seriesInfo
  );

  const [hiddenSeries, setHiddenSeries] = useState<number[]>([]);
  const legendItemClick = useCallback(
    (index: number) => {
      if (hiddenSeries.includes(index)) {
        setHiddenSeries(hiddenSeries.filter((number) => number !== index));
      } else {
        setHiddenSeries([...hiddenSeries, index]);
      }
    },
    [hiddenSeries]
  );

  const { maxValueInSecondaryMeasure, sortladderseries } = useMemo(() => {
    let maxValueInSecondary = Number.MIN_SAFE_INTEGER;

    const ladderValues: laddervalues[] = midSeries.map(
      ({ metricValue }, index) => ({
        x: index,
        low: formatMetricValue(lowSeries[index]?.metricValue),
        mid: formatMetricValue(metricValue),
        high: formatMetricValue(highSeries[index]?.metricValue),
      })
    );

    // if secondarymetric is selected then adding the entry to laddervalues (secondaryValue) and finding the max in SecondaryMeasure
    if (secondaryMetric) {
      const filteredAdditionalSeries = pricingSeries.find(
        (metricSeries) => metricSeries.metricLabel === secondaryMetric
      )?.data;
      if (filteredAdditionalSeries) {
        for (const [index, item] of ladderValues.entries()) {
          item.secondaryvalue = formatMetricValue(
            filteredAdditionalSeries[index]?.metricValue
          );
          if (
            item.secondaryvalue &&
            item.secondaryvalue > maxValueInSecondary
          ) {
            maxValueInSecondary = item.secondaryvalue;
          }
        }
      }
    }

    return {
      maxValueInSecondaryMeasure: maxValueInSecondary,
      // sorting the laddervalues based on the selected sortBy option
      sortladderseries: sortLadderValues(
        [...ladderValues],
        sortBy,
        secondaryMetric
      ),
    };
  }, [
    highSeries,
    lowSeries,
    midSeries,
    pricingSeries,
    secondaryMetric,
    sortBy,
  ]);

  // storing the xindex values for categories mapping
  const xindex = useMemo(
    () => sortladderseries.map((data) => data.x),
    [sortladderseries]
  );

  const {
    minLadderValue,
    maxLadderValue,
    lowSeriesData,
    midSeriesData,
    highSeriesData,
  } = useMemo(() => {
    let minLadder = Number.MAX_SAFE_INTEGER;
    let maxLadder = Number.MIN_SAFE_INTEGER;
    const lowData: laddervalues[] = [];
    const midData: laddervalues[] = [];
    const highData: laddervalues[] = [];

    // Split the ladder values into the series used for rendering columns going up and down from the midpoint, and a marker at the mid
    // also finding min and max laddervalues
    for (const [index, ladderValue] of sortladderseries.entries()) {
      if (ladderValue.mid === null || ladderValue.mid === undefined) {
        // If we don't have a midpoint we can't interpret *any* of this data point
        continue;
      }

      // Low to mid column
      if (
        ladderValue.low !== null &&
        ladderValue.low !== undefined &&
        ladderValue.low <= ladderValue.mid
      ) {
        minLadder = Math.min(ladderValue.low, minLadder);
        maxLadder = Math.max(ladderValue.low, maxLadder);

        lowData.push({
          x: index,
          low: ladderValue.low,
          high: ladderValue.mid,
        });
      }

      // Mid to high column
      if (
        ladderValue.high !== null &&
        ladderValue.high !== undefined &&
        ladderValue.high >= ladderValue.mid
      ) {
        minLadder = Math.min(ladderValue.high, minLadder);
        maxLadder = Math.max(ladderValue.high, maxLadder);

        highData.push({
          x: index,
          low: ladderValue.mid,
          high: ladderValue.high,
        });
      }

      minLadder = Math.min(ladderValue.mid, minLadder);
      maxLadder = Math.max(ladderValue.mid, maxLadder);

      // Mid marker
      midData.push({
        x: index,
        y: ladderValue.mid,
      });
    }

    return {
      minLadderValue: minLadder,
      maxLadderValue: maxLadder,
      lowSeriesData: lowData,
      midSeriesData: midData,
      highSeriesData: highData,
    };
  }, [sortladderseries]);

  // We shift the axis so the primary series takes the top 4/5 of the chart
  const minValueForPrimaryAxis =
    minLadderValue - (maxLadderValue - minLadderValue) / 4;

  // Series data used for highcharts
  const chartSeries = useMemo(() => {
    const seriesData = pricingSeriesToProcess.map((series) => {
      let data;
      if (series.metricLabel === seriesInfo[2]) {
        data = lowSeriesData;
      } else if (series.metricLabel === seriesInfo[0]) {
        data = highSeriesData;
      } else {
        data = midSeriesData;
      }

      return {
        name: series.name,
        data,
        type: series.metricLabel === seriesInfo[1] ? "line" : "columnrange",
        color: colors[colourIndex++],
        custom: { metricFormat: series.format },
        yAxis: 0,
        visible: true,
      };
    });

    if (secondaryMetric) {
      const filteredAdditionalSeries = pricingSeries.find(
        (metricSeries) => metricSeries.metricLabel === secondaryMetric
      );

      if (filteredAdditionalSeries) {
        seriesData.push({
          name: filteredAdditionalSeries.name,
          data: sortladderseries.map((data, index) => ({
            x: index,
            y: data.secondaryvalue,
          })),

          type: "column",
          // static colour for secondary line series
          color: ChartColour.BrandBlue500,
          custom: {
            metricFormat: filteredAdditionalSeries.format,
          },
          yAxis: 1,
          visible: true,
        });
      }
    }

    for (const [index, seriesDataItem] of seriesData.entries()) {
      seriesDataItem.visible = !hiddenSeries.includes(index);
    }

    return seriesData;
  }, [
    colourIndex,
    hiddenSeries,
    highSeriesData,
    lowSeriesData,
    midSeriesData,
    pricingSeries,
    pricingSeriesToProcess,
    secondaryMetric,
    seriesInfo,
    sortladderseries,
  ]);

  const yAxis: Highcharts.YAxisOptions[] = useMemo(() => {
    const axisData: Highcharts.YAxisOptions[] = [
      {
        plotLines: [
          {
            value: 0,
            color: "var(--qbit-colour-shade-6)",
            width: 2,
            zIndex: 1,
          },
        ],
        labels: {
          formatter() {
            // eslint-disable-next-line react/no-this-in-sfc, unicorn/no-this-assignment
            const self = this;
            const value = self.value;
            const metricFormat =
              self.axis.series[0]?.userOptions.custom?.metricFormat ?? "None";
            return formatter(metricFormat)(value, false, "", true);
          },
          style: {
            color: "var(--qbit-colour-text-secondary)",
          },
        },
        title: {
          style: {
            fontWeight: "bold",
            color: "var(--qbit-colour-text-primary)",
          },
          text: "Pricing",
        },
        min: minValueForPrimaryAxis,
        max: maxLadderValue,
        tickInterval: (() => {
          const range = maxLadderValue - minValueForPrimaryAxis;
          // Force 0.01 intervals for small ranges
          if (range < 0.01) {
            return 0.01;
          }

          // Let Highcharts decide for larger ranges
          return undefined;
        })(),
      },
    ];

    if (secondaryMetric) {
      const filteredAdditionalSeries = pricingSeries.find(
        (metricSeries) => metricSeries.metricLabel === secondaryMetric
      );

      if (filteredAdditionalSeries) {
        axisData.push({
          labels: {
            formatter() {
              // eslint-disable-next-line react/no-this-in-sfc, unicorn/no-this-assignment
              const self = this;
              const value = self.value;
              const metricFormat =
                self.axis.series[0]?.userOptions.custom?.metricFormat ?? "None";
              return formatter(metricFormat)(value, false, "", true);
            },
            style: {
              color: "var(--qbit-colour-text-secondary)",
            },
          },
          title: {
            style: {
              fontWeight: "bold",
              color: "var(--qbit-colour-text-primary)",
            },
            text: filteredAdditionalSeries.metricLabel,
          },
          opposite: true,
          // Shift secondary axis so secondary series is bottom 1/5 of chart
          max: maxValueInSecondaryMeasure * 5,
          min: 0,
        });
      }
    }

    return axisData;
  }, [
    formatter,
    maxLadderValue,
    maxValueInSecondaryMeasure,
    minValueForPrimaryAxis,
    pricingSeries,
    secondaryMetric,
  ]);

  const options: HighchartsReactProps = useMemo(
    () => ({
      ...defaultOptions,
      chart: {
        ...defaultOptions.chart,
        style: {
          cursor: "pointer",
          fontFamily: "var(--qbit-font-family)",
        },
        height: "444px",
      },
      legend: {
        ...defaultOptions.legend,
        // square symbols
        symbolRadius: 0,
      },
      xAxis: {
        categories: xindex.map((index) => categories[index]),
        crosshair: {
          color: "var(--qbit-colour-chrome-background)",
          zIndex: 0,
        },
        title: {
          style: {
            color: "var(--qbit-colour-text-primary)",
          },
        },
        labels: {
          style: {
            color: "var(--qbit-colour-text-secondary)",
          },
        },
        visible: true,
      },
      // Typescript is complaining when it shouldn't. Types are ensured to be correct above anyway
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      yAxis: yAxis as any,
      tooltip: {
        ...defaultOptions.tooltip,
        ReactFormatter: (ttData) =>
          HighchartsPlTooltip({
            pointFormatter: (pt: TooltipFormatterContextObject) =>
              formatter(pt.series.userOptions.custom?.metricFormat),
            hasSentiment: false,
            ttData,
            yAxisTitle: `${ttData.x?.toString()}`,
            seriesInfo: chartSeries,
          }),
      },
      plotOptions: {
        column: {
          // sets width of each column by changing padding between them
          pointPadding: 0,
          stacking: "normal",
          events: {
            legendItemClick: (event) => legendItemClick(event.target.index),
          },
        },
        columnrange: {
          grouping: false,
          states: {
            inactive: {
              enabled: false,
            },
          },
          dataLabels: {
            // CURRENTLY Disabled until can fix the duplicate labels appearing in columnrange
            enabled: false,
          },
          events: {
            legendItemClick: (event) => legendItemClick(event.target.index),
          },
        },
        line: {
          marker: {
            enabled: true,
            symbol: "circle",
            radius: 7,
            lineColor: "white",
            lineWidth: 2,
          },
          zIndex: 1,
          events: {
            legendItemClick: (event) => legendItemClick(event.target.index),
          },
        },

        series: {
          lineWidth: 0,
          borderWidth: 0,
          cursor: "pointer",
          connectNulls: true,
          label: {
            enabled: false,
          },
          dataLabels: {
            enabled: showChartDataLabels,
            padding: 10,
            formatter() {
              // eslint-disable-next-line react/no-this-in-sfc, unicorn/no-this-assignment
              const self = this;
              const point = self.point;
              const metricFormat =
                point.series.userOptions.custom?.metricFormat ?? "None";
              return formatter(metricFormat)(point.y, false, "", true);
            },
            style: {
              color: "var(--qbit-colour-white)",
              fontFamily: "var(--qbit-font-family)",
              fontSize: "0.7rem",
              fontWeight: "var(--qbit-font-weight-medium)",
              textOutline: "var(--qbit-colour-text-primary)",
              strokeWidth: 3,
              cursor: "pointer",
            },
          },
        },
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      series: chartSeries as any,
    }),
    [
      xindex,
      yAxis,
      showChartDataLabels,
      chartSeries,
      categories,
      formatter,
      legendItemClick,
    ]
  );

  useEffect(() => {
    if (onOptionsChanged) {
      onOptionsChanged(options);
    }
  }, [onOptionsChanged, options]);

  return <HighchartsReact options={options} />;
};
