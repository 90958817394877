import { Button, ButtonVariant } from "@qbit/react";
import { uniqueId } from "@qbit/react/dist/common";
import {
  TrackingComponent,
  type HierarchyValueDto,
  TrackingEvent,
  HierarchyItemType,
  formatNumberDate,
  useGetUserQuery,
} from "@quantium-enterprise/common-ui";
import { useDivision, useNumberFormat } from "@quantium-enterprise/hooks-ui";
import { type CellContext, type ColumnDef } from "@tanstack/react-table";
import classNames from "classnames";
import { benchmarkColours } from "components-ui/src/charts/npb-chart/npb-chart-utils";
import { EmptySearch } from "components-ui/src/search/EmptySearch";
import { SearchBox } from "components-ui/src/search-box/SearchBox";
import { ExpandableNameCell } from "components-ui/src/tables/common/table-cell/ExpandableNameCell";
import { ValueCell } from "components-ui/src/tables/common/table-cell/ValueCell";
import { ReportHierarchyTableWrapper } from "components-ui/src/tables/report-hierarchy-table/components/ReportHierarchyTableWrapper";
import { VirtuosoTableComponent } from "components-ui/src/tables/virtuoso-table/VirtuosoTableComponent";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { type ProductRow } from "../models/new-product-benchmarking-data-table-models";
import {
  onSearchQueryChange,
  selectBenchmarkData,
  selectFocalItems,
  selectFocalTableInitialised,
  selectFocalTableProductsData,
  selectMetricsList,
  selectRowSelection,
  selectSearchQuery,
  selectShowBenchmarks,
  setFocalItems,
} from "../services/new-product-benchmarking-slice";
import styles from "./NewProductBenchmarkingFocalTable.module.scss";

const getRowId = (row: ProductRow) => row.itemCode;

const BenchmarkTitleCell = ({
  displayName,
  color,
}: {
  color: string;
  displayName: string;
}) => (
  <div className={styles.benchmarkTitleCell}>
    <ValueCell
      className={styles.valueCell}
      formatter={(name: string) => name}
      value={displayName}
    />
    <div className={styles.colorTile} style={{ backgroundColor: color }} />
  </div>
);

type FocalTableRow = ProductRow & {
  benchmarkIndex?: number;
};

export const NewProductBenchmarkingFocalTable = ({
  getMoreProductRows,
  tableLoading,
  moreRowsLoading,
  eventTrackingService,
}: {
  eventTrackingService: Function;
  getMoreProductRows: Function;
  moreRowsLoading: boolean;
  tableLoading: boolean;
}) => {
  const dispatch = useDispatch();
  const { metricFormatter } = useNumberFormat();

  const { name: divisionName } = useDivision();
  const { data: user } = useGetUserQuery();

  const benchmarkData = useSelector(selectBenchmarkData);
  const productData = useSelector(selectFocalTableProductsData);
  const showBenchmarks = useSelector(selectShowBenchmarks);
  const metricsList = useSelector(selectMetricsList);
  const rowSelection = useSelector(selectRowSelection);
  const focalItems = useSelector(selectFocalItems);
  const searchQuery = useSelector(selectSearchQuery);
  const focalTableInitialised = useSelector(selectFocalTableInitialised);

  const allMetrics = useMemo(
    () => metricsList.flatMap((x) => x.metrics),
    [metricsList]
  );

  const loadMoreAvailable = useMemo(
    () => productData.lastRowIndex < productData.totalRows - 1,
    [productData]
  );

  const combinedData: FocalTableRow[] = useMemo(
    () =>
      showBenchmarks
        ? benchmarkData.benchmarkRows
            .map(({ label, values }, index) => ({
              name: label,
              itemCode: label,
              launchWeek: "-",
              weeksInMarket: "-",
              values,
              benchmarkIndex: index,
            }))
            .reverse()
            // @ts-expect-error cannot cast number | undefined to number
            .concat(productData.productRows as FocalTableRow[])
        : (productData.productRows as FocalTableRow[]),
    [benchmarkData, productData, showBenchmarks]
  );

  const onSearchChange = useCallback(
    (value: string) => dispatch(onSearchQueryChange(value)),
    [dispatch]
  );

  const matchesFound = useMemo(() => {
    if (!searchQuery) {
      return undefined;
    }

    return productData.productRows.length;
  }, [productData.productRows, searchQuery]);

  const memoizedSearchBox = useMemo(
    () => (
      <div className={styles.searchbox}>
        <SearchBox
          enableDebounce
          onChange={onSearchChange}
          placeholder="Type to search"
          resultCount={matchesFound}
          searchQuery={searchQuery}
        />
      </div>
    ),
    [onSearchChange, matchesFound, searchQuery]
  );

  const displayEmptySearch: boolean =
    !tableLoading &&
    searchQuery.length > 0 &&
    productData.productRows.length === 0;
  const createNameCell = useCallback(
    ({ row }: CellContext<FocalTableRow, unknown>) =>
      row.original.benchmarkIndex === undefined ? (
        <div className={styles.productTitleCell}>
          <ExpandableNameCell
            canExpand={false}
            depth={0}
            handleToggleExpanded={row.getToggleExpandedHandler()}
            handleToggleSelected={(event) => {
              const selectedItem = {
                ...row.original,
                shortName: row.original.itemCode,
              } as HierarchyValueDto;
              row.getToggleSelectedHandler()(event);
              dispatch(
                setFocalItems({
                  isSelected: row.getIsSelected(),
                  selectedItem,
                  selectedRow: row.original,
                })
              );
              const focalItemsAfterSelection = focalItems.some(
                (x) => x.itemCode === selectedItem.itemCode
              )
                ? focalItems.filter((x) => x.itemCode !== selectedItem.itemCode)
                : focalItems.concat([selectedItem]);
              eventTrackingService(
                [
                  TrackingComponent.MyReports,
                  TrackingComponent.Report,
                  TrackingComponent.FocalItemTableRow,
                ],
                row.getIsSelected()
                  ? TrackingEvent.Unselected
                  : TrackingEvent.Selected,
                {
                  selection: focalItemsAfterSelection.map((x) => x.name),
                  division: divisionName,
                  user: user?.isSupplier ? "Supplier" : "Retailer",
                }
              );
            }}
            isExpanded={false}
            isSelected={row.getIsSelected()}
            name={row.original.name}
            shortName="PD"
            type={HierarchyItemType.Hierarchy}
            value={row.original.name}
          />
        </div>
      ) : (
        <BenchmarkTitleCell
          color={
            benchmarkColours[
              benchmarkColours.length - row.original.benchmarkIndex - 1
            ]
          }
          displayName={row.original.name}
        />
      ),
    [dispatch, focalItems, eventTrackingService, divisionName, user?.isSupplier]
  );

  const valueCellCallback = useCallback(
    (value: number, formatter: (value: number) => number | string) => (
      <ValueCell formatter={formatter} value={value} />
    ),
    []
  );

  // @ts-expect-error typecheck issue with memoizedSearchBox as header
  const columnDefs: Array<ColumnDef<FocalTableRow>> = useMemo(
    () => {
      const weekColumns =
        benchmarkData.metrics.length > 1
          ? benchmarkData.weeks.map((weekName, weekIndex) => ({
              header: weekName,
              id: uniqueId(),
              columns: benchmarkData.metrics.map((metricName, metricIndex) => ({
                id: uniqueId(),
                header: metricName,
                accessorFn: (row: FocalTableRow) => row,
                cell: ({ getValue }: { getValue: Function }) => {
                  const value =
                    getValue().values.length > weekIndex
                      ? getValue().values[weekIndex][metricIndex]
                      : "-";

                  const numberFormat =
                    allMetrics.find((x) => x.name === metricName)?.format ??
                    "Currency";

                  return valueCellCallback(value, (value_: number) =>
                    metricFormatter(numberFormat, value_)
                  );
                },
              })),
            }))
          : benchmarkData.weeks.map((weekName, weekIndex) => ({
              id: uniqueId(),
              header: weekName,
              accessorFn: (row: FocalTableRow) => row,
              cell: ({ getValue }: { getValue: Function }) => {
                const value =
                  getValue().values.length > weekIndex
                    ? getValue().values[weekIndex][0]
                    : "-";

                const numberFormat =
                  allMetrics.find((x) => x.name === benchmarkData.metrics[0])
                    ?.format ?? "Currency";

                return valueCellCallback(value, (value_: number) =>
                  metricFormatter(numberFormat, value_)
                );
              },
            }));

      return [
        {
          header: memoizedSearchBox,
          id: uniqueId(),
          accessorFn: (row: FocalTableRow) => row,
          ...(benchmarkData.metrics.length > 1 ? {} : { cell: createNameCell }),
          ...(benchmarkData.metrics.length > 1
            ? {
                columns: [
                  {
                    cell: createNameCell,
                    id: uniqueId(),
                    header: "Item description",
                  },
                ],
              }
            : {}),
        },
        {
          header: "Launch week",
          accessorFn: (row: FocalTableRow) => row.launchWeek,
          cell: ({ getValue }: { getValue: Function }) =>
            valueCellCallback(getValue(), (value: number | string) =>
              value === "-" ? value : formatNumberDate(Number(value))
            ),
        },
        {
          header: "Weeks in market",
          accessorFn: (row: FocalTableRow) => row.weeksInMarket,
          cell: ({ getValue }: { getValue: Function }) =>
            valueCellCallback(getValue(), (value: number) => value),
        },
        ...weekColumns,
      ];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      benchmarkData,
      allMetrics,
      createNameCell,
      valueCellCallback,
      memoizedSearchBox,
      // TODO: metric formatter is causing unnecessary rerenders
      // metricFormatter
    ]
  );

  return (
    <ReportHierarchyTableWrapper
      className={styles.npbFocalTableWrapper}
      isSuccess={focalTableInitialised}
    >
      <VirtuosoTableComponent
        className={classNames(styles.npbFocalTable, {
          [styles.multipleMetricsFormat]: benchmarkData.metrics.length > 1,
        })}
        columns={columnDefs}
        data={combinedData}
        getRowId={getRowId}
        getSubRows={() => []}
        pinFirstColumn
        refreshingData={tableLoading}
        rowExpandedState={{ "0": true }}
        rowSelectionState={rowSelection}
      />

      <div className={styles.loadMoreButtonWrapper}>
        {loadMoreAvailable && (
          <Button
            loading={moreRowsLoading}
            loadingLabel="Loading more rows"
            onClick={() => getMoreProductRows()}
            text="Load more rows..."
            variant={ButtonVariant.Stealth}
          />
        )}
        {displayEmptySearch && (
          <div className={styles.emptySearch}>
            <EmptySearch />
          </div>
        )}
      </div>
    </ReportHierarchyTableWrapper>
  );
};
