import { Item, InlineIcon, InlineIconGlyph, Text } from "@qbit/react";
import styles from "./WarningBanner.module.css";

export type WarningBannerProps = {
  extraPadding?: boolean;
  text: string;
};

export const WarningBanner = ({
  extraPadding = false,
  text,
}: WarningBannerProps) => (
  <Item
    className={`${styles.warningBannerContainer} ${
      extraPadding ? styles.extraPadding : ""
    }`}
  >
    <InlineIcon
      colour="warning"
      glyph={InlineIconGlyph.AlertsAndNotificationsWarning}
      text={text}
    />
    <Text>{text}</Text>
  </Item>
);

export default WarningBanner;
