import {
  MessageVariant,
  QbitEmitToast,
  QbitToastMessage,
  Icon,
  IconColour,
  IconGlyph,
} from "@qbit/react";
import {
  type RenameCustomerGroupRequestDto,
  EMIT_TOAST_DURATION,
  type SharedUserDto,
  type SseMessageQueryArguments,
  type CustomerGroupWithSharingDto,
  useGetCustomerGroupTypesQuery,
  useGetCustomerGroupsQuery,
  type CustomerGroupStatus,
  useCreateCustomerGroupValidationTaskMutation,
  useGetMessagesQuery,
  isCustomerGroupDto,
  FeatureFlag,
  useGetUsersByIdQuery,
  useRenameCustomerGroupMutation,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import { type CellContext, type ColumnDef } from "@tanstack/react-table";
import { CustomerGroupStatusTag } from "components-ui/src/customer-group-status-tag/CustomerGroupStatusTag";
import { EditableField } from "components-ui/src/editable-field/EditableField";
import { CustomerGroupIcon } from "components-ui/src/icons";
import { SharedUserIconDisplay } from "components-ui/src/shared-user-icon-display/SharedUserIconDisplay";
import { useCallback, useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GroupType } from "../../../enums/group-type";
import {
  selectSearchText,
  setActivatedGroupIds,
  setDeleteCustomerGroups,
  setFocalCustomerGroup,
  setShareCustomerGroup,
  toggleSelectedCustomerGroups,
} from "../../../states/group-list-slice";
import {
  type GroupRow,
  TimeElapsedCell,
  TypeCell,
  GroupListTable,
  QuickActionsCell,
} from "../GroupListTable";
import styles from "./CustomerGroupListTable.module.css";

type CustomerGroupType = {
  type: string;
  typeDisplayName: string;
};

type CustomerGroupRow = CustomerGroupWithSharingDto;

const timeElapsedCell = (info: CellContext<CustomerGroupRow, unknown>) => (
  <TimeElapsedCell timestamp={info.getValue<string>()} />
);

const statusCell = (info: CellContext<CustomerGroupRow, unknown>) => (
  <span>
    <CustomerGroupStatusTag
      status={info.getValue<string>() as CustomerGroupStatus}
    />
  </span>
);

const typeCell = (info: CellContext<CustomerGroupRow, unknown>) => {
  const { type, typeDisplayName } = info.getValue<CustomerGroupType>();
  return (
    <TypeCell icon={<CustomerGroupIcon type={type} />} text={typeDisplayName} />
  );
};

const SharedCell = (
  allReportSharedUsers: SharedUserDto[],
  info: CellContext<CustomerGroupRow, unknown>
) => (
  <SharedUserIconDisplay
    users={allReportSharedUsers.filter((user) =>
      info.row.original.sharedWithUserIds?.includes(user.salesforceUserId)
    )}
  />
);

export const CustomerGroupListTable = () => {
  const flags = useFlags();
  const { name: divisionName } = useDivision();
  const dispatch = useDispatch();

  const searchTerm = useSelector(selectSearchText);

  const {
    data: serverGroupList,
    isFetching: isGroupListFetching,
    isUninitialized,
  } = useGetCustomerGroupsQuery(
    {
      divisionName,
      searchTerm,
    },
    { skip: !divisionName }
  );

  const { data: groupTypes } = useGetCustomerGroupTypesQuery(divisionName, {
    skip: !divisionName,
  });

  const { data: sseData } = useGetMessagesQuery({
    url: "/sse/server-sent-events",
  } as SseMessageQueryArguments);

  const [createCustomerGroupValidationTask] =
    useCreateCustomerGroupValidationTaskMutation();

  const handleRefresh = useCallback(
    async (groupId: string) => {
      if (groupId) {
        await createCustomerGroupValidationTask({ divisionName, groupId });
      }
    },
    [createCustomerGroupValidationTask, divisionName]
  );

  const [preventBlur, setPreventBlur] = useState(false);

  const [renameState, setRenameState] = useState<{
    id: string | undefined;
  }>({ id: undefined });

  const [renameCustomerGroupMutation] = useRenameCustomerGroupMutation();
  const renameCustomerGroupTrigger = async (value: string) => {
    const renameRequest: RenameCustomerGroupRequestDto = {
      groupId: renameState.id ?? "",
      proposedName: value,
    };
    try {
      await renameCustomerGroupMutation({
        divisionName,
        renameRequest,
      }).unwrap();
    } catch (error) {
      // @ts-expect-error cant cast it into the right type to access the status
      const errorStatus = error?.status;
      const is409 = errorStatus === 409;
      const content = is409
        ? "Please use a group name that is unique."
        : "An unknown error has occurred";
      const heading = is409 ? "Rename error" : "Unknown error";
      QbitEmitToast(
        <QbitToastMessage
          content={<p>{content}</p>}
          heading={<h5>{heading}</h5>}
          showIcon
          variant={MessageVariant.Danger}
        />,
        {
          autoClose: EMIT_TOAST_DURATION,
        }
      );
      return false;
    }

    setRenameState({ id: undefined });
    return true;
  };

  useEffect(() => {
    const activatedGroupIds = serverGroupList
      ?.filter((group) =>
        group.segments.some(
          (segment) =>
            segment.activationDate !== undefined ||
            segment.stagedActivationDate !== undefined
        )
      )
      .map((stagedGroup) => stagedGroup.id);

    dispatch(setActivatedGroupIds(activatedGroupIds ?? []));
  }, [serverGroupList, dispatch]);

  type useNameCellProps = {
    id: string;
    name: string;
  };

  const useNameCell = (info: CellContext<CustomerGroupRow, unknown>) => {
    const { name, id } = info.getValue<useNameCellProps>();

    return (
      <div
        className={styles.row}
        onClick={(event) => {
          if (renameState.id === id) {
            event.stopPropagation();
          }
        }}
        // onKeyDown added for accessibility to  satisfy
        // the jsx-a11y/click-events-have-key-events linter rule.
        // It doesn't change  functionality or user experience.
        onKeyDown={(event) => {
          if (event.key === "Enter" && renameState.id === id) {
            event.stopPropagation();
          }
        }}
        role="button"
        style={{ display: "flex", alignItems: "center" }}
        tabIndex={0}
      >
        <Icon
          colour={IconColour.Notification}
          glyph={IconGlyph.AccountAndUserAccount}
          text="Customer group"
        />
        <EditableField
          editableFieldState={{
            isEditing: renameState.id === id,
            toggleEditing: () => {},
          }}
          onlyExternalState
          preventBlur={preventBlur}
          save={renameCustomerGroupTrigger}
          stopEditing={() => setRenameState({ id: undefined })}
          textStyle={styles.editableField}
          value={name}
        />
      </div>
    );
  };

  const rows: CustomerGroupRow[] = useMemo(
    () =>
      serverGroupList
        ?.map((group): CustomerGroupRow => {
          const sseUpdatedGroup = [...(sseData ?? [])]
            .reverse()
            .filter((x) => isCustomerGroupDto(x))
            .find((x) => x.id === group.id) as CustomerGroupRow | undefined;

          // if there is an SSE update for this group, update it
          return {
            ...(sseUpdatedGroup ?? group),
            typeDisplayName:
              groupTypes?.find((type) => type.customerGroupType === group.type)
                ?.displayName ?? "",
          };
        })
        .sort((row1, row2) =>
          // We need to manually sort because we use scrollToIndex and this list will be used to find the index.
          // If we rely on the internal sorting of react table model, then this list will be out of sync
          // with what is actually being rendered and thus the find index will be wrong.
          // (i.e. this is only a problem when using both scrollToIndex and sorting)
          !row1.refreshDateUtc && !row2.refreshDateUtc
            ? 0
            : // eslint-disable-next-line no-negated-condition
            !row1.refreshDateUtc
            ? -1
            : // eslint-disable-next-line no-negated-condition
            !row2.refreshDateUtc
            ? 1
            : row1.refreshDateUtc < row2.refreshDateUtc
            ? 1
            : row1.refreshDateUtc > row2.refreshDateUtc
            ? -1
            : 0
        ) ?? [],
    [serverGroupList, sseData, groupTypes]
  );

  const { data: allGroupSharedUsers, isLoading: isAllGroupSharedUsersLoading } =
    useGetUsersByIdQuery(
      {
        divisionName,
        payload: {
          SalesforceUserIds: rows.flatMap(
            (report) => report.sharedWithUserIds ?? []
          ),
        },
      },
      {
        skip: rows.length === 0 || !flags[FeatureFlag.SharingGroups],
      }
    );

  const handleDeleteGroup = useCallback(
    (id: string) => {
      if (!id) return;
      const group = rows.find((row) => row.id === id);
      if (!group) return;
      dispatch(setDeleteCustomerGroups([group]));
    },
    [dispatch, rows]
  );

  const getCustomerGroup = useCallback(
    (groupId: string) => rows.find((row) => row.id === groupId),
    [rows]
  );
  const setFocalGroupById = useCallback(
    (groupId: string | undefined) => {
      const group = rows.find((row) => row.id === groupId);
      dispatch(setFocalCustomerGroup(group));
    },
    [rows, dispatch]
  );

  const handleShare = useCallback(
    (groupId: string | undefined) => {
      const group = rows.find((row) => row.id === groupId);
      dispatch(setShareCustomerGroup(group));
    },
    [rows, dispatch]
  );

  const handleRename = useCallback((groupId: string | undefined) => {
    // Prevent early exit of EditableField after change of editing state
    setPreventBlur(true);
    setRenameState({ id: groupId });
    setTimeout(() => setPreventBlur(false), 100);
  }, []);

  const quickActionsCell = useCallback(
    (info: CellContext<CustomerGroupRow, unknown>) => (
      <QuickActionsCell
        getCustomerGroup={getCustomerGroup}
        groupId={info.getValue<string>()}
        handleDelete={handleDeleteGroup}
        handleRefresh={handleRefresh}
        handleRename={handleRename}
        handleShare={handleShare}
      />
    ),
    [
      handleRefresh,
      handleDeleteGroup,
      handleRename,
      handleShare,
      getCustomerGroup,
    ]
  );

  const refreshColumnId = "refreshed";

  const columns: Array<ColumnDef<CustomerGroupRow>> = [
    {
      accessorFn: (row: CustomerGroupRow): useNameCellProps => ({
        name: row.name,
        id: row.id,
      }),
      cell: useNameCell,
      header: "Name",
      id: "name",
      minSize: 100,
      maxSize: 900,
      size: 200,
    },
    {
      accessorFn: (row: CustomerGroupRow) => row.id,
      cell: quickActionsCell,
      header: "",
      id: "quickActions",
      minSize: 20,
      maxSize: 20,
      size: 20,
    },
    {
      accessorFn: (row: CustomerGroupRow) => row.status,
      cell: statusCell,
      header: "Status",
      id: "status",
      minSize: 50,
      maxSize: 100,
      size: 80,
    },
    {
      accessorFn: (row: CustomerGroupRow) => row.refreshDateUtc,
      cell: timeElapsedCell,
      header: "Last refreshed",
      id: refreshColumnId,
      minSize: 50,
      maxSize: 100,
      size: 70,
    },
    {
      accessorFn: (row: CustomerGroupRow): CustomerGroupType => ({
        type: row.type,
        typeDisplayName: row.typeDisplayName,
      }),
      cell: typeCell,
      header: "Type",
      id: "type",
      minSize: 100,
      maxSize: 200,
      size: 100,
    },
  ];

  if (flags[FeatureFlag.SharingGroups]) {
    columns.push({
      accessorFn: (row) => row.sharedWithUserIds,
      cell: (info) => SharedCell(allGroupSharedUsers ?? [], info),
      header: "Shared",
      id: "shared",
      minSize: 50,
      maxSize: 100,
      size: 75,
    });
  }

  return (
    <GroupListTable
      columns={columns as Array<ColumnDef<GroupRow>>}
      groupType={GroupType.Customer}
      isFetchUnitialised={isUninitialized}
      isFetching={isGroupListFetching || isAllGroupSharedUsersLoading}
      onCheckboxSelect={(groupId) =>
        dispatch(toggleSelectedCustomerGroups(groupId))
      }
      rows={rows}
      setFocalGroup={setFocalGroupById}
      sortByColumnId={refreshColumnId}
    />
  );
};
