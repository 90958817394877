import { useNumberFormat } from "@quantium-enterprise/hooks-ui";
import {
  type SortingState,
  type Updater,
  type CellContext,
  type ColumnDef,
} from "@tanstack/react-table";
import { EmptySearch } from "components-ui/src/search/EmptySearch";
import { SearchBox } from "components-ui/src/search-box/SearchBox";
import { HierarchyValueCell } from "components-ui/src/tables/common/table-cell/HierarchyValueCell";
import { VirtuosoTableComponent } from "components-ui/src/tables/virtuoso-table/VirtuosoTableComponent";
import {
  ColumnType,
  HeaderVerticalAlign,
} from "components-ui/src/tables/virtuoso-table/VirtuosoTableHeaderElement";
import { useCallback, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReportletTableIdPurchasedWith } from "../constants/basket-affinities-common";
import { type purchasedWithAssociatedData } from "../models/basket-affinities-chart-models";
import {
  selectPurchasedWithAssociatedSearched,
  selectPurchasedWithProductsSortingState,
  onReportletTableSort,
  onReportletSearchQueryChange,
  selectReportletSearchQuery,
} from "../services/basket-affinities-slice";
import styles from "./BasketAffinitiesPurchasedWithFocalItemsTableEnhanced.module.css";

export const BasketAffinitiesPurchasedWithFocalItemsTableV2 = () => {
  const searchQuery = useSelector(selectReportletSearchQuery);
  const tableData: purchasedWithAssociatedData[] = useSelector(
    selectPurchasedWithAssociatedSearched
  );
  const sortingState = useSelector(selectPurchasedWithProductsSortingState);

  const searchReportletRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const resultSize = useMemo(() => tableData.length, [tableData.length]);

  const {
    integerFormatter,
    currencyFormatter,
    decimalFormatter,
    percentFormatter,
  } = useNumberFormat();

  const createNameCell = useCallback(
    ({ row }: CellContext<purchasedWithAssociatedData, unknown>) => (
      <HierarchyValueCell item={row.original.product} />
    ),
    []
  );

  const createNumberCell = useCallback(
    (
      formatter: (
        value: number | string,
        alwaysDisplaySign: boolean,
        suffix?: string
      ) => string,
      value: string,
      suffix?: string
    ) => (
      <div className={styles.numberCell}>{formatter(value, false, suffix)}</div>
    ),
    []
  );

  const onSearchChange = useCallback(
    (value: string) => {
      dispatch(onReportletSearchQueryChange(value));
      searchReportletRef.current?.querySelector("input")?.focus();
    },
    [dispatch]
  );

  const searchHeader = useMemo(
    () => (
      <div className={styles.searchHeader} ref={searchReportletRef}>
        <div className={styles.searchHeaderTitle}>
          Associated items ({resultSize})
        </div>
        <SearchBox
          onChange={onSearchChange}
          placeholder="Type to search"
          searchQuery={searchQuery}
        />
      </div>
    ),
    [onSearchChange, resultSize, searchQuery]
  );

  const columns: Array<ColumnDef<purchasedWithAssociatedData>> = useMemo(
    () => [
      {
        accessorFn: (x: purchasedWithAssociatedData) => x.product.name,
        cell: createNameCell,
        enableHiding: false,
        enableResizing: true,
        enableSorting: false,
        enableRowFiltering: false,
        header: () => searchHeader,
        id: "product",
        meta: {
          columnType: ColumnType.Text,
        },
      },
      /* This is not required until milestone 4
      {
        accessorFn: (x: purchasedWithAssociatedData) => x.associatedAncestor.name,
        cell: (cell: CellContext<PurchasedWithProduct, unknown>) =>
          createTextCell(`${cell.getValue()}`),
        enableHiding: false,
        enableResizing: true,
        enableSorting: true,
        enableRowFiltering: false,
        header: "Subcategory",
        meta: {
          columnType: ColumnType.Text,
          headerVerticalAlign: HeaderVerticalAlign.Bottom,
        },
      }, */
      {
        accessorFn: (x: purchasedWithAssociatedData) => x.uplift,
        cell: ({ getValue }: { getValue: () => unknown }) =>
          createNumberCell(decimalFormatter, `${getValue()}`, " x"),
        enableHiding: false,
        enableResizing: true,
        enableSorting: true,
        enableRowFiltering: false,
        header: "Uplift",
        meta: {
          columnType: ColumnType.Numeric,
          headerVerticalAlign: HeaderVerticalAlign.Bottom,
        },
      },
      {
        accessorFn: (x: purchasedWithAssociatedData) => x.basketsWithBoth,
        cell: ({ getValue }: { getValue: () => unknown }) =>
          createNumberCell(integerFormatter, `${getValue()}`),
        enableHiding: false,
        enableResizing: true,
        enableSorting: true,
        enableRowFiltering: false,
        header: "Baskets with both",
        meta: {
          columnType: ColumnType.Numeric,
          headerVerticalAlign: HeaderVerticalAlign.Bottom,
        },
      },
      {
        accessorFn: (x: purchasedWithAssociatedData) => x.confidence,
        cell: ({ getValue }: { getValue: () => unknown }) =>
          createNumberCell(percentFormatter, `${getValue()}`),
        enableHiding: false,
        enableResizing: true,
        enableSorting: true,
        enableRowFiltering: false,
        header: "Confidence (Basket With both/ Baskets with focal item)",
        meta: {
          columnType: ColumnType.Numeric,
          headerVerticalAlign: HeaderVerticalAlign.Bottom,
        },
      },
      {
        accessorFn: (x: purchasedWithAssociatedData) => x.support,
        cell: ({ getValue }: { getValue: () => unknown }) =>
          createNumberCell(percentFormatter, `${getValue()}`),
        enableHiding: false,
        enableResizing: true,
        enableSorting: true,
        enableRowFiltering: false,
        header: "Support (Associated baskets / Total baskets)",
        meta: {
          columnType: ColumnType.Numeric,
          headerVerticalAlign: HeaderVerticalAlign.Bottom,
        },
      },
      {
        accessorFn: (x: purchasedWithAssociatedData) => x.associatedSales,
        cell: ({ getValue }: { getValue: () => unknown }) =>
          createNumberCell(currencyFormatter, `${getValue()}`),
        enableHiding: false,
        enableResizing: true,
        enableSorting: true,
        enableRowFiltering: false,
        header: "Associated sales per basket",
        meta: {
          columnType: ColumnType.Numeric,
          headerVerticalAlign: HeaderVerticalAlign.Bottom,
        },
      },
    ],
    [
      createNameCell,
      searchHeader,
      createNumberCell,
      percentFormatter,
      integerFormatter,
      currencyFormatter,
      decimalFormatter,
    ]
  );

  const onSort = (sorting: Updater<SortingState>): void => {
    dispatch(onReportletTableSort(sorting));
  };

  return (
    <div
      className={styles.table}
      id={`${ReportletTableIdPurchasedWith}`}
      style={{ height: 400 }}
    >
      <VirtuosoTableComponent
        columns={columns}
        data={tableData}
        enableSorting
        getRowId={(row) => row.product.itemCode}
        onSortingChange={onSort}
        pinFirstColumn
        sorting={sortingState}
      />
      {searchQuery.length > 0 && tableData.length === 0 && (
        <div className={styles.noSearchResults}>
          <EmptySearch />
        </div>
      )}
    </div>
  );
};
