/* eslint-disable typescript-sort-keys/string-enum */
export enum TrackingEvent {
  Added = "Added",
  Created = "Created",
  Collapsed = "Collapsed",
  Copied = "Copied",
  Deleted = "Deleted",
  Expanded = "Expanded",
  GroupsLimitReached = "10K Limit Reached",
  Navigated = "Navigated",
  Opened = "Opened",
  Organised = "Organised",
  Parameters = "Parameters",
  Reset = "Reset",
  Removed = "Removed",
  Searched = "Searched",
  Selected = "Selected",
  Shared = "Shared",
  Submitted = "Submitted",
  Testing = "Testing",
  Unselected = "Unselected",
  Unshared = "Unshared",
}
