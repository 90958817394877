import {
  type AttributeFilter,
  ddLog,
  type FilterCriteria,
  type HierarchyAttributeParameterOption,
  HierarchyItemType,
  HierarchyType,
  type ParameterDto,
  useLazyAttributesCountQuery,
  useLazyCountChildrenQuery,
} from "@quantium-enterprise/common-ui";
import { isHierarchyAttributeParameterOption } from "components-ui/src/checkbox-list/CheckboxGroup";
import { useCallback, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  RadioSelectTable,
  type RadioSelectTableOption,
} from "../../../../components/src/tables/radio-select-table/RadioSelectTable";
import { type HierarchyState } from "../hierarchy/HierarchyState";
import { type LevelOfAnalysisState } from "../hierarchy/LevelOfAnalysisState";
import styles from "./LevelParameter.module.css";

const MINIMUM_COUNT_REQUIREMENT = 2;

export type LevelParameterProps = {
  handleSelect: (value: string, parent?: string) => void;
  hierarchyState?: HierarchyState;
  levelState?: LevelOfAnalysisState;
  parameterDto: ParameterDto;
};

export const LevelParameter = ({
  parameterDto,
  hierarchyState,
  levelState,
  handleSelect,
}: LevelParameterProps) => {
  const { division } = useParams();

  const hasHierarchySelections = useMemo(
    () => Boolean(hierarchyState && hierarchyState.selectedRows.length > 0),
    [hierarchyState]
  );

  const [
    triggerAttributesCountQuery,
    { data: attributesCount, isFetching: isAttributesCountLoading },
  ] = useLazyAttributesCountQuery();

  const [
    triggerChildrenCountQuery,
    { data: childrenCount, isFetching: isChildrenCountLoading },
  ] = useLazyCountChildrenQuery();

  const fetchLOACounts = useCallback(async () => {
    if (!hierarchyState || !levelState) {
      return;
    }

    const uniqueShortNames = new Set(
      hierarchyState.selectedRows.map((selectedRow) => selectedRow.shortName)
    );
    const selectedItems = Array.from(uniqueShortNames).map(
      (uniqueShortName: string) => ({
        codes: hierarchyState.selectedRows
          .filter((selectedRow) => selectedRow.shortName === uniqueShortName)
          .map((matchedRow) => matchedRow.code),
        shortName: uniqueShortName,
      })
    );

    const productHierarchyGroupedByShortName: { [key: string]: string[] } = {};
    for (const selectedItem of selectedItems) {
      if (
        Object.keys(productHierarchyGroupedByShortName).includes(
          selectedItem.shortName
        )
      )
        productHierarchyGroupedByShortName[selectedItem.shortName] = [
          ...productHierarchyGroupedByShortName[selectedItem.shortName],
          ...selectedItem.codes,
        ];
      else
        productHierarchyGroupedByShortName[selectedItem.shortName] = [
          ...selectedItem.codes,
        ];
    }

    const productHierarchyFilterCriteria: FilterCriteria[] = Object.keys(
      productHierarchyGroupedByShortName
    ).map(
      (key: string): FilterCriteria => ({
        includeFilters: [
          { shortName: key, codes: productHierarchyGroupedByShortName[key] },
        ],
        excludeFilters: [],
      })
    );

    const loaOptions = levelState.parameterConfig
      .options as HierarchyAttributeParameterOption[];

    const focalAttributes = loaOptions
      .filter((option) => !option.isHierarchical || option.isLeaf)
      .map((option) => option.value);

    const attributesCountRequestDto = {
      division: division ?? "",
      hierarchyType: HierarchyType.Product,
      payload: {
        focalAttributes,
        filters: [...productHierarchyFilterCriteria],
      },
    };

    await triggerAttributesCountQuery(attributesCountRequestDto);

    const hierarchyLevels = loaOptions
      .filter((option) => option.isHierarchical)
      .map((option) => option.value)
      .filter((value) => !focalAttributes.includes(value));

    const productHierarchyAsAttributeFilters: AttributeFilter[] = Object.keys(
      productHierarchyGroupedByShortName
    ).map(
      (key: string): AttributeFilter => ({
        shortName: key,
        codes: productHierarchyGroupedByShortName[key],
      })
    );

    const hierarchyLevelsCountRequestDto = {
      division: division ?? "",
      hierarchyType: HierarchyType.Product,
      payload: {
        shortNamesToCount: hierarchyLevels,
        selectedItems: productHierarchyAsAttributeFilters,
      },
    };

    await triggerChildrenCountQuery(hierarchyLevelsCountRequestDto);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Ignore changes to hierarchyState except on selectedRows
  }, [
    hierarchyState?.selectedRows,
    division,
    triggerAttributesCountQuery,
    triggerChildrenCountQuery,
  ]);

  useEffect(() => {
    if (hasHierarchySelections) {
      fetchLOACounts().catch((error) => {
        ddLog("ERROR", {}, "error", error);
      });
    }
  }, [fetchLOACounts, hasHierarchySelections]);

  const transformedOptions: RadioSelectTableOption[] = useMemo(
    () =>
      parameterDto.options.map((option) => {
        // An option will either be part of the attributes or the hierarchy
        const attributeCount = attributesCount?.[option.value] ?? 0;
        const hierarchyCount = childrenCount?.[option.value] ?? 0;
        const totalCount = attributeCount + hierarchyCount;

        return {
          hierarchyItemType:
            isHierarchyAttributeParameterOption(option) && option.isHierarchical
              ? HierarchyItemType.Hierarchy
              : HierarchyItemType.Attribute,
          isDisabled: totalCount < MINIMUM_COUNT_REQUIREMENT,
          isExpanded: false,
          label: option.label,
          value: option.value,
        };
      }),
    [parameterDto.options, attributesCount, childrenCount]
  );

  const selectedValue: { parent?: string; value?: string } = useMemo(() => {
    if (!levelState || levelState.selections.length < 1) {
      return {};
    }

    return { value: levelState.selections[0].value };
  }, [levelState]);

  const isLoading = isAttributesCountLoading || isChildrenCountLoading;

  return (
    <div className={styles.levelParameterContainer}>
      <RadioSelectTable
        handleSelect={handleSelect}
        isLoading={isLoading}
        options={hasHierarchySelections ? transformedOptions : []}
        selected={selectedValue}
        title={parameterDto.name}
      />
    </div>
  );
};
