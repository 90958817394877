import { ddLog, getBaseQuery } from "@quantium-enterprise/common-ui";
import { createApi } from "@reduxjs/toolkit/query/react";
import {
  onReportletDataReceived,
  setReportletIsInitialised,
} from "../../basket-affinities/services/basket-affinities-slice";
import { type BasketAffinitiesReportParametersResponseDto } from "../models";
import {
  type ReportletWithAssociatedResponse,
  type PurchasedWithTableRequest,
  type ReportletsDto,
} from "../models/basket-affinities-chart-models";
import { onLocalParametersReceived } from "./basket-affinities-slice";

export const basketAffinitiesApiSlice = createApi({
  baseQuery: getBaseQuery(`/api/basket-affinities`),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  endpoints: (builder) => ({
    getReport: builder.query<
      BasketAffinitiesReportParametersResponseDto,
      { divisionName: string; reportId: string }
    >({
      async onQueryStarted(argument, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        try {
          const { data } = await queryFulfilled;
          dispatch(onLocalParametersReceived(data));
        } catch {
          ddLog("Error retrieving local parameters");
        }
      },
      query: ({ divisionName, reportId }) => ({
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        method: "GET",
        url: `/GetLocalParameters/${divisionName}/${reportId}`,
      }),
    }),
    getReportletData: builder.query<
      ReportletsDto,
      { divisionName: string; requestPayload: PurchasedWithTableRequest }
    >({
      async onQueryStarted(argument, { dispatch, queryFulfilled }) {
        dispatch(setReportletIsInitialised(false));
        try {
          const { data } = await queryFulfilled;
          dispatch(onReportletDataReceived(data));
          dispatch(setReportletIsInitialised(true));
        } catch {
          ddLog("Error retrieving reportlet data");
        }
      },
      query: ({ divisionName, requestPayload }) => ({
        body: requestPayload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        method: "POST",
        url: `/GetReportletData/${divisionName}`,
      }),
    }),
    getAssociatedReportletData: builder.query<
      ReportletWithAssociatedResponse,
      {
        divisionName: string;
        requestPayload: PurchasedWithTableRequest;
      }
    >({
      async onQueryStarted(argument, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch {
          ddLog("Error retrieving Associated reportlet data");
        }
      },
      query: ({ divisionName, requestPayload }) => ({
        body: requestPayload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        method: "POST",
        url: `/GetAssociatedReportletData/${divisionName}`,
      }),
    }),
  }),
  keepUnusedDataFor: 0,
  reducerPath: "basketAffinitiesApi",
  tagTypes: ["basketAffinities"],
});

export const {
  useGetReportQuery,
  useGetReportletDataQuery,
  useLazyGetReportQuery,
  useGetAssociatedReportletDataQuery,
  useLazyGetAssociatedReportletDataQuery,
} = basketAffinitiesApiSlice;
