import {
  type TransactionSource,
  useLazyHierarchyMetadataQuery,
  type HierarchyGroupRuleWithIdAndName,
  type HierarchyItemName,
  type HierarchyServiceResponseDto,
  type NodeExpansionRequestDto,
  type SearchRequestDto,
  ParameterId,
  ddLog,
  HierarchyGroupRuleOperator,
  HierarchyType,
  useLazyExpandQuery,
  useLazyGetRootNodesQuery,
  useLazySearchQuery,
  FilterMode,
  SCAN_DATA_EXPORT_FEATURE_NAME,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { EmptySearch } from "components-ui/src/search/EmptySearch";
import { type ContextMenuType } from "components-ui/src/tables/common/table-cell/ContextMenu";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  onHierarchySearchResultsReceived,
  onProductSearchResultsReceived,
  onHierarchyMetadataReceived,
  onHierarchyRootNodeReceived,
  selectAdvancedSearchEnableState,
  selectSearchString,
  selectTriggerSearchState,
  selectFilterRules,
  selectHierarchyItems,
  selectFocalAttributes,
  selectProductParameters,
  selectedTransactionSources,
} from "../../states/report-wizard-slice";
import { type RootState } from "../../store";
import { isFilterComplete } from "../utils/isFilterComplete";
import {
  PRODUCT_HIERARCHY_SEARCH_QUERY_PAGE_SIZE,
  PRODUCT_HIERARCHY_EXPAND_QUERY_PAGE_SIZE,
} from "./ProductHierarchyParameter";
import styles from "./ProductHierarchyParameter.module.css";
import { ProductHierarchyParameterTable } from "./ProductHierarchyParameterTable";

const hierarchyType = HierarchyType.Product;

const levelsRequireTreeView = ["SM", "DT", "CT", "SC", "SE"];

export type ProductHierarchyParameterQueryProps = {
  contextMenuType: ContextMenuType;
  parameterType?: ParameterId;
};

export const ProductHierarchyParameterQuery = ({
  contextMenuType,
  parameterType = ParameterId.ProductHierarchy,
}: ProductHierarchyParameterQueryProps) => {
  const dispatch = useDispatch();
  const { name: activeDivisionName } = useDivision();

  const items = useSelector((state: RootState) =>
    selectHierarchyItems(parameterType, state)
  );

  const searchString = useSelector((state: RootState) =>
    selectSearchString(parameterType, state)
  );

  const isAdvancedSearchEnabled = useSelector((state: RootState) =>
    selectAdvancedSearchEnableState(parameterType, state)
  );

  const triggerSearch = useSelector((state: RootState) =>
    selectTriggerSearchState(parameterType, state)
  );

  const filterRules = useSelector((state: RootState) =>
    selectFilterRules(parameterType, state)
  );

  const focalAttributes = useSelector((state: RootState) =>
    selectFocalAttributes(parameterType, state)
  );

  const rawDataProductParameters = useSelector((state: RootState) =>
    selectProductParameters(state)
  );

  const selectedTransactionSource = useSelector((state: RootState) =>
    selectedTransactionSources(state)
  );

  const isRawDataReport = useMemo(
    () =>
      rawDataProductParameters.find(
        // Todo: to be changed in future based on report type
        (parameter) => parameter === ParameterId.Dataset
      ),
    [rawDataProductParameters]
  );

  const [
    triggerLazyRootNodesQuery,
    { isLoading: isRootNodesLoading, isFetching: isRootNodesFetching },
  ] = useLazyGetRootNodesQuery();

  const [
    triggerExpandQuery,
    { isLoading: isExpandQueryLoading, isFetching: isExpandQueryFetching },
  ] = useLazyExpandQuery();
  const [
    triggerSearchQuery,
    { isLoading: isSearchQueryLoading, isFetching: isSearchQueryFetching },
  ] = useLazySearchQuery();

  const [triggerHierarchyMetadataQuery] = useLazyHierarchyMetadataQuery();

  // only search barcodes when searching for 4+ digit numbers
  const isSearchBarcodes = useMemo(() => {
    // Match a comma-separated list of numbers, at least one of which has 4+ digits.
    const onlyDigits = /^[\d\s,]+$/gu;
    const atLeastFourDigits = /\d{4}/gu;
    return (
      onlyDigits.test(searchString) && atLeastFourDigits.test(searchString)
    );
  }, [searchString]);

  const includeFilters = useMemo(
    () =>
      filterRules
        .filter(
          (rule) =>
            isFilterComplete(rule) &&
            rule.operator === HierarchyGroupRuleOperator.Is
        )
        .map((rule) => ({
          codes: rule.values.map((x) => x.code),
          shortName: rule.shortName,
        })),
    [filterRules]
  );

  const excludeFilters = useMemo(
    () =>
      filterRules
        .filter(
          (rule) =>
            isFilterComplete(rule) &&
            rule.operator === HierarchyGroupRuleOperator.IsNot
        )
        .map((rule) => ({
          codes: rule.values.map((x) => x.code),
          shortName: rule.shortName,
        })),
    [filterRules]
  );

  const searchRequest = useMemo(() => {
    const localSearchRequest: SearchRequestDto = {
      page: 0,
      pageSize: PRODUCT_HIERARCHY_SEARCH_QUERY_PAGE_SIZE,
    };

    if (searchString) {
      localSearchRequest.query = searchString;
      localSearchRequest.includeBarcodes = isSearchBarcodes;
      localSearchRequest.includeCodes = true;
    }

    if (isAdvancedSearchEnabled) {
      if (includeFilters.length > 0) {
        localSearchRequest.filters = includeFilters;
      }

      if (excludeFilters.length > 0) {
        localSearchRequest.excludeFilters = excludeFilters;
      }

      if (focalAttributes.length > 0) {
        localSearchRequest.focalAttributes = focalAttributes;
      }
    }

    return localSearchRequest;
  }, [
    searchString,
    isAdvancedSearchEnabled,
    isSearchBarcodes,
    includeFilters,
    excludeFilters,
    focalAttributes,
  ]);

  const fetchOptionsForIsNotOperator = useCallback(
    async (ruleToFetch: HierarchyGroupRuleWithIdAndName) => {
      try {
        const { data } = await triggerSearchQuery({
          division: activeDivisionName,
          hierarchyType,
          payload: {
            focalAttributes: [ruleToFetch.shortName],
            page: 0,
            pageSize: 30_000,
            excludeFilters,
            filters: includeFilters,
            query: searchString || undefined,
          },
        });
        return data;
      } catch (error) {
        ddLog("ERROR", {}, "error", error as Error);
        return null;
      }
    },
    [
      activeDivisionName,
      excludeFilters,
      includeFilters,
      searchString,
      triggerSearchQuery,
    ]
  );

  const getExpandPromises = useCallback(
    (
      values: HierarchyItemName[],
      shortName: string
    ): Array<Promise<HierarchyServiceResponseDto>> =>
      values.map(
        async (value) =>
          await triggerExpandQuery({
            division: activeDivisionName,
            hierarchyType,
            payload: {
              page: 0,
              pageSize: PRODUCT_HIERARCHY_EXPAND_QUERY_PAGE_SIZE,
              parent: {
                code: value.code,
                shortName,
              },
            } as NodeExpansionRequestDto,
          }).unwrap()
      ),
    [activeDivisionName, triggerExpandQuery]
  );

  const handleApplyFilter = useCallback(async () => {
    // retrieve tree structure data when only hierarchy level filters are applied
    if (
      isAdvancedSearchEnabled &&
      searchString === "" &&
      filterRules.every((rule) =>
        levelsRequireTreeView.includes(rule.shortName)
      )
    ) {
      const ruleToFetch = filterRules[filterRules.length - 1];

      let values;
      if (ruleToFetch.operator === HierarchyGroupRuleOperator.IsNot) {
        const data = await fetchOptionsForIsNotOperator(ruleToFetch);

        if (!data || data.count === 0) return;

        values = data.results
          .map(
            ({ code, name }) =>
              ({
                code,
                name,
              } as HierarchyItemName)
          )
          .filter(({ code }) => code);
      } else {
        values = ruleToFetch.values;
      }

      const promises = getExpandPromises(values, ruleToFetch.shortName);

      await Promise.all(promises)
        .then((data) => {
          dispatch(
            onHierarchySearchResultsReceived({
              childNodes: data,
              parameterType,
            })
          );
        })
        .catch((error) => {
          ddLog("ERROR", {}, "error", error);
        });
    } else {
      // retrieve flat product list data when non-hierarchy level filters are applied
      await triggerSearchQuery({
        division: activeDivisionName,
        hierarchyType,
        payload: searchRequest,
      })
        .unwrap()
        .then((data) => {
          dispatch(
            onProductSearchResultsReceived({
              childNodes: data,
              parameterType,
            })
          );
        })
        .catch((error) => {
          ddLog("ERROR", {}, "error", error);
        });
    }
  }, [
    activeDivisionName,
    dispatch,
    fetchOptionsForIsNotOperator,
    filterRules,
    getExpandPromises,
    isAdvancedSearchEnabled,
    parameterType,
    searchRequest,
    searchString,
    triggerSearchQuery,
  ]);

  /**
   * Trigger query on mount to fetch
   *  - hierarchy metadata
   *  - root node
   */
  const fetchData = useCallback(async () => {
    await triggerHierarchyMetadataQuery({
      division: activeDivisionName,
      hierarchyType,
      getAllAttributes: false,
    })
      .unwrap()
      .then((data) => {
        dispatch(
          onHierarchyMetadataReceived({
            hierarchyMetadata: data,
            parameterType,
          })
        );
      });

    await triggerLazyRootNodesQuery({
      division: activeDivisionName,
      hierarchyType,
      payload: {
        page: 0,
        transactionSourceFilter: isRawDataReport
          ? {
              mode: FilterMode.PreserveAncestors,
              source: selectedTransactionSource as TransactionSource,
            }
          : undefined,
      },
      featureModules: isRawDataReport ? [SCAN_DATA_EXPORT_FEATURE_NAME] : [],
    })
      .unwrap()
      .then((data) => {
        dispatch(
          onHierarchyRootNodeReceived({
            parameterType,
            response: data,
          })
        );
      });
  }, [
    triggerHierarchyMetadataQuery,
    activeDivisionName,
    triggerLazyRootNodesQuery,
    dispatch,
    parameterType,
    isRawDataReport,
    selectedTransactionSource,
  ]);

  useEffect(() => {
    if (activeDivisionName) {
      fetchData().catch((error) => ddLog("ERROR", {}, "error", error));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDivisionName]);

  useEffect(() => {
    if (triggerSearch) {
      handleApplyFilter().catch((error) => {
        ddLog("ERROR", {}, "error", error);
      });
    }
  }, [dispatch, handleApplyFilter, triggerSearch]);

  const isSuccess = useMemo(() => {
    if (
      isSearchQueryFetching ||
      isExpandQueryFetching ||
      isSearchQueryLoading ||
      isExpandQueryLoading
    ) {
      return false;
    }

    if (isRootNodesLoading || isRootNodesFetching) {
      return false;
    }

    return true;
  }, [
    isExpandQueryFetching,
    isExpandQueryLoading,
    isRootNodesFetching,
    isRootNodesLoading,
    isSearchQueryFetching,
    isSearchQueryLoading,
  ]);

  return (searchString !== "" || isAdvancedSearchEnabled) &&
    items.length === 0 ? (
    <div className={styles.noresults}>
      <EmptySearch />
    </div>
  ) : (
    <ProductHierarchyParameterTable
      contextMenuType={contextMenuType}
      isSuccess={isSuccess}
      parameterType={parameterType}
    />
  );
};
