import {
  type ParameterGroupSelectionDto,
  type TimePeriodSelectionDto,
  type HierarchySelectionDto,
} from "@quantium-enterprise/common-ui";
import {
  ParameterId,
  isListSelectionDto,
} from "@quantium-enterprise/common-ui";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { TimePeriodOptions } from "../../../report-parameters/src/parameters/time/components/TimePeriodConstants";

export const buildRawDataFilename = (
  parameterGroups?: ParameterGroupSelectionDto[]
) => {
  if (!parameterGroups?.length) return "Scan_Data_Export_report";

  // Extract time data type using ParameterId
  const timeDataTypeParameter = parameterGroups
    .flatMap((group) => group.parameterSelections)
    .find((selection) => selection.id === ParameterId.TimePeriodDataType);

  const timeDataTypeValues =
    timeDataTypeParameter?.selections
      .filter(isListSelectionDto)
      .map((selection) => selection.label)
      .filter(Boolean) ?? [];

  // Apply mapping for shorter names in filename
  const timeDataTypeMapping: Record<string, string> = {
    Aggregate: "Agg",
    "Trend (Weekly breakdown)": "Trended",
  };

  const timeDataType =
    timeDataTypeValues.length > 0
      ? timeDataTypeValues
          .map((label) => {
            const mappedValue =
              timeDataTypeMapping[label as keyof typeof timeDataTypeMapping];
            return mappedValue || label;
          })
          .join("_")
      : "default";

  // Extract focus period using ParameterId
  const focusPeriodParameter = parameterGroups
    .flatMap((group) => group.parameterSelections)
    .find((selection) => selection.id === ParameterId.FocusPeriod);

  const focusPeriodValues =
    focusPeriodParameter?.selections
      .map((selection) => {
        const timePeriodSelection = selection as TimePeriodSelectionDto;
        const periodValue = timePeriodSelection.periodValue;
        const label = timePeriodSelection.label;

        if (periodValue === TimePeriodOptions.CUSTOM_PERIOD) {
          return label;
        }

        return label.trim();
      })
      .filter(Boolean) ?? [];

  const focusPeriodString =
    focusPeriodValues.length > 0
      ? focusPeriodValues
          .map((value) => {
            const isCustomDate = focusPeriodParameter?.selections.some(
              (selection) => {
                const timePeriodSelection = selection as TimePeriodSelectionDto;
                return (
                  timePeriodSelection.periodValue ===
                    TimePeriodOptions.CUSTOM_PERIOD &&
                  timePeriodSelection.label === value
                );
              }
            );

            if (isCustomDate) {
              return "Custom_Date";
            }

            return value.replace(/\s+/gu, "_");
          })
          .join("_")
      : "default";

  // Extract location using ParameterId
  const locationParameter = parameterGroups
    .flatMap((group) => group.parameterSelections)
    .find((selection) => selection.id === ParameterId.LocationHierarchy);

  const locationSelections =
    locationParameter?.selections as HierarchySelectionDto[];

  let locationPart = "";

  if (locationSelections.length) {
    const locationParts = locationSelections.map((selection) => {
      const shortName = selection.hierarchyItem.shortName;
      const name = selection.hierarchyItem.name.replace(/\s+/gu, "_");
      return `${shortName}_${name}`;
    });
    locationPart = locationParts.join("_");
  }

  return cleanFilename(
    `Scan_Data_Export_${timeDataType}_${focusPeriodString}_${locationPart}`
  );
};

export const buildRawDataFilenameFromReportParameters = (
  reportParameters:
    | { parameterGroupSelections?: ParameterGroupSelectionDto[] }
    | undefined
) => {
  if (!reportParameters) return "Scan_Data_Export_report";
  return buildRawDataFilename(reportParameters.parameterGroupSelections);
};
