import {
  type CustomLoaDto,
  type HierarchyItem,
  type ParameterOptionDto,
  type HierarchyAttributeParameterOption,
  HierarchyShortName,
} from "@quantium-enterprise/common-ui";

export const LOA_MIN_SELECTION_DEFAULT = 2;

export const isCustomLoa = (shortName: string, customLoaDto: CustomLoaDto) =>
  shortName === customLoaDto.shortName;

export const getDisabledMessage = (
  loaShortname: string,
  loaLabel: string,
  minSelections: number,
  customLoaDto: CustomLoaDto,
  associatedHierarchyLevel: string
) => {
  if (isCustomLoa(loaShortname, customLoaDto)) {
    return `To analyse at ${loaLabel} level, you must only have selected 1 ${associatedHierarchyLevel} in your product selection`;
  }

  let pluralLabel = loaLabel;
  if (minSelections !== 1) {
    if (loaLabel.endsWith("y")) {
      pluralLabel = `${loaLabel.slice(0, loaLabel.length - 1)}ies`;
    } else if (!loaLabel.endsWith("s")) {
      pluralLabel = `${loaLabel}s`;
    }
  }

  if (loaShortname === HierarchyShortName.ProductGroup) {
    return `To analyse at ${loaLabel} level, you must select a minimum of ${minSelections} ${pluralLabel} and not select any products outside groups`;
  }

  return `To analyse at ${loaLabel} level, you must select a minimum of ${minSelections} ${pluralLabel} in your product selection`;
};

export const isAtleastXSelected = (
  thresholdValue: number,
  selectedRows: HierarchyItem[],
  option: HierarchyAttributeParameterOption,
  countForShortNames?: Record<string, number> | undefined
): boolean => {
  if (countForShortNames?.[option.value]) {
    const isAtLeastX = countForShortNames[option.value] >= thresholdValue;
    if (option.isHierarchical && !option.isLeaf) {
      // If product groups selected, then Hierarchy LOAs (like SC, SE) are not supported
      const groups = countForShortNames[HierarchyShortName.ProductGroup];
      return isAtLeastX && groups === 0;
    } else if (option.value === HierarchyShortName.ProductGroup) {
      // If product hierarchy selected, then LOA=Groups not supported
      return isAtLeastX && selectedRows.length === 0;
    }

    return isAtLeastX;
  }

  return false;
};

export const isValidCustomLoaSelection = (
  selectedRows: HierarchyItem[],
  customLoaConfig: CustomLoaDto
): boolean =>
  selectedRows.length === 1 &&
  selectedRows[0].shortName === customLoaConfig.customLoaSelectableHierarchy;

export const getDisabledValuesWithReason = (
  isProductSelectionValid: boolean,
  selectedRows: HierarchyItem[],
  parameterOptions: ParameterOptionDto[],
  customLoaDto: CustomLoaDto,
  associatedHierarchyLevel: string,
  countForShortNames?: Record<string, number>
): Record<string, string> => {
  const options = parameterOptions as HierarchyAttributeParameterOption[];

  const disabledOptions = isProductSelectionValid
    ? options.filter((option) =>
        isCustomLoa(option.value, customLoaDto)
          ? !isValidCustomLoaSelection(selectedRows, customLoaDto)
          : !isAtleastXSelected(
              option.minSelections ?? LOA_MIN_SELECTION_DEFAULT,
              selectedRows,
              option,
              countForShortNames
            )
      )
    : options;

  const disabledValuesWithReason: Record<string, string> = {};
  for (const disabledOption of disabledOptions) {
    disabledValuesWithReason[disabledOption.value] = getDisabledMessage(
      disabledOption.value,
      disabledOption.label,
      disabledOption.minSelections ?? LOA_MIN_SELECTION_DEFAULT,
      customLoaDto,
      associatedHierarchyLevel
    );
  }

  return disabledValuesWithReason;
};
