import {
  FormBlock,
  FormBlockDesignVariant,
  FormBlockType,
  FormInputHeight,
  Icon,
  IconGlyph,
  IconSize,
  Input,
  Label,
  QSearchInput,
} from "@qbit/react";
import { useDebounce } from "@quantium-enterprise/hooks-ui";
import classNames from "classnames";
import { type ClipboardEvent } from "react";
import { useCallback, useEffect, useId, useMemo, useState } from "react";
import styles from "./SearchBox.module.scss";

export type SearchBoxProps = {
  adjustableWidth?: boolean;
  className?: string;
  debounceTimeMs?: number;
  disabled?: boolean;
  enableDebounce?: boolean;
  maximumCount?: number;
  onChange: (searchText: string) => void;
  placeholder?: string;
  resultCount?: number;
  searchQuery?: string;
  testId?: string;
};

export const SearchBox = ({
  adjustableWidth = false,
  className,
  debounceTimeMs = 500,
  disabled,
  enableDebounce = false,
  maximumCount,
  onChange,
  placeholder = "Search",
  resultCount,
  searchQuery,
  testId,
}: SearchBoxProps) => {
  const searchId = useId();
  const [search, setSearch] = useState<string>(searchQuery ?? "");
  const debouncedSearch = useDebounce(search, debounceTimeMs) as string;

  const isSearching = useMemo(() => Boolean(search), [search]);

  const onSearch = useCallback((event: Event) => {
    if (event.target) {
      setSearch((event.target as HTMLInputElement).value);
    }
  }, []);

  useEffect(() => {
    setSearch(searchQuery ?? "");
  }, [searchQuery]);

  const onCancelSearch = () => {
    setSearch("");
  };

  // handle newline-separated pastes from Excel spreadsheet
  const onPaste = useCallback((event: ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const target = event.target as HTMLInputElement;
    const pasteValue = target.value;
    const selectionStart = target.selectionStart ?? 0;
    const selectionEnd = target.selectionEnd ?? 0;
    const pre = pasteValue.slice(0, selectionStart);
    const post = pasteValue.slice(selectionEnd);
    const clipboardText = event.clipboardData.getData("text");
    const filteredText = clipboardText
      .replace(/\r\n/gu, ", ")
      .replace(/\n/gu, ", ");
    setSearch(pre + filteredText + post);
  }, []);

  // Wrap onPaste prop in an object so we can pass it as a spread
  // ie. smuggle a prop that QSearchBox doesn't support past the linter
  const customProps = {
    onPaste,
  };

  // fire the onChange event back to the parent
  useEffect(() => {
    if (enableDebounce) {
      onChange(debouncedSearch);
    }
  }, [debouncedSearch, enableDebounce, onChange]);

  // separate to above as if both search and debouncedSearch in the same dependency array debounce will send twice
  useEffect(() => {
    if (!enableDebounce) {
      onChange(search);
    }
  }, [search, enableDebounce, onChange]);

  const matchCountText = useMemo(() => {
    if (resultCount === undefined) {
      return undefined;
    }

    if (maximumCount && resultCount === maximumCount) {
      return `${maximumCount}+ matches found. Showing first ${maximumCount}, please refine your search further.`;
    }

    return `${resultCount} match${resultCount === 1 ? "" : "es"} found`;
  }, [resultCount, maximumCount]);

  return (
    <div className={styles.searchbox}>
      <FormBlock
        blockType={FormBlockType.Search}
        className={classNames(
          styles.searchformblock,
          adjustableWidth && styles.inline,
          className
        )}
        designvariant={FormBlockDesignVariant.Default}
      >
        <Label
          className={styles.searchlabel}
          htmlFor={searchId}
          text="Search"
        />
        <Input>
          <QSearchInput
            data-testid={testId}
            disabled={disabled}
            height={FormInputHeight.XSmall}
            id={searchId}
            onChange={onSearch}
            placeholder={placeholder}
            value={search}
            {...customProps}
          />
        </Input>
        <>
          {isSearching && (
            <div
              className={styles.searchcancel}
              onClick={onCancelSearch}
              onKeyUp={onCancelSearch}
              role="button"
              tabIndex={0}
            >
              <Icon
                glyph={IconGlyph.DeleteAndCloseClose}
                size={IconSize.Small}
                text="Clear search"
              />
            </div>
          )}
        </>
      </FormBlock>
      {matchCountText !== undefined && (
        <span className={styles.searchcount}>{matchCountText}</span>
      )}
    </div>
  );
};
