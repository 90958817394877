import {
  Spinner,
  QbitToastMessage,
  QbitEmitToast,
  MessageVariant,
  Group,
  GroupRowspace,
  Item,
  ItemHalign,
  ItemWidth,
  Text,
  ItemValign,
} from "@qbit/react";
import {
  FeatureFlag,
  GenericTrackingProperties,
  ParametersTrackingProperty,
  TrackingComponent,
  TrackingEvent,
  TransactionSource,
  useEventTrackingServiceContext,
} from "@quantium-enterprise/common-ui";
import {
  isSelectableAttributeItem,
  isSelectableAttributeLevelItem,
  isSelectableHierarchyItem,
  isSelectableProductGroupItem,
  ReportLoadingWrapper,
  type SelectableItem,
} from "@quantium-enterprise/fast-reporting-ui";
import { type HierarchySearchLevel } from "@quantium-enterprise/fast-reporting-ui/src/focal-item-search/HierarchySearchLevel";
import { useFlags } from "@quantium-enterprise/hooks-ui";
import { useCallback, useEffect, useMemo, useState } from "react";
import ErrorBoundary from "../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import {
  useGetWatchlistMetricsQuery,
  useGetWatchlistQuery,
  useAddWatchlistItemsMutation,
  useDeleteWatchlistItemsMutation,
  useUpdateFocusPeriodMutation,
  useUpdateComparisonPeriodMutation,
  useUpdateMetricsMutation,
  useReorderWatchlistItemsMutation,
  useUpdateTransactionSourceMutation,
  useUpdateCompStoreMutation,
} from "../services/DashboardService";
import { trackWatchlistItemEvent } from "../services/WatchlistItemEventTracking";
import { type DashboardDto } from "../services/dtos/DashboardDto";
import {
  type DashboardWidgetDto,
  type WatchlistWidgetDto,
} from "../services/dtos/DashboardWidgetDto";
import {
  type WatchlistCompStoreDto,
  type WatchlistMetricDto,
  type WatchlistComparisonPeriodDto,
  type WatchlistFocusPeriodDto,
  type WatchlistDto,
  type WatchlistTransactionSourceDto,
  type WatchlistParametersDto,
} from "../services/dtos/WatchlistDto";
import {
  type AddWatchlistProductGroupItemDto,
  type AddWatchlistItemDto,
  WatchlistItemType,
  type WatchlistItemDto,
} from "../services/dtos/WatchlistItemDto";
import {
  type MetricDouble,
  type WatchlistMetricsResultDto,
} from "../services/dtos/WatchlistMetricsResultDto";
import AddItemsButton from "./AddItemsButton";
import EmptyWatchlist from "./EmptyWatchlist";
import FocusPeriodDescription from "./FocusPeriodDescription";
import styles from "./PopulatedWatchlist.module.css";
import {
  NoItemsSelectedButtons,
  ItemsSelectedButtons,
  type SelectedParameters,
} from "./WatchlistButtons";
import HierarchySearchAddItemsLayout from "./hierarchy-search-add-items/HierarchySearchAddItemsLayout";
import ParameterSelectorDropdown from "./parameter-selector-dropdown/ParameterSelectorDropdown";
import WatchlistTable, {
  type WatchlistItemWithMetrics,
  type Metric,
  type MetricValue,
  type MetricDefinition,
} from "./watchlist-table/WatchlistTable";
import WatchlistTableNew from "./watchlist-table/WatchlistTableNew";

const displaySelector = (
  item:
    | WatchlistComparisonPeriodDto
    | WatchlistCompStoreDto
    | WatchlistFocusPeriodDto
    | WatchlistTransactionSourceDto
) => <Text>{item.label}</Text>;

const metricDisplaySelector = (item: WatchlistMetricDto) => (
  <Text>{item.metadata.displayName}</Text>
);
const convertToMetricValue = (metric: MetricDouble): MetricValue => ({
  hasValue: metric.hasValue,
  value: metric.value,
});

export type PopulatedWatchlistProps = {
  availableSearchLevels: HierarchySearchLevel[];
  dashboard: DashboardDto;
  division: string;
  onSearchLevelChanged: (level: HierarchySearchLevel) => void;
  onWatchlistUpsertFailed: () => void;
  onWatchlistUpsertSuccess: () => void;
  searchLevel?: HierarchySearchLevel;
  watchlistId: string;
};

// eslint-disable-next-line complexity
export const PopulatedWatchlist = ({
  dashboard,
  watchlistId,
  division,
  onSearchLevelChanged,
  onWatchlistUpsertSuccess,
  onWatchlistUpsertFailed,
  searchLevel,
  availableSearchLevels,
}: PopulatedWatchlistProps) => {
  const eventTrackingService = useEventTrackingServiceContext();

  const featureFlags = useFlags();
  const useNewWatchlistTable =
    featureFlags[FeatureFlag.NewWatchlistTable] ?? false;
  const newWatchlistButtonsEnabled =
    featureFlags[FeatureFlag.NewWatchlistButtons] ?? false;
  const newWatchlistCsv = featureFlags[FeatureFlag.NewWatchlistCsv] ?? false;

  const [selectedWatchlistItems, setSelectedWatchlistItems] = useState<
    Set<string>
  >(new Set());

  const handleSelectionChange = useCallback((selectedItems: Set<string>) => {
    setSelectedWatchlistItems(selectedItems);
  }, []);

  const [watchlist, setWatchlist] = useState<WatchlistDto>();
  const [watchlistMetrics, setWatchlistMetrics] =
    useState<WatchlistMetricsResultDto>();
  const [watchlistMetricsRefetching, setWatchlistMetricsRefetching] =
    useState(false);
  const [watchlistTableItems, setWatchlistTableItems] =
    useState<WatchlistItemWithMetrics[]>();
  const [metrics, setMetrics] = useState<MetricDefinition[]>();
  const [selectedParameters, setSelectedParameters] =
    useState<SelectedParameters>();

  const [showHierarchySearch, setShowHierarchySearch] = useState(false);
  const [deleteWatchlistItems] = useDeleteWatchlistItemsMutation();

  const watchlistSummary: DashboardWidgetDto | undefined =
    dashboard.widgets.find((widget) => widget.id === watchlistId);

  if (watchlistSummary === undefined) {
    throw new Error("Couldn't find a widget with the provided id");
  }

  if (watchlistSummary.type !== "watchlist") {
    throw new Error("The provided widget is not a watchlist");
  }

  // If transactionSource option is available, filter out customer metrics on total
  const filterCustomerMetrics =
    selectedParameters?.transactionSource?.transactionSource ===
    TransactionSource.Total;
  const nonCustomerMetrics = useMemo(
    () =>
      watchlist?.parameters.watchlistMetrics.filter(
        (x) => !x.metadata.isCustomerMetric
      ) ?? [],
    [watchlist?.parameters.watchlistMetrics]
  );

  const {
    data: dataGetWatchlist,
    isSuccess: isSuccessGetWatchlist,
    isLoading: isLoadingGetWatchlist,
    isError: isErrorGetWatchlist,
    refetch: refetchGetWatchlist,
  } = useGetWatchlistQuery(
    {
      dashboardId: dashboard.id,
      division,
      watchlistId: watchlistSummary.id,
    },
    {
      skip: !division,
    }
  );

  useEffect(() => {
    if (watchlist) {
      const comparisonPeriod =
        watchlist.parameters.comparisonPeriods.find(
          (x) => x.comparisonPeriod === watchlist.comparisonPeriod
        ) ?? watchlist.parameters.comparisonPeriods[0];

      const focusPeriod =
        watchlist.parameters.focusPeriods.find(
          (x) => x.focusPeriod === watchlist.focusPeriod
        ) ?? watchlist.parameters.focusPeriods[0];

      const transactionSource =
        watchlist.parameters.transactionSources.find(
          (x) => x.transactionSource === watchlist.transactionSource
        ) ?? watchlist.parameters.transactionSources[0];

      const compStore =
        watchlist.parameters.compStores.find(
          (x) => x.compStore === watchlist.compStore
        ) ?? watchlist.parameters.compStores[0];

      const selectedMetrics = watchlist.metrics
        .map((metric) =>
          watchlist.parameters.watchlistMetrics.find(
            (watchlistMetric) => watchlistMetric.key === metric
          )
        )
        .filter((metric) => metric !== undefined) as WatchlistMetricDto[];

      setSelectedParameters({
        comparisonPeriod,
        focusPeriod,
        transactionSource,
        compStore,
        metrics: selectedMetrics,
      });
    }
  }, [watchlist]);

  useEffect(() => {
    if (isSuccessGetWatchlist) {
      setWatchlist(dataGetWatchlist);
    }
  }, [isSuccessGetWatchlist, dataGetWatchlist]);

  useEffect(() => {
    let metricParameters = selectedParameters?.metrics.map(
      (metric) =>
        ({
          displayName: metric.metadata.displayName,
          key: metric.key,
          formatType: metric.metadata.unitOfMeasure.formatType,
        } as MetricDefinition)
    ) as MetricDefinition[];

    if (filterCustomerMetrics) {
      metricParameters = metricParameters.filter((x) =>
        nonCustomerMetrics.find((y) => y.key === x.key)
      );
    }

    setMetrics(metricParameters);
  }, [filterCustomerMetrics, selectedParameters, nonCustomerMetrics]);

  const {
    currentData: dataGetWatchlistMetrics,
    isSuccess: isSuccessGetWatchlistMetrics,
    isError: isErrorGetWatchlistMetrics,
    isLoading: isLoadingGetWatchlistMetrics,
    refetch: refetchGetWatchlistMetrics,
  } = useGetWatchlistMetricsQuery(
    {
      dashboardId: dashboard.id,
      division,
      watchlistId: watchlistSummary.id,
      focusPeriod: selectedParameters?.focusPeriod.focusPeriod ?? 0,
      comparisonPeriod:
        selectedParameters?.comparisonPeriod.comparisonPeriod ?? "",
      transactionSource:
        selectedParameters?.transactionSource?.transactionSource,
      compStore: selectedParameters?.compStore?.compStore,
    },
    {
      skip:
        !division ||
        (watchlistSummary as WatchlistWidgetDto).itemCount === 0 ||
        !selectedParameters?.focusPeriod ||
        !selectedParameters.comparisonPeriod,
    }
  );

  useEffect(() => {
    if (isSuccessGetWatchlistMetrics) {
      setWatchlistMetrics(dataGetWatchlistMetrics);
    }

    setWatchlistMetricsRefetching(false);
  }, [isSuccessGetWatchlistMetrics, dataGetWatchlistMetrics]);

  useEffect(() => {
    const getMetricsForItem = (
      itemId: string
    ): { [key: string]: Metric | undefined } => {
      if (!metrics || !watchlistMetrics) {
        return {};
      }

      const metricsDictionary = watchlistMetrics.items[itemId];
      if (!metricsDictionary) {
        return {};
      }

      const result: { [key: string]: Metric | undefined } = {};
      for (const metric of metrics) {
        const metricValues = metricsDictionary[metric.key];
        if (metricValues) {
          result[metric.key] = {
            comparisonPeriod: convertToMetricValue(
              metricValues.comparisonPeriod
            ),
            focalPeriod: convertToMetricValue(metricValues.focusPeriod),
            growth: convertToMetricValue(metricValues.growth),
          };
        }
      }

      return result;
    };

    if (watchlist) {
      setWatchlistTableItems(
        watchlist.items.map((item) => ({
          ...item,
          metrics: getMetricsForItem(item.id),
        }))
      );
    }
  }, [watchlist, watchlistMetrics, metrics]);

  const handleShowHierarchySearch = useCallback(() => {
    eventTrackingService.trackEvent(
      TrackingComponent.WatchlistAddItemsModal,
      TrackingEvent.Opened
    );
    setShowHierarchySearch(true);
  }, [eventTrackingService]);

  const handleCloseHierarchySearch = useCallback(() => {
    setShowHierarchySearch(false);
  }, []);

  const [updateMetrics] = useUpdateMetricsMutation();

  const handleMetricsChanged = (items: WatchlistMetricDto[]) => {
    if (!watchlist || !selectedParameters) {
      return;
    }

    if (items.length > selectedParameters.metrics.length) {
      const selectedMetrics = items.filter(
        (item) => !selectedParameters.metrics.includes(item)
      );
      for (const selectedMetric of selectedMetrics) {
        eventTrackingService.trackEvent(
          TrackingComponent.Watchlist,
          TrackingEvent.Parameters,
          GenericTrackingProperties.single(
            ParametersTrackingProperty.MetricSelected,
            selectedMetric.metadata.displayName
          )
        );
      }
    } else {
      const deselectedMetrics = selectedParameters.metrics.filter(
        (metric) => !items.includes(metric)
      );
      for (const deselectedMetric of deselectedMetrics) {
        eventTrackingService.trackEvent(
          TrackingComponent.Watchlist,
          TrackingEvent.Parameters,
          GenericTrackingProperties.single(
            ParametersTrackingProperty.MetricDeselected,
            deselectedMetric.metadata.displayName
          )
        );
      }
    }

    updateMetrics({
      dashboardId: dashboard.id,
      division,
      metrics: items.map((metric) => metric.key),
      watchlistId,
    })
      .unwrap()
      .catch(() => {
        onWatchlistUpsertFailed();
      });
  };

  const [updateTransactionSource] = useUpdateTransactionSourceMutation();

  const handleTransactionSourceChanged = (
    items: WatchlistTransactionSourceDto[]
  ) => {
    if (!watchlist || !selectedParameters) {
      return;
    }

    const transactionSource = items[0];
    const transactionSourceString = items[0].transactionSource;

    eventTrackingService.trackEvent(
      TrackingComponent.Watchlist,
      TrackingEvent.Parameters,
      GenericTrackingProperties.single(
        ParametersTrackingProperty.TransactionSourceSelected,
        transactionSource.label
      )
    );

    setSelectedParameters({
      ...selectedParameters,
      transactionSource,
    });

    setWatchlistMetricsRefetching(true);

    updateTransactionSource({
      transactionSource: transactionSourceString,
      dashboardId: dashboard.id,
      division,
      watchlistId,
    })
      .unwrap()
      .then(() => {
        setWatchlist({
          ...watchlist,
          transactionSource: transactionSourceString,
        });
        refetchGetWatchlistMetrics();
      })
      .catch(() => {
        setWatchlistMetricsRefetching(false);
        onWatchlistUpsertFailed();
      });
  };

  const [updateCompStore] = useUpdateCompStoreMutation();

  const handleCompStoreChanged = (items: WatchlistCompStoreDto[]) => {
    if (!watchlist || !selectedParameters) {
      return;
    }

    const compStore = items[0];
    const compStoreString = items[0].compStore;

    eventTrackingService.trackEvent(
      TrackingComponent.Watchlist,
      TrackingEvent.Parameters,
      GenericTrackingProperties.single(
        ParametersTrackingProperty.CompStoreSelected,
        compStore.label
      )
    );

    setSelectedParameters({
      ...selectedParameters,
      compStore,
    });

    setWatchlistMetricsRefetching(true);

    updateCompStore({
      compStore: compStoreString,
      dashboardId: dashboard.id,
      division,
      watchlistId,
    })
      .unwrap()
      .then(() => {
        setWatchlist({
          ...watchlist,
          compStore: compStoreString,
        });
        refetchGetWatchlistMetrics();
      })
      .catch(() => {
        setWatchlistMetricsRefetching(false);
        onWatchlistUpsertFailed();
      });
  };

  const [updateComparisonPeriod] = useUpdateComparisonPeriodMutation();

  const handleComparisonPeriodChanged = (
    items: WatchlistComparisonPeriodDto[]
  ) => {
    if (!watchlist || !selectedParameters) {
      return;
    }

    const comparisonPeriod = items[0];
    const comparisonPeriodString = items[0].comparisonPeriod;

    eventTrackingService.trackEvent(
      TrackingComponent.Watchlist,
      TrackingEvent.Parameters,
      GenericTrackingProperties.single(
        ParametersTrackingProperty.ComparisonTimePeriodSelected,
        comparisonPeriod.label
      )
    );

    setSelectedParameters({
      ...selectedParameters,
      comparisonPeriod,
    });

    setWatchlistMetricsRefetching(true);

    updateComparisonPeriod({
      comparisonPeriod: comparisonPeriodString,
      dashboardId: dashboard.id,
      division,
      watchlistId,
    })
      .unwrap()
      .then(() => {
        setWatchlist({
          ...watchlist,
          comparisonPeriod: comparisonPeriodString,
        });
        refetchGetWatchlistMetrics();
      })
      .catch(() => {
        setWatchlistMetricsRefetching(false);
        onWatchlistUpsertFailed();
      });
  };

  const [updateFocusPeriod] = useUpdateFocusPeriodMutation();

  const handlefocusPeriodChanged = (items: WatchlistFocusPeriodDto[]) => {
    if (!watchlist || !selectedParameters) {
      return;
    }

    const focusPeriod = items[0];
    const focusPeriodNumber = items[0].focusPeriod;

    eventTrackingService.trackEvent(
      TrackingComponent.Watchlist,
      TrackingEvent.Parameters,
      GenericTrackingProperties.single(
        ParametersTrackingProperty.FocalTimePeriod,
        focusPeriod.label
      )
    );

    setSelectedParameters({
      ...selectedParameters,
      focusPeriod,
    });

    setWatchlistMetricsRefetching(true);

    updateFocusPeriod({
      dashboardId: dashboard.id,
      division,
      focusPeriod: focusPeriodNumber,
      watchlistId,
    })
      .unwrap()
      .then(() => {
        setWatchlist({ ...watchlist, focusPeriod: focusPeriodNumber });
        refetchGetWatchlistMetrics();
      })
      .catch(() => {
        setWatchlistMetricsRefetching(false);
        onWatchlistUpsertFailed();
      });
  };

  const [addWatchlistItems] = useAddWatchlistItemsMutation();

  const handleAddItems = (items: SelectableItem[]) => {
    const watchlistItems = items.map((item) => {
      if (isSelectableAttributeItem(item)) {
        return {
          code: item.code,
          hierarchyCode: item.additionalHierarchyFilter?.code,
          hierarchyLevel: item.additionalHierarchyFilter?.shortName,
          shortName: item.shortName,
          type: WatchlistItemType.Attribute,
        } as AddWatchlistItemDto;
      } else if (isSelectableAttributeLevelItem(item)) {
        return {
          code: item.parent.code,
          hierarchyCode: item.code,
          hierarchyLevel: item.shortName,
          shortName: item.parent.shortName,
          type: WatchlistItemType.Attribute,
        } as AddWatchlistItemDto;
      } else if (isSelectableHierarchyItem(item)) {
        return {
          code: item.code,
          shortName: item.shortName,
          type: WatchlistItemType.Hierarchy,
        } as AddWatchlistItemDto;
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- Think this makes it clearer
      } else if (isSelectableProductGroupItem(item)) {
        return {
          type: WatchlistItemType.ProductGroup,
          productGroupId: item.productGroupId,
        } as AddWatchlistProductGroupItemDto;
      }

      throw new Error("Unknown watchlist item type");
    }) as AddWatchlistItemDto[];

    addWatchlistItems({
      dashboardId: dashboard.id,
      division,
      watchlistId,
      watchlistItems,
    })
      .unwrap()
      .then(() => {
        onWatchlistUpsertSuccess();
        refetchGetWatchlist();

        for (const watchlistItem of watchlistItems) {
          trackWatchlistItemEvent(
            eventTrackingService,
            watchlistItem,
            TrackingEvent.Added
          );
        }
      })
      .catch(onWatchlistUpsertFailed)
      .finally(handleCloseHierarchySearch);
  };

  const handleDelete = useCallback(
    async (itemsToDelete: Set<string> | WatchlistItemDto[]) => {
      let watchlistItems: WatchlistItemDto[];

      if (itemsToDelete instanceof Set) {
        if (itemsToDelete.size === 0 || !watchlistTableItems) return;

        watchlistItems = watchlistTableItems.filter((item) =>
          itemsToDelete.has(item.id)
        );

        setSelectedWatchlistItems(new Set());
      } else {
        watchlistItems = itemsToDelete;
      }

      const itemCount = watchlistItems.length;
      const watchlistItemIds = watchlistItems.map((item) => item.id);

      try {
        await deleteWatchlistItems({
          dashboardId: dashboard.id,
          division,
          watchlistId,
          watchlistItemIds,
        }).unwrap();

        for (const watchlistItem of watchlistItems) {
          trackWatchlistItemEvent(
            eventTrackingService,
            watchlistItem,
            TrackingEvent.Deleted
          );
        }

        QbitEmitToast(
          <QbitToastMessage
            content={
              <p>{`Successfully deleted ${itemCount} item${
                itemCount === 1 ? "" : "s"
              } from your watchlist.`}</p>
            }
            heading={<h5>Items Deleted</h5>}
            showIcon
            variant={MessageVariant.Success}
          />
        );

        onWatchlistUpsertSuccess();
      } catch {
        QbitEmitToast(
          <QbitToastMessage
            content={<p>Please try again later</p>}
            heading={<h5>Delete Failed</h5>}
            showIcon
            variant={MessageVariant.Danger}
          />
        );

        onWatchlistUpsertFailed();
      }
    },
    [
      watchlistTableItems,
      deleteWatchlistItems,
      eventTrackingService,
      onWatchlistUpsertSuccess,
      onWatchlistUpsertFailed,
      dashboard.id,
      watchlistId,
      division,
      setSelectedWatchlistItems,
    ]
  );

  const [reorderWatchlistItems] = useReorderWatchlistItemsMutation();

  const handleReorderItem = (
    watchlistItem: WatchlistItemDto,
    oldPosition: number,
    newPosition: number
  ) => {
    if (watchlistTableItems) {
      reorderWatchlistItems({
        dashboardId: dashboard.id,
        division,
        newPosition,
        watchlistId,
        watchlistItemId: watchlistItem.id,
      })
        .unwrap()
        .then(() => {
          eventTrackingService.trackEvent(
            TrackingComponent.WatchlistRow,
            TrackingEvent.Organised
          );
        })
        .catch(onWatchlistUpsertFailed)
        .finally(handleCloseHierarchySearch);
    }
  };

  const isWatchlistEmpty = watchlist && watchlist.items.length === 0;
  if (isWatchlistEmpty) {
    return (
      <EmptyWatchlist
        availableSearchLevels={availableSearchLevels}
        dashboardId={dashboard.id}
        maxWatchlistItems={dashboard.maxWatchlistItems}
        onSearchLevelChanged={onSearchLevelChanged}
        onWatchlistUpsertFailed={() => {}}
        onWatchlistUpsertSuccess={() => {
          setWatchlist(undefined);
        }}
        searchLevel={searchLevel}
        watchlistId={watchlistId}
      />
    );
  }

  const watchlistReadyToDisplay =
    watchlist && metrics && watchlistTableItems && selectedParameters;

  const watchlistButtons = (
    watchlistParameters: WatchlistParametersDto,
    chosenMetrics: MetricDefinition[],
    chosenParameters: SelectedParameters
  ) => (
    <div>
      <div className={styles.heading}>
        <h4>Watchlist</h4>
      </div>
      <div className={styles.parameterContainer}>
        <Group rowspace={GroupRowspace.Medium}>
          <Item halign={ItemHalign.Left} width={ItemWidth.Fit}>
            <Group>
              <Item className={styles.parameter}>
                <AddItemsButton onClick={handleShowHierarchySearch} />
              </Item>
              {chosenParameters.transactionSource && (
                <Item className={styles.parameter}>
                  <ParameterSelectorDropdown
                    buttonText={`${chosenParameters.transactionSource.label} dataset`}
                    data-cy="DatasetSelector"
                    defaultSelection={[chosenParameters.transactionSource]}
                    disableLastSelected
                    isMultiSelect={false}
                    itemDisplaySelector={displaySelector}
                    itemKeySelector={(item) => item.label}
                    items={watchlistParameters.transactionSources}
                    onSelectionChanged={handleTransactionSourceChanged}
                  />
                </Item>
              )}
              <Item className={styles.parameter}>
                <ParameterSelectorDropdown
                  buttonText={`Metrics (${chosenMetrics.length})`}
                  data-cy="MetricSelector"
                  defaultSelection={chosenParameters.metrics}
                  disableLastSelected={false}
                  isMultiSelect
                  itemDisplaySelector={metricDisplaySelector}
                  itemKeySelector={(item) => item.key}
                  items={
                    filterCustomerMetrics
                      ? nonCustomerMetrics
                      : watchlistParameters.watchlistMetrics
                  }
                  onSelectionChanged={handleMetricsChanged}
                />
              </Item>
              <Item className={styles.parameter}>
                <ParameterSelectorDropdown
                  buttonText={chosenParameters.focusPeriod.label}
                  data-cy="FocusPeriodSelector"
                  defaultSelection={[chosenParameters.focusPeriod]}
                  disableLastSelected
                  isMultiSelect={false}
                  itemDisplaySelector={displaySelector}
                  itemKeySelector={(item) => item.focusPeriod}
                  items={watchlistParameters.focusPeriods}
                  onSelectionChanged={handlefocusPeriodChanged}
                />
              </Item>
              <Item className={styles.parameter} valign={ItemValign.Middle}>
                <Text>vs</Text>
              </Item>
              <Item className={styles.parameter}>
                <ParameterSelectorDropdown
                  buttonText={chosenParameters.comparisonPeriod.label}
                  data-cy="ComparisonPeriodSelector"
                  defaultSelection={[chosenParameters.comparisonPeriod]}
                  disableLastSelected
                  isMultiSelect={false}
                  itemDisplaySelector={displaySelector}
                  itemKeySelector={(item: WatchlistComparisonPeriodDto) =>
                    item.comparisonPeriod
                  }
                  items={watchlistParameters.comparisonPeriods}
                  onSelectionChanged={handleComparisonPeriodChanged}
                />
              </Item>
              {chosenParameters.compStore && (
                <Item className={styles.parameter}>
                  <ParameterSelectorDropdown
                    buttonText={chosenParameters.compStore.label}
                    defaultSelection={[chosenParameters.compStore]}
                    disableLastSelected
                    isMultiSelect={false}
                    itemDisplaySelector={displaySelector}
                    itemKeySelector={(item) => item.label}
                    items={watchlistParameters.compStores}
                    onSelectionChanged={handleCompStoreChanged}
                  />
                </Item>
              )}
            </Group>
          </Item>
        </Group>
      </div>
    </div>
  );

  const watchlistControls = () => {
    if (!watchlistReadyToDisplay) return null;

    if (selectedWatchlistItems.size > 0) {
      return (
        <ItemsSelectedButtons
          chosenMetrics={metrics}
          chosenParameters={selectedParameters}
          handleDelete={handleDelete}
          handleShowHierarchySearch={handleShowHierarchySearch}
          newWatchlistCsv={newWatchlistCsv}
          selectedWatchlistItems={selectedWatchlistItems}
          tableItems={watchlistTableItems}
        />
      );
    } else {
      return (
        <NoItemsSelectedButtons
          chosenMetrics={metrics}
          chosenParameters={selectedParameters}
          filterCustomerMetrics={filterCustomerMetrics}
          handleCompStoreChanged={handleCompStoreChanged}
          handleComparisonPeriodChanged={handleComparisonPeriodChanged}
          handleMetricsChanged={handleMetricsChanged}
          handleShowHierarchySearch={handleShowHierarchySearch}
          handleTransactionSourceChanged={handleTransactionSourceChanged}
          handlefocusPeriodChanged={handlefocusPeriodChanged}
          newWatchlistCsv={newWatchlistCsv}
          nonCustomerMetrics={nonCustomerMetrics}
          tableItems={watchlistTableItems}
          watchlistParameters={watchlist.parameters}
        />
      );
    }
  };

  return (
    <div
      className={styles.watchlistContainer}
      data-cy="Watchlist"
      data-dashboard-id={dashboard.id}
      data-watchlist-id={watchlistId}
    >
      <ReportLoadingWrapper
        isError={isErrorGetWatchlist}
        isLoading={isLoadingGetWatchlist}
        reportMinimumHeight={300}
        retry={refetchGetWatchlist}
      >
        {!watchlistReadyToDisplay && <Spinner />}

        {watchlistReadyToDisplay && (
          <div className={styles.watchlistTableContainer}>
            <ErrorBoundary>
              {newWatchlistButtonsEnabled
                ? watchlistControls()
                : watchlistButtons(
                    watchlist.parameters,
                    metrics,
                    selectedParameters
                  )}
              <ReportLoadingWrapper
                disableLoadingSpinner
                isError={isErrorGetWatchlistMetrics}
                isLoading={isLoadingGetWatchlistMetrics}
                reportMinimumHeight={300}
                retry={refetchGetWatchlistMetrics}
              >
                {selectedWatchlistItems.size === 0 && (
                  <div className={styles.watchlistHeader}>
                    <FocusPeriodDescription
                      endDate={
                        watchlistMetricsRefetching
                          ? undefined
                          : dataGetWatchlistMetrics?.focusPeriodEnd
                      }
                      selectedFocusPeriod={selectedParameters.focusPeriod}
                      startDate={
                        watchlistMetricsRefetching
                          ? undefined
                          : dataGetWatchlistMetrics?.focusPeriodStart
                      }
                    />
                  </div>
                )}
                <div className={styles.watchlistTableFlexContainer}>
                  {useNewWatchlistTable ? (
                    <WatchlistTableNew
                      comparisonPeriodTitle={
                        selectedParameters.comparisonPeriod.label
                      }
                      focalPeriodTitle={`Last ${watchlist.focusPeriod}w`}
                      items={watchlistTableItems}
                      metrics={metrics}
                      onDeleteItems={handleDelete}
                      onReorderItem={handleReorderItem}
                      onSelectionChange={handleSelectionChange}
                      refetching={watchlistMetricsRefetching}
                      selectedItems={selectedWatchlistItems}
                      userSelectedTransactionSource={
                        selectedParameters.transactionSource?.transactionSource
                      }
                    />
                  ) : (
                    <WatchlistTable
                      comparisonPeriodTitle={
                        selectedParameters.comparisonPeriod.label
                      }
                      focalPeriodTitle={`Last ${watchlist.focusPeriod}w`}
                      items={watchlistTableItems}
                      metrics={metrics}
                      onDeleteItems={handleDelete}
                      onReorderItem={handleReorderItem}
                      refetching={watchlistMetricsRefetching}
                      userSelectedTransactionSource={
                        selectedParameters.transactionSource?.transactionSource
                      }
                    />
                  )}
                </div>
                <HierarchySearchAddItemsLayout
                  availableSearchLevels={availableSearchLevels}
                  existingSelectedItemCount={
                    (watchlistSummary as WatchlistWidgetDto).itemCount
                  }
                  maxWatchlistItems={dashboard.maxWatchlistItems}
                  onAddItems={handleAddItems}
                  onClose={handleCloseHierarchySearch}
                  onSearchLevelChanged={onSearchLevelChanged}
                  searchLevel={searchLevel}
                  show={showHierarchySearch}
                />
              </ReportLoadingWrapper>
            </ErrorBoundary>
          </div>
        )}
      </ReportLoadingWrapper>
    </div>
  );
};
