import {
  Button,
  ButtonHeight,
  ButtonVariant,
  Icon,
  IconGlyph,
  IconSize,
} from "@qbit/react";
import { type ParameterDto, ParameterId } from "@quantium-enterprise/common-ui";
import { SearchBox } from "components-ui/src/search-box/SearchBox";
import { ContextMenuType } from "components-ui/src/tables/common/table-cell/ContextMenu";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAppSelector } from "../../../states/hooks";
import {
  onSearchChange,
  onShowAdvancedSearch,
  resetSearch,
  selectAdvancedSearchEnableState,
  selectFilterRules,
  selectHierarchyItems,
  selectHierarchySelectedItemsFiltered,
  selectIsSelectedItemsShown,
  selectSearchHasNextPage,
  selectSearchString,
  selectTriggerSearchState,
  updateParameterWarningState,
} from "../../../states/report-wizard-slice";
import { type RootState } from "../../../store";
import { ParameterAdvancedSearchEditor } from "../../advanced-search/ParameterAdvancedSearchEditor";
import { isFilterComplete } from "../../utils/isFilterComplete";
import { getHierarchyState } from "../HierarchyState";
import {
  PRODUCT_HIERARCHY_SEARCH_MAX_PAGE_SIZE,
  PRODUCT_HIERARCHY_SEARCH_QUERY_PAGE_SIZE,
  SEARCH_BOX_DEBOUNCE_TIME,
} from "../ProductHierarchyParameter";
import { ProductHierarchyParameterQuery } from "../ProductHierarchyParameterQuery";
import styles from "./AssociatedProductsParameter.module.css";

type AssociatedProductsParameterProps = {
  parameterDto: ParameterDto;
};

export const AssociatedProductsParameter = ({
  parameterDto,
}: AssociatedProductsParameterProps) => {
  const dispatch = useDispatch();

  const contextMenuType =
    ContextMenuType[parameterDto.contextMenuType ?? "None"];

  const items = useSelector((state: RootState) =>
    selectHierarchyItems(ParameterId.AssociatedProducts, state)
  );

  const focalProductState = useAppSelector(
    getHierarchyState(ParameterId.FocalProducts)
  );

  const searchString = useSelector((state: RootState) =>
    selectSearchString(ParameterId.AssociatedProducts, state)
  );

  const filterRules = useSelector((state: RootState) =>
    selectFilterRules(ParameterId.AssociatedProducts, state)
  );

  const triggerSearch = useSelector((state: RootState) =>
    selectTriggerSearchState(ParameterId.AssociatedProducts, state)
  );

  const isAdvancedSearchEnabled = useSelector((state: RootState) =>
    selectAdvancedSearchEnableState(ParameterId.AssociatedProducts, state)
  );

  const handleShowAdvancedSearch = () =>
    dispatch(onShowAdvancedSearch(ParameterId.AssociatedProducts));

  const handleSearchChange = useCallback(
    (searchText: string) => {
      dispatch(
        onSearchChange({
          parameterType: ParameterId.AssociatedProducts,
          searchString: searchText,
          filterRules,
        })
      );
    },
    [dispatch, filterRules]
  );

  const showSearchResultMessage = useMemo(
    () =>
      !triggerSearch &&
      (searchString !== "" ||
        (isAdvancedSearchEnabled &&
          filterRules.every((rule) => isFilterComplete(rule)))),
    [filterRules, isAdvancedSearchEnabled, searchString, triggerSearch]
  );

  const isSelectedItemsShown = useSelector((state: RootState) =>
    selectIsSelectedItemsShown(ParameterId.AssociatedProducts, state)
  );

  const selectedRowsFiltered = useSelector((state: RootState) =>
    selectHierarchySelectedItemsFiltered(ParameterId.AssociatedProducts, state)
  );

  const searchHasNextPage = useSelector((state: RootState) =>
    selectSearchHasNextPage(ParameterId.AssociatedProducts, state)
  );

  const resultCountText = useMemo(() => {
    const amount = isSelectedItemsShown
      ? selectedRowsFiltered.length
      : items.length;
    if (searchHasNextPage && !isSelectedItemsShown) {
      return `${PRODUCT_HIERARCHY_SEARCH_MAX_PAGE_SIZE}+ matches found. Showing first ${PRODUCT_HIERARCHY_SEARCH_QUERY_PAGE_SIZE}, please refine your search further`;
    } else {
      return `${amount} match${amount === 1 ? "" : "es"} found`;
    }
  }, [
    searchHasNextPage,
    items.length,
    selectedRowsFiltered.length,
    isSelectedItemsShown,
  ]);

  /**
   * Reset the search state when the component is unmounted
   */
  useEffect(
    () => () => {
      dispatch(resetSearch(ParameterId.AssociatedProducts));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Show warning when there are no Focal Products selected
  useEffect(() => {
    const showWarning = focalProductState?.selectedRows.length === 0;
    dispatch(
      updateParameterWarningState({
        isWarning: showWarning,
        parameter: parameterDto.id,
      })
    );
  }, [
    dispatch,
    parameterDto.options,
    parameterDto.id,
    focalProductState?.selectedItems.length,
    focalProductState?.selectedRows.length,
  ]);

  return (
    <div className={styles.associatedProductContainer}>
      <div className={styles.searchFilterRow}>
        <div className={styles.searchBox}>
          <SearchBox
            debounceTimeMs={SEARCH_BOX_DEBOUNCE_TIME}
            enableDebounce
            onChange={handleSearchChange}
            testId="associated-product-parameter-search"
          />
        </div>
        <Button
          height={ButtonHeight.XSmall}
          onClick={handleShowAdvancedSearch}
          variant={ButtonVariant.Stealth}
        >
          <Icon
            glyph={IconGlyph.SortAndViewFilter}
            size={IconSize.Small}
            text="Filter"
          />
          <span>Filter</span>
        </Button>
        {showSearchResultMessage && (
          <div className={styles.resultCount}>{resultCountText}</div>
        )}
      </div>
      {isAdvancedSearchEnabled && (
        <div className={styles.advancedSearchFilterRow}>
          <ParameterAdvancedSearchEditor
            enableAdvancedSearch={handleShowAdvancedSearch}
            parameterType={ParameterId.AssociatedProducts}
            useFullWidth
          />
        </div>
      )}
      <ProductHierarchyParameterQuery
        contextMenuType={contextMenuType}
        parameterType={ParameterId.AssociatedProducts}
      />
    </div>
  );
};
