import {
  Button,
  ButtonHeight,
  ButtonVariant,
  Tooltip,
  TooltipPlacement,
  TooltipSpaceInside,
  TooltipVariant,
} from "@qbit/react";
import { Link } from "react-router-dom";
import styles from "../GroupInfoPanelHeader.module.css";

export type EditButtonProps = {
  editGroupPath: string;
  isOwner: boolean;
};

export const EditButton = ({ editGroupPath, isOwner }: EditButtonProps) => (
  <Tooltip
    placement={TooltipPlacement.TopCentre}
    spaceInside={TooltipSpaceInside.Small}
    trigger={
      <Link className={styles.editButton} to={editGroupPath}>
        <Button
          data-testid="edit-group-button"
          disabled={!isOwner}
          height={ButtonHeight.XSmall}
          text="Edit"
          variant={ButtonVariant.Primary}
        />
      </Link>
    }
    variant={TooltipVariant.ArrowDark}
  >
    {isOwner ? "Edit" : "Only owner can edit"}
  </Tooltip>
);
