import {
  type ParameterDto,
  type TrendedandAggregatedParameterOption,
  type TimePeriodParameterOption,
  isTrendedAndAggregatedParameterOption,
  ParameterId,
} from "@quantium-enterprise/common-ui";
import { ButtonToggleGroup } from "components-ui/src/button-toggle-group/ButtonToggleGroup";
import React from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../states/hooks";
import {
  focusPeriodSelected,
  timePeriodDataTypeSelected,
} from "../../states/report-wizard-slice";
import { getTimePeriodState } from "./TimePeriodState";
import { getTimePeriodDataTypeState } from "./TrendedAndAggregatedState";
import {
  getViewType,
  viewTypes,
  type ViewType,
} from "./TrendedAndAggregatedViewType";
import styles from "./components/TimePeriod.module.css";

type TrendedAndAggregatedParameterProps = {
  parameterDto: ParameterDto;
};

export const TrendedAndAggregatedParameter = ({
  parameterDto,
}: TrendedAndAggregatedParameterProps) => {
  const dispatch = useDispatch();

  const TimePeriodDataTypeState = useAppSelector(
    getTimePeriodDataTypeState(parameterDto.id)
  );

  const focusPeriodState = useAppSelector(
    getTimePeriodState(ParameterId.FocusPeriod)
  );

  const options = React.useMemo(
    () => parameterDto.options.filter(isTrendedAndAggregatedParameterOption),
    [parameterDto.options]
  );

  const buttonOptions = React.useMemo(
    () =>
      options.map((option, index) => ({
        displayText: option.label,
        id: index,
        disabled: option.isDisabled,
      })),
    [options]
  );

  const selectedIndex = React.useMemo(() => {
    const index = options.findIndex(
      (opt) => opt.value === TimePeriodDataTypeState?.value
    );
    return index === -1 ? 0 : index;
  }, [TimePeriodDataTypeState?.value, options]);

  const handleViewTypeChange = React.useCallback(
    (selectedOption: TrendedandAggregatedParameterOption): void => {
      const newViewType: ViewType = getViewType(selectedOption.value);
      const isAggregateView = Object.is(newViewType, viewTypes.AGGREGATE);

      dispatch(
        timePeriodDataTypeSelected({
          parameter: parameterDto.id,
          value: selectedOption.value,
        })
      );

      if (!isAggregateView) {
        dispatch(
          focusPeriodSelected({
            parameter: ParameterId.FocusPeriod,
            timePeriodValue: "",
          })
        );
      }

      const defaultOption = focusPeriodState?.parameterConfig.options.find(
        (opt) => opt.isDefault
      ) as TimePeriodParameterOption;

      dispatch(
        focusPeriodSelected({
          parameter: ParameterId.FocusPeriod,
          timePeriodValue: isAggregateView
            ? [defaultOption.value]
            : defaultOption.value,
        })
      );
    },
    [dispatch, parameterDto.id, focusPeriodState]
  );

  const handleButtonToggle = React.useCallback(
    (selection: number) => {
      handleViewTypeChange(options[selection]);
    },
    [options, handleViewTypeChange]
  );

  return (
    <div className={styles.timePeriodContainer}>
      <ButtonToggleGroup
        buttonSelected={selectedIndex}
        buttons={buttonOptions}
        setButtonSelected={handleButtonToggle}
      />
    </div>
  );
};

export default TrendedAndAggregatedParameter;
