import {
  ButtonVariant,
  ButtonHeight,
  Text,
  Button,
  Dialog,
  DialogHeight,
  DialogWidth,
  Icon,
  IconGlyph,
  ButtonGroup,
  ButtonGroupVariant,
} from "@qbit/react";
import { type SubscriptionDto } from "@quantium-enterprise/common-ui/src/models/subscription-dto";
import { useCallback, useRef, useState } from "react";
import styles from "./SubscriptionToggle.module.css";

export type SubscriptionToggleProps = {
  handleChangeSubscription: (option: SubscriptionDto) => void;
  hideToggle: boolean;
  ignoreDialog: boolean;
  selectedSubscription: SubscriptionDto | undefined;
  subscriptionOptions: SubscriptionDto[];
};

export const SubscriptionToggle = ({
  handleChangeSubscription,
  hideToggle,
  ignoreDialog,
  selectedSubscription,
  subscriptionOptions,
}: SubscriptionToggleProps) => {
  const [showDialog, setShowDialog] = useState(false);
  const [targetOption, setTargetOption] = useState<SubscriptionDto | null>(
    null
  );
  const buttonRef = useRef(null);

  const handleSelectOption = useCallback(
    (option: SubscriptionDto) => {
      if (ignoreDialog) {
        handleChangeSubscription(option);
      } else {
        setTargetOption(option);
        setShowDialog(true);
      }
    },
    [handleChangeSubscription, ignoreDialog]
  );

  const handleDialogCancel = useCallback(() => {
    setTargetOption(null);
    setShowDialog(false);
  }, []);

  const handleDialogSwitch = useCallback(() => {
    if (targetOption) {
      handleChangeSubscription(targetOption);
    }

    setTargetOption(null);
    setShowDialog(false);
  }, [handleChangeSubscription, targetOption]);

  return (
    <>
      <Dialog
        className={styles.dialog}
        footer={
          <div className={styles.dialogFooter}>
            <Button onClick={handleDialogSwitch} variant={ButtonVariant.Danger}>
              <Text>Clear all and switch</Text>
            </Button>
            <Button
              onClick={handleDialogCancel}
              variant={ButtonVariant.Default}
            >
              <Text>Cancel</Text>
            </Button>
          </div>
        }
        header={
          <div className={styles.dialogHeader}>
            <h5>Switch to {targetOption?.displayName} hierarchy?</h5>
            <Button
              onClick={handleDialogCancel}
              variant={ButtonVariant.Stealth}
            >
              <Icon glyph={IconGlyph.DeleteAndCloseClose} text="Close" />
            </Button>
          </div>
        }
        height={DialogHeight.XSmall}
        onClose={handleDialogCancel}
        show={showDialog}
        titleId="exit-dialog"
        triggeredBy={buttonRef}
        width={DialogWidth.XXXSmall}
      >
        <div>
          <p>
            Switching datasets will{" "}
            <strong>clear your current selections.</strong>
          </p>
          <p>Do you want to continue?</p>
        </div>
      </Dialog>
      {!hideToggle && (
        <ButtonGroup
          className={styles.subscriptionButtons}
          data-testid="subscription-toggle"
          variant={ButtonGroupVariant.Segmented}
        >
          {subscriptionOptions.map((option) => (
            <Button
              height={ButtonHeight.XSmall}
              key={option.featureModule}
              onClick={() => handleSelectOption(option)}
              pressed={option === selectedSubscription}
              text={option.displayName}
              variant={ButtonVariant.Secondary}
            />
          ))}
        </ButtonGroup>
      )}
    </>
  );
};
