import {
  FormBlockStatus,
  Tag,
  TagColour,
  TagTextTransform,
  TagVariant,
} from "@qbit/react";
import { useEffect } from "react";
import { ErrorKeys } from "../TimePeriodState";
import { dateToString } from "../utilities";
import {
  CustomTimePeriod,
  type CustomTimePeriodOption,
} from "./CustomTimePeriod";
import NumberOfWeeksInput from "./NumberOfWeeksInput";
import styles from "./TimePeriod.module.css";
import { TimePeriodOptions } from "./TimePeriodConstants";
import { WithMinWeeksRestriction } from "./WithMinWeeksRestriction";
import TimeDropdown from "./shared/time-dropdown/TimeDropdown";
import { type TimePeriodOption } from "./time-period-option";

export type TimePeriodProps = {
  customLength: boolean;
  customTimePeriodOption?: TimePeriodOption;
  customTimePeriodOptions: CustomTimePeriodOption[];
  endDate: Date | undefined;
  endDateHandler: (startDate: string) => void;
  errors: Record<string, boolean>;
  infoIcon?: string;
  isDisabled: boolean;
  isDropdownVisible?: boolean;
  isEndDateSelectionVisible?: boolean;
  isNumberOfWeeksTagVisible?: boolean;
  isVisited: boolean;
  label: string;
  maxEndDate: Date | undefined;
  maxStartDate: Date | undefined;
  maxWeeks?: number;
  minEndDate: Date | undefined;
  minStartDate: Date | undefined;
  minWeeks?: number;
  onChange: (newTimePeriod: string) => void;
  options: TimePeriodOption[];
  startDate: Date | undefined;
  startDateHandler: (startDate: string) => void;
  timePeriod: string;
  weeks: number | undefined;
  weeksHandler?: (weeks: number) => void;
};

export const TimePeriod = ({
  customTimePeriodOptions,
  customTimePeriodOption,
  customLength,
  endDate,
  endDateHandler,
  errors,
  infoIcon,
  isDisabled,
  isVisited,
  label,
  maxEndDate,
  maxStartDate,
  maxWeeks,
  minEndDate,
  minStartDate,
  minWeeks,
  onChange,
  options,
  startDate,
  startDateHandler,
  timePeriod,
  weeks,
  weeksHandler,
  isDropdownVisible = true,
  isEndDateSelectionVisible = true,
  isNumberOfWeeksTagVisible = true,
}: TimePeriodProps) => {
  const defaultTimePeriod = {
    disabled: false,
    label: `Select ${label.toLowerCase()}`,
    value: "",
  };

  const selectBlockStatus =
    isVisited && errors[ErrorKeys.value]
      ? FormBlockStatus.Error
      : FormBlockStatus.Default;
  // Always show the number weeks block status as it is a user input field.
  // Notifying the user they entered something wrong immediately in this field
  // will be better than notifying them only after navigating away.
  // This is different to the select as the user cannot choose an invalid option with a select.
  const numberWeeksBlockStatus = errors[ErrorKeys.invalidWeekRange]
    ? FormBlockStatus.Error
    : FormBlockStatus.Default;

  useEffect(() => {
    if (!isDropdownVisible && timePeriod !== TimePeriodOptions.CUSTOM_PERIOD)
      onChange(TimePeriodOptions.CUSTOM_PERIOD);
  }, [isDropdownVisible, onChange, timePeriod]);

  const customPeriod = (beginningOfEndDate?: Date, endOfStartDate?: Date) => (
    <CustomTimePeriod
      beginningOfEndDate={beginningOfEndDate}
      endDate={endDate}
      endDateHandler={endDateHandler}
      endOfStartDate={endOfStartDate}
      errors={errors}
      isEndDateSelectionVisible={isEndDateSelectionVisible}
      isVisited={isVisited}
      maxEndDate={maxEndDate}
      maxStartDate={maxStartDate}
      minEndDate={minEndDate}
      minStartDate={minStartDate}
      options={customTimePeriodOptions}
      startDate={startDate}
      startDateHandler={startDateHandler}
      weeks={weeks}
    />
  );

  return (
    <div className={styles.timePeriodContainer}>
      <div className={styles.timePeriodDropdownWrapper}>
        {isDropdownVisible && (
          <TimeDropdown
            blockStatus={selectBlockStatus}
            defaultOption={defaultTimePeriod}
            disabled={isDisabled}
            infoIcon={infoIcon}
            label={label}
            onChange={onChange}
            options={options}
            selectedValue={timePeriod}
          />
        )}

        {customLength && weeksHandler && (
          <NumberOfWeeksInput
            blockStatus={numberWeeksBlockStatus}
            max={maxWeeks}
            min={minWeeks}
            onChange={weeksHandler}
            value={weeks}
          />
        )}

        {isNumberOfWeeksTagVisible &&
          timePeriod &&
          timePeriod !== TimePeriodOptions.CUSTOM_PERIOD && (
            <Tag
              className={styles.timePeriodDateTag}
              colour={TagColour.Neutral}
              text={`${dateToString(startDate)} - ${dateToString(endDate)}`}
              textTransform={TagTextTransform.None}
              variant={TagVariant.Default}
            />
          )}
      </div>

      {timePeriod === TimePeriodOptions.CUSTOM_PERIOD && (
        <WithMinWeeksRestriction
          customTimePeriodBuilder={customPeriod}
          customTimePeriodOption={customTimePeriodOption}
          customTimePeriodOptions={customTimePeriodOptions}
          endDate={endDate}
          startDate={startDate}
        />
      )}
    </div>
  );
};
