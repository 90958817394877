import {
  getNumberFormat,
  useDivision,
  useFlags,
} from "@quantium-enterprise/hooks-ui";
import classNames from "classnames";
import { DataTableOptions } from "components-ui/src/data-table-options/DataTableOptions";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { BasketAffinitiesFeatureFlags } from "../constants/basket-affinities-feature-flags";
import {
  selectFocalItem,
  selectLocalParametersSelection,
  selectPurchasedWithProducts,
  selectPurchasedWithProductsSortingState,
  selectReportParameters,
} from "../services/basket-affinities-slice";
import { getExportParameterSummary } from "../utils/getExportParameterSummary";
import styles from "./BasketAffinitiesPurchasedWithFocalItemsTableExport.module.css";
import { csvPurchasedWithFocalItems } from "./csvTransformation";

export const BasketAffinitiesPurchasedWithFocalItemsTableExport = () => {
  const focalItem = useSelector(selectFocalItem);
  const tableData = useSelector(selectPurchasedWithProducts);
  const sortingState = useSelector(selectPurchasedWithProductsSortingState);
  const reportParameters = useSelector(selectReportParameters);

  const featureFlags = useFlags();
  const isExportEnabled =
    featureFlags[BasketAffinitiesFeatureFlags.ReportExport] ?? false;

  const localParametersSelection = useSelector(selectLocalParametersSelection);
  const { locale, currency } = useDivision();
  const currencySymbol = useMemo(() => {
    const { getCurrencySymbol } = getNumberFormat(locale, currency);
    return getCurrencySymbol();
  }, [locale, currency]);

  const exportFilename = useMemo(
    () =>
      cleanFilename(
        `Purchased_With_Focal_Item_Table_${localParametersSelection.timePeriodLength}_${localParametersSelection.location.name}`
      ),
    [localParametersSelection]
  );

  const localParameters = useMemo(
    () => getExportParameterSummary(localParametersSelection, focalItem),
    [localParametersSelection, focalItem]
  );

  const csvTransformationCallback = useCallback(
    () =>
      csvPurchasedWithFocalItems(
        tableData,
        sortingState,
        localParametersSelection.upliftThreshold,
        localParametersSelection.basketsWithBothThreshold,
        currencySymbol
      ),
    [tableData, sortingState, localParametersSelection, currencySymbol]
  );

  return (
    <div
      className={classNames({
        [styles.chartOptionsContainer]: isExportEnabled,
      })}
    >
      <DataTableOptions
        filename={exportFilename}
        invokeCSVDownload={csvTransformationCallback}
        isFeatureEnabled={isExportEnabled}
        localParameters={localParameters}
        reportParameters={reportParameters}
      />
    </div>
  );
};
