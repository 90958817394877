import { Button, ButtonVariant, Icon, IconGlyph, Text } from "@qbit/react";
import { FeatureFlag } from "@quantium-enterprise/common-ui";
import { useFlags } from "@quantium-enterprise/hooks-ui";

type AddItemsButtonProps = {
  onClick: () => void;
};

export const AddItemsButton = ({ onClick }: AddItemsButtonProps) => {
  const featureFlags = useFlags();
  const newWatchlistButtonsEnabled =
    featureFlags[FeatureFlag.NewWatchlistButtons];

  const text = newWatchlistButtonsEnabled ? "Add to watchlist" : "Add item(s)";
  const icon = newWatchlistButtonsEnabled
    ? IconGlyph.AddAndPlusPlusCircle
    : IconGlyph.AddAndPlusAddPlus;

  return (
    <Button
      data-cy="AddWatchlistItemButton"
      onClick={onClick}
      variant={ButtonVariant.Primary}
    >
      <Icon glyph={icon} text={text} />
      <Text>{text}</Text>
    </Button>
  );
};

export default AddItemsButton;
