import {
  Button,
  ButtonHeight,
  ButtonVariant,
  Dropdown,
  DropdownDisplay,
  DropdownPlacement,
  DropdownWidth,
  Icon,
  IconGlyph,
  IconSize,
  Menu,
  MenuItemButton,
  MenuSection,
  Nav,
  NavButton,
  NavSize,
  NavVariant,
} from "@qbit/react";
import {
  type ParameterOptionDto,
  type TransactionSource,
  ParameterId,
  type ParameterDto,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { TransactionSourceIcon } from "components-ui/src/icons";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  isListParameterState,
  ListParameterSelectionType,
} from "../../../parameters";
import {
  getselections,
  listOptionSelected,
} from "../../../states/report-wizard-slice";
import { type RootState } from "../../../store";
import ParameterItem from "../parameters/ParameterItem";
import styles from "./ParameterGroupTab.module.css";

export type TabbedParametersProps = {
  nonTabParameters: ParameterDto[];
  parameters: ParameterDto[];
};

export const TabbedParameters = ({
  parameters,
  nonTabParameters,
}: TabbedParametersProps) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [selected, setSelected] = useState<string>("");
  const dispatch = useDispatch();

  const { transactionSources } = useDivision();

  const selectedTransactionSource = useSelector((state: RootState) =>
    getselections(ParameterId.Dataset, state)
  );

  const transactionSourceParameters = useMemo(
    () =>
      nonTabParameters.find(
        (parameter) => parameter.id === ParameterId.Dataset
      ),
    [nonTabParameters]
  );

  useEffect(() => {
    if (
      transactionSourceParameters &&
      isListParameterState(selectedTransactionSource)
    ) {
      const selectedLabels = selectedTransactionSource.selections
        .slice(0, 1)
        .pop();
      setSelected(selectedLabels?.label ?? "");
    }
  }, [transactionSourceParameters, selectedTransactionSource]);

  const IsMulti =
    transactionSourceParameters &&
    transactionSourceParameters.options.length <= 1;

  const setSelectedTransactionSourceState = (item: ParameterOptionDto) => {
    setSelected(item.label);
    dispatch(
      listOptionSelected({
        parameter: transactionSourceParameters?.name ?? "",
        selectedValue: item.value,
        selectionType: ListParameterSelectionType.DropDown,
      })
    );
  };

  // remove selected tab now, and add it when its developed
  const parametersWithSelectedTab = [...parameters];
  const parameterNavItems = parametersWithSelectedTab.map(
    (parameter, index) => (
      <NavButton key={parameter.name} onClick={() => setCurrentTab(index)}>
        <span className={styles.parameterNav}>{parameter.name}</span>
      </NavButton>
    )
  );

  const parameterNavContent = () => {
    const tabName = parametersWithSelectedTab[currentTab].name;
    switch (tabName) {
      case "Selected":
        return (
          <div className={styles.parameterContainer}>
            <br />
          </div>
        );
      default: {
        const currentTabParameter = parameters.find(
          (parameter) => parameter.name === tabName
        );
        return (
          <>
            {currentTabParameter && (
              <ParameterItem
                key={currentTabParameter.name}
                parameterDto={currentTabParameter}
              />
            )}
          </>
        );
      }
    }
  };

  return (
    <>
      <div className={styles.tabs}>
        <div>
          <Nav
            activeIndex={currentTab}
            className={styles.tab}
            size={NavSize.Small}
            variant={NavVariant.Tab}
          >
            {parameterNavItems}
          </Nav>
        </div>
        {transactionSourceParameters && (
          <div className={styles.transactionSource}>
            <Dropdown
              contentWidth={DropdownWidth.Medium}
              data-testid="transaction-source-dropdown"
              display={DropdownDisplay.Block}
              placement={DropdownPlacement.BottomRight}
              trigger={
                <Button
                  disabled={IsMulti}
                  height={ButtonHeight.XSmall}
                  variant={ButtonVariant.Stealth}
                >
                  <span>{selected}</span>
                  <Icon
                    colour={IsMulti ? "var(--qbit-button-colour-inactive)" : ""}
                    glyph={IconGlyph.ArrowsChevronDown}
                    size={IconSize.Small}
                    text=""
                  />
                </Button>
              }
            >
              <Menu className={styles.menu}>
                <span className={styles.dataset}>DATASET</span>
                <MenuSection className={styles.menuItems}>
                  {transactionSourceParameters.options.map((item) => (
                    <MenuItemButton
                      key={`transaction-sources-button-${item.value}`}
                      onClick={() => {
                        setSelectedTransactionSourceState(item);
                      }}
                      selected={selected === item.label}
                      text={
                        <>
                          <div className={styles.sourceIcon}>
                            <TransactionSourceIcon
                              availableTransactionSources={transactionSources}
                              transactionSource={
                                item.value as TransactionSource
                              }
                            />
                          </div>
                          <span className={styles.itemLabel}>{item.label}</span>
                        </>
                      }
                    />
                  ))}
                </MenuSection>
              </Menu>
            </Dropdown>
          </div>
        )}
      </div>
      <div className={styles.tabsContent}>{parameterNavContent()}</div>
    </>
  );
};

export default TabbedParameters;
