/* eslint-disable complexity */
import {
  Nav,
  NavButton,
  NavVariant,
  Spinner,
  SpinnerSize,
  Text,
} from "@qbit/react";
import {
  HierarchyType,
  useGetGroupSummaryQuery,
  FeatureFlag,
  useGetGroupSharingActivitiesQuery,
  useGetUsersByIdQuery,
  AppContext,
  HierarchyGroupStatus,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import ErrorBanner from "components-ui/src/error-banner/ErrorBanner";
import WarningBanner from "components-ui/src/error-banner/WarningBanner";
import { HierarchyGroupSummary } from "components-ui/src/hierarchy-group-summary/HierarchyGroupSummary";
import { HierarchyGroupIcon } from "components-ui/src/icons";
import { InfoPanelActivity } from "components-ui/src/info-panel/info-panel-body/InfoPanelActivity";
import { ViewAccessModal } from "components-ui/src/modals";
import { PanelWithSideDrawer } from "components-ui/src/panel-with-side-drawer/PanelWithSideDrawer";
import {
  useContext,
  useEffect,
  useMemo,
  useState,
  type PropsWithChildren,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  onGroupSummaryReceived,
  selectFocalGroup,
  setFocalGroup,
  setMoveFolderOrGroup,
  setShareFolderOrGroup,
  setShowManageAccess,
} from "../../states/group-list-slice";
import { isFolder } from "../../utilities/folder-helper";
import { getDefaultGroupsPath } from "../../utilities/route-path-formats";
import styles from "./GroupInfoPanel.module.css";
import { GroupInfoPanelHeader } from "./group-info-panel-header/GroupInfoPanelHeader";

export type HierarchyGroupInfoPanelProps = {
  hierarchyType: HierarchyType;
};

export const HierarchyGroupInfoPanel = ({
  children,
  hierarchyType,
}: PropsWithChildren<HierarchyGroupInfoPanelProps>) => {
  const { userState } = useContext(AppContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentNavIndex, setCurrentNavIndex] = useState(0);
  const [sharingAccessModal, setSharingAccessModal] = useState(false);
  const flags = useFlags();
  const isEntitlementsFilterEnabled =
    flags[FeatureFlag.ScanEnhanceProductGroups] ?? false;
  const { name: divisionName } = useDivision();
  const { groupId } = useParams();
  const focalGroup = useSelector(selectFocalGroup);

  const displayEntitlements = useMemo(
    () =>
      isEntitlementsFilterEnabled &&
      userState.currentUser?.isSupplier &&
      hierarchyType === HierarchyType.Product,
    [hierarchyType, isEntitlementsFilterEnabled, userState.currentUser]
  );

  const onClose = () => {
    navigate(getDefaultGroupsPath(divisionName, hierarchyType));
    dispatch(setFocalGroup(undefined));
  };

  const commonConditions = Boolean(divisionName) && Boolean(groupId);
  const { currentData: summary, isFetching: isSummaryFetching } =
    useGetGroupSummaryQuery(
      {
        divisionName,
        groupId: groupId ?? "",
        maxItemsInSubset: 32,
      },
      {
        skip: !commonConditions || focalGroup?.isFolder,
      }
    );
  const { data: sharingActivities, isLoading: isActivityLoading } =
    useGetGroupSharingActivitiesQuery(
      {
        divisionName,
        groupId: groupId ?? "",
      },
      {
        skip:
          !(commonConditions && flags[FeatureFlag.SharingGroups]) ||
          focalGroup?.isFolder,
      }
    );
  const { data: reportSharedUsers, isLoading: isReportSharedUsersLoading } =
    useGetUsersByIdQuery(
      {
        divisionName,
        payload: {
          SalesforceUserIds:
            sharingActivities?.flatMap(
              (sharingActivity) => sharingActivity.salesforceUserIds
            ) ?? [],
        },
      },
      {
        skip: !(
          commonConditions &&
          Boolean(sharingActivities) &&
          Boolean(sharingActivities?.length) &&
          flags[FeatureFlag.SharingGroups]
        ),
      }
    );

  useEffect(() => {
    if (displayEntitlements && focalGroup?.id && summary) {
      dispatch(onGroupSummaryReceived({ groupId: focalGroup.id, summary }));
    }
  }, [dispatch, displayEntitlements, focalGroup?.id, summary]);

  const label = (
    <div className={styles.infoPanelLabel}>
      <HierarchyGroupIcon hierarchyType={hierarchyType} />
      <Text>{`${hierarchyType} group`}</Text>
    </div>
  );

  const infoPanelTabs = [
    {
      content: (
        <>
          {isSummaryFetching ? (
            <Spinner className={styles.spinner} size={SpinnerSize.Large} />
          ) : (
            <div className={styles.groupInfoPanelBodyContent}>
              <HierarchyGroupSummary
                group={summary}
                isSubscriptionShown={displayEntitlements}
                title="Group info"
              />
            </div>
          )}
        </>
      ),
      title: "Info",
    },
  ];

  const enableTrueShare = flags[FeatureFlag.ProductGroupsTrueShare];

  const userIsOwner =
    userState.currentUser?.salesForceId === focalGroup?.userId;

  // only show the "View/manage access" modal if True Share is enabled.
  // Recipients see a different, more restricted modal than owners.
  let showSharingAccess;
  if (enableTrueShare) {
    if (userIsOwner) {
      showSharingAccess = () => {
        dispatch(setShowManageAccess(true));
        dispatch(setShareFolderOrGroup(focalGroup));
      };
    } else {
      showSharingAccess = () => setSharingAccessModal(true);
    }
  } else {
    showSharingAccess = undefined;
  }

  // display "Manage access" for owners, but "View access" for recipients
  let sharingAccessText;
  if (enableTrueShare) {
    sharingAccessText = userIsOwner ? "Manage access" : "View access";
  } else {
    sharingAccessText = undefined;
  }

  if (flags[FeatureFlag.SharingGroups]) {
    const activityTab = {
      content: (
        <div className={styles.groupInfoPanelBodyContent}>
          {isActivityLoading || isReportSharedUsersLoading ? (
            <Spinner className={styles.spinner} size={SpinnerSize.Large} />
          ) : (
            <InfoPanelActivity
              header="Group activity"
              resourceType="group"
              sharedUsers={reportSharedUsers}
              sharingAccessText={sharingAccessText}
              sharingActivities={sharingActivities}
              showSharingAccess={showSharingAccess}
            />
          )}
        </div>
      ),
      title: "Activity",
    };
    infoPanelTabs.push(activityTab);
  }

  const header = (
    <>
      <GroupInfoPanelHeader
        focalGroup={focalGroup}
        groupType={hierarchyType}
        hierarchyGroupStatus={displayEntitlements ? summary?.status : undefined}
        onClose={onClose}
        onMove={() => dispatch(setMoveFolderOrGroup(focalGroup))}
        onShare={() => dispatch(setShareFolderOrGroup(focalGroup))}
      />
      {displayEntitlements &&
        summary?.status === HierarchyGroupStatus.Warning && (
          <div className={styles.infoBanner}>
            <WarningBanner
              extraPadding
              text="Some entitlements have changed and the product group has been updated with some removed products."
            />
          </div>
        )}
      {displayEntitlements &&
        summary?.status === HierarchyGroupStatus.Invalid && (
          <div className={styles.infoBanner}>
            <ErrorBanner
              extraPadding
              text="Entitlements have changed. This product group is now invalid and no longer available. Contact Product Support for more details."
            />
          </div>
        )}
      <Nav activeIndex={currentNavIndex} variant={NavVariant.Tab}>
        {infoPanelTabs.map((tab, index) => (
          <NavButton key={tab.title} onClick={() => setCurrentNavIndex(index)}>
            {tab.title}
          </NavButton>
        ))}
      </Nav>
    </>
  );

  const body = infoPanelTabs[currentNavIndex].content;

  return (
    <>
      {showSharingAccess && !userIsOwner && (
        <ViewAccessModal
          onClose={() => setSharingAccessModal(false)}
          ownerId={focalGroup?.userId}
          sharedWithUserIds={focalGroup?.sharedWithUserIds}
          showModal={sharingAccessModal}
        />
      )}
      <PanelWithSideDrawer
        closeFunc={onClose}
        drawerContent={{
          body,
          header,
          label,
        }}
        isOverlay={false}
        showDrawer={
          Boolean(focalGroup) && Boolean(groupId) && !isFolder(focalGroup)
        }
      >
        {children}
      </PanelWithSideDrawer>
    </>
  );
};
