import { type HierarchyValueDto } from "@quantium-enterprise/common-ui";
import { type FormatterFunction } from "@quantium-enterprise/hooks-ui";
import { MetricTypes, useFormatter } from "@quantium-enterprise/hooks-ui";
import HighChartsWithPinnableTooltips from "components-ui/src/charts/highcharts-react/HighChartsWithPinnableTooltips";
import {
  type HighchartsReactProps,
  defaultOptions,
} from "components-ui/src/charts/highcharts-react/HighchartsReact";
import { getPoint } from "components-ui/src/charts/utils";
import { HighchartsCustomTooltip } from "components-ui/src/highcharts-custom-tooltip/HighchartsCustomTooltip";
import {
  type TooltipFormatterContextObject,
  type PointClickCallbackFunction,
  type PointOptionsObject,
  type PointClickEventObject,
} from "highcharts";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectBasketLimitsChartRows,
  onStoreBreakdownFocalStoreChange,
  selectStoreBreakdownFocalStore,
  selectShowBasketLimitChartDataLabels,
  resetStoreBreakdownFocalStore,
} from "../services/basket-limits-slice";

const getStore: (
  ttData: TooltipFormatterContextObject
) => HierarchyValueDto | undefined = (ttData) => {
  const store = getPoint(ttData, (point) => point.options.custom?.store);
  if (store) {
    return store;
  }

  return undefined;
};

type TooltipProps = {
  chartDataFormatter?: FormatterFunction;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  ttData: TooltipFormatterContextObject;
};

const Tooltip = ({ chartDataFormatter, onClick, ttData }: TooltipProps) => (
  <HighchartsCustomTooltip.Layout>
    <HighchartsCustomTooltip.Title title={getStore(ttData)?.name ?? ""} />
    <HighchartsCustomTooltip.Series
      chartDataFormatter={chartDataFormatter}
      hasSentiment={false}
      hideSeriesIcon
      ttData={ttData}
      useSeriesNameForLabels
    />
    <HighchartsCustomTooltip.ClickableLink
      onClick={onClick}
      text="View store breakdown"
    />
  </HighchartsCustomTooltip.Layout>
);

export type BasketLimitsChartProps = {
  onOptionsChanged?: (options: HighchartsReactProps) => void;
};

export const BasketLimitsChart = ({
  onOptionsChanged,
}: BasketLimitsChartProps) => {
  const dispatch = useDispatch();
  const formatter = useFormatter();

  const basketLimitRows = useSelector(selectBasketLimitsChartRows);
  const storeBreakdownFocalStore = useSelector(selectStoreBreakdownFocalStore);
  const showBasketLimitChartDataLabels = useSelector(
    selectShowBasketLimitChartDataLabels
  );

  // TODO: change this to tooltip hyperlink/button
  const pointClickCallback: PointClickCallbackFunction = useCallback(
    (event: PointClickEventObject) => {
      const store = event.point.options.custom?.store;
      if (store) {
        if (
          store.itemCode === storeBreakdownFocalStore.itemCode &&
          store.shortName === storeBreakdownFocalStore.shortName
        ) {
          dispatch(resetStoreBreakdownFocalStore());
        } else {
          dispatch(onStoreBreakdownFocalStoreChange(store));
        }
      }
    },
    [dispatch, storeBreakdownFocalStore]
  );
  const tooltipClickCallback = useCallback(
    (ttData: TooltipFormatterContextObject) => {
      const store = getStore(ttData);
      if (store) {
        dispatch(onStoreBreakdownFocalStoreChange(store));
      }
    },
    [dispatch]
  );

  const categories = basketLimitRows.map((item) => item.store.name);
  // memoise the series so that pinned tooltips are reset ever rerender.
  const series = useMemo(() => {
    const data = basketLimitRows.map(
      (item) =>
        ({
          y: item.numberOfBaskets,
          custom: {
            store: item.store,
          },
        } as PointOptionsObject)
    );

    return [
      {
        // only care about the data matching the categories in the right order
        type: "column",
        // Typescript is complaining when it shouldn't. Types are ensured to be correct above anyway
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data: data as any,
        name: "Number of baskets",
      },
    ];
  }, [basketLimitRows]);
  // HACK: Update the colour outside the series to prevent pinned tooltips from being rerendered.
  for (const seriesData of series[0].data) {
    seriesData.color =
      seriesData.custom.store.itemCode === storeBreakdownFocalStore.itemCode &&
      seriesData.custom.store.shortName === storeBreakdownFocalStore.shortName
        ? "var(--qbit-colour-shade-6)"
        : undefined;
  }

  const yAxis: Highcharts.YAxisOptions[] = useMemo(
    () => [
      {
        plotLines: [
          {
            value: 0,
            color: "var(--qbit-colour-shade-6)",
            width: 2,
            zIndex: 1,
          },
        ],
        labels: {
          formatter() {
            // eslint-disable-next-line react/no-this-in-sfc, unicorn/no-this-assignment
            const self = this;
            const value = self.value;
            return formatter(MetricTypes.Integer)(value, false, "", true);
          },
          style: {
            color: "var(--qbit-colour-text-secondary)",
          },
        },
        title: {
          style: {
            fontWeight: "bold",
            color: "var(--qbit-colour-text-primary)",
          },
          text: "Number of baskets",
        },
        visible: true,
      },
    ],
    [formatter]
  );

  const options: HighchartsReactProps = useMemo(
    () => ({
      ...defaultOptions,
      chart: {
        // remove zooming and cursor styles
        style: {
          fontFamily: "var(--qbit-font-family)",
        },
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        categories,
        crosshair: {
          color: "var(--qbit-colour-chrome-background)",
          zIndex: 0,
        },
        visible: true,
        tickWidth: 1,
        title: {
          style: {
            fontWeight: "bold",
            color: "var(--qbit-colour-text-primary)",
          },
          text: "Store name",
        },
        labels: {
          style: {
            color: "var(--qbit-colour-text-secondary)",
          },
        },
      },
      // Typescript is complaining when it shouldn't. Types are ensured to be correct above anyway
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      yAxis: yAxis as any,
      tooltip: {
        ...defaultOptions.tooltip,
        ReactFormatter: (ttData) =>
          Tooltip({
            chartDataFormatter: formatter(MetricTypes.Integer),
            onClick: (event) => {
              event.stopPropagation();
              tooltipClickCallback(ttData);
            },
            ttData,
          }),
      },
      plotOptions: {
        column: {
          point: {
            events: {
              click: pointClickCallback,
            },
          },
        },
        series: {
          dataLabels: {
            enabled: showBasketLimitChartDataLabels,
            formatter() {
              // eslint-disable-next-line react/no-this-in-sfc, unicorn/no-this-assignment
              const self = this;
              const value = self.y;
              return formatter(MetricTypes.Integer)(value);
            },
          },
        },
      },
      // Typescript is complaining when it shouldn't. Types are ensured to be correct above anyway
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      series: series as any,
    }),
    [
      categories,
      formatter,
      pointClickCallback,
      series,
      showBasketLimitChartDataLabels,
      tooltipClickCallback,
      yAxis,
    ]
  );

  useEffect(() => {
    if (onOptionsChanged) {
      onOptionsChanged(options);
    }
  }, [onOptionsChanged, options]);

  return <HighChartsWithPinnableTooltips options={options} />;
};
